import React, { useState, useEffect } from "react";
import Customdatatable from "../../components/customtable/customtable";
import axios from "axios";
import moment from "moment";
import { BsSearch } from "react-icons/bs";
import { Modal, Dropdown } from "react-bootstrap";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import {
  backToNormal,
  deleteCareSite,
  deleteCareSiteBefore7Year,
  unblockCareSite,
  selectMultipleIds,
} from "../../redux-toolkit/reducer/SuperAdminReducer";
import DataGridTable from "../../utils/DataGridTable";
import { formatDate } from "../../utils/CommonFunctions";
import { IoIosArrowForward } from "react-icons/io";
import { filterData } from "../../utils/TableSearchFilter";
import { useHistory } from "react-router-dom";
import { twoDecimalPlaces } from "../../utils/utilsHandlerFunctions";

export default function Subscription() {
  let [rowsData, setrowData] = useState([]);
  const [searchTxt, setSearchTxt] = useState("");
  // react-redux state
  const dispatch = useDispatch();

  const {
    CareSiteIds,
    deleteSiteCount,
    deleteSiteMessage,
    deleteSiteStatus,
    deleteCareSiteBefore7YearCount,
    deleteCareSiteBefore7YearMessage,
    deleteCareSiteBefore7YearStatus,
  } = useSelector((state) => state.superAdminReducer);

  useEffect(() => {
    adminlisting();
  }, []);

  function adminlisting() {
    dispatch(
      backToNormal({
        loading: false,
        CareSiteIds: [],
        selectedIds: [],
        deleteSiteCount: 0,
        deleteSiteMessage: "",
        deleteSiteStatus: false,
      })
    );
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_SUPERADMIN_BASEURL}/getOwners`,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("superadmin_token"),
      },
    };
    axios(config)
      .then(function (response) {
        const responseData = response.data.data;
        let subscriptiondata = [];
        responseData.forEach((element) => {
          if (element?.subscriptionPlanData?.length > 0) {
            element?.subscriptionPlanData.forEach((subelement, index) => {
              const planstartDate = formatDate(subelement.createdAt);
              subscriptiondata.push({
                id: subelement._id ?? "",
                clientId: element._id ?? "",
                firstName: element?.firstName ?? "",
                lastName: element?.lastName ?? "",
                couponCode: subelement?.allAppliedCouponCodes?.map((coupon) => {
                  return coupon?.couponId
                }),
                email: element?.email ?? "",
                companyname: subelement?.companyName ?? "",
                sitename: subelement.careSiteName ?? "",
                siteType:
                  subelement.carefacility === "facalities"
                    ? "Care Facility"
                    : "Care Agency",
                subscriptionid: subelement.finalId ?? "",
                plantype: subelement.SubscriptionPlan ?? "",
                planid: subelement.planId ?? "",
                carehomeId: subelement.carehomeId ?? "",
                startdate: planstartDate,
                trialdaysleft: subelement.trialPeriod,
                activationdate: subelement.planstartDate ? formatDate(subelement.planstartDate) : "---",
                monthlyfees: twoDecimalPlaces(subelement?.total_amount) ?? "",
                currency: subelement?.currency ?? "",
                frequency: subelement.monthyOrYearly ?? "",
                nextbillingdate: formatDate(subelement?.nextBillingDate) ?? "--",
                status:
                  subelement.subscriptionStatus == "true"
                    ? "Active"
                    : subelement.subscriptionStatus == "false"
                      ? "Inactive"
                      : subelement.subscriptionStatus,
                redirectionLink: `/superadmin/clientdetails/${element._id}/subscription/${subelement._id}`,
                // firstname: element.firstName?.trim(),
                // email: element.email,
                // creationDate: element.createdAt,
                // noofSites: element?.subscriptionPlanData.length,
              });
            });
          }
        });
        setrowData(subscriptiondata.reverse());
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  let roleAccess = JSON.parse(localStorage.getItem("__csadmin__d"));

  // -----------  Delete Clients Popup (Show and Hide Handler) Starts ------------ //

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const handleCloseDeletePopup = () => setIsDeleteOpen(false);
  const handleShowDeletePopup = () => setIsDeleteOpen(true);

  // -----------  Unblock Clients Popup (Show and Hide Handler) Starts ------------ //

  const [isUnblockOpen, setIsUnblockOpen] = useState(false);
  const handleCloseUnBlockPopup = () => setIsUnblockOpen(false);
  const handleShowUnblockPopup = () => setIsUnblockOpen(true);

  // ---------------------     delete multiple clients api    ----------------------------------  //
  async function deletemultiple() {
    const deletecaresitesIds = CareSiteIds.filter(
      (item) => item.status === "Active"
    );
    const deleteCareSiteBefore7YearIds = CareSiteIds.filter(
      (item) => item.status === "deactivated"
    );
    if (deletecaresitesIds.length > 0) {
      await dispatch(
        deleteCareSite({
          ids: deletecaresitesIds.map((item) => ({
            ownerId: item.ownerId,
            careSiteId: item.careSiteId,
          })),
          isClientModule: false
        })
      );
    }
    if (deleteCareSiteBefore7YearIds.length > 0) {
      await dispatch(
        deleteCareSiteBefore7Year({
          ids: deleteCareSiteBefore7YearIds.map((item) => ({
            ownerId: item.ownerId,
            careSiteId: item.careSiteId,
          })),
          isClientModule: false
        })
      );
    }
  }

  // ---------------------     unblock clients api    ----------------------------------  //
  async function unBlock() {
    const unblockCareSiteId = CareSiteIds.filter(
      (item) => item.status === "blocked"
    );
    if (unblockCareSiteId.length > 0) {
      await dispatch(
        unblockCareSite({
          subscriptionStatus: 'true',
        })
      );
    } else {
      swal("Failed", "User is already unblocked.", "error");
    }
  }

  useEffect(() => {
    if (deleteSiteCount) {
      try {
        swal(
          deleteSiteStatus ? "Success" : "Failed",
          Array.isArray(deleteSiteMessage)
            ? deleteSiteMessage.join("\n")
            : deleteSiteMessage,
          deleteSiteStatus ? "success" : "error"
        ).then((value) => {
          dispatch(
            backToNormal({
              loading: false,
              CareSiteIds: [],
              selectedIds: [],
              deleteSiteCount: 0,
              deleteSiteMessage: "",
              deleteSiteStatus: false,
            })
          );
          handleCloseDeletePopup();
          dispatch(selectMultipleIds({ ids: [] }));
          adminlisting();
          return;
        });
      } catch (e) {
        swal("Failed", "Oops, Something went wrong", "error");
      }
    }
  }, [deleteSiteCount]);

  useEffect(() => {
    if (deleteCareSiteBefore7YearCount) {
      try {
        swal(
          deleteCareSiteBefore7YearStatus ? "Success" : "Failed",
          Array.isArray(deleteCareSiteBefore7YearMessage)
            ? deleteCareSiteBefore7YearMessage.join("\n")
            : deleteCareSiteBefore7YearMessage,
          deleteCareSiteBefore7YearStatus ? "success" : "error"
        ).then((value) => {
          dispatch(
            backToNormal({
              loading: false,
              CareSiteIds: [],
              selectedIds: [],
              deleteCareSiteBefore7YearCount: 0,
              deleteCareSiteBefore7YearMessage: "",
              deleteCareSiteBefore7YearStatus: false,
            })
          );
          handleCloseDeletePopup();
          dispatch(selectMultipleIds({ ids: [] }));
          adminlisting();
          return;
        });
      } catch (e) {
        swal("Failed", "Oops, Something went wrong", "error");
      }
    }
  }, [deleteCareSiteBefore7YearCount]);

  function selectMultipledata(ids) {
    const arrayOfIds = rowsData
      .filter((item) => ids.includes(item.id))
      .map((item) => ({
        ownerId: item.clientId,
        careSiteId: item.id,
        status: item.status,
      }));
    dispatch(selectMultipleIds({ ids: arrayOfIds }));
  }

  const columns = [
    { field: "firstName", headerName: "First Name", width: 120 },
    { field: "lastName", headerName: "Last Name", flex: 1, minWidth: 120 },
    { field: "email", headerName: "Email", flex: 1, minWidth: 120 },
    { field: "couponCode", headerName: "Coupon", flex: 1, minWidth: 120 },
    {
      field: "companyname",
      headerName: "Company Name",
      flex: 1,
      minWidth: 120,
    },
    { field: "sitename", headerName: "Site Name", flex: 1, minWidth: 120 },
    { field: "siteType", headerName: "Site Type", minWidth: 120 },
    {
      field: "subscriptionid",
      headerName: "Subscription ID",
      flex: 1,
      minWidth: 120,
    },
    { field: "plantype", headerName: "Plan Type", minWidth: 120 },
    { field: "startdate", headerName: "Start Date", minWidth: 120 },
    { field: "activationdate", headerName: "Activation Date", minWidth: 120 },
    { field: "trialdaysleft", headerName: "Trial Days Left", minWidth: 120 },
    { field: "monthlyfees", headerName: "Fees", minWidth: 90 },
    { field: "currency", headerName: "Currency", minWidth: 90 },
    { field: "frequency", headerName: "Sub. Frequency", minWidth: 90 },
    {
      field: "nextbillingdate",
      headerName: "Next Billing Date",
      minWidth: 120,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (fieldData) => <StatusBtn fieldData={fieldData} />,
    },
    {
      id: "arrow",
      width: 50,
      numeric: true,
      disablePadding: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (fieldData) => <RedirectionIcon fieldData={fieldData} />,
    },
  ];

  const filteredData = filterData(
    columns?.map((item) => ({
      id: item.field,
      label: item.headerName,
    })),
    rowsData,
    searchTxt
  );

  return (
    <>
      <div className="page-wrapper" style={{ height: "90vh" }}>
        {roleAccess?.role?.map((roletype) => {
          const clientSectionAccess = roletype.Modules[2];
          if (clientSectionAccess?.access !== "full")
            return <div className="clickOff"></div>;
        })}
        <div className="card">
          <div className="card-body">
            <div className="row mb-2 justify-content-end">
              <div className="searchForm careSearch">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  value={searchTxt}
                  onChange={(e) => setSearchTxt(e.target.value)}
                />
                <BsSearch className="icon me-2" />
              </div>
              <div className="col-md-6">
                <div className="float-end btns_head d-flex">
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      Action
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          if (CareSiteIds.length >= 1)
                            handleShowDeletePopup(true);
                          else
                            swal(
                              "Failed",
                              "Please Select at least one client",
                              "error"
                            ).then((value) => {
                              return;
                            });
                        }}
                      >
                        Delete
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          if (CareSiteIds.length >= 1)
                            handleShowUnblockPopup(true);
                          else
                            swal(
                              "Failed",
                              "Please Select at least one client",
                              "error"
                            ).then((value) => {
                              return;
                            });
                        }}
                      >
                        Unblock
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>

            <DataGridTable
              columns={columns}
              rows={filteredData}
              handleRowSelection={selectMultipledata}
            />
          </div>
        </div>
      </div>

      <Modal
        className="viewModal"
        show={isDeleteOpen}
        onHide={handleCloseDeletePopup}
      >
        <Modal.Header>
          <Modal.Title>
            <span>Delete Client</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="">
                <p>
                  Are you sure you want to delete this{" "}
                  {CareSiteIds.length > 1 ? "sites" : "site"} ?
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex">
            <button className="btn submitBtn" onClick={handleCloseDeletePopup}>
              No
            </button>
            <button
              className="btn submitBtn"
              onClick={() => {
                deletemultiple();
              }}
            >
              Yes
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        className="viewModal"
        show={isUnblockOpen}
        onHide={handleCloseUnBlockPopup}
      >
        <Modal.Header>
          <Modal.Title>
            <span>Unblock Client</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="">
                <p>
                  Are you sure you want to unblock this{" "}
                  {CareSiteIds.length > 1 ? "sites" : "site"} ?
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex">
            <button className="btn submitBtn" onClick={handleCloseUnBlockPopup}>
              No
            </button>
            <button
              className="btn submitBtn"
              onClick={() => {
                unBlock();
              }}
            >
              Yes
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const RedirectionIcon = (props) => {
  const { fieldData } = props;
  const history = useHistory();
  return (
    <IoIosArrowForward
      style={{ cursor: "pointer", fontSize: "20px" }}
      onClick={() => {
        history.push(fieldData.row?.redirectionLink);
      }}
    />
  );
};

const StatusBtn = (props) => {
  const { fieldData } = props;
  const status = fieldData?.row?.status;
  return (
    <button
      className={`btn table_btn btn-${status === "Active" ? "success" : "danger"
        } btn-sm`}
    >
      {status ?? "-"}
    </button>
  );
};
