import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetch3, fetch4 } from "../../Apis/commonApis";
//security_question / approval_email / false
export let getCareSiteListing = [];
export let loginOwnerId;
export let currentCareSiteId = localStorage?.getItem("currentCareSiteId");
export let userData = JSON.parse(localStorage.getItem("userData"));
export let care_admin_token = localStorage.getItem("care_admin_token");

const initialState = {
  loading: false,
  careSitelistingCount: 0,
  careSitelistings: [],
  selectedCareSiteId: "",
  selectedCareSiteName: "",
  loginVerifyEmail: 0,
  captureBeforeSwitchCareSiteId: "",
  admincareSiteId: "",
  adminUserData: "",
  adminToken: "",
};

export const careSitelisting = createAsyncThunk(
  "careSitelisting",
  async ({ id }) => {
    const result = await fetch3(
      `${process.env.REACT_APP_BASEURL}/careHomelisting`,
      "get"
    );
    // localStorage.setItem("carehomeId", id);
    localStorage.setItem("currentCareSiteId", id);
    getCareSiteListing = result.listing;
    return result;
  }
);

export const switchCareSiteApi = createAsyncThunk(
  "switchCareSite",
  async (body) => {
    const result = await fetch3(
      `${process.env.REACT_APP_BASEURL}/switchCareSite?userId=${body.userId}&careSiteId=${body.careSiteId}`,
      "get"
    );
    const switechedcaresite = result.data;
    if (switechedcaresite) {
      localStorage.setItem("userData", JSON.stringify(result.data));
    }
    return result;
  }
);

export const updateCurrentCareSite = createAsyncThunk(
  "editadmin",
  async (body) => {
    const result = await fetch4(
      `${process.env.REACT_APP_BASEURL}/editadmin?adminId=${body.userId}`,
      { currentCareSiteId: body.careSiteId }
    );
    const currentCareSiteId = result;
    if (currentCareSiteId.status && currentCareSiteId) {
      localStorage.setItem("userData", JSON.stringify(result.result));
    }
    return result;
  }
);

const CareSiteSlice = createSlice({
  name: "CareSiteSlice",
  initialState,
  reducers: {
    captureSwitchCareSiteId(state = initialState, action) {
      state.captureBeforeSwitchCareSiteId = action.payload.id;
    },
    currentCareSiteIdHandler(state = initialState, action) {
      currentCareSiteId = action.payload.currentCareSiteId;
      state.admincareSiteId = action.payload.currentCareSiteId;
      localStorage.setItem(
        "currentCareSiteId",
        action.payload.currentCareSiteId
      );
    },
    currentCareSiteDataHandler(state = initialState, action) {
      userData = action.payload.userData;
      state.adminUserData = action.payload.userData;
      localStorage.setItem("userData", JSON.stringify(action.payload.userData));
    },
    currentCareSiteTokenHandler(state = initialState, action) {
      care_admin_token = action.payload.care_admin_token;
      localStorage.setItem("care_admin_token", action.payload.care_admin_token);
      state.adminToken = action.payload.care_admin_token;
    },
    selectCareSite(state = initialState, action) {
      const id = getCareSiteListing?.find(
        (item) => item._id === action.payload.id
      );
      if (id) {
        state.selectedCareSiteName = id.careSiteName;
        state.selectedCareSiteId = action.payload.id;
      }
    },
  },
  extraReducers: {
    [careSitelisting.fulfilled]: (state, { payload }) => {
      console.log(payload, '99999999999999999')
      if (payload.status) {
        state.careSitelistings = payload.listing;
      }
      state.loading = false;
      state.careSitelistingCount += 1;
    },

    [switchCareSiteApi.fulfilled]: (
      state,
      { payload: { error, message, status } }
    ) => {
      console.log(message, status, error, "switchCareSiteApi response");
      if (status) {
        state.registerSuccess = 2;
        state.verifySuccessLogin = 2;
      } else {
        state.errorMsg = message;
        state.registerSuccess = 1;
        state.verifySuccessLogin = 1;
      }
      if (
        message.includes("User successfully authenticated") &&
        !state.signupVerification
      ) {
        state.loginVerifyEmail = 2;
        // loginOwnerId = undefined;
      }
    },
  },
});

export const {
  getCareSiteId,
  currentCareSiteIdHandler,
  selectCareSite,
  captureSwitchCareSiteId,
  currentCareSiteDataHandler,
  currentCareSiteTokenHandler,
} = CareSiteSlice.actions;
export default CareSiteSlice.reducer;
