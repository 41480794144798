import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./forgot-password.css";
import { httpAdmin } from "../../Apis/commonApis";
import swal from "sweetalert";

function Forgotpassword() {
  const [email, setEmail] = useState("");

  async function approveEmailForgotPassword() {
    const result = await httpAdmin(
      "forgotPassOrSecQuestions",
      "post",
      {
        email,
        whatForgot: "password",
      },
      null
    );

    if (result.status) {
      swal("Success", result.message, "success").then((value) => {
        // dispatch(getSettingList(userData._id));
      });
    }
  }

  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      approveEmailForgotPassword();
    }
  };

  return (
    <section className="login_section">
      <div className="login_header">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 logo_box">
              <NavLink className="navbar-brand" to="">
                <b className="logo-icon">
                  <img alt="" src={`/images/Newfooter_Logo.svg`} />
                </b>
              </NavLink>
            </div>
            <div className="col-md-9">
              <div className="login_title">
                <h1>Care Management Simplified!</h1>
                <p>
                  Welcome to your new experience of care management. Our
                  powerful, easy to use <br/> and intuitive care platform, enables you
                  to easily manage all you care tasks.
                </p>
              </div>
            </div>
            <div className="col-md-3 img_box">
              <img alt="" src={`/images/NewhomeRightside.svg`} />
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 form_box">
        <form>
          <h4>Forgot Password</h4>
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyUp={(e) => {
              handleKeypress(e);
            }}
            type="email"
            className="form-control"
            placeholder="Email"
          />{" "}
          <button
            type="button"
            className="form-control btn"
            onClick={approveEmailForgotPassword}
          >
            Send
          </button>
        </form>
        <div className="option_box">
          <p>
            Already have an account?{" "}
            <NavLink to="/login" className="">
              Log in
            </NavLink>
          </p>
        </div>
      </div>
    </section>
  );
}

export default Forgotpassword;
