import React, { useState } from "react";
import "../Contact/contact.css";
import axios from "axios";
import { useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useHistory } from "react-router-dom";
import EmbeddedHTML from "./embedContactForm";

function Contact() {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    unregister,
    reset,
    formState: { errors },
  } = useForm();

  // ==========================  Phone Number Input Library COde  =============== //
  const [isCountryCode, setcuntrycode] = useState("");
  const [isDialCode, setDialCode] = useState("");
  const [mobileNo, setmobileNo] = useState("");
  function phoneInput(value, data) {
    setmobileNo(value);
    setDialCode(`+${data.dialCode}`);
    setcuntrycode(`${data.countryCode}`);
  }
  // ==========================  Phone Number Input Library COde  =============== //

  function ContactUs({
    firstName,
    lastName,
    email,
    companyName,
    department,
    subject,
    refrence,
    message,
    countryCode, 
  }) {
    let data = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      mobileNo:  mobileNo,
      companyName: companyName,
      department: department,
      refrence: refrence,
      subject: subject,
      message: message,
      country_code:countryCode
    };

    // console.log("mobileNo Country code ------------------>",countryCode)

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_SUPERADMIN_BASEURL}/createContactus`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    console.log(data);

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        reset();
        history.replace("/");
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <>
      <section className="mt-5 pagespace_section resp_pagespace_section">
        <div className="container">
          <div className="title">
            <h3>Get In Touch. Let’s Connect.</h3>
          </div>
        </div>
      </section>

      <section className="map_section resp_contact_map_section">
        <div className="container">
          <div className="col-md-7 margin_auto">
            {/* <iframe className="map" title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d227748.3825634848!2d75.65047013538644!3d26.88544791745257!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396c4adf4c57e281%3A0xce1c63a0cf22e09!2sJaipur%2C%20Rajasthan!5e0!3m2!1sen!2sin!4v1640086471422!5m2!1sen!2sin" allowfullscreen="" loading="lazy"></iframe> */}

            <div className="row">
              <div className="col-md-6 add_box m-auto">
                <h5 className="text-center">United Kingdom</h5>
                <p>
                  Wearfield, Enterprise Park East, Sunderland, Tyne and Wear,
                  SR5 2TA.United Kingdom.
                </p>
              </div>
              {/* <div className="col-md-6 add_box">
                <h5>Canada</h5>
                <p>5 Marine Parade Drive, Toronto, Ontario, M8V 4B4, Canada</p>
              </div> */}
            </div>
          </div>
        </div>
      </section>

      <section className="contact_section">
        <div className="container">
          <div className="contact_box col-md-10 margin_auto">
            <div className="title">
              <h3>Contact Us</h3>
              {/* <p>info@aurigamagnuscare.com </p> */}
            </div>
            <hr />
            <EmbeddedHTML />
          </div>
        </div>
      </section>
    </>
  );
}
export default Contact;
