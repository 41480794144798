import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "../../websitestyle.css";
import { GiHamburgerMenu } from "react-icons/gi";
import axios from "axios";
import { countryList } from "../../constants/constants";
import countryLists from "../../admin/Signup/countries.json";
import { fetchUserIpData } from "../../redux-toolkit/reducer/websitePlan";
import { useDispatch, useSelector } from "react-redux";

function Header() {
  // const URL = "https://ip.nf/me.json";
  const { userIpData } = useSelector((state) => state.websitePlans);
  const dispatch = useDispatch()

  const [info, setInfo] = useState(null);
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchUserIpData());
    // const bannerscript = document.createElement('script');
    // bannerscript.src = 'https://cdn-cookieyes.com/client_data/998f68ed8a6d140b7950b1d0/script.js';
    // bannerscript.type = "text/javascript";
    // bannerscript.id = "cookieyes";
    // console.log(bannerscript, 'dddddddddd')
    // document.body.appendChild(bannerscript);
    // return () => {
    //   console.log('removinggggggggg')
    //   document.body.removeChild(bannerscript);
    // };
  }, []);

  useEffect(() => {
    if (userIpData) {
      let userCountry = countryLists?.countries?.find(item => {
        return item?.isoAlpha2?.includes(userIpData?.country);
      });
      setInfo(userCountry ?? countryList[0]);
    }
  }, [userIpData]);

  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  const handleCalendlyPopup = () => {
    if (window.Calendly) {
      window.Calendly.initPopupWidget({
        url: 'https://calendly.com/caremagnus-support?hide_gdpr_banner=1&primary_color=f2994a'
      });
    } else {
      console.error('Calendly is not loaded yet');
    }
    return false;
  };


  return (
    <header className="main_header header_fix">
      <div className="websitetopbar">
        <div className="container">
          <div className="row">
            <div className="col-md-6"></div>
            <div className="col-md-6">
              <ul className="topbar_list">
                <li>Region : </li>
                <li>
                  <div className="dropdown">
                    <button
                      className="btn"
                      type="button"
                    >
                      {/* {info?.name?.toLowerCase() && <img id="RegionFlag" alt="" src={require(`../../images/flags/${info?.isoAlpha2?.toLowerCase()}.svg`)} />}{" "} */}
                      {
                        info?.name?.toLowerCase() && (() => {
                          const flagContext = require.context(
                            "../../images/flags/",
                            true,
                            /\.svg$/ // Pattern to match SVG files
                          );

                          const isoAlpha2FlagPath = `./${info?.isoAlpha2?.toLowerCase()}.svg`;
                          const nameFlagPath = `./${info?.name?.toLowerCase()}.svg`;

                          // Check if the flag image for isoAlpha2 exists
                          if (flagContext.keys().includes(isoAlpha2FlagPath)) {
                            const isoAlpha2Flag = flagContext(isoAlpha2FlagPath);
                            return <img id="RegionFlag" alt="" src={isoAlpha2Flag} />;
                          }

                          // Check if the flag image for name exists
                          if (flagContext.keys().includes(nameFlagPath)) {
                            const nameFlag = flagContext(nameFlagPath);
                            return <img id="RegionFlag" alt="" src={nameFlag} />;
                          }

                          // If neither image found, return nothing
                          return null;
                        })()
                      }

                      <span id="RegionName">{info?.name}</span>
                    </button>
                    {/* <ul
                      className="dropdown-menu dropdown-menu-end"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li
                        data-name="United States"
                        data-img={`${process.env.PUBLIC_URL}/images/r1.svg`}
                      >
                        <img
                          alt=""
                          src={`${process.env.PUBLIC_URL}/images/us_flag.svg`}
                        />{" "}
                        United States{" "}
                      </li>
                    </ul> */}
                  </div>
                </li>
                <li>
                  <a href="#" onClick={handleCalendlyPopup} style={{color: '#F2994A', fontWeight: '700'}}>
                    Book a Demo
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <nav className="navbar navbar-expand-lg ak_menu">
        <div className="mobile_topbar"></div>
        <div className="container">
          <NavLink className="navbar-brand" to="/">
            <img
              alt=""
              src={`${process.env.PUBLIC_URL}/images/Newfooter_Logo.svg`}
            />
          </NavLink>
          <button className="navbar-toggler mobile_menu" onClick={toggleNavbar}>
            <GiHamburgerMenu />
          </button>
          <div
            className={`collapse navbar-collapse ${isNavbarOpen ? "boxOpen" : ""
              }`}
          >
            <div className="menu_box">
              <div className="mobile_logo">
                <NavLink to="" className="mobile_close" onClick={toggleNavbar}>
                  ×
                </NavLink>
              </div>
              <ul className="navbar-nav link_font_family">
                <li className="nav-item">
                  <NavLink
                    exact
                    to="/"
                    className="nav-link"
                    onClick={toggleNavbar}
                  >
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/segments"
                    className="nav-link"
                    onClick={toggleNavbar}
                  >
                    Segments
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/features"
                    className="nav-link"
                    onClick={toggleNavbar}
                  >
                    Features
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/plans"
                    className="nav-link"
                    onClick={toggleNavbar}
                  >
                    Plans
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/contact"
                    className="nav-link"
                    onClick={toggleNavbar}
                  >
                    Contact Us
                  </NavLink>
                </li>
                <li className="nav-item">
                  <a
                    href="https://caremagnus.com/blogs/"
                    className="nav-link"
                    onClick={toggleNavbar}
                  >
                    Blog
                  </a>
                </li>
                <li className="nav-item login_signup">
                  <NavLink to="/login" className="nav-link">
                    Log-In
                  </NavLink>{" "}
                  {/* <span>/</span>
                  <Link onClick={register} className="nav-link">
                    Register
                  </Link> */}
                </li>
              </ul>
            </div>

            <div className="hide_box mobile_close" onClick={toggleNavbar}></div>
          </div>
        </div>
      </nav>
    </header>
  );
}
export default Header;
