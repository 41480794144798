import React, { useState, useEffect } from "react";
import axios from "axios";
import cx from "./subscriptiondetails.module.css";
import { useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useHistory } from "react-router-dom";
import DataGridTable from "../../utils/DataGridTable";
import { formatDate } from "../../utils/CommonFunctions";
import { ShowSwal } from "../../utils/alertSwal";
import moment from "moment";
import { twoDecimalPlaces } from "../../utils/utilsHandlerFunctions";

const SubscriptionDetails = () => {
  const params = useParams();
  const history = useHistory();
  const [show2, setShow2] = useState(false);
  const [rowsData, setrowData] = useState([]);
  const [planData, setplanData] = useState([]);
  const [planStartDate, setplanStartDate] = useState("");
  const [activationDate, setActivationDate] = useState("");
  const [trialExpDate, setTrialExpDate] = useState("");
  const [subExpDate, setSubExpDate] = useState("");
  const [changeTrExpDt, setChangeTrExpDt] = useState(false);
  const [adminId, setAdminId] = useState("");
  const [manualSwitch, setManualSwitch] = useState(false);
  const handleClose2 = () => {
    setShow2(false);
    setChangeTrExpDt(false);
  };
  const handleShow2 = (type) => {
    if (type === "trial") {
      setChangeTrExpDt(true);
    } else {
      setChangeTrExpDt(false);
    }
    setShow2(true);
  };

  const redirectToClient = () => {
    //Redirect to another route
    history.push(
      `/superadmin/clientdetails/${planData?.companyName}/${params.id}/overview`
    );
  };

  useEffect(() => {
    subsDetails();
  }, []);

  function handleSubmit(type) {
    if (type === "trial") {
      let stDate = new Date(planStartDate);
      let expDate = new Date(trialExpDate);
      let stMilis = stDate.getTime();
      let expMilis = expDate.getTime();

      if (expMilis > 0) {
        changePlanDate(stMilis, expMilis, 0);
      }
    } else {
      if (subExpDate === "Indefinite") {
        changePlanDate(0, 0, subExpDate);
      } else {
        let subDt = new Date(subExpDate);
        let subMilis = subDt.getTime();
        if (subMilis > 0) {
          changePlanDate(0, 0, subMilis);
        }
      }
    }
    handleClose2();
  }

  function subsDetails() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_SUPERADMIN_BASEURL}/getCareSitewithCoupon?careSiteId=${params.subId}`, //63a99df1afc3d3918bc13600 ${careHomeId}
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("superadmin_token"),
      },
    };
    axios(config)
      .then(function (res) {
        let subPlanData = res.data.result[0]?.subscriptionPlanData;
        let eligibleCouponData = res.data.result[0]?.couponData;
        let subExp = subPlanData?.planExpiryDate;
        if (subPlanData?.planExpiryDate === "Indefinite") {
          subExp = "Indefinite";
        }
        let rowdata = [];
        const chargdetail = subPlanData?.chargesDetails;

        chargdetail?.forEach((element, index) => {
          let detailType = index == 0 ? "Plan" : "Add-On";
          if (index !== 2) {
            rowdata.push({
              id: index,
              type: detailType,
              productName: element?.discription,
              productID: index == 0 ? subPlanData?.SubscriptionPlanId : "FF01",
              qty: chargdetail[index]?.qty,
              unitPrice: +chargdetail[index]?.rate,
              price: +chargdetail[index]?.rate,
              taxRate: +res.data.result[0]?.taxRate,
              sum: +chargdetail[index]?.rate * chargdetail[index]?.qty,
              tax: `+${+subPlanData?.taxRate}%`,
              totalPrice: +subPlanData?.total_amount,
              lastBillingDate: subPlanData?.lastBillingDate
                ? formatDate(subPlanData?.lastBillingDate)
                : "-",
              nextBillingDate: subPlanData?.nextBillingDate
                ? formatDate(subPlanData?.nextBillingDate)
                : "-",
            });
          }
        });

        eligibleCouponData?.forEach((elem, index) => {
          // const discountSum =
          //   elem.discountType === "Fixed"
          //     ? elem.discountValue
          //     : (subtotalAmt / 100) * elem.discountValue;
          const discountSum = elem.discountValue
          rowdata.push({
            id: elem._id,
            remainingCounter: elem?.remainingCounter,
            type: "Coupon",
            productName: "Dicount Coupon",
            productID: elem?.couponId,
            qty: 1,
            // unitPrice:
            //   elem.discountType === "Fixed"
            //     ? elem.discountValue
            //     : `${elem.discountValue}%`,
            unitPrice: elem.discountValue,
            price: elem.discountValue,
            taxRate: +res.data.result[0]?.taxRate,
            sum: -discountSum,
            tax: 0,
            totalPrice: +subPlanData?.total_amount,
            lastBillingDate: subPlanData?.lastBillingDate
              ? formatDate(subPlanData?.lastBillingDate)
              : "-",
            nextBillingDate: subPlanData?.nextBillingDate
              ? formatDate(subPlanData?.nextBillingDate)
              : "-",
          });
        });
        setAdminId(subPlanData.adminId);
        setplanStartDate(formatDate(subPlanData.createdAt));
        setActivationDate(formatDate(subPlanData.planstartDate))
        setTrialExpDate(subPlanData.trialExpiry);
        setSubExpDate(subExp);
        setrowData(rowdata);
        setplanData(subPlanData);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  function changePlanDate(stMilis, expMilis, subMilis) {
    if (expMilis > 0) {
      var data = JSON.stringify({
        careSiteId: params.subId,
        adminId: adminId, //6319bee4690a9d8c518b1759
        paymentThrough: planData.paymentThrough,

        [planData.paymentThrough === "gocardless"
          ? "goCardlessSubId"
          : "stripeSubscriptionId"]:
          planData?.paymentThrough === "gocardless"
            ? planData?.goCardLess_subscription_id
            : planData?.stripe_subscription_id,
        trialExpiryDate: expMilis,
      });
    } else {
      var data = JSON.stringify({
        careSiteId: params.subId,
        adminId: adminId, //6319bee4690a9d8c518b1759
        subExpiryDate: subMilis,
      });
    }
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_SUPERADMIN_BASEURL}/editSubAndTrialExpiry`,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("superadmin_token"),
      },
      data: data,
    };
    axios(config)
      .then((res) => {
        ShowSwal(res.data.status, res.data.message);
        subsDetails();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleManualSwitch = (event) => {
    setManualSwitch(event.target.checked);
    event.target.checked ? setSubExpDate("Indefinite") : setSubExpDate("");
  };

  function calculateTotalValue(subtotal, taxrate) {
    subtotal = +subtotal || 0;
    taxrate = +taxrate || 0;
    return subtotal + (subtotal * taxrate) / 100;
  }

  const columns = [
    { field: "type", headerName: "Type", width: 120 },
    {
      field: "productName",
      headerName: "Product Name",
      flex: 1,
      minWidth: 170,
    },
    { field: "productID", headerName: "Product ID", flex: 1, minWidth: 150 },
    { field: "qty", headerName: "Qty", minWidth: 100 },
    { field: "remainingCounter", headerName: "Remaining Counter", minWidth: 150 },
    {
      field: "unitPrice",
      headerName: "Unit Price",
      flex: 1,
      minWidth: 130,
      renderCell: (fieldData) => (
        <DisplayDecimal value={fieldData.row.unitPrice} />
      ),
    },
    {
      field: "sum",
      headerName: "Sum",
      flex: 1,
      minWidth: 130,
      renderCell: (fieldData) => <DisplayDecimal value={fieldData.row.sum} />,
    },
    {
      field: "lastBillingDate",
      headerName: "Last Billing date",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "nextBillingDate",
      headerName: "Next Billing Date",
      flex: 1,
      minWidth: 150,
    },
  ];

  const subTotalVal = rowsData?.reduce((acc, item) => {
    if (acc + item.sum < 0) {
      return 0;
    } else {
      return acc + item.sum;
    }
  }, 0);

  return (
    <div className="page-wrapper">
      <div className="card">
        <div className="card-body">
          <div className="d-flex">
            <h3>{planData?.companyName} </h3>
            <a className="ms-3 mt-1" onClick={redirectToClient}>
              Client Details
            </a>
          </div>
          <h4>{planData?.careSiteName}</h4>
          <div className="row justify-content-between">
            <div className={`col-12 col-md-5 col-lg-5 ${cx.cardBox}`}>
              <h5>Subscription Details</h5>
              <ul>
                <li>Subscription ID: {planData?.finalId}</li>
                <li>Subscription Start Date: {planStartDate}</li>
                <li>
                  Subscription Activation Date:{" "}
                  {activationDate}
                </li>
                <li className="d-flex justify-content-between">
                  Subscription Expiry Date: {subExpDate}{" "}
                  <a href="#" onClick={() => handleShow2("subscription")}>
                    Change
                  </a>
                </li>
                <li>
                  Subscription Renewal Frequency: {planData?.monthyOrYearly}
                </li>
                <li>
                  Subscription Price Total:{" "}
                  {twoDecimalPlaces(
                    calculateTotalValue(subTotalVal, rowsData?.[0]?.taxRate)
                  )}
                </li>
                <li>
                  Payment method:{" "}
                  {planData?.paymentThrough === "gocardless" ? (
                    <>Direct Debit</>
                  ) : (
                    <>Credit Card</>
                  )}
                </li>
              </ul>
            </div>
            <div className={`col-12 col-md-5 col-lg-6 ${cx.cardBox}`}>
              <h5>Site Details</h5>
              <ul>
                <div className="d-flex">
                  <li className="w-50">Site Name: {planData.careSiteName}</li>
                  <li className="w-50">
                    <a>Site No: </a>
                  </li>
                </div>
                <li>Address: {planData.billingAddress}</li>
              </ul>
            </div>
            <div className={`col-12 col-md-5 col-lg-5 ${cx.cardBox}`}>
              <h5>Plan Details</h5>
              <ul>
                <li>
                  Plan Type: {planData.SubscriptionPlan}
                  {planData.trialStatus ? ` TRIAL` : ''}
                </li>
                <li>Plan ID: {planData.SubscriptionPlanId}</li>
              </ul>
            </div>
            <div className={`col-12 col-md-5 col-lg-6 ${cx.cardBox}`}>
              <h5>Trial Details</h5>
              <ul>
                <li>Trial Status: {planData.trialStatus ? 'Active' : 'In-Active'}</li>
                <div className="d-flex justify-content-between">
                  <li>Trial Start Date: {planStartDate}</li>
                  <li>
                    <a>Total Trial Days: {planData?.totalTrialDays} </a>
                  </li>
                </div>
                <div className="d-flex justify-content-between">
                  <li className="d-flex justify-content-between">
                    Trial Expiry Date:{" "}
                    <span> {formatDate(trialExpDate)} </span>{" "}
                    <a href="#" onClick={() => handleShow2("trial")}>
                      Change
                    </a>
                  </li>

                  <li className="ms-2">
                    Trial Days Left: {planData.trialPeriod}
                  </li>
                </div>
              </ul>
            </div>
          </div>
          <h4>Included in Subscription (Plan - Add-On - Discount Coupon)</h4>
          <DataGridTable
            columns={columns}
            tableHeight="35vh"
            rows={rowsData}
            handleRowSelection={() => { }}
          />
          <div className="row mt-5">
            <div className="col-md-8"></div>
            <div className="col-md-4">
              <table className="table">
                <thead>
                  <tr>
                    <th style={{ color: "#222" }}>SubTotal</th>
                    <td>{twoDecimalPlaces(subTotalVal)}</td>
                  </tr>
                  <tr>
                    <th style={{ color: "#222" }}>Tax(%)</th>
                    <td>{twoDecimalPlaces(rowsData?.[0]?.taxRate)}</td>
                  </tr>
                  <tr>
                    <th style={{ color: "#222" }}>Total</th>
                    <td>
                      {twoDecimalPlaces(
                        calculateTotalValue(subTotalVal, rowsData?.[0]?.taxRate)
                      )}
                    </td>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Modal className="viewModal" show={show2} onHide={handleClose2}>
        <Modal.Header>
          <Modal.Title>
            <span>Edit</span>
            <div className="d-flex">
              <button className="btn" onClick={handleClose2}>
                Close
              </button>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "10px 20px" }}>
          <form>
            <div className="row">
              {!changeTrExpDt && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={manualSwitch}
                      onChange={handleManualSwitch}
                    />
                  }
                  label="Set Indefinite"
                  className="mb-2"
                />
              )}
              {!manualSwitch && !changeTrExpDt && (
                <div className="col-md-12 mb-3">
                  <label className="mb-1">Set Date</label>
                  <div className="d-flex">
                    <input
                      type="date"
                      className="form-control"
                      value={subExpDate}
                      onChange={(e) => {
                        setSubExpDate(e.target.value);
                      }}
                    />
                  </div>
                </div>
              )}
              {manualSwitch && !changeTrExpDt && (
                <div className="col-md-12 mb-3">
                  <select className="form-select">
                    <option value="Indefinite">Indefinite</option>
                  </select>
                </div>
              )}
              {changeTrExpDt && (
                <div className="col-md-12 mb-3">
                  <label className="mb-1">Set Date</label>
                  <div className="d-flex">
                    <input
                      type="date"
                      className="form-control"
                      value={trialExpDate}
                      onChange={(e) => {
                        setTrialExpDate(e.target.value);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex">
            {changeTrExpDt ? (
              <button
                className="btn"
                type="button"
                onClick={() => handleSubmit("trial")}
              >
                Save
              </button>
            ) : (
              <button
                className="btn"
                type="button"
                onClick={() => handleSubmit("subscription")}
              >
                Save
              </button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default SubscriptionDetails;

const DisplayDecimal = (props) => {
  return <>{twoDecimalPlaces(props.value)}</>;
};
