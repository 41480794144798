import { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import axios from "axios";
import "./UserRoleManagement.css";
import Select from "react-select";
import { modules } from "../../constants/roles";
import { httpAdmin } from "../../Apis/commonApis";
import { ShowSwal } from "../../utils/alertSwal";

const UpdateUserRoleManagement = forwardRef((props, ref) => {
  const updatedroles = props?.updatedData?.role[0]?.Modules;
  const [selectedOptiongroup, setselectedOptiongroup] = useState(updatedroles);
  const [selectedCareSites, setselectedCareSites] = useState([])
  const [roleErrorStatus, setRoleErrorStatus] = useState(null);
  const [firstName, seteditfirstNames] = useState(props.updatedData?.firstName);
  const [lastName, seteditlastName] = useState(props.updatedData?.lastName);
  const [mobileNo, seteditmobileNo] = useState(props.updatedData?.mobileNo);
  useEffect(() => {
    if (props.refresh) {
      updateStudentData(); //children function of update roles
    }
  }, [props.refresh]);

  useEffect(() => {
    if (selectedOptiongroup.length > 0) {
      setRoleErrorStatus(false);
    }
  }, [selectedOptiongroup]);

  const updateStudentData = async () => {
    if (props.refresh != 0) {
      let roles = [
        {
          roleId: props?.updatedData?.role[0].roleId,
          Modules: selectedOptiongroup,
        },
      ];
      const mapSelectedSites = selectedCareSites.map(item => ({ caresiteId: item.value }));

      let data = {
        // email: props.updatedData?.email,
        firstName: firstName,
        lastName: lastName,
        subscriptionPlanData: mapSelectedSites,
        mobileNo: mobileNo,
        userType: props.updatedData.userType,
        role: roles,
      };
      const result = await httpAdmin(`editadmin?adminId=${props.updatedData?._id}`, 'post', data, null);
      if (result.status) {
        props.onEditDataFunction();
        props.handleClose();
        setRoleErrorStatus(false);
      }
      ShowSwal(result.status, result.message);
    }
  }

  function customRolesChangeHandler(perStatus, subModuleName, moduleName) {
    if (moduleName) {
      setselectedOptiongroup((prevState, index) => {
        let customedData = prevState.map((moduleRole) => {
          if (moduleName == moduleRole.moduleName) {
            if (moduleRole && moduleRole.children) {
              const isFullAccess = moduleRole.children?.some(elem => (elem.access === 'full' || elem.access === 'view'));
              moduleRole["access"] = isFullAccess ? 'full' : 'no';
              const subChildren = moduleRole.children.map((subModuleRole) => {
                if (subModuleName == subModuleRole.moduleName) {
                  subModuleRole["access"] = perStatus;
                }
                return subModuleRole;
              });
              moduleRole["children"] = subChildren;
            } else {
              moduleRole["access"] = perStatus;
            }
          }
          return moduleRole;
        });
        return customedData;
      });
    }
    // setselectedOptiongroup()
  }

  const handleSiteChange = (event) => {
    setselectedCareSites(event);
  };

  useEffect(() => {
    const formattedUsers = props?.updatedData?.subscriptionPlanData?.map((careSite) => ({
      label: careSite?.careSiteName,
      value: careSite?._id,
    }));
    setselectedCareSites(formattedUsers);
  }, [props.updatedData]);

  return (
    <>
      <div className="row">
        <div className="col-md-12 mb-3">
          <label className="form-label">First Name</label>
          <div className="position-relative">
            <input
              type="text"
              defaultValue={firstName}
              key={props.updatedData?.firstName}
              onChange={(e) => {
                seteditfirstNames(e.target.value);
              }}
              className="form-control"
            />
            <div className="hint_box" style={{ display: "block" }}></div>
          </div>
        </div>
        <div className="col-md-12 mb-3">
          <label className="form-label">Last Name</label>
          <div className="position-relative">
            <input
              type="text"
              defaultValue={lastName}
              key={props.updatedData?.lastName}
              className="form-control"
              onChange={(e) => {
                seteditlastName(e.target.value);
              }}
            />
            <div className="hint_box" style={{ display: "block" }}></div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mb-3">
          <label className="form-label">Email Address</label>
          <div className="position-relative">
            <input
              disabled
              type="text"
              defaultValue={props.updatedData?.email}
              key={props.updatedData?.email}
              className="form-control"
            />
            <div className="hint_box" style={{ display: "block" }}></div>
          </div>
        </div>
        <div className="col-md-12 mb-3">
          <label className="form-label">Contact Number</label>
          <div className="position-relative">
            <input
              type="number"
              defaultValue={mobileNo}
              key={props.updatedData?.mobileNo}
              onChange={(e) => {
                seteditmobileNo(e.target.value);
              }}
              className="form-control"
            />
            <div className="hint_box" style={{ display: "block" }}></div>
          </div>
        </div>
        <div className="col-md-12 mb-3">
          <label className="form-label">Care Site</label>
          <Select
            isMulti
            options={props?.careSites}
            value={selectedCareSites}
            onChange={(e) => handleSiteChange(e)}
          />
          {/* {roleErrorStatus && (
            <p className="error_style">This field is required</p>
          )} */}
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mb-3">
          <label className="form-label">Custom Roles</label>
          <div className="position-relative category">
            {updatedroles.length > 0 &&
              updatedroles?.map((customroles, customIndex) => {
                let submodule = "";
                if (customroles && customroles.children) {
                  submodule = customroles.children?.map(
                    (subcroles, subcroleIndex) => {
                      return (
                        <div className="subcategorychild">
                          <div>{subcroles.moduleName}</div>
                          {"  "}
                          <div className="lableText d-flex">
                            {" "}
                            <div>
                              <label>full access</label>{" "}
                              <input
                                style={{ width: '100px' }}
                                name={subcroles.moduleName + customIndex}
                                type="radio"
                                value="full"
                                defaultChecked={subcroles?.access === "full"}
                                onChange={(e) => {
                                  customRolesChangeHandler(
                                    e.target.value,
                                    subcroles.moduleName,
                                    customroles.moduleName
                                  );
                                }}
                              />
                            </div>
                            <div>
                              <label>view access</label>{" "}
                              <input
                                style={{ width: '100px' }}
                                name={subcroles.moduleName + customIndex}
                                type="radio"
                                value="view"
                                defaultChecked={subcroles?.access === "view"}
                                onChange={(e) => {
                                  customRolesChangeHandler(
                                    e.target.value,
                                    subcroles.moduleName,
                                    customroles.moduleName
                                  );
                                }}
                              />
                            </div>
                            <div>
                              <label>no access</label>{" "}
                              <input
                                style={{ width: '100px' }}
                                name={subcroles.moduleName + customIndex}
                                type="radio"
                                value="no"
                                defaultChecked={subcroles?.access === "no"}
                                onChange={(e) => {
                                  customRolesChangeHandler(
                                    e.target.value,
                                    subcroles.moduleName,
                                    customroles.moduleName
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    }
                  );
                } else {
                  submodule = (
                    <div className="subcategorychild">
                      <div>{" "}</div>
                      {"  "}
                      <div className="lableText">
                        <label>full access</label>
                        <input
                          style={{ width: '100px' }}
                          name={customroles.moduleName + customIndex}
                          type="radio"
                          value="full"
                          defaultChecked={customroles.access === "full"}
                          onChange={(e) => {
                            customRolesChangeHandler(
                              e.target.value,
                              null,
                              customroles.moduleName
                            );
                          }}
                        />
                        <label>view access</label>
                        <input
                          style={{ width: '100px' }}
                          name={customroles.moduleName + customIndex}
                          type="radio"
                          value="view"
                          defaultChecked={customroles.access === "view"}
                          onChange={(e) => {
                            customRolesChangeHandler(
                              e.target.value,
                              null,
                              customroles.moduleName
                            );
                          }}
                        />
                        <label>no access</label>
                        <input
                          style={{ width: '100px' }}
                          name={customroles.moduleName + customIndex}
                          type="radio"
                          value="no"
                          defaultChecked={customroles.access === "no"}
                          onChange={(e) => {
                            customRolesChangeHandler(
                              e.target.value,
                              null,
                              customroles.moduleName
                            );
                          }}
                        />
                      </div>
                    </div>
                  );
                }
                return (
                  <>
                    <div className="parentchild">
                      <b>{customroles.moduleName}</b>
                      <br />
                      {submodule}
                    </div>
                  </>
                );
              })}
            {roleErrorStatus && <p className="error_style">This field is required</p>}
          </div>
        </div>
      </div>
    </>
  );
});

export default UpdateUserRoleManagement;
