import React, { useState, useEffect } from "react";
import { fetch2 } from "../../Apis/commonApis";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";

function BlogModals(props) {
  const {
    register,
    handleSubmit,
    unregister,
    reset,
    formState: { errors },
  } = useForm();

  const { popData, getLatestBlogData, isOpen, setIsOpen } = props;
  const [addBlogData, setAddBlogData] = useState({
    blogTitle: "",
    blogDescription: "",
    publishDate: "",
    image: "",
    blogType: ""
  });
  const [editBlogData, setEditBlogData] = useState({
    blogTitle: "",
    blogDescription: "",
    image: "",
    blogType: ""
  });

  useEffect(() => {
    setEditBlogData({
      ...editBlogData,
      blogDescription: popData.blogDescription ? popData.blogDescription : "",
      image: popData.image ? popData.image : "",
      blogTitle: popData.blogTitle ? popData.blogTitle : "",
      blogType: popData.blogType ? popData.blogType : "",
    });
  }, [popData]);

  async function handlevalidation({
    blogTitle,
    blogType,
    blogDescription,
    publishDate,
    image,
  }) {
    const result = await fetch2(
      `${process.env.REACT_APP_SUPERADMIN_BASEURL}/createBlog`,
      {
        blogTitle,
        blogDescription,
        publishDate,
        image: image[0],
        blogType: blogType
      }
    );
    if (result.status) {
      setIsOpen(false);
      reset();
      getLatestBlogData();
    }
  }

  const updateBlogHandler = async () => {
    const result = await fetch2(
      `${process.env.REACT_APP_SUPERADMIN_BASEURL}/editBlog/${popData._id}`,
      editBlogData
    );
    if (result.status) getLatestBlogData();
  };

  return (
    <>
      <div
        className="modal fade"
        id="blog_details_modal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel1"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header d-flex align-items-center">
              <h4 className="modal-title" id="exampleModalLabel1">
                Blog Details
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body">
              <form>
                <div className="mb-3 col-md-12">
                  <div className="mb-3 col-md-6">
                    <div className="upload_img">
                      <img
                        alt=""
                        src={popData?.image}
                        className="fit_img"
                        width="100px"
                        height="100px"
                      />
                    </div>
                  </div>
                </div>
                <div className="blog_popup_details">
                  <strong>Blog Title</strong>
                  <p>{popData?.blogTitle}</p>
                  <strong>Publish Date</strong>
                  <p>{popData?.publishDate}</p>
                  <strong>Description</strong>
                  <p>{popData?.blogDescription}</p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="editblog_details_modal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel1"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header d-flex align-items-center">
              <h4 className="modal-title" id="exampleModalLabel1">
                Edit Blog
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body">
              <form>
                <div className="mb-3 col-md-12">
                  <div className="mb-3 col-md-6">
                    <div className="upload_img mb-3">
                      <img
                        alt=""
                        src={editBlogData?.image}
                        className="fit_img"
                        width="100px"
                        height="100px"
                      />
                      <input
                        type="file"
                        onChange={(e) =>
                          setEditBlogData({
                            ...editBlogData,
                            image: e.target.files[0],
                          })
                        }
                        className="mt-2"
                      />
                    </div>
                  </div>
                </div>
                <div className="blog_popup_details">
                  <div className="mb-3">
                    <label className="form-label">Blog Title</label>
                    <input
                      type="text"
                      value={editBlogData?.blogTitle}
                      onChange={(e) =>
                        setEditBlogData({
                          ...editBlogData,
                          blogTitle: e.target.value,
                        })
                      }
                      className="form-control"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Blog Title</label>
                    <select
                      className="form-select"
                      value={editBlogData?.blogType}
                      onChange={(e) =>
                        setEditBlogData({
                          ...editBlogData,
                          blogType: e.target.value,
                        })
                      }
                    >
                      <option value="general">General</option>
                      <option value="news">News</option>
                    </select>
                  </div>
                  {/* <div className="mb-3">
                                      <label className="form-label">Publish Date</label>
                                      <input type="datetime-local" className="form-control" />
                                  </div> */}
                  <div className="mb-3">
                    <label className="form-label">Blog Description</label>
                    <textarea
                      className="form-control"
                      value={editBlogData?.blogDescription}
                      onChange={(e) =>
                        setEditBlogData({
                          ...editBlogData,
                          blogDescription: e.target.value,
                        })
                      }
                      style={{ minHeight: "100px" }}
                    ></textarea>
                  </div>
                </div>
              </form>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary submit_btn"
                data-bs-dismiss="modal"
                onClick={updateBlogHandler}
              >
                Update Blog
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={isOpen}
        onHide={() => {
          setIsOpen(false);
          reset();
        }}
      >
        <Modal.Body>
          <div
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header d-flex align-items-center">
                  <h4 className="modal-title" id="exampleModalLabel1">
                    Add New Blog
                  </h4>
                  <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    onClick={() => {
                      setIsOpen(false);
                      reset();
                    }}
                  ></button>
                </div>

                <div className="modal-body">
                  <form onSubmit={handleSubmit(handlevalidation)}>
                    <div className="mb-3 col-md-12">
                      <div className="mb-3 col-md-6">
                        <div className="upload_img mb-3">
                          {/* <label className="form-label">Blog Title</label><br /> */}
                          {/* <input type="file" className="mt-2" /> */}
                        </div>
                      </div>
                    </div>
                    <div className="blog_popup_details">
                      <div className="mb-3">
                        <label className="form-label">Blog Title</label>
                        <input
                          type="text"
                          required="required"
                          className="form-control"
                          {...register("blogTitle", {
                            required: true,
                            min: 1,
                          })}
                        />

                        {errors?.blogTitle?.type === "required" && (
                          <p className="error_style">This field is required</p>
                        )}
                        {errors?.blogTitle?.type === "min" && (
                          <p className="error_style">This field is required</p>
                        )}
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Blog Type</label>
                        <select
                          className="form-select"
                          {...register("blogType", {
                            required: true,
                            min: 1,
                          })}
                        >
                          <option value="general">General</option>
                          <option value="news">News</option>
                        </select>

                        {errors?.blogTitle?.type === "required" && (
                          <p className="error_style">This field is required</p>
                        )}
                        {errors?.blogTitle?.type === "min" && (
                          <p className="error_style">This field is required</p>
                        )}
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Image</label>
                        <input
                          type="file"
                          accept="image/*"
                          className="form-control"
                          {...register("image", {
                            required: true,
                          })}
                          onChange={(e) => {
                            unregister("image");
                          }}
                        />
                        {errors?.image?.type === "required" && (
                          <p className="error_style">This field is required</p>
                        )}
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Publish Date</label>
                        <input
                          type="Date"
                          className="form-control"
                          {...register("publishDate", {
                            required: true,
                          })}
                        />
                        {errors?.publishDate?.type === "required" && (
                          <p className="error_style">This field is required</p>
                        )}
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Blog Description</label>
                        <textarea
                          type="text"
                          className="form-control"
                          style={{ minHeight: "100px" }}
                          {...register("blogDescription", {
                            required: true,
                          })}
                        />
                        {errors?.publishDate?.type === "required" && (
                          <p className="error_style">This field is required</p>
                        )}
                        {/* <textarea className="form-control" style={{ minHeight: "100px" }} ></textarea> */}
                      </div>
                      <div className="modal-footer">
                        <button
                          type="submit"
                          className="btn btn-primary submit_btn"
                        >
                          Add Blog
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default BlogModals;
