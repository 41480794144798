import React, { useState } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import "../Login/login.css";
import swal from "sweetalert";
import { securityQuestions } from "../../constants/constants";
import useInput from "../../hooks/use-input";
import Select from "react-select";
import useQuery from "../../hooks/useQuery";
import SnackBar from "../../components/SnackBar/SnackBar";
import { httpAdmin } from "../../Apis/commonApis";

const isEmpty = (value) => value.trim() !== "";

function NewSecurityQuestion() {
  //security questions
  let history = useHistory();
  const location = useLocation();
  const query = useQuery();
  const [question, setQuestion] = useState("");
  const [inValid, setInvalid] = useState(false);
  const [status, setStatus] = useState("info");
  const [statusMsg, setStatusMsg] = useState("All Fields are required");
  const {
    value: securityAnswerValue,
    isValid: securityAnswerIsValid,
    hasError: securityAnswerHasError,
    valueChangeHandler: securityAnswerChangeHandler,
    inputBlurHandler: securityAnswerBlurHandler,
  } = useInput(isEmpty);

  const securityAnswerClasses = securityAnswerHasError
    ? "form-control invalid"
    : "form-control";

  const isFormValidCheck = async () => {
    if (securityAnswerIsValid && question !== "") {
      setInvalid(false);

      let data = {
        resetType: "securityQuestions",
        question: question,
        answer: securityAnswerValue,
      };

      if (location.pathname.includes("ff")) {
        data["panel"] = "fandf";
      } else if (location.pathname.includes("app")) {
        data["panel"] = "careteam";
      }

      const result = await httpAdmin(
        `reset_password_from_email/${query.get("id")}`,
        "post",
        data,
        null
      );

      if (result.status) {
        swal("Success", result.message, "success").then((value) => {
          history.replace("/login");
        });
      }
    } else {
      setInvalid(true);
      setStatus("error");
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setInvalid(false);
  };

  function selectQuestion(event) {
    setQuestion(event.label);
  }

  return (
    <section className="login_section">
      <div className="login_header">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 logo_box">
              <NavLink className="navbar-brand" to="/admin/dashboard">
                <b className="logo-icon">
                  <img alt="" src={`/images/logo.svg`} />
                </b>
              </NavLink>
            </div>
            <div className="col-md-9">
              <div className="login_title">
                <h1>Care Management Simplified!</h1>
                <p>
                  Welcome to your new experience of care management. Our
                  powerful, easy to use <br/> and intuitive care platform, enables you
                  to easily manage all you care tasks.
                </p>
              </div>
            </div>
            <div className="col-md-3 img_box">
              <img alt="" src={`/images/login.svg`} />
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 form_box">
        <form>
          <h4>New Security Question</h4>
          <Select
            options={securityQuestions}
            onChange={selectQuestion}
            required={true}
          />
          {question !== "" && (
            <input
              style={{ marginTop: "20px" }}
              type="text"
              id="securityAnswer"
              value={securityAnswerValue}
              onChange={securityAnswerChangeHandler}
              onBlur={securityAnswerBlurHandler}
              className={securityAnswerClasses}
              placeholder="Security Answer"
            />
          )}{" "}
          {securityAnswerHasError && (
            <span className="input_error">Please Enter Security Answer</span>
          )}
          <button
            type="button"
            onClick={isFormValidCheck}
            className="form-control btn"
          >
            Save
          </button>
        </form>
      </div>
      <SnackBar
        isInvalid={inValid}
        status={status}
        hideDuration={4000}
        textmessage={statusMsg}
        handleClose={handleClose}
      />
    </section>
  );
}

export default NewSecurityQuestion;
