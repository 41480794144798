import React, { useEffect, useState } from "react";
import { BsSearch } from "react-icons/bs";
import DatePicker from "react-datepicker";
import { getMonthAgoDate } from "../../utils/CommonFunctions";
import PayrollRoutes from "./PayrollRoutes";
import { Switch, Route, NavLink, useRouteMatch, useLocation, useHistory } from "react-router-dom";

let monthAgoDate = getMonthAgoDate();
let today = new Date();

export default function Payroll() {

  const history = useHistory();
  let { path, url } = useRouteMatch();
  let location = useLocation();

  const [searchText, setSearchText] = useState("");
  const [overviewId, setOverviewId] = useState(null);
  const [filterType, setFilterType] = useState("week");

  const [dateRange, setDateRange] = useState([monthAgoDate, new Date()]);
  const [startDate, endDate] = dateRange;

  useEffect(() => {
    console.log(location.pathname, 'lllllllllll')
    let pathname = window?.location?.pathname;
    if (pathname?.includes('calendar') || pathname?.includes('overview')) {
      // console.log(window.location.pathname, 'jjjjjjjjjjjjjj')
    } else {
      handleNavigate('overview');
    }
  }, [location.pathname]);

  function handleDetailShowHide(oid) {
    setOverviewId(oid);
    if (oid) {
      history.replace({ pathname: `${url}/overview-payslip` });
    } else {
      history.replace({ pathname: `${url}/overview` });
    }
  }

  function customDateChangeHandler(update) {
    setDateRange(update);
  }

  const handleNavigate = (type) => {
    history.replace({
      pathname: `${url}/${type}`,
    });
  };

  return (
    <div className="page-wrapper">
      <div className="container-fluid min_height">
        <div className="card">
          <div className="card-body">
            {/* <h5 className="default_color">Payroll</h5> */}
            <div className="row">
              <div className="col-md-4">
                <ul
                  className="nav nav-tabs ract_tab_list border-0 listspaceGap"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item me-3" role="presentation">
                    <p
                      onClick={() => handleNavigate('overview')}
                      className={`nav-link curser_point ${window.location.href.includes("overview") ? "active" : ""
                        }`}
                    >
                      Overview
                    </p>
                  </li>

                  <li className="nav-item" role="presentation">
                    <p
                      onClick={() => handleNavigate('calendar')}
                      className={`nav-link curser_point ${window.location.href.includes("calendar") ? "active" : ""
                        }`}
                    >
                      Calendar
                    </p>
                  </li>

                </ul>
              </div>
              {overviewId ? <div className="col-md-3"></div> :
                <div className="col-md-3 searchForm" style={{ marginLeft: "0px", transform: 'translate(-6%, 0%)' }}>
                  <input
                    type="text"
                    className="form-control"
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    placeholder="search"
                  />
                  <BsSearch className="icon me-2" />
                </div>}
              {window.location.href.includes("payroll") &&
                <div className="col-md-5">
                  <div className="col-md-12 dateFilter_sales">
                    <div className="">
                      <ul className="filter_box">
                        {/* <NavLink
                          exact
                          activeClassName={filterType === "today" ? "active" : ""}
                          to="#"
                          onClick={() => setFilterType("today")}
                        >
                          Today
                        </NavLink> */}
                        <NavLink
                          activeClassName={
                            filterType === "week" ? "active mx-3" : "mx-3"
                          }
                          to="#"
                          onClick={() => setFilterType("week")}
                        >
                          This Week
                        </NavLink>
                        <NavLink
                          activeClassName={
                            filterType === "custom" ? "active" : ""
                          }
                          to="#"
                          onClick={() => setFilterType("custom")}
                        >
                          Custom
                        </NavLink>
                      </ul>
                    </div>
                    {filterType === "custom" ? (
                      <div className="input_space">
                        <DatePicker
                          selectsRange={true}
                          dateFormat="dd/MM/yyyy"
                          startDate={startDate}
                          endDate={endDate}
                          maxDate={today}
                          onChange={(update) => {
                            customDateChangeHandler(update);
                          }}
                          isClearable={true}
                        />
                      </div>
                    ) : (
                      <div className="input_space">&ensp;</div>
                    )}
                  </div>
                </div>}
            </div>

            <div className="tab-content mt-3" id="myTabContent">
              <Switch>
                <Route path={`${path}/:topicId`}>
                  <PayrollRoutes
                    searchText={searchText}
                    dateRange={dateRange}
                    filterType={filterType}
                    overviewId={overviewId}
                    handleDetailShowHide={handleDetailShowHide} />
                </Route>
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
