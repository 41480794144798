import { FaPlus } from "react-icons/fa";
import Dropdown from "react-bootstrap/Dropdown";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import React, { useState, useEffect, useRef } from "react";
import swal from "sweetalert";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import DownloadCarePlan from "./DownloadCarePlan";
import { currentCareSiteId } from "../../redux-toolkit/reducer/switchSites";
import { useDispatch, useSelector } from "react-redux";
import { TiDelete } from "react-icons/ti";
import { BsPrinter } from "react-icons/bs";
import { GoDownload } from "react-icons/go";
import {
  addCarePlanTemplate,
  createCarePlanDirect,
  deleteCarePlanDirect,
  editClientCarePlanPdf,
  getCarePlanQueAndAns,
  getClientCarePlanPdf,
  handleRisksInterventionsChange,
  plusButtonStatusHandler,
  questionAnswerStatementCollection,
  risksinterventionsRemoveChangeHandler,
} from "../../redux-toolkit/reducer/carePlanReducer";
import { DropdownButton } from "react-bootstrap";
import SavedPlanHistory from "./SavedPlanHistory";
import { ShowInfoSwal, ShowSwal } from "../../utils/alertSwal";
import { CarePlanOptions, mainTasksArray, timeValues } from "../../constants/constants";
import cx from "./clients.module.css";
import { addMinutesToTime, getCurrentTimeZone } from "../../utils/CommonFunctions";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { httpAdmin } from "../../Apis/commonApis";

const ClientCarePlans = (props) => {
  const { clientId, CareplanAccess } = props;
  // const { clientAllData } = props;

  const { currentPlanName } = useSelector((state) => state.caresiteDataReducer);
  const isPlanBase = currentPlanName === 'Base';

  // --------------------------  LOCAL STORAGE DATA  --------------------------- //
  let urlID = JSON.parse(localStorage.getItem("userData"));
  const divRef = useRef(null);

  const dispatch = useDispatch();
  let {
    plusButtonStatus,
    templateName,
    savebuttonstatus,
    lastupdate,
    whichModalStatus,
    whichModalType,
    updatedBy,
    activetemplateriskClass,
    riskProfileCategory,
    activeriskClass,
    globalClientSpecificCarePlanTemplate,
    existingCategoryTemplates,
    selectedCategory,
    activeclassOnClick,
    oberservationsfield,
    outcomesfield,
    risksfield,
    risksFields,
    interventionsFields,
    interventionsfield,
    risksinterventionsFields,
    categoryType,
    templateId,
    careteGoryId,
    questionAndAnswer,
    review,
    questionAndAnswersList,
    answerId,
    allAnswerIds,
    globalCarePlanTemplate,
    savedPlanHistoryStatus,
    getClientCarePlanPdfData,
    saveCarePlanPopupStatus,
    carePlanRemainderDatePopupStatus,
  } = useSelector((state) => state?.carePlanReducer);

  console.log(review, 'getClientCarePlanPdfData8888')

  const closecreateCareplanTemplate = () => {
    if (whichModalType === "newCarePlanToCategory") {
      dispatch(
        addCarePlanTemplate({
          plusButtonStatus: false,
          whichModalStatus: false,
          activeclassOnClick: false,
          oberservationsfield: "",
          activetemplateriskClass: "",
          outcomesfield: "",
          risksfield: "",
          risksFields: [],
          interventionsFields: [],
          interventionsfield: "",
          risksinterventionsFields: [],
          whichModalType: "",
          templateName: "",
          lastupdate: "",
          updatedBy: "",
          allAnswerIds: [],
          questionType: "",
        })
      );
    } else {
      dispatch(
        addCarePlanTemplate({
          whichModalStatus: false,
          activeclassOnClick: false,
          oberservationsfield: "",
          outcomesfield: "",
          risksfield: "",
          risksFields: [],
          interventionsFields: [],
          interventionsfield: "",
          risksinterventionsFields: [],
          whichModalType: "",
          activetemplateriskClass: "",
          templateName: "",
          lastupdate: "",
          updatedBy: "",
          allAnswerIds: [],
          questionType: "",
        })
      );
    }
  };

  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [taskList, setTaskList] = useState([]);
  const [addTaskObj, setAddTaskObj] = useState({ taskName: '', desc: '', spTime: 'No' });
  const [addedTasks, setAddedTasks] = useState([]);
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // --------------------  TEMPLATE STATUS STATES  ------------------------- //
  // const [activeclassOnClick, setActiveclassOnClick] = useState(false);
  const [specificTemplateDataCategory, setSpecificTemplateDataCategory] = useState({});

  // ----------------------------- Question & Answer Area ------------------------------- //

  // ---------------- Create Care plan Templates based on currentCareSiteId -------------------//

  function createCarePlanTemplate() {
    let data = JSON.stringify({
      careHomeId: currentCareSiteId,
      categoryType: categoryType,
      riskProfileCategory: activetemplateriskClass,
      risk_profile: activetemplateriskClass,
      updated_by: `${urlID.firstName?.trim()} ${urlID?.lastName}`,
      TemplateName: templateName,
      questions: [],
      obeservation_needs_comments: oberservationsfield,
      outcomes: outcomesfield,
      risk_interventions: risksinterventionsFields,
    });

    let config = {
      method: "POST",
      url: `${process.env.REACT_APP_BASEURL}/createCarePlanTemplate`,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("care_admin_token"),
      },
      data: data,
    };
    axios(config).then((response) => {
      closecreateCareplanTemplate();
      getClientSpecificByCategoryCarePlan();
      getCarePlanTemplate();
    }).catch(function (error) {
      console.log(error);
    });
  }

  // ---------------- Add Care plan Templates on Specific Client based on Category -------------------//
  function addCarePlanTemplateToClientSpecificCategory(type) {
    if (type === "edit") {
      let data = JSON.stringify({
        TemplateName: templateName,
        risk_profile: activetemplateriskClass,
        riskProfileCategory: activetemplateriskClass,
        updated_by: `${urlID.firstName?.trim()} ${urlID?.lastName}`,
        questions: [],
        obeservation_needs_comments: oberservationsfield,
        outcomes: outcomesfield,
        risk_interventions: risksinterventionsFields,
        Task: addedTasks,
        timeZone: getCurrentTimeZone()
      });

      let config = {
        method: "POST",
        url: `${process.env.REACT_APP_BASEURL}/updateClientCarePlan?careHomeId=${currentCareSiteId}&clientId=${clientId}&objectId=${templateId}&careplanObjId=${careteGoryId}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("care_admin_token"),
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          closecreateCareplanTemplate();
          getClientSpecificByCategoryCarePlan();
          getCarePlanTemplate();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  // ---------------- Delete Template From Templates Listing -------------------//
  function DeleteTemplateFromListing(objectId, mainId) {
    let config = {
      method: "POST",
      url: `${process.env.REACT_APP_BASEURL}/deleteCarePlanTemplate?objectId=${objectId}&careHomeId=${currentCareSiteId}&mainId=${mainId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("care_admin_token"),
      },
    };
    axios(config)
      .then(function (response) {
        closecreateCareplanTemplate();
        getClientSpecificByCategoryCarePlan();
        getCarePlanTemplate();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // -----------------  Get Care plan Template details based on CarehomeId  -------------//

  function getCarePlanTemplate() {
    setPageLoading(true);
    let config = {
      method: "GET",
      url: `${process.env.REACT_APP_BASEURL}/getCarePlanTemplate?careHomeId=${currentCareSiteId}`,
      headers: {
        Authorization: localStorage.getItem("care_admin_token"),
      },
    };
    axios(config)
      .then((response) => {
        dispatch(
          addCarePlanTemplate({
            globalCarePlanTemplate: response.data.data,
          })
        );
        setPageLoading(false);
      }).catch((error) => {
        console.log(error);
        setPageLoading(false);
      });
  }

  // -----------------  Get Care plan Template details based on Specific Client Category  -------------//

  function getClientSpecificByCategoryCarePlan() {
    let config = {
      method: "GET",
      url: `${process.env.REACT_APP_BASEURL}/getClientCarePlan?clientId=${clientId}`,
      headers: {
        Authorization: localStorage.getItem("care_admin_token"),
      },
    };
    axios(config).then((response) => {
      dispatch(
        addCarePlanTemplate({
          globalClientSpecificCarePlanTemplate:
            response.data.data[0].carePlan,
        })
      );
    }).catch((error) => {
      console.log(error);
    });
  }

  useEffect(() => {
    getTaskList();
    getCarePlanTemplate();
    getClientSpecificByCategoryCarePlan();
    dispatch(getCarePlanQueAndAns());
    dispatch(
      getClientCarePlanPdf({
        adminId: urlID?._id,
        currentCareSiteId,
        clientId: clientId,
      })
    );
  }, []);

  const { clientProfileData } = useSelector((state) => state.carePlanReducer);
  function saveToDownloads(type) {
    setLoading(true);
    const input = document.getElementById("pagetoDownload");
    html2canvas(input, { proxy: clientProfileData.image, scrollY: 0 }).then((canvas) => {
      const image = { type: 'jpeg', quality: 0.98 };
      const margin = [0.6, 0.5];
      const filename = 'myfile.pdf';

      var imgWidth = 8.5;
      var pageHeight = 11;

      var innerPageWidth = imgWidth - margin[0] * 2;
      var innerPageHeight = pageHeight - margin[1] * 2;

      // Calculate the number of pages.
      var pxFullHeight = canvas.height;
      var pxPageHeight = Math.floor(canvas.width * (pageHeight / imgWidth));
      var nPages = Math.ceil(pxFullHeight / pxPageHeight);

      // Define pageHeight separately so it can be trimmed on the final page.
      var pageHeight = innerPageHeight;

      // Create a one-page canvas to split up the full image.
      var pageCanvas = document.createElement('canvas');
      var pageCtx = pageCanvas.getContext('2d');
      pageCanvas.width = canvas.width;
      pageCanvas.height = pxPageHeight;

      // Initialize the PDF.
      var pdf = new jsPDF('p', 'in', [8.5, 11]);

      for (var page = 0; page < nPages; page++) {
        // Trim the final page to reduce file size.
        if (page === nPages - 1 && pxFullHeight % pxPageHeight !== 0) {
          pageCanvas.height = pxFullHeight % pxPageHeight;
          pageHeight = (pageCanvas.height * innerPageWidth) / pageCanvas.width;
        }

        // Display the page.
        var w = pageCanvas.width;
        var h = pageCanvas.height;
        pageCtx.fillStyle = 'white';
        pageCtx.fillRect(0, 0, w, h);
        pageCtx.drawImage(canvas, 0, page * pxPageHeight, w, h, 0, 0, w, h);

        // Add the page to the PDF.
        if (page > 0) pdf.addPage();
        // debugger;
        var imgData = pageCanvas.toDataURL('image/' + image.type, image.quality);
        pdf.addImage(imgData, image.type, margin[1], margin[0], innerPageWidth, pageHeight);
      }

      if (type === 'download') {
        pdf.save();
        setLoading(true);
      } else {
        const savepdftobase64format = new Blob([pdf.output("blob")], { type: "application/pdf", });
        savetocareplan(savepdftobase64format);
      }
    })
      .catch((error) => console.log(error));
  }

  async function savetocareplan(pdfFile) {
    const data = new FormData();

    data.append("pdfLink", pdfFile, "careplan.pdf");
    data.append("adminId", urlID._id);
    data.append("careHomeId", currentCareSiteId);
    data.append("clientId", clientId);
    data.append("createdBy", `${urlID.firstName?.trim()} ${urlID?.lastName}`);

    const result = await httpAdmin(`createClientCarePlanPdf`, 'POST', data, 'formdata');
    setLoading(false);
    ShowSwal(result.status, result.message);
    if (result.status) {
      dispatch(
        getClientCarePlanPdf({
          adminId: urlID?._id,
          currentCareSiteId,
          clientId: clientId,
        })
      );
      dispatch(addCarePlanTemplate({ saveCarePlanPopupStatus: false }));
      handleClose4();
    }

  }

  async function saveToPlansDirect() {
    const result = await dispatch(
      createCarePlanDirect({
        careHomeId: currentCareSiteId,
        clientId: clientId,
        categoryType: categoryType,
        TemplateName: templateName,
        riskProfileCategory: activetemplateriskClass,
        risk_profile: activetemplateriskClass,
        updated_by: `${urlID.firstName?.trim()} ${urlID?.lastName}`,
        questionAndAnswer: questionAndAnswer,
        obeservation_needs_comments: oberservationsfield,
        outcomes: outcomesfield,
        risk_interventions: risksinterventionsFields,
        Task: addedTasks,
      })
    );
    if (result.payload.status) {
      closecreateCareplanTemplate();
      getClientSpecificByCategoryCarePlan();
      getCarePlanTemplate();
    }
  }

  async function deleteFromCarePlans() {
    const result = await dispatch(
      deleteCarePlanDirect({
        careHomeId: currentCareSiteId,
        clientId: clientId,
        careteGoryId: careteGoryId,
        templateId: templateId,
      })
    );
    if (result.payload.status) {
      closecreateCareplanTemplate();
      getClientSpecificByCategoryCarePlan();
      getCarePlanTemplate();
    }
  }

  function getTaskList() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getTask`,
      headers: {
        Authorization: localStorage.getItem("care_admin_token"),
      },
    };
    axios(config).then((res) => {
      setTaskList(res.data?.taskData ?? []);
    }).catch((error) => {
      console.log(error);
    });
  }

  function handleTaskAdd() {
    if (addTaskObj.taskName === '') {
      return;
    }
    let userData = JSON.parse(localStorage.getItem("userData"));
    let newObj = {
      shiftType: "within time",
      startTime: "",
      endTime: "",
      taskName: categoryType,
      riskProfileCategory: activetemplateriskClass,
      taskType: addTaskObj.taskName,
      status: "false",
      comments: addTaskObj.desc,
      createdBy: userData.userType,
    }
    if (addTaskObj.spTime !== 'No') {
      let calculatedEndTime = addMinutesToTime(addTaskObj.spTime, 30)
      newObj.shiftType = 'specific time';
      newObj.startTime = addTaskObj.spTime;
      newObj.endTime = calculatedEndTime;
    }
    let newArr = [...addedTasks, newObj];
    setAddedTasks(newArr);
    setAddTaskObj({ taskName: '', desc: '', spTime: 'No' })
  }

  const addedTaskRemoveHandler = (e, indexToRemove) => {
    e.preventDefault();
    const clonefield = addedTasks.slice();
    clonefield.splice(indexToRemove, 1);
    setAddedTasks(clonefield);
  };

  const selectedCategoryType = mainTasksArray.find(item => item.taskName === categoryType);
  const allTasksClient = globalClientSpecificCarePlanTemplate?.reduce((sum, obj) => {
    obj?.template?.forEach(innerObj => { sum += innerObj.Task?.length; });
    return sum;
  }, 0);
  const allRisksClient = globalClientSpecificCarePlanTemplate?.reduce((sum, obj) => {
    obj?.template?.forEach(innerObj => { sum += innerObj.risk_interventions?.length; });
    return sum;
  }, 0);

  return (
    <>
      {pageLoading && <p>Loading...</p>}
      {(savedPlanHistoryStatus === false && pageLoading === false) && (
        <>
          <div className="row btns_head mt-3 alignText justify-content-between">
            <div className="col-md-4 mt-2">
              <h5 className="tb_title d-flex justify-content-between mt-0">
                Plan Creation and Modification Tool
              </h5>
            </div>
            <div className="col-md-6 d-flex col-xl-5 justify-content-between">
              <p className="alltaskCounts">
                <strong>All Tasks: {allTasksClient ?? '0'}</strong>
                <strong className="ms-3">All Risks: {allRisksClient ?? '0'}</strong>
              </p>
              {!isPlanBase && <span
                onClick={() => { dispatch(addCarePlanTemplate({ savedPlanHistoryStatus: true })); }}
              >
                {" "}
                Saved Plans History
              </span>}
              <div className="btns_head">
                <DropdownButton id="dropdown-basic-button" title="Actions">
                  <Dropdown.Item
                    disabled={CareplanAccess !== 'full'}
                    onClick={() => {
                      isPlanBase ?
                        ShowInfoSwal(`This feature is not available in the ${currentPlanName} Plan`) :
                        handleShow4()
                    }}>
                    Preview Care Plan
                  </Dropdown.Item>
                  {/* <Dropdown.Item
                    onClick={() => {
                      dispatch(
                        addCarePlanTemplate({
                          saveCarePlanPopupStatus: true,
                        })
                      );
                    }}
                  >
                    Save Care Plan
                  </Dropdown.Item> */}
                  <Dropdown.Item
                    disabled={CareplanAccess !== 'full'}
                    onClick={() => {
                      isPlanBase ?
                        ShowInfoSwal(`This feature is not available in the ${currentPlanName} Plan`) :
                        dispatch(addCarePlanTemplate({ carePlanRemainderDatePopupStatus: true, }))
                    }}
                  >
                    Set Reminder for Next Review{" "}
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            </div>
          </div>

          <div className="accordion care_plan_list" id="accordionExample">
            {CarePlanOptions.map((clientCarePlan) => {
              const resultantCarePlan = globalCarePlanTemplate.find(
                (careplan) => careplan?.categoryType === clientCarePlan?.Ctg
              );
              const resultantClientSpecificCarePlanTemplate =
                globalClientSpecificCarePlanTemplate.find(
                  (careplan) => careplan?.categoryType === clientCarePlan?.Ctg
                );
              const totalCatTasks = resultantClientSpecificCarePlanTemplate?.template.reduce((sum, obj) => sum + obj?.Task?.length, 0);
              const totalCatRisks = resultantClientSpecificCarePlanTemplate?.template.reduce((sum, obj) => sum + obj?.risk_interventions?.length, 0);

              return (
                <div className={`${cx.accordionItems} accordion-item`} key={clientCarePlan.value}>
                  <h2
                    className="accordion-header"
                    id={`careplan_${clientCarePlan.value}`}
                  >
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse_careplan_${clientCarePlan.value}`}
                      aria-expanded="true"
                      aria-controls="collapseOne"
                      onClick={() => {
                        dispatch(
                          addCarePlanTemplate({
                            selectedCategory: clientCarePlan.apiCategoryName,
                            categoryType: clientCarePlan.Ctg,
                          })
                        );
                      }}
                    >
                      <img
                        style={{ width: clientCarePlan.value === 7 ? "31px" : "30px", }}
                        alt=""
                        src={clientCarePlan.image}
                      />{" "}
                      {clientCarePlan.Ctg}
                      <p className="taskCounts">
                        <span>Tasks: {totalCatTasks ?? '0'}</span>
                        <span className="ms-3">Risks: {totalCatRisks ?? '0'}</span>
                      </p>
                    </button>
                  </h2>
                  <div
                    id={`collapse_careplan_${clientCarePlan.value}`}
                    className="accordion-collapse collapse"
                    aria-labelledby={`careplan_${clientCarePlan.value}`}
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="row align-items-center mt-2">
                        {resultantClientSpecificCarePlanTemplate?.template.map(
                          (cstemplate) => {
                            const updateddate = new Date(cstemplate?.last_update_date).toDateString();
                            return (
                              <div className="col-md-3">
                                <div
                                  className="living_box"
                                  onClick={() => {
                                    const updateddate = new Date(
                                      cstemplate.last_update_date
                                    ).toDateString();
                                    setAddedTasks(cstemplate.Task);
                                    dispatch(
                                      addCarePlanTemplate({
                                        whichModalStatus: true,
                                        whichModalType: "viewTemplate",
                                        templateName: cstemplate.TemplateName,
                                        updatedBy: cstemplate.updated_by,
                                        activetemplateriskClass:
                                          cstemplate.risk_profile,
                                        lastupdate: updateddate,
                                        savebuttonstatus: "edit",
                                        oberservationsfield:
                                          typeof cstemplate.obeservation_needs_comments ==
                                            "string"
                                            ? cstemplate.obeservation_needs_comments
                                            : cstemplate.obeservation_needs_comments
                                              ? cstemplate.obeservation_needs_comments.join(
                                                ""
                                              )
                                              : "",
                                        outcomesfield:
                                          typeof cstemplate.outcomes == "string"
                                            ? cstemplate.outcomes
                                            : cstemplate.outcomes
                                              ? cstemplate.outcomes.join("")
                                              : "",
                                        interventionsFields:
                                          cstemplate.risk_interventions.map(
                                            (item) => item.interventions
                                          ),
                                        risksFields:
                                          cstemplate.risk_interventions.map(
                                            (item) => item.risk
                                          ),
                                        risksinterventionsFields:
                                          cstemplate.risk_interventions,
                                        editTemplateData: cstemplate,
                                        activeriskClass:
                                          resultantClientSpecificCarePlanTemplate.riskProfileCategory,
                                        templateId: cstemplate._id,
                                        careteGoryId:
                                          resultantClientSpecificCarePlanTemplate._id,
                                        categoryType,
                                      })
                                    );

                                    // setSpecificTemplateDataCategory({
                                    //   templatedata: cstemplate,
                                    //   riskProfileCategory:
                                    //     resultantClientSpecificCarePlanTemplate.riskProfileCategory,
                                    //   categoryType:
                                    //     resultantClientSpecificCarePlanTemplate.categoryType,
                                    //   id: resultantClientSpecificCarePlanTemplate._id,
                                    // });
                                  }}
                                >
                                  <h5>{cstemplate.TemplateName}</h5>
                                  <p>{updateddate}</p>
                                  {cstemplate.risk_profile === "high" && (
                                    <div
                                      className="fAction"
                                      style={{ backgroundColor: "#BE0C0C" }}
                                    >
                                      High
                                      <br />
                                      Risk
                                    </div>
                                  )}
                                  {cstemplate.risk_profile === "medium" && (
                                    <div
                                      className="fAction"
                                      style={{ backgroundColor: "#d96a07" }}
                                    >
                                      Medium
                                      <br />
                                      Risk
                                    </div>
                                  )}
                                  {cstemplate.risk_profile === "low" && (
                                    <div
                                      className="fAction"
                                      style={{ backgroundColor: "#7d7d7d" }}
                                    >
                                      Low
                                      <br />
                                      Risk
                                    </div>
                                  )}
                                </div>
                              </div>
                            );
                          }
                        )}

                        <div className="col-md-3">
                          <Dropdown className="addPlus" show={plusButtonStatus}>
                            <Dropdown.Toggle variant="success">
                              <FaPlus
                                onClick={() => {
                                  dispatch(
                                    plusButtonStatusHandler({
                                      plusButtonStatus: !plusButtonStatus,
                                    })
                                  );
                                  setAddedTasks([]);
                                  dispatch(
                                    addCarePlanTemplate({
                                      riskProfileCategory: activeriskClass,
                                    })
                                  );
                                }}
                              />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Header>
                                <div className="actionBtns">
                                  <button
                                    className="btn"
                                    onClick={() => {
                                      dispatch(
                                        addCarePlanTemplate({
                                          whichModalStatus: true,
                                          whichModalType:
                                            "newCarePlanToCategory",
                                          savebuttonstatus: "add",
                                          categoryType: categoryType,
                                        })
                                      );
                                    }}
                                  >
                                    Create New
                                  </button>

                                  <button
                                    className="btn"
                                    onClick={() => {
                                      isPlanBase ?
                                        ShowInfoSwal(`This feature is not available in the ${currentPlanName} Plan`) :
                                        dispatch(addCarePlanTemplate({
                                          whichModalStatus: true,
                                          whichModalType: "addCarePlan",
                                          savebuttonstatus: "add",
                                        }))
                                    }}
                                  >
                                    Create Template
                                  </button>

                                  <button
                                    className="btn"
                                    onClick={() => {
                                      dispatch(
                                        plusButtonStatusHandler({
                                          plusButtonStatus: false,
                                        })
                                      );
                                      // dispatch(
                                      //   addCarePlanTemplate({
                                      //     riskProfileCategory: "",
                                      //   })
                                      // );
                                    }}
                                  >
                                    Close
                                  </button>
                                </div>
                              </Dropdown.Header>
                              <div className="row">
                                <div className="col-md-12 mt-3">
                                  <h6>Choose from Predefined Assessment Templates</h6>
                                </div>
                                {existingCategoryTemplates.find((item, index) => {
                                  return item.name === selectedCategory;
                                })?.subCategory?.map((template) => {
                                  return (
                                    <div
                                      className="col-md-6"
                                      onClick={() => {
                                        if (isPlanBase) {
                                          ShowInfoSwal(`This feature is not available in the ${currentPlanName} Plan`)
                                        } else {
                                          dispatch(
                                            addCarePlanTemplate({
                                              activeclassOnClick:
                                                !activeclassOnClick,
                                              whichModalType:
                                                "QuestionAnswerTemplate",
                                              whichModalStatus: true,
                                              questionAndAnswersList:
                                                template.allQuestionswithAnswers,
                                              templateName: template.name,
                                            })
                                          );
                                          setSpecificTemplateDataCategory({
                                            templatedata: template,
                                            UniversalTempObjectId:
                                              resultantCarePlan?._id,
                                            carehomeId: currentCareSiteId,
                                            clientId: clientId,
                                            categoryType: categoryType,
                                            riskProfileCategory:
                                              resultantCarePlan?.riskProfileCategory,
                                          });
                                        }
                                      }}
                                    >
                                      {/* <div className={`living_box ${activeclassOnClick ? 'active' : ''}`}> */}
                                      <div
                                        className={`living_box ${activeclassOnClick ? "active" : ""
                                          }`}
                                      >
                                        <h5>{template.name}</h5>
                                        <p>Updated: ---- ----- -----</p>
                                      </div>
                                    </div>
                                  );
                                })}
                                {resultantCarePlan?.template.map((template) => {
                                  const updateddate = new Date(
                                    template?.last_update_date
                                  ).toDateString();
                                  return (
                                    <div className="col-md-6">
                                      <TiDelete
                                        onClick={() => {
                                          DeleteTemplateFromListing(
                                            template._id,
                                            resultantCarePlan._id
                                          );
                                        }}
                                      />
                                      {/* <div className={`living_box ${activeclassOnClick ? 'active' : ''}`}> */}
                                      <div
                                        className={`living_box`}
                                        onClick={() => {
                                          const updateddate = new Date(
                                            template.last_update_date
                                          ).toDateString();
                                          dispatch(
                                            addCarePlanTemplate({
                                              activeclassOnClick:
                                                !activeclassOnClick,
                                              whichModalStatus: true,
                                              whichModalType:
                                                "newCarePlanToCategory",
                                              savebuttonstatus: "add",
                                              categoryType: categoryType,
                                              templateName:
                                                template.TemplateName,
                                              updatedBy: template.updated_by,
                                              activetemplateriskClass:
                                                template.risk_profile,
                                              lastupdate: updateddate,
                                              savebuttonstatus: "add",
                                              oberservationsfield:
                                                typeof template.obeservation_needs_comments ==
                                                  "string"
                                                  ? template.obeservation_needs_comments
                                                  : template.obeservation_needs_comments
                                                    ? template.obeservation_needs_comments.join(
                                                      ""
                                                    )
                                                    : "",
                                              outcomesfield:
                                                typeof template.outcomes ==
                                                  "string"
                                                  ? template.outcomes
                                                  : template.outcomes
                                                    ? template.outcomes.join("")
                                                    : "",
                                              interventionsFields:
                                                template.risk_interventions.map(
                                                  (item) => item.interventions
                                                ),
                                              risksFields:
                                                template.risk_interventions.map(
                                                  (item) => item.risk
                                                ),
                                              risksinterventionsFields:
                                                template.risk_interventions,
                                              editTemplateData: template,
                                              templateId: template._id,
                                              categoryType,
                                            })
                                          );

                                          setSpecificTemplateDataCategory({
                                            templatedata: template,
                                            UniversalTempObjectId:
                                              resultantCarePlan._id,
                                            carehomeId: currentCareSiteId,
                                            clientId: clientId,
                                            categoryType:
                                              resultantCarePlan.categoryType,
                                            riskProfileCategory:
                                              resultantCarePlan.riskProfileCategory,
                                          });
                                        }}
                                      >
                                        <h5>{template.TemplateName}</h5>
                                        <p>Updated: {updateddate}</p>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}

      {savedPlanHistoryStatus === true && (
        <>
          <div className="btns_head mt-3 setPostion">
            <a
              onClick={() => {
                dispatch(
                  addCarePlanTemplate({ savedPlanHistoryStatus: false })
                );
              }}
            >
              Back{" "}
            </a>
          </div>
          <SavedPlanHistory rows={getClientCarePlanPdfData} />
        </>
      )}

      <Modal className="viewModal" show={show4} size="xl" onHide={handleClose4}>
        <Modal.Header>
          <Modal.Title>
            <span></span>
            <div className="d-flex">
              <div className="iconDownLoad me-4">
                <span>
                  <BsPrinter />
                </span>
                <span>
                  <GoDownload
                    className="curser_point"
                    onClick={() => saveToDownloads('download')}
                  />
                </span>
              </div>
              <button
                className="btn"
                onClick={() => {
                  dispatch(
                    addCarePlanTemplate({
                      saveCarePlanPopupStatus: true,
                    })
                  );
                }}
              >
                Save Care Plan
              </button>

              {/* <span>
                <AiOutlineClose
                  className="curser_point"
                  onClick={handleClose4}
                />
              </span> */}
              <button className="btn" onClick={handleClose4}>
                Close
              </button>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DownloadCarePlan divRef={divRef} />
        </Modal.Body>
      </Modal>

      <Modal
        className="viewModal"
        show={whichModalStatus}
        size="lg"
        onHide={closecreateCareplanTemplate}
      >
        {(whichModalType === "addCarePlan" ||
          whichModalType === "newCarePlanToCategory" ||
          whichModalType === "viewTemplate") && (
            <div className="predefineTemplate">
              {isPlanBase && <p className="mb-0">Only Template Name and Task Addition is allowed in Base Plan</p>}
              <div className="row mt-4 mb-5 align-items-center">
                <div className="col-md-6">
                  <input
                    placeholder="Care Plan Template Name"
                    type="text"
                    className="form-control"
                    value={templateName}
                    onChange={(e) => {
                      dispatch(
                        addCarePlanTemplate({ templateName: e.target.value })
                      );
                    }}
                  />{" "}
                </div>
                <div className="col-md-6">
                  <ul className="menuListTop">
                    <li>
                      {whichModalType === "addCarePlan" &&
                        savebuttonstatus === "add" && (
                          <button
                            className="btn"
                            onClick={() => {
                              createCarePlanTemplate();
                            }}
                          >
                            Save
                          </button>
                        )}
                      {savebuttonstatus === "edit" && (
                        <button className="btn" onClick={deleteFromCarePlans}>
                          Delete
                        </button>
                      )}{" "}
                      {savebuttonstatus === "edit" && (
                        <button
                          className="btn"
                          onClick={() => {
                            addCarePlanTemplateToClientSpecificCategory("edit");
                          }}
                        >
                          Update to plan
                        </button>
                      )}{" "}
                      {whichModalType === "newCarePlanToCategory" &&
                        savebuttonstatus === "add" && (
                          <button className="btn" onClick={saveToPlansDirect}>
                            Save to plan
                          </button>
                        )}
                    </li>
                    <li>
                      <button
                        className="btn"
                        onClick={closecreateCareplanTemplate}
                      >
                        Close
                      </button>
                    </li>
                  </ul>
                </div>
              </div>

              <Modal.Body>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <ul className="catList">
                      <h6 className="mb-0">Select Risk Profile: </h6>
                      <li
                        className={
                          activetemplateriskClass === "high" ? "high" : ""
                        }
                        style={{ backgroundColor: "#BE0C0C" }}
                        onClick={(e) => {
                          if (!isPlanBase) {
                            dispatch(addCarePlanTemplate({
                              activetemplateriskClass: "high",
                            }))
                          }
                        }}
                      >
                        High <br /> Risk
                      </li>
                      <li
                        className={
                          activetemplateriskClass === "medium" ? "medium" : ""
                        }
                        style={{ backgroundColor: "#D96A07" }}
                        onClick={(e) => {
                          if (!isPlanBase) {
                            dispatch(addCarePlanTemplate({
                              activetemplateriskClass: "medium",
                            }))
                          }
                        }}
                      >
                        Medium <br /> Risk
                      </li>
                      <li
                        className={activetemplateriskClass === "low" ? "low" : ""}
                        style={{ backgroundColor: "#7D7D7D" }}
                        onClick={(e) => {
                          if (!isPlanBase) {
                            dispatch(addCarePlanTemplate({
                              activetemplateriskClass: "low",
                            }))
                          }
                        }}
                      >
                        Low <br /> Risk
                      </li>
                    </ul>
                    <h6>Last Update Date: {lastupdate}</h6>
                    <h6>Updated By: {updatedBy}</h6>
                  </div>
                  {/* Questions and Answers Dynamic Form  */}

                  <>
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-12">
                          <h5>Observations, Needs and Comments</h5>

                          <div className="d-flex mb-3">
                            <textarea
                              style={{ minHeight: "150px" }}
                              className="form-control"
                              placeholder=""
                              disabled={isPlanBase}
                              value={oberservationsfield}
                              onChange={(e) =>
                                dispatch(
                                  addCarePlanTemplate({
                                    oberservationsfield: e.target.value,
                                  })
                                )
                              }
                            ></textarea>
                          </div>

                          <h5>Outcomes</h5>

                          <div className="d-flex mb-3">
                            <textarea
                              style={{ minHeight: "150px" }}
                              className="form-control"
                              placeholder=""
                              disabled={isPlanBase}
                              value={outcomesfield}
                              onChange={(e) =>
                                dispatch(
                                  addCarePlanTemplate({
                                    outcomesfield: e.target.value,
                                  })
                                )
                              }
                            ></textarea>
                          </div>
                        </div>

                        <div className="col-md-12 mb-2">
                          <div className="d-flex">
                            <div className={`col-md-4 position-relative ${cx.reactAutocomplete}`}>
                              <label className="mb-1">Task</label>
                              <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                value={addTaskObj.taskName}
                                ListboxProps={{ style: { maxHeight: 80, backgroundColor: '#d9e5f2' } }}
                                onChange={(event, newPet) => {
                                  if (newPet) setAddTaskObj({ ...addTaskObj, taskName: newPet });
                                }}
                                inputValue={addTaskObj?.taskName}
                                onInputChange={(event, newPetInputValue) => {
                                  setAddTaskObj({ ...addTaskObj, taskName: newPetInputValue });
                                }}
                                options={taskList?.[selectedCategoryType?.shortName] ?? []}
                                renderInput={(params) => <TextField {...params} label="" />}
                              />
                            </div>
                            <div className="col-4 ms-3">
                              <label>Description</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Add description"
                                value={addTaskObj.desc}
                                onChange={(e) => setAddTaskObj({ ...addTaskObj, desc: e.target.value })}
                              />
                            </div>
                            <div className="col-4 ms-3">
                              <label> Specific Time</label>
                              <div className="d-flex">
                                <div className="col-9">
                                  <select
                                    className="form-select"
                                    value={addTaskObj.spTime}
                                    onChange={(e) => setAddTaskObj({ ...addTaskObj, spTime: e.target.value })}
                                  >
                                    <option value='No'>No</option>
                                    {timeValues.map((item) => {
                                      return (
                                        <option key={item.value} value={item.value}>
                                          {item.label}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                                <button className={cx.removeBtn} onClick={handleTaskAdd}>
                                  ADD
                                </button>
                              </div>
                            </div>
                          </div>
                          {/* <span className="error_style">This field is required</span> */}
                          {/* <p className="error_style">This field is required</p> */}
                        </div>

                        {addedTasks?.map((item, index) => {
                          return (
                            <div key={index} className="col-md-12 mb-2">
                              <div className="d-flex">
                                <div className="col-4">
                                  <input
                                    className="form-control"
                                    type="text"
                                    value={item?.taskType}
                                    disabled
                                  />
                                </div>
                                <div className="col-4 ms-3">
                                  <input
                                    className="form-control"
                                    type="text"
                                    value={item?.comments}
                                    disabled
                                  />
                                </div>
                                <div className="col-4 ms-3">
                                  <div className="d-flex">
                                    <div className="col-9">
                                      <input
                                        className="form-control"
                                        type="text"
                                        value={item?.shiftType === "specific time" ? item.startTime : 'No'}
                                        disabled
                                      />
                                    </div>
                                    <button className={cx.removeBtn}
                                      onClick={(e) => {
                                        addedTaskRemoveHandler(e, index);
                                      }}>
                                      -
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}

                        <div className="col-md-6 mt-2 position-relative buttonAddSet">
                          <h5>Risks</h5>
                          <input
                            type="text"
                            className="form-control mb-3"
                            placeholder="Add Risk"
                            value={risksfield}
                            disabled={isPlanBase}
                            onChange={(e) =>
                              dispatch(
                                addCarePlanTemplate({
                                  risksfield: e.target.value,
                                })
                              )
                            }
                          />
                          {risksFields?.map((item, index) => {
                            return (
                              <span
                                key={index}
                                className="form-control mb-3"
                                placeholder=""
                              >
                                {item}
                              </span>
                            );
                          })}
                        </div>

                        <div className="col-md-6 mt-2 position-relative buttonAddSet">
                          <h5>Interventions</h5>
                          <div className="position-relative">
                            <input
                              type="text"
                              className="form-control mb-3"
                              placeholder=""
                              disabled={isPlanBase}
                              value={interventionsfield}
                              onChange={(e) =>
                                dispatch(
                                  addCarePlanTemplate({
                                    interventionsfield: e.target.value,
                                  })
                                )
                              }
                            />
                            <button
                              className="removeBtn"
                              style={{ top: "3px" }}
                              disabled={isPlanBase}
                              onClick={() => {
                                dispatch(
                                  handleRisksInterventionsChange({
                                    risk: risksfield,
                                    intervention: interventionsfield,
                                  })
                                );
                              }}
                            >
                              ADD
                            </button>
                          </div>
                          {interventionsFields?.map((item, index) => {
                            return (
                              <div className="position-relative pe-5  mb-3">
                                <span className="form-control">{item}</span>
                                <button
                                  className="removeBtn"
                                  style={{ top: "3px" }}
                                  onClick={() =>
                                    dispatch(
                                      risksinterventionsRemoveChangeHandler({
                                        removeIdx: index,
                                      })
                                    )
                                  }
                                >
                                  -
                                </button>
                              </div>
                            );
                          })}
                        </div>

                      </div>
                    </div>
                  </>
                </div>
              </Modal.Body>
            </div>
          )}

        {whichModalType === "QuestionAnswerTemplate" && (
          <div className="predefineTemplate">
            <Modal.Body>
              <div className="col-md-12">
                <h5>{templateName}</h5>
                <p>Please answer below questions</p>
                <p>
                  Click Next when you finish answering. Your input will be used
                  to auto-fill the observations section which you can
                  accordingly edit, complete and save in the plan.{" "}
                </p>
                <button
                  className="btn nextBtn"
                  type="button"
                  onClick={() => {
                    const selectedanwsersuggestion = questionAndAnswersList.map((item) => {
                      const answer = item.options.filter((elem) => {
                        return (
                          elem &&
                          Object.keys(elem).includes("answerValue") &&
                          elem?.answerValue === true
                        );
                      });
                      return answer;
                    }).flat().filter((item) => item !== undefined).map((item) => {
                      return item.suggestion;
                    }).join(" \n");
                    console.log(questionAndAnswersList, questionAndAnswersList.map((item) => {
                      const answer = item.options.filter((elem) => {
                        return (
                          elem &&
                          Object.keys(elem).includes("answerValue") &&
                          elem?.answerValue === true
                        );
                      });
                      return answer;
                    }).flat().filter((item) => item !== undefined), selectedanwsersuggestion, 'sss ss')
                    dispatch(
                      addCarePlanTemplate({
                        whichModalStatus: true,
                        whichModalType: "newCarePlanToCategory",
                        savebuttonstatus: "add",
                        categoryType: categoryType,
                        oberservationsfield: selectedanwsersuggestion,
                      })
                    );
                  }}
                >
                  Next
                </button>
              </div>
              <div className="col-md-12">
                {questionAndAnswersList?.map(
                  ({ questions, options, _id }, index) => {
                    return (
                      <>
                        {questions.includes(
                          "(you can select more than one)"
                        ) && (
                            <div className="quationBox">
                              <h5>{questions} </h5>
                              <ul>
                                {options.map((option) => {
                                  return (
                                    <li>
                                      {/* <label className="checkbox active"> */}
                                      <label
                                        className={`checkbox ${Object.keys(option).includes(
                                          "answerValue"
                                        ) && option?.answerValue
                                          ? "active"
                                          : ""
                                          }`}
                                      >
                                        <input
                                          type="checkbox"
                                          name={_id}
                                          onChange={(event) => {
                                            dispatch(
                                              questionAnswerStatementCollection(
                                                {
                                                  selectedAnswerId: option._id,
                                                  questionId: _id,
                                                  questionAndAnswersList,
                                                  selectedAnswerEvent:
                                                    event.target.checked,
                                                  questionType: "checkbox",
                                                }
                                              )
                                            );
                                            dispatch(
                                              addCarePlanTemplate({
                                                answerId: option._id,
                                              })
                                            );
                                          }}
                                        />
                                        <span className="checkmark"></span>
                                        {option.answer}
                                      </label>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          )}

                        {/*question that have single select  */}
                        {!questions.includes(
                          "(you can select more than one)"
                        ) && (
                            <div className="quationBox">
                              <h5>{questions} </h5>
                              <ul>
                                {options.map((option) => {
                                  return (
                                    <li>
                                      {/* <label className="checkbox active"> */}
                                      <label
                                        className={`checkbox ${Object.keys(option).includes(
                                          "answerValue"
                                        ) && option?.answerValue
                                          ? "active"
                                          : ""
                                          }`}
                                      >
                                        <input
                                          type="radio"
                                          name={_id}
                                          onChange={(event) => {
                                            dispatch(
                                              questionAnswerStatementCollection(
                                                {
                                                  selectedAnswerId: option._id,
                                                  questionId: _id,
                                                  questionAndAnswersList,
                                                  selectedAnswerEvent:
                                                    event.target.checked,
                                                  questionType: "radio",
                                                }
                                              )
                                            );
                                            dispatch(
                                              addCarePlanTemplate({
                                                answerId: option._id,
                                              })
                                            );
                                          }}
                                        />
                                        <span className="checkmark"></span>
                                        {option.answer}
                                      </label>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          )}
                      </>
                    );
                  }
                )}
              </div>
            </Modal.Body>
          </div>
        )}
      </Modal>

      <Modal
        className="viewModal"
        show={saveCarePlanPopupStatus}
        onHide={() => {
          dispatch(addCarePlanTemplate({ saveCarePlanPopupStatus: false }));
        }}
      >
        <Modal.Header>
          <Modal.Title>
            <span>Save Client Care Plan</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="">
                <p>Are you sure you want to save this care plan?</p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex">
            <button
              className="btn submitBtn"
              onClick={() => {
                dispatch(
                  addCarePlanTemplate({ saveCarePlanPopupStatus: false })
                );
              }}
            >
              No
            </button>
            <button
              className="btn submitBtn"
              onClick={() => saveToDownloads('save')}
              disabled={loading}
            >
              Yes
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        className="viewModal"
        show={carePlanRemainderDatePopupStatus}
        onHide={() => {
          dispatch(
            addCarePlanTemplate({ carePlanRemainderDatePopupStatus: false })
          );
        }}
      >
        <Modal.Header>
          <Modal.Title>
            <span>Set Next Review Date</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 mb-3">
              <label className="form-label">Date</label>
              <input
                type="date"
                className="form-control"
                value={review}
                onChange={(e) => {
                  // if (getClientCarePlanPdfData[0]?._id) {
                  dispatch(addCarePlanTemplate({ review: e.target.value }));
                  // } else {
                  // swal("Failed", 'error.response.data.message', "error");
                  // }
                }}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex">
            <button
              className="btn submitBtn"
              onClick={() => {
                dispatch(
                  addCarePlanTemplate({
                    carePlanRemainderDatePopupStatus: false,
                  })
                );
              }}
            >
              No
            </button>
            <button
              className="btn submitBtn"
              onClick={async () => {
                if (review && getClientCarePlanPdfData[0]?._id) {
                  dispatch(
                    editClientCarePlanPdf({
                      review,
                      documentId: getClientCarePlanPdfData[0]?._id,
                    })
                  );
                  swal("Success", 'Review Date added successfully', "success");
                  await dispatch(
                    getClientCarePlanPdf({
                      adminId: urlID?._id,
                      currentCareSiteId,
                      clientId: clientId,
                    })
                  );
                } else {
                  swal("Failed", 'Document not found', "error");
                }
              }}
            >
              Yes
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ClientCarePlans;
