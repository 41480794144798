import React, { useState, useEffect } from "react";
import NoRowsOverlay from "../../components/NoRowsOverlays";
import { IoIosArrowForward } from "react-icons/io";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { styled } from "@mui/system";
import { Dropdown, DropdownButton } from "react-bootstrap";
import swal from "sweetalert";
import { httpAdmin } from "../../Apis/commonApis";
import { AiFillPrinter } from "react-icons/ai";
import JsonDownload from "../../components/DownloadExcel/DownloadExcel";
import { handleDataPrint } from "../../utils/CommonFunctions";
import { ShowSwal } from "../../utils/alertSwal";
import { useHistory } from "react-router-dom";

const chatUserImg = '/images/chatUser.svg';

const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
    "& .MuiDataGrid-cell:focus": {
        outline: "none",
    },
}));

export default function MedicineNewTable(props) {
    let { rows, handleShowOrHide, dmdUpdationDate, searchText, medAccess } = props;

    const history = useHistory();

    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const [pageSize, setPageSize] = useState(5);

    function redirectToDetailPage(selectedRow) {
        history.push({
            pathname: `/admin/emar/medicine-detail`,
            state: {
                clientId: selectedRow.cid,
                customMedId: selectedRow.custumMedId,
                clientName: selectedRow.name,
            }
        });
    }

    const columns = [
        {
          field: "image",
          headerName: "",
          width: 50,
          sortable: false,
          disableColumnMenu: true,
          renderCell: (params) => <ProfileImgName params={params} />,
        },
        {
            field: "name",
            headerName: "Name",
            width: 130,
        },
        { field: "location", headerName: "Location", minWidth: 220 },
        {
            field: "allergies",
            headerName: "Allergies",
            minWidth: 140,
        },
        { field: "conditions", headerName: "Conditions", minWidth: 140 },
        { field: "medName", headerName: "Medicine Name", minWidth: 190 },
        {
            field: "PRNStatus",
            headerName: "PRN Status",
            width: 100,
            sortable: false,
            disableColumnMenu: true,
            // align: "center",
            renderCell: (params) => <PrnBtn params={params} />,
        },
        {
            field: "InStock",
            headerName: "In Stock",
            width: 80,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "StockLevel",
            headerName: "Stock Level",
            width: 100,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => <StockLevelBtn params={params} />,
        },
        {
            field: "status",
            headerName: "Status",
            align: "center",
            width: 80,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => <StatusButton params={params} />,
        },
        {
            field: "arrow",
            headerName: "",
            width: 50,
            numeric: true,
            disablePadding: true,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <RedirectionIcon params={params} handleRedirect={redirectToDetailPage} />
            ),
        },
    ];

    useEffect(() => {
        // console.log(rowSelectionModel, "rowSelectionModel");
    }, [rowSelectionModel]);

    function handleEditDeleteMedicine(action) {
        if (rowSelectionModel.length > 0) {
            let swaltext =
                action === "status"
                    ? "Click below to procced for Activate Or Deactivate the Status"
                    : "Click below to procced";
            let data = [];
            rows.forEach((item) => {
                rowSelectionModel.forEach((value) => {
                    if (item.medication?._id === value) {
                        data.push({
                            careHomeId: item.careHomeId,
                            clientId: item.cid,
                            customMedicineId: item.custumMedId,
                            medicineName: item.medication.NM,
                            status: item.status === "active" ? "inactive" : "active",
                        });
                    }
                });
            });
            swal({
                title: "Are you sure??",
                text: swaltext,
                buttons: {
                    cancel: "Cancel",
                    succeed: "Yes",
                },
            }).then((result) => {
                if (result) {
                    if (action === "status") {
                        editMedicine(data);
                    } else {
                        deleteMedicine(data);
                    }
                }
            });
        } else
            swal("Failed", "Please Select checkbox to proceed", "error").then(
                (value) => {
                    return;
                }
            );
    }

    const deleteMedicine = async (body) => {
        const res = await httpAdmin(
            "deleteMedicines",
            'post',
            { medicineArray: body },
            null
        );
        if (res.status) {
            ShowSwal(true, res.message ?? '');
            props.getClientRowData();
        }else{
            ShowSwal(false, res.message ?? 'Something went wrong!');
        }
    };

    const editMedicine = async (body) => {
        const result = await httpAdmin(
            "markMedicinesStatus",
            'post',
            { medicineArray: body },
            null
        );
        ShowSwal(result.status, result.message ?? 'Something went wrong!');
        if (result.status) {
            props.getClientRowData();
        }
    };

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize);
    };

    const filteredRows = searchText.trim()
        ? rows.filter(
            (row) =>
                row.name.toLowerCase().includes(searchText) ||
                row.location.toLowerCase().includes(searchText) ||
                row.medName.toLowerCase().includes(searchText)
        )
        : rows;

    const downloadData = rows?.map(obj => {
        const { ['medication']: _, ['image']: __, ['cid']: ___, ['careHomeId']: ____, ['no']: _____, ['id']: ______, ...rest } = obj;
        return rest;
    });
    // console.log(downloadData, 'downloadData')

    return (
        <>
            <div className="row">
                <div className="col-md-2">
                    <AiFillPrinter
                        className="me-3 ms-1 curser_point"
                        onClick={() => handleDataPrint(downloadData)}
                    />
                    <a className="curser_point">
                        <JsonDownload
                            fileName="medicines-excel"
                            downloadfile={downloadData}
                        />
                    </a>
                </div>
                <div className="col-md-10 rightSidePostionSet">
                    <div className="row justify-content-end">
                        <div className="col-md-3 my-1 d-flex" style={{ fontSize: "11px" }}>
                            <span className="default_color">
                                Medicine data updated from NHS database:
                            </span>
                            {dmdUpdationDate ? ` ${dmdUpdationDate?.split("T")[0]}: ${dmdUpdationDate?.split("T")[1].slice(0, 5)}` : "Not Available"}
                        </div>
                        <div className="col-md-2 btns_head my-1">
                            <DropdownButton id="dropdown-basic-button" title="Actions">
                                <Dropdown.Item
                                    disabled={medAccess !== 'full'}
                                    onClick={handleShowOrHide}> Add </Dropdown.Item>
                                <Dropdown.Item
                                    disabled={medAccess !== 'full'}
                                    onClick={() => {
                                        handleEditDeleteMedicine("delete");
                                    }}
                                >Delete</Dropdown.Item>
                                <Dropdown.Item
                                    disabled={medAccess !== 'full'}
                                    onClick={() => {
                                        handleEditDeleteMedicine("status");
                                    }}
                                >
                                    Change Status
                                </Dropdown.Item>
                            </DropdownButton>
                        </div>
                    </div>
                </div>
                {/* {/ <InvoiceActions selectedData={selectedData} getLatestInvioceData={getLatestInvioceData} /> /} */}
            </div>
            <div className="" style={{ height: "60vh", width: "100%" }}>
                <CustomDataGrid
                    columns={columns}
                    rows={filteredRows}
                    // onRowClick={handleRowClick}
                    // autoHeight
                    checkboxSelection
                    onSelectionModelChange={(newRowSelectionModel) => {
                        setRowSelectionModel(newRowSelectionModel);
                    }}
                    disableSelectionOnClick
                    getRowId={(row) => row?.id}
                    rowSelectionModel={rowSelectionModel}
                    slots={{
                        toolbar: GridToolbar,
                        noRowsOverlay: NoRowsOverlay,
                    }}
                    pageSize={pageSize}
                    onPageSizeChange={handlePageSizeChange}
                    rowsPerPageOptions={[5, 10, 20]}
                />
            </div>
        </>
    );
}

const ProfileImgName = (props) => {
    let { params } = props;
    const dp = params.row.image;
    return (
        <span className="">
            <img alt="" className="profile_img_table" src={dp ? dp : chatUserImg} />
            {/* {`${params.row.name}`} */}
        </span>
    );
};

const RedirectionIcon = (props) => {
    let { params, handleRedirect } = props;
    return (
        <IoIosArrowForward
            style={{ cursor: "pointer", fontSize: "20px" }}
            onClick={() => handleRedirect(params.row)}
        />
    );
};

const PrnBtn = (props) => {
    let { params } = props;
    return (
        <>
            {params.row?.PRNStatus ? (
                <div className="btnprn">
                    <button className="btnprn1">PRN</button>
                </div>
            ) : <span> No</span>}
        </>
    );
};

const StockLevelBtn = (props) => {
    const { params } = props;
    const levelVal = params.row?.StockLevel;

    let btnColor = "#2D9CDB";
    if (levelVal === "Out of Stock") {
        btnColor = "#EB5757";
    } else if (levelVal === "Low Stock") {
        btnColor = "#F2994A";
    } else if (levelVal === "Ordered") {
        btnColor = "#F2C94C";
    }

    return (
        <button className={`btn btn-sm pe-none`}
            style={{
                backgroundColor: btnColor,
                color: "#ffff",
                fontSize: "12px",
                width: "75px",
                pointerEvents: "none",
            }}>
            {levelVal === 'Stock' ? 'In-Stock' : levelVal}
        </button>
        //   <Button
        //     variant="contained"
        //     size="small"
        //     style={{
        //       backgroundColor: btnColor,
        //       color: "#ffff",
        //       fontSize: "12px",
        //       width: "75px",
        //       pointerEvents: "none",
        //     }}
        //   >
        //     {levelVal === "Stock" ? "In-Stock" : levelVal}
        //   </Button>
    );
};

const StatusButton = (props) => {
    const { params } = props;
    const isActive = params?.row?.isActive;

    return (
        // <Button
        //     variant="contained"
        //     size="small"
        //     style={{
        //         backgroundColor: isActive ? "#4CAF50" : "#f44336",
        //         color: "white",
        //         pointerEvents: "none",
        //     }}
        // >
        //     {isActive ? "Active" : "Inactive"}
        // </Button>
        <button className={`btn btn-sm pe-none table_btn_font`}
            style={{
                backgroundColor: isActive ? "#4CAF50" : "#f44336",
                color: "white",
                pointerEvents: "none",
            }}>
            {isActive ? "Active" : "Inactive"}
        </button>
    );
};


export { StatusButton, StockLevelBtn };
