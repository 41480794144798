import React from "react";
import { Fragment } from "react";

export default function RedirectionLinks(props) {
  let { planName, planId, fandfData, SubscriptionType, currency } = props;

  return (
    <Fragment>
      <div className="bottom_box">
        <span
          className="btn"
          onClick={(e) => {
            e.preventDefault();
            window.open(`/multistepsignupform?planName=${planName}&planId=${planId}&status=${fandfData.fandfStatus}&count=${fandfData.count}&year=${SubscriptionType}&currency=${currency}`,
              "_blank",
              "noopener,noreferrer"
            );
          }}
        >
          Get Started
        </span>
      </div>
    </Fragment>
  );
}
