export const logoutAdmin = (type) => {
  const removeItems = [
    "care_admin_token",
    "userData",
    "carehomeId",
    "currentCareSiteId",
    "carefacility",
    "companyName",
    "carehomeName",
  ];

  if (type === "site_Delete") {
    removeItems.push("careAdminToken");
    removeItems.push("userRegisterData");
  }

  removeItems.map((item) => {
    localStorage.removeItem(`${item}`);
  });
};