import React, { useEffect, useMemo, useState } from "react";
import "./Profile.css";
import { NavLink } from "react-router-dom";
import { httpAdmin } from "../../Apis/commonApis";
import { useSelector } from "react-redux";
import { formatDate } from "../../utils/CommonFunctions";
import { twoDecimalPlaces } from "../../utils/utilsHandlerFunctions";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";

export default function Subscription() {
  let { currentCareSiteData } = useSelector(
    (state) => state.caresiteDataReducer
  );

  const history = useHistory();

  const [subPlanData, setSubPlanData] = useState(null);
  const [careSiteplanData, setCareSitePlanData] = useState(null);
  const [couponData, setCouponData] = useState(null);

  useEffect(() => {
    if (currentCareSiteData) {
      setCareSitePlanData(currentCareSiteData);
      getSubscriptionDetails(currentCareSiteData);
      getCouponDetails(currentCareSiteData?._id);
    }
  }, [currentCareSiteData]);

  const getSubscriptionDetails = async (paymentData) => {
    // let payMode = paymentData?.goCardLess_subscription_id ? "goCardLess_subscription_id" : "stripe_subscription_id";
    //  let oldUrl =  `getSubscriptionDetailsForProfile?paymentThrough=${paymentData?.paymentThrough}&${payMode}=${paymentData[payMode]}&careHomeId=${paymentData._id}`,
    let result = await httpAdmin(
      `getSubscriptionDetailsForProfile?careHomeId=${paymentData._id}`,
      "get",
      null,
      null
    );
    if (result.status) setSubPlanData(result.data);
  };

  const getCouponDetails = async (csid, aid) => {
    const result = await httpAdmin(
      `getCouponForProfile?caresiteId=${csid}&adminId=${aid}`,
      "get",
      null,
      null
    );
    if (result.status) {
      setCouponData(result.data ?? null);
    }
  };

  // const fandfLicCount = careSiteplanData?.chargesDetails?.find(charge => charge?.discription === 'Friends & Family App')?.qty || 0;
  const fandfLicCount = careSiteplanData?.chargesDetails[1]?.qty || 0;

  const getDateDifference = (date1, date2) => {
    const diffTime = Math.abs(date2 - date1);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };

  const remainingDaysInPlan = useMemo(() => {
    console.log(subPlanData, 'subPlanData')
    if (subPlanData) {
      if (subPlanData?.daysRemaining > 0) {
        return subPlanData?.daysRemaining;
      } else {
        return getDateDifference(new Date(subPlanData?.current_period_end), new Date())
      }
    } else {
      return 0
    }
  }, [subPlanData]);

  const planDuration = useMemo(() => {
    let durationType = subPlanData?.interval ?? subPlanData?.interval_unit;
    if (+subPlanData?.daysRemaining > 0) {
      durationType = 'month'
    }
    return durationType?.includes('month') ? 30 : 365;
  }, [subPlanData]);

  function handleUpdateClick() {
    const state = {
      ownerId: careSiteplanData?.adminId,
      updatePlan: true,
      currentPlanData: careSiteplanData?.chargesDetails,
      planId: careSiteplanData?.planId,
      monthlyearly: careSiteplanData?.monthyOrYearly,
    };
    if (subPlanData?.daysRemaining > 0) {
      swal({
        title: "Are you sure!",
        text: `Kindly note that upgrading during a trial period will start the paid plan immediately. Do you want to proceed`,
        buttons: {
          cancel: "Cancel",
          succeed: "Yes",
        },
      }).then((result) => {
        if (result) {
          history.push(`/admin/purchagePlan`, state);
        } else {
          console.log("rejected");
        }
      });
    } else {
      history.push(`/admin/purchagePlan`, state);
    }
  }

  return (
    <div className="mt-3 subscriptionBox">
      <h5 className="themeColor">{careSiteplanData?.SubscriptionPlan}</h5>
      <p className="mb-3">
        <span className="themeColor">Friends & Family App License:</span>{" "}
        {fandfLicCount}
      </p>
      <p>
        <span className="themeColor">Subscription ID :</span>{" "}
        {careSiteplanData?.finalId}
      </p>
      <p>
        <span className="themeColor">Subscription Start Date:</span>{" "}
        {careSiteplanData ? formatDate(careSiteplanData?.createdAt) : ""}
      </p>
      {careSiteplanData?.trialExpiry && (
        <p>
          <span className="themeColor">Subscription Activation Date:</span>{" "}
          {subPlanData ? formatDate(subPlanData?.acitivationDate) : ""}
          {/* .toLocaleDateString("en-GB") */}
        </p>
      )}

      {subPlanData?.current_period_end && (
        <p>
          <span className="themeColor">Subscription Type & Validity:</span>{" "}
          {careSiteplanData?.monthyOrYearly},{" "}
          {subPlanData ? formatDate(subPlanData?.current_period_end) : ""}
        </p>
      )}

      {remainingDaysInPlan >= 0 && (
        <p>
          <span className="themeColor">Days remaining in subscription:</span>{" "}
          {remainingDaysInPlan}
          Days
        </p>
      )}
      {remainingDaysInPlan >= 0 && (
        <div className="progress col-6">
          <div
            className="progress-bar"
            style={{ width: `${(remainingDaysInPlan * 100) / planDuration}%` }}
          ></div>
        </div>
      )}

      <div className="col-md-12">
        <div className="row promotionalC">
          <h5 className="themeColor mt-3">Promotional Coupons</h5>

          {couponData?.map((item) => {
            return (
              <div className="col-6" key={item._id}>
                <ul>
                  {/* <li>
                   <span className="themeColor">Active:</span> 02
                 </li> */}
                  <li>
                    <span className="themeColor">Coupon Id:</span>{" "}
                    {item?.couponId}
                  </li>
                  <li>
                    <span className="themeColor">Type:</span> {item?.couponType}
                  </li>
                  <li>
                    <span className="themeColor">Discount Value:</span>{" "}
                    {item.discountType === "Fixed"
                      ? twoDecimalPlaces(item?.discountValue)
                      : `${twoDecimalPlaces(item?.discountValue)}`}
                  </li>
                  <li>
                    <span className="themeColor">Start Date:</span>{" "}
                    {new Date(item?.couponCreationDate)?.toLocaleDateString(
                      "en-GB"
                    )}
                  </li>
                  {/* <li>
                   <span className="themeColor">Valid Till:</span> 07.12.2022
                 </li> */}
                  {/* <li>
                    <span className="themeColor">Linked Plans:</span>{" "}
                    {item?.linkedPlan?.join(", ")}
                  </li>
                  <li>
                    <span className="themeColor">Linked Add-Ons:</span>{" "}
                    {item?.linkedAddOn?.join(", ")}
                  </li> */}
                </ul>
              </div>
            );
          })}

          <h5 className="themeColor mt-3 mb-3">
            Total Subscription Fee: £{" "}
            {twoDecimalPlaces(subPlanData?.amount) ?? 0} /
            {careSiteplanData?.monthyOrYearly}
          </h5>

          <a
            style={{ color: "#2D9CDB", cursor: 'pointer', textDecoration: 'underline' }}
            onClick={handleUpdateClick}
          >
            Update Plan
          </a>
        </div>
      </div>
    </div>
  );
}
