import React, { useEffect, useState } from 'react'
import cx from "../Portal.module.css";
import { Row, Col, NavLink } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AiFillPlusCircle } from "react-icons/ai";
import { compressImage } from '../../utils/CommonFunctions';
import CircularProgress from "@mui/material/CircularProgress";
import { httpPortal } from '../../Apis/commonApis';

export default function PortalHeader() {

    const history = useHistory();

    const [portalData, setportalData] = useState(null);
    const [careHome, setCareHome] = useState("");
    const [imgLoader, setImgLoader] = useState(false);

    useEffect(() => {
        let data = JSON?.parse(localStorage.getItem('fandfData'));
        let careSite = localStorage.getItem('careSite');
        console.log(data, 'fffffffffff')
        setportalData(data)
        setCareHome(careSite)
    }, []);

    let redireUrl = (type) => {
        if (type == "profile") {
            history.push(`/portal/userProfile`, { replace: false });
        } else if (type == "reqService") {
            history.push(`/portal/requestService`, { replace: false });
        } else if (type == "changePwd") {
            history.push(`/changePassword`, { replace: false });
        } else if (type == "changeSecQue") {
            history.push({
                pathname: `/portal/createPortalPassword`,
                state: { SecurityQuestion: "SecurityQuestion" },
            });
        } else if (type == "feedback") {
            history.push(`/portal/Feedback`, { replace: false });
        } else {
            history.push(`/portal/login`, { replace: false });
            localStorage.removeItem("fandfData");
            localStorage.removeItem("fandfam_token");
            localStorage.removeItem("careSite");
        }

    }

    async function onFileChange(e) {
        let profileImg = e.target.files;
        if (profileImg) {
            setImgLoader(true);
            let compressedImg = await compressImage(profileImg);
            editImage(compressedImg);
        }
    }

    const editImage = async (imgg) => {
        let body = new FormData();
        body.append("image", imgg);
        body.append("fandfMemberId", portalData?._id);
        body.append("clientId", portalData.clientId);
        const res = await httpPortal(`editFanFmember`, "post", body, "formdata");
        if (res.status) {
            let keyUser = res.data?.keycontact.find(item => item._id === portalData?._id);
            localStorage.setItem("fandfData", JSON.stringify(keyUser));
            setportalData(keyUser);
        }
        setImgLoader(false);
    };

    return (
        <div className={`${cx.headerStart}`}>
            <div className={`container`}>
                <img className={`${cx.logoImg}`} onClick={() => history.push('/')} src="/images/Newfooter_Logo.svg" alt="logo" />
                <div className={`${cx.headerTop}`}>
                    <h5>Friends & Family Portal</h5>
                </div>
                <div className={`${cx.userDropdown}`}>
                    {portalData && <div className={`dropdown`}>
                        <button className={`btn btn-secondary dropdown-toggle`} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            {careHome}
                            <img src={portalData?.image ?? "/images/chatUser.svg"} alt="" className={portalData?.image ? "" : 'forImagefandsfam'} />
                        </button>
                        <ul className={`dropdown-menu`} aria-labelledby="dropdownMenuButton1">
                            <li>
                                <div className={`${cx.userImages}`}>
                                    <div className="position-relative">
                                        {imgLoader ? (
                                            <div>
                                                <CircularProgress color="secondary" />
                                            </div>
                                        ) : (
                                            <img src={portalData?.image ?? "/images/chatUser.svg"} alt="" className={portalData?.image ? "" : 'forImagefandsfam'} />
                                        )}
                                        <div className={`${cx.editProfile_imgicon}`}>
                                            <input
                                                type="file"
                                                id="upLoader"
                                                accept="image/*"
                                                name="images"
                                                onChange={onFileChange}
                                            />
                                            <span>
                                                <AiFillPlusCircle className="edit_icon" />
                                            </span>
                                        </div>
                                    </div>
                                    <h3> {portalData?.name}</h3>
                                    <p>{portalData?.email}</p>
                                </div>
                            </li>
                            <li><NavLink className={`dropdown-item`} to="#" onClick={(e) => redireUrl("profile")}>My profile</NavLink></li>
                            <li><NavLink className={`dropdown-item`} to="#" onClick={(e) => redireUrl("reqService")}>Request Service</NavLink></li>
                            <li><NavLink className={`dropdown-item`} to="#" onClick={(e) => redireUrl("changePwd")}>Change Password</NavLink></li>
                            <li><NavLink className={`dropdown-item`} to="#" onClick={(e) => redireUrl("changeSecQue")}>Change Security Question</NavLink></li>
                            <li><NavLink className={`dropdown-item`} to="#" onClick={(e) => redireUrl("feedback")}>Feedback</NavLink></li>
                            <li><NavLink className={`dropdown-item`} to="#" onClick={(e) => redireUrl("logout")}>Logout</NavLink></li>
                        </ul>
                    </div>}
                </div>
            </div>
        </div>
    )
}
