import React, { useState, useEffect } from "react";
import axios from "axios";
import { currentCareSiteId } from "../../redux-toolkit/reducer/switchSites";
import "../Invoicing/invoicing.css";
import InvoiceAction from "./InvoiceAction";
import {
  formatDate,
  getFullName,
  getMondayDate,
  toTitleCase,
} from "../../utils/CommonFunctions";
import CareMagnusTable from "../../utils/CareMagnusTable";
import { filterData } from "../../utils/TableSearchFilter";

function statusBgColor(type) {
  if (type === "unpaid") {
    return "#F2994A";
  } else if (type === "paid") {
    return "green";
  } else {
    return "red";
  }
}

const columns = [
  {
    field: "image",
    headerName: "",
    width: 70,
    sortable: false,
    renderCell: (fieldData) => <span className="profle_img_box">
      <img
        alt=""
        className={`profile_img_table`}
        src={fieldData.row?.clientId?.image ?? "/images/chatUser.svg"}
      />
    </span>
  },
  {
    field: "invoice_for", headerName: "Invoice For", flex: 1, minWidth: 120,
    renderCell: (fieldData) => {
      return <span>{getFullName(fieldData.row?.clientId)}</span>;
    },
  },
  { field: "invoiceNo", headerName: "Invoice No.", flex: 1, minWidth: 120 },
  {
    field: "invoiceDate", headerName: "Creation Date", minWidth: 120,
    valueFormatter: (params) => {
      return params.value ? formatDate(params.value) : '-';
    },
  },
  { field: "toEmail", headerName: "Invoice To", flex: 1, minWidth: 120 },
  {
    field: "total", headerName: "Value", width: 120,
    valueFormatter: (params) => {
      return params.value ? (params.value).toFixed(2) : '-';
    },
  },
  {
    field: "approval", headerName: "Approval", width: 100, sortable: false,
    renderCell: (fieldData) => <button
      className={`btn invoice_approval ${fieldData.row?.approval ? "btn-success" : "btn-secondary"
        }`}
    >
      {fieldData.row?.approval ? "Yes" : "No"}
    </button>,
  },
  {
    field: "approveBy", headerName: "Approved By", width: 120,
    valueFormatter: (params) => {
      return params.value ?? '-';
    },
  },
  {
    field: "approvalTimestamp", headerName: "Sent", width: 120,
    valueFormatter: (params) => {
      return params.value ? formatDate(params.value) : '-';
    },
  },
  {
    field: "status",
    headerName: "Status",
    minWidth: 100,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (fieldData) => <button
      className={`btn invoice_status btn-sm`}
      style={{
        backgroundColor: statusBgColor(fieldData.row.status),
      }}
    >
      {toTitleCase(fieldData.row?.status)}
    </button>,
  },
  {
    field: "paymentTimestamp", headerName: "Payment Date", flex: 1, minWidth: 120,
    valueFormatter: (params) => {
      return params.value ? formatDate(params.value) : '-';
    },
  },
];

export default function EnhancedTable(props) {
  const { searchText, filterType, dateRange } = props;

  const [startDate, endDate] = dateRange;
  const [selected, setSelected] = useState([]);
  const [rows, setRowData] = useState([]);
  const [invoiceCount, setInvoiceCount] = useState(0);
  const [refreshPage, setRefreshPage] = useState(0);

  useEffect(() => {
    if (filterType === "custom" && endDate) {
      getClientRowData(formatDate(startDate), formatDate(endDate));
    } else if (filterType === "week") {
      const mondayDate = getMondayDate(new Date());
      getClientRowData(formatDate(mondayDate), formatDate(new Date()));
    } else if (filterType === "today") {
      // let sendDate = today.toLocaleDateString();
      // getClientRowData();
    }
  }, [filterType, dateRange, refreshPage]);


  const applyDateFilter = () => {
    setRefreshPage(refreshPage + 1);
  };

  const getClientRowData = (start, end) => {
    axios({
      url: `${process.env.REACT_APP_BASEURL}/getInvoiceAdmin?careHomeId=${currentCareSiteId}&startDate=${start}&endDate=${end}`,
      method: "GET",
      headers: { Authorization: localStorage.getItem("care_admin_token") },
    })
      .then((res) => {
        let ClientData = res.data.invoiceData;
        setRowData(ClientData.data);
        setInvoiceCount({
          overdueCount: ClientData.overdueCount,
          paidCount: ClientData.paidCount,
          totalCount: ClientData.totalCount,
          unpaidCount: ClientData.unpaidCount,
          overdueAmount: ClientData.overdueAmount.toFixed(2),
          paidAmount: ClientData.paidAmount.toFixed(2),
          totalAmount: ClientData.totalAmount.toFixed(2),
          unpaidAmount: ClientData.unpaidAmount.toFixed(2),
        });
      })
      .catch((error) => console.log(`Error: ${error}`));
  };
  const selectedclient = rows?.find((item) => item?._id === selected[0]);

  const handleRowSelection = (rowId) => {
    setSelected(rowId.slice(-1));
  };

  const filteredData = filterData(
    columns?.map((item) => ({
      id: item.field,
      label: item.headerName,
    })),
    rows,
    searchText
  );

  return (
    <>
      <div className="row align-items-center">
        <div
          className="col-md-4 mt-2 mb-2 m-auto"
          style={{ transform: "translate(-62px, 0px)" }}
        >
          <div className="d-flex justify-content-around">
            <div
              className=" statusCount"
              style={{ color: "#000", fontweight: "500" }}
            >
              <h6>Total</h6>
              <h5>{invoiceCount.totalCount}</h5>
              <p>£ {invoiceCount.totalAmount}</p>
            </div>
            <div className=" statusCount" style={{ color: "#219653" }}>
              <h6>Paid</h6>
              <h5>{invoiceCount.paidCount}</h5>
              <p>£ {invoiceCount.paidAmount}</p>
            </div>
            <div className=" statusCount" style={{ color: "#F2994A" }}>
              <h6>Unpaid</h6>
              <h5>{invoiceCount.unpaidCount}</h5>
              <p>£{invoiceCount.unpaidAmount}</p>
            </div>
            <div className=" statusCount" style={{ color: "#EB5757" }}>
              <h6>Overdue</h6>
              <h5>{invoiceCount.overdueCount}</h5>
              <p>£ {invoiceCount.overdueAmount}</p>
            </div>
          </div>
        </div>
        {/* <div className="col-md-3">
          <div className="d-flex justify-content-end">
            <Dropdown className="themeBtn2">
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                All
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item>Action</Dropdown.Item>
                <Dropdown.Item>Another action</Dropdown.Item>
                <Dropdown.Item>Something else</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div> */}
      </div>
      <InvoiceAction
        selected={selectedclient}
        rowData={rows}
        invoiceTemplateHandler={props.invoiceTemplateHandler}
        getClientRowData={applyDateFilter}
      />
      <CareMagnusTable
        tableHeight='60vh'
        idParam='_id'
        columns={columns}
        rows={filteredData}
        selectedRow={selected}
        handleRowSelection={handleRowSelection}
      />
    </>
  );
}
