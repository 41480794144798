import { Switch, Route, Link, useRouteMatch, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import MultiSiteRoutes from "./MultiSiteRoutes";
import { currentCareSiteId } from "../../redux-toolkit/reducer/switchSites";

export default function MultiSites() {
    const location = useLocation();
    let { path, url } = useRouteMatch();

    let { careSitesData } = useSelector((state) => state.caresiteDataReducer);

    const [currentSiteData, setCurrentSiteData] = useState(null);

    useEffect(() => {
        if (careSitesData) {
            console.log(careSitesData, 'careSitesData')
            let currentsite = careSitesData?.find(item => item?._id === currentCareSiteId);
            setCurrentSiteData(currentsite);
        }
    }, [careSitesData]);

    return (
        <div className="page-wrapper">
            <div className="card">
                <div className="card-body">
                    <div className="top_menubar">
                        <ul
                            className="nav nav-tabs ract_tab_list border-0 listspaceGap"
                            id="myTab"
                            role="tablist"
                        >
                            <li className="nav-item" role="presentation">
                                <button className="nav-link settingsColor">
                                    MultiSite Dashboard {">"}
                                </button>
                            </li>

                            <li className="nav-item" role="presentation">
                                <Link
                                    to={`${url}/operations`}
                                    className={`nav-link ${window.location.href.includes("operations") ? "active" : ""
                                        }`}
                                >
                                    Operations
                                </Link>
                            </li>
                            <li className={`nav-item`} role="presentation">
                                <Link
                                    to={`${url}/finance`}
                                    className={`nav-link ${window.location.href.includes("finance")
                                        ? "active"
                                        : ""
                                        }`}
                                >
                                    Finance
                                </Link>
                            </li>
                        </ul>
                    </div>

                    <Switch>
                        <Route path={`${path}/:topicId`}>
                            <MultiSiteRoutes />
                        </Route>
                    </Switch>
                </div>
            </div>
        </div>
    );
}
