import React, { useState, Fragment, useEffect } from "react";
import "./signup.css";
import useInput from "../../hooks/use-input";
import FormButton from "./FormNextButton";
import { useDispatch, useSelector } from "react-redux";
import {
  changeButton,
  trackId,
  trackSignup
} from "../../redux-toolkit/reducer/accountCreationApiReducer";
import SnackBar from "../../components/SnackBar/SnackBar";
import { AccessManagementDefaultRoles } from "../../constants/roles";
import { useHistory, useLocation } from "react-router-dom";
import { fetch4 } from "../../Apis/commonApis";
import { websiteRefreshHandler } from "../../redux-toolkit/reducer/websitePlan";
import PhoneInput from "react-phone-input-2";
import swal from "sweetalert";

let emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

const isEmail = (value) => value.match(emailRegex);
const ismobileNumber = (value) => value.length == 10;
const isEmpty = (value) => value.trim() !== "";

export default function StepThreeAdminDetails() {
  const { stepNo, step, ownerId } = useSelector((state) => state.accountCreationApiReducer);
  const { userIpData } = useSelector((state) => state.websitePlans);

  const [inValid, setInvalid] = useState(false);
  const [status, setStatus] = useState("info");
  const [adminRadio, setAdminRadio] = useState("no");

  // ==========================  Phone Number Input Library COde  =============== //
  const [isCountryCode, setcuntrycode] = useState("");
  const [isDialCode, setDialCode] = useState("");
  const [mobileNo, setmobileNo] = useState("");

  const onRadioHandler = (event) => {
    // console.log(event.target.value);
    setAdminRadio(event.target.value);
  };

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const stateData = location.state;

  const registerAdmin = async (body) => {
    const result = await fetch4(
      `${process.env.REACT_APP_BASEURL}/register`,
      body
    );
    if (result.status)
      swal("Success", result.message || "", "success").then((value) => {
        history.push(`/admin/purchagePlan${location?.search ?? ''}`, {
          ownerId: ownerId,
          updatePlan: false,
          newCareSite: false,
          adminId: result.adminData._id,
          trackIdParam: trackId ? trackId : stateData?.trackIdParam,
        });
      });
    else {
      swal("Failed", result.message || "", "error").then((value) => {
        return;
      });
    }
  };
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const isFormValidCheck = () => {
    if (adminRadio === "no") {
      if (
        emailAdminIsValid &&
        firstNameAdminIsValid &&
        lastNameAdminIsValid &&
        mobileNo !== ""
      ) {
        let data = {
          email: emailAdminValue,
          firstName: firstNameAdminValue,
          lastName: lastNameAdminValue,
          mobileNo: mobileNo,
          timeZone: userTimezone,
          userType: "admin",
          ownerId: ownerId,
          role: AccessManagementDefaultRoles[1],
        };
        setInvalid(false);
        let trackData2 = {
          step: 2,
          _id: trackId ? trackId : stateData?.trackIdParam,
          ownerId: ownerId,
          setp_2_Details: data
        }
        dispatch(trackSignup(trackData2));
        registerAdmin(data);
        dispatch(websiteRefreshHandler());

        // dispatch(nextStepFuncThirdForm(data));
        // nextStepChangeHandler();
      } else {
        // console.log("clicked!!");
        dispatch(websiteRefreshHandler());

        setInvalid(true);
        setStatus("error");
        setTimeout(() => {
          setInvalid(false);
        }, 4000);
      }
    } else {
      setInvalid(false);
      dispatch(websiteRefreshHandler());
      let trackData2 = {
        step: 2,
        _id: trackId ? trackId : stateData?.trackIdParam,
        ownerId: ownerId,
        setp_2_Details: {
          ownerIsAdmin: true
        }
      }
      trackSignupProcess(trackData2);
      // nextStepChangeHandler();
    }
  };

  const trackSignupProcess = async (body) => {
    const result = await fetch4(
      `${process.env.REACT_APP_BASEURL}/tracksignup`,
      body
    );
    if (result.status) {
      history.push(`/admin/purchagePlan${location?.search ?? ''}`, {
        ownerId: ownerId,
        trackIdParam: trackId ? trackId : stateData?.trackIdParam,
        updatePlan: false,
        newCareSite: false
      });
    };
  }

  function phoneInput(value, data) {
    setmobileNo(value);
    setDialCode(`+${data.dialCode}`);
    setcuntrycode(`${data.countryCode}`);
  }

  function nextStepChangeHandler() {
    dispatch(
      changeButton({
        currentNo: stepNo,
        buttonText: "Create Account",
        stepNo: stepNo + 1,
        nextNo: stepNo + 1,
      })
    );
  }
  const [state, setState] = React.useState({
    billing: false,
  });
  const {
    value: emailAdminValue,
    isValid: emailAdminIsValid,
    hasError: emailAdminHasError,
    valueChangeHandler: emailAdminChangeHandler,
    inputBlurHandler: emailAdminBlurHandler,
  } = useInput(isEmail);

  const {
    value: firstNameAdminValue,
    isValid: firstNameAdminIsValid,
    hasError: firstNameAdminHasError,
    valueChangeHandler: firstNameAdminChangeHandler,
    inputBlurHandler: firstNameAdminBlurHandler,
  } = useInput(isEmpty);

  const {
    value: lastNameAdminValue,
    isValid: lastNameAdminIsValid,
    hasError: lastNameAdminHasError,
    valueChangeHandler: lastNameAdminChangeHandler,
    inputBlurHandler: lastNameAdminBlurHandler,
  } = useInput(isEmpty);

  const {
    value: mobileNumberAdminValue,
    isValid: mobileNumberAdminIsValid,
    hasError: mobileNumberAdminHasError,
    valueChangeHandler: mobileNumberAdminChangeHandler,
    inputBlurHandler: mobileNumberAdminBlurHandler,
  } = useInput(ismobileNumber);

  const emailAdminClasses = emailAdminHasError
    ? "form-control invalid text_start"
    : "form-control text_start";
  const firstNameAdminClasses = firstNameAdminHasError
    ? "form-control invalid text_start"
    : "form-control text_start";
  const lastNameAdminClasses = lastNameAdminHasError
    ? "form-control invalid text_start"
    : "form-control text_start";
  const mobileNumberAdminClasses = mobileNumberAdminHasError
    ? "form-control invalid"
    : "form-control";

  return (
    <Fragment>
      <div
        className="col-md-4 form_box"
        style={{ display: `${step === "step3" ? "block" : "none"}` }}
      >
        <form>
          <h4> Care Site Admin Details</h4>
          <div className="col-md-12 contentCreate">
            <p>Is the Account Owner also the Admin of this Care Site?</p>
            <label className="checkbox me-3">
              <input
                type="radio"
                name="adminType"
                value="yes"
                checked={adminRadio === "yes"}
                onChange={onRadioHandler}
              />
              <span className="checkmark"></span>
              Yes
            </label>
            <label className="checkbox me-3">
              <input
                type="radio"
                name="adminType"
                value="no"
                checked={adminRadio === "no"}
                onChange={onRadioHandler}
              />
              <span className="checkmark"></span>
              No
            </label>
            {adminRadio === "yes" && (
              <div className="col-md-12 contentCreate mt-2"></div>
            )}
            {adminRadio === "no" && (
              <div className="col-md-12 contentCreate mt-2">
                <p>
                  Create Admin of this Care Site by completing the details below
                </p>
                <input
                  type="email"
                  id="emailAdmin"
                  value={emailAdminValue}
                  onChange={emailAdminChangeHandler}
                  onBlur={emailAdminBlurHandler}
                  className={emailAdminClasses}
                  placeholder="Email"
                />
                {emailAdminHasError && (
                  <span className="input_error">
                    Please enter a valid email address.
                  </span>
                )}
                <input
                  type="text"
                  id="firstNameAdmin"
                  value={firstNameAdminValue}
                  onChange={firstNameAdminChangeHandler}
                  onBlur={firstNameAdminBlurHandler}
                  className={firstNameAdminClasses}
                  placeholder="First Name"
                />
                {firstNameAdminHasError && (
                  <span className="input_error">Please Enter First Name</span>
                )}
                <input
                  type="text"
                  id="lastName"
                  value={lastNameAdminValue}
                  onChange={lastNameAdminChangeHandler}
                  onBlur={lastNameAdminBlurHandler}
                  className={lastNameAdminClasses}
                  placeholder="Last Name"
                />
                {lastNameAdminHasError && (
                  <span className="input_error">Please Enter last Name</span>
                )}

                <div className="col-md-12 form-group">
                  <div className="position-relative text_start">
                    <PhoneInput
                      country={userIpData?.country?.toLowerCase()}
                      inputProps={{
                        name: "phone",
                        required: true,
                        autoFocus: true,
                      }}
                      isValid={(value, country) => {
                        if (
                          value.match(
                            /\+?\d{1,9}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/g
                          )
                        ) {
                          return true;
                        } else {
                          return false;
                        }
                      }}
                      onChange={phoneInput}
                      defaultErrorMessage="Invalid Number"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <FormButton isFormValidCheck={isFormValidCheck} />
        </form>
        <SnackBar isInvalid={inValid} status={status} />
      </div>
    </Fragment>
  );
}
