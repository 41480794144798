import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  deleteFetch,
  fetch3,
  fetch4,
  httpSuperAdmin,
  sortByDateDescend,
} from "../../Apis/commonApis";
import { twoDecimalPlaces } from "../../utils/utilsHandlerFunctions";
import { sortByLatestDateFirst, sortDataByKey } from "../../utils/CommonFunctions";
//security_question / approval_email / false

const initialState = {
  loading: false,
  CareSiteIds: [],
  selectedIds: [],
  blogIds: [],
  deleteBlogCount: 0,
  deleteBlogMessage: "",
  deleteBlogStatus: false,
  deleteSiteCount: 0,
  deleteSiteMessage: "",
  deleteSiteStatus: false,
  deleteCareSiteBefore7YearCount: 0,
  deleteCareSiteBefore7YearMessage: "",
  deleteCareSiteBefore7YearStatus: false,
  invoiceAllData: [],
};

export const getInvoiceData = createAsyncThunk("getInvoiceData", async () => {
  const res = await httpSuperAdmin("getInvoiceSuperAdmin", null);
  return res;
});

export const deleteCareSite = createAsyncThunk(
  "deleteCareSite",
  async (body) => {
    const result = await fetch4(
      `${process.env.REACT_APP_SUPERADMIN_BASEURL}/deleteCareSite`,
      {
        ids: body.ids,
        clientModule: body.isClientModule ?? false
      },
      {
        Authorization: localStorage.getItem("superadmin_token"),
      }
    );
    return result;
  }
);

export const unblockCareSite = createAsyncThunk(
  "editSubAndTrialExpiry",
  async (body) => {
    const result = await fetch4(
      `${process.env.REACT_APP_SUPERADMIN_BASEURL}/editSubAndTrialExpiry`,
      {
        ids: body.ids,
      },
      {
        Authorization: localStorage.getItem("superadmin_token"),
      }
    );
    return result;
  }
);

export const deleteCareSiteBefore7Year = createAsyncThunk(
  "deleteCareSiteBefore7Year",
  async (body) => {
    const result = await fetch4(
      `${process.env.REACT_APP_SUPERADMIN_BASEURL}/deleteCareSiteBefore7Year`,
      {
        ids: body.ids,
        clientModule: body.isClientModule ?? false
      },
      {
        Authorization: localStorage.getItem("superadmin_token"),
      }
    );
    return result;
  }
);

export const deleteBlog = createAsyncThunk("deleteBlog", async (body) => {
  const result = await deleteFetch(
    `${process.env.REACT_APP_SUPERADMIN_BASEURL}/deleteBlog`,
    {
      blogId: body.ids,
    },
    {
      Authorization: localStorage.getItem("superadmin_token"),
    }
  );
  return result;
});

const SuperAdminReducer = createSlice({
  name: "SuperAdminReducer",
  initialState,
  reducers: {
    selectMultipleIds(state = initialState, action) {
      state.CareSiteIds = action.payload.ids;
    },
    selectMultipleBlogIds(state = initialState, action) {
      state.blogIds = action.payload.ids;
    },
    backToNormal(state = initialState, action) {
      state.loading = action.payload.loading;
      state.deleteSiteCount = action.payload.deleteSiteCount;
      state.CareSiteIds = action.payload.CareSiteIds;
      state.selectedIds = action.payload.selectedIds;
      state.deleteSiteMessage = action.payload.deleteSiteMessage;
      state.deleteSiteStatus = action.payload.deleteSiteStatus;
    },
  },
  extraReducers: {
    [deleteCareSite.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.deleteSiteStatus = payload.status;
      state.deleteSiteCount += 1;
      state.deleteSiteMessage = payload.message;
    },
    [deleteCareSiteBefore7Year.fulfilled]: (state, { payload }) => {
      console.log(
        state,
        payload,
        "deleteCareSiteBefore7Year deleteCareSiteBefore7Year"
      );
      state.loading = false;
      state.deleteCareSiteBefore7YearStatus = payload.status;
      state.deleteCareSiteBefore7YearCount += 1;
      state.deleteCareSiteBefore7YearMessage = payload.message;
    },
    [deleteBlog.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.deleteBlogStatus = payload.status;
      state.deleteBlogCount += 1;
      state.deleteBlogMessage = payload.message;
    },
    [getInvoiceData.fulfilled]: (state, { payload }) => {
      if (payload.status) {
        let allInvoice = [];
        payload.invoiceData?.forEach((elem, index) => {
          elem?.invoices.forEach((item, i) => {
            let stripeDate;
            if (item?.invoicesDetails?.created) {
              stripeDate = new Date(
                item?.invoicesDetails?.created * 1000
              ).toISOString();
            }
            let details = item?.invoicesDetails;
            let refId;
            if (item?.invoiceNo) {
              refId = item?.paymentGatewayInvId?.charge
                ? item?.paymentGatewayInvId?.charge
                : item?.paymentGatewayInvId?.id;
            } else {
              refId = item?.charge ? item?.charge : item?.id;
            }
            const invoiceData = item.invoicesDetails ? item.invoicesDetails : item;
            const isManual = item.invoicesDetails ? true : false;
            // allInvoice.push({...invoiceData, isManual, id: invoiceData._id, actualId: invoiceData.id })

            allInvoice.push({
              _id: invoiceData._id, //this is for key values
              id: invoiceData._id,
              sendId: invoiceData.id,
              customInvoiceId: invoiceData?.cusotmInvId ?? details?.invoiceNo,
              customPaymentId: invoiceData?.cusotmPaymentId ?? details?.paymentId,
              careHomeId: elem._id,
              refundId: refId,
              paymentGatewayId: invoiceData.id,
              invoiceId: invoiceData.id || invoiceData?.invoicesDetails?._id || invoiceData?._id,
              companyName: elem.companyName,
              careSiteName: elem.careSiteName,
              chargesDetails: invoiceData?.invoicesDetails?.chargesDetails ?? elem?.chargesDetails ?? invoiceData?.chargesDetails,
              SubscriptionPlan: elem.SubscriptionPlan,
              type: elem.type ?? "",
              status: invoiceData?.manualInvApprovalStatus ?? invoiceData?.status ?? "",
              paymentThrough: elem.paymentThrough ?? "",
              planType: elem.SubscriptionPlan ?? "",
              generationType: elem.generationType ?? "",
              fromName: invoiceData?.fromName ?? "",
              fromAddress: invoiceData?.fromAddress ?? "",
              fromContact: invoiceData?.fromContact ?? '',
              fromEmail: invoiceData?.fromEmail ?? "",
              toName: invoiceData?.toName ?? elem.careSiteName,
              toAddress: invoiceData?.toAddress ?? elem.billingAddress,
              templateFor: invoiceData?.templateFor ?? "",
              toEmail: invoiceData?.toEmail ?? elem.toEmail,
              toContactNo: invoiceData?.toContactNo ?? elem.billingContact ?? "",
              invoiceNo: invoiceData?.invoiceNo ?? invoiceData?.cusotmInvId,
              invoiceDate: invoiceData?.invoiceDate ?? stripeDate,
              sortDate: invoiceData?.createdAt ?? invoiceData?.invoiceDate,
              invoicePeriod: invoiceData?.invoicePeriod ?? '-',
              customId: invoiceData?.cusotmInvId ?? invoiceData?.invoiceNo,
              taxRate: +invoiceData?.tax ?? 0,
              totalTax: invoiceData?.totalTax ?? 0,
              amountDue: invoiceData?.amountDue ?? null,
              currency: invoiceData?.currency ?? '-',
              isManualInvoice: isManual,
              invoiceDeadline:
                invoiceData?.invoiceDeadline ??
                (elem?.paymentThrough === "stripe"
                  ? invoiceData?.due_date
                  : invoiceData?.charge_date),
              total: invoiceData?.total ?? invoiceData?.amount ?? invoiceData?.amountDue,
              totalAmount: elem.total_amount ?? 0,
            });
          });
        });
        state.invoiceAllData = sortByLatestDateFirst(allInvoice, 'sortDate');
      }
    },
  },
});

export const { selectMultipleIds, selectMultipleBlogIds, backToNormal } =
  SuperAdminReducer.actions;
export default SuperAdminReducer.reducer;
