import React from "react";

const Test = () => {
  const handleDownloadClick = () => {
    // Simulating some content to be downloaded
    const content = "<html><body><h1>Hello, world!</h1></body></html>";

    // Create a Blob from the content
    const blob = new Blob([content], { type: "text/html" });
    // Create a temporary URL for the Blob
    const url = URL.createObjectURL(blob);
    console.log(blob, url, "blob");

    // Create a temporary anchor element
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = "downloaded-page.html"; // Specify the desired file name

    // Append the anchor to the body
    document.body.appendChild(downloadLink);

    // Click the link to trigger the download
    downloadLink.click();

    // Remove the temporary anchor element
    document.body.removeChild(downloadLink);

    // Release the URL object
    URL.revokeObjectURL(url);
  };

  return (
    <div>
      <h1>Download Page Example</h1>
      <button onClick={handleDownloadClick}>Download Page</button>
    </div>
  );
};

export default Test;
