import React, { useEffect, useState } from "react";
import countryList from "./countries.json";
import CircularProgress from "@mui/material/CircularProgress";
import SnackBar from "../../components/SnackBar/SnackBar";
import { httpPayments } from "../../Apis/commonApis";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ShowSwal } from "../../utils/alertSwal";
import { currentCareSiteId } from "../../redux-toolkit/reducer/switchSites";
// import { useParams } from "react-router-dom";
const schemeArray = [
  { scheme: "ach", currency: "USD" },
  { scheme: "pad", currency: "CAD" },
  { scheme: "sepa_core", currency: "EUR" },
  { scheme: "autogiro", currency: "SEK" },
  { scheme: "bacs", currency: "GBP" },
  { scheme: "becs", currency: "AUD" },
  { scheme: "becs_nz", currency: "NZD" },
  { scheme: "betalingsservice", currency: "DKK" }
];

export default function GoCardlessPayment(props) {
  const { cartData, adminData } = props;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    clearErrors,
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [inValid, setInvalid] = useState(false);
  const [status, setStatus] = useState("info");

  const location = useLocation();
  const { ownerId, newCareSite, adminId, trackIdParam } = location.state;

  const isFormValidCheck = (postdata) => {
    const subscriptionData = adminData?.subscriptionPlanData?.find(item => item._id === currentCareSiteId);
    let redirectUrl = "";
    let trackid = trackIdParam ? trackIdParam : 0;
    let admin_id = adminId ? adminId : 0;

    const baseUrl = process.env.REACT_APP_REDIRECTION_URL_PLAN;
    console.log(baseUrl, 'baseUrl');
    if (newCareSite) {
      redirectUrl = `${baseUrl}/multistepsignupform/newCareSite/${ownerId}/${admin_id}/${trackid}`;
      // redirectUrl = `http://localhost:8700/multistepsignupform/newCareSite/${ownerId}/${admin_id}/${trackid}`;
    } else if (cartData === 0) {
      redirectUrl = `${baseUrl}/admin/profile/success/update-billing-success/${ownerId}/${subscriptionData?._id ?? 'NA'}`;
      // redirectUrl = `http://localhost:8700/admin/profile/success/update-billing-success/${ownerId}/${subscriptionData?._id ?? 'NA'}`;
    } else {
      redirectUrl = `${baseUrl}/multistepsignupform/paymentStatus/${ownerId}/${admin_id}/${trackid}`;
      // redirectUrl = `http://localhost:8700/multistepsignupform/paymentStatus/${ownerId}/${admin_id}/${trackid}`;
    }
    let newCareSiteData;
    if (newCareSite) {
       newCareSiteData = JSON.parse(localStorage.getItem("createNewCareSiteData"));
    }
    const gocardlesscountry = countryList?.countries?.find((ele) => ele.name === adminData?.country);
    let cityVal = adminData.city ? adminData.city : adminData.billingAddress.split(",")[1];
    let postalVal = adminData.postalCode ? adminData.postalCode : adminData.billingAddress.split(",")[2];
    const findScheme = schemeArray.find(item => item.currency === gocardlesscountry?.currency?.code);
    const careSiteName = newCareSiteData ? newCareSiteData?.careSiteName : subscriptionData?.careSiteName ?? adminData?.careSiteName;
    let data = {
      billingAddress: subscriptionData ? subscriptionData.billingAddress : postdata.billingAddress,
      billingEmail: postdata.email,
      careSiteName: careSiteName,
      ownerFirstName: adminData.firstName,
      ownerLastName: adminData.lastName,
      couponCode: cartData?.promoName,
      paymentThrough: "gocardless",
      city: cityVal?.trim(),
      postal_code: postalVal?.trim(),
      companyLastName: adminData.companyName,
      redirect_uri: redirectUrl,
      scheme: findScheme ? findScheme.scheme : 'bacs',
      country_code: gocardlesscountry?.isoAlpha2 ?? 'UK'
    };
    data.country = gocardlesscountry?.name;
    data.currency = gocardlesscountry?.currency?.code;
    setLoading(true);
    createRedirctFlow(data);
    localStorage.setItem("goCardlessData", JSON.stringify(data));
  };

  const createRedirctFlow = async (body) => {
    const res = await httpPayments(`createRedirctFlow`, body);
    if (res.status) {
      localStorage.setItem("startPayApiData", JSON.stringify(res));
      window.location.replace(res.data.redirect_url);
    } else {
      ShowSwal(res.status, 'Something went wrong!');
    }
    setLoading(false);
  };

  useEffect(() => {
    if (adminData) {
      setValue('email', adminData?.email);
      const subscriptionData = adminData?.subscriptionPlanData?.find(item => item._id === currentCareSiteId);
      setValue('billingAddress', subscriptionData ? subscriptionData?.billingAddress : adminData?.billingAddress);
    }
  }, [adminData]);

  const containerWidth = {
    maxWidth: "700px",
  };

  return (
    <>
      <div
        className={`${cartData === 0 ? "" : "stripeContainer"} container`}
        style={cartData === 0 ? containerWidth : { maxWidth: "1000px" }}
      >
        {/* <div className="py-5 text-center">
        <h4>Stripe Integration - <a href="https://www.cluemediator.com/" target="_blank">Clue Mediator</a></h4>
      </div> */}

        <div className="row s-box">
          <React.Fragment>
            {cartData === 0 ? (
              ""
            ) : (
              <div className="col-md-5 order-md-2 mb-4">{cart(cartData)}</div>
            )}
            <div
              className={`${cartData === 0 ? "col-md-12" : "col-md-7"
                } order-md-1`}
            >
              <h4 className="d-flex justify-content-between align-items-center mb-3">
                <span className="text-muted">Payment Details</span>
              </h4>
              {/* <h4 className="fw-normal">Payment Details</h4> */}
              <div className="col-md-12 order-md-1">
                {/* <p className="d-flex justify-content-center">
                Fill the details to procced for payment of £{" "}
                {planDetails?.unit_amount}
              </p> */}
                <div className="col-md-12">
                  <form>
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <label htmlFor="cc-name">Billing Email</label>
                          <input
                            type="email"
                            id="email"
                            className="form-control"
                            {...register("email", {
                              required: true,
                              pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            })}
                            disabled={
                              adminData && adminData.billingAddressStatus
                            }
                          />
                          {errors?.email?.type === "required" && (
                            <p className="error_style error_bottom">This field is required</p>
                          )}
                          {errors?.email?.type === "pattern" && (
                            <p className="error_style error_bottom">Invalid email address</p>
                          )}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <label htmlFor="cc-name">Billing Address</label>
                          <input
                            type="text"
                            id="billingAddress"
                            className="form-control"
                            {...register("billingAddress", {
                              required: true,
                            })}
                            disabled={
                              adminData && adminData.billingAddressStatus
                            }
                          />
                          {errors?.billingAddress?.type === "required" && (
                            <p className="error_style error_bottom">This field is required</p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 submitBtn">
                      <button
                        type="button"
                        className="form-control btn"
                        style={{ display: "inline-block" }}
                        onClick={handleSubmit(isFormValidCheck)}
                      >
                        {cartData?.unit_amount > 0 && `Pay ${cartData?.selectedCurrency?.symbol} ${cartData?.unit_amount}`}
                        {cartData?.unit_amount === 0 && `Add Payment Method`}
                        {cartData === 0 && `Change/Update Billing Method`}
                        {cartData < 0 && `Pay`}
                      </button>
                    </div>
                    {loading && (
                      <div className="loaderPage">
                        <CircularProgress className="spinnerLoader" />
                      </div>
                    )}
                  </form>
                  <SnackBar
                    isInvalid={inValid}
                    status={status}
                    hideDuration={4000}
                    textmessage="All fields are required"
                  />
                </div>
              </div>
            </div>
          </React.Fragment>
        </div>
      </div>
    </>
  );
}

const cart = (cartData) => {
  const currencySymbol = cartData?.selectedCurrency?.symbol;
  const cartCount = (cartData.amount_off > 0 && cartData.licsCount > 0) ? '3' : (cartData.amount_off <= 0 && cartData.licsCount <= 0) ? '1' : '2';
  return (
    <React.Fragment>
      <h4 className="d-flex justify-content-between align-items-center mb-3">
        <span className="text-muted">Your cart</span>
        <span className="badge bg-secondary badge-pill">{cartCount}</span>
      </h4>
      <ul className="list-group mb-3">
        <li className="list-group-item d-flex justify-content-between lh-condensed">
          <div>
            <h6 className="my-0">Subscription Plan</h6>
            <small className="text-muted">{cartData.planName}</small>
          </div>
          <span className="text-muted">{`${currencySymbol} ${cartData?.planPrice}`}</span>
        </li>
        <li className="list-group-item d-flex justify-content-between lh-condensed">
          <div>
            <h6 className="my-0">Friends & Family Add-On</h6>
            <small className="text-muted">Count {cartData.licsCount}</small>
          </div>
          <span className="text-muted">
            {currencySymbol}&nbsp;
            {cartData.frndFamAmt ? (cartData.frndFamAmt * cartData.licsCount) : 0}
          </span>
        </li>
        {/* <li className="list-group-item d-flex justify-content-between lh-condensed">
        <div>
          <h6 className="my-0">Third item</h6>
          <small className="text-muted">Brief description</small>
        </div>
        <span className="text-muted">₹500</span>
      </li> */}
        <li className="list-group-item d-flex justify-content-between bg-light">
          <div className="text-success">
            <h6 className="my-0">Promo code</h6>
            <small>{cartData.promoName}</small>
          </div>
          <span className="text-success">- {`${currencySymbol} ${cartData.amount_off}`}</span>
        </li>

        <li className="list-group-item d-flex justify-content-between">
          <span>Total ({cartData?.selectedCurrency?.currency})</span>
          <strong>{`${currencySymbol} ${cartData.totalBeforeTax}`}</strong>
        </li>
        <li className="list-group-item d-flex justify-content-between bg-light">
          <div className="text-success">
            <h6 className="my-0">Tax</h6>
            {/* <small>{cartData.countryName}</small> */}
          </div>
          <span className="text-success">+{cartData.taxRate} %</span>
        </li>
        <li className="list-group-item d-flex justify-content-between">
          <span>Total (After Tax)</span>
          <strong> {`${currencySymbol} ${cartData.unit_amount}`}</strong>
        </li>
      </ul>
    </React.Fragment>
  );
};
