import React from 'react';
import Chart from 'react-apexcharts';
import { NavLink } from "react-router-dom";

export default function DonutSales(props) {
    const { colors, series, midTitle, rate, labels } = props;

    let colorarray = ['#c580d1', '#219653', '#2f80ed', '#818181', '#eb5757', '#42c369'];

    const options = {
        colors: colors,
        annotations: {
            position: "front",
        },
        labels: labels,
        legend: {
            show: false,
            customLegendItems: [
                "skldlfkasldk",
                "sdjhj",
                "sd",
                "sbd",
                "asbdamhms",
                "aaa",
            ],
        },

        dataLabels: {
            enabled: false,
        },

        tooltip: {
            enabled: true,
        },

        plotOptions: {
            pie: {
                size: 800
            }
        }
    };


    return (
        <div className="graph_box_in">
            <div className="donut">
                <Chart options={options} series={series}  type="donut" width="100%" height="100%" />
            </div>
            <span className="count">
                {rate > 0 ? rate : 0} % <br />
                {midTitle}
            </span>
        </div>
    )
}

