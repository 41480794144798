import React, { useEffect, useState, useRef, Fragment, useMemo } from "react";
import cx from "./Payroll.module.css";
import "./PayrollCareTeamProfile.css";
import { TbArrowBarToDown } from "react-icons/tb";
import { AiOutlinePrinter } from "react-icons/ai";
import { httpAdmin } from "../../Apis/commonApis";
import { FaRegFileAlt } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import html2canvas from 'html2canvas';
import { AiOutlineClose } from "react-icons/ai";
import { AiFillPlusCircle } from "react-icons/ai";
import { formatDateToDDMM, getFullName, getMondayDate } from "../../utils/CommonFunctions";
import { ShowInfoSwal, ShowSwal } from "../../utils/alertSwal";
import { useSelector } from "react-redux";
import JsonDownload from "../../components/DownloadExcel/DownloadExcel";

const default_userImg = "/images/profle.jpg";
let today = new Date();

export default function OverviewDetails(props) {
    const { overviewId, handleDetailShowHide, dateRange, filterType } = props;
    const [startDate, endDate] = dateRange;

    let { currentCareSiteData, accessRoles, userDataLatest } = useSelector((state) => state.caresiteDataReducer);
    const moduleAccess = useMemo(() => {
        const findCurrentModule = accessRoles?.find(item => item.moduleName === 'Payroll');
        return findCurrentModule;
    }, [accessRoles]);

    const divRef = useRef(null);

    let initialFormData = {
        careteamId: "",
        payslipId: "",
        grossWage: '',
        bonus: '',
        reimbursements: '',
        taxAmount: '',
        insurance: '',
    }

    const [paySlipData, setPaySlipData] = useState(null);
    const [paySlipDetail, setPaySlipDetail] = useState(null);
    const [downloadData, setDownloadData] = useState(null);
    const [payHistory, setPayHistory] = useState([]);
    const [paySlipFormData, setPaySlipFormData] = useState(initialFormData);
    const [showModal, setShowModal] = useState(false);
    const [fieldName, setFieldName] = useState('');
    const [fieldAmt, setFieldAmt] = useState('');
    const [otherIncome, setOtherIncome] = useState([]);
    const [deductibles, setDeductibles] = useState([]);
    const [err, setErr] = useState('');

    useEffect(() => {
        if (filterType === "custom" && endDate) {
            getSlipData(startDate, endDate);
        } else if (filterType === "week") {
            let mondayDate = getMondayDate();
            getSlipData(mondayDate, today);
        } else if (filterType === "today") {
            // getSlipData(today, today);
        }
    }, [filterType, dateRange]);

    useEffect(() => {
        if (paySlipData) {
            filterPayHistory();
        }
    }, [paySlipData, paySlipDetail]);

    useEffect(() => {
        if (paySlipData) {
            setDownloadData([{
                ['Employer Name']: currentCareSiteData?.careSiteName,
                ['Employer Address']: currentCareSiteData?.address ?? userDataLatest?.address,
                ['Employee Name']: getFullName(paySlipData),
                ['Job Title']: paySlipData?.jobTitle,
                ['Employment Start Date']: paySlipData?.startDate,
                ['Pay period']: paySlipDetail?.duration?.from?.split("T")[0] + ' ' + paySlipDetail?.duration?.to?.split("T")[0],
                ['Regular Hours Threshold per Week']: paySlipData?.weeklyPlannedHours,
                ['Pro-rated Regular Hours Worked']: paySlipDetail?.regularHours,
                ['Pro-rated Overtime Hours Worked']: paySlipDetail?.overTimeHours,
                ['Total Hours Worked']: paySlipDetail?.totalHours,
            }])
        }
    }, [paySlipData, currentCareSiteData]);

    const getSlipData = async (start, end) => {
        const result = await httpAdmin(
            `getPayslip?careteamId=${overviewId}&startDate=${start}&endDate=${end}`, 'get',
            null, null
        );
        if (result.status) {
            let paydata = result?.data?.payslip;
            let paydataLength = paydata.length;
            if (paydataLength > 0) {
                setPaySlipDetail(paydata[paydataLength - 1]);
                setOtherIncome(paydata[paydataLength - 1]?.otherIncome);
                setDeductibles(paydata[paydataLength - 1]?.deductables);
            } else {
                setPaySlipDetail(null);
                setOtherIncome([]);
                setDeductibles([]);
            }
            setPaySlipData(result.data);
        }
    };

    function filterPayHistory() {
        let fromDate = new Date(paySlipDetail?.duration?.from).getTime();
        let toDate = new Date(paySlipDetail?.duration?.to).getTime();
        let rowHistory = [];

        paySlipData?.ratesHistory?.forEach((elem) => {
            let starts = new Date(elem?.startDate).getTime();
            let ends = new Date(elem?.endDate).getTime();
            if (ends < toDate) {
                rowHistory.push({
                    from: starts > fromDate ? starts : fromDate,
                    to: ends,
                    hourlyRate: elem?.wagesPerhour,
                    otr: elem?.overtimeRate,
                });
            } else if (starts > fromDate && starts < toDate) {
                rowHistory.push({
                    from: starts,
                    to: ends > toDate ? toDate : ends,
                    hourlyRate: elem?.wagesPerhour,
                    otr: elem?.overtimeRate,
                });
            }
        });
        setPayHistory(rowHistory);
    }

    const handleEdit = async (selectedDate) => {
        if (moduleAccess?.access === 'full') {

            let data = {
                careteamId: paySlipData?._id,
                payslipId: paySlipDetail?._id,
            }
            if (selectedDate) {
                data.paymentDate = selectedDate;
            } else {
                data.grossWage = paySlipDetail?.grossWage;
                data.otherIncome = otherIncome;
                data.deductables = deductibles;
            }
            let result = await httpAdmin('editPayslip', 'post', data, null);
            if (result.status) {
                let paydata = result?.data?.payslip;
                paydata.forEach(elem => {
                    if (paySlipDetail?._id === elem._id) {
                        setPaySlipDetail(elem);
                        setOtherIncome(elem.otherIncome);
                        setDeductibles(elem.deductables);
                    }
                });
                setPaySlipData(result.data);
            }
            ShowSwal(result.status, result.message);
            handleCloseModal();
        } else {
            ShowInfoSwal('Your user Role does not have access to this section')
        }
    };

    const handleShowModal = (type) => {
        setPaySlipFormData({
            ...paySlipFormData,
            careteamId: paySlipData?._id,
            payslipId: paySlipDetail?._id,
            grossWage: paySlipDetail?.grossWage,
            bonus: paySlipDetail?.bonus,
            reimbursements: paySlipDetail?.reimbursements,
            taxAmount: paySlipDetail?.taxAmount,
            insurance: paySlipDetail?.insurance,
        });
        setShowModal(type);
    };

    function handlePaySlipClick(row) {
        setPaySlipDetail(row);
        setOtherIncome(row.otherIncome);
        setDeductibles(row.deductables);
    }

    const handleCloseModal = () => {
        setPaySlipFormData(initialFormData);
        setShowModal(false);
    };

    const handleDownload = () => {
        html2canvas(divRef.current).then((canvas) => {
            const link = document.createElement('a');
            link.download = 'div_screenshot.png';
            link.href = canvas.toDataURL();
            link.click();
        });
    };

    const handlePrint = () => {
        const printContents = divRef.current.innerHTML;
        const printWindow = window.open('', '_blank');
        printWindow.document.write(`
          <html>
            <head>
              <title>Print</title>
            </head>
            <body>${printContents}</body>
          </html>
        `);
        printWindow.document.close();
        printWindow.print();
    };

    // -------------- multi start time and end time selection ----------------- //

    const handleTimeChange = (e, name, amount) => {
        e.preventDefault();
        if (name.trim() === '' || amount.trim() === '') {
            setErr('fields');
            return;
        }
        if (showModal === 'otherIncome') {
            setOtherIncome((prev) => [...prev, { name, amount: +amount }]);
        } else {
            setDeductibles((prev) => [...prev, { name, amount: +amount }]);
        }
        setFieldAmt('');
        setFieldName('');
    };

    const timeRemoveChangeHandler = (e, indexToRemove) => {
        e.preventDefault();
        if (showModal === 'otherIncome') {
            const clonefield = otherIncome.slice();
            clonefield.splice(indexToRemove, 1);
            setOtherIncome(clonefield);
        } else {
            const clonefield = deductibles.slice();
            clonefield.splice(indexToRemove, 1);
            setDeductibles(clonefield);
        }
    };

    const paymentDate = paySlipDetail?.paymentDate ? paySlipDetail?.paymentDate?.split('T')[0] : '';

    function handlePaymentDate(e) {
        const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
        let selectedDate = e.target.value;
        if (dateFormatRegex.test(selectedDate) && paymentDate !== selectedDate) {
            handleEdit(selectedDate);
        }
    }
    const commonData = showModal === 'deductibles' ? deductibles : otherIncome;
    const reversedPayrollArray = paySlipData?.payslip?.slice().reverse();

    return (
        <div className="">
            <div className="top_menubar btns_head">
                <a onClick={() => handleDetailShowHide(null)} className="payroll_back" style={{ fontSize: "13px" }}>Back</a>
                <div className="row mt-3">
                    <div className="col-lg-4">
                        <div className="top">
                            <h6>Payroll History</h6>
                            {/* <div className="">
                                <p>All</p>
                            </div> */}
                        </div>
                        <div className="text">
                            <span>{paySlipData?.empPayrollFreq} Frequency</span>
                        </div>
                        {reversedPayrollArray?.length > 0 ? <div className="frequency_div">
                            {reversedPayrollArray?.map((item) => {
                                return (
                                    <div
                                        key={item._id}
                                        onClick={() => {
                                            handlePaySlipClick(item);
                                        }}
                                        className={`content-mid paymonths_style ${item?._id === paySlipDetail?._id
                                            ? "selectedPayMonth"
                                            : ""
                                            }`}
                                    >
                                        <ul className="nov mb-0">
                                            <FaRegFileAlt size={22} />
                                            {/* <img src="../../images/Vectorimg.png" alt="" /> */}
                                            <li style={{ fontSize: '11px' }} className="ms-1">
                                                {item?.empPayrollFreq === 'Weekly' ? <><span>{`${formatDateToDDMM(item.duration?.from)} to ${formatDateToDDMM(item.duration?.to)}`}</span><br /></> : ''}
                                                <span>{new Date(item?.createdAt)?.toLocaleString(
                                                    "default",
                                                    { month: "long" }
                                                )}</span>{" "}
                                                {new Date(item?.createdAt)?.getFullYear()}
                                            </li>
                                        </ul>
                                        <p className="mb-0">
                                            Generated: {item?.createdAt?.split("T")[0]} <br />
                                            Edited:{" "}
                                            {item?.updatedAt
                                                ? item?.updatedAt?.split("T")[0]
                                                : "--"}
                                        </p>
                                    </div>
                                );
                            })}
                        </div> :
                            <div className="text">
                                <span> No History Available in selected time slot</span>
                            </div>}
                    </div>
                    <div className="col-lg-8" ref={divRef}>
                        <div className="overview_header">
                            <div className="col-md-8 d-flex ms-2">
                                <img
                                    src={paySlipData?.image ? paySlipData.image : default_userImg}
                                    className="overview_img"
                                    alt="user"
                                />
                                <p className="mb-0 mx-2">{paySlipData ? getFullName(paySlipData) : ''}</p>
                            </div>
                            <div className="col-md-2">
                                <JsonDownload
                                    fileName="payroll-excel"
                                    downloadfile={downloadData}
                                />
                                {/* <TbArrowBarToDown className="curser_point" onClick={handleDownload} /> */}
                                <AiOutlinePrinter className="ms-3 me-2 curser_point" onClick={handlePrint} />
                            </div>
                            {/* <div className="col-md-2 input-box-button">
                                <button onClick={() => handleShowModal('edit')}>Edit</button>
                            </div> */}

                            {/* <img src="../../images/Ellipse 9.png" alt="" />
                            <p className="input-box-download">
                                <TbArrowBarToDown onClick={handleDownload} />
                            </p>
                            <div className="input-box-button">
                                <button onClick={handleShowModal}>Edit</button>
                            </div>
                            <p className="input-box-printer">
                                <AiOutlinePrinter onClick={handlePrint} />
                            </p> */}
                        </div>
                        <div className={`${cx.detailDiv} Employee-items row`}>
                            <div className="col-md-6">
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <td>Employer Name</td>
                                            <td>
                                                {currentCareSiteData?.careSiteName}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Employer Address</td>
                                            <td>
                                                {currentCareSiteData?.address ?? userDataLatest?.address}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>Employee Name</td>
                                            <td>
                                                {/* {getFullName(paySlipData)} */}
                                                {`${paySlipData?.first_Name ?? ''} ${paySlipData?.last_Name ?? ''}`}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>Job Title</td>
                                            <td>{paySlipData?.jobTitle}</td>
                                        </tr>

                                        <tr>
                                            <td>Employment Start Date</td>
                                            <td>{paySlipData?.startDate}</td>
                                        </tr>

                                        {paySlipDetail && <tr>
                                            <td>Payment Date</td>
                                            <td>
                                                <input className={`${cx.payrollInput}`}
                                                    type="date"
                                                    value={paymentDate}
                                                    onChange={handlePaymentDate} />
                                            </td>
                                        </tr>}

                                        <tr>
                                            <td>Pay period</td>
                                            <td>
                                                {paySlipDetail?.duration?.from?.split("T")[0]}-
                                                {paySlipDetail?.duration?.to?.split("T")[0]}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>Regular Hours Threshold per Week &nbsp; </td>
                                            <td>{paySlipData?.weeklyPlannedHours}</td>
                                        </tr>

                                        <tr>
                                            <td>Pro-rated Regular Hours Worked &nbsp;</td>
                                            <td>{paySlipDetail?.regularHours}</td>
                                        </tr>
                                        <tr>
                                            <td>Pro-rated Overtime Hours Worked &nbsp;</td>
                                            <td>{paySlipDetail?.overTimeHours}</td>
                                        </tr>

                                        <tr>
                                            <td>Total Hours Worked &nbsp;</td>
                                            <td>{paySlipDetail?.totalHours}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className={`${cx.detailDiv} col-md-6`}>
                                <table className="table">
                                    <tbody>
                                        {payHistory?.map((item, index) => {
                                            return (
                                                <Fragment key={index}>
                                                    <tr>
                                                        <td>Hourly Rate</td>
                                                        <td>$ {item.hourlyRate}</td>
                                                        <td>
                                                            {new Date(item.from)
                                                                .toJSON()
                                                                .slice(0, 10)
                                                                .replace(/-/g, "/")}
                                                            -
                                                            {new Date(item.to)
                                                                .toJSON()
                                                                .slice(0, 10)
                                                                .replace(/-/g, "/")}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Over time Rate per Hour &nbsp; </td>
                                                        <td>$ {item.otr}</td>
                                                        <td>
                                                            {new Date(item.from)
                                                                .toJSON()
                                                                .slice(0, 10)
                                                                .replace(/-/g, "/")}-
                                                            {new Date(item.to)
                                                                .toJSON()
                                                                .slice(0, 10)
                                                                .replace(/-/g, "/")}
                                                        </td>
                                                    </tr>
                                                </Fragment>
                                            );
                                        })}

                                    </tbody>
                                </table>
                            </div>
                            {paySlipDetail ? <div className="col-md-12">
                                <div className="d-flex my-2">
                                    <span className="col-md-12"><strong>Income</strong> </span>
                                </div>
                                <div className="d-flex">
                                    <span className="col-md-4">Wage based on hours worked </span>
                                    <span className="col-md-4 text-center">£ {paySlipDetail?.grossWage}</span>
                                    <span className="col-md-4"></span>
                                </div>

                                <div className="d-flex my-2">
                                    <span className="col-md-4"><strong>Other Income</strong> </span>
                                    <span className="col-md-4 text-center"></span>
                                    <span className="col-md-4 add_sign"><AiFillPlusCircle onClick={() => handleShowModal('otherIncome')} /></span>
                                </div>

                                {paySlipDetail?.otherIncome?.map((item, index) => {
                                    return (
                                        <div key={index} className="d-flex">
                                            <span className="col-md-4">{item?.name} </span>
                                            <span className="col-md-4 text-center">£ {item?.amount}</span>
                                            <span className="col-md-4 add_sign"
                                                onClick={(e) => handleShowModal('otherIncome')}><strong>--</strong></span>
                                        </div>
                                    );
                                })}

                                <div className="d-flex my-2">
                                    <span className="col-md-4"><strong>Total Income </strong></span>
                                    <span className="col-md-4 text-center">£ {paySlipDetail?.totalIncome}</span>
                                    <span className="col-md-4"></span>
                                </div>

                                <div className="d-flex my-2">
                                    <span className="col-md-4"><strong>Deductibles</strong></span>
                                    <span className="col-md-4 text-center"></span>
                                    <span className="col-md-4 add_sign"><AiFillPlusCircle onClick={() => handleShowModal('deductibles')} /></span>
                                </div>
                                {paySlipDetail?.deductables?.map((item, index) => {
                                    return (
                                        <div key={index} className="d-flex">
                                            <span className="col-md-4">{item?.name} </span>
                                            <span className="col-md-4 text-center">£ {item?.amount}</span>
                                            <span className="col-md-4 add_sign"
                                                onClick={(e) => handleShowModal('deductibles')}><strong>--</strong></span>
                                        </div>
                                    );
                                })}

                                <div className="d-flex my-2">
                                    <span className="col-md-4"> <strong>Net Income</strong></span>
                                    <span className="col-md-4 text-center"><strong>£ {paySlipDetail?.netWage}</strong></span>
                                    <span className="col-md-4"></span>
                                </div>

                            </div> : ''}
                        </div>
                    </div>
                </div>
            </div>

            <Modal className="viewModal" show={showModal === 'edit'} onHide={handleCloseModal}>
                <Modal.Header>
                    <Modal.Title>
                        <span>Edit</span>
                        <span>
                            <AiOutlineClose
                                className="curser_point"
                                onClick={handleCloseModal}
                            />
                        </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ padding: "10px 20px" }}>
                    <form>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="d-flex justify-content-between">
                                    <div className="col-6 mb-2">
                                        <label className="mb-1">Gross Wage</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            style={{ width: "94%" }}
                                            value={paySlipFormData.grossWage}
                                            onChange={(e) =>
                                                setPaySlipFormData({
                                                    ...paySlipFormData,
                                                    grossWage: +e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="col-6 mb-2">
                                        <label className="mb-1"> Bonus</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            value={paySlipFormData.bonus}
                                            onChange={(e) =>
                                                setPaySlipFormData({
                                                    ...paySlipFormData,
                                                    bonus: +e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="d-flex justify-content-between">
                                    <div className="col-6 mb-2">
                                        <label className="mb-1">Reimbursements</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            style={{ width: "94%" }}
                                            value={paySlipFormData.reimbursements}
                                            onChange={(e) =>
                                                setPaySlipFormData({
                                                    ...paySlipFormData,
                                                    reimbursements: +e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="col-6 mb-2">
                                        <label className="mb-1">Tax Amount</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            value={paySlipFormData.taxAmount}
                                            onChange={(e) =>
                                                setPaySlipFormData({
                                                    ...paySlipFormData,
                                                    taxAmount: +e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <label className="mb-1">Insurance</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    value={paySlipFormData.insurance}
                                    onChange={(e) =>
                                        setPaySlipFormData({
                                            ...paySlipFormData,
                                            insurance: +e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex">
                        <button className="btn" type="button" onClick={() => handleEdit(null)}>
                            Save
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
            <Modal className="viewModal" show={showModal === 'otherIncome' || showModal === 'deductibles'} onHide={handleCloseModal}>
                <Modal.Header>
                    <Modal.Title>
                        <span>{`Add ${showModal === 'deductibles' ? 'Deductibles' : 'Other Income'}`}</span>
                        <span>
                            <AiOutlineClose
                                className="curser_point"
                                onClick={handleCloseModal}
                            />
                        </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ padding: "10px 20px" }}>
                    <form>
                        <div className="row">
                            <div className="col-md-12 mb-2">
                                <div className="d-flex">
                                    <div className="col-5">
                                        <label>Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            // style={{ width: "86%" }}
                                            value={fieldName}
                                            onChange={(e) => {
                                                setFieldName(e.target.value); setErr('');
                                            }}
                                        />
                                    </div>
                                    <div className="col-6 ms-4">
                                        <label className="">Amount</label>
                                        <div className="d-flex">
                                            <input
                                                type="number"
                                                className="form-control"
                                                value={fieldAmt}
                                                onChange={(e) => {
                                                    setFieldAmt(e.target.value); setErr('');
                                                }}
                                            />
                                            <button className="ms-2 btn theme_btn" onClick={(e) => handleTimeChange(e, fieldName, fieldAmt)}>
                                                +
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {err.includes('fields') && (
                                    <span className='error_style'>
                                        All fields are required
                                    </span>
                                )}
                            </div>

                            {commonData.map((item, index) => {
                                return (
                                    <div key={index} className="col-md-12 mb-2">
                                        <div
                                            className="pe-5 position-relative d-flex general"
                                        >
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={item?.name}
                                                disabled
                                            />
                                            <input
                                                className="form-control ms-3"
                                                type="text"
                                                value={item?.amount}
                                                disabled
                                            />
                                            <button
                                                className="removeBtn"
                                                style={{ top: "3px" }}
                                                onClick={(e) => {
                                                    timeRemoveChangeHandler(e, index);
                                                }}
                                            >
                                                -
                                            </button>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex">
                        <button className="btn" type="button" onClick={() => handleEdit(null)}>
                            Save
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
        // <div className="page-wrapper">
        //     <div className="container-fluid min_height">
        //         <div className="card">
        //             <div className="card-body">
        //                 <h5 className='default_color'>Payroll</h5>
        //                 <div className="top_menubar btns_head">
        //                     <div className="row">
        //                         <div className="col-md-7">
        //                             <NavLink to={'#'} onClick={history.goBack}>Back</NavLink>
        //                         </div>
        //                         <div className="col-md-5">
        //                             <button className=" mb-4 btn btn-theme btn-sm float-end">
        //                                 Download
        //                             </button>
        //                             <button
        //                                 className=" mb-4 btn btn-theme btn-sm float-end"
        //                                 onClick={() => window.print()}
        //                             >
        //                                 Print
        //                             </button>
        //                         </div>
        //                     </div>

        //                     <div className="col-md-8 px-2 py-3" style={{ backgroundColor: '#FAFAFA' }}>
        //                         <span className="profle_img_box d-flex" >
        //                             <img alt="" className="profile_img_table" src={'row.image'} />
        //                             <h6 className='ms-3'>margret alva</h6>
        //                         </span>
        //                     </div>

        //                     <div className="row">
        //                         <div className={`col-12 col-md-4 ${cx.cardBox}`}>
        //                             <ul className='me-3'>
        //                                 <li>Employee Name <span> lsjdlfjl</span></li>
        //                                 <li>Designation <span> lsjdlfjl</span></li>
        //                                 <li>Start Date <span> lsjdlfjlsdfghjk</span></li>
        //                                 <li>Pay Period <span> lsjdlfjlsdfghjk</span></li>
        //                             </ul>
        //                         </div>
        //                         <div className={`col-12 col-md-4 ${cx.cardBox}`}>
        //                             <ul>
        //                                 <li>Regular Hours <span> lsjdlfjl</span></li>
        //                                 <li>Overtime Hours <span> lsjdlfjl</span></li>
        //                                 <li>Hourly Rate <span> lsjdlfjl</span></li>
        //                                 <li>Overtime Rate per Hour <span> lsjdlfjl</span></li>
        //                             </ul>
        //                         </div>
        //                     </div>

        //                     <div className="row">
        //                         <div className={`col-12 col-md-4 ${cx.cardBox}`}>
        //                             <ul className='me-3'>
        //                                 <p>Income</p>
        //                                 <li>Gross Income <span> lsjdlfjl</span></li>

        //                                 <p>Deductibles</p>
        //                                 <li>Tax <span> lsjdlfjlsdfghjk</span></li>
        //                                 <li>National Insurance <span> lsjdlfjlsdfghjk</span></li>

        //                                 <p>Net Income <span> lsjdlfjlsdfghjk</span></p>
        //                             </ul>
        //                         </div>
        //                     </div>

        //                 </div>

        //             </div>
        //         </div>
        //     </div>
        // </div>
    );
}
