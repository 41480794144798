import React, { useEffect, useState } from "react";
import cx from "./Portal.module.css";
import { Row, Col, Button } from "react-bootstrap";
import { NavLink } from 'react-router-dom';

export default function userProfile() {
  let userData = JSON.parse(localStorage?.getItem("fandfData"));
  let careSite = localStorage?.getItem("careSite");

  return (
    <div className={`${cx.profilePage}`}>

      <div className={`container`}>
        <Row>
          <Col lg={4} md={6} sm={12} className={`m-auto`}>
            <div className={`${cx.ProfileBox}`}>
              <div className={`${cx.topHeadeing}`}>
                <h3>My Profile</h3>
                <div className={`${cx.backIcon}`}>
                  <NavLink to="/portal/portal-main"><img src="/images/BackVector.png" alt="" /></NavLink>
                </div>
              </div>
              <div className={`${cx.profileImg}`}>
                <img src={userData?.image !== "" ? userData?.image : ""} alt="" />
              </div>
              <div className={`${cx.FromBox}`}>
                <div className={`${cx.box}`}>
                  <label>ID</label>
                  <input type="text" value={userData?.customId} />
                </div>
                <div className={`${cx.box}`}>
                  <label>Name</label>
                  <input type="text" value={userData?.name} />
                </div>
                <div className={`${cx.box}`}>
                  <label>Relation to Client</label>
                  <input type="text" value={userData?.relation} />
                </div>
                <div className={`${cx.box}`}>
                  <label>Care Site</label>
                  <input type="text" value={careSite} />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}
