import React, { useState, useEffect } from "react";
import { FiSearch } from "react-icons/fi";
import moment from "moment";
import { db } from "../Firebase";
import { onSnapshot, collection } from "firebase/firestore";
import { currentCareSiteId } from "../redux-toolkit/reducer/switchSites";

const chatUserImg = '/images/chatUser.svg'

export default function Users(props) {
  const {
    chatUserList,
    chatHeadList,
    selectedChat,
    handleChatSelection,
    currentUser,
  } = props;

  let currentUserId = currentUser._id;
  const [searchTxt, setSearchTxt] = useState("");
  const [activeUsers, setActiveUsers] = useState([]);
  const [filteredUsersList, setFilteredUsersList] = useState([]);

  useEffect(() => {
    if (chatHeadList) {
      let list = filterUserList(chatUserList, chatHeadList);
      // console.log(list, 'listttttttttttt')
      setFilteredUsersList(list);
    }
  }, [chatHeadList]);

  useEffect(() => {
    moment.updateLocale("en-gb", {
      relativeTime: {
        future: "in %s",
        past: "%s ago",
        s: "just now",
        m: "1 min",
        mm: "%d min",
        h: "1 hr",
        hh: "%d hr",
        d: "1 day",
        dd: "%d days",
        M: "1 month",
        MM: "%d months",
        y: "1 year",
        yy: "%d years",
      },
    });
    const unSub = onSnapshot(
      collection(db, "onlineStatus", currentCareSiteId, 'user'),
      (querySnapshot) => {
        let statusdata = [];
        querySnapshot.forEach((doc) => {
          let docdata = doc.data();
          if (docdata?.status) {
            statusdata.push(docdata.id);
          }
        });
        setActiveUsers(statusdata);
        // console.log(statusdata, 'statusssssssss')
      }
    );
    return () => {
      unSub();
    };
  }, []);

  function filterUserList(userList, chatheads) {
    if (userList) {
      // const filteredArray = userList.filter(item => {
      //   return item.role !== 'createdGroup' || (item.role === 'createdGroup' && item.userIds.includes(userdata._id));
      // });
      function generateCId(user1Id, user2Id) {
        const sortedUserIds = [user1Id, user2Id].sort(); // Sort user IDs alphabetically
        return sortedUserIds.join('_');
      }
      const sortedArray = userList.map(item => {
        let chatId = item.id;
        if (item.type === 'admin') {
          chatId = generateCId(item.id, currentUserId);
        }
        const relatedItem = chatheads.find(secondItem => secondItem.id === chatId);
        const timestamp = relatedItem && relatedItem.date ? relatedItem.date : '';
        const lastMsg = relatedItem && relatedItem.lastMessage ? relatedItem.lastMessage : '';
        const senderId = relatedItem && relatedItem.senderId ? relatedItem.senderId : '';
        const countMap = relatedItem && relatedItem.counterMap ? relatedItem.counterMap : [];
        return { ...item, timestamp, lastMsg, countMap, senderId };
      }).sort((a, b) => {
        if (!a.timestamp && !b.timestamp) {
          return 0;
        } else if (!a.timestamp) {
          return 1;
        } else if (!b.timestamp) {
          return -1;
        }
        return new Date(b.timestamp) - new Date(a.timestamp); // Sort by timestamps
      });
      return sortedArray;
    }
  }

  return (
    <>
      <div className="col-md-5 chat_users_box">
        <div className="d-flex">
          <span className="careTeamTab">Care Team</span>
          <span className="careTeamTab FfTab">Friends & Family</span>
          <span className="careTeamTab adminTab">Admin</span>
        </div>
        <div className="input-group user_search_box">
          <input
            type="text"
            className="form-control"
            value={searchTxt}
            placeholder="Search"
            onChange={(e) => {
              setSearchTxt(e.target.value);
            }}
          />
          <div className="input-group-append">
            <span className="input-group-text">
              <FiSearch />
            </span>
          </div>
        </div>

        <div className="chatuser_list">
          {filteredUsersList?.filter((item) => {
            const searchTerm = searchTxt.toLowerCase();
            const fullName = item?.displayName?.toLowerCase();
            return (
              fullName?.includes(searchTerm) && item.id !== currentUserId
            );
          }).map((item) => {
            let msgSeenData = item.countMap?.find(c => c.id === currentUserId);
            const timeAgo = moment(item.timestamp).fromNow();
            return (
              <div
                key={item.id}
                className={`user_box online ${selectedChat?.id === item?.id ? "active" : ""
                  }`}
              >
                <div className={`color_div ${item.type}`}></div>
                <div className="userImgDiv">
                  {activeUsers.includes(item.id) && <span className="online_badge badge badge-success">0</span>}
                  <img
                    onClick={() => {
                      handleChatSelection(item);
                    }}
                    className={item.img === "" ? `forImage${item.type}` : ''}
                    alt=""
                    src={item.photoURL === "" ? chatUserImg : item.photoURL}
                  />
                </div>
                {msgSeenData && msgSeenData.counter > 0 && <span className="count_badge badge badge-success">{msgSeenData.counter}</span>}
                <div
                  className="user_box_body"
                  onClick={() => {
                    handleChatSelection(item);
                  }}
                >
                  <h4>
                    {item.displayName}
                    {item.timestamp !== "" && (
                      <span className="timeAgo_span">
                        {timeAgo.includes("just now") ? "just now" : timeAgo}
                      </span>
                    )}
                  </h4>
                  <small>
                    <i className="fas fa-circle"></i>
                  </small>
                  {item.type === "fandsfam" && (
                    <small>
                      <i className="fas fa-circle"></i> Client:{" "}
                      {item.clientName} <br />
                      Relation: {item.relation}
                    </small>
                  )}
                  {item.type === "admin" && <small>{item.role}</small>}
                  {item.type === "group" && <small>{item.role}</small>}
                  {item.type === "careteam" && (
                    <small>Assigned Client(s): {item.assignedClients?.join(", ")}</small>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
