import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { httpCareMagnus } from '../../Apis/commonApis';
import swal from "sweetalert";

export default function DisaproveInvoice() {

    const params = useParams();
    const history = useHistory();

    
    const [disapproveReason, setDisapproveReason] = useState('');

    // useEffect(() => {
    //     console.log(params, 'object');
    // }, []);

    const deleteInvoice = async () => {
        let body = {
            invoiceId: params.invoiceId, 
            disapproveReason: disapproveReason
        }
        const result = await httpCareMagnus('deleteInv', body);
        if (result.status) {
            swal("Success!", result.message, "success");
            history.replace("/login");
        }else{
            swal("Try Again!", result.message, "info");
        }
    }
    
    return (
        <div className='container mx-5 my-5'>
            <div className="mt-2 border">
                <div className="mx-3 my-3">
                    <div className="mb-3">
                        <label className="form-label">Please give your Reason to Disapprove this invoice</label>
                        <textarea
                            className="form-control"
                            id="noteDesc"
                            value={disapproveReason}
                            onChange={(e) => setDisapproveReason(e.target.value)}
                            placeholder="Describe here..."
                            rows="3"
                        ></textarea>
                    </div>
                    <div className="btns_head">
                        <button type="submit" className="btn btn-theme btn-sm" onClick={(e) => {deleteInvoice()}}>
                            Verify
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
