import React, { useEffect, useMemo, useState } from "react";
import "../ShiftTask/CreateShiftTask.css";
import { BsFillCameraFill } from "react-icons/bs";
import axios from "axios";
import EditTask from "./EditTask";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { currentCareSiteId } from "../../redux-toolkit/reducer/switchSites";
import { timeValues } from "../../constants/constants";
import { httpAdmin } from "../../Apis/commonApis";
import swal from "sweetalert";
import { getFullName } from "../../utils/CommonFunctions";

const ITEMS = [
  { id: "Sun", name: "SUN" },
  { id: "Mon", name: "MON" },
  { id: "Tue", name: "TUE" },
  { id: "Wed", name: "WED" },
  { id: "Thu", name: "THU" },
  { id: "Fri", name: "FRI" },
  { id: "Sat", name: "SAT" },
];

const clientGroupImg = '/images/clientGroup.svg';

function EditCreateShiftTask(props) {

  let updateData;
  if (props.typeshift == "generic") {
    updateData = props?.editTaskData;
  } else if (props.typeshift == "client") {
    updateData = props?.editTaskData;
  } else {
    updateData = props?.editTaskData?.ShiftData;
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    watch,
    setValue,
    dirtyFields,
    clearErrors,
  } = useForm({
    defaultValues: {
      shiftname: updateData?.shiftName,
      date: updateData?.Date,
      startTime: updateData?.startingTime,
      endTime: updateData?.endingTime,
      endDate: updateData?.endDate,
      recurringshift: updateData.recurringShiftType,
      weekdays: updateData?.recurringTasktype
        ? updateData?.recurringTasktype.split(",")
        : [],
      shiftType: updateData.recurringShiftType,
      assign_to: updateData?.careTeamMember,
    },
  });

  let buttonText;
  if (props.buttonType === "editGenericTemplate") {
    buttonText = "Update Template";
  } else if (props.buttonType === "editClientTemplate") {
    buttonText = "Update Template";
  } else {
    buttonText = props.typeshift === "" ? "Update Shift" : "Create Shift";
  }

  // const [updateData, setUpdateData] = useState(props?.editTaskData?.ShiftData)
  const [shownShiftTaskUI, setshownShiftTaskUI] = useState(
    props?.shownEditShiftTaskUI
  );
  const [searchFor, setsearchFor] = useState("");
  const [selectedidData, setselectedidData] = useState(
    updateData?.clientId?.length > 0
      ? updateData?.clientId[0]?._id
      : updateData?.clientId?._id
  );

  const todayTime = `${new Date().getHours() < 10 ? "0" : ""
    }${new Date().getHours()}:${new Date().getMinutes() < 10 ? "0" : ""
    }${new Date().getMinutes()}`;

  //assign To values
  const [assignselectedidData, setassignselectedidData] = useState(
    updateData?.careTeamId?.length > 0
      ? updateData?.careTeamId[0]?._id
      : updateData?.careTeamId?._id
  );
  const [APIData, setAPIData] = useState([]);
  const [APIDataTeam, setAPIDataTeam] = useState([]);
  const [searchInput, setSearchInput] = useState(updateData?.forClient);
  const [searchInputTeam, setSearchInputTeam] = useState(
    updateData?.careTeamMember
  );
  const [arrLength, setarrLength] = useState(updateData?.Task.length);

  //post shift data
  const [instruction, setinstruction] = useState(updateData?.instruction);

  const [image, setimage] = useState("");
  const [displayList, setdisplayList] = useState(updateData?.Task);
  const [selectedDays, setSelectedDays] = useState(
    updateData?.recurringTasktype
      ? updateData?.recurringTasktype.split(",")
      : []
  );

  //
  const [filteredResults, setFilteredResults] = useState([]);
  const [filteredResultsTeam, setFilteredResultsTeam] = useState([]);
  const [status, setStatus] = useState(updateData?.recurringTask ? 1 : 2); // 0: no show, 1: show yes, 2: show no.
  const [showModal, setShowModal] = useState(false);
  const [shiftType, setShiftType] = useState(updateData.recurringShiftType);
  const [isAddTaskValid, setIsAddTaskValid] = useState(false);
  const [endTimeVal, setEndTimeVal] = useState(updateData?.endingTime);
  const [startTimeVal, setStartTimeVal] = useState(updateData?.startingTime);

  useEffect(() => {
    setValue(
      "priorityBasedOnType",
      Object.keys(updateData).includes("priorityBasedOn")
        ? updateData?.priorityBasedOn?.trim()
        : "weeklyHoursAssigned"
    );
    setValue(
      "date",
      Object.keys(updateData).includes("Date") ? updateData?.Date : ""
    );
    setValue(
      "endingTime",
      Object.keys(updateData).includes("endingTime")
        ? updateData?.endingTime
        : ""
    );
    setValue(
      "startingTime",
      Object.keys(updateData).includes("startingTime")
        ? updateData?.startingTime
        : ""
    );
    setValue(
      "endingTime",
      Object.keys(updateData).includes("endingTime")
        ? updateData?.endingTime
        : ""
    );
    setBeforedate(updateData.Date);
  }, [updateData]);

  useEffect(() => {
    setShowModal(true);

    searchForApi();
    filterAssignto();

    setStartTimeVal(updateData?.startingTime);
    setEndTimeVal(updateData?.endingTime);
  }, []);

  const searchForApi = async () => {
    let data = {
      name: searchFor,
      careHomeId: currentCareSiteId,
    };
    const response = await httpAdmin("filterFor", "post", data, null);
    let filteredData = [];
    response.clientData.forEach((elem) => {
      let clintName = elem?.first_Name ? getFullName(elem) : `${elem.groupName}`;
      filteredData.push({
        value: elem._id,
        label: (
          <div>
            <img src={elem.image ? elem.image : clientGroupImg} height="30px" width="30px" />{" "}
            {clintName}{" "}
          </div>
        ),
        name: clintName,
      });
    });
    setFilteredResults(filteredData);
    setAPIData(response.clientData);
  };

  function filterAssignto() {
    let data = JSON.stringify({
      name: searchFor,
      careHomeId: currentCareSiteId,
    });
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/filterAssignto`,
      headers: {
        Authorization: localStorage.getItem("care_admin_token"),
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        setAPIDataTeam(response.data.clientData);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleChange = (event) => {
    const { checked, value } = event.currentTarget;
    setSelectedDays((prev) =>
      checked ? [...prev, value] : prev.filter((val) => val !== value)
    );
  };

  function editTemplate(type, event) {
    let ShiftType = [];
    let startTime = [];
    let endTime = [];
    let taskName = [];
    let taskType = [];
    let comments = [];
    displayList.forEach((items, index) => {
      ShiftType.push(items.shiftType);
      startTime.push(items.startTime);
      endTime.push(items.endTime);
      taskName.push(items.taskName);
      taskType.push(items.taskType);
      comments.push(items.comments);
    });

    let shiftdata = [];

    if (type === "client") {
      shiftdata.push({
        careHomeId: currentCareSiteId,
        clientId: selectedidData,
        forClient: searchInput,
        Date: event.date,
        startingTime: event.startTime,
        endingTime: event.endTime,
        recurringTask: event.recurringshift === "1" ? true : false,
        recurringTasktype: selectedDays ? selectedDays.join(",") : [],
        instruction: instruction,
        // recurringShiftType: shiftType,
        endDate: event.endDate,
        shiftType: ShiftType, //
        startTime: startTime, //
        endTime: endTime, //
        taskName: taskName, //
        taskType: taskType, //
        shiftName: event.shiftname,
        comments: comments,
        templateType: "client",
      });
      var data = new FormData();
      data.append("shiftId", props?.editShiftId);
      data.append("shiftdata", JSON.stringify(shiftdata));
      data.append("attachments", image);
      var config = {
        method: "post",
        url: `${process.env.REACT_APP_BASEURL}/editShift`,
        headers: {
          Authorization: localStorage.getItem("care_admin_token"),
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          window.location.reload(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else if (type === "generic") {
      shiftdata.push({
        careHomeId: currentCareSiteId,
        Date: event.date,
        startingTime: event.startTime,
        endingTime: event.endTime,
        recurringTask: event.recurringshift === "1" ? true : false,
        recurringTasktype: selectedDays ? selectedDays.join(",") : [],
        instruction: instruction,
        // recurringShiftType: shiftType,
        endDate: event.endDate,
        shiftType: ShiftType, //
        startTime: startTime, //
        endTime: endTime, //
        shiftName: event.shiftname,
        taskName: taskName, //
        taskType: taskType, //
        comments: comments, //
        templateType: "generic",
      });
      var data = new FormData();
      data.append("shiftId", props?.editShiftId);
      data.append("shiftdata", JSON.stringify(shiftdata));
      data.append("attachments", image);
      var config = {
        method: "post",
        url: `${process.env.REACT_APP_BASEURL}/editShift`,
        headers: {
          Authorization: localStorage.getItem("care_admin_token"),
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          window.location.reload(false)
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function editshift(Apitype, event) {
    if (arrLength) {
      let ShiftType = [];
      let startTime = [];
      let endTime = [];
      let taskName = [];
      let taskType = [];
      let comments = [];
      displayList.forEach((items, index) => {
        ShiftType.push(items.shiftType);
        startTime.push(items.startTime);
        endTime.push(items.endTime);
        taskName.push(items.taskName);
        taskType.push(items.taskType);
        comments.push(items.comments);
      });

      const shiftdata = [];

      if (Apitype === "postShift") {
        if (event.recurringshift === "1") {
          if (shiftType ===
            "open" || shiftType === "unassigned") {
            let recurringWeekDays = [];

            let getDaysArray = function (startDate, endDate) {
              let dateArray = [];
              for (
                let daysName = new Date(startDate);
                daysName <= new Date(endDate);
                daysName.setDate(daysName.getDate() + 1)
              ) {
                selectedDays.forEach((item, index) => {
                  if (item === daysName.toString().split(" ")[0]) {
                    recurringWeekDays.push(daysName.toString().split(" ")[0]);
                    dateArray.push(new Date(daysName));
                  }
                });
              }
              return dateArray;
            };

            let weekdaysDate = getDaysArray(event.date, event.endDate);

            weekdaysDate.forEach((shiftStartDate, index) => {
              let date = shiftStartDate.toISOString().split("T")[0];
              shiftdata.push({
                careHomeId: currentCareSiteId,
                clientId: selectedidData,
                forClient: searchInput,
                Date: date,
                startingTime: event.startTime,
                endingTime: event.endTime,
                recurringTask: event.recurringshift === "1" ? true : false,
                recurringTasktype: selectedDays.join(","),
                instruction: instruction,
                recurringShiftType: shiftType,
                type: type,
                endDate: event.endDate,
                shiftType: ShiftType,
                startTime: startTime,
                endTime: endTime,
                taskName: taskName,
                taskType: taskType,
                comments: comments,
                templateType: "",
              });
            });
          } else {
            let recurringWeekDays = [];

            let getDaysArray = function (startDate, endDate) {
              let dateArray = [];
              for (
                let daysName = new Date(startDate);
                daysName <= new Date(endDate);
                daysName.setDate(daysName.getDate() + 1)
              ) {
                selectedDays.forEach((item, index) => {
                  if (item === daysName.toString().split(" ")[0]) {
                    recurringWeekDays.push(daysName.toString().split(" ")[0]);
                    dateArray.push(new Date(daysName));
                  }
                });
              }
              return dateArray;
            };

            let weekdaysDate = getDaysArray(event.date, event.endDate);

            weekdaysDate.forEach((shiftStartDate, index) => {
              let date = shiftStartDate.toISOString().split("T")[0];
              shiftdata.push({
                careHomeId: currentCareSiteId,
                clientId: selectedidData,
                careTeamId: assignselectedidData,
                forClient: searchInput,
                careTeamMember: searchInputTeam,
                Date: date,
                startingTime: event.startTime,
                endingTime: event.endTime,
                recurringTask: event.recurringshift === "1" ? true : false,
                recurringTasktype: selectedDays.join(","),
                instruction: instruction,
                recurringShiftType: shiftType,
                type: type,
                endDate: event.endDate,
                shiftType: ShiftType,
                startTime: startTime,
                endTime: endTime,
                taskName: taskName,
                taskType: taskType,
                comments: comments,
                templateType: "",
              });
            });
          }
        } else {
          if (shiftType === "open" || shiftType === "unassigned") {
            shiftdata.push({
              careHomeId: currentCareSiteId,
              clientId: selectedidData,
              forClient: searchInput,
              Date: event.date,
              startingTime: event.startTime,
              endingTime: event.endTime,
              recurringTask: event.recurringshift === "1" ? true : false,
              recurringTasktype: selectedDays.join(","),
              instruction: instruction,
              recurringShiftType: shiftType,
              type: type,
              endDate: event.date,
              shiftType: ShiftType,
              startTime: startTime,
              endTime: endTime,
              taskName: taskName,
              taskType: taskType,
              comments: comments,
              templateType: "",
            });
          } else {
            shiftdata.push({
              careHomeId: currentCareSiteId,
              clientId: selectedidData,
              careTeamId: assignselectedidData,
              forClient: searchInput,
              careTeamMember: searchInputTeam,
              Date: event.date,
              startingTime: event.startTime,
              endingTime: event.endTime,
              recurringTask: event.recurringshift === "1" ? true : false,
              recurringTasktype: selectedDays.join(","),
              instruction: instruction,
              recurringShiftType: shiftType,
              type: type,
              endDate: event.date,
              shiftType: ShiftType,
              startTime: startTime,
              endTime: endTime,
              taskName: taskName,
              taskType: taskType,
              comments: comments,
              templateType: "",
            });
          }
        }
        var data = new FormData();
        data.append("shiftdata", JSON.stringify(shiftdata));
        data.append("attachments", image);
        var config = {
          method: "post",
          url: `${process.env.REACT_APP_BASEURL}/createShift`,
          headers: {
            Authorization: localStorage.getItem("care_admin_token"),
          },
          data: data,
        };

        axios(config)
          .then(function (response) {
            window.location.reload(false);
          })
          .catch(function (error) {
            console.log(error);
          });
      } else if (Apitype === "editshift") {
        let ShiftType = [];
        let startTime = [];
        let endTime = [];
        let taskName = [];
        let taskType = [];
        let comments = [];
        displayList.forEach((items, index) => {
          ShiftType.push(items.shiftType);
          startTime.push(items.startTime);
          endTime.push(items.endTime);
          taskName.push(items.taskName);
          taskType.push(items.taskType);
          comments.push(items.comments);
        });
        if (shiftType.length > 1) {
          var type = "array";
        } else {
          var type = "string";
        }

        const shiftdata = [];

        if (shiftType === "open" || shiftType === "unassigned") {
          shiftdata.push({
            careHomeId: currentCareSiteId,
            clientId: selectedidData,
            forClient: searchInput,
            Date: event.date,
            startingTime: event.startTime,
            endingTime: event.endTime,
            recurringTask: false,
            recurringTasktype: selectedDays.join(","),
            instruction: instruction,
            recurringShiftType: shiftType,
            priorityBasedOn: getValues("priorityBasedOnType")
              ? getValues("priorityBasedOnType")
              : event.priorityBasedOnType,
            type: type,
            endDate: event.date,
            shiftType: ShiftType,
            startTime: startTime,
            endTime: endTime,
            taskName: taskName,
            taskType: taskType,
            comments: comments,
            templateType: "",
          });
        } else {
          shiftdata.push({
            careHomeId: currentCareSiteId,
            clientId: selectedidData,
            careTeamId: assignselectedidData,
            forClient: searchInput,
            careTeamMember: searchInputTeam,
            Date: event.date,
            startingTime: event.startTime,
            endingTime: event.endTime,
            recurringTask: false,
            recurringTasktype: selectedDays.join(","),
            instruction: instruction,
            priorityBasedOn: getValues("priorityBasedOnType")
              ? getValues("priorityBasedOnType")
              : event.priorityBasedOnType,
            recurringShiftType: shiftType,
            type: type,
            endDate: event.date,
            shiftType: ShiftType,
            startTime: startTime,
            endTime: endTime,
            taskName: taskName,
            taskType: taskType,
            comments: comments,
            templateType: "",
          });
        }

        var data = new FormData();
        data.append("shiftId", props?.data?.EditShiftData?.id);
        data.append("shiftdata", JSON.stringify(shiftdata));
        data.append("attachments", image);
        var config = {
          method: "post",
          url: `${process.env.REACT_APP_BASEURL}/createShift?type=edit`,
          headers: {
            Authorization: localStorage.getItem("care_admin_token"),
          },
          data: data,
        };

        axios(config)
          .then(function (res) {
            swal(
              res.data.status ? "Success" : "Failed",
              res.data.message ?? 'Something went wrong!',
              res.data.status ? "success" : "error"
            ).then((value) => {
              if (res.data.status) {
                // window.location.reload(false);
                props.getShiftsandtaskNew('closePopup');
              }
              return;
            });
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    } else {
      return;
    }
  }

  const onAddTaskHandler = (arrTask) => {
    const arrData = [...arrTask];
    setdisplayList(arrData);
  };

  const radioHandler = (val) => {
    setStatus(val);
    if (val === 2) {
      setValue("endDate", "");
    }
  };

  const showField = (e) => {
    if (e === "assigned") {
      handleSubmit((data) => {
        if (Object.keys(errors).length === 0) {
          setShiftType(e);
        }
        handleShiftSelect();
      })();
    } else {
      setShiftType(e);
    }
  };

  function handleShiftSelect() {
    let startDate = getValues("date");
    let endDate;
    if (status === 1) getValues("endDate");

    const numberArray = selectedDays.map((day) => {
      switch (day) {
        case "Mon":
          return 1;
        case "Tue":
          return 2;
        case "Wed":
          return 3;
        case "Thu":
          return 4;
        case "Fri":
          return 5;
        case "Sat":
          return 6;
        case "Sun":
          return 0;
        default:
          return -1; // If an invalid day is encountered
      }
    });

    let result = getDatesOfSelectedDays(startDate, endDate, numberArray);

    let shiftDates = [];
    result.forEach((elem) => {
      shiftDates.push({
        date: elem?.split("T")[0],
        startTime: getValues("startTime"),
        endTime: getValues("endTime"),
      });
    });
    if (shiftDates.length > 0) getAvailableCareTeam(shiftDates);
  }

  const getAvailableCareTeam = async (shiftArray) => {
    let startDate = new Date(getValues("date"))?.toISOString();
    let endDate = new Date(getValues("endDate"));
    let data = {
      careSiteId: currentCareSiteId,
      shiftDates: shiftArray,
      clientId: selectedidData,
      priorityBasedOn: getValues("priorityBasedOnType")
        ? getValues("priorityBasedOnType")
        : "weeklyHoursAssigned",
      startDate: startDate,
      endDate: shiftArray.length > 1 ? endDate.toISOString() : startDate,
    };
    const response = await httpAdmin("availableCareMember", "post", data, null);
    let filteredData = [];
    response?.data?.forEach((elem) => {
      filteredData.push({
        value: elem?._id,
        label: (
          <div>
            <img src={elem?.image} height="30px" width="30px" />{" "}
            {getFullName(elem)}{" "}
          </div>
        ),
        name: getFullName(elem),
      });
    });
    setFilteredResultsTeam(filteredData);
  };

  function getDatesOfSelectedDays(startDate, endDate, selectedDay) {
    let dates = [];
    let currentDate = new Date(startDate);

    if (!endDate) {
      dates.push(new Date(startDate).toISOString());
      return dates;
    }

    while (currentDate <= new Date(endDate)) {
      if (selectedDay.includes(currentDate.getDay())) {
        dates.push(new Date(currentDate).toISOString());
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  }

  const reverse = () => {
    setIsAddTaskValid(false);
  };

  const onarrTaskLengthHandler = (arrLength) => {
    setarrLength(arrLength);
  };

  function handleCareteamSelect(event) {
    setassignselectedidData(event.value);
    setSearchInputTeam(event.name);
  }

  const today = new Date().toISOString().split("T")[0];

  function handleClientSelect(event) {
    setValue("for", event.value);
    clearErrors("for");
    setselectedidData(event.value);
    setSearchInput(event.name);
  }
  let editClient = {};
  if (props.typeshift == "client" || props.typeshift == "") {
    editClient = updateData?.clientId?.length
      ? updateData?.clientId[0]
      : updateData?.clientId;
  }

  const [beforeDate, setBeforedate] = useState("");

  const selectedTeamMember = useMemo(() => {
    const defaultData = {
      value: updateData?.careTeamId?.[0]?._id,
      label: (
        <div>
          <img
            src={updateData?.careTeamId?.[0]?.image}
            height="30px"
            width="30px"
          />{" "}
          {getFullName(updateData?.careTeamId?.[0])}
        </div>
      ),
      name: getFullName(updateData?.careTeamId?.[0]),
    }
    return updateData?.recurringShiftType === "assigned" ? defaultData : null;
  }, [props?.editTaskData]);

  return (
    <>
      <div className="card">
        <form
          onSubmit={handleSubmit((event) => {
            if (props.buttonType === "editGenericTemplate") {
              editTemplate("generic", event);
            } else if (props.buttonType === "editClientTemplate") {
              editTemplate("client", event);
            } else {
              editshift(props.addCreateShift, event);
            }
            setshownShiftTaskUI(!props.shownEditShiftTaskUI);
          })}
        >
          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                {props.titleName !== "Shift Creation" && (
                  <div className="row form_box align-items-center mb-3">
                    <label className="form-label col-md-4 mb-0">
                      Shift Name:
                    </label>
                    <div className="col-md-8">
                      <div className="position-relative">
                        <input
                          autoComplete="off"
                          type="text"
                          // value={shiftName}
                          // onChange={(e) => setshiftName(e.target.value)}
                          className="form-control keytype"
                          name="shiftname"
                          {...register("shiftname", {
                            required:
                              props.titleName !== "Shift Creation"
                                ? true
                                : false,
                            // onChange: (e) => {
                            //   setshiftName(e.target.value);
                            // },
                          })}
                        />
                        {errors.shiftname && (
                          <span style={{ color: "red" }}>
                            This field is required
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {props.buttonType !== "editGenericTemplate" && (
                  <div className="row form_box align-items-center mb-3">
                    <label className="form-label col-md-4 mb-0">For:</label>
                    <div className="col-md-8">
                      <div className="position-relative">
                        {/* {props.typeshift == "client" || props.typeshift == "client" ? } */}
                        {props.typeshift == "generic" && (
                          <Select
                            options={filteredResults}
                            onChange={handleClientSelect}
                          />
                        )}

                        {(props.typeshift == "client" ||
                          props.typeshift == "") && (
                            <Select
                              value={{
                                value: editClient?._id,
                                label: (
                                  <div>
                                    <img
                                      src={editClient?.image}
                                      height="30px"
                                      width="30px"
                                    />{" "}
                                    {getFullName(editClient)}{" "}
                                    {/* {editClient?.first_Name + " " + editClient?.last_Name}{" "} */}
                                  </div>
                                ),
                                name: getFullName(editClient),
                              }}
                              isDisabled={true}
                            // onChange={handleClientSelect}
                            // options={filteredResults}
                            />
                          )}

                        {errors.for && (
                          <span className="error_style">
                            This field is required
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                <div className="row form_box align-items-center mb-3">
                  <label className="form-label col-md-4 mb-0">
                    Date{" "}
                    {props.titleName !== "Shift Creation" ? "(Optional)" : ""}:
                  </label>
                  <div className="col-md-8">
                    <input
                      type="date"
                      name="date"
                      min={today}
                      className="form-control"
                      {...register("date", {
                        required:
                          props.titleName !== "Shift Creation" ? true : false,
                        onChange(e) {
                          setBeforedate(e.target.value);
                        },
                      })}
                    />
                    {errors.date && (
                      <span style={{ color: "red" }}>
                        This field is required
                      </span>
                    )}
                  </div>
                </div>
                {/*  */}
                <div className="row form_box align-items-center mb-3">
                  <label className="form-label col-md-4 mb-0">
                    Starting Time:
                  </label>
                  <div className="col-md-8">
                    <select
                      className="form-select custom_timeInput"
                      {...register("startTime", { required: true })}
                    >
                      {timeValues
                        .filter((item) =>
                          beforeDate <= today ? item.value > todayTime : true
                        )
                        .map((item) => {
                          return (
                            <option key={item.value} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })}
                    </select>
                    {errors.startTime && (
                      <span style={{ color: "red" }}>
                        This field is required
                      </span>
                    )}
                  </div>
                </div>
                <div className="row form_box align-items-center mb-3">
                  <label className="form-label col-md-4 mb-0">
                    Ending Time:
                  </label>
                  <div className="col-md-8">
                    <select
                      className="form-select custom_timeInput"
                      {...register("endTime", { required: true })}
                    >
                      {timeValues
                        .filter((item) => {
                          return item.value > watch('startTime', '');
                        })
                        .map((item) => {
                          return (
                            <option key={item.value} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })}
                    </select>
                    {errors.endTime && (
                      <span style={{ color: "red" }}>
                        This field is required
                      </span>
                    )}
                  </div>
                </div>
                {/*  */}
                <div className="row form_box align-items-center mb-3 mt-2">
                  <label className="form-label col-md-4 mb-0">
                    CareTeam Selection Criteria:
                  </label>
                  <div className="col-md-8">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="priorityRadioOptions"
                        id="priorityRadio1"
                        {...register("priorityBasedOnType", {
                          required: true,
                        })}
                        value="weeklyHoursAssigned"
                      />
                      <label className="form-check-label" htmlFor="priorityRadio1">
                        Weekly Hours Assigned
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="priorityRadioOptions"
                        id="priorityRadio2"
                        value="distance"
                        {...register("priorityBasedOnType", {
                          required: true,
                        })}
                      />
                      <label className="form-check-label" htmlFor="priorityRadio2">
                        Distance
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="priorityRadioOptions"
                        id="priorityRadio3"
                        value="performance"
                        {...register("priorityBasedOnType", {
                          required: true,
                        })}
                      />
                      <label className="form-check-label" htmlFor="priorityRadio3">
                        Performance
                      </label>
                    </div>
                    {errors.priorityBasedOnType && (
                      <span style={{ color: "red" }}>
                        This field is required
                      </span>
                    )}
                  </div>
                </div>
                {props.titleName === "Shift Creation" && (
                  <div className="row form_box align-items-center mb-3 mt-2">
                    <label className="form-label col-md-4 mb-0">
                      Shift Assignment:
                    </label>
                    <div className="col-md-8">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio1"
                          {...register("shiftType", { required: true })}
                          value="open"
                          checked={shiftType === "open"}
                          onChange={() => showField("open")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio1"
                          style={{ fontSize: "14px" }}
                        >
                          Open
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio2"
                          {...register("shiftType", { required: true })}
                          value="unassigned"
                          checked={shiftType === "unassigned"}
                          onChange={() => showField("unassigned")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio2"
                          style={{ fontSize: "15px" }}
                        >
                          Unassigned
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio3"
                          {...register("shiftType", { required: false })}
                          value="assigned"
                          checked={shiftType === "assigned"}
                          onChange={() => showField("assigned")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio3"
                          style={{ fontSize: "15px" }}
                        >
                          Assigned To
                        </label>
                      </div>

                      {errors.shiftType && (
                        <span style={{ color: "red" }}>
                          This field is required
                        </span>
                      )}
                    </div>
                    <div className="col-md-4"></div>
                    {shiftType == "assigned" && (
                      <div className="col-md-8 mt-2">
                        <div
                          className="position-relative"
                          onClick={() => handleShiftSelect()}
                        >
                          {(props.typeshift == "generic" ||
                            props.typeshift == "client" ||
                            props?.editTaskData?.evdata == "open") && (
                              <Select
                                options={filteredResultsTeam}
                                onChange={handleCareteamSelect}
                              />
                            )}
                          {props.typeshift == "" &&
                            props?.editTaskData?.evdata !== "open" && (
                              <Select
                                defaultValue={selectedTeamMember}
                                options={filteredResultsTeam}
                                onChange={handleCareteamSelect}
                              />
                            )}

                          {/* <input
                            autoComplete="off"
                            type="text"
                            id="fornamevalue"
                            value={searchInputTeam}
                            // onChange={(e) => searchItemsTeam(e.target.value)}
                            className="form-control keytype"
                            name="assign_to"
                            {...register("assign_to", {
                              required:
                                getValues("shiftType") === "assigned"
                                  ? true
                                  : false,
                              onChange: (e) => {
                                searchItemsTeam(e.target.value);
                              },
                            })}
                          /> */}
                          {errors.assign_to && (
                            <span style={{ color: "red" }}>
                              This field is required
                            </span>
                          )}
                          {/* {searchInputTeam?.length > 1 ? (
                            <div
                              className="hint_box"
                              style={{
                                display: assigndisplayData ? "block" : "none",
                              }}
                            >
                              <ul>
                                {" "}
                                {filteredResultsTeam.map(function (
                                  items,
                                  index
                                ) {
                                  return (
                                    <li
                                      key={items._id}
                                      onClick={(e) => {
                                        setassigndisplayData(false);
                                        setSearchInputTeam(
                                          items.first_Name +
                                          " " +
                                          items.last_Name
                                        );
                                        setassignselectedidData(items._id);
                                      }}
                                    >
                                      <img alt="" src={items.image} />{" "}
                                      {items.first_Name} {items.last_Name}
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          ) : (
                            <div
                              className="hint_box"
                              style={{
                                display: assigndisplayData ? "block" : "none",
                              }}
                            >
                              <ul>
                                {APIDataTeam.map(function (items, index) {
                                  return (
                                    <li
                                      key={items._id}
                                      onClick={(e) => {
                                        setassigndisplayData(false);
                                        setSearchInputTeam(
                                          items.first_Name +
                                          " " +
                                          items.last_Name
                                        );
                                        setassignselectedidData(items._id);
                                      }}
                                    >
                                      <img alt="" src={items.image} />{" "}
                                      {items.first_Name} {items.last_Name}
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          )} */}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="col-md-6">
                <div className="instructions_box">
                  <textarea
                    placeholder="Instructions (Optional)"
                    rows="12"
                    value={instruction}
                    onChange={(e) => setinstruction(e.target.value)}
                    className="form-control"
                  ></textarea>
                  {/* <div className="action_icon">
                    <button className="btn camera">
                      <BsFillCameraFill />
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
            <hr />

            {/* <EditTask
              startingTime={watch('startTime', '')}
              endTime={watch('endTime', '')}
              onAddTask={onAddTaskHandler}
              updateData={updateData}
              arrTaskLength={onarrTaskLengthHandler}
              isAddTaskValid={isAddTaskValid}
              reverse={reverse}
            /> */}

            <div className="row">
              <div className="col-md-12 text-center">
                <button
                  type="submit"
                  className="submit_b task_btn_size"
                  onClick={() => setIsAddTaskValid(true)}
                >
                  {buttonText}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
export default EditCreateShiftTask;
