import React, { useState } from "react";
import NoRowsOverlay from "../../components/NoRowsOverlays";
// import datechangeHandler from "../../utils/datechangehandler";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { styled } from "@mui/system";
// import { useSelector } from "react-redux";

const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
    "& .MuiDataGrid-cell:focus": {
        outline: "none",
    },
}));

const columns = [
    // { field: "id", headerName: "No.", width: 30 },
    { field: "careSiteName", headerName: "Site Name", flex: 1, minWidth: 200 },
    { field: "totalBeds", headerName: "No. of Beds", width: 100 },
    { field: "occupancy", headerName: "Occupancy", width: 100 },
    { field: "occupancyRate", headerName: "Occupancy Rates", width: 130 },
    { field: "revenue", headerName: "Revenue (Excl. Tax)", flex: 1, minWidth: 120 },
    { field: "grossWages", headerName: "Gross Wages", width: 120 },
    { field: "fixedPeriodAverageFeeRates", headerName: "Avg. Fixed Fee Rate", flex: 1, minWidth: 120 },
    { field: "payAsYouGoAverageFeeRates", headerName: "Avg. PayAsYouGo Rate", flex: 1, minWidth: 120 },
    { field: "churnRate", headerName: "Staff Churn", width: 100 },
];

export default function Finance(props) {
    let { rows, searchText } = props;

    // const { currentPlanName } = useSelector((state) => state.caresiteDataReducer);
    // const isPlanBase = currentPlanName === 'Base';

    const [rowSelectionModel, setRowSelectionModel] = useState([]);

    const filteredRows = searchText ? rows?.filter((row) => row.careSiteName?.toLowerCase().includes(searchText)) : rows;

    return (
        <div style={{ height: "70vh", width: "100%" }}>
            <CustomDataGrid
                columns={columns}
                rows={filteredRows}
                // onRowClick={handleRowClick}
                // autoHeight
                checkboxSelection={false}
                onSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel);
                }}
                disableSelectionOnClick
                getRowId={(row) => row?.id}
                rowSelectionModel={rowSelectionModel}
                slots={{
                    toolbar: GridToolbar,
                    noRowsOverlay: NoRowsOverlay,
                }}
            />
        </div>
    );
}
