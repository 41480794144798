import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { httpSuperAdmin } from "../../Apis/commonApis";
import { Row } from "react-bootstrap";
import styles from "./invoices.module.css";
import Select from "react-select";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { ShowSwal } from "../../utils/alertSwal";
import { disablePastDate } from "../../utils/CommonFunctions";
import { getInvoiceData } from "../../redux-toolkit/reducer/SuperAdminReducer";
// import DatePicker from "react-datepicker";

export default function InvoiceActions(props) {

    const dispatch = useDispatch();
    const { invoiceAllData } = useSelector((state) => state.superAdminReducer);

    const { selectedData, getLatestInvioceData } = props;
    let invoiceData = {
        careHomeId: "",
        toName: "",
        toAddress: "",
        toEmail: "",
        toContactNo: "",
        invoiceDate: "",
        invoiceDeadline: "",
        invoicePeriod: "",
        chargesDetails: [],
        total: "",
        status: "",
        type: "",
        paymentDate: "",
        image: "",
        templateFor: "superAdmin",
    };

    let chargeData = {
        discription: "",
        rate: 0,
        qty: 1,
        amount: 0,
    };

    const [addInvoiceData, setAddInvoiceData] = useState(invoiceData);
    const [planData, setPlanData] = useState(chargeData);
    const [fandFamData, setFandFamData] = useState(chargeData);
    const [couponData, setCouponData] = useState(chargeData);
    const [create, setCreate] = useState(false);
    const [allCopanyNames, setAllCompanyNames] = useState([]);
    const [careSiteNames, setCareSiteNames] = useState([]);
    const [tax, setTax] = useState(0);
    const [dateRange, setDateRange] = useState([]);
    const [startDate, endDate] = dateRange;
    const [invoiceStartDate, setInvStartDate] = useState("");
    const [invoiceEndDate, setInvEndDate] = useState("");

    useEffect(() => {
        let careData = JSON.parse(localStorage.getItem('__csadmin__d'));
        setAddInvoiceData((prevState) => {
            return { ...prevState, careHomeId: careData._id };
        });
        fetchCompanyNames();
    }, []);

    useEffect(() => {
        if (create) {
            createInvoice();
        }
    }, [create])

    function onClickHandler(type) {
        if (selectedData.length === 0) {
            swal("Error!", 'Please select checkbox to proceed!!', "error");
            return;
        }
        if (type === "delete") {
            deleteInvoice();
        } else if (type === "email") {
            sendInvoiceToClient();
        }
    }

    const invalidateInvoice = async () => {
        let invoiceId = { invoiceId: selectedData[0] };
        const result = await httpSuperAdmin(`markInvInvalid`, invoiceId);
        if (result.status) getLatestInvioceData();
    };

    const sendInvoiceToClient = async () => {
        console.log(selectedData, 'selectedData')
        const filterSelectedIds = invoiceAllData.filter(item => selectedData.includes(item._id));
        console.log(filterSelectedIds, 'invoiceAllData')
        const getIds = filterSelectedIds.map(item => item.sendId);
        let invoiceId = { invoiceId: getIds };
        const result = await httpSuperAdmin(`createInvoiceAndSend`, invoiceId);
        ShowSwal(result.status, result.message);
    }

    const deleteInvoice = async () => {
        let invoiceId = { invoiceId: selectedData };
        const result = await httpSuperAdmin(`deleteInvoice`, invoiceId);
        swal("Success!", result.message, "Success");
        if (result.status) getLatestInvioceData();
        dispatch(getInvoiceData());
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let finalCarges = [];
        finalCarges.push(planData, fandFamData, couponData);
        setAddInvoiceData((prevState) => {
            return {
                ...prevState,
                chargesDetails: finalCarges,
                total: totalAmount,
                tax: tax,
                invoicePeriod: `${invoiceStartDate} : ${invoiceEndDate}`,
                // invoicePeriod: `${invoiceData.invoiceDate} - ${invoiceData.invoiceDeadline}`,
            };
        });
        setCreate(true);
    }

    const createInvoice = async () => {
        const result = await httpSuperAdmin(`createInvoice`, addInvoiceData);
        swal("success!", result.message, "success");
        if (result.status) {
            dispatch(getInvoiceData());
            setCreate(false);
            setAddInvoiceData(invoiceData)
            getLatestInvioceData();
            setPlanData(chargeData);
            setFandFamData(chargeData);
            setCouponData(chargeData);
        }
    };

    const fetchCompanyNames = async () => {
        let result = await httpSuperAdmin("getCareHomeAndItsSites", null);
        let names = [];
        result?.data?.forEach((elem) => {
            names.push({ value: elem.adminId, label: elem.companyName });
        });
        setAllCompanyNames(names);
    };

    const fetchCareSiteNames = async (id) => {
        let result = await httpSuperAdmin(`getCareHomeAndItsSites?adminId=${id}`, null);
        let names = [];
        result?.data?.forEach((elem) => {
            names.push({
                value: elem._id,
                label: elem.careSiteName,
                billingEmail: elem?.billingEmail,
                billingAddress: elem?.billingAddress,
                companyName: elem?.companyName,
                mobileNo: elem?.adminId?.mobileNo
            });
        });
        setCareSiteNames(names);
    };

    function handleCompanyNameSelect(e) {
        setAddInvoiceData({
            careHomeId: "",
            toEmail: "",
            toAddress: "",
            toName: "",
            toContactNo: ""
        });
        fetchCareSiteNames(e?.value?._id);
    }

    function handleCareSiteNameSelect(e) {
        setAddInvoiceData((prevState) => {
            return {
                ...prevState,
                careHomeId: e.value,
                toEmail: e?.billingEmail,
                toAddress: e?.billingAddress,
                toName: e?.companyName,
                toContactNo: e?.mobileNo
            };
        });
    }

    useEffect(() => {
        const amount = planData?.rate * planData?.qty;
        setPlanData(prevPlanData => ({ ...prevPlanData, amount }));
    }, [planData.rate, planData.qty]);

    useEffect(() => {
        const amount = fandFamData?.rate * fandFamData?.qty
        setFandFamData(prevfandFamData => ({ ...prevfandFamData, amount }))
    }, [fandFamData.rate, fandFamData.qty]);

    useEffect(() => {
        const amount = couponData?.rate * couponData?.qty
        setCouponData(prevCouponData => ({ ...prevCouponData, amount }))
    }, [couponData.rate, couponData.qty]);

    const subTotalAmount = (+planData.rate * +planData.qty) + (+fandFamData.rate * +fandFamData.qty) - (+couponData.rate * +couponData.qty);
    const totalAmount = subTotalAmount + ((subTotalAmount * tax) / 100);

    return (
        <>
            <div className="float-end btns_head d-flex">
                <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        Action
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item
                            data-bs-toggle="modal"
                            data-bs-target="#addnewblog_modal"
                            data-bs-whatever="@mdo">
                            Add
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => onClickHandler("delete")}>Delete</Dropdown.Item>
                        <Dropdown.Item onClick={() => onClickHandler("email")}> Email to Client </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                {/* <select
                    id="event"
                    name="event"
                    className="border-0 default_color"
                    style={{ width: "50px" }}
                >
                    <option value="all">All</option>
                </select> */}
            </div>

            <div
                className="modal fade"
                id="addnewblog_modal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel1"
            >
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header d-flex align-items-center">
                            <h4 className="modal-title" id="exampleModalLabel1">
                                Add Invoice
                            </h4>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>

                        <div className="modal-body">
                            <form>
                                <div className="row">
                                    <label className="form-label mt-2">Select Company Name</label>
                                    <Select
                                        options={allCopanyNames}
                                        onChange={handleCompanyNameSelect}
                                    />

                                    <label className="form-label mt-2">
                                        Select Care Site Name
                                    </label>
                                    <Select
                                        options={careSiteNames}
                                        onChange={handleCareSiteNameSelect}
                                    />

                                    <h6 className={`text-dark mb-2 mt-2`}>To</h6>
                                    <div className="mb-3 col-md-6">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={addInvoiceData.toName}
                                            onChange={(e) => setAddInvoiceData({ ...addInvoiceData, toName: e.target.value })}
                                            placeholder="Recipient Name"
                                        />
                                    </div>
                                    <div className="mb-3 col-md-6">
                                        <input
                                            type="email"
                                            className="form-control"
                                            value={addInvoiceData.toEmail}
                                            onChange={(e) => setAddInvoiceData({ ...addInvoiceData, toEmail: e.target.value })}
                                            placeholder="Recipient Email"
                                        />
                                    </div>
                                    <div className="mb-3 col-md-6">
                                        <textarea
                                            className="form-control"
                                            value={addInvoiceData.toAddress}
                                            onChange={(e) => setAddInvoiceData({ ...addInvoiceData, toAddress: e.target.value })}
                                            placeholder="Recipient Address"
                                        ></textarea>
                                    </div>
                                    <div className="mb-3 col-md-6">
                                        <input
                                            type="number"
                                            className="form-control"
                                            value={addInvoiceData.toContactNo}
                                            onChange={(e) => setAddInvoiceData({ ...addInvoiceData, toContactNo: e.target.value })}
                                            placeholder="Recipient Telephone"
                                        />
                                    </div>
                                    <div className="mb-3 col-md-6">
                                        <label className="form-label mt-2">Select Invoice Date</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={addInvoiceData.invoiceDate}
                                            onChange={(e) => setAddInvoiceData({ ...addInvoiceData, invoiceDate: e.target.value })}
                                            placeholder="invoice Date"
                                        />
                                    </div>
                                    <div className="mb-3 col-md-6">
                                        <label className="form-label mt-2">Select Invoice Deadline</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={addInvoiceData.invoiceDeadline}
                                            onChange={(e) => setAddInvoiceData({ ...addInvoiceData, invoiceDeadline: e.target.value })}
                                            placeholder="invoice Deadline"
                                        />
                                    </div>

                                    <div className="d-flex justify-content-between mb-2">
                                        <div className="w-50">
                                            <label className="form-label mt-2">Invoice Period</label>
                                            <input
                                                onChange={(e) => {
                                                    setInvStartDate(e?.target?.value)
                                                }}
                                                type="date"
                                                className="form-control"
                                                // {...register("invoicePeriodStart", {
                                                //     required: true,
                                                // })}
                                                name="invoicePeriodStart"
                                            />
                                        </div>

                                        <div className="w-50 ms-2">
                                            <label className="form-label mt-2">&nbsp;</label>
                                            <input
                                                onChange={(e) => {
                                                    setInvEndDate(e?.target?.value)
                                                }}
                                                type="date"
                                                className="form-control"
                                                // {...register("invoicePeriodEnd", {
                                                //     required: true,
                                                // })}
                                                name="invoicePeriodEnd"
                                            />
                                        </div>
                                    </div>
                                    {/* <div className="mb-3 col-md-6">
                                        <label className="form-label mt-2">Select Invoice Period</label>
                                        <DatePicker
                                            className={styles.datePicker}
                                            selectsRange={true}
                                            placeholderText='select invice period'
                                            startDate={startDate}
                                            endDate={endDate}
                                            onChange={(update) => {
                                                customDateChangeHandler(update);
                                            }}
                                            isClearable={true}
                                        />
                                    </div> */}
                                    {/* <input
                                            type="email"
                                            className="form-control"
                                            value={addInvoiceData.invoicePeriod}
                                            onChange={(e) => setAddInvoiceData({ ...addInvoiceData, invoicePeriod: e.target.value })}
                                            placeholder="invoice Period"
                                        /> */}
                                </div>
                                <Row>
                                    <h6 className={`text-dark mb-2 mt-2`}>Charges Details</h6>
                                    <div className="col-md-6">
                                        <label className={`form-label ${styles.lable}`}>
                                            Plan Description{" "}
                                        </label>
                                        <input
                                            type="text"
                                            className={`form-control`}
                                            value={planData?.discription}
                                            onChange={(e) => setPlanData({ ...planData, discription: e.target.value })}
                                            placeholder=""
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <label className={`form-label ${styles.lable}`}>Rate</label>
                                        <input
                                            type="number"
                                            className={`form-control`}
                                            value={planData?.rate}
                                            onChange={(e) => setPlanData({ ...planData, rate: e.target.value })}
                                            placeholder=""
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <label className={`form-label ${styles.lable}`}>Qty </label>
                                        <input
                                            type="number"
                                            className={`form-control`}
                                            value={planData?.qty}
                                            onChange={(e) => setPlanData({ ...planData, qty: e.target.value })}
                                            placeholder=""
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <label className={`form-label ${styles.lable}`}>
                                            Amount{" "}
                                        </label>
                                        <input
                                            type="number"
                                            readOnly
                                            className={`form-control`}
                                            value={+planData?.rate * +planData?.qty}
                                            placeholder=""
                                        />
                                    </div>
                                </Row>
                                <Row>
                                    <div className="col-md-6">
                                        <label className={`form-label ${styles.lable}`}>
                                            F&F App Description{" "}
                                        </label>
                                        <input
                                            type="text"
                                            className={`form-control`}
                                            value={fandFamData.discription}
                                            onChange={(e) => setFandFamData({ ...fandFamData, discription: e.target.value })}
                                            placeholder=""
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <label className={`form-label ${styles.lable}`}>Rate</label>
                                        <input
                                            type="number"
                                            className={`form-control`}
                                            value={fandFamData.rate}
                                            onChange={(e) => setFandFamData({ ...fandFamData, rate: e.target.value })}
                                            placeholder=""
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <label className={`form-label ${styles.lable}`}>Qty </label>
                                        <input
                                            type="number"
                                            className={`form-control`}
                                            value={fandFamData.qty}
                                            onChange={(e) => setFandFamData({ ...fandFamData, qty: e.target.value })}
                                            placeholder=""
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <label className={`form-label ${styles.lable}`}>
                                            Amount{" "}
                                        </label>
                                        <input
                                            type="number"
                                            readOnly
                                            className={`form-control`}
                                            value={+fandFamData?.rate * +fandFamData?.qty}
                                            placeholder=""
                                        />
                                    </div>
                                </Row>
                                <Row className="mt-3">
                                    <div className="col-md-6">
                                        <label className={`form-label ${styles.lable}`}>
                                            Coupon Description{" "}
                                        </label>
                                        <input
                                            type="text"
                                            className={`form-control mb-3`}
                                            value={couponData.discription}
                                            onChange={(e) => setCouponData({ ...couponData, discription: e.target.value })}
                                            placeholder=""
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <label className={`form-label ${styles.lable}`}>Rate</label>
                                        <input
                                            type="number"
                                            className={`form-control mb-3`}
                                            value={couponData.rate}
                                            onChange={(e) => setCouponData({ ...couponData, rate: e.target.value })}
                                            placeholder=""
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <label className={`form-label ${styles.lable}`}>Qty </label>
                                        <input
                                            type="number"
                                            className={`form-control mb-3`}
                                            value={couponData.qty}
                                            onChange={(e) => setCouponData({ ...couponData, qty: e.target.value })}
                                            placeholder=""
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <label className={`form-label ${styles.lable}`}>
                                            Amount{" "}
                                        </label>
                                        <input
                                            type="number"
                                            readOnly
                                            className={`form-control mb-3`}
                                            value={+couponData.rate * +couponData.qty}
                                            placeholder=""
                                        />
                                    </div>
                                </Row>
                                <Row>
                                    <div className="col-md-9"></div>
                                    <div className="col-md-3">
                                        <label className={`form-label ${styles.lable}`}>Sub Total </label>
                                        <input
                                            type="number"
                                            readOnly
                                            value={subTotalAmount}
                                            className={`form-control`}
                                            placeholder=""
                                        />
                                    </div>
                                </Row>
                                <Row>
                                    <div className="col-md-9"></div>
                                    <div className="col-md-3">
                                        <label className={`form-label ${styles.lable}`}>Tax(%) </label>
                                        <input
                                            type="number"
                                            onChange={(e) => setTax(e.target.value)}
                                            value={tax}
                                            className={`form-control`}
                                            placeholder=""
                                        />
                                    </div>
                                </Row>
                                <Row>
                                    <div className="col-md-9"></div>
                                    <div className="col-md-3">
                                        <label className={`form-label ${styles.lable}`}>Total </label>
                                        <input
                                            type="number"
                                            readOnly
                                            value={totalAmount}
                                            className={`form-control`}
                                            placeholder=""
                                        />
                                    </div>
                                </Row>
                                <div className="row mt-3">
                                    <div className="mb-3 col-md-6">
                                        <select
                                            className="form-control"
                                            value={addInvoiceData.status}
                                            onChange={(e) => setAddInvoiceData({ ...addInvoiceData, status: e.target.value })}
                                        >
                                            <option value="unpaid">Unpaid</option>
                                            <option value="paid">Paid</option>
                                        </select>
                                    </div>
                                    <div className="mb-3 col-md-6">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={addInvoiceData.type}
                                            onChange={(e) => setAddInvoiceData({ ...addInvoiceData, type: e.target.value })}
                                            placeholder="Payment Type"
                                        />
                                    </div>
                                    <div className="mb-3 col-md-6">
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={addInvoiceData.paymentDate}
                                            onChange={(e) => setAddInvoiceData({ ...addInvoiceData, paymentDate: e.target.value })}
                                            placeholder="Payment Date"
                                        />
                                    </div>
                                </div>
                                <div className="blog_popup_details">
                                    {/* <div className="mb-3 col-md-6">
                                        <label className="form-label">Image</label>
                                        <input
                                            type="file"
                                            accept="image/apng, image/avif, image/jpg, image/jpeg, image/png"
                                            value={addInvoiceData.image}
                                            onChange={(e) => setAddInvoiceData({ ...addInvoiceData, image: e.target.value })}
                                            required="required"
                                            className="form-control"
                                        />
                                    </div> */}
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-primary submit_btn"
                                            data-bs-dismiss="modal"
                                            onClick={(e) => handleSubmit(e)}
                                        >
                                            Add Invoice
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
