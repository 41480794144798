import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import swal from "sweetalert";
import TimezoneSelect, { allTimezones } from "react-timezone-select";
import Switch from "@mui/material/Switch";
import { alpha, styled } from "@mui/material/styles";
import { deepPurple } from "@mui/material/colors";
import { NavLink, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { httpAdmin } from "../../Apis/commonApis";
import { logoutAdmin } from "../../utils/logoutadmin";
import {
  getSettingList,
  getSettingListItems,
} from "../../redux-toolkit/reducer/adminSettings";
import "./setting.css";
import { currentCareSiteId } from "../../redux-toolkit/reducer/switchSites";
import { ShowSwal } from "../../utils/alertSwal";
import { RefreshPage } from "../../utils/utilsHandlerFunctions";
import { getCurrentTimeZone } from "../../utils/CommonFunctions";

let minutes = [];
for (let i = 0; i <= 60; i++) {
  minutes.push({ value: i, label: i });
}

let days = [];
for (let i = 1; i <= 30; i++) {
  days.push({ value: i, label: i });
}

let radiusInMeters = [];
for (let i = 50; i <= 500; i += 50) {
  radiusInMeters.push({ value: i, label: i });
}

const GreenSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: deepPurple[600],
    "&:hover": {
      backgroundColor: alpha(
        deepPurple[600],
        theme.palette.action.hoverOpacity
      ),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: deepPurple[600],
  },
}));

const label = { inputProps: { "aria-label": "Color switch demo" } };

const SettingUser = () => {

  let { careSitesData, currentCareSiteData } = useSelector((state) => state.caresiteDataReducer);
  const { getSettingListCount } = useSelector((state) => state.adminPanelSetting);

  const dispatch = useDispatch();
  const history = useHistory();

  const userData = JSON.parse(localStorage.getItem("userData"));

  const [state, setState] = React.useState({
    adminId: userData._id,
    careFacility: userData.carefacility,
    timeZone: "timeZone",
    TWO_FA_type: "security_question",
    shift: {
      ClockInGracePeriodInMin: 0,
      ClockOutGracePeriodInMin: 10,
    },
    clientLocationClockInOutMeter: 10,
    careTeamPerformance: {
      includeRating: false,
    },
    reminders: {
      birthday: {
        status: true,
        dayInAdvance: 3,
      },
      clientDocReview: {
        status: true,
        dayInAdvance: 3,
      },
      carePlanReview: {
        status: true,
        dayInAdvance: 3,
      },
      careteamDocReview: {
        status: true,
        dayInAdvance: 3,
      },
      careteamTrainingDate: {
        status: true,
        dayInAdvance: 3,
      },
      careteamTrainingDocReview: {
        status: true,
        dayInAdvance: 3,
      },
    },
    loginSecurity: {
      all_2fa_status: false,
      admin_2fa_type: "false",
      app_2fa_type: "false",
      FandF_2fa_type: "false",
    },
  });

  // ---------------------------------  Select Timezone  -------------------------------- //
  const [selectedTimezone, setSelectTimezone] = useState('');

  const [allCareSites, setAllCareSites] = useState([]);
  const [defaultCareSite, setdefaultCareSite] = useState({});

  useEffect(() => {
    if (currentCareSiteData) {
      const caresites = careSitesData?.map((site) => {
        return { value: site?._id, label: site?.careSiteName };
      });
      const defaultsite = caresites.find(
        (site) => site?.value === userData?.defaultCareSiteId
      );
      setdefaultCareSite(defaultsite);
      setAllCareSites(caresites);
    }
  }, [currentCareSiteData]);

  useEffect(async () => {
    dispatch(getSettingList(currentCareSiteId));
  }, []);

  useEffect(() => {
    if (getSettingListCount > 0) {
      const {
        timeZone,
        TWO_FA_type,
        shift: { ClockInGracePeriodInMin, ClockOutGracePeriodInMin },
        clientLocationClockInOutMeter,
        careTeamPerformance: { includeRating },
        reminders: {
          birthday,
          clientDocReview,
          carePlanReview,
          careteamDocReview,
          careteamTrainingDate,
          careteamTrainingDocReview,
        },
        loginSecurity: {
          all_2fa_status,
          admin_2fa_type,
          app_2fa_type,
          FandF_2fa_type,
        },
      } = getSettingListItems;
      setSelectTimezone(timeZone ?? '');

      setState({
        adminId: userData._id,
        careFacility: getSettingListItems.careFacility,
        TWO_FA_type: TWO_FA_type,
        shift: {
          ClockInGracePeriodInMin: ClockInGracePeriodInMin,
          ClockOutGracePeriodInMin: ClockOutGracePeriodInMin,
        },
        clientLocationClockInOutMeter: clientLocationClockInOutMeter,
        careTeamPerformance: {
          includeRating: includeRating,
        },
        reminders: {
          birthday: birthday,
          clientDocReview: clientDocReview,
          carePlanReview: carePlanReview,
          careteamDocReview: careteamDocReview,
          careteamTrainingDate: careteamTrainingDate,
          careteamTrainingDocReview: careteamTrainingDocReview,
        },
        loginSecurity: {
          all_2fa_status: all_2fa_status,
          admin_2fa_type: admin_2fa_type,
          app_2fa_type: app_2fa_type,
          FandF_2fa_type: FandF_2fa_type,
        },
      });
    }
  }, [getSettingListCount]);

  async function updateAdminSetting() {
    state.careHomeId = currentCareSiteId;
    state.timeZone = selectedTimezone;
    state.careFacility = userData.carefacility;
    state.TWO_FA_type = state.loginSecurity.admin_2fa_type;
    const result = await httpAdmin("editSetting", 'post', state, null);
    if (result.status) {
      swal("Success", result.message, "success").then((value) => {
        dispatch(getSettingList(userData._id));
      });
    }
  }

  const handleChange = (event) => {
    const value = event.target.checked;
    const keys = event.target.name.split(".");
    if (keys.length > 0) {
      if (keys.includes("loginSecurity") && keys.length > 2) {
        setState({
          ...state,
          [keys[0]]: {
            ...state[keys[0]],
            [keys[1]]: !value ? "false" : keys[2],
          },
        });
      } else if (keys.length > 2) {
        setState({
          ...state,
          [keys[0]]: {
            ...state[keys[0]],
            [keys[1]]: {
              ...state[keys[0]][keys[1]],
              [keys[2]]: value,
            },
          },
        });
      } else if (keys.includes("loginSecurity") && keys.length == 2) {
        setState({
          ...state,
          [keys[0]]: {
            all_2fa_status: value,
            admin_2fa_type: "false",
            app_2fa_type: "false",
            FandF_2fa_type: "false",
          },
        });
      } else {
        setState({
          ...state,
          [keys[0]]: {
            ...state[keys[0]],
            [keys[1]]: value,
          },
        });
      }
    } else {
      setState({
        ...state,
        [keys[0]]: value,
      });
    }
  };

  function select_day_radius_minutes(event, action) {
    const value = event.value;
    const keys = action.name.split(".");

    if (keys.length > 2) {
      setState({
        ...state,
        [keys[0]]: {
          ...state[keys[0]],
          [keys[1]]: {
            ...state[keys[0]][keys[1]],
            [keys[2]]: value,
          },
        },
      });
    }

    if (keys.length === 2) {
      setState({
        ...state,
        [keys[0]]: {
          ...state[keys[0]],
          [keys[1]]: value,
        },
      });
    }

    if (keys.length === 1) {
      setState({
        ...state,
        [keys[0]]: value,
      });
    }
  }

  // ----------------------------  Delete Care site  ---------------------------------  //

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = (text) => {
    if (userData?.userType === 'owner') {
      if (defaultCareSite.value === currentCareSiteData._id && careSitesData?.length > 1) {
        ShowSwal(false, 'Please change default careSite before deleting this careSite');
        return;
      }
      setShow(true);
    } else {
      ShowSwal(false, 'Only Owner can delete the CareSite!');
    }
  };

  async function deleteCareSite() {
    const data = {
      ownerId: userData?._id,
      careSiteId: currentCareSiteId,
    };

    const result = await httpAdmin("deleteCareSite", 'post', data, null);
    if (result.status) {
      swal("Success", result.message, "success").then((value) => {
        if (careSitesData?.length === 1) {
          localStorage.clear();
          history.push("/login");
        } else {
          setShow(false);
          localStorage.setItem("currentCareSiteId", defaultCareSite.value);
          RefreshPage();
          setTimeout(() => {
          }, 1000);
        }
      });
    } else {
      ShowSwal(false, result.message);
    }
  }

  // ---------------------------- Default Care Site  ----------------------------------- //

  async function setDefaultCareSite(event) {
    const data = {
      userId: userData?._id,
      defaultCareSiteId: event?.value,
    };

    const result = await httpAdmin("setDefaultCareSite", 'post', data, null);
    if (result.status) {
      userData.defaultCareSiteId = event?.value;
      setdefaultCareSite({ label: event.label, value: event.value });
      localStorage.setItem("userData", JSON.stringify(userData));
    }
    ShowSwal(result.status, result.message);
  }
  
  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <div className="setting_box_border">
            <div className="setting_box ">
              <ul>
                <li style={{ justifyContent: "normal" }}>
                  <span>
                    Home Care Agency
                    <GreenSwitch
                      {...label}
                      checked={currentCareSiteData?.carefacility === "facalities"}
                      onChange={() => { }}
                      name="careFacility"
                    />
                  </span>
                  <span>Care Facility</span>
                </li>
              </ul>
              <h5>Time-Zone</h5>
              <ul>
                <li>
                  <span>Current Time-Zone</span>
                  <span style={{width: '65%'}}>
                    <TimezoneSelect
                      value={getCurrentTimeZone()}
                      labelStyle="abbrev"
                      onChange={(e) => {}}
                      isDisabled={true}
                      timezones={{
                        ...allTimezones,
                      }}
                    />
                  </span>
                </li>
                <li>
                  <span>CareSite Time-Zone</span>
                  <span style={{width: '65%'}}>
                    <TimezoneSelect
                      value={selectedTimezone}
                      labelStyle="abbrev"
                      onChange={(e) => {
                        setSelectTimezone(e.value);
                      }}
                      timezones={{
                        ...allTimezones,
                      }}
                    />
                  </span>
                </li>
              </ul>

              <h5 className="mt-5">Shift Grace Periods</h5>
              <ul>
                <li>
                  <span>Clock-In Grace period (- minutes)</span>
                  <span>
                    <Select
                      name="shift.ClockInGracePeriodInMin"
                      options={minutes}
                      value={{
                        label: state.shift.ClockInGracePeriodInMin,
                        value: state.shift.ClockInGracePeriodInMin,
                      }}
                      onChange={select_day_radius_minutes}
                    />
                  </span>
                </li>
                <li>
                  <span>Clock-Out Grace period (- + minutes)</span>
                  <span>
                    <Select
                      name="shift.ClockOutGracePeriodInMin"
                      options={minutes}
                      value={{
                        label: state.shift.ClockOutGracePeriodInMin,
                        value: state.shift.ClockOutGracePeriodInMin,
                      }}
                      onChange={select_day_radius_minutes}
                    />
                  </span>
                </li>
              </ul>
            </div>

            <div className="setting_box">
              <h5> Reminders</h5>
              <ul>
                <li>
                  <span>Client’s Birthday</span>
                  <span className="d-flex">
                    <GreenSwitch
                      {...label}
                      checked={state.reminders.birthday.status}
                      onChange={handleChange}
                      name="reminders.birthday.status"
                    />
                    Yes
                  </span>
                </li>
                <li>
                  <span> (Days in advance)</span>
                  <span>
                    <Select
                      name="reminders.birthday.dayInAdvance"
                      options={days}
                      value={{
                        label: state.reminders.birthday.dayInAdvance,
                        value: state.reminders.birthday.dayInAdvance,
                      }}
                      onChange={select_day_radius_minutes}
                    />
                  </span>
                </li>
                <li>
                  <span>Client’s Document Review Date </span>
                  <span className="d-flex">
                    <GreenSwitch
                      {...label}
                      checked={state.reminders.clientDocReview.status}
                      onChange={handleChange}
                      name="reminders.clientDocReview.status"
                    />
                    Yes
                  </span>
                </li>
                <li>
                  <span> (Days in advance)</span>
                  <span>
                    <Select
                      name="reminders.clientDocReview.dayInAdvance"
                      options={days}
                      value={{
                        label: state.reminders.clientDocReview.dayInAdvance,
                        value: state.reminders.clientDocReview.dayInAdvance,
                      }}
                      onChange={select_day_radius_minutes}
                    />
                  </span>
                </li>
                <li>
                  <span>Client’s Care Plan Review Date</span>
                  <span className="d-flex">
                    <GreenSwitch
                      {...label}
                      checked={state.reminders.carePlanReview.status}
                      onChange={handleChange}
                      name="reminders.carePlanReview.status"
                    />
                    Yes
                  </span>
                </li>
                <li>
                  <span> (Days in advance)</span>
                  <span>
                    <Select
                      name="reminders.carePlanReview.dayInAdvance"
                      options={days}
                      value={{
                        label: state.reminders.carePlanReview.dayInAdvance,
                        value: state.reminders.carePlanReview.dayInAdvance,
                      }}
                      onChange={select_day_radius_minutes}
                    />
                  </span>
                </li>
                <li>
                  <span>Caregiver Document Review Date</span>
                  <span className="d-flex">
                    <GreenSwitch
                      {...label}
                      checked={state.reminders.careteamDocReview.status}
                      onChange={handleChange}
                      name="reminders.careteamDocReview.status"
                    />
                    Yes
                  </span>
                </li>
                <li>
                  <span> (Days in advance)</span>
                  <span>
                    <Select
                      name="reminders.careteamDocReview.dayInAdvance"
                      options={days}
                      value={{
                        label: state.reminders.careteamDocReview.dayInAdvance,
                        value: state.reminders.careteamDocReview.dayInAdvance,
                      }}
                      onChange={select_day_radius_minutes}
                    />
                  </span>
                </li>
                <li>
                  <span>Caregiver Training Date</span>
                  <span className="d-flex">
                    <GreenSwitch
                      {...label}
                      checked={state.reminders.careteamTrainingDate.status}
                      onChange={handleChange}
                      name="reminders.careteamTrainingDate.status"
                    />
                    Yes
                  </span>
                </li>
                <li>
                  <span> (Days in advance)</span>
                  <span>
                    <Select
                      name="reminders.careteamTrainingDate.dayInAdvance"
                      options={days}
                      value={{
                        label:
                          state.reminders.careteamTrainingDate.dayInAdvance,
                        value:
                          state.reminders.careteamTrainingDate.dayInAdvance,
                      }}
                      onChange={select_day_radius_minutes}
                    />
                  </span>
                </li>
                <li>
                  <span>Caregiver Training Doc Review Date</span>
                  <span className="d-flex">
                    <GreenSwitch
                      {...label}
                      checked={state.reminders.careteamTrainingDocReview.status}
                      onChange={handleChange}
                      name="reminders.careteamTrainingDocReview.status"
                    />
                    Yes
                  </span>
                </li>
                <li>
                  <span> (Days in advance)</span>
                  <span>
                    <Select
                      name="reminders.careteamTrainingDocReview.dayInAdvance"
                      options={days}
                      value={{
                        label:
                          state.reminders.careteamTrainingDocReview
                            .dayInAdvance,
                        value:
                          state.reminders.careteamTrainingDocReview
                            .dayInAdvance,
                      }}
                      onChange={select_day_radius_minutes}
                    />
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="setting_box_border">
            <div className="setting_box">
              <h5 className="">GeoLocation</h5>
              <ul>
                <li>
                  <span>
                    Client location Clock-In/Clock-Out radius (meters)
                  </span>
                  <span>
                    <Select
                      options={radiusInMeters}
                      value={{
                        label: state.clientLocationClockInOutMeter,
                        value: state.clientLocationClockInOutMeter,
                      }}
                      onChange={select_day_radius_minutes}
                      name="clientLocationClockInOutMeter"
                      key={radiusInMeters.map((id) => id.value)}
                    />
                  </span>
                </li>
                <li className="mt-5"></li>
              </ul>

              <h5 className="mt-5">Care Team Performance</h5>
              <ul>
                <li>
                  <span>
                    Include Ratings from Friends&Family App in performance
                    calculation
                  </span>
                  <span className="d-flex">
                    <GreenSwitch
                      {...label}
                      checked={state.careTeamPerformance.includeRating}
                      onChange={handleChange}
                      name="careTeamPerformance.includeRating"
                    />
                    Yes
                  </span>
                </li>
                <li className="mt-5"></li>
                <li className="mt-1"></li>

                <h5 className="mt-5">
                  Login Security (2 Factor Authentication)
                </h5>
                <li>
                  <span>Enable</span>
                  <span className="d-flex">
                    <GreenSwitch
                      {...label}
                      checked={state.loginSecurity.all_2fa_status}
                      onChange={handleChange}
                      name="loginSecurity.all_2fa_status"
                    />
                    Yes
                  </span>
                </li>
                <h6 className="mt-4 login_security_items_text">
                  {" "}
                  Admin Portal Users
                </h6>
                <li>
                  <span>Security Question</span>
                  <span className="d-flex">
                    <GreenSwitch
                      {...label}
                      checked={
                        state.loginSecurity.admin_2fa_type ===
                        "security_question"
                      }
                      onChange={handleChange}
                      name="loginSecurity.admin_2fa_type.security_question"
                      disabled={!state.loginSecurity.all_2fa_status}
                    />
                    Yes
                  </span>
                </li>
                <li>
                  <span>Approval Email</span>
                  <span className="d-flex">
                    <GreenSwitch
                      {...label}
                      checked={
                        state.loginSecurity.admin_2fa_type === "approval_email"
                      }
                      onChange={handleChange}
                      name="loginSecurity.admin_2fa_type.approval_email"
                      disabled={!state.loginSecurity.all_2fa_status}
                    />
                    Yes
                  </span>
                </li>
                <h6 className="mt-4 login_security_items_text">
                  {" "}
                  CareTeam App Users
                </h6>
                <li>
                  <span>Security Question</span>
                  <span className="d-flex">
                    <GreenSwitch
                      {...label}
                      checked={
                        state.loginSecurity.app_2fa_type === "security_question"
                      }
                      onChange={handleChange}
                      name="loginSecurity.app_2fa_type.security_question"
                      disabled={!state.loginSecurity.all_2fa_status}
                    />
                    Yes
                  </span>
                </li>
                <li>
                  <span>Approval Email</span>
                  <span className="d-flex">
                    <GreenSwitch
                      {...label}
                      checked={
                        state.loginSecurity.app_2fa_type === "approval_email"
                      }
                      onChange={handleChange}
                      name="loginSecurity.app_2fa_type.approval_email"
                      disabled={!state.loginSecurity.all_2fa_status}
                    />
                    Yes
                  </span>
                </li>

                <h6 className="mt-4  login_security_items_text">
                  {" "}
                  Friends&Family App Users
                </h6>
                <li>
                  <span>Security Question</span>
                  <span className="d-flex">
                    <GreenSwitch
                      {...label}
                      checked={
                        state.loginSecurity.FandF_2fa_type ===
                        "security_question"
                      }
                      onChange={handleChange}
                      name="loginSecurity.FandF_2fa_type.security_question"
                      disabled={!state.loginSecurity.all_2fa_status}
                    />
                    Yes
                  </span>
                </li>
                <li>
                  <span>Approval Email</span>
                  <span className="d-flex">
                    <GreenSwitch
                      {...label}
                      checked={
                        state.loginSecurity.FandF_2fa_type === "approval_email"
                      }
                      onChange={handleChange}
                      name="loginSecurity.FandF_2fa_type.approval_email"
                      disabled={!state.loginSecurity.all_2fa_status}
                    />
                    Yes
                  </span>
                </li>
              </ul>
              <div>
                <h5 className="mt-5">Default Site</h5>
                <Select
                  placeholder="Default Care Site"
                  value={defaultCareSite}
                  options={allCareSites}
                  onChange={setDefaultCareSite}
                  required={true}
                />
              </div>
              <div>
                <h5 className="mt-5">Site Deletion</h5>
                <NavLink
                  to="#"
                  style={{ color: "#2F80ED" }}
                  onClick={() => {
                    handleShow("Delete");
                  }}
                >
                  Delete Site{" "}
                </NavLink>
                <button className="primary btn" onClick={updateAdminSetting}>
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {show && (
        <Modal className="viewModal" show={show} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>
              <span>Delete Care Site</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <div className="mt-1">
                  {careSitesData?.length === 1 &&
                    <p>This is Your Only Care Site, You will be no longer with us.</p>}
                  <p>Are you sure you want to delete this Care Site?</p>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex">
              <button className="btn submitBtn" onClick={handleClose}>
                No
              </button>
              <button
                className="btn submitBtn"
                onClick={() => {
                  deleteCareSite();
                }}
              >
                Yes
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default SettingUser;