import React, { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { CurrencyConversion, changeCurrency, getTaxesAndCurrency } from "../../redux-toolkit/reducer/websitePlan";
import { useDispatch, useSelector } from "react-redux";
import countryLists from "../../admin/Signup/countries.json";

export default function SelectSmall(props) {

  const dispatch = useDispatch();
  const { selectedCurrency, taxesAndCurrencyData, userIpData } = useSelector((state) => state.websitePlans);

  useEffect(() => {
    if (userIpData && taxesAndCurrencyData && props.fromPage === 'website') {
      let findCountry = countryLists?.countries?.find(item => {
        return item?.isoAlpha2?.includes(userIpData?.country);
      });
      let findCurrency = taxesAndCurrencyData?.currencies.find(item => item.currency === findCountry?.currency?.code);
      if (!findCurrency?.currency) {
        findCurrency = taxesAndCurrencyData?.currencies.find(item => item.currency === 'GBP');
      }
      console.log(findCurrency, "findcutrrruieoihweoi");
      dispatch(changeCurrency({ currency: findCurrency }));
    }
  }, [userIpData, taxesAndCurrencyData]);

  useEffect(() => {
    dispatch(CurrencyConversion());
    dispatch(getTaxesAndCurrency());
  }, []);

  const handleChange = (event) => {
    const findCurrency = taxesAndCurrencyData?.currencies.find(item => item.currency === event.target.value);
    dispatch(changeCurrency({ currency: findCurrency }));
  };

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <Select
        labelId="demo-select-small"
        id="demo-select-small"
        value={selectedCurrency?.currency || ''}
        onChange={handleChange}
      >
        {taxesAndCurrencyData?.currencies?.map((item, index) => {
          return (
            <MenuItem value={item.currency} key={index}>{item.currency}</MenuItem>
          )
        })}
      </Select>
    </FormControl>
  );
}
