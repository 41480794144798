import React, { useState } from "react";
import {
  useStripe,
  useElements,
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import "./StripePayment.css";
import { stripePaymentMethodHandler } from "./script";
import country from "../Signup/countries.json";
import { httpAdmin, httpPayments } from "../../Apis/commonApis";
import swal from "sweetalert";
import { useEffect } from "react";
import { currentCareSiteId } from "../../redux-toolkit/reducer/switchSites";
import { ShowSwal } from "../../utils/alertSwal";
import { useLocation } from "react-router-dom";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      lineHeight: "27px",
      color: "#212529",
      fontSize: "1.1rem",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
  // hidePostalCode: true
};

export default function CheckoutForm(props) {
  const { adminData, cartDetails, countryData, amount, setLast4, loading, setLoading } = props;

  const location = useLocation();
  const { newCareSite } = location.state;
  let newCareSiteData;
  if (newCareSite) {
    newCareSiteData = JSON.parse(localStorage.getItem("createNewCareSiteData"));
  }

  const [errorMsg, setErrorMsg] = useState("");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  // const [countryData, setCountryData] = useState({
  //   name: "",
  //   currencyCode: "",
  //   countryCode: "",
  // });

  useEffect(() => {
    setEmail(adminData.billingAddressStatus ? adminData?.email : "");
  }, [adminData]);

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);
    setErrorMsg("");
    if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      ShowSwal(false, 'Provide a valid email');
      setLoading(false);
      return;
    }

    const paymentMethodObj = {
      type: "card",
      card: elements.getElement(CardNumberElement),
      billing_details: {
        name,
        email,
      },
    };

    const paymentMethodResult = await stripe.createPaymentMethod(
      paymentMethodObj
    );
    // console.log(paymentMethodResult, "paymentMethodResult");
    if (paymentMethodResult.error) {
      swal("Error!", paymentMethodResult?.error?.message ?? 'Something went wrong', "error");
      // swal( paymentMethodResult.error, 'error');
      setLoading(false);
      return paymentMethodResult.error;
    } else {
      const last4Digit = paymentMethodResult.paymentMethod.card.last4;
      setLast4(last4Digit);

      const subscriptionData = adminData?.subscriptionPlanData?.find(item => item._id === currentCareSiteId);
      if (
        cartDetails === 0 &&
        subscriptionData?.paymentThrough?.toLowerCase().includes('stripe')
      ) {
        changeStripeCard(paymentMethodResult.paymentMethod.id, subscriptionData?._id, last4Digit);
      } else {
        stripePaymentMethodHandler(
          {
            result: paymentMethodResult,
            amount: amount,
            cartDetails: cartDetails,
            adminData: adminData,
            countryData: countryData,
            newCareSiteData: newCareSiteData,
          },
          handleResponse
        );
      }
    }
  };

  // callback method to handle the response
  const handleResponse = async (response) => {
    if (response.error) {
      setErrorMsg(response.error.message);
      return;
    }
    if (response.status) {
      const stripeSubData = await response;
      console.log(stripeSubData, 'stripeSubData')
      const confirm = await stripe.confirmCardPayment(stripeSubData?.clientSecret, {
        payment_method: { card: elements.getElement(CardNumberElement) },
      });
      const { paymentIntent } = confirm; // if payment fails parameter name is payment_intent
      const idData = {
        subscriptionId: stripeSubData?.subscriptionId,
        customerId: stripeSubData?.customerId,
      }
      if (!paymentIntent && confirm.error) {
        ShowSwal(false, confirm.error?.message ?? 'Something went wrong!');
        const deleteSubAndCust = await httpPayments(`deleteStripeCustomerAndSubscription`, idData);
        setLoading(false);
      } else {
        if (paymentIntent?.status === "succeeded") {
          props.setPaymentDetails(stripeSubData);
        } else if (paymentIntent) {
          props.setPaymentDetails(confirm);
        } else {
          ShowSwal(false, confirm.error?.message ?? 'Something went wrong!');
          const deleteSubAndCust = await httpPayments(`deleteStripeCustomerAndSubscription`, idData);
          setLoading(false);
        }
      }
    } else {
      ShowSwal(false, response?.message ?? 'Something went wrong!')
      setLoading(false);
      return;
    };
  };

  const changeStripeCard = async (paymentId, csid, last4Digit) => {
    let body = {
      careHomeId: csid,
      adminId: adminData._id,
      newPaymentThrough: "stripe",
      paymentMethodId: paymentId,
      newSubscriptionDetails: {
        bankNOorCardNo: last4Digit,
      },
    };
    const res = await httpAdmin("changeBillingMethod", 'post', body, null);
    if (res?.status) {
      props.setPaymentDetails({ msg: res.message });
    } else {
      props.setPaymentDetails({ error: { message: res.message } });
    }
  };

  // const onSelectCountry = (name, currCode, contCode) => {
  //   setCountryData({
  //     name: name,
  //     currencyCode: currCode,
  //     countryCode: contCode,
  //   });
  //   props.setSelectedCountry({
  //     name: name,
  //     currencyCode: currCode,
  //     countryCode: contCode,
  //   });
  // };

  return (
    <React.Fragment>
      <h4 className="d-flex justify-content-between align-items-center mb-3">
        <span className="text-muted">Pay with card</span>
      </h4>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-6 mb-3">
            <label htmlFor="cc-name">Name on card</label>
            <input
              id="cc-name"
              type="text"
              className="form-control"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="cc-email">Email</label>
            <input
              id="cc-email"
              type="text"
              className="form-control"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={adminData && adminData.billingAddressStatus}
            />
          </div>
        </div>

        {/* <div className="row">
          <div className="col-md-12 mb-3">
            <label htmlFor="cc-number">Card</label>
            <CardElement
              id="cc-number"
              className="form-control"
              options={CARD_ELEMENT_OPTIONS}
            />
          </div>
        </div>  */}

        <div className="row">
          <div className="col-md-12 mb-3">
            <label htmlFor="cc-number">Card Number</label>
            <CardNumberElement
              id="cc-number"
              className="form-control"
              options={CARD_ELEMENT_OPTIONS}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 mb-3">
            <label htmlFor="expiry">Expiration Date</label>
            <CardExpiryElement
              id="expiry"
              className="form-control"
              options={CARD_ELEMENT_OPTIONS}
            />
          </div>

          <div className="col-md-6 mb-3">
            <label htmlFor="cvc">CVC</label>
            <CardCvcElement
              id="cvc"
              className="form-control"
              options={CARD_ELEMENT_OPTIONS}
            />
          </div>
        </div>

        {/* <div className="row">
          <div className="col-md-12 mb-3">
            <label htmlFor="cc-name">Search and select country</label>
            <input
              type="text"
              className="form-control"
              style={{ marginBottom: "0" }}
              value={countryData.name}
              // placeholder="Search and select country"
              onChange={(e) =>
                setCountryData({ ...countryData, name: e.target.value })
              }
            />
            <div className="countryDropdown" style={{ width: "41%" }}>
              {country.countries
                .filter((item) => {
                  const searchTerm = countryData.name.toLowerCase();
                  const fullName = item?.countryName.toLowerCase();
                  return (
                    searchTerm &&
                    fullName.startsWith(searchTerm) &&
                    fullName !== searchTerm
                  );
                })
                .slice(0, 5)
                .map((item) => (
                  <div
                    onClick={() =>
                      onSelectCountry(
                        item.countryName,
                        item.currencyCode,
                        item.countryCode
                      )
                    }
                    className="dropdownRow"
                    key={item.countryCode}
                  >
                    {item.countryName}
                  </div>
                ))}
            </div>
          </div>
        </div> */}

        <hr className="mb-4" />
        <button className="btn card-btn w-100" type="submit" disabled={loading}>
          {loading ? (
            <div
              className="spinner-border spinner-border-sm text-light"
              role="status"
            ></div>
          ) : (
            `${amount ? `PAY ${cartDetails ? cartDetails?.selectedCurrency?.symbol : ''} ${amount}` : amount === 0 ? "Add Payment Method" : "Change/Update Billing Method"}`
          )}
        </button>
        {errorMsg && <div className="text-danger mt-2">{errorMsg}</div>}
      </form>
    </React.Fragment>
  );
}
