import React, { useEffect, useState } from "react";
import "../ShiftTask/CreateShiftTask.css";
import { BsFillCameraFill } from "react-icons/bs";
import axios from "axios";
import AddTask from "./AddTask";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { httpAdmin } from "../../Apis/commonApis";
import { currentCareSiteId } from "../../redux-toolkit/reducer/switchSites";
import { timeValues } from "../../constants/constants";
import swal from "sweetalert";
import { convertToUTCWithTimezone } from "../../utils/datechangehandler";
import { currentTimeZone, getCurrentTimeZone, getFullName, getWeekStartAndEndDates } from "../../utils/CommonFunctions";
import moment from 'moment'

const ITEMS = [
  { id: "Sun", name: "SUN" },
  { id: "Mon", name: "MON" },
  { id: "Tue", name: "TUE" },
  { id: "Wed", name: "WED" },
  { id: "Thu", name: "THU" },
  { id: "Fri", name: "FRI" },
  { id: "Sat", name: "SAT" },
];

const clientGroupImg = '/images/clientGroup.svg';

const defaultwellbeingchart = {
  overallMood: 0,
  sleep: 0,
  partcipation: 0,
  pain: 0,
  bowels: 0,
  diet: 0,
  activity: 0,
};

export default function CreateShiftTask(props) {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
    clearErrors,
  } = useForm({ defaultValues: { priorityBasedOnType: "weeklyHoursAssigned" } });

  const history = useHistory();
  const [searchFor, setsearchFor] = useState("");
  const [selectedidData, setselectedidData] = useState([]);
  //assign To values
  const [assignselectedidData, setassignselectedidData] = useState([]);
  const [APIData, setAPIData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [searchInputTeam, setSearchInputTeam] = useState("");
  const [isAddTaskValid, setIsAddTaskValid] = useState(false);
  //post shift data
  const [shiftType, setShiftType] = useState("");
  const [image, setimage] = useState("");
  const [displayList, setdisplayList] = useState([]);
  const [arrLength, setarrLength] = useState("");
  const [selectedDays, setSelectedDays] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [clientsInGroup, setClientsInGroup] = useState([]);
  const [filteredResultsTeam, setFilteredResultsTeam] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [beforeDate, setBeforedate] = useState("");

  const today = getCurrentDateTime().date;
  const todayTime = getCurrentDateTime().time;

  function getCurrentDateTime() {
    const currentTime = moment().tz(getCurrentTimeZone()).format('HH:mm');
    const currentDate = moment().tz(getCurrentTimeZone()).format('YYYY-MM-DD');
    return { date: currentDate, time: currentTime };
  }

  useEffect(() => {
    register("for", { required: true });
    searchForApi();
    setShowModal(true);
  }, []);

  const searchForApi = async () => {
    let data = {
      name: searchFor,
      careHomeId: currentCareSiteId,
    };
    const response = await httpAdmin("filterFor", 'post', data, null);
    let filteredData = [];
    response.clientData.forEach((elem) => {
      let clintName = elem?.first_Name ? getFullName(elem)
        // `${elem?.first_Name} ${elem.last_Name}`
        : `${elem.groupName}`;
      filteredData.push({
        value: elem._id,
        clientGroup: elem.clientGroup,
        label: (
          <div>
            <img src={elem.groupName ? clientGroupImg : elem.image} height="30px" width="30px" />{" "}
            {clintName}{" "}
          </div>
        ),
        name: clintName,
      });
    });
    setFilteredResults(filteredData);
    setAPIData(response.clientData);
  };

  const getClientTasks = async (cid) => {
    // let shiftDate = new Date().toJSON().split('T')[0];
    const res = await httpAdmin(`getGlobalTask?clientId=${cid}`, 'get', null, null);
    if (res.status) {
      console.log(res, 'taskssssssssssss');
      setdisplayList(res.data?.Task)
    }
  };

  const getAvailableCareTeam = async (shiftArray) => {
    let startDate = new Date(getValues("date"))?.toISOString();
    let endDate = new Date(getValues("endDate"));
    let data = {
      careSiteId: currentCareSiteId,
      shiftDates: shiftArray,
      clientId: selectedidData,
      priorityBasedOn: getValues("priorityBasedOnType")
        ? getValues("priorityBasedOnType")
        : "weeklyHoursAssigned",
      startDate: startDate,
      endDate: shiftArray.length > 1 ? endDate.toISOString() : startDate,
    };
    const response = await httpAdmin("availableCareMember", 'post', data, null);
    let filteredData = [];
    response?.data?.forEach((elem) => {
      filteredData.push({
        value: elem?._id,
        label: (
          <div>
            <img src={elem?.image} height="30px" width="30px" />{" "}
            {getFullName(elem)}{" "}
            {/* {elem?.first_Name + " " + elem?.last_Name}{" "} */}
          </div>
        ),
        name: getFullName(elem),
        // `${elem.first_Name} ${elem.last_Name}`,
      });
    });
    setFilteredResultsTeam(filteredData);
  };

  //task Data
  const handleChange = (event) => {
    const { checked, value } = event.currentTarget;
    setSelectedDays((prev) =>
      checked ? [...prev, value] : prev.filter((val) => val !== value)
    );
  };


  function create_shift(apiData) {
    if (beforeDate <= today ? apiData.startTime < todayTime : false) {
      console.log('rejected');
      return;
    }
    var ShifType = [];
    var start_time = [];
    var end_time = [];
    var mainTaskcat = [];
    var text = [];
    var comments = [];
    displayList?.forEach((items, index) => {
      ShifType.push(items.shiftType);
      start_time.push(items.startTime ? items.startTime : " ");
      end_time.push(items.endTime ? items.endTime : " ");
      mainTaskcat.push(items.taskName);
      text.push(items.taskType);
      comments.push(items.comments);
    });
    if (ShifType.length > 1) {
      var type = "array";
      // console.log("arr");
    } else {
      var type = "string";
      // console.log("string");
    }
    let shiftdata = [];

    if (apiData.recurringshift === "1") {
      if (shiftType === "open" || shiftType === "unassigned") {
        let recurringWeekDays = [];

        let getDaysArray = function (startDate, endDate) {
          let dateArray = [];
          for (
            let daysName = new Date(startDate);
            daysName <= new Date(endDate);
            daysName.setDate(daysName.getDate() + 1)
          ) {
            selectedDays.forEach((item, index) => {
              if (item === daysName.toString().split(" ")[0]) {
                recurringWeekDays.push(daysName.toString().split(" ")[0]);
                dateArray.push(new Date(daysName));
              }
            });
          }
          return dateArray;
        };

        let weekdaysDate = getDaysArray(apiData.date, apiData.endDate);

        weekdaysDate.forEach((shiftStartDate, index) => {
          let date = shiftStartDate.toISOString().split("T")[0];
          clientsInGroup.forEach(elem => {
            shiftdata.push({
              wellbeingAssessment: {
                overallMood: 0,
                sleep: 0,
                partcipation: 0,
                pain: 0,
                bowels: 0,
                diet: 0,
                activity: 0,
              },
              careHomeId: currentCareSiteId,
              clientId: elem.clientId,
              forClient: elem.name,
              // clientId: selectedidData,
              // forClient: searchInput,
              Date: date,
              shiftStartDatetime: convertToUTCWithTimezone(
                date,
                apiData.startTime,
                userData?.timeZone
              ),
              shiftEndDatetime: convertToUTCWithTimezone(
                date,
                apiData.endTime,
                userData?.timeZone
              ),
              startingTime: apiData.startTime,
              endingTime: apiData.endTime,
              priorityBasedOn: apiData.priorityBasedOnType,
              recurringTask: apiData.recurringshift === "1" ? true : false,
              recurringTasktype: selectedDays.join(","),
              instruction: apiData.instruction,
              recurringShiftType: shiftType,
              type: type,
              endDate: apiData.endDate,
              shiftType: ShifType,
              startTime: start_time,
              endTime: end_time,
              taskName: mainTaskcat,
              taskType: text,
              comments: comments,
              templateType: "",
              timeZone: currentTimeZone()
            });
          });
        });
      } else {
        let recurringWeekDays = [];

        let getDaysArray = function (startDate, endDate) {
          let dateArray = [];
          for (
            let daysName = new Date(startDate);
            daysName <= new Date(endDate);
            daysName.setDate(daysName.getDate() + 1)
          ) {
            selectedDays.forEach((item, index) => {
              if (item === daysName.toString().split(" ")[0]) {
                recurringWeekDays.push(daysName.toString().split(" ")[0]);
                dateArray.push(new Date(daysName));
              }
            });
          }
          return dateArray;
        };

        let weekdaysDate = getDaysArray(apiData.date, apiData.endDate);

        weekdaysDate.forEach((shiftStartDate, index) => {
          let date = shiftStartDate.toISOString().split("T")[0];
          clientsInGroup.forEach(elem => {
            shiftdata.push({
              wellbeingAssessment: {
                overallMood: 0,
                sleep: 0,
                partcipation: 0,
                pain: 0,
                bowels: 0,
                diet: 0,
                activity: 0,
              },
              careHomeId: currentCareSiteId,
              careTeamId: assignselectedidData,
              careTeamMember: searchInputTeam,
              clientId: elem.clientId,
              forClient: elem.name,
              // clientId: selectedidData,
              // forClient: searchInput,
              Date: date,
              shiftStartDatetime: convertToUTCWithTimezone(
                date,
                apiData.startTime,
                userData?.timeZone
              ),
              shiftEndDatetime: convertToUTCWithTimezone(
                date,
                apiData.endTime,
                userData?.timeZone
              ),
              priorityBasedOn: apiData.priorityBasedOnType,

              startingTime: apiData.startTime,
              endingTime: apiData.endTime,
              recurringTask: apiData.recurringshift === "1" ? true : false,
              recurringTasktype: selectedDays.join(","),
              instruction: apiData.instruction,
              recurringShiftType: shiftType,
              type: type,
              endDate: apiData.endDate,
              shiftType: ShifType,
              startTime: start_time,
              endTime: end_time,
              taskName: mainTaskcat,
              taskType: text,
              comments: comments,
              templateType: "",
              timeZone: currentTimeZone()
            });
          })
        });
      }
    } else {
      // console.log(shiftType, "shiftType");
      if (shiftType === "open" || shiftType === "unassigned") {
        clientsInGroup.forEach(elem => {
          shiftdata.push({
            wellbeingAssessment: {
              overallMood: 0,
              sleep: 0,
              partcipation: 0,
              pain: 0,
              bowels: 0,
              diet: 0,
              activity: 0,
            },
            careHomeId: currentCareSiteId,
            clientId: elem.clientId,
            forClient: elem.name,
            // clientId: selectedidData,
            // forClient: searchInput,
            Date: apiData.date,
            shiftStartDatetime: convertToUTCWithTimezone(
              apiData.date,
              apiData.startTime,
              userData?.timeZone
            ),
            shiftEndDatetime: convertToUTCWithTimezone(
              apiData.date,
              apiData.endTime,
              userData?.timeZone
            ),
            priorityBasedOn: apiData.priorityBasedOnType,

            startingTime: apiData.startTime,
            endingTime: apiData.endTime,
            recurringTask: apiData.recurringshift === "1" ? true : false,
            recurringTasktype: selectedDays.join(","),
            instruction: apiData.instruction,
            recurringShiftType: shiftType,
            type: type,
            endDate: apiData.date,
            shiftType: ShifType,
            startTime: start_time,
            endTime: end_time,
            startDateTime: convertToUTCWithTimezone(
              apiData.date,
              start_time,
              userData?.timeZone
            ),
            endDateTime: convertToUTCWithTimezone(
              apiData.date,
              end_time,
              userData?.timeZone
            ),
            taskName: mainTaskcat,
            taskType: text,
            comments: comments,
            templateType: "",
            timeZone: currentTimeZone()
          });
        });
      } else {
        clientsInGroup.forEach(elem => {
          shiftdata.push({
            wellbeingAssessment: {
              overallMood: 0,
              sleep: 0,
              partcipation: 0,
              pain: 0,
              bowels: 0,
              diet: 0,
              activity: 0,
            },
            careHomeId: currentCareSiteId,
            careTeamId: assignselectedidData,
            priorityBasedOn: apiData.priorityBasedOnType,
            careTeamMember: searchInputTeam,
            clientId: elem.clientId,
            forClient: elem.name,
            // clientId: selectedidData,
            // forClient: searchInput,
            Date: apiData.date,
            shiftStartDatetime: convertToUTCWithTimezone(
              apiData.date,
              apiData.startTime,
              userData?.timeZone
            ),
            shiftEndDatetime: convertToUTCWithTimezone(
              apiData.date,
              apiData.endTime,
              userData?.timeZone
            ),
            startingTime: apiData.startTime,
            endingTime: apiData.endTime,
            recurringTask: apiData.recurringshift === "1" ? true : false,
            recurringTasktype: selectedDays.join(","),
            instruction: apiData.instruction,
            recurringShiftType: shiftType,
            type: type,
            endDate: apiData.date,
            shiftType: ShifType,
            startTime: start_time,
            endTime: end_time,
            taskName: mainTaskcat,
            taskType: text,
            comments: comments,
            templateType: "",
            timeZone: currentTimeZone()
          });
        })
      }
    }
    console.log(shiftdata, "shiftdatashiftdata");
    let data = new FormData();
    data.append("shiftdata", JSON.stringify(shiftdata));
    data.append("attachments", image);
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/createShift`,
      headers: {
        Authorization: localStorage.getItem("care_admin_token"),
      },
      data: data,
    };

    axios(config)
      .then(function (res) {
        swal(
          res.data.status ? "Success" : "Failed",
          res.data.message ?? 'Something went wrong!',
          res.data.status ? "success" : "error"
        ).then((value) => {
          if (res.data.status) {
            // window.location.reload(false);
            props.getShiftsandtaskNew('closePopup');
          }
          return;
        });
      })
      .catch(function (error) {
        console.log(error);
      });

  }

  // const [checkStatus,setCheckStatus] = useState(false)
  const showField = (e) => {
    console.log(e, "eeeeeeeee");
    if (e === "assigned") {
      handleSubmit(() => {
        console.log(errors, "errror");
        if (Object.keys(errors).length === 0) {
          setShiftType(e);
        }
        handleShiftSelect();
      })();
    } else {
      setShiftType(e);
    }
  };

  function handleShiftSelect() {
    let startDate = getValues("date");
    let endDate = getValues("endDate");

    const numberArray = selectedDays.map((day) => {
      switch (day) {
        case "Mon":
          return 1;
        case "Tue":
          return 2;
        case "Wed":
          return 3;
        case "Thu":
          return 4;
        case "Fri":
          return 5;
        case "Sat":
          return 6;
        case "Sun":
          return 0;
        default:
          return -1; // If an invalid day is encountered
      }
    });

    let result = getDatesOfSelectedDays(startDate, endDate, numberArray);

    let shiftDates = [];
    result.forEach((elem) => {
      shiftDates.push({
        date: elem?.split("T")[0],
        startTime: getValues("startTime"),
        endTime: getValues("endTime"),
      });
    });
    if (shiftDates.length > 0) getAvailableCareTeam(shiftDates);
  }

  function getDatesOfSelectedDays(startDate, endDate, selectedDay) {
    let dates = [];
    let currentDate = new Date(startDate);

    if (!endDate) {
      console.log("object");
      dates.push(new Date(startDate).toISOString());
      return dates;
    }

    while (currentDate <= new Date(endDate)) {
      if (selectedDay.includes(currentDate.getDay())) {
        dates.push(new Date(currentDate).toISOString());
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  }

  const onAddTaskHandler = (arrTask) => {
    const arrData = [...arrTask];
    setdisplayList(arrData);
  };

  const onarrTaskLengthHandler = (arrLength) => {
    setarrLength(arrLength);
  };
  const [status, setStatus] = useState(0); // 0: no show, 1: show yes, 2: show no.

  const radioHandler = (status) => {
    setStatus(status);
  };

  const reverse = () => {
    setIsAddTaskValid(false);
  };

  function handleClientSelect(event) {
    getClientTasks(event.value);
    setValue("for", event.value);
    clearErrors("for");
    setselectedidData(event.value);
    setSearchInput(event.name);
    let groupArr = [];
    if (event.clientGroup.length < 1) {
      groupArr.push({ name: event.name, clientId: event.value })
    } else {
      groupArr = event.clientGroup.map(item => ({ name: getFullName(item), clientId: item._id }))
    }
    setClientsInGroup(groupArr);
  }

  function handleCareteamSelect(event) {
    setassignselectedidData(event.value);
    setSearchInputTeam(event.name);
  }

  return (
    <>
      <div className="card">
        <form onSubmit={handleSubmit(create_shift)}>
          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                <div className="row form_box align-items-center mb-3">
                  <label className="form-label col-md-4 mb-0">For:</label>
                  <div className="col-md-8">
                    <div className="position-relative">
                      <Select
                        options={filteredResults}
                        onChange={handleClientSelect}
                      />
                      {errors.for && (
                        <span className="error_style">
                          This field is required
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row form_box align-items-center mb-3">
                  <label className="form-label col-md-4 mb-0">Date:</label>
                  <div className="col-md-8">
                    <input
                      type="date"
                      name="date"
                      min={today}
                      // onChange={(e) => setdate(e.target.value)}
                      className="form-control"
                      {...register("date", {
                        required: true,
                        onChange(e) {
                          setBeforedate(e.target.value);
                        },
                      })}
                    />
                    {errors.date && (
                      <span className="error_style">
                        This field is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="row form_box align-items-center mb-3">
                  <label className="form-label col-md-4 mb-0">
                    Starting Time:
                  </label>
                  <div className="col-md-8">
                    <select
                      className="form-select custom_timeInput"
                      {...register("startTime", { required: true })}
                    >
                      {/* timeValues={timeValues.filter((item: any) => item.value >= watch('from', ''))} */}
                      {timeValues
                        .filter((item) =>
                          beforeDate <= today ? item.value > todayTime : true
                        )
                        .map((item) => {
                          return (
                            <option key={item.value} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })}
                    </select>
                    {errors.startTime && (
                      <span className="error_style">
                        This field is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="row form_box align-items-center mb-3">
                  <label className="form-label col-md-4 mb-0">
                    Ending Time:
                  </label>
                  <div className="col-md-8">
                    <select
                      className="form-select custom_timeInput"
                      {...register("endTime", { required: true })}
                    >
                      <option key="endtimeValue" value="">
                        Select
                      </option>
                      {timeValues
                        .filter((item) => {
                          return item.value > watch('startTime', '');
                        })
                        .map((item) => {
                          return (
                            <option key={item.value} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })}
                    </select>
                    {errors.endTime && (
                      <span className="error_style">
                        This field is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="row form_box align-items-center mb-3">
                  <label className="form-label col-md-4 mb-0">
                    Recurring Shift:
                  </label>
                  <div className="col-md-8">
                    <label className="checkbox checkbox_shift">
                      <input
                        type="radio"
                        id="r1"
                        name="recurringshift"
                        value="1"
                        checked={status === 1}
                        onClick={(e) => radioHandler(1)}
                        {...register("recurringshift", { required: true })}
                      // onChange={(e) => setrecurringTask(e.target.value)}
                      />
                      <span className="checkmark">Yes</span>
                    </label>
                    <label className="checkbox checkbox_shift">
                      <input
                        type="radio"
                        id="r2"
                        name="recurringshift"
                        value="2"
                        checked={status === 2}
                        onClick={(e) => radioHandler(2)}
                        {...register("recurringshift", { required: true })}

                      // onChange={(e) => setrecurringTask(e.target.value)}
                      />
                      <span className="checkmark">No</span>
                    </label>
                    <br />
                    {errors.recurringshift && (
                      <span className="error_style">
                        This field is required
                      </span>
                    )}
                  </div>
                </div>
                {status === 1 && (
                  <div className="desc" id="Cars1">
                    <div className="row form_box align-items-center mb-3">
                      <label className="form-label col-md-4 mb-0"></label>
                      <div className="col-md-8">
                        {ITEMS.map((item) => (
                          <>
                            <label
                              key={item.id}
                              className="checkbox me-2"
                              htmlFor={item.id}
                            >
                              <input
                                id={item.id}
                                value={item.id}
                                type="checkbox"
                                {...register("weekdays", {
                                  required:
                                    getValues("recurringshift") === 1
                                      ? true
                                      : false,
                                  onChange: (e) => {
                                    handleChange(e);
                                  },
                                })}
                                checked={selectedDays.some(
                                  (val) => val === item.id
                                )}
                              />
                              <span className="checkmark"></span>
                              {item.name}
                            </label>
                          </>
                        ))}
                        {errors.weekdays && (
                          <div className="error_style">
                            This field is required
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row form_box align-items-center mb-3">
                      <label className="form-label col-md-4 mb-0">
                        End Date:
                      </label>
                      <div className="col-md-8">
                        <input
                          type="date"
                          className="form-control"
                          min={today}
                          // onChange={(e) => setendDate(e.target.value)}
                          {...register("endDate", {
                            required: status === 1 ? true : false,
                          })}
                        />
                        {errors.endDate && (
                          <span className="error_style">
                            This field is required
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {status === 2 && <div></div>}

                <div className="row form_box align-items-center mb-3 mt-2">
                  <label className="form-label col-md-4 mb-0">
                    CareTeam Selection Criteria:
                  </label>
                  <div className="col-md-8">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="priorityRadioOptions"
                        id="priorityRadio1"
                        {...register("priorityBasedOnType", {
                          required: true,
                        })}
                        value="weeklyHoursAssigned"
                      // checked={
                      //   getValues("priorityBasedOnType") ===
                      //   "weeklyHoursAssigned"
                      // }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="priorityRadio1"
                      >
                        Weekly Hours Assigned
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="priorityRadioOptions"
                        id="priorityRadio2"
                        // checked={
                        //   getValues("priorityBasedOnType") === "distance"
                        // }
                        value="distance"
                        {...register("priorityBasedOnType", {
                          required: true,
                        })}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="priorityRadio2"
                      >
                        Distance
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="priorityRadioOptions"
                        id="priorityRadio3"
                        value="performance"
                        // checked={
                        //   getValues("priorityBasedOnType") === "performance"
                        // }
                        {...register("priorityBasedOnType", {
                          required: true,
                        })}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="priorityRadio3"
                      >
                        Performance
                      </label>
                    </div>
                    {errors.priorityBasedOnType && (
                      <span className="error_style">
                        This field is required
                      </span>
                    )}
                  </div>
                </div>
                <div className="row form_box align-items-center mb-3 mt-2">
                  <label className="form-label col-md-4 mb-0">
                    Shift Assignment:
                  </label>
                  <div className="col-md-8">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="inlineRadio1"
                        {...register("shiftType", { required: true })}
                        value="open"
                        checked={shiftType === "open"}
                        onClick={() => showField("open")}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineRadio1"
                      >
                        Open
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="inlineRadio2"
                        checked={shiftType === "unassigned"}
                        value="unassigned"
                        onClick={() => showField("unassigned")}
                        {...register("shiftType", { required: true })}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineRadio2"
                      >
                        Unassigned
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="inlineRadio3"
                        value="assigned"
                        checked={shiftType === "assigned"}
                        onClick={() => showField("assigned")}
                        {...register("shiftType", { required: false })}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineRadio3"
                      >
                        Assigned To
                      </label>
                    </div>
                    <br />
                    {errors.shiftType && (
                      <span className="error_style">
                        This field is required
                      </span>
                    )}
                  </div>
                  <div className="col-md-4"></div>
                  {/* <div className="col-md-2"></div> */}
                  {shiftType == "assigned" && (
                    <div
                      className="col-md-8 mt-2"
                      onClick={() => handleShiftSelect()}
                    >
                      <Select
                        options={filteredResultsTeam}
                        onChange={handleCareteamSelect}
                      />
                      {errors.assign_to && (
                        <span className="error_style">
                          This field is required
                        </span>
                      )}
                    </div>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="instructions_box">
                  <textarea
                    placeholder="Instructions (Optional)"
                    className="form-control"
                    {...register("instruction")}
                  ></textarea>
                  <div className="action_icon">
                    {/* <button className="btn audio">
                        <AiFillAudio />
                      </button> */}
                    {/* <button className="btn attachment">
                        <GrAttachment />
                        <input
                          type="file"
                          onChange={(e) => setimage(e.target.files[0])}
                        />
                      </button> */}
                    {/* <button className="btn camera">
                      <BsFillCameraFill />
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
            <hr />
            {/* <AddTask
              startingTime={watch('startTime', '')}
              endTime={watch('endTime', '')}
              onAddTask={onAddTaskHandler}
              arrTaskLength={onarrTaskLengthHandler}
              isAddTaskValid={isAddTaskValid}
              reverse={reverse}
            /> */}

            <div className="row">
              <div className="col-md-12 text-center">
                <button
                  className="submit_b task_btn_size"
                  type="submit"
                  onClick={() => setIsAddTaskValid(true)}
                >
                  Create Shift
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
