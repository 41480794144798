import { Switch, Route, Link, useRouteMatch, useHistory } from "react-router-dom";
import { useState } from "react"; // Import useState hook
import CommonSettingsPage from "../Settings/commonsettingspage";

export default function SettingsDetails() {
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState("");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    history.push(`${url}/${tabName}`);
  };

  let roleAccess = JSON.parse(localStorage.getItem("__csadmin__d"));

  return (
    <div className="page-wrapper">
      <div className="card">
        <div className="card-body">
          <div className="top_menubar">
            <h4>Settings</h4>
            <ul
              className="nav nav-tabs ract_tab_list border-0"
              id="myTab"
              role="tablist"
            >
              <li className={`nav-item ${activeTab === "company" && "active"}`} role="presentation">
                <Link to={`${url}/company`} className="nav-link" onClick={() => handleTabClick("company")}>
                  Company
                </Link>
              </li>
              {roleAccess?.userType !== "customer_support" && (
                <li className={`nav-item ms-3 ${activeTab === "access-roles" && "active"}`} role="presentation">
                  <Link to={`${url}/access-roles`} className="nav-link" onClick={() => handleTabClick("access-roles")}>
                    Access Roles
                  </Link>
                </li>
              )}

              <li className={`nav-item ms-3 ${activeTab === "taxes" && "active"}`} role="presentation">
                <Link to={`${url}/taxes`} className="nav-link" onClick={() => handleTabClick("taxes")}>
                  Taxes
                </Link>
              </li>
              <li className={`nav-item ms-3 ${activeTab === "currencies" && "active"}`} role="presentation">
                <Link to={`${url}/currencies`} className="nav-link" onClick={() => handleTabClick("currencies")}>
                  Currencies
                </Link>
              </li>
              <li className={`nav-item ms-3 ${activeTab === "templates" && "active"}`} role="presentation">
                <Link to={`${url}/templates`} className="nav-link" onClick={() => handleTabClick("templates")}>
                  Templates
                </Link>
              </li>
            </ul>
          </div>

          <Switch>
            <Route exact path={path}>
              {/* <h3>Please select a topic.</h3> */}
            </Route>
            <Route path={`${path}/:topicId`}>
              <CommonSettingsPage />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}
