import React from "react";
// import SidebarFooter from "./Sidebar-footer";
import SidebarList from "./Sidebar-List";
import { basePlanModules, AdvancePlanModules, turboPlanModules } from "../../constants/roles";
import cx from "./sidebar.module.css";
import { useSelector } from "react-redux";

function Sidebar() {

  let { msgCount } = useSelector((state) => state.msgReducer);

  return (
    <aside className={`left-sidebar col-md-4 ${cx.adminSidebar}`}>
      <SidebarList list1={basePlanModules} list2={AdvancePlanModules}
        list3={turboPlanModules} msgCount={msgCount} />
      {/* <SidebarFooter list={modules} /> */}
    </aside>
  );
}

export default Sidebar;
