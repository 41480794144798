import React from 'react'
import cx from "./Portal.module.css";
import { Row, Col } from "react-bootstrap";
import { NavLink } from 'react-router-dom';

export default function PortalHome() {
  return (
    <div className={`${cx.portalHome}`}>
      <div className={`container`}>
        <Row>
          <Col lg={4} md={6} sm={12} className={`m-auto`}>
            <div className={`${cx.portalBox}`}>
              <img src="/images/grandmportala.svg" alt="" />
              <h3>Care Management Simplified!</h3>
              <p>Welcome to your new experience of care management. Our powerful, easy to use and intuitive app enables you to engage and stay updated on the care activities of your loved one. </p>
              <NavLink to="/portal/login">Get Started</NavLink>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}
