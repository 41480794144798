import React, { useEffect, useState } from "react";
// import "./signup.css";
import { useLocation } from "react-router-dom";
import { httpPayments } from "../../Apis/commonApis";
import GoCardlessPayment from "../Signup/GoCardlessPayment";
import StripePayment from "../Spayment/StripePayment";

export default function UpdateBillingMethod() {

  const location = useLocation();
  const { ownerId } = location.state;

  const [selectedTab, setSelectedTab] = useState(true);
  const [adminData, setAdminData] = useState(null);

  useEffect(() => {
    getAdminData()
  }, [])

  function handleTabSelection(type) {
    setSelectedTab(type === "cardless" ? true : false);
  }

  const getAdminData = async () => {
    const res = await httpPayments(`getAdmin?adminId=${ownerId}`, null);
    if (res.status) setAdminData(res.result[0]);
  }

  const tabStyle = {
    marginRight: "20px",
    cursor: "pointer",
    fontWeight: "600",
  };

  const styleNone = {
    marginRight: "20px",
    cursor: "pointer",
  };
  return (
    <>
      <div className="themeColor mt-3 promotionalCode d-flex justify-content-center">
        <h4
          style={selectedTab ? tabStyle : styleNone}
          onClick={() => handleTabSelection("cardless")}
        >
          Direct Debit
        </h4>
        <hr />
        <h4
          style={selectedTab ? styleNone : tabStyle}
          className="ms-3"
          onClick={() => handleTabSelection("stripe")}
        >
          Credit Card
        </h4>
        <hr />
      </div>
      {selectedTab &&
        <GoCardlessPayment cartData={0} adminData={adminData} />
      }
      {!selectedTab &&
        <StripePayment cartData={0} adminData={adminData} />
      }

    </>
  )
}
