import React, { Component, Fragment } from "react";
import Scheduler, { SchedulerData, ViewTypes } from "react-big-scheduler";
import WithDragDropContext from "./withDnDContext";
import "./scheduleMed.css";
import axios from "axios";
import { Col, Row } from "react-bootstrap";
import { Redirect } from "react-router";
import "react-big-scheduler/lib/css/style.css";
import "moment/locale/en-gb";
import { currentCareSiteId } from "../../../redux-toolkit/reducer/switchSites";
import { convertToTimeZone, getFullName, handleDataPrint } from "../../../utils/CommonFunctions";
import { AiFillPrinter } from "react-icons/ai";
import JsonDownload from "../../../components/DownloadExcel/DownloadExcel";
const moment = require("moment-timezone");

const chatUserImg = "/images/chatUser.svg";

class Basic extends Component {
  constructor(props) {
    super(props);
    //let schedulerData = new SchedulerData(new moment("2017-12-18").format(DATE_FORMAT), ViewTypes.Week);
    this.schedulerData = new SchedulerData(
      new Date(),
      ViewTypes.Week,
      false,
      false,
      {
        schedulerWidth: "88%",
        besidesWidth: 0,
        defaultEventBgColor: "#808080",
        resourceName: "Name",
        movable: true,
        creatable: true,
        crossResourceMove: false,
        eventItemPopoverEnabled: true,
        calendarPopoverEnabled: true,
        dayResourceTableWidth: 180,
        weekResourceTableWidth: 180,
        eventItemHeight: 35,
        eventItemLineHeight: 40,
        schedulerMaxHeight: 380,
        selectedAreaColor: "#808080",
        recurringEventsEnabled: true,
        headerEnabled: true,
        displayWeekend: true,
        nonWorkingTimeHeadColor: "grey",
        nonWorkingTimeHeadBgColor: "white",
        nonWorkingTimeBodyBgColor: "white",

        groupOnlySlotColor: "#808080.",
        startResizable: true,
        customCellWidth: 80,
        dayCellWidth: "5%",
        endResizable: true,
        weekCellWidth: "12%",
        views: [
          {
            viewName: "Day",
            viewType: ViewTypes.Day,
            showAgenda: false,
            isEventPerspective: false,
          },
          {
            viewName: "Week",
            viewType: ViewTypes.Week,
            showAgenda: false,
            isEventPerspective: false,
          },
          {
            viewName: "Month",
            viewType: ViewTypes.Month,
            showAgenda: false,
            isEventPerspective: false,
          },
        ],
      }
    );

    this.schedulerData.localeMoment.locale("en-gb");

    this.state = {
      viewModel: this.schedulerData,
      isDisplayed: false,
      redirect: false,
      clientId: "",
      clientSide: false,
      EventsArr: [],
      resourceArr: [],
      dataToDownload: [],
      isviewdisabled: false,
      loader: true,
      medicCountStatus: null,
      SchedulerAccess: props.SchedulerAccess,
      intervalId: '',
    };
  }

  componentDidMount() {
    const intervalId = setInterval(() => {
      this.getMedicinesCarehomeWise();
    }, 25000);
    this.getMedicinesCarehomeWise();
    this.setState(() => ({ intervalId: intervalId }));
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  getMedicinesCarehomeWise = () => {
    const start = this.state.viewModel.startDate;
    const end = this.state.viewModel.endDate;
    axios({
      url: `${process.env.REACT_APP_BASEURL}/getMedicinesCarehomeWiseNew?careHomeId=${currentCareSiteId}&startDate=${start}&endDate=${end}`,
      method: "GET",
      headers: { Authorization: localStorage.getItem("care_admin_token") },
    }).then((res) => {
      const resourceArr = [];
      const eventsArr = [];
      const downloadData = [];

      let countData = res?.data?.data;
      let scheduleData = res?.data?.data?.data;

      let medicshiftCountStatus = {
        assignedTotal: countData?.assignedTotal,
        total: countData?.total,
        noShift: countData?.notMatchedTotal,
        omittedTotal: countData?.omittedTotal,
        overdueTotal: countData?.overdueTotal,
        prnTotal: countData?.prnTotal,
        completed: countData?.completedTotal,
      };

      if (scheduleData) {
        scheduleData?.forEach((items) => {
          const id = items?._id;
          const name = getFullName(items);
          const image = items.image ? items.image : chatUserImg;
          resourceArr.push({ id: id, name: name ? name : "", image: image });

          items?.medicineSchedule?.assigned?.forEach((element, index) => {
            // const shiftTaskdata = element?.shiftData?.Task;
            const shiftTaskdata = element?.shiftData?.carefacility === "facalities" ? element?.shiftData?.assignedCareTeam?.Task : element?.shiftData?.Task;
            const bgColor = "#828282";
            eventsArr.push({
              shiftId: element._id,
              ShiftData: element,
              editShiftId: element._id,
              evdata: name ? name : "",
              status: "Assigned",
              evImage: image,
              id: `assigned${element._id}`,
              start: `${shiftTaskdata?.startDate?.split("T")[0]} ${shiftTaskdata?.schedule?.split("-")[0]
                }`,
              taskList: shiftTaskdata,
              end: `${shiftTaskdata?.startDate?.split("T")[0]} ${shiftTaskdata?.schedule?.split("-")[1]
                }`,
              resourceId: items._id,
              title: shiftTaskdata?.medName?.split("(")[0],
              bgColor: bgColor,
            });
          });

          items?.medicineSchedule?.omitted?.forEach((element, index) => {
            // const shiftTaskdata = element?.shiftData?.Task;
            const shiftTaskdata = element?.shiftData?.carefacility === "facalities" ? element?.shiftData?.assignedCareTeam?.Task : element?.shiftData?.Task;
            const bgColor = "#F2C94C";
            eventsArr.push({
              shiftId: element._id,
              ShiftData: element,
              editShiftId: element._id,
              evdata: name ? name : "",
              status: "Omitted",
              evImage: image,
              id: `omitted${element._id}`,
              start: `${shiftTaskdata.startDate?.split("T")[0]} ${shiftTaskdata.schedule?.split("-")[0]
                }`,
              taskList: shiftTaskdata,
              end: `${shiftTaskdata.startDate?.split("T")[0]} ${shiftTaskdata.schedule?.split("-")[1]
                }`,
              resourceId: id,
              title: shiftTaskdata.medName?.split("(")[0],
              bgColor: bgColor,
            });
          });

          items?.medicineSchedule?.overdue?.forEach((element, index) => {
            // const shiftTaskdata = element?.shiftData?.Task;
            const shiftTaskdata = element?.shiftData?.carefacility === "facalities" ? element?.shiftData?.assignedCareTeam?.Task : element?.shiftData?.Task;
            const bgColor = "#eb5757";
            eventsArr.push({
              shiftId: element._id,
              ShiftData: element,
              editShiftId: element._id,
              evdata: name ? name : "",
              status: "Overdue",
              evImage: image,
              id: `overdue${element._id}`,
              start: `${shiftTaskdata?.startDate?.split("T")[0]} ${shiftTaskdata?.schedule?.split("-")[0]
                }`,
              taskList: shiftTaskdata,
              end: `${shiftTaskdata?.startDate?.split("T")[0]} ${shiftTaskdata?.schedule?.split("-")[1]
                }`,
              resourceId: id,
              title: shiftTaskdata.medName?.split("(")[0],
              bgColor: bgColor,
            });
          });

          items?.medicineSchedule?.prn?.forEach((element, index) => {
            // const shiftTaskdata = element?.shiftData?.Task;
            const shiftTaskdata = element?.shiftData?.carefacility === "facalities" ? element?.shiftData?.assignedCareTeam?.Task : element?.shiftData?.Task;
            const bgColor = "#2fbbe9";
            eventsArr.push({
              shiftId: element._id,
              ShiftData: element,
              editShiftId: element._id,
              evdata: name ? name : "",
              status: "PRN",
              evImage: image,
              id: `prn${element._id}`,
              start: `${shiftTaskdata?.timestamp?.split("T")[0]} ${shiftTaskdata?.startTime}`,
              taskList: shiftTaskdata,
              end: `${shiftTaskdata?.timestamp?.split("T")[0]} ${shiftTaskdata?.endTime}`,
              resourceId: id,
              title: shiftTaskdata?.medName?.split("(")[0],
              bgColor: bgColor,
            });
          });

          items?.medicineSchedule?.notMatchedMedicines?.forEach(
            (element, index) => {
              const shiftTaskdata = element?.medicine;
              const bgColor = "#f2994a";
              eventsArr.push({
                shiftId: element._id,
                ShiftData: element,
                editShiftId: element._id,
                evdata: name ? name : "",
                status: "not matched",
                evImage: image,
                id: `notMatched${shiftTaskdata._id}`,
                start: `${shiftTaskdata?.startDate?.split("T")[0]} ${shiftTaskdata?.schedule?.split("-")[0]}`,
                taskList: shiftTaskdata,
                end: `${shiftTaskdata?.startDate?.split("T")[0]} ${shiftTaskdata.schedule?.split("-")[1]}`,
                resourceId: id,
                title: shiftTaskdata.medName?.split("(")[0],
                bgColor: bgColor,
              });
            }
          );

          items?.medicineSchedule?.completed?.forEach((element, index) => {
            const shiftTaskdata = element?.shiftData?.carefacility === "facalities" ? element?.shiftData?.assignedCareTeam?.Task : element?.shiftData?.Task;
            const bgColor = "#219653";
            eventsArr.push({
              shiftId: element._id,
              ShiftData: element,
              editShiftId: element._id,
              evdata: name ? name : "",
              status: "Completed",
              evImage: image,
              id: `completed${element._id}`,
              start: `${shiftTaskdata?.startDate?.split("T")[0]} ${shiftTaskdata?.schedule?.split("-")[0]}`,
              taskList: shiftTaskdata,
              end: `${shiftTaskdata?.startDate?.split("T")[0]} ${shiftTaskdata?.schedule?.split("-")[1]}`,
              resourceId: id,
              title: shiftTaskdata?.medName?.split("(")[0],
              bgColor: bgColor,
            });
          });
        });
      }

      eventsArr?.forEach(elem => {
        downloadData.push({
          client: elem.evdata,
          ['Med Name']: elem.title,
          ['Start Time']: elem.start,
          ['End Time']: elem.end,
          CareTeam: elem?.ShiftData?.shiftData?.careTeamMember ?? '',
          ['Status']: elem.status,
        })
      });

      this.schedulerData.setResources(resourceArr);
      this.schedulerData.setEvents(eventsArr);
      this.setState((prevState) => ({
        ...prevState,
        medicCountStatus: medicshiftCountStatus,
        EventsArr: eventsArr,
        resourceArr: resourceArr,
        dataToDownload: downloadData,
      }));
    }).catch((error) => console.log(`Error: ${error}`));
  };

  componentDidUpdate(prevProps) {
    if (prevProps.text !== this.props.text) {
      let filteredVal = this.state.resourceArr?.filter((elem) => {
        return elem.name.toLowerCase().includes(this.props.text.toLowerCase());
      });
      this.schedulerData.setResources(filteredVal);
      this.forceUpdate();
    }
  }

  toggleShowHide = (events, type) => {
  };

  toggle = () => {
    this.setState((state) => ({
      isDisplayed: !state.isDisplayed,
    }));
  };

  hide = () => {
    this.setState((state) => ({
      isDisplayed: !state.isDisplayed,
    }));
  };

  clear = () => {
    this.setState((state) => ({
      isDisplayed: !state.isDisplayed,
    }));
  };

  clientTem = () => {
    this.setState((state) => ({
      clientSide: !state.clientSide,
    }));
  };

  deleteShift(mainshiftId, shiftId, deleteType) {
    let data = JSON.stringify({
      mainshiftId: mainshiftId,
      shiftId: shiftId,
      type: deleteType,
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/deleteshift`,
      headers: {
        Authorization: localStorage.getItem("care_admin_token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        window.location.reload(false);
      })
      .catch(function (error) {
      });
  }

  render() {
    const { viewModel, redirect, clientId, medicCountStatus, dataToDownload } = this.state;

    if (redirect) {
      return (
        <Redirect
          to={{
            pathname: "/admin/clientweekschedule",
            state: { referrer: true, clientId: clientId },
          }}
        />
      );
    }
    return (
      <Fragment>

        <Row className="justify-content-center mt-4" style={{ transform: "translate(-50px, 0px)" }}>
          <Col xl={7} lg={8} md={9} className="d-flex justify-content-around">
            <div className="sechudleCard" style={{ color: "#9e61e7" }}>
              <p>Total</p>
              <h5>{medicCountStatus?.total}</h5>
            </div>
            <div className="sechudleCard" style={{ color: "#F2994A" }}>
              <p>No Shift</p>
              <h5>{medicCountStatus?.noShift}</h5>
            </div>
            <div className="sechudleCard" style={{ color: "#828282" }}>
              <p>Assigned</p>
              <h5>{medicCountStatus?.assignedTotal}</h5>
            </div>
            <div className="sechudleCard" style={{ color: "#F2C94C" }}>
              <p>Omitted</p>
              <h5>{medicCountStatus?.omittedTotal}</h5>
            </div>
            <div className="sechudleCard" style={{ color: "#EB5757" }}>
              <p>Overdue</p>
              <h5>{medicCountStatus?.overdueTotal}</h5>
            </div>
            <div lg={3} className="sechudleCard" style={{ color: "#2FBBE9" }}>
              <p>PRNs Administered</p>
              <h5>{medicCountStatus?.prnTotal}</h5>
            </div>
            <div className="sechudleCard" style={{ color: "#219653" }}>
              <p>Completed</p>
              <h5>{medicCountStatus?.completed}</h5>
            </div>
          </Col>
        </Row>
        <div>
          <AiFillPrinter
            className="me-3 ms-1 curser_point"
            onClick={() => handleDataPrint(dataToDownload)}
          />
          <a className="curser_point">
            <JsonDownload
              fileName="payroll-excel"
              downloadfile={dataToDownload}
            />
          </a>
        </div>

        <div className="mt-4">
          <Scheduler
            schedulerData={viewModel}
            prevClick={this.prevClick}
            nextClick={this.nextClick}
            onSelectDate={this.onSelectDate}
            onViewChange={this.onViewChange}
            eventItemClick={this.eventClicked}
            // updateEventStart={this.updateEventStart}
            // updateEventEnd={this.updateEventEnd}
            moveEvent={this.moveEvent}
            newEvent={this.newEvent}
            onScrollLeft={this.onScrollLeft}
            onScrollRight={this.onScrollRight}
            toggleExpandFunc={this.toggleExpandFunc}
            eventItemPopoverTemplateResolver={
              this.eventItemPopoverTemplateResolver
            }
            eventItemTemplateResolver={this.eventItemTemplateResolver}
            slotItemTemplateResolver={this.slotItemTemplateResolver}
            movingEvent={this.movingEvent}
          />
        </div>

      </Fragment >
    );
  }

  moveEvent = (schedulerData, event, slotId, slotName, start, end) => {
    if (!event.Shif) {
      return;
    }
    // console.log("moving event", event, slotName, slotId, start, start.split(" ")[0], end);
    let date = start.split(" ")[0];
    schedulerData.moveEvent(event, slotId, slotName, start, end);
    this.setState({
      viewModel: schedulerData,
    });

    var shiftType = [];
    var startTime = [];
    var endTime = [];
    var taskName = [];
    var taskType = [];
    event.ShiftData?.Task?.forEach((items, index) => {
      shiftType.push(items.shiftType);
      startTime.push(items.startTime);
      endTime.push(items.endTime);
      taskName.push(items.taskName);
      taskType.push(items.taskType);
    });

    if (shiftType.length > 1) {
      var type = "array";
    } else {
      var type = "string";
    }

    var data = new FormData();
    data.append("careHomeId", event.ShiftData.careHomeId);
    data.append("careTeamId", event?.ShiftData?.careTeamId._id);
    data.append("shiftId", event.ShiftData._id);
    data.append("clientId", event.ShiftData.clientId._id);
    data.append(
      "forClient",
      event.ShiftData.forClient
        .split(" ")
        .filter((s) => s)
        .join(" ")
    );
    data.append(
      "careTeamMember",
      event.ShiftData.careTeamMember
        .split(" ")
        .filter((s) => s)
        .join(" ")
    );
    data.append("Date", date);
    data.append("startingTime", event.ShiftData.startingTime);
    data.append("endingTime", event.ShiftData.endingTime);
    data.append("recurringTask", event.ShiftData.recurringTask);
    data.append("recurringTasktype", event.ShiftData.recurringTasktype);
    data.append("instruction", event.ShiftData.instruction);
    data.append("recurringShiftType", event.ShiftData.recurringShiftType);
    data.append(
      "attachments",
      event.ShiftData.image ? event.ShiftData.image : ""
    );
    data.append("type", type);
    data.append("endDate", event.ShiftData.endDate);
    data.append("shiftType", shiftType);
    data.append("startTime", startTime);
    data.append("endTime", endTime);
    data.append("taskName", taskName);
    data.append("taskType", taskType);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/editShift`,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // window.location.reload(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  eventItemPopoverTemplateResolver = (
    schedulerData,
    eventItem,
    title,
    start,
    end,
    statusColor
  ) => {
    // console.log(eventItem, 'eventItem');
    let schedule = eventItem.taskList?.schedule ? eventItem.taskList?.schedule : `${eventItem.taskList?.startTime}-${eventItem.taskList?.endTime}`;
    const userData = JSON.parse(localStorage.getItem("userData"));
    const localDateTime = moment.tz(eventItem.taskList?.timestamp, userData?.timeZone);
    const utcDateTime = localDateTime.utc().format();
    const doneByObj = eventItem.taskList?.doneBy?.[0]?._id ? eventItem.taskList?.doneBy?.[0] : (eventItem.taskList?.doneBy?._id ? eventItem.taskList?.doneBy : null);
    return (
      <div style={{ width: "300px", height: "220px" }}>
        <div className="scheduler_dot">
          <span
            className="status-dot mt-1 col-1"
            style={{ backgroundColor: eventItem?.bgColor ?? "#898988" }}
          ></span>
          <span className="header2-text ms-1 text-center" title="Medicine Name">
            {eventItem?.taskList?.medName}
          </span>
        </div>
        <Row type="flex" align="middle">
          <Col span={100} style={{ lineHeight: "15px" }}>
            <p>
              Dose Amount: {eventItem.taskList.doseAmount}{" "}
              {eventItem.taskList?.strengthUnit}
            </p>
            <p>
              Duration of Course: {eventItem.taskList?.durationofCourse} Days
            </p>
            <p>Schedule Time: {schedule}</p>
            <p>Meal Consideration: {eventItem.taskList?.mealConsideration}</p>
            {/* <p>Units (in-Stock): {eventItem.taskList?.units}</p> */}
            {(eventItem.bgColor === "#2fbbe9" || eventItem.bgColor === "#219653") && <p>Completed Time: {convertToTimeZone(utcDateTime)}</p>}
            {(eventItem.bgColor === "#F2C94C") && <p>Omitted Time: {convertToTimeZone(utcDateTime)}</p>}
            {(eventItem.bgColor === "#F2C94C" || eventItem.bgColor === "#2fbbe9" || eventItem.bgColor === "#219653") && <p>CareTeam: {getFullName(doneByObj)}</p>}
          </Col>
        </Row>
      </div>
    );
  };

  deleteButtonClicked = (event) => {
    this.setState(
      (prevState) => {
        return prevState.viewModel.events.filter(
          (index) => index.id !== event.id
        );
      },
      () => this.state.viewModel
    );
    this.deleteShift(event.id);
  };

  slotItemTemplateResolver(
    schedulerData,
    slot,
    slotClickedFunc,
    width,
    clsName
  ) {
    let resourceData = schedulerData.getSlotById(slot.slotId);
    return (
      <span className="schedulerResourse">
        <img className="profile_img_calendar" src={`${resourceData?.image}`} />{" "}
        <span>{slot.slotName}</span>
      </span>
    );
  }

  prevClick = (schedulerData) => {
    schedulerData.prev();
    schedulerData.setEvents(this.state.EventsArr);
    this.setState({
      viewModel: schedulerData,
    });
    this.getMedicinesCarehomeWise(schedulerData.startDate, schedulerData.endDate);
  };

  nextClick = (schedulerData) => {
    schedulerData.next();
    schedulerData.setEvents(this.state.EventsArr);
    this.setState({
      viewModel: schedulerData,
    });
    this.getMedicinesCarehomeWise(schedulerData.startDate, schedulerData.endDate);
  };

  onViewChange = (schedulerData, view) => {
    schedulerData.setViewType(
      view.viewType,
      view.showAgenda,
      view.isEventPerspective
    );
    schedulerData.setEvents(this.state.EventsArr);
    this.setState({
      viewModel: schedulerData,
    });
    this.getMedicinesCarehomeWise(schedulerData.startDate, schedulerData.endDate);
  };

  onSelectDate = (schedulerData, date) => {
    schedulerData.setDate(date);
    schedulerData.setEvents(this.state.EventsArr);
    this.setState({
      viewModel: schedulerData,
    });
    this.getMedicinesCarehomeWise(schedulerData.startDate, schedulerData.endDate);
  };

  eventClicked = (schedulerData, event) => {
    schedulerData.setEvents(this.state.EventsArr);
    this.setState({
      viewModel: schedulerData,
    });
  };

  newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
    // console.log(schedulerData, slotId, slotName, start, end, type, item, "eventClicked==>>newEvent")
    this.setState({ redirect: false, clientId: slotId });
  };

  updateEventStart = (schedulerData, event, newStart) => {
    schedulerData.updateEventStart(event, newStart);
    this.setState({
      viewModel: schedulerData,
    });
  };

  updateEventEnd = (schedulerData, event, newEnd) => {
    schedulerData.updateEventEnd(event, newEnd);
    this.setState({
      viewModel: schedulerData,
    });
  };

  onScrollRight = (schedulerData, schedulerContent, maxScrollLeft) => {
    if (schedulerData.ViewTypes === ViewTypes.Day) {
      schedulerData.next();
      schedulerData.setEvents(this.state.EventsArr);
      this.setState({
        viewModel: schedulerData,
      });
      schedulerContent.scrollLeft = maxScrollLeft - 10;
    }
  };

  onScrollLeft = (schedulerData, schedulerContent, maxScrollLeft) => {
    if (schedulerData.ViewTypes === ViewTypes.Day) {
      schedulerData.prev();
      schedulerData.setEvents(this.state.EventsArr);
      this.setState({
        viewModel: schedulerData,
      });
      schedulerContent.scrollLeft = 10;
    }
  };

  toggleExpandFunc = (schedulerData, slotId) => {
    schedulerData.toggleExpandStatus(slotId);
    this.setState({ viewModel: schedulerData });
  };
}

export default WithDragDropContext(Basic);