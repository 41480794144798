import React from "react";
import NoRowsOverlay from "../../components/NoRowsOverlays";
// import datechangeHandler from "../../utils/datechangehandler";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { styled } from "@mui/system";
// import { useSelector } from "react-redux";

const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
    "& .MuiDataGrid-cell:focus": {
        outline: "none",
    },
}));

const columns = [
    // { field: "id", headerName: "No.", width: 30 },
    { field: "careSiteName", headerName: "Site Name", flex: 1, minWidth: 200 },
    { field: "pending", headerName: "Pending", width: 120 },
    { field: "inProgress", headerName: "In-Process", flex: 1, minWidth: 120 },
    { field: "completed", headerName: "Completed", flex: 1, minWidth: 120 },
    { field: "overdue", headerName: "Overdue", flex: 1, minWidth: 120 },
    { field: "omitted", headerName: "Omitted", flex: 1, minWidth: 120 },
    { field: "total", headerName: "Total Tasks", flex: 1, minWidth: 120 },
    { field: "reportIncident", headerName: "Incidents", flex: 1, minWidth: 120 },
];

export default function Operations(props) {
    let { rows, searchText } = props;

    // const { currentPlanName } = useSelector((state) => state.caresiteDataReducer);
    // const isPlanBase = currentPlanName === 'Base';

    const filteredRows = searchText ? rows?.filter(row => row.careSiteName?.toLowerCase().includes(searchText)) : rows;

    return (
        <div style={{ height: "70vh", width: "100%" }}>
            <CustomDataGrid
                columns={columns}
                rows={filteredRows}
                // autoHeight
                checkboxSelection={false}
                disableSelectionOnClick
                getRowId={(row) => row?.id}
                slots={{
                    toolbar: GridToolbar,
                    noRowsOverlay: NoRowsOverlay,
                }}
            />
        </div>
    );
}
