import React, { useEffect } from "react";
import { useState } from "react";
import GoCardlessPayment from "./GoCardlessPayment";
// import "./signup.css";
import StripePayment from "../Spayment/StripePayment";
import { useLocation } from "react-router-dom";
import { httpPayments } from "../../Apis/commonApis";
import { AiOutlineArrowLeft } from "react-icons/ai";

export default function FinalStepPaymentRedirection(props) {
  const { cartData, setOpenPaymentPage } = props;

  const location = useLocation();
  const { ownerId } = location.state;

  const [selectedTab, setSelectedTab] = useState(true);
  const [adminData, setAdminData] = useState(null);

  useEffect(() => {
    localStorage.removeItem("selectedWebsiteData");
    getAdminData();
  }, []);

  function handleTabSelection(type) {
    setSelectedTab(type === "cardless" ? true : false);
  }

  const getAdminData = async () => {
    const res = await httpPayments(`getAdmin?adminId=${ownerId}`, null);
    if (res.status) setAdminData(res.result[0]);
  };

  const tabStyle = {
    marginRight: "20px",
    cursor: "pointer",
    fontWeight: "600",
  };

  const styleNone = {
    marginRight: "20px",
    cursor: "pointer",
  };

  return (
    <>
      <div className="themeColor mt-5 promotionalCode d-flex justify-content-center">
        <span onClick={() => setOpenPaymentPage(false)} style={{ fontSize: '20px', margin: '0 20px 10px 0', cursor: 'pointer' }}>
          <AiOutlineArrowLeft />
        </span>
        <h4
          style={selectedTab ? tabStyle : styleNone}
          onClick={() => handleTabSelection("cardless")}
        >
          Direct Debit
        </h4>
        <hr />
        <h4
          style={selectedTab ? styleNone : tabStyle}
          className="ms-3"
          onClick={() => handleTabSelection("stripe")}
        >
          Credit Card
        </h4>
        <hr />
      </div>
      {selectedTab && (
        <GoCardlessPayment cartData={cartData} adminData={adminData} />
      )}
      {!selectedTab && (
        <StripePayment cartData={cartData} adminData={adminData} />
      )}
    </>
  );
}
