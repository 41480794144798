import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import "../Blog/blog.css";
import { IoIosArrowBack } from "react-icons/io";

const BlogDetails = () => {
  let param = useParams();
  console.log(param._id);

  useEffect(() => {
    getblogdata();
  }, []);

  const [rowData, setrowData] = useState([]);
  function getblogdata() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_SUPERADMIN_BASEURL}/getblogbyId/${param._id}`,
      headers: {
        Authorization:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InlvZ2VuZHJhbmFydWthMTk5NUBnbWFpbC5jb20iLCJwYXNzd29yZCI6IjEyMzQ1NiIsImFkbWluSWQiOiI2NDNlMzgyZTkyMDlkMWFhZjE5OTk2MDkiLCJpYXQiOjE2ODE4MTE4NDZ9.5OGkOGDPA_0UCz7l4UL23k7tyDhQcSvxLgry7nQf6pw",
      },
    };

    axios(config)
      .then(function (response) {
        if (response.data.status) setrowData(response.data.Blogdata);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <>
      <section className="mt-5 pagespace_section resp_pagespace_section">
        <div className="container">
          <div className="title">
            <h3>Blog</h3>
          </div>
        </div>
      </section>
      <section className="blogmain_section blogDetailsPage resp_blogmain_section ">
      <div className="container">
        {"image" in rowData && (
          <img alt={rowData.blogTitle} src={rowData.image} />
        )}
        <div className="blogmain_body">
          <div className="container"></div>
        </div>
        </div>
      </section>

      <section className="blog_section pt-0 resp_blog_section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="blog_box">
                <div className="blog_box_body">
                  <h2>{rowData.blogTitle} </h2>
                  <time>{rowData.publishDate} </time>
                  <p className="blog_box_text" >{rowData.blogDescription}</p>
                </div>
              </div>
            </div>
          </div>
          <Link to="/allBlogs" className="entRight">
            {" "}
            <IoIosArrowBack /> Blog Home
          </Link>
        </div>
      </section>
    </>
  );
};
export default BlogDetails;
