import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import { MdModeEditOutline } from "react-icons/md";
import swal from "sweetalert";
import { AiOutlineClose } from "react-icons/ai";
import { disablePastDate } from "../../utils/CommonFunctions";
import { useSelector } from "react-redux";

let invoiceoptions = [
  { value: "Fixed Period", label: "Fixed Period" },
  { value: "PayAsYouGo", label: "Pay as you go" },
];

export default function EditInvProf(props) {
  const { selected, getClientRowData, canEditInvProfile } = props;

  const { accessRoles } = useSelector((state) => state.caresiteDataReducer);
  const moduleAccess = useMemo(() => {
    const findCurrentModule = accessRoles?.find(item => item.moduleName === 'Invoicing');
    return findCurrentModule;
  }, [accessRoles]);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [invoiceType, setInvoiceType] = useState("");
  const [frequency, setFrequency] = useState("");
  const [invoiceRate, setInvoiceRate] = useState("");
  const [editkeyStatus, seteditkeyStatus] = useState(false);
  const [invoiceDate, setInvoiceDate] = useState("");

  let [invoiceFundingoptions, setInvoiceFundingoptions] = useState([]);
  let [percentOpts, setPercentOpts] = useState([]);

  let [invoiceFreqoptions, setInvoiceFreqOptions] = useState([
    {
      value: "Hourly",
      label: "Pay-As-You-Go based on hourly rate",
      [selected?.invoiceType == "Fixed Period" ? "isDisabled" : ""]: true,
    },
    {
      value: "Weekly",
      label: "Weekly",
      [selected?.invoiceType == "PayAsYouGo" ? "isDisabled" : ""]: true,
    },
    // {
    //   value: "twiceInWeek",
    //   label: "BiWeekly",
    //   [selected?.invoiceType == "PayAsYouGo" ? "isDisabled" : ""]: true,
    // },
    {
      value: "Monthly",
      label: "Monthly",
      [selected?.invoiceType == "PayAsYouGo" ? "isDisabled" : ""]: true,
    },
  ]);

  const [selectedFundSources, setSelectedFundSources] = useState([]);
  const [selectedContact, setSelectedContact] = useState("");
  const [selectedPercent, setSelectedPercent] = useState("");
  const [planType, setPlanType] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    let opts = [];
    for (let i = 1; i <= 100; i++) {
      opts.push({ value: i, label: i + "%" });
    }
    setPercentOpts(opts);
  }, []);

  useEffect(() => {
    if (selected) {
      console.log(selected, 'seelecred')
      // getCareHomeInvoiceDetail();
      let clientKeyContactsoptions = [];
      selected.keycontact?.map((item) => {
        clientKeyContactsoptions.push({
          value: item._id,
          label: item.name,
          email: item.email,
        });
      });
      let fundSrcs = [];
      selected.fundingSources?.map((item) => {
        fundSrcs.push({
          keyCont: item.name,
          keyContId: item.keyContactId,
          email: item.email,
          percentVal: item.InvContriPercentage,
        });
      });
      let invDate = selected?.invoiceStartDate ? selected?.invoiceStartDate : '';
      setPlanType(selected?.clientPlanType)
      setSelectedFundSources(fundSrcs);
      setInvoiceFundingoptions(clientKeyContactsoptions);
      setInvoiceType(selected?.invoiceType);
      setInvoiceDate(invDate);
      setFrequency(selected?.frequency);
      setInvoiceRate(selected.invoiceRate);
      handleInvoiceFreqOpts(selected.invoiceType);
    }
  }, [selected]);
  // ----------------------------------------------- Edit Invoice Api ----------------------------------------- //

  function editClient() {
    let fundSrcArr = [];
    if (selectedFundSources.length < 1 || invoiceRate === "") {
      setError(invoiceRate === "" ? "invRate" : "fundSources");
      return;
    }
    if (planType === "") {
      setError("planType");
      return;
    }
    selectedFundSources.forEach((elem) => {
      fundSrcArr.push({
        keyContactId: elem.keyContId,
        name: elem.keyCont,
        InvContriPercentage: elem.percentVal,
        email: elem.email,
      });
    });
    var data = JSON.stringify({
      careHomeId: selected.careHomeId,
      invoiceType: invoiceType,
      invoiceStartDate: invoiceDate,
      frequency: frequency,
      invoiceRate: +invoiceRate,
      clientPlanType: planType,
      fundingSources: fundSrcArr,
    });
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/edit_client/${selected?._id}`,
      headers: {
        Authorization: localStorage.getItem("care_admin_token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (res) {
        getClientRowData();
        handleClose2();
        swal(
          res.data.status ? "Success" : "Failed",
          res.data.message,
          res.data.status ? "success" : "error"
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function invoiceTypehandleChange(event) {
    setInvoiceType(event.value);
    handleInvoiceFreqOpts(event.value);
  }

  function handleInvoiceFreqOpts(invTypeVal) {
    if (invTypeVal === "Fixed Period") {
      setInvoiceFreqOptions((prev) => {
        return prev.map((item) => {
          if (item.value == "Hourly") {
            return { value: item.value, label: item.label, isDisabled: true };
          } else {
            return { value: item.value, label: item.label };
          }
        });
      });
    }

    if (invTypeVal === "PayAsYouGo") {
      setInvoiceFreqOptions((prev) => {
        return prev.map((item) => {
          if (item.value == "Hourly") {
            return { value: item.value, label: item.label };
          } else {
            return { value: item.value, label: item.label, isDisabled: true };
          }
        });
      });
    }
  }

  function invoiceFreqhandleChange(event) {
    setFrequency(event.value);
  }

  function handleRateChange(event) {
    setInvoiceRate(event.target.value);
    setError("");
  }

  function handleEditClick() {
    if (selected && canEditInvProfile) {
      handleShow2();
    } else {
      let swalText = 'Please Select checkbox to proceed';
      if (selected) swalText = "Please complete the Basic Details before editing the client's invoice profile.";
      swal("Failed", swalText, "error").then(
        (value) => {
          return;
        }
      );
    }
  }

  // -------------- multi start time and end time selection ----------------- //

  function fundingSourcesChangeHandler(event) {
    setSelectedContact(event);
  }

  function percentChangeHandler(event) {
    setSelectedPercent(event);
  }

  const handleFundSourceAdd = (e, keyCont, percentVal) => {
    e.preventDefault();
    if (keyCont && percentVal) {
      setSelectedFundSources((prev) => [
        ...prev,
        {
          keyCont: keyCont.label,
          keyContId: keyCont.value,
          email: keyCont.email,
          percentVal: percentVal.value,
        },
      ]);
    }
    setError("");
    setSelectedPercent("");
    setSelectedContact("");
  };

  const fundSrcRemoveChangeHandler = (e, indexToRemove) => {
    e.preventDefault();
    const clonefield = selectedFundSources.slice();
    clonefield.splice(indexToRemove, 1);
    setSelectedFundSources(clonefield);
  };

  const defaultInvType = invoiceoptions.find(item => item.value === selected?.invoiceType);

  const remainPercent = selectedFundSources.reduce(
    (acc, obj) => acc + obj.percentVal,
    0
  );
  const filteredPercentOpts = percentOpts.filter(
    (option) => option.value <= 100 - remainPercent
  );

  const filteredFundOpts = invoiceFundingoptions.filter(
    (item1) =>
      !selectedFundSources.some((item2) => item1.value === item2.keyContId)
  );

  return (
    <>
      <div className="btns_head d-flex justify-content-end mb-1">
        {/* <button className="btn btn-theme btn-sm">Create Groups</button> */}
        <button className="btn btn-theme btn-sm"
          disabled={moduleAccess?.access !== 'full'}
          onClick={handleEditClick}>
          Edit
        </button>
      </div>
      <Modal className="viewModal" show={show2} onHide={handleClose2}>
        <Modal.Header>
          <Modal.Title>
            <span>Edit Client Invoice Profile</span>
            <span>
              <AiOutlineClose className="curser_point" onClick={handleClose2} />
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selected?.keycontact.length > 0 ? (
            <form>
              <div className="row">
                <div className="col-md-12 mb-3">
                  <label className="mb-1">Invoice Type</label>
                  <Select
                    defaultValue={defaultInvType}
                    options={invoiceoptions}
                    onChange={invoiceTypehandleChange}
                  />
                </div>
                {invoiceType === "Fixed Period" && (
                  <div className="col-md-12 mb-3">
                    <label className="mb-1">Start Date</label>
                    <input
                      type="date"
                      className="form-control"
                      min={disablePastDate()}
                      defaultValue={invoiceDate}
                      onChange={(e) => {
                        setInvoiceDate(e.target.value);
                      }}
                    />
                  </div>
                )}

                <div className="col-md-12 mb-3">
                  <label className="mb-1">Invoice Frequency</label>
                  <Select
                    defaultValue={{
                      value: selected?.frequency,
                      label: selected?.frequency,
                    }}
                    options={invoiceFreqoptions}
                    onChange={invoiceFreqhandleChange}
                  />
                </div>

                <div className="col-md-12 mb-3">
                  <label className="mb-1">
                    Rate/{frequency?.split("ly")[0]}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={selected?.invoiceRate}
                    onChange={handleRateChange}
                  />
                  {error.includes("invRate") && (
                    <span className="error_style">This field is required</span>
                  )}
                </div>

                <div className="mb-3 col-md-12">
                  <label className={`form-label m-0`}>Plan Type</label>
                  <select
                    className="form-select"
                    defaultValue={planType}
                    onChange={(e) => { setPlanType(e.target.value); setError('') }
                    }
                  >
                    <option value="">--Select--</option>
                    <option value="Council">Council</option>
                    <option value="Self-Funded">Private</option>
                  </select>
                  {error.includes("planType") && (
                    <span className="error_style">This field is required</span>
                  )}
                </div>

                <div className="col-md-12">
                  <label className="mb-1">Funding Source</label>{" "}
                  <MdModeEditOutline
                    onClick={() => {
                      seteditkeyStatus(true);
                    }}
                  />
                  <div className="d-flex">
                    <div className="col-5">
                      <label>Select Contact</label>
                      <Select
                        value={selectedContact}
                        options={filteredFundOpts}
                        onChange={fundingSourcesChangeHandler}
                      />
                    </div>
                    <div className="col-7 ms-3">
                      <label>Funding Percentage</label>
                      <div className="d-flex">
                        <div className="col-9">
                          <Select
                            value={selectedPercent}
                            options={filteredPercentOpts}
                            onChange={percentChangeHandler}
                          />
                        </div>
                        <button
                          className="ms-2 btn theme_btn"
                          onClick={(e) =>
                            handleFundSourceAdd(
                              e,
                              selectedContact,
                              selectedPercent
                            )
                          }
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                  {error.includes("fundSources") && (
                    <span className="error_style">This field is required</span>
                  )}
                </div>


                {selectedFundSources?.map((item, index) => {
                  return (
                    <div key={index} className="col-md-12 mt-2">
                      <div className="pe-5 position-relative d-flex general">
                        <input
                          className="form-control"
                          type="text"
                          value={item?.keyCont}
                          disabled
                        />
                        <input
                          className="form-control ms-3"
                          type="text"
                          value={item?.percentVal + "%"}
                          disabled
                        />
                        <button
                          className="removeBtn"
                          style={{ top: "3px" }}
                          onClick={(e) => {
                            fundSrcRemoveChangeHandler(e, index);
                          }}
                        >
                          -
                        </button>
                      </div>
                    </div>
                  );
                })}

                <div className="col-md-12 my-2">
                  <label className="mb-1">Email</label>
                  <input
                    type="text"
                    className="form-control"
                    value={selectedFundSources
                      ?.map((item) => item?.email)
                      .join(", ")}
                    disabled
                  />
                </div>
              </div>
            </form>
          ) : (
            <div>
              <span>
                Please add key contacts for this client by going in client
                section before editing the invoice details.{" "}
              </span>
              <br />
              <span>As It will be required for adding funding sources. </span>
              <br />
              Thanks.
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {selected?.keycontact.length > 0 && (
            <div className="d-flex">
              <button
                type="button"
                className="btn"
                onClick={() => {
                  editClient();
                }}
              >
                Save
              </button>
            </div>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
