import React, { useEffect, useState } from "react";
import cx from "./Portal.module.css";
import { Row, Col,Button } from "react-bootstrap";
import { NavLink } from 'react-router-dom';
import tick from '../images/tick.svg'
import { useHistory } from "react-router-dom";

export default function RequestService() {
  const history = useHistory();
  const [selectedItems, setSelectedItems] = useState([]);
  const [shiftInstructions,setShiftInstructions] = useState("")
  const [error,setError] = useState("")
  const [serviceerror,setserviceError] = useState("")
  const handleSelectClick = (itemName) => {
    if (selectedItems.includes(itemName)) {
      setSelectedItems(selectedItems.filter(item => item !== itemName));
    } else {
      setSelectedItems([...selectedItems, itemName]);
    }
  }
  
  let onSubmit = ()=>{
    if(selectedItems?.length<1){
      setserviceError("Please select min one service")
      return;
    }else if (shiftInstructions?.length <1){
      setError("this field required")
      return;
    }else{
      history.push({
        pathname: `/portal/RequestServiceCalendar`,
        state: { service:selectedItems, shiftInstructions:shiftInstructions},
      });
    }
  }
  const isItemSelected = (itemName) => selectedItems.includes(itemName);
  return (
    <div className={`${cx.profilePage}`}>
      <div className={`container`}>
        <Row>
          <Col lg={4} md={6} sm={12} className={`m-auto`}>
            <div className={`${cx.ProfileBox} ${cx.RequestBox}`}>
              <div className={`${cx.topHeadeing}`}>
                  <h3>Request Service</h3>
                  <div className={`${cx.backIcon}`}>
                  <NavLink to="/portal/portal-main"><img src="/images/BackVector.png" alt=""/></NavLink>
                  </div>
                  
              </div>
              
              <Row>
                {/* <Col lg="12">
                  <div className={`${cx.heading}`}>
                      <h5>Select Services</h5>
                  </div>
                </Col> */}
                <Col lg="12">
                  <div className={`${cx.productBox}`}>
                      <div className={`${cx.iconBox} ${isItemSelected('Personal Care') ? cx.SelectServicesActive : ""}`} onClick={() => handleSelectClick('Personal Care')}>
                          <img src="/images/s1.svg" alt="" />
                          <span>Personal Care</span>
                      </div>
                      <div className={`${cx.iconBox} ${isItemSelected('Health') ? cx.SelectServicesActive : ""}`} onClick={() => handleSelectClick('Health')}>
                          <img src="/images/s2.svg" alt="" />
                          <span>Health</span>
                      </div>
                  </div>
                </Col>
                <Col lg="12">
                  <div className={`${cx.productBox}`}>
                      <div className={`${cx.iconBox} ${isItemSelected('Mobility') ? cx.SelectServicesActive : ""}`} onClick={() => handleSelectClick('Mobility')}>
                          <img src="/images/s3.svg" alt="" />
                          <span>Mobility</span>
                      </div>
                      <div className={`${cx.iconBox} ${isItemSelected('Diet') ? cx.SelectServicesActive : ""}`} onClick={() => handleSelectClick('Diet')}>
                          <img src="/images/s4.svg" alt="" />
                          <span>Diet</span>
                      </div>
                  </div>
                </Col>
                <Col lg="12">
                  <div className={`${cx.productBox}`}>
                      <div className={`${cx.iconBox} ${isItemSelected('Social') ? cx.SelectServicesActive : ""}`} onClick={() => handleSelectClick('Social')}>
                          <img src="/images/s5.svg" alt="" />
                          <span>Social</span>
                      </div>
                      <div className={`${cx.iconBox} ${isItemSelected('Housekeeping') ? cx.SelectServicesActive : ""}`} onClick={() => handleSelectClick('Housekeeping')}>
                          <img src="/images/s7.svg" alt="" />
                          <span>Housekeeping</span>
                      </div>
                  </div>
                      {selectedItems?.length <1 && <p className="error_style">{serviceerror}</p>}
                </Col>
                <Col lg="12">
                  <div className={`${cx.textAreaBox}`}>
                      <textarea placeholder="Shift Instructions" onChange={(e)=>{
                        setShiftInstructions(e?.target?.value)
                      }}></textarea>
                  </div>
                  {shiftInstructions?.length <1 && <p className="error_style">{error}</p>}
                  <div className={`${cx.nextButton}`}>
                    <button onClick={onSubmit}>Next</button>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}
