import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { httpAdmin } from "../../Apis/commonApis";

const initialState = {
    portalUserData: null
};

const portalDataSlice = createSlice({
    name: "portalDataSlice",
    initialState,
    reducers: {
        updatePortalUserData(state = initialState, action) {
            state.portalUserData = action?.payload?.userData;
        },
    },
});
console.log(portalDataSlice.actions,"portalDataSlice");
export const { updatePortalUserData } =
    portalDataSlice.actions;
export default portalDataSlice.reducer;
