import React, { useEffect, useState } from "react";
import { NavLink, useHistory, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import "../Login/login.css";
import swal from "sweetalert";
import paramsObject from "../../utils/paramsToObject";

function VerifyUser() {
  let history = useHistory();
  let params = useParams();
  const { search } = useLocation();
  let searchSections = search.substring(1);
  let { emailToken } = paramsObject(searchSections);
  console.log(emailToken, "lgoinsdnfasdajsd");

  //   const [email, setEmail] = useState("");
  //   const [password, setPassword] = useState("");
  function verify() {
    var data = JSON.stringify({
      adminId: params.id,
      emailToken,
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/authenticate`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response, "response");
        if (response.data.status === true) {
          swal("Success", response.data.message, "success").then((value) => {
            if (
              response.data.message.includes("Please authenticate yourself")
            ) {
              return;
            } else {
              localStorage.setItem("care_admin_token", response.data.token);
              localStorage.setItem(
                "userData",
                JSON.stringify(response.data.data)
              );
              history.push("/admin/dashboard");
            }
          });
        } else {
          swal("Failed", response.data.message, "error").then((value) => {
            history.push("/login");
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    verify();
  }, []);

  return (
    <section className="login_section">
      <div className="login_header">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 logo_box">
              <NavLink className="navbar-brand" to="/admin/dashboard">
                <b className="logo-icon">
                  <img alt="" src={`/images/logo.svg`} />
                </b>
              </NavLink>
            </div>
            <div className="col-md-9">
              <div className="login_title">
                <h1>Care Management Simplified!</h1>
                <p>
                  Welcome to your new experience of care management. Our
                  powerful, easy to use <br/> and intuitive care platform, enables you
                  to easily manage all you care tasks.
                </p>
              </div>
            </div>
            <div className="col-md-3 img_box">
              <img alt="" src={`/images/login.svg`} />
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 form_box"></div>
    </section>
  );
}

export default VerifyUser;
