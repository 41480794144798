import React, { Fragment, useState } from "react";
import PlanHeaderSection from "./PlanHeaderSection";
import FeatureSection from "./FeatureSection";
import FriendsAndFamilyAppSection from "./FriendsAndFamilyAppSection";
import RedirectionLinks from "./RedirectionLink";
import TrailPeriod from "./TrialPeriod";

export default function PlanSection(props) {
  const [fandfData, setfandfData] = useState({
    planName: "",
    fandfStatus: false,
    count: 0,
    rate: 0
  });
  let { planDetails, SubscriptionType } = props;

  function fandfselectHandler(data, status, event, fRate) {
    setfandfData({ planName: data, fandfStatus: status, count: event, rate: fRate });
  }

  let fandfAddOnData = planDetails?.combined?.FandFappData?.[0]

  return (
    <Fragment>
      <div className="col-md-4 plan_main">
        <div className="plan_box">
          {/* image section  */}
          <PlanHeaderSection
            plan={planDetails}
            SubscriptionType={SubscriptionType}
            fandfData={fandfData}
          />

          {/* feature section  */}
          <FeatureSection features={planDetails?.featureList} />

          {/* friends and family app  */}

          {fandfAddOnData?.isActive &&
            <FriendsAndFamilyAppSection
              planName={planDetails?.planName}
              fnfData={fandfAddOnData}
              SubscriptionType={SubscriptionType}
              fandfselectHandler={fandfselectHandler}
            />}
          {/* showing free trial or not */}

          {/* get started button for redirection
           */}

          <RedirectionLinks
            fandfData={fandfData}
            planName={planDetails?.planName}
            planId={planDetails?._id}
            prize={+planDetails?.combined?.FandFappData[0]?.unitPrice}
            currency={planDetails?.currency}
            SubscriptionType={SubscriptionType}
          />

          <TrailPeriod planName={planDetails?.planName} />
        </div>
      </div>
    </Fragment>
  );
}
