import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { httpSuperAdmin } from "../../Apis/commonApis";
import Select from "react-select";
import swal from "sweetalert";

const PaymentAction = (props) => {
  let { rowDetail, latestRowData } = props;
  console.log(rowDetail, 'rowDetail55555555555555')
  const [allCopanyNames, setAllCompanyNames] = useState([]);
  const [careSiteNames, setCareSiteNames] = useState([]);
  const [paymentDetails, setPaymentDetails] = useState({
    adminId: "",
    careHomeId: "",
    finalId: "",
    companyName: "",
    careSiteName: "",
    plan: "",
    created_at: "",
    amount: "",
    status: "received",
  });

  useEffect(() => {
    fetchCompanyNames();
  }, []);

  const clickHandler = () => {
    if (!rowDetail) {
      swal("Error!", 'Please select checkbox to proceed!!', "error");
      return;
    }
    swal({
      title: 'Are you sure??',
      text: 'click below button to procced',
      buttons: {
        cancel: 'Cancel',
        succeed: 'Yes',
      },
    }).then((result) => {
      if (result) {
        RefundPayment();
      } else {
        console.log('rejected');
      }
    });
  };

  const RefundPayment = async () => {
    let refundDetail = {
      careHomeId: rowDetail.careHomeId,
      paymentThrough: rowDetail.paymentThrough,
      paymentRefId: rowDetail.refundId,
      amount: rowDetail.totalAmount,
    };
    const result = await httpSuperAdmin(`makeRefund`, refundDetail);
    if (result.status) {
      swal("Success!", 'Refund Successfully!!', "success");
      latestRowData();
    } else {
      swal("Error!", result?.message ?? 'Something went wrong', "error");
    }
  };

  const fetchCompanyNames = async () => {
    let result = await httpSuperAdmin("getCareHomeAndItsSites", null);
    let names = [];
    result?.data?.forEach((elem) => {
      names.push({ value: elem.adminId, label: elem.companyName });
    });
    setAllCompanyNames(names);
  };

  const fetchCareSiteNames = async (id) => {
    let result = await httpSuperAdmin(`getCareHomeAndItsSites?adminId=${id}`, null);
    let names = [];
    result?.data?.forEach((elem) => {
      names.push({ value: elem._id, label: elem.careSiteName, id: elem.finalId });
    });
    setCareSiteNames(names);
  };

  const createPayment = async () => {
    let result = await httpSuperAdmin("createPayment", paymentDetails);
    if (result.status) latestRowData();
  };

  function handleSubmit(e) {
    e.preventDefault();
    createPayment()
  }

  function handleCompanyNameSelect(e) {
    setPaymentDetails({ ...paymentDetails, companyName: e.label, adminId: e.value });
    fetchCareSiteNames(e.value);
  }

  function handleCareSiteNameSelect(e) {
    setPaymentDetails({ ...paymentDetails, careSiteName: e.label, careHomeId: e.value, finalId: e.id });
  }

  function handleInputChange(e, field) {
    setPaymentDetails((prev) => { return { ...prev, [field]: e.target.value } });
  }

  return (
    <>
      <h4 className="card-title default_color">
        {/* Payments */}
        <div className="float-end btns_head d-flex">
          {/* <button
            className="btn btn-theme btn-sm"
            data-bs-toggle="modal"
            data-bs-target="#addnewblog_modal"
            data-bs-whatever="@mdo"
          >
            Add
          </button> */}
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              Action
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                data-bs-toggle="modal"
                data-bs-target="#addnewblog_modal"
                data-bs-whatever="@mdo">
                Add
              </Dropdown.Item>
              <Dropdown.Item onClick={() => clickHandler()}>
                Refund
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {/* <select
            id="event"
            name="event"
            className="border-0 default_color ms-3"
            style={{ width: "40px", fontSize: "15px" }}
          >
            <option value="all">All</option>
          </select> */}
        </div>
      </h4>
      <div
        className="modal fade"
        id="addnewblog_modal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel1"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header d-flex align-items-center">
              <h4 className="modal-title" id="exampleModalLabel1">
                Add Payment
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body">
              <form>
                <div className="row">
                  <label className="form-label mt-2">Select Company Name</label>
                  <Select
                    options={allCopanyNames}
                    onChange={handleCompanyNameSelect}
                  />
                  <label className="form-label mt-2">
                    Select Care Site Name
                  </label>
                  <Select
                    options={careSiteNames}
                    onChange={handleCareSiteNameSelect}
                  />

                  <div className="mb-3 mt-2 col-md-12">
                    <label className={`form-label`}>Plan Type</label>
                    <input type="text" className="form-control" onChange={(e) => handleInputChange(e, 'plan')} />
                  </div>
                  <div className="mb-2 col-md-12">
                    <label className={`form-label`}>Value Received</label>
                    <input type="text" className="form-control" onChange={(e) => handleInputChange(e, 'amount')} />
                  </div>
                  <div className="mb-2 col-md-12">
                    <label className={`form-label`}>Receipt Date</label>
                    <input type="date" className="form-control" onChange={(e) => handleInputChange(e, 'created_at')} />
                  </div>
                </div>
                <div className="blog_popup_details">
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-primary submit_btn"
                      data-bs-dismiss="modal"
                      onClick={(e) => handleSubmit(e)}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentAction;
