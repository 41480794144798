import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import ReactApexChart from 'react-apexcharts';

export default function GraphsSalesFunnel(props) {
  const { title, filterType, graphVals, customDates } = props;

  const [options, setOptions] = useState({
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      curve: 'straight',
      lineCap: 'butt',
      colors: undefined,
      width: 3,
      dashArray: 0,
    },
    // title: {
    //   text: 'Product Trends by Month',
    //   align: 'left'
    // },
    grid: {
      row: {
        colors: ['transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      },
    },
    xaxis: {
      categories: ['', '4AM', '8AM', '12AM', '4PM', '8PM', '12PM'],
      floating: false,
    },
    yaxis: [
      {
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#585858'
        },
      },
    ],
  });

  const [series, setSeries] = useState([{
    name: 'Revenue',
    data: [0, 41, 35, 51, 49, 62, 73]
  }]);

  const splitTitle = title.split('-');

  useEffect(() => {

    if (filterType === 'today') {
      setOptions({
        ...options, xaxis: {
          categories: ['0', '4AM', '8AM', '12AM', '4PM', '8PM', '12PM'],
        },
        title: {
          text: 'Trends by Today',
          align: 'left'
        },
      });
    } else if (filterType === 'week') {
      setOptions({
        ...options, xaxis: {
          categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
          floating: false,
        },
        title: {
          text: 'Trends by Week',
          align: 'left'
        },
      });
    } else if (filterType === 'custom') {
      setOptions({
        ...options,
        xaxis: {
          categories: customDates,
          // floating: true,
          labels: {
            show: false,
          },
        },
        tickPlacement: 'on',
        // labels: {
        //   formatter: function (value) {
        //       if (value !== undefined) {
        //           return  "";
        //       }
        //       return ""
        //   }
        // },
        title: {
          text: 'Trends by Date',
          align: 'left'
        },
      });
    }
    if (title === 'Average Fee Rates') {
      setSeries([
        { name: 'Fixed Fee (Weekly)', data: graphVals.fixFee },
        { name: 'PayAsYouGo (Hourly)', data: graphVals.payAsGo },
      ])
    } else {
      setSeries([{
        name: splitTitle[0],
        data: graphVals
      }])
    }
  }, [filterType, graphVals])


  return (
    <div className="col-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
      <div className="card dashboard_card card_border">
        <div className="card-body">
          <h4 className="card-title">{splitTitle[0]} <span>{splitTitle[1] ?? ''}</span></h4>
          <div className="graph_box">
            <div id="chart">
              <ReactApexChart options={options} series={series} type="line" height={215} />
            </div>
          </div>
          {/* <NavLink to="/superadmin/dashboard" className="link">Sell All</NavLink> */}
        </div>
      </div>
    </div>
  )
}
