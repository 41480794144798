import React from "react";
import "../Clients/clients.css";
import NoRowsOverlay from "../../components/NoRowsOverlays";
// import datechangeHandler from "../../utils/datechangehandler";
import { IoIosArrowForward } from "react-icons/io";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { styled } from "@mui/system";
import PreviewButton from "../../components/PreviewButton";

const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-cell:focus": {
    outline: "none",
  },
}));

const columns = [
  // { field: "id", headerName: "No.", width: 30 },
  {
    field: "createdBy",
    headerName: "Created by",
    width: 210,
    // renderCell: (params) => <ProfileImgName params={params} />,
  },
  {
    field: "creationDate",
    headerName: "Creation Date",
    flex: 1,
    minWidth: 190,
  },
  {
    field: "nextReview",
    headerName: "Next Review",
    flex: 1,
    minWidth: 150,
    align: "left",
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    minWidth: 150,
    renderCell: (params) => (
      <>
        <StatusButton params={params} />
      </>
    ),
  },

  {
    field: "image",
    headerName: "File",
    width: 50,
    editable: false,
    renderCell: (params) => (
      <>
        <PreviewButton filedata={params} />
      </>
    ),
  },
  {
    field: "fileName",
    headerName: "",
    width: 300,
    editable: false,
  },
];

export default function SavedPlanHistory(props) {
  //   let { rows, selectedIDs, searchText } = props;
  let { rows } = props;
  //   const [rowSelectionModel, setRowSelectionModel] = useState([]);

  //   useEffect(() => {
  //     selectedIDs(rowSelectionModel);
  //   }, [rowSelectionModel]);

  //   const filteredRows = searchText
  //     ? rows.filter(
  //         (row) =>
  //           row.name.toLowerCase().includes(searchText) ||
  //           row.location.toLowerCase().includes(searchText)
  //       )
  //     : rows;

  return (
    <div style={{ height: "80vh", width: "100%" }}>
      <CustomDataGrid
        columns={columns}
        rows={rows}
        // onRowClick={handleRowClick}
        // autoHeight
        checkboxSelection
        // onSelectionModelChange={(newRowSelectionModel) => {
        //   setRowSelectionModel(newRowSelectionModel);
        // }}
        disableSelectionOnClick
        getRowId={(row) => row?._id}
        // rowSelectionModel={rowSelectionModel}
        slots={{
          toolbar: GridToolbar,
          noRowsOverlay: NoRowsOverlay,
        }}
      />
    </div>
  );
}

const StatusButton = (props) => {
  let { params } = props;
  return (
    <>
      {params?.row?.status === "active" && (
        <button className={`btn btn-sm btn-success table_btn_font`}>
          Active
        </button>
      )}
      {params?.row?.status === "inactive" && (
        <button className={`btn btn-sm btn-danger table_btn_font`}>
          Inactive
        </button>
      )}
    </>
  );
};
