import { useParams } from "react-router-dom";
import TaskManagement from "../FetauresDetailsPages/TaskManagement";
import Rota from "../FetauresDetailsPages/Rota";
import Emar from "../FetauresDetailsPages/Emar";
import CarePlans from "../FetauresDetailsPages/CarePlans";
import PayrollCalculator from "../FetauresDetailsPages/PayrollCalculator";
import SalesFunnels from "../FetauresDetailsPages/SalesFunnels";

export default function CommonFeaturePages() {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  let { topicId } = useParams();
  console.log(topicId, "CommonFeaturePages");

  return (
    <div>
      {topicId == "rota" && <Rota />}
      {topicId == "task-management" && <TaskManagement />}
      {topicId == "eMAR" && <Emar />}
      {topicId == "care-plans" && <CarePlans />}
      {topicId == "payroll-calculator" && <PayrollCalculator />}
      {topicId == "sales-funnels" && <SalesFunnels />}
    </div>
  );
}
