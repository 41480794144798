import React, { useState, useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import "./StripePayment.css";
import { useHistory, useLocation } from "react-router-dom";
import { fetch4, httpAdmin, httpPayments } from "../../Apis/commonApis";
import countryList from "../Signup/countries.json";
import { useDispatch } from "react-redux";
import {
  changeButton,
  getOwnerId,
  handleEmailVerifyFromLogin,
} from "../../redux-toolkit/reducer/accountCreationApiReducer";
import { currentCareSiteId } from "../../redux-toolkit/reducer/switchSites";
import { getCurrentTimeZone } from "../../utils/CommonFunctions";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripePromise = loadStripe(`${process.env.STRIPE_PUBLISH_KEY}`);
const stripePromise = loadStripe(
  `pk_live_51KKC0gG5ZfZ1lNaoE8eew93c0TSWJhJi5YkZbkuRLJamw0A0LaI55tHVU3qGrm8CNQiPKyB9QCbt1dmyhcfVzD5H00I5zwxjsn`
);

const successMessage = (msg) => {
  return (
    <div className="success-msg">
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        className="bi bi-check2"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"
        />
      </svg>
      <div className="title">Payment Successful</div>
      <p>{msg ? msg : ""}</p>
    </div>
  );
};

const failedMessage = (msg) => {
  return (
    <div className="error-msg">
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.25 4.25L11.75 11.75M11.75 4.25L4.25 11.75"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      <div className="title">Payment Failed</div>
      <p>{msg ? msg : ""}</p>
    </div>
  );
};

function StripePayment(props) {
  const { cartData, adminData } = props;

  const [paymentCompleted, setPaymentCompleted] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [trackData, setTrackData] = useState([]);
  const [newCareSiteData, setNewCareSiteData] = useState([]);
  const [last4, setLast4] = useState("");
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const { ownerId, newCareSite, adminId, trackIdParam } = location.state;

  const dispatch = useDispatch();

  useEffect(() => {
    if (paymentDetails) {
      if (cartData === 0 && paymentDetails.error) {
        setPaymentCompleted("failed");
        setLoading(false);
      } else if (cartData === 0 && paymentDetails.msg) {
        setPaymentCompleted("success");
        setLoading(false);
      } else if (cartData === 0 && paymentDetails.status) {
        changeBillingMethodToStripe();
      } else if (cartData !== 0 && paymentDetails.status) {
        registerNewSite();
      }
    }
  }, [paymentDetails]);

  useEffect(() => {
    if (trackIdParam) getTrackSignup();
    if (newCareSite) {
      let careData = JSON.parse(localStorage.getItem("createNewCareSiteData"));
      setNewCareSiteData(careData);
    }
    let choosedContry = adminData.country ? adminData.country : adminData.billingAddress.split(",")[3]?.trim();
    if (!choosedContry) {
      choosedContry = 'United Kingdom';
    }
    const stripcountry = countryList.countries?.find((ele) => ele.name === choosedContry);
    setSelectedCountry(stripcountry);
  }, [adminData]);

  const getTrackSignup = async () => {
    const res = await fetch4(`getTrackSignup?_id=${trackIdParam}`, null);
    if (res.status) {
      setTrackData(res.data);
    }
  };

  const registerNewSite = async () => {
    let caresitedata = newCareSite ? newCareSiteData : adminData;
    let lattitude = caresitedata?.lattitude ?? caresitedata?.loc?.coordinates?.[1];
    let longitude = caresitedata?.longitude ?? caresitedata?.loc?.coordinates?.[0];
    let data = {
      adminId: newCareSite ? ownerId : adminData?._id,
      companyName: caresitedata.companyName,
      billingAddress: adminData.billingAddress,
      billingEmail: adminData.email,
      billingContact: adminData.mobileNo,
      careSiteName: caresitedata?.careSiteName,
      carefacility: caresitedata?.carefacility,
      careSiteAddress: caresitedata?.careSiteAddress ?? '',
      lattitude: lattitude ?? 0,
      longitude: longitude ?? 0,
      type: newCareSite ? "loggedIn" : "signup",
      ownerName: adminData.firstName,
      bankNOorCardNo: last4,
      totalBeds: caresitedata?.totalBeds,
      planId: cartData.planId,
      SubscriptionPlan: cartData.SubscriptionPlan,
      SubscriptionPlanId: cartData.SubscriptionPlanId,
      monthyOrYearly: cartData.monthyOrYearly,
      planRate: cartData.planPrice,
      chargesDetails: cartData?.chargesDetails,
      unit_amount: cartData?.totalBeforeTax,
      taxRate: cartData?.taxRate,
      total_amount: cartData?.unit_amount,
      // unit_amount: cartData.unit_amount,
      multiCareSite: false,
      planstartDate: Date.now(),
      trialExpiry: new Date(),
      country: selectedCountry.name,
      currency: selectedCountry?.currency?.code,
      paymentThrough: "stripe",
      trackSignUp: "complete",
      stripe_customer_id: paymentDetails.customerId,
      stripe_Price_id: paymentDetails.priceId,
      stripe_subscription_id: paymentDetails?.subscriptionId,
      stripe_coupon_id: paymentDetails.couponId ? paymentDetails.couponId : "",
      product_id: paymentDetails?.subscriptionDetails?.plan?.product,
      productId: cartData?.productId ?? '',
      couponCode: cartData.promoName,
      timeZone: getCurrentTimeZone()
    };
    if (adminId) {
      data.subAdminId = adminId;
    }
    if (cartData?.unit_amount === 0) {
      data.stripe_charge_id = paymentDetails.stripe_charge_id;
    }
    const res = await httpPayments(`registerNewSite?adminId=${adminData?._id}`, data);
    if (res.status) {
      setPaymentCompleted("success");
      dispatch(
        changeButton({
          currentNo: 0,
          buttonText: "Next",
          stepNo: 1,
          nextNo: 1,
        })
      );
      dispatch(getOwnerId({ loginVerifyEmail: 0 }));
      dispatch(handleEmailVerifyFromLogin({ verifySuccessLogin: 0 }));
      setTimeout(() => {
        if (newCareSite) {
          localStorage.removeItem("createNewCareSiteData");
          history.replace("/admin/dashboard");
        } else {
          localStorage.clear();
          history.replace("/login");
        }
      }, 2000);
    } else {
      setPaymentCompleted("failed");
    }
    setLoading(false);
    // let id = adminApiData?._id ? adminApiData?._id : adminData?._id;
  };

  const changeBillingMethodToStripe = async () => {
    let body = {
      careHomeId: adminData.subscriptionPlanData.find(item => item._id === currentCareSiteId)._id,
      adminId: adminData._id,
      newPaymentThrough: "stripe",
      newSubscriptionDetails: {
        stripe_customer_id: paymentDetails.customerId,
        stripe_Price_id: paymentDetails.priceId,
        stripe_subscription_id: paymentDetails.subscriptionId,
        bankNOorCardNo: last4,
      },
    };
    const res = await httpAdmin("changeBillingMethod", 'post', body, null);
    if (res.status) {
      setPaymentCompleted("success");
    } else {
      setPaymentCompleted("failed");
    }
    setLoading(false);
  };

  const containerWidth = {
    maxWidth: "700px",
  };

  return (
    <div
      className={`${cartData === 0 ? "" : "stripeContainer"} container`}
      style={cartData === 0 ? containerWidth : { maxWidth: "1000px" }}
    >
      {/* <div className="py-5 text-center">
        <h4>Stripe Integration - <a href="https://www.cluemediator.com/" target="_blank">Clue Mediator</a></h4>
      </div> */}

      <div className="row s-box">
        {paymentCompleted === "success" &&
          successMessage(paymentDetails?.msg ? paymentDetails?.msg : "")}
        {paymentCompleted === "failed" &&
          failedMessage(
            paymentDetails?.error?.message
              ? paymentDetails?.error?.message
              : "Something went wrong. Please try after somtime"
          )}
        {!paymentCompleted && (
          <React.Fragment>
            {cartData === 0 ? (
              ""
            ) : (
              <div className="col-md-5 order-md-2 mb-4">{cart(cartData)}</div>
            )}
            <div
              className={`${cartData === 0 ? "col-md-12" : "col-md-7"
                } order-md-1`}
            >
              <Elements stripe={stripePromise}>
                <CheckoutForm
                  setLast4={setLast4}
                  countryData={selectedCountry}
                  amount={cartData?.unit_amount}
                  cartDetails={cartData}
                  adminData={adminData}
                  setPaymentCompleted={setPaymentCompleted}
                  setPaymentDetails={setPaymentDetails}
                  setSelectedCountry={setSelectedCountry}
                  loading={loading}
                  setLoading={setLoading}
                />
              </Elements>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

export default StripePayment;

const cart = (cartData) => {
  const currencySymbol = cartData?.selectedCurrency?.symbol;
  const cartCount = (cartData.amount_off > 0 && cartData.licsCount > 0) ? '3' : (cartData.amount_off <= 0 && cartData.licsCount <= 0) ? '1' : '2';
  return (
    <React.Fragment>
      <h4 className="d-flex justify-content-between align-items-center mb-3">
        <span className="text-muted">Your cart</span>
        <span className="badge bg-secondary badge-pill">{cartCount}</span>
      </h4>
      <ul className="list-group mb-3">
        <li className="list-group-item d-flex justify-content-between lh-condensed">
          <div>
            <h6 className="my-0">Subscription Plan</h6>
            <small className="text-muted">{cartData.planName}</small>
          </div>
          <span className="text-muted">{`${currencySymbol} ${cartData.planPrice}`}</span>
        </li>
        <li className="list-group-item d-flex justify-content-between lh-condensed">
          <div>
            <h6 className="my-0">Friends & Family Add-On</h6>
            <small className="text-muted">Count {cartData.licsCount}</small>
          </div>
          <span className="text-muted">
            {currencySymbol} &nbsp;
            {cartData.frndFamAmt ? cartData.frndFamAmt * cartData.licsCount : 0}
          </span>
        </li>
        {/* <li className="list-group-item d-flex justify-content-between lh-condensed">
        <div>
          <h6 className="my-0">Third item</h6>
          <small className="text-muted">Brief description</small>
        </div>
        <span className="text-muted">₹500</span>
      </li> */}
        <li className="list-group-item d-flex justify-content-between bg-light">
          <div className="text-success">
            <h6 className="my-0">Promo code</h6>
            <small>{cartData.promoName}</small>
          </div>
          <span className="text-success">- {`${currencySymbol} ${cartData.amount_off}`}</span>
        </li>
        <li className="list-group-item d-flex justify-content-between">
          <span>Total ({cartData?.selectedCurrency?.currency})</span>
          <strong>{`${currencySymbol} ${cartData.totalBeforeTax}`}</strong>
        </li>
        <li className="list-group-item d-flex justify-content-between bg-light">
          <div className="text-success">
            <h6 className="my-0">Tax</h6>
            {/* <small>{cartData.countryName}</small> */}
          </div>
          <span className="text-success">+{cartData.taxRate} %</span>
        </li>
        <li className="list-group-item d-flex justify-content-between">
          <span>Total (After Tax)</span>
          <strong> {`${currencySymbol} ${cartData.unit_amount}`}</strong>
        </li>
      </ul>
    </React.Fragment>
  );
};