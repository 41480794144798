import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CurrencyConversion } from "../../redux-toolkit/reducer/websitePlan";
import { useEffect } from "react";

export default function PlanHeaderSection(props) {
  let { plan, SubscriptionType, fandfData } = props;

  let { count, fandfStatus, planName } = fandfData;
  const dispatch = useDispatch();
  const { selectedCurrency } = useSelector((state) => state.websitePlans);

  useEffect(() => {
    dispatch(CurrencyConversion());
  }, []);

  let rate = SubscriptionType
    ? (
      converToCurrency(
        plan?.currency,
        plan?.unitPriceYearly,
        plan?.unitPriceMonthly
      ) / 12
    ).toFixed(2)
    : converToCurrency(
      plan?.currency,
      plan?.unitPriceYearly,
      plan?.unitPriceMonthly
    );

  let withoutYDrate = converToCurrency(
    plan?.currency,
    plan?.unitPriceMonthly,
    plan?.unitPriceMonthly
  );

  function converToCurrency(fromCurrency, yearlyPrice, monthlyPrice) {
    let defaultRate = SubscriptionType ? yearlyPrice : monthlyPrice;
    let toRate = selectedCurrency?.gbpExchangeRate;
    const rate = (+toRate * defaultRate).toFixed(2);
    return rate;
  }

  // =================================  FRIENDS AND FAMILY APP PRICING WITH PLAN   ======================== //

  let friendsAndFamilyTotalPrice = +fandfData?.rate * fandfData?.count;
  // =================================  FRIENDS AND FAMILY APP PRICING WITH PLAN   ======================== //

  let totalAlongWithAddOn = (fandfStatus && count > 0) ? (friendsAndFamilyTotalPrice + +rate).toFixed(2) : rate;
  totalAlongWithAddOn = totalAlongWithAddOn !== 'NaN' ? totalAlongWithAddOn : '0.00';
  let withoutYDTotalAlongWithAddOn =
    fandfStatus && count > 0
      ? (friendsAndFamilyTotalPrice + +withoutYDrate).toFixed(2)
      : withoutYDrate;
  withoutYDTotalAlongWithAddOn = withoutYDTotalAlongWithAddOn !== 'NaN' ? withoutYDTotalAlongWithAddOn : '0.00';

  return (
    <Fragment>
      <div className="plan_box_img">
        {fandfStatus && count > 0 && (
          <p className="addon">
            Friends & Family <br />
            Portal Add-On
          </p>
        )}
        {SubscriptionType && (
          <div className="discount">
            Save <br />
            {plan?.yearlyDiscount}%
          </div>
        )}
        {/* {plan?.planName === "Base" && (!fandfStatus || count === 0) && (
          <div className="discount">FREE</div>
        )} */}

        <img
          alt={plan?.planName}
          src={plan?.image}
          width="40px"
          height={"50px"}
        />
        <h3>
          <del>
            {SubscriptionType && (
              <>
                {/* {getSymbolFromCurrency(currency)} */}
                {selectedCurrency?.symbol}
                {withoutYDTotalAlongWithAddOn}
              </>
            )}
          </del>
          <b>
            <sup>
              {selectedCurrency?.symbol}
            </sup>
            {totalAlongWithAddOn}
            <sub>/mo</sub>
          </b>
          <p className="plan_box_yearly_text">
            {SubscriptionType && (
              <>billed yearly</>
            )}
          </p>
        </h3>
        <h2>{plan?.planName}</h2>
      </div>
    </Fragment>
  );
}
