import { useSelector } from "react-redux";
import SidebarItem from "./Sidebar-Item";

const SidebarList = (props) => {
  const { list1, list2, list3 } = props;
  const { currentPlanName, accessRoles } = useSelector((state) => state.caresiteDataReducer);
  const isPlanAdvance = currentPlanName === 'Advanced';
  const isPlanTurbo = currentPlanName === 'Turbo';

  return (
    <div className="admin-scroll-sidebar">
      <nav className="admin-sidebar-nav">
        <ul id="sidebarnav">
          {list1.map((item) => {
            const moduleName = item.value?.toLowerCase();
            const findCurrentModule = accessRoles?.find(elem => (elem.moduleName?.toLowerCase() === moduleName));
            const isModuleAllowed = (findCurrentModule?.access === 'full' || findCurrentModule?.access === 'view');
            return (<SidebarItem
              key={item.value}
              links={item.link}
              isAccess={isModuleAllowed}
              msgToShow={isModuleAllowed ? '' : 'Your user Role does not have access to this section'}
              currentPlanName={currentPlanName}
              src={item.src}
              sidebarName={item.label}
            />)
          })}
          <br />
          {list2?.map((item) => {
            const moduleName = item.value?.toLowerCase();
            const findCurrentModule = accessRoles?.find(elem => (elem.moduleName?.toLowerCase() === moduleName));
            const isModuleAllowed = (findCurrentModule?.access === 'full' || findCurrentModule?.access === 'view');
            return (<SidebarItem
              key={item.value}
              links={item.link}
              msgCount={props.msgCount}
              isAccess={((isPlanAdvance || isPlanTurbo) && isModuleAllowed)}
              msgToShow={isModuleAllowed ? '' : 'Your user Role does not have access to this section'}
              currentPlanName={currentPlanName}
              src={item.src}
              sidebarName={item.label}
            />)
          }
          )}
          <br />
          {list3?.map((item) => {
            const moduleName = item.value?.toLowerCase();
            const findCurrentModule = accessRoles?.find(elem => (elem.moduleName?.toLowerCase() === moduleName));
            const isModuleAllowed = (findCurrentModule?.access === 'full' || findCurrentModule?.access === 'view');
            return (<SidebarItem
              key={item.value}
              links={item.link}
              msgCount={props.msgCount}
              isAccess={(isPlanTurbo && isModuleAllowed)}
              msgToShow={isModuleAllowed ? '' : 'Your user Role does not have access to this section'}
              currentPlanName={currentPlanName}
              src={item.src}
              sidebarName={item.label}
            />)
          }
          )}
        </ul>
      </nav>
    </div>
  );
};

export default SidebarList;
