import React, { useState } from "react";
import "../Clients/clients.css";
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { IoIosArrowForward } from "react-icons/io";
import { Dropdown, DropdownButton } from "react-bootstrap";
import swal from "sweetalert";
import { httpAdmin } from "../../Apis/commonApis";
import { BsSearch } from "react-icons/bs";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/system";

const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-cell:focus": {
    outline: "none",
  },
}));

const headCells = [
  {
    id: 'image',
    numeric: false,
    disablePadding: true,
    label: '',
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "location",
    numeric: false,
    disablePadding: false,
    label: "Location",
  },
  {
    id: "allergies",
    numeric: false,
    disablePadding: false,
    label: "Allergies",
  },
  {
    id: "conditions",
    numeric: false,
    disablePadding: false,
    label: "Conditions",
  },
  {
    id: "MedicinesName",
    numeric: false,
    disablePadding: false,
    label: "Medicine Name",
  },
  {
    id: "prn",
    numeric: false,
    disablePadding: false,
    label: "PRN Status",
  },
  {
    id: "InStock",
    numeric: false,
    disablePadding: false,
    label: "In Stock",
  },
  {
    id: "StockLevel",
    numeric: false,
    disablePadding: false,
    label: "Stock Level",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: 'action',
    numeric: true,
    disablePadding: false,
    label: '',
  },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function MedicineTable(props) {

  let { rows, handleShowOrHide, dmdUpdationDate } = props;

  // console.log(rows, 'rows')

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchText, setSearchText] = useState("");

  function handleRowClick(id) {
    props.medicinedetails(true, id);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const deleteMedicine = async (body) => {
    const result = await httpAdmin("deleteMedicines", 'post', { medicineArray: body }, null);
    if (result.status) {
      props.getClientRowData();
    }
  };

  const editMedicine = async (body) => {
    const result = await httpAdmin("markMedicinesStatus", 'post', { medicineArray: body }, null);
    if (result.status) {
      props.getClientRowData();
    }
  };

  function handleEditDeleteMedicine(action) {
    if (selected.length > 0) {
      let data = [];
      rows.forEach(item => {
        selected.forEach(value => {
          if (item.medication?._id === value) {
            data.push({
              careHomeId: item.careHomeId,
              clientId: item.cid,
              customMedicineId: item.custumMedId,
              medicineName: item.medication.NM,
              status: item.status === 'active' ? 'inactive' : 'active'
            });
          }
        });
      });
      swal({
        title: 'Are you sure??',
        text: 'Click below to procced for Activate Or Deactivate the Status',
        buttons: {
          cancel: 'Cancel',
          succeed: 'Yes',
        },
      }).then((result) => {
        if (result) {
          if (action === 'status') {
            editMedicine(data)
          } else {
            deleteMedicine(data)
          }
        }
      });
    } else
      swal(
        "Failed",
        "Please Select checkbox to proceed",
        "error"
      ).then((value) => {
        return;
      });
  }

  return (
    <>
      <div className="row justify-content-between">
        <div className="col-md-2 my-2" style={{ fontSize: '13px' }}>
          <span className="default_color">Dmd data Updated On : <br />
          </span> {dmdUpdationDate ? dmdUpdationDate?.split('T')[0] : 'Not Available'}
        </div>
        <div className="col-md-5 searchForm my-2">
          <input
            type="text"
            className="form-control"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            placeholder="search for client"
          />
          <BsSearch className="icon me-2" />
        </div>
        {/* <InvoiceActions selectedData={selectedData} getLatestInvioceData={getLatestInvioceData} /> */}
        <div className="col-md-2 btns_head my-2">
          <DropdownButton
            id="dropdown-basic-button"
            title="Actions"
          >
            <Dropdown.Item onClick={handleShowOrHide}> Add </Dropdown.Item>
            <Dropdown.Item onClick={() => { handleEditDeleteMedicine('delete') }}> Delete </Dropdown.Item>
            <Dropdown.Item onClick={() => { handleEditDeleteMedicine('status') }}>Change Status{" "}</Dropdown.Item>
          </DropdownButton>
        </div>
      </div>

      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2, boxShadow: "none" }}>
          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length} />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  ?.filter((item) => {
                    const searchTerm = searchText.toLowerCase();
                    const fullName = item?.name?.toLowerCase();
                    return fullName?.startsWith(searchTerm);
                  })
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            onClick={(event) => handleClick(event, row.id)}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }} />
                        </TableCell>
                        <TableCell>
                          <img
                            src={row.image ? row.image : "blank"}
                            className="table_image"
                            alt="user" />
                        </TableCell>
                        <TableCell padding="none" sx={{ minWidth: '110px' }}> {row.name} </TableCell>
                        <TableCell padding="none" sx={{ minWidth: '190px' }}>
                          <Tooltip title={row.location} placement="top">
                            <span>{row.location.slice(0, 20)}..</span>
                          </Tooltip></TableCell>
                        <TableCell padding="none">{row.allergies}</TableCell>
                        <TableCell padding="none">{row.conditions}</TableCell>
                        <TableCell padding="none" sx={{ minWidth: '180px' }}>{row.medication?.medName?.split('(')[0]}</TableCell>
                        {/* <TableCell>{row.expDate}</TableCell> */}
                        <TableCell padding="none">
                          {/* <button type="button" className="btn btn-sm btn-primary table_btn_font">
                            {row.medication?.prn === 'Yes' ? 'PRN' : ''}
                          </button> */}
                          {row.medication?.prn === 'Yes' && <div className="btnprn">
                            <button className="btnprn1">PRN</button>
                          </div>}
                        </TableCell>
                        <TableCell padding="none">{row.inStock}</TableCell>
                        <TableCell padding="none">
                          <button type="button" className="btn btn-sm btn-primary table_btn_font">
                            {row.stockLevel}Stock
                          </button>
                        </TableCell>
                        <TableCell padding="none">
                          <button type="button" className={`btn btn-sm table_btn_font ${row.isActive ? 'btn-success' : 'btn-danger'}`}>
                            {row.isActive ? 'Active' : 'Inactive'}
                          </button>
                        </TableCell>
                        <TableCell padding="none" sx={{ fontSize: '20px', cursor: 'pointer' }}
                          onClick={() => handleRowClick(row.cid)}>
                          <IoIosArrowForward />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage} />
        </Paper>
      </Box>
    </>
  );
}
