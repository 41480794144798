import React, {
  useState,
  useEffect,
} from "react";
import { Switch, Route, NavLink, Link, useRouteMatch, useLocation, useHistory } from "react-router-dom";
import "../SalesFunnel/SalesFunnel.css";
import DatePicker from "react-datepicker";
import { getMonthAgoDate } from "../../utils/CommonFunctions";
import SalesRoutes from "./SalesRoutes";

let monthAgoDate = getMonthAgoDate();

let today = new Date();

function SalesFunnel() {

  const history = useHistory();
  const location = useLocation();
  let { path, url } = useRouteMatch();

  useEffect(() => {
    console.log(location.pathname, 'lllllllllll')
    let pathname = window?.location?.pathname;
    if (pathname?.includes('leads') || pathname?.includes('overview')) {
      // console.log(window.location.pathname, 'jjjjjjjjjjjjjj')
    } else {
      handleNavigate('overview');
    }
  }, [location.pathname]);

  const handleNavigate = (type) => {
    history.replace({
      pathname: `${url}/${type}`,
    });
  };

  const [filterType, setFilterType] = useState("week");

  const [dateRange, setDateRange] = useState([monthAgoDate, new Date()]);
  const [startDate, endDate] = dateRange;

  function customDateChangeHandler(update) {
    setDateRange(update);
  }

  return (
    <>
      <div className="page-wrapper">
        <div className="container-fluid min_height">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6 ">
                  <ul
                    className="nav nav-tabs ract_tab_list border-0 listspaceGap"
                    id="myTab"
                    role="tablist"
                  >
                    <li className="nav-item me-3" role="presentation">
                      <p
                        onClick={() => handleNavigate('overview')}
                        className={`nav-link curser_point ${window.location.href.includes("overview") ? "active" : ""
                          }`}
                      >
                        Sales Overview
                      </p>
                    </li>

                    <li className="nav-item" role="presentation">
                      <p
                        onClick={() => handleNavigate('leads')}
                        className={`nav-link curser_point ${window.location.href.includes("leads") ? "active" : ""
                          }`}
                      >
                        Leads Management
                      </p>
                    </li>

                  </ul>
                </div>
                {window.location.href.includes("overview") && <div className="col-md-6">
                  <div className="col-md-12 dateFilter_sales">
                    <div className="">
                      <ul className="filter_box">
                        <NavLink
                          activeClassName={
                            filterType === "week" ? "active mx-3" : "mx-3"
                          }
                          to="#"
                          onClick={() => setFilterType("week")}
                        >
                          This Week
                        </NavLink>
                        <NavLink
                          activeClassName={
                            filterType === "custom" ? "active" : ""
                          }
                          to="#"
                          onClick={() => setFilterType("custom")}
                        >
                          Custom
                        </NavLink>
                      </ul>
                    </div>
                    {filterType === "custom" ? (
                      <div className="input_space">
                        <DatePicker
                          selectsRange={true}
                          dateFormat="dd/MM/yyyy"
                          startDate={startDate}
                          endDate={endDate}
                          maxDate={today}
                          onChange={(update) => {
                            customDateChangeHandler(update);
                          }}
                          isClearable={true}
                        />
                      </div>
                    ) : (
                      <div className="input_space">&ensp;</div>
                    )}
                  </div>
                </div>}
              </div>
              <div className="tab-content mt-3" id="myTabContent">
                <Switch>
                  <Route path={`${path}/:topicId`}>
                    <SalesRoutes
                      filterType={filterType}
                      dateRange={dateRange} />
                  </Route>
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SalesFunnel;
