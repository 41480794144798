import React from "react";
import { NavLink } from "react-router-dom";
import "./Footer.css";

function Footer() {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  return (
    <footer className="main_footer">
      <div className="container">
        <div className="row">
          <div className=" col-lg-9 col-md-8 footer-head">
            <h4>
              Ready to unleash your care <br />
              services' full potential?
            </h4>

            <div>
              <NavLink to="/plans" className="btn">
                Get Started
              </NavLink>
            </div>
          </div>
          <div className="col-lg-3 col-md-4">
            <div className="footerPotential_img" >
              <img src="/images/tech-award.svg" alt="img" />
            </div>
          </div>
        </div>
        <hr />
        <div className="col-md-12 text-center logo_footer">
          <NavLink to="">
            {" "}
            <img
              alt=""
              src={`${process.env.PUBLIC_URL}/images/Newfooter_Logo.svg`}
            />
          </NavLink>
        </div>
        <ul className="link_font_family">
          <li>
            <NavLink exact to="/">
              Home
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/about">
              About
            </NavLink>
          </li>
          <li>
            <NavLink to="/segments">Segments</NavLink>
          </li>
          <li>
            <NavLink to="/features">Features</NavLink>
          </li>
          <li>
            <NavLink to="/plans">Plans</NavLink>
          </li>
          <li>
            <NavLink to="/contact">Contact Us</NavLink>
          </li>
          <li>
            {/* <NavLink to="/blogs/">Blog</NavLink> */}
            <a href="https://caremagnus.com/blogs/">Blog</a>
          </li>
          <li>
            <NavLink to="/login">Log-In</NavLink>
          </li>
        </ul>
        <div className="copyright">
          <a href="https://caremagnus.com/blogs/terms-of-use">Terms of Use</a> <span>|</span>{" "}
          <a href="https://caremagnus.com/blogs/privacy-policy">Privacy Policy</a> <span>|</span>{" "}
          <a href="https://caremagnus.com/blogs/cookie-policy">Cookie Policy</a> <span>|</span>
          © 2021 - {currentYear} Auriga Magnus Care Limited. All Rights Reserved.
        </div>
      </div>
    </footer>
  );
}

export default Footer;
