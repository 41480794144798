import React, { Fragment, useEffect, useState } from "react";
import { alpha, styled } from "@mui/material/styles";
import { deepPurple } from "@mui/material/colors";
import Switch from "@mui/material/Switch";
import SelectSmall from "./CurrencySelect";
import axios from "axios";
import PlanSection from "./PlanSection";
import "./plans.css";
import { NavLink } from "react-router-dom";

const GreenSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: deepPurple[600],
    "&:hover": {
      backgroundColor: alpha(
        deepPurple[600],
        theme.palette.action.hoverOpacity
      ),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: deepPurple[600],
  },
}));

const label = { inputProps: { "aria-label": "Color switch demo" } };

export default function WebsitePlan() {
  const [planData, setPlansData] = useState([]);
  const [state, setState] = React.useState({
    monthlyearly: false,
  });
  const [bold, setBold] = useState(false);

  const handleChange = (event) => {
    setBold(event.target.checked);
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  useEffect(() => {
    getPlans();
  }, []);

  const getPlans = () => {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_SUPERADMIN_BASEURL}/getAllPlans`,
    };

    axios(config)
      .then(function (response) {
        setPlansData(response.data.result);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <Fragment>
      <section className="mt-5 pagespace_section resp_pagespace_section">
        <div className="container">
          <div className="title">
            <h3>Quality Uncompromised. Value Unmatched. </h3>
          </div>
        </div>
      </section>
      <section className="plan_section resp_plan_section">
        <div className="container">
          <div className="title text-center">
            <h3>Choose your plan</h3>
            <div className="plan_select" style={{ paddingLeft: "170px" }}>
              <span
                className="p1"
                style={{ fontWeight: !bold ? "bold" : "normal" }}
              >
                Monthly
              </span>
              <GreenSwitch
                {...label}
                checked={state.monthlyearly}
                onChange={handleChange}
                name="monthlyearly"
              />
              <span
                className="p1"
                style={{ fontWeight: bold ? "bold" : "normal" }}
              >
                Yearly
              </span>
              <span className="present">Save on yearly plans </span>
            </div>
          </div>

          <div className="title text-end mt-3">
            <div className="plan_select">
              <SelectSmall fromPage='website' />
            </div>
          </div>

          <div className="row plan_row">
            {planData?.map((plan, index) => {
              return (
                <PlanSection
                  key={index}
                  planDetails={plan}
                  SubscriptionType={state.monthlyearly}
                />
              );
            })}

            <div className="col-md-12" style={{padding: "0px 8px"}}>
              <div className="subscribe_priceBox">
                <div className="row align-items-center">
                  <div className="col-md-1"></div>
                  <div className="col-md-1">
                    <div className="img_box">
                      <img src="/images/Boy-Rocket.svg" alt="boy" />
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="content_box">
                      <h4>Early-Stage Subscription Price</h4>
                      <p>
                        Embark on excellence! Care Sites managing 15 clients or
                        fewer, can avail our easy entry <span>Early-Stage Subscription
                        Price.</span> Please contact us to discuss and unleash the
                        power of our cutting-edge platform for your care service
                        success.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="contact_btn">
                      <NavLink to="/contact" className="btn">Contact Us</NavLink>
                    </div>
                  </div>
                  <div className="col-md-1"></div>
                </div>
              </div>
            </div>
            <div className="col-md-12 note_box">
              <p>Notes</p>
              <ul className="plans_end_notes">
                <li>All the prices above are excluding taxes.</li>
                <li>
                  30-Days free trial is included in All Plans. This is a one-off
                  and will apply automatically only to the first plan you trial.
                  You can cancel before trial end date at no charge.
                </li>
                {/* <li>
                  All discounts are applicable on the plan price
                  only and do not include the Friends&Family App Add-On.
                </li> */}
                <li>
                  All Plan subscription licenses are based on a single site or
                  one (1) site license only. For each additional site please
                  subscriber for an additional license. A site could be one (1)
                  Nursing Home or Residential Care Home or Retirement Home or
                  Assisted Living Facility or Retirement Community or Home-Care
                  Agency.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}
