import { Component } from "react";
import { exportToExcel } from "react-json-to-excel";

import { HiDownload } from "react-icons/hi";

export default class JsonDownload extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <HiDownload
        className="curser_point"
        onClick={() =>
          exportToExcel(this.props.downloadfile, this.props.fileName)
        }
      />
    );
  }
}
