import React, { useEffect, useState } from "react";
import cx from "../Portal.module.css";

import { Row, Col, NavLink, Button } from "react-bootstrap";
import { httpPortal } from "../../Apis/commonApis";
import { useForm } from 'react-hook-form';
import { useHistory } from "react-router-dom";
export default function PortalLogin() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const history = useHistory();
  const [isChecked, setIsChecked] = useState(false);
  const [error, setError] = useState("")
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const signin = async (postdata) => {
    if (isChecked) {
      var data = JSON.stringify({
        email: postdata.email,
      });
      const res = await httpPortal('login', 'post', data, 'withoutToken');
      if (res?.message == "Please set your password and security questions first.") {
        history.push({
          pathname: `/portal/createPortalPassword`,
          state: { email: postdata?.email },
        });
      } else if (res.status) {
        // localStorage.setItem("fandfData", JSON.stringify(res.data));
        // localStorage.setItem("fandfam_token", res.token);
        history.push({
          pathname: `/portal/password`,
          state: { email: postdata?.email },
        });
      } else {
        setError(res?.message.includes('not registered') ? 'It seems that this email is either not correct or not yet registered' : res?.message)
      }
    } else {
      setError("Please select checkbox")
    }
  }
  return (
    <div className={`${cx.LoginStart}`}>
      <div className={`container`}>
        <Row>
          <Col lg={4} md={6} sm={12} className={`m-auto`}>
            <div className={`${cx.LoginBox}`}>
              <img src="/images/Newfooter_Logo.svg" alt="" />
              <h3>Welcome,</h3>
              <p>Please enter your email address. </p>
              <div className={`${cx.formInput}`}>
                <div className={`${cx.inputEmail}`}>
                  <label>Email</label>
                  <input className={`form-control`} type="email" placeholder="jhon@gmail.com"  {...register("email", {
                    required: true,
                  })}
                  />
                </div>
                {errors?.email?.type === "required" && (
                  <p className="error_style">This field is required</p>
                )}

                <div className={`form-check`}>
                  <input className={`form-check-input`} type="checkbox" value="" id="flexCheckDefault" onChange={handleCheckboxChange} />
                  <label className={`form-check-label`} for="flexCheckDefault">
                    We follow GDPR security requirements. Please read and accept our privacy notice and terms of use before sign-in.
                    <a href="https://caremagnus.com/blogs/privacy-policy" className="seeDetails">
                      Privacy Policy
                    </a> &nbsp; & &nbsp; 
                    <a href="https://caremagnus.com/blogs/blogs/app-terms-of-use" className="seeDetails">
                      Terms of Use{" "}
                    </a>{" "}
                    {/* <NavLink to="#"> Privacy Notice </NavLink> & <NavLink to="#"> Terms of Use</NavLink>. */}
                  </label>
                </div>
                <p className="error_style">{error}</p>
              </div>
              <div className={`${cx.continueButton}`}>
                <NavLink to="#" onClick={handleSubmit(signin)}>Continue</NavLink>
              </div>
              {/* <p>It seems that this email is either not correct or not yet registered to use the Friends & Family App. Kindly recheck email or contact your care service provider to activate your profile.</p> */}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}
