import React, { useEffect, useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { httpAdmin } from "../../Apis/commonApis";
import Modal from "react-bootstrap/Modal";
import swal from "sweetalert";
import { currentCareSiteId } from "../../redux-toolkit/reducer/switchSites";
import "../SalesFunnel/SalesFunnel.css";
import { AiOutlineClose } from "react-icons/ai";
import { ShowSwal } from "../../utils/alertSwal";
import AwsLocation from "../../utils/AwsLocation";
import { AiFillPrinter } from "react-icons/ai";
import JsonDownload from "../../components/DownloadExcel/DownloadExcel";
import { getFullName, handleDataPrint } from "../../utils/CommonFunctions";

export default function LeadsActions(props) {
  const { leadData, leadsId, applyDateFilter, rowData, leadAccess } = props;

  let initialVals = {
    longitude: 0,
    lattitude: 0,
    address: '',
    first_Name: "",
    last_Name: "",
    referral: "",
    typeOfPlan: "",
    ratePerWeek: "",
    salesPerson: "",
    salesPersonEmail: "",
    leadsStatus: "earlyStage",
    // clientType: "",
  };
  const [leadsFormData, setLeadsFormData] = useState(initialVals);
  const [actionType, setActionType] = useState("create");
  const [showModal, setShowModal] = useState(false);
  const [locationText, setLocationText] = useState(null);

  const createLead = async () => {
    const res = await httpAdmin("createLeads", 'post', leadsFormData, null);
    if (res.status) {
      applyDateFilter();
      closeModal();
    }
    ShowSwal(res.status, res.message);
  };

  const editLead = async () => {
    const res = await httpAdmin("editLeads", 'post', leadsFormData, null);
    if (res.status) {
      applyDateFilter();
      closeModal();
    }
    ShowSwal(res.status, res.message);
  };

  const changeLeadStatus = async () => {
    let statusData = {
      leadId: leadsId,
      leadsStatus: leadsFormData.leadsStatus,
    };
    const res = await httpAdmin("markLeadsStatus", 'post', statusData, null);
    if (res.status) {
      applyDateFilter();
      closeModal();
    }
    ShowSwal(res.status, res.message);
  };

  const deleteLeads = async () => {
    const res = await httpAdmin("deleteClient", 'post', { clientId: leadsId }, null);
    if (res.status) {
      applyDateFilter();
    }
    ShowSwal(res.status, res.message);
  };

  const makeLeadToClient = async () => {
    const res = await httpAdmin("makeClient", 'post', { leadId: leadsId }, null);
    if (res.status) {
      applyDateFilter();
    }
  };

  function awsLocationHandler(locVal) {
    if (locVal) {
      setLeadsFormData((prevState) => {
        return {
          ...prevState,
          address: locVal?.Label,
          longitude: locVal?.latitude,
          lattitude: locVal?.longitude,
        };
      });
    }
    // setlocation(locVal);
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (actionType === "Create") {
      createLead();
    } else if (actionType === "Edit") {
      editLead();
    } else {
      changeLeadStatus();
    }
  }

  const openModal = (type) => {
    setLeadsFormData(initialVals);
    setActionType(type);
    if (type === "Create") {
      let user = JSON.parse(localStorage.getItem("userData"));
      setLeadsFormData((prevState) => {
        return { ...prevState, careHomeId: currentCareSiteId };
      });
      setShowModal(true);
    } else if (type === "Edit") {
      if (leadsId.length === 0) {
        swal("Error!", "Please Select To Edit", "error");
      } else if (leadsId.length > 1) {
        swal("Error!", "Please Select Only One To Edit", "error");
      } else {
        leadData.data.forEach((elem) => {
          if (elem._id === leadsId[0]) {
            setLocationText(elem.address);
            setLeadsFormData({
              leadId: leadsId[0],
              lattitude: elem.loc.coordinates[0],
              longitude: elem.loc.coordinates[1],
              address: elem.address,
              first_Name: elem.first_Name,
              last_Name: elem.last_Name,
              referral: elem.referral,
              typeOfPlan: elem.clientPlanType,
              ratePerWeek: elem.invoiceRate,
              salesPerson: elem.salesPerson,
              salesPersonEmail: elem.salesPersonEmail,
              leadsStatus: elem.leadsStatus,
              // clientType: elem.clientType,
            });
          }
        });
        setShowModal(true);
      }
    } else {
      if (leadsId.length < 1) {
        swal(
          "Error!",
          "Please Select To Atleast One To Change Status",
          "error"
        );
      } else if (type === "delete") {
        swal({
          title: "Are you sure??",
          text: 'Click below to procced',
          buttons: {
            cancel: "Cancel",
            succeed: "Yes",
          },
        }).then((result) => {
          if (result) {
            deleteLeads();
          } else {
            console.log("rejected");
          }
        });
      } else if (type === "addtoclient") {
        swal({
          title: "Are you sure??",
          text: 'Click below to procced',
          buttons: {
            cancel: "Cancel",
            succeed: "Yes",
          },
        }).then((result) => {
          if (result) {
            makeLeadToClient();
          } else {
            console.log("rejected");
          }
        });
      } else {
        setShowModal(true);
      }
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const downloadData = rowData?.map(item => {
    return {
      ["ProspectName"]: getFullName(item),
      //  `${item.first_Name} ${item.last_Name}`,
      ["Referral"]: item.referral,
      ["Type of Plan"]: item.clientPlanType,
      ["Rate per Week"]: item.invoiceRate,
      ["Contact Name"]: item.keycontact?.map(item => item.name)?.join(', '),
      ["Tel"]: item.keycontact?.map(item => item.phone)?.join(', '),
      ["Email"]: item.keycontact?.map(item => item.email)?.join(', '),
      ["Sales Person"]: item.salesPerson,
      ["Client Add Date"]: item.addedTimestamp,
      ["Status"]: item.leadsStatus,
    }
  })

  return (
    <>
      <div className="row align-items-center mt-3 pt-2">
        <div className="row col-sm-12 col-md-6 col-lg-6 m-auto" style={{ transform: "translateX(-40px)" }}>
          <div className="col-11 d-flex justify-content-between">
            <div className=" sechudleCard" style={{ color: "#828282" }}>
              <p>Early Stage</p>
              <h5>{leadData?.earlyStage}</h5>
            </div>
            <div className=" sechudleCard" style={{ color: "#2D9CDB" }}>
              <p>Qualified</p>
              <h5>{leadData?.qualified}</h5>
            </div>
            <div className=" sechudleCard" style={{ color: "#27AE60" }}>
              <p>Success</p>
              <h5>{leadData?.success}</h5>
            </div>
            <div className=" sechudleCard" style={{ color: "#EB5757" }}>
              <p>Case Closed</p>
              <h5>{leadData?.caseClosed}</h5>
            </div>
            <div className=" sechudleCard" style={{ color: "#F2994A" }}>
              <p>Added-as-Client</p>
              <h5>{leadData?.addToClient}</h5>
            </div>
          </div>
        </div>

        <div className="col-md-12 d-flex justify-content-between ">
          <div>
            <AiFillPrinter
              className="me-3 ms-1 curser_point"
              onClick={() => handleDataPrint(downloadData)}
            />
            <a className="curser_point">
              <JsonDownload
                fileName="leads-excel"
                downloadfile={downloadData}
              />
            </a>
          </div>
          <div className="btns_head my-1">
            <DropdownButton id="dropdown-basic-button" title="Actions">
              <Dropdown.Item
                disabled={leadAccess !== 'full'}
                onClick={() => openModal("Create")}>
                Create New Lead
              </Dropdown.Item>
              <Dropdown.Item
                disabled={leadAccess !== 'full'}
                onClick={() => openModal("Edit")}>
                Edit
              </Dropdown.Item>
              <Dropdown.Item
                disabled={leadAccess !== 'full'}
                onClick={() => openModal("Change Status")}>
                {" "}
                Change Status{" "}
              </Dropdown.Item>
              <Dropdown.Item
                disabled={leadAccess !== 'full'}
                onClick={() => openModal("delete")}>
                {" "}
                Delete{" "}
              </Dropdown.Item>
              <Dropdown.Item
                disabled={leadAccess !== 'full'}
                onClick={() => openModal("addtoclient")}>
                {" "}
                Add to Clients Section
              </Dropdown.Item>
            </DropdownButton>
          </div>

        </div>
      </div >

      <Modal className="viewModal" show={showModal} onHide={closeModal}>
        <Modal.Header>
          <Modal.Title>
            <span>{actionType}</span>
            <span>
              <AiOutlineClose
                className="curser_point"
                onClick={closeModal}
              />
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "10px 20px" }}>
          {actionType !== "Change Status" ? (
            <form>
              <div className="row">
                <div className="my-1 col-md-6">
                  <label className={`form-label m-0`}>First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={leadsFormData.first_Name}
                    onChange={(e) =>
                      setLeadsFormData((prevState) => {
                        return { ...prevState, first_Name: e.target.value };
                      })
                    }
                  />
                </div>

                <div className="my-1 col-md-6">
                  <label className={`form-label m-0`}>Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={leadsFormData.last_Name}
                    onChange={(e) =>
                      setLeadsFormData((prevState) => {
                        return { ...prevState, last_Name: e.target.value };
                      })
                    }
                  />
                </div>

                <div className="my-1 col-md-6">
                  <label className={`form-label m-0`}>Plan Type</label>
                  <select
                    className="form-select"
                    defaultValue={leadsFormData.typeOfPlan}
                    onChange={(e) =>
                      setLeadsFormData((prevState) => {
                        return { ...prevState, typeOfPlan: e.target.value };
                      })
                    }
                  >
                    <option value="">--Select--</option>
                    <option value="Council">Council</option>
                    <option value="Self-Funded">Private</option>
                  </select>
                </div>
                <div className="my-1 col-md-6">
                  <label className={`form-label m-0`}>Rate Per Week</label>
                  <input
                    type="number"
                    className="form-control"
                    value={leadsFormData.ratePerWeek}
                    onChange={(e) =>
                      setLeadsFormData((prevState) => {
                        return { ...prevState, ratePerWeek: e.target.value };
                      })
                    }
                  />
                </div>
                <div className="mt-1 col-md-12 mb-2">
                  <label className="form-label m-0">Location</label>
                  <AwsLocation awsLocationHandler={awsLocationHandler}
                    defaultVal={locationText}
                  />
                </div>
                <div className="mb-1 col-md-6">
                  <label className={`form-label m-0`}>Sales Person Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={leadsFormData.salesPerson}
                    onChange={(e) =>
                      setLeadsFormData((prevState) => {
                        return { ...prevState, salesPerson: e.target.value };
                      })
                    }
                  />
                </div>
                <div className="mb-1 col-md-6">
                  <label className={`form-label m-0`}>Sales Person Email</label>
                  <input
                    type="email"
                    className="form-control"
                    value={leadsFormData.salesPersonEmail}
                    onChange={(e) =>
                      setLeadsFormData((prevState) => {
                        return { ...prevState, salesPersonEmail: e.target.value };
                      })
                    }
                  />
                </div>
                {/* <div className="my-1 col-md-6">
                  <label className={`form-label m-0`}>Client Type</label>
                  <input
                    type="text"
                    className="form-control"
                    value={leadsFormData.clientType}
                    onChange={(e) =>
                      setLeadsFormData((prevState) => {
                        return { ...prevState, clientType: e.target.value };
                      })
                    }
                  />
                </div> */}
                <div className="my-1 col-md-6">
                  <label className={`form-label m-0`}>Referral</label>
                  <input
                    type="text"
                    className="form-control"
                    value={leadsFormData.referral}
                    onChange={(e) =>
                      setLeadsFormData((prevState) => {
                        return { ...prevState, referral: e.target.value };
                      })
                    }
                  />
                </div>
                <div className="my-1 col-md-6">
                  <label className={`form-label m-0`}>Leads Status</label>
                  <select
                    className="form-select"
                    defaultValue={leadsFormData.leadsStatus}
                    onChange={(e) =>
                      setLeadsFormData((prevState) => {
                        return { ...prevState, leadsStatus: e.target.value };
                      })
                    }
                  >
                    <option value="earlyStage"> Early Stage</option>
                    <option value="qualified"> Qualified</option>
                    <option value="success"> Success</option>
                    <option value="caseClosed"> Case Close</option>
                  </select>
                </div>
              </div>
            </form>
          ) : (
            <form>
              <div className="row">
                <div className="my-1 col-md-12">
                  <label className={`form-label m-0`}>Leads Status</label>
                  <select
                    className="form-select"
                    defaultValue={leadsFormData.leadsStatus}
                    onChange={(e) =>
                      setLeadsFormData((prevState) => {
                        return { ...prevState, leadsStatus: e.target.value };
                      })
                    }
                  >
                    <option value=""> Select Status</option>
                    <option value="earlyStage"> Early Stage</option>
                    <option value="qualified"> Qualified</option>
                    <option value="success"> Success</option>
                    <option value="caseClosed"> Case Close</option>
                  </select>
                </div>
              </div>
            </form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex">
            <button
              className="btn"
              type="button"
              onClick={(e) => handleSubmit(e)}
            >
              {actionType === 'Edit' ? 'Save' : actionType}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
