import Customtable from "../Customtable/Customtable";
// import SubscriptionPlans from "./SubscriptionPlans";

const GeneralPlans = () => {
  return (
    <>
      <div className="card">
        <div className="card-body" style={{height: '90vh'}}>
          {/* <SubscriptionPlans /> */}
          <Customtable />
        </div>
      </div>
    </>
  );
};

export default GeneralPlans;
