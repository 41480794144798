import React, { useEffect, useState } from "react";
import "../Profile/Profile.css";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { MdClose } from "react-icons/md";
import useInput from "../../hooks/use-input";
import { httpSuperAdmin } from "../../Apis/commonApis";
import swal from "sweetalert";
import { useForm } from "react-hook-form";
import {
	authenticateOwnerSignup,
	getOwnerIdAfterLogin,
	securitysuccessfullMsg,
} from "../../redux-toolkit/reducer/accountCreationApiReducer";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { ShowSwal } from "../../utils/alertSwal";

function Profile() {
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
		control,
	} = useForm();

	const userData = JSON.parse(localStorage.getItem("__csadmin__d"));
	const { loginVerifyEmail } = useSelector(
		(state) => state.accountCreationApiReducer
	);
	const isPasswordValidate = (value) => value.length >= 6;
	const isEmpty = (value) => value.trim() !== "";
	const [question, setQuestion] = useState("");
	const [show, setShow] = useState(false);
	const [popupStatus, setPopupStatus] = useState("");
	const [checkForOTP, setCheckForOTP] = useState(false);
	const [authenticatPassword, setAuthenticatePassword] = useState(false);
	const [email, setNewEmail] = useState("");
	const [otp, setOTP] = useState("");
	const [userDetail, setUserDetail] = useState(null);
	let [formData, setFormData] = useState({
		firstname: "",
		lastname: "",
		mobileNo: "",
		email: ""
	});
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleClose = () => {
		getProfileDetails();
		setCheckForOTP(false);
		setShow(false);
		setAuthenticatePassword(false);
	};

	const handleShow = (text) => {
		setShow(true);
		setPopupStatus(text);
	};
	// ----------------------------  Security Questions   -------------------------- //

	function selectQuestion(event) {
		setQuestion(event.label);
	}

	const {
		value: passwordValue,
		isValid: passwordIsValid,
		hasError: passwordHasError,
		valueChangeHandler: passwordChangeHandler,
		inputBlurHandler: passwordBlurHandler,
	} = useInput(isPasswordValidate);
	const {
		value: confirmPasswordValue,
		isValid: confirmPasswordIsValid,
		hasError: confirmPasswordHasError,
		valueChangeHandler: confirmPasswordChangeHandler,
		inputBlurHandler: confirmPasswordBlurHandler,
	} = useInput(isPasswordValidate);
	const {
		value: securityAnswerValue,
		isValid: securityAnswerIsValid,
		hasError: securityAnswerHasError,
		valueChangeHandler: securityAnswerChangeHandler,
		inputBlurHandler: securityAnswerBlurHandler,
	} = useInput(isEmpty);

	const getProfileDetails = async () => {

		const axios = require("axios");

		let config = {
			method: "get",
			maxBodyLength: Infinity,
			url: `${process.env.REACT_APP_SUPERADMIN_BASEURL}/getSuperadminUsers?id=${userData?._id}`,
			headers: {
				Authorization: localStorage.getItem("superadmin_token"),
			},
		};
		axios
			.request(config)
			.then((response) => {
				setUserDetail(response?.data?.data?.[0])
				userData.firstName = response?.data?.data?.[0]?.firstName;
				userData.lastName = response?.data?.data?.[0]?.lastName;
				userData.mobileNo = response?.data?.data?.[0]?.mobileNo;
				userData.email = response?.data?.data?.[0]?.email;
				localStorage.setItem("__csadmin__d", JSON.stringify(userData));
			})
			.catch((error) => {
				console.log(error);
			});
	};

	// useEffect(() => {
	// 	const interval = setInterval(() => {
	// 		if (formData?.firstName == "" || formData?.lastName == "" || formData?.mobileNo == "") {
	// 			getProfileDetails();
	// 		}
	// 	}, 500);
	// 	return () => clearInterval(interval);
	// }, [formData]);

	function verifyOTP() {
		var data = JSON.stringify({
			userId: userData?._id,
			otp: otp,
			newEmail: email,
		});

		var config = {
			method: "post",
			url: `${process.env.REACT_APP_SUPERADMIN_BASEURL}/verifyOtp`,
			headers: {
				"Content-Type": "application/json",
				Authorization: localStorage.getItem("superadmin_token"),
			},
			data: data,
		};

		axios(config)
			.then(function (response) {
				getProfileDetails();
				if (response?.data?.status) {
					swal("Success", response?.data?.message, "success");
					handleClose();
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	const [sendEmail, setSendEmail] = useState(false);

	function OwnerSignupID() {
		const axios = require("axios");

		let config = {
			method: "get",
			maxBodyLength: Infinity,
			url: `https://caremagnus.com/API/admin/authenticateOwnerSignup?ownerId=${userData?._id}&authProcess=password`,
			headers: {},
		};

		axios
			.request(config)
			.then((response) => {
				if (response?.data?.status == true) {
					setSendEmail(false);
					swal("Success", response.data.message, "success").then((value) => {
						handleShow("reset_password");
						setAuthenticatePassword(true);
					});
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}

	useEffect(() => {
		const interval = setInterval(() => {
			if (sendEmail) {
				OwnerSignupID();
			}
		}, 5000);

		return () => clearInterval(interval);
	}, [sendEmail]);

	function changeEmailPassSctQty(changeType) {
		var data = JSON.stringify({
			userId: userData?._id,
			changeType: changeType,
			// userType: "superadmin",
		});

		var config = {
			method: "post",
			url: `${process.env.REACT_APP_SUPERADMIN_BASEURL}/reset_profile`,
			headers: {
				"Content-Type": "application/json",
				Authorization: localStorage.getItem("superadmin_token"),
			},
			data: data,
		};

		axios(config)
			.then(function (response) {
				if (response?.data?.status) {
					swal("Success", response?.data?.message, "success");
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	async function reset_password_securityQuestion(resetType) {
		const data = {};

		if (resetType === "securityQuestions") {
			if (!securityAnswerIsValid && question === "") {
				return;
			}
			data.resetType = resetType;
			data.question = question;
			data.answer = securityAnswerValue;
		} else {
			if (!passwordIsValid && !confirmPasswordIsValid) {
				return;
			}
			data.resetType = resetType;
			data.new_password = passwordValue;
			data.confirm_password = confirmPasswordValue;
		}
		const result = await httpSuperAdmin(
			`reset_password/${userData?._id}`,
			data
		);
		if (result.status) {
			handleClose();
			getProfileDetails(userData._id);
		}
		ShowSwal(result.status, result.message);
	}

	const updateProfileDetails = async (postData) => {
		const axios = require("axios");
		let data = JSON.stringify({
			userId: userData?._id,
			firstName: formData?.firstName == "" ? userData?.firstName : formData?.firstName,
			lastName: formData?.lastName == "" ? userData?.lastName : formData?.lastName,
			mobileNo: formData?.mobileNo == "" ? userData?.mobileNo : formData?.mobileNo,
			userType: "superadmin",
		});

		let config = {
			method: "post",
			maxBodyLength: Infinity,
			url: `${process.env.REACT_APP_SUPERADMIN_BASEURL}/editUserSuperadminPanel`,
			headers: {
				"Content-Type": "application/json",
				Authorization: localStorage.getItem("superadmin_token"),
			},
			data: data,
		};

		axios
			.request(config)
			.then((response) => {
				getProfileDetails();
				handleClose();
				setFormData({})
			})
			.catch((error) => {
				console.log(error);
			});
	};
	return (
		<>
			<div className="page-wrapper">
				<div className="container-fluid min_height">
					<div className="card">
						<div className="card-body">
							<div className="top_menubar">
								<ul
									className="nav nav-tabs ract_tab_list border-0"
									id="myTab"
									role="tablist"
								>
									<li className="nav-item" role="presentation">
										<button
											className="nav-link active"
											id="tabD-1"
											data-bs-toggle="tab"
											data-bs-target="#tab_1"
											type="button"
											role="tab"
											aria-controls="tab_1"
											aria-selected="true"
										>
											Profile
										</button>
									</li>
									{/* <li className="nav-item" role="presentation">
									<button className="nav-link" id="tabD-2" data-bs-toggle="tab" data-bs-target="#tab_2" type="button" role="tab" aria-controls="tab_2" aria-selected="false">Change Password</button>
								</li> */}
								</ul>
							</div>

							<div className="tab-content ract_tab_data" id="myTabContent">
								<div
									className="tab-pane fade show active"
									id="tab_1"
									role="tabpanel"
									aria-labelledby="tabD-1"
								>
									<div className="row">
										<div className="col-md-6">
											<div className="mb-3">
												<div className="profile_img">
													<input type="file" />
													<img
														src={`${process.env.PUBLIC_URL}/images/profle.jpg`}
														alt="user"
													/>
												</div>
											</div>
											<div className="mb-3">
												<label className="form-label">Name</label>
												<input
													type="text"
													className="form-control"
													value={`${userData?.firstName} ${userData?.lastName}`}
												/>
											</div>

											<div className="mb-3">
												<label className="form-label">Phone Number</label>
												<input
													type="number"
													className="form-control"
													value={userData?.mobileNo}
												/>
											</div>

											<p>
												<span onClick={() => handleShow("profile")} to="#">
													<Link to="#">Edit</Link>
												</span>
											</p>

											<div className="mb-3">
												<label className="form-label">Email</label>
												<input
													type="email"
													className="form-control"
													value={userData?.email}
												/>
												<p>
													<span onClick={() => handleShow("Email")} to="#">
														<Link to="#">Change</Link>
													</span>
												</p>
											</div>
											<div className="mb-3">
												<label className="form-label">Password</label>
												<input
													type="password"
													className="form-control"
													value="Password"
												/>
												<p>
													<span onClick={() => handleShow("reset_password")}>
														<Link to="#">Change</Link>
													</span>
												</p>
											</div>
											{/* <div className="mb-3">
												<button
													style={{ backgroundColor: "#bb6bd9" }}
													className="btn submit_btn"
												>
													Update Profile
												</button>
											</div> */}
										</div>
									</div>
								</div>

								{/* <div className="tab-pane fade" id="tab_2" role="tabpanel" aria-labelledby="tabD-2">
								<div className="row">
									<div className="col-md-6 margin-auto">
										<div className="mb-3">
											<label className="form-label">Old Password</label>
											<input type="password" className="form-control" value="" />
										</div>
										<div className="mb-3">
											<label className="form-label">New Password</label>
											<input type="password" className="form-control" value="" />
										</div>
										<div className="mb-3">
											<label className="form-label">Confirm Password</label>
											<input type="password" className="form-control" value="" />
										</div>
										<div className="mb-3">
											<button className="btn submit_btn">Change Password</button>
										</div>
									</div>
								</div>
							</div> */}
							</div>
						</div>
					</div>
				</div>
			</div>

			{popupStatus === "reset_password" && (
				<Modal className="viewModal" show={show}>
					<Modal.Header>
						<Modal.Title>
							<span>Reset Password</span>
							<button
								className="popupCloseIcon"
								title="Close"
								onClick={handleClose}
							>
								<MdClose />
							</button>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{popupStatus === "reset_password" && !authenticatPassword && (
							<form>
								<div className="row">
									<p>Authenticate yourself by clicking on the below button</p>
									{!checkForOTP && (
										<button
											className="btn btn-primary mb-3 w-25 mx-auto"
											type="button"
											onClick={() => {
												changeEmailPassSctQty("password");
												setCheckForOTP(true);
												setSendEmail(true);
											}}
										>
											Send Email{" "}
										</button>
									)}{" "}
								</div>
							</form>
						)}
						{authenticatPassword && (
							<form>
								<h4 className="mt-2">New Password</h4>
								<input
									type="password"
									id="password"
									value={passwordValue}
									onChange={passwordChangeHandler}
									onBlur={passwordBlurHandler}
									placeholder="Password"
								/>
								{passwordHasError && (
									<span className="input_error">
										Please enter at least 6 digits
									</span>
								)}

								<input
									type="password"
									id="confirmPassword"
									value={confirmPasswordValue}
									onChange={confirmPasswordChangeHandler}
									onBlur={confirmPasswordBlurHandler}
									placeholder="Confirm Password"
								/>
								{confirmPasswordHasError && (
									<span className="input_error">
										Please enter at least 6 digits
									</span>
								)}
								<button
									type="button"
									className="form-control mt-2 btn btn-primary  w-25 mb-2 d-block mx-auto "
									onClick={() => {
										reset_password_securityQuestion("password");
									}}
								>
									Save
								</button>
							</form>
						)}
					</Modal.Body>
				</Modal>
			)}

			{popupStatus === "profile" && (
				<Modal className="viewModal" show={show} onHide={handleClose}>
					<Modal.Header>
						<Modal.Title>
							<span>Update Profile</span>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<form>
							<div className="row">
								<div className="mb-3 col-md-6">
									<label className={`form-label`}>First Name</label>
									<input
										type="text"
										className="form-control"
										defaultValue={userData?.firstName}
										{...register("firstName", {
											required: true,
										})}
										onChange={handleInputChange}
									/>
									{errors?.firstName?.type === "required" && (
										<p className="error_style">This field is required</p>
									)}
								</div>
								<div className="mb-3 col-md-6">
									<label className={`form-label`}>Last Name</label>
									<input
										type="text"
										className="form-control"
										defaultValue={userData?.lastName}
										{...register("lastName", {
											required: true,
										})}
										onChange={handleInputChange}
									/>
									{errors?.lastName?.type === "required" && (
										<p className="error_style">This field is required</p>
									)}
								</div>
								<div className="mb-3 col-md-12">
									<label className={`form-label`}>Phone Number</label>
									<input
										type="number"
										className="form-control"
										defaultValue={userData?.mobileNo}
										{...register("mobileNo", {
											required: true,
										})}
										onChange={handleInputChange}
									/>
									{errors?.mobileNo?.type === "required" && (
										<p className="error_style">This field is required</p>
									)}
								</div>
							</div>
						</form>
					</Modal.Body>
					<Modal.Footer>
						<div className="d-flex ">
							<button className="btn submitBtn" onClick={handleClose}>
								cancel
							</button>
							<button
								className="btn submitBtn"
								onClick={handleSubmit(updateProfileDetails)}
							>
								update
							</button>
						</div>
					</Modal.Footer>
				</Modal>
			)}

			{popupStatus === "Email" && (
				<Modal className="viewModal" show={show} onHide={handleClose}>
					<Modal.Header>
						<Modal.Title>
							<span>Change Email Id</span>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<form>
							{popupStatus === "Email" && (
								<div className="row">
									<div className="mb-3 col-md-12">
										<label className={`form-label`}>New Email</label>
										<input
											type={"email"}
											className="form-control"
											value={email}
											onChange={(e) => setNewEmail(e.target.value)}
										/>
									</div>
									<label className={`form-label`}>OTP</label>
									<div className="mb-3 col-md-12 d-flex justify-content-between">
										<input
											type={"number"}
											className="form-control w-75"
											value={otp}
											onChange={(e) => setOTP(e.target.value)}
										/>
										{!checkForOTP && (
											<button
												className="btn btn-primary"
												type="button"
												onClick={() => {
													changeEmailPassSctQty("email");
													setCheckForOTP(true);
												}}
											>
												Send OTP{" "}
											</button>
										)}{" "}
									</div>
								</div>
							)}
						</form>
					</Modal.Body>
					<Modal.Footer>
						<div className="d-flex ">
							<button className="btn submitBtn" onClick={handleClose}>
								cancel
							</button>
							{checkForOTP && (
								<button
									className="btn submitBtn"
									onClick={() => {
										if (popupStatus === "Email") {
											verifyOTP();
										}
									}}
								>
									update
								</button>
							)}
						</div>
					</Modal.Footer>
				</Modal>
			)}
		</>
	);
}

export default Profile;
