import { httpPayments } from "../../Apis/commonApis";
import { currentCareSiteId } from "../../redux-toolkit/reducer/switchSites";

export const stripePaymentMethodHandler = async (data, cb) => {
  const { amount, result, cartDetails, countryData, adminData, newCareSiteData } = data;

  const subscriptionData = adminData?.subscriptionPlanData.find(item => item._id === currentCareSiteId);
  const csName = subscriptionData ? subscriptionData?.careSiteName : adminData?.careSiteName;
  let leftTrialDays = cartDetails?.trialDays;
  let isCreateSubsciption = true;
  if (cartDetails === 0) {
    let date1 = Date.now();
    let date2 = new Date(subscriptionData.trialExpiry);
    // Calculate the difference in time between the two dates
    let timeDiff = date2.getTime() - date1;
    // Convert the time difference from milliseconds to days
    let diffDays = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    leftTrialDays = diffDays > 0 ? diffDays : 0;
  }
  const currencyCode = countryData?.currency?.code ?? 'GBP';
  let dynamicData = {
    careHomeId: subscriptionData?._id,
    adminId: adminData._id,
    careStieName: newCareSiteData ? newCareSiteData?.careSiteName : csName,
    address: adminData.billingAddress,
    phone: adminData.mobileNo,
    email: result.paymentMethod.billing_details.email,
    paymentMethod: result.paymentMethod.id,
    country: countryData?.name,
    currency: currencyCode,
    couponType: "",
    unit_amount: amount ? +amount : subscriptionData?.total_amount,
    isCreateSubsciption: isCreateSubsciption,
    productId: cartDetails?.productId ?? subscriptionData?.productId,
    interval: cartDetails.interval
      ? cartDetails.interval
      : subscriptionData.monthyOrYearly.toLowerCase() === "monthly"
        ? "month"
        : "year",
    trialDays: leftTrialDays,
    // redemCount: "6",
  };
  if (!amount) {
    dynamicData.reason = 'change_billing_method'
  }

  if (cartDetails.amount_off > 0) {
    dynamicData.couponType = "amount_off";
    dynamicData.amount_off = cartDetails.amount_off;
    dynamicData.couponCode = cartDetails.promoName;
  }

  if (result.error) {
    cb(result);
  } else {
    const paymentResponse = await stripePayment(dynamicData);
    // console.log(paymentResponse, 'paymentResponse');
    localStorage.setItem("stripePayData", JSON.stringify(dynamicData));
    cb(paymentResponse);
  }
};

// place backend API call for payment
const stripePayment = async (data) => {
  const res = await httpPayments(`createStripeSubscription`, data);
  // console.log(res, 'createStripeSubscription');
  return await res;
};
