import { useEffect, useState } from "react";
import {
  Switch,
  Route,
  useRouteMatch,
  useParams,
  useHistory,
} from "react-router-dom";
import CommonClientPage from "../superadmin-clients/commonclientpage";
import { httpSuperAdmin } from "../../Apis/commonApis";

export default function ClientDetails() {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` 
  
  let { path, url, ...res } = useRouteMatch();
  const params = useParams();
  const history = useHistory();

  const [careSiteNames, setCareSiteNames] = useState([]);
  const [careSite, setCareSite] = useState(null);

  useEffect(() => {
    fetchCareSiteNames();
  }, []);

  const fetchCareSiteNames = async () => {
    let result = await httpSuperAdmin(
      `getCareHomeAndItsSites?adminId=${params.id}`,
      null
    );
    if (result.status) {
      let res = result?.data;
      let names = [];
      res?.forEach((elem, index) => {
        let subscriptionId = elem.goCardLess_subscription_id ?? elem.stripe_subscription_id;
        const siteDataObj = {
          careSiteId: elem._id,
          label: elem.careSiteName,
          subscriptionId: subscriptionId,
        };
        if (index === 0) {
          setCareSite(siteDataObj);
        }
        names.push(siteDataObj);
      });
      setCareSiteNames(names);
    }
  };

  const handleCaresiteChange = (e) => {
    const findSelected = careSiteNames.find(
      (item) => item.careSiteId === e.target.value
    );
    setCareSite(findSelected);
  };

  const handleNavigate = (type) => {
    // if (currentPlanName !== 'Turbo' && type === 'docs') {
    //   ShowInfoSwal(`This feature is not available in the ${currentPlanName} Plan`);
    //   return;
    // }
    history.replace({
      pathname: `${url}/${type}`,
      state: {},
    });
  };

  return (
    <div className="page-wrapper">
      <div className="card">
        <div className="card-body">
          <div className="top_menubar">
            <div className="d-flex">
              <h2>{params.copanyName}</h2>
              <select
                id="event"
                name="event"
                className="border-0 default_color ms-3"
                onChange={handleCaresiteChange}
              >
                {careSiteNames.map((item) => {
                  return (
                    <option key={item.careSiteId} value={item.careSiteId}>
                      {item.label}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="top_menubar">
              <ul
                className="nav nav-tabs ract_tab_list border-0 listspaceGap"
                id="myTab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    // disabled={currentModuleAccess?.Profile === "no"}
                    onClick={() => handleNavigate("overview")}
                    className={`nav-link curser_point ${window.location.href.includes("overview") ? "active" : ""
                      }`}
                  >
                    Overview
                  </button>
                </li>
                <li className={`nav-item`} role="presentation">
                  <button
                    // disabled={currentModuleAccess?.Contacts === "no"}
                    onClick={() => handleNavigate("events")}
                    className={`nav-link curser_point ${window.location.href.includes("events") ? "active" : ""
                      }`}
                  >
                    Events
                  </button>
                </li>
                {/* <li className="nav-item" role="presentation">
                  <button
                    // disabled={currentModuleAccess?.['Compliance Docs'] === "no"}
                    onClick={() => handleNavigate('emails')}
                    className={`nav-link curser_point ${window.location.href.includes("emails") ? "active" : ""
                      }`}
                  >
                    Emails
                  </button>
                </li> */}

                <li className="nav-item" role="presentation">
                  <button
                    // disabled={currentModuleAccess?.Journal === "no"}
                    onClick={() => handleNavigate("ff")}
                    className={`nav-link curser_point ${window.location.href.includes("ff") ? "active" : ""
                      }`}
                  >
                    F&F
                  </button>
                </li>

                <li className="nav-item" role="presentation">
                  <button
                    // disabled={currentModuleAccess?.['Care Plan'] === "no"}
                    className={`nav-link curser_point ${window.location.href.includes("notes") ? "active" : ""
                      }`}
                    onClick={() => {
                      handleNavigate("notes");
                    }}
                  >
                    Notes
                  </button>
                </li>
              </ul>
            </div>
          </div>

          <Switch>
            <Route exact path={path}>
              {/* <h3>Please select a topic.</h3> */}
            </Route>
            <Route path={`${path}/:topicId`}>
              <CommonClientPage careSite={careSite} />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}
