import React, { useState, useEffect } from "react";
import { httpAdmin } from "../../Apis/commonApis";
import Modal from "react-bootstrap/Modal";
import swal from "sweetalert";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { GiSettingsKnobs } from "react-icons/gi";
import {
  disablePastDate,
  getChangedKeysVals,
} from "../../utils/CommonFunctions";
import { AiOutlineClose } from "react-icons/ai";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { timeValues } from "../../constants/constants";
import { Controller, useForm } from "react-hook-form";
import { currentCareSiteId } from "../../redux-toolkit/reducer/switchSites";
import PhoneInput from "react-phone-input-2";
import { ShowSwal } from "../../utils/alertSwal";
import AwsLocation from "../../utils/AwsLocation";
import { twoDecimalPlaces } from "../../utils/utilsHandlerFunctions";

const icon_color = {
  email: "#2D9CDB",
  phone: "#E49231",
  meeting: "#34A853",
  task: "#5BA29E",
  notes: "#F2C94C",
};
export default function LeadsDetail(props) {
  const { leadDetail, handleLeadsDetails, getLeads } = props;
  const [activityData, setActivityData] = useState({
    history: [],
    upcoming: [],
  });
  const [showActivityModal, setShowActivityModal] = useState(false);
  const [checkState, setCheckState] = useState([]);
  const [keyContactData, setKeyContactData] = useState(null);

  const [editFormData, setEditFormData] = useState(null);
  const [leadDetailData, setLeadDetailData] = useState(null);
  const [activityId, setActivityId] = useState(null);
  const [changeBtn, setchangeBtn] = useState(true);
  const [changeProspectData, setChangeProspectData] = useState(true);
  const [manualSwitch, setManualSwitch] = useState(false);
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [location, setlocation] = useState(null);
  const [selectedActvityHistId, setSelectedActvityHistId] = useState("");

  const {
    register,
    handleSubmit,
    unregister,
    reset,
    setValue,
    formState: { errors },
    control,
  } = useForm();

  const {
    register: registerKeyCont,
    handleSubmit: handleSubmitKeyCont,
    reset: resetKeyCont,
    formState: { errors: errorsKeyCont },
  } = useForm();

  useEffect(() => {
    if (leadDetail) {
      setLeadDetailData(leadDetail);
      if (leadDetail.keycontact.length > 0) {
        setKeyContactData(leadDetail.keycontact[0]);
        setPhone(leadDetail.keycontact[0].phone?.toString());
      }
      setEditFormData({
        longitude: 0,
        lattitude: 0,
        leadId: leadDetail._id,
        first_Name: leadDetail.first_Name,
        last_Name: leadDetail.last_Name,
        referral: leadDetail.referral,
        clientPlanType: leadDetail.clientPlanType,
        invoiceRate: leadDetail.invoiceRate,
        salesPerson: leadDetail.salesPerson,
        salesPersonEmail: leadDetail.salesPersonEmail,
        leadsStatus: leadDetail.leadsStatus,
        clientType: leadDetail.clientType,
      });
      getLeadsActivity();
    }
  }, [leadDetail]);

  const editLead = async () => {
    const res = await httpAdmin("editLeads", "post", editFormData, null);
    if (res.status) {
      setChangeProspectData(true);
      setLeadDetailData(res.data);
      getLeads();
    }
    swal(
      res.status ? "Success" : "Failed",
      res.message,
      res.status ? "success" : "error"
    );
  };

  const createOrEditKeyContact = async (postData) => {
    setchangeBtn(true);
    let postObj = { ...postData };
    postObj.phone = phone;
    if (location) {
      postObj.address = location?.Label;
      postObj.lattitude = location?.latitude;
      postObj.longitude = location?.longitude;
    }
    const userData = JSON.parse(localStorage.getItem("userData"));
    let createOrEditApi = "keyContact";
    let body = {};
    if (leadDetail.keycontact.length > 0) {
      let compareObj = {
        name: keyContactData.name,
        phone: keyContactData.phone,
        email: keyContactData.email,
        address: keyContactData.address,
        relation: keyContactData.relation,
      };
      body = getChangedKeysVals(compareObj, postObj);
      if (Object.keys(body).length < 1) {
        return;
      }
      createOrEditApi = "editKeyContact";
      body.keycontact_id = leadDetail.keycontact[0]._id;
    } else {
      body = { ...postObj };
    }
    body.adminId = userData._id;
    body.careHomeId = currentCareSiteId;
    body.clientId = leadDetail._id;
    const res = await httpAdmin(
      `${createOrEditApi}/${leadDetail._id}`,
      "post",
      body,
      null
    );
    if (res.status) {
      setKeyContactData(res.docs.keycontact[0]);
      setPhone(res.docs.keycontact[0].phone);
      handleLeadsDetails(res.docs);
      getLeads();
    }
    ShowSwal(res.status, res.message);
  };

  const getLeadsActivity = async () => {
    const res = await httpAdmin(
      `getActivity?leadId=${leadDetail._id}`,
      "get",
      null,
      null
    );
    if (res.status) {
      let history = [];
      let upcoming = [];
      res.data?.leadsActivities.forEach((elem) => {
        if (elem.activityType === "upcoming") {
          upcoming.push(elem);
        } else {
          history.push(elem);
        }
      });
      setActivityData({ history: history, upcoming: upcoming });
    }
  };

  const createLeadsActivity = async (data) => {
    let postUrl = "createActivity";
    let body = {
      leadId: leadDetail._id,
      activityType: data.activityType,
      connectType: data.connectType,
      activityDate: data.activityDate.split("T")[0],
      activityTime: data.activityTime,
      reminderBefore: data.reminderBefore,
      reminderFreq: data.reminderFreq,
      discription: data.discription,
    };
    if (activityId) {
      body.activityId = activityId;
      postUrl = "editActivity";
    }
    const res = await httpAdmin(postUrl, "post", body, null);
    if (res.status) {
      getLeadsActivity();
      closeActivityModal();
      reset();
      setManualSwitch(false);
    }
    ShowSwal(res.status, res.message);
  };

  const completeLeadsActivity = async (activityId) => {
    let body = {
      leadId: leadDetail._id,
      activityId: activityId,
      activityType: "past",
    };
    const res = await httpAdmin(`completeActivity`, "post", body, null);
    if (res.status) {
      getLeadsActivity();
    }
    ShowSwal(res.status, res.message);
  };

  const deleteLeadsActivity = async (activityId) => {
    let body = {
      leadsId: leadDetail._id,
      activityIds: [activityId],
    };
    const res = await httpAdmin(`deleteActivity`, "post", body, null);
    if (res.status) {
      getLeadsActivity();
    }
    ShowSwal(res.status, res.message);
  };

  // const editLeadsActivity = async () => {
  //     const res = await httpAdmin(`editActivity`, 'post', editKeyContactFormData, null);
  //     if (res.status) {
  //         getLeadsActivity();
  //         closeActivityModal();
  //     }
  // }

  function closeActivityModal() {
    reset();
    setShowActivityModal(false);
  }

  function handleActivityActionChange(type) {
    if (type === "create") {
      setActivityId(null);
      setShowActivityModal(true);
    } else if (type === "edit") {
      if (checkState.length > 0) {
        setActivityId(checkState[0]);
        let activity = {};
        let allActivityArr = [
          ...activityData.history,
          ...activityData.upcoming,
        ];
        allActivityArr.forEach((elem) => {
          if (elem._id === checkState[0]) {
            activity = elem;
          }
        });
        setValue("activityType", activity.activityType);
        setValue("connectType", activity.connectType);
        setValue("activityDate", activity.activityDate.split("T")[0]);
        setValue("activityTime", activity.activityTime);
        setValue("discription", activity.discription);
        setValue("reminderBefore", activity.reminderBefore);
        setValue("reminderFreq", activity.reminderFreq);
        setShowActivityModal(true);
      } else {
        if (selectedActvityHistId !== "") {
          ShowSwal(false, "Activity history is not editable!");
        } else {
          ShowSwal(false, "Please Select checkbox to proceed");
        }
      }
    } else if (type === "complete") {
      if (checkState.length > 0) {
        swal({
          title: "Are you sure??",
          text: "Click below to mark this activity as completed",
          buttons: {
            cancel: "Cancel",
            succeed: "Yes",
          },
        }).then((result) => {
          if (result) completeLeadsActivity(checkState[0]);
        });
      } else {
        if (selectedActvityHistId !== "") {
          ShowSwal(false, "Already completed!");
        } else {
          ShowSwal(false, "Please Select checkbox to proceed");
        }
      }
    } else {
      if (checkState.length > 0 || selectedActvityHistId !== "") {
        swal({
          title: "Are you sure??",
          text: "Click below to procced",
          buttons: {
            cancel: "Cancel",
            succeed: "Yes",
          },
        }).then((result) => {
          if (result) deleteLeadsActivity(checkState[0]);
        });
      } else {
        ShowSwal(false, "Please Select checkbox to proceed");
      }
    }
  }

  const handleCheckBoxChange = (event) => {
    let eventBool = event.target.checked;
    // let newSelected = [];
    if (eventBool) {
      setCheckState([event.target.name]);
      // setCheckState((prev) => [...prev, event.target.name]);
    } else {
      setCheckState([]);
      // const selectedIndex = checkState.indexOf(event.target.name);
      // newSelected = newSelected.concat(
      //     checkState.slice(0, selectedIndex),
      //     checkState.slice(selectedIndex + 1)
      // );
      // setCheckState(newSelected);
    }
    setSelectedActvityHistId("");
  };

  const handleHistoryCheckBox = (event) => {
    let eventBool = event.target.checked;
    // let newSelected = [];
    if (eventBool) {
      setSelectedActvityHistId(event.target.name);
    } else {
      setSelectedActvityHistId("");
    }
    setCheckState([]);
  };

  const handleManualSwitch = (event) => {
    setManualSwitch(event.target.checked);
  };

  const handlePhoneChange = (value, selectedCountry) => {
    setPhone(value);
    if (phoneError !== "") {
      setPhoneError("");
    }
  };

  function awsLocationHandler(locVal) {
    setlocation(locVal);
  }

  return (
    <>
      <div className="card-body">
        <div className="mb-2">
          <a
            className="mb-2 backSideButton"
            href="#"
            onClick={() => handleLeadsDetails(null)}
          >
            Back
          </a>
        </div>
        <div className="row">
          <div className="d-flex border4">
            <div className="col-md-6 prospects_box_content">
              <div className="edit_lead_head">
                <h5>Prospects Details</h5>
                {changeProspectData ? (
                  <div
                    className="backbutton"
                    onClick={() => setChangeProspectData(false)}
                  >
                    <span>Edit</span>
                  </div>
                ) : (
                  <div className="backbutton" onClick={() => editLead()}>
                    <span>Save</span>
                  </div>
                )}
              </div>
              <table className="table_sales table-striped table_half mb-0">
                <tbody>
                  <tr>
                    <td>First Name:</td>
                    {changeProspectData ? (
                      <td>{leadDetailData?.first_Name}</td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={editFormData?.first_Name}
                          onChange={(e) =>
                            setEditFormData({
                              ...editFormData,
                              first_Name: e.target.value,
                            })
                          }
                          name="first_Name"
                          className="form-control form-control-sm"
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Last Name:</td>
                    {changeProspectData ? (
                      <td>{leadDetailData?.last_Name}</td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={editFormData?.last_Name}
                          onChange={(e) =>
                            setEditFormData({
                              ...editFormData,
                              last_Name: e.target.value,
                            })
                          }
                          name="last_Name"
                          className="form-control form-control-sm"
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Referral:</td>
                    <td>
                      {leadDetailData?.referral.length > 0 ? "Yes" : "No"}
                    </td>
                  </tr>
                  <tr>
                    <td>Referred By:</td>
                    {changeProspectData ? (
                      <td>{leadDetailData?.referral}</td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={editFormData?.referral}
                          onChange={(e) =>
                            setEditFormData({
                              ...editFormData,
                              referral: e.target.value,
                            })
                          }
                          name="referral"
                          className="form-control form-control-sm"
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Plan Type:</td>
                    {changeProspectData ? (
                      <td>
                        {leadDetailData?.clientPlanType === "Self-Funded"
                          ? "Private"
                          : "Council"}
                      </td>
                    ) : (
                      <td>
                        <select
                          className="form-select"
                          name="clientPlanType"
                          defaultValue={leadDetail?.clientPlanType}
                          onChange={(e) =>
                            setEditFormData({
                              ...editFormData,
                              clientPlanType: e.target.value,
                            })
                          }
                        >
                          <option value="">--Select--</option>
                          <option value="Council">Council</option>
                          <option value="Self-Funded">Private</option>
                        </select>
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Rate per Week: </td>
                    {changeProspectData ? (
                      <td>{twoDecimalPlaces(leadDetailData?.invoiceRate)}</td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={leadDetail?.invoiceRate}
                          onChange={(e) =>
                            setEditFormData({
                              ...editFormData,
                              invoiceRate: e.target.value,
                            })
                          }
                          name="invoiceRate"
                          className="form-control form-control-sm"
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Sales Person: </td>
                    {changeProspectData ? (
                      <td>{leadDetailData?.salesPerson}</td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={leadDetail?.salesPerson}
                          onChange={(e) =>
                            setEditFormData({
                              ...editFormData,
                              salesPerson: e.target.value,
                            })
                          }
                          name="salesPerson"
                          className="form-control form-control-sm"
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Sales Person Email: </td>
                    {changeProspectData ? (
                      <td>{leadDetailData?.salesPersonEmail}</td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={leadDetail.salesPersonEmail}
                          onChange={(e) =>
                            setEditFormData({
                              ...editFormData,
                              salesPersonEmail: e.target.value,
                            })
                          }
                          name="salesPersonEmail"
                          className="form-control form-control-sm"
                        />
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-md-6 prospects_box_content">
              <div className="edit_lead_head">
                <h5>Key Contact</h5>
                {changeBtn ? (
                  <div
                    className="backbutton"
                    onClick={() => setchangeBtn(false)}
                  >
                    <span>Edit</span>
                  </div>
                ) : (
                  <div
                    className="backbutton"
                    onClick={handleSubmitKeyCont(createOrEditKeyContact)}
                  >
                    <span>Save</span>
                  </div>
                )}
              </div>
              <table className="table_sales table-striped table_half mb-0">
                <tbody>
                  <tr>
                    <td>Name:</td>
                    {changeBtn ? (
                      <td>{keyContactData?.name}</td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          defaultValue={keyContactData?.name}
                          {...registerKeyCont("name", {
                            required: false,
                          })}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Tel:</td>
                    {changeBtn ? (
                      <td>{keyContactData?.phone}</td>
                    ) : (
                      <td>
                        <PhoneInput
                          country={"gb"}
                          defaultMask={phone}
                          value={phone}
                          onChange={handlePhoneChange}
                          isValid={(value, country) => {
                            if (
                              value.match(
                                /\+?\d{1,9}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/g
                              )
                            ) {
                              return true;
                            } else {
                              return false;
                            }
                          }}
                          defaultErrorMessage=""
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Email:</td>
                    {changeBtn ? (
                      <td>{keyContactData?.email}</td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={keyContactData?.email}
                          className="form-control form-control-sm"
                          {...registerKeyCont("email", {
                            required: false,
                            pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          })}
                        />
                        {errorsKeyCont?.email?.type === "pattern" && (
                          <p className="error_style">Invalid email address</p>
                        )}
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Address:</td>
                    {changeBtn ? (
                      <td>{keyContactData?.address}</td>
                    ) : (
                      <td>
                        <AwsLocation
                          awsLocationHandler={awsLocationHandler}
                          defaultVal={keyContactData?.address}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Relationship:</td>
                    {changeBtn ? (
                      <td>{keyContactData?.relation}</td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={keyContactData?.relation}
                          className="form-control form-control-sm"
                          {...registerKeyCont("relation", {
                            required: false,
                          })}
                        />
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="top_menubar mt-3 d-flex justify-content-between">
            <ul
              className="nav nav-tabs ract_tab_list border-0"
              id="myTab"
              role="tablist"
            >
              {/* <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link active"
                                    id="leadsD-1"
                                    data-bs-toggle="tab"
                                    data-bs-target="#leads_1"
                                    type="button"
                                    role="tab"
                                    aria-controls="leads_1"
                                    aria-selected="true"
                                >
                                    Activity
                                </button>
                            </li> */}
              {/* <li className="nav-item ms-3" role="presentation">
                                <button
                                    className="nav-link"
                                    id="leadsD-2"
                                    data-bs-toggle="tab"
                                    data-bs-target="#leads_2"
                                    type="button"
                                    role="tab"
                                    aria-controls="leads_2"
                                    aria-selected="false"
                                >
                                    Emails
                                </button>
                            </li> */}
            </ul>
            <div className="dropdownAction">
              <div className="iconFilter">
                <span>
                  <GiSettingsKnobs />
                </span>
              </div>
              <DropdownButton id="dropdown-basic-button" title="Actions">
                <Dropdown.Item
                  onClick={() => handleActivityActionChange("create")}
                >
                  Create Activity
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleActivityActionChange("complete")}
                >
                  Complete Activity
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleActivityActionChange("edit")}
                >
                  Edit
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleActivityActionChange("delete")}
                >
                  Delete
                </Dropdown.Item>
              </DropdownButton>
            </div>
          </div>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="leads_1"
              role="tabpanel"
              aria-labelledby="leadsD-1"
            >
              <div className="col-md-12 activity_box">
                <h5 className="d-flex justify-content-between align-items-end">
                  Upcoming Activity
                  {/* <div className="activity_main_box">
                                        <ul className='d-flex activity_icons'>
                                            <li>
                                                <NavLink to="#" onClick={() => openActivityModal('email')}>
                                                    <img src="../../images/ds1.svg" />
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="#" onClick={() => openActivityModal('phone')}>
                                                    <img src="../../images/ds2.svg" />
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="#" onClick={() => openActivityModal('meeting')}>
                                                    <img src="../../images/ds3.svg" />
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="#" onClick={() => openActivityModal('reminder')}>
                                                    <img src="../../images/ds4.svg" />
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </div> */}
                </h5>
                <div className="activity_main row">
                  {activityData.upcoming.map((item) => {
                    return (
                      <div
                        key={item._id}
                        className="call_history col-md-12 mb-3"
                      >
                        <div className="d-flex">
                          <p>{item.activityDate?.split("T")[0]}</p>
                          <span> &nbsp; {item.activityTime} </span>
                        </div>
                        <div className="d-flex history mt-1 justify-content-between">
                          <div className="d-flex align-items-start">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={checkState.includes(item._id)}
                                  onChange={handleCheckBoxChange}
                                  name={item._id}
                                />
                              }
                            />
                            <img
                              style={{
                                width:
                                  item.connectType === "meeting"
                                    ? "31px"
                                    : "28px",
                              }}
                              src={`/images/img_${item.connectType}.svg`}
                            />
                            <div className="body">
                              <h6
                                style={{ color: icon_color[item.connectType] }}
                              >
                                {item.connectType}
                              </h6>
                              <p>{item.discription}</p>
                            </div>
                          </div>
                        </div>
                        {/* <div className="checkbox-wrapper col d-flex align-items-center justify-content-end">
                                            </div> */}
                        {/* <div className="col d-flex align-items-center justify-content-end">
                                                <input type="radio" name="subscriptionPlan" />
                                            </div> */}
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="col-md-12 activity_box activity_margin mb-5">
                <h5>Activity History</h5>
                <div className="activity_main row">
                  {activityData.history.map((item) => {
                    return (
                      <div
                        key={item._id}
                        className="call_history col-md-12 mb-3"
                      >
                        <div className="d-flex">
                          <p>{item.activityDate?.split("T")[0]}</p>
                          <span> &nbsp; {item.activityTime} </span>
                        </div>
                        <div className="d-flex history mt-1 justify-content-between">
                          <div className="d-flex align-items-start">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={selectedActvityHistId.includes(
                                    item?._id
                                  )}
                                  onChange={handleHistoryCheckBox}
                                  name={item._id}
                                />
                              }
                            />
                            <img
                              style={{ width: "30px" }}
                              src={`/images/img_${item.connectType}.svg`}
                            />
                            <div className="body">
                              <h6>{item?.connectType}</h6>
                              <p>{item?.discription}</p>
                            </div>
                          </div>

                          <Checkbox
                            name={item?._id}
                            checked={true}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </div>
                        {/* <div className="checkbox-wrapper col d-flex align-items-center justify-content-end">
                                            </div> */}
                        {/* <div className="col d-flex align-items-center justify-content-end">
                                                <input type="radio" name="subscriptionPlan" />
                                            </div> */}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="viewModal"
        show={showActivityModal}
        onHide={closeActivityModal}
      >
        <Modal.Header>
          <Modal.Title>
            <span>{activityId ? "Edit" : "Create"} Activity</span>
            <span>
              <AiOutlineClose
                className="curser_point"
                onClick={closeActivityModal}
              />
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "10px 20px" }}>
          <form onSubmit={handleSubmit(createLeadsActivity)}>
            <div className="row">
              <div className="my-1 col-md-6">
                <label className={`form-label m-0`}>Connect Type</label>
                <select
                  className="form-select"
                  {...register("connectType", {
                    required: true,
                  })}
                  name="connectType"
                >
                  <option value="email"> Email</option>
                  <option value="phone"> Phone</option>
                  <option value="meeting"> Meeting</option>
                  <option value="task"> Task</option>
                  <option value="notes"> Notes</option>
                </select>
                {errors?.connectType?.type === "required" && (
                  <p className="error_style">This field is required</p>
                )}
              </div>
              <div className="my-1 col-md-6">
                <label className={`form-label m-0`}>Activity Type</label>
                <select
                  className="form-select"
                  {...register("activityType", {
                    required: true,
                  })}
                  name="activityType"
                >
                  <option value="upcoming"> Upcoming</option>
                  <option value="past"> Past</option>
                </select>
                {errors?.activityType?.type === "required" && (
                  <p className="error_style">This field is required</p>
                )}
              </div>
              <div className="my-1 col-md-12">
                <label className={`form-label m-0`}>Activity Date</label>
                <input
                  type="date"
                  className="form-control"
                  {...register("activityDate", {
                    required: true,
                  })}
                  name="activityDate"
                  min={disablePastDate()}
                />
                {errors?.activityDate?.type === "required" && (
                  <p className="error_style">This field is required</p>
                )}
              </div>
              <div className="col-md-12">
                <label className="mb-1">Activity Time</label>
                <label>Start Time</label>
                <select
                  className="form-select custom_timeInput"
                  {...register("activityTime", {
                    required: true,
                  })}
                  name="activityTime"
                >
                  {timeValues.map((item) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    );
                  })}
                </select>
                {errors?.activityTime?.type === "required" && (
                  <p className="error_style">This field is required</p>
                )}
              </div>
              <FormControlLabel
                control={
                  <Switch
                    checked={manualSwitch}
                    onChange={handleManualSwitch}
                  />
                }
                label="Set Reminder"
                className="my-1"
              />
              {manualSwitch && (
                <>
                  <div className="my-1 col-md-6">
                    <label className={`form-label m-0`}>Remind before</label>
                    <input
                      className="form-control"
                      type="number"
                      {...register("reminderBefore", {
                        required: true,
                      })}
                      name="reminderBefore"
                    />
                    {errors?.reminderBefore?.type === "required" && (
                      <p className="error_style">This field is required</p>
                    )}
                  </div>
                  <div className="my-1 col-md-6">
                    <label className={`form-label m-0`}>{""}</label>
                    <select
                      className="form-select"
                      {...register("reminderFreq", {
                        required: true,
                      })}
                      name="reminderFreq"
                    >
                      <option value="day"> Days</option>
                      <option value="hour"> Hours</option>
                      <option value="minute"> Minutes</option>
                    </select>
                    {errors?.reminderFreq?.type === "required" && (
                      <p className="error_style">This field is required</p>
                    )}
                  </div>
                </>
              )}
              <div className="my-1 col-md-12">
                <label className={`form-label m-0`}>Description</label>
                <textarea
                  {...register("discription", {
                    required: true,
                  })}
                  name="discription"
                  className="form-control"
                ></textarea>
                {errors?.discription?.type === "required" && (
                  <p className="error_style">This field is required</p>
                )}
              </div>
            </div>
            <Modal.Footer>
              <div className="d-flex">
                <button
                  className="btn"
                  type="submit"
                  onClick={(e) => handleSubmit(e)}
                >
                  {activityId ? "Edit" : "Create"}
                </button>
              </div>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
