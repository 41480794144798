import React from "react";
import AdminWrapper from "../../../components/AdminWrapper";
import "./PredefinedTemplate.css";

export default function PredefinedQuestionAnswer() {
  return (
    <AdminWrapper>
      <div className="predefineTemplate">
        <div className="col-md-8">
          <h5>Readiness for Enhanced SelfCare</h5>
          <h5 style={{ fontSize: "18px" }}>Please answer below questions</h5>
          <p>
            Click Next when you finish answering. Your input will be used to
            auto-fill the observations section which you can accordingly edit,
            complete and save in the plan.{" "}
          </p>
          <button className="btn nextBtn">Next</button>
        </div>
        <div className="col-md-12">
          <div className="col-md-4">
            <div className="quationBox">
              <h5>Does NAME have trouble completing ADLs on their own? </h5>
              <ul>
                <li>
                  <label className="checkbox">
                    <input type="checkbox" />
                    <span className="checkmark"></span>
                    NAME is completely independent.
                  </label>
                </li>
                <li>
                  <label className="checkbox">
                    <input type="checkbox" />
                    <span className="checkmark"></span>
                    NAME Needs minimal assistance
                  </label>
                </li>
                <li>
                  <label className="checkbox">
                    <input type="checkbox" />
                    <span className="checkmark"></span>
                    NAME needs moderate assistance
                  </label>
                </li>
                <li>
                  <label className="checkbox">
                    <input type="checkbox" />
                    <span className="checkmark"></span>
                    NAME is completely dependent on Caregivers for ADLs
                  </label>
                </li>
              </ul>
            </div>

            <div className="quationBox">
              <h5>
                Does NAME tolerate ADLs or exercise without significant weakness
                or dyspnea?{" "}
              </h5>
              <ul>
                <li>
                  <label className="checkbox active">
                    <input type="checkbox" />
                    <span className="checkmark"></span>
                    Yes
                  </label>
                </li>
                <li>
                  <label className="checkbox">
                    <input type="checkbox" />
                    <span className="checkmark"></span>
                    No
                  </label>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </AdminWrapper>
  );
}
