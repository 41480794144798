import React from "react";
import cx from "./Portal.module.css";
import { Row, Col } from "react-bootstrap";

export default function welcomeLogin() {


  return (
    <div className={`${cx.welcomeLogin}`}>
      <div className={`container`}>
        <Row>
          <Col lg={4} md={6} sm={12} className={`m-auto`}>
            <div className={`${cx.welcomeBox}`}>
              <div className={`${cx.topBanner}`}>
                <img src="/images/Newfooter_Logo.svg" alt="portalLogo" />
              </div>
              <div className={`${cx.LoginBox}`}>
                <h3>Welcome,</h3>
                <p>Please enter your email address. </p>
                <div className={`${cx.formInput}`}>
                  <div className={`${cx.inputEmail}`}>
                    <label>Email</label>
                    <input className={`form-control`} type="email" placeholder="jhon@gmail.com" />
                  </div>

                  <div className={`form-check`}>
                    <input className={`form-check-input`} type="checkbox" value="" id="flexCheckDefault" />
                    <label className={`form-check-label`} for="flexCheckDefault">
                      We follow GDPR security requirements. Please read and accept our privacy notice and terms of use before sign-in. <a href="https://caremagnus.com/blogs/privacy-policy"> Privacy Notice </a> & <a href="https://caremagnus.com/blogs/app-terms-of-use"> Terms of Use</a>.
                    </label>
                  </div>
                </div>
                <div className={`${cx.nextButton}`}>
                  <button>Next</button>
                </div>
                <p className={`${cx.termText}`}>It seems that this email is either not correct or not yet registered to use the Friends & Family App. Kindly recheck email or contact your care service provider to activate your profile.</p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}
