import { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import "./Profile.css";
import { httpAdmin } from "../../Apis/commonApis";
import { useDispatch, useSelector } from "react-redux";
import { ShowSwal } from "../../utils/alertSwal";
import { getCareSiteListing } from "../../redux-toolkit/reducer/caresiteDataReducer";

export default function Billing() {

  const dispatch = useDispatch();

  let { currentCareSiteData } = useSelector((state) => state.caresiteDataReducer);

  const [invoiceHistory, setInvoiceHistory] = useState([]);
  const [careSiteplanData, setCareSitePlanData] = useState([]);
  const [billAddress, setBillAddress] = useState([]);

  useEffect(() => {
    dispatch(getCareSiteListing());
  }, []);

  useEffect(() => {
    if (currentCareSiteData) {
      setCareSitePlanData(currentCareSiteData);
      const invoices = [];
      currentCareSiteData.invoices?.forEach(elem => {
        invoices.push(elem?.invoicesDetails ?? elem)
      });
      setInvoiceHistory(invoices);
    }
  }, [currentCareSiteData]);

  const changeBillAddress = async () => {
    let postdata = {
      adminId: careSiteplanData?.adminId,
      careHomeId: currentCareSiteData?._id,
      subAdminId: careSiteplanData?._id,
      billingAddress: billAddress
    }
    let result = await httpAdmin('editCarehomeProfile', 'post', postdata, null);
    ShowSwal(result.status, result.message);
    if (result.status) {
      dispatch(getCareSiteListing());
    }
  };

  return (
    <div className="mt-3 profileBox">
      <div className="col-md-12">
        <div className="row profileB">
          <div className="col-9">
            <ul>
              <li>
                <p>
                  <span className="themeColor">Billing Method:</span>
                  {careSiteplanData?.paymentThrough === "gocardless" ? 'Direct Debit' : 'Credit Card'}
                </p>
                <p>
                  <NavLink
                    to={{
                      pathname: "/admin/profile/update-billing-method",
                      state: { ownerId: careSiteplanData?.adminId },
                    }}
                  >
                    Update
                  </NavLink>
                </p>
              </li>
              <li>
                <p>
                  <span className="themeColor">Billing Address:</span> {careSiteplanData?.billingAddress}
                </p>
                {careSiteplanData?.paymentThrough != "gocardless" && <p>
                  <NavLink
                    data-bs-toggle="modal"
                    data-bs-target="#editprofile_modal"
                    data-bs-whatever="@mdo"
                    to="#">Update</NavLink>
                </p>}
              </li>
            </ul>
            <h5 className="themeColor mt-3">Invoice History</h5>

            <ul className="billingHistory">
              {invoiceHistory?.map((item, i) => {
                return (
                  <li key={i}>
                    <p>
                      {new Date(item?.invoiceDate).toLocaleDateString("en-US")} — {item?.symbol ?? '£'}{" "}
                      {item.amountDue ?? item.total}
                    </p>
                    <p>
                      {/* <NavLink to={item.invoiceLink} target="_blank" download={item.invoiceLink}>
                        Invoice
                      </NavLink> */}

                      <a href={item.invoiceLink} target="_blank" >
                        Invoice
                      </a>
                    </p>
                  </li>
                );
              })}
            </ul>

            {/* <button className="btn">See More</button> */}

            {invoiceHistory?.length === 0 && <p>No data to show!!</p>}
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="editprofile_modal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel1"
      >
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <div className="modal-header d-flex align-items-center">
              <h4 className="modal-title" id="exampleModalLabel1">
                Update Billing Address
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body">
              <form>
                <div className="row">
                  <div className="mb-3 col-md-12">
                    <label className={`form-label`}>Billing Address</label>
                    <textarea
                      type="text"
                      className="form-control"
                      defaultValue={careSiteplanData?.billingAddress}
                      onChange={(e) => setBillAddress(e.target.value)}
                    />
                  </div>
                </div>
                <div className="blog_popup_details">
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-primary submit_btn"
                      data-bs-dismiss="modal"
                      onClick={changeBillAddress}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
