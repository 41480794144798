import React from "react";

const chatUserImg = '/images/chatUser.svg'

export default function FrontMsg({ userDetails }) {
  console.log(userDetails, 'userDetails')
  return (
    <div className="col-md-7 chat_front_user_box">
      <div className="">
        <div className="chat_front_user_body">
          <h2 className="text-center mb-4">Welcome, {userDetails?.name}</h2>
          <div className="userprofile_box">
            <img
              alt=""
              className={userDetails?.image.includes('chatUser') ? "forImageadmin" : ""}
              src={userDetails?.image ? userDetails.image : chatUserImg}
            />
            <small>
              <i className="fas fa-circle"></i>
            </small>
          </div>
        </div>
        <div className="chat_front_user_footer text-center">
          <p>You are signed in as {userDetails?.email}</p>
          <p>
            Try <strong>switching accounts</strong> if you do not see your
            contacts or conversation history. <br />
            {/* <a href="#" className="learn_more">
              Learn More
            </a> */}
          </p>
        </div>
      </div>
    </div>
  );
}
