import React from "react";

export default function PrivacyAndPolicy() {
  return (
    <p className="mt-2 privacy_policy">
      We follow GDPR security requirements. Please read and accept our privacy
      policy & Terms & Conditions before sign-up.
      <a href="https://caremagnus.com/blogs/privacy-policy" className="seeDetails">
        Privacy Policy
      </a>{" "}
      &
      <a href="https://caremagnus.com/blogs/terms-conditions" className="seeDetails">
        Terms & Conditions{" "}
      </a>{" "}
    </p>
  );
}
