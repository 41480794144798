import React, { useState, useEffect } from "react";
import "../Clients/clients.css";
import { useHistory } from "react-router-dom";
// import { ArrowRight } from "@mui/icons-material";
import { IoIosArrowForward } from "react-icons/io";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/system";
import { useSelector } from "react-redux";
import { getFullName } from "../../utils/CommonFunctions";

const chatUserImg = '/images/chatUser.svg'

const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-cell:focus": {
    outline: "none",
  },
}));

const columns = [
  {
    field: "name",
    headerName: "Name",
    width: 210,
    renderCell: (params) => <ProfileImgName params={params} />,
  },
  { field: "location", headerName: "Location", flex: 1, minWidth: 190 },
  { field: "contractStartdate", headerName: "Start Date", flex: 1, minWidth: 110 },
  { field: "performance", headerName: "Performance(%)", minWidth: 130 },
  { field: "hrsWorked", headerName: "Hrs Worked", width: 130 },
  { field: "taskDone", headerName: "Task Done", width: 120 },
  { field: "teamNotes", headerName: "Care Notes", width: 120 },
  {
    field: "careMemberstatus",
    headerName: "Status",
    width: 80,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => <StatusButton params={params} />,
  },
  {
    id: "arrow",
    width: 50,
    numeric: true,
    disablePadding: true,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => <RedirectionIcon params={params} />,
  },
];

export default function CareTeamNewTable(props) {
  let { rows, selectedIDs, searchText } = props;

  const { currentPlanName } = useSelector((state) => state.caresiteDataReducer);
  const isPlanBase = currentPlanName === 'Base';

  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  useEffect(() => {
    selectedIDs(rowSelectionModel);
  }, [rowSelectionModel]);

  const filteredRows = searchText
    ? rows.filter(
      (row) =>
        row.name?.toLowerCase().includes(searchText) ||
        row.location?.toLowerCase().includes(searchText)
    )
    : rows;

  let filteredCols = isPlanBase ? columns?.filter(item => item.field !== "hrsWorked") : columns;

  return (
    <div style={{ height: "75vh", width: "100%" }}>
      <CustomDataGrid
        columns={filteredCols}
        rows={filteredRows}
        // autoHeight
        checkboxSelection
        onSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        disableSelectionOnClick
        getRowId={(row) => row?.id}
        rowSelectionModel={rowSelectionModel}
        pageSize={8}
      />
    </div>
  );
}

const StatusButton = (props) => {
  let { params } = props;
  return (
    <div>
      {params?.row?.careMemberstatus === "active" && (
        <button className={`btn btn-sm btn-success table_btn_font`}>
          Active
        </button>
      )}
      {params?.row?.careMemberstatus.toLowerCase() === "inactive" && (
        <button
          className={`btn btn-sm btn-danger table_btn_font`}
        >
          Inactive
        </button>
      )}
    </div>
  );
};

const ProfileImgName = (props) => {
  let { params } = props;
  const dp = params.row.image;
  const isDefaultImg = params.row.image?.includes('chatUser');
  return (
    <span className="profle_img_box">
      <img alt="" className={`profile_img_table ${isDefaultImg ? 'forImagecareteam' : ''}`}
        src={dp ? dp : chatUserImg} />{" "}
      <strong
        style={{ fontWeight: "400" }}
      >
        {getFullName(params?.row)}
        {/* {`${params.row.first_Name} ${params.row.last_Name}`} */}
      </strong>
    </span>
  );
};

const RedirectionIcon = (props) => {
  const history = useHistory();
  let { params } = props;
  return (
    <IoIosArrowForward
      style={{ cursor: "pointer", fontSize: "20px" }}
      onClick={() => {
        history.push(`/admin/careteam/details/profile`, { careteamId: params?.row?.id });
      }}
    />
  );
};
