import React, { useState, useEffect } from "react";
import "./messaging.css";
import ChatBox from "./ChatBox";
import { currentCareSiteId } from "../redux-toolkit/reducer/switchSites";

function Messaging() {
    //   console.log(db, "firebase database");redux-toolkit/reducer/switchSites
    // const ref = firebase.database().ref('users/');
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem("userData"));
        setCurrentUser(userData);
    }, []);

    return (
        <div className="page-wrapper">
            <div className="container-fluid min_height">
                <div className="card mb-0">
                    <div className="card-body">
                        {/* <h4 className="card-title">
                            Messaging
                            <div className="float-end btns_head">
                                <button className="btn btn-theme btn-sm">Create Groups</button>
							<button className="btn btn-theme btn-sm">Add New Resident</button>
                            </div>
                        </h4> */}
                        <div>
                            <section className="chat_box_section">
                                <div className="container-fluid">
                                    {currentUser && <ChatBox userdata={currentUser} currentCareSiteId={currentCareSiteId} />}
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Messaging;
