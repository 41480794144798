import React, { useState, useEffect } from "react";
import BlogListTable from "./BlogListTable";
import { httpSuperAdmin } from "../../Apis/commonApis";
import BlogModals from "./BlogModals";
import {
  backToNormal,
  deleteBlog,
  selectMultipleBlogIds,
} from "../../redux-toolkit/reducer/SuperAdminReducer";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Dropdown, DropdownButton } from "react-bootstrap";

export default function Blogs() {
  const [blogsList, setBlogsList] = useState([]);
  const [popData, setPopData] = useState({});
  const [getLatestBlogCount, setGetLatestBlogCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  const { blogIds, deleteBlogCount, deleteBlogMessage, deleteBlogStatus } =
    useSelector((state) => state.superAdminReducer);

  useEffect(() => {
    getBlogs();
  }, [getLatestBlogCount]);

  function getLatestBlogData() {
    setGetLatestBlogCount(getLatestBlogCount + 1);
  }

  const getBlogs = async () => {
    dispatch(
      backToNormal({
        loading: false,
        blogIds: [],
        selectedIds: [],
        deleteBlogCount: 0,
        deleteBlogMessage: "",
        deleteBlogStatus: false,
      })
    );
    const res = await httpSuperAdmin("websiteBlog?type=active?blogType=all");
    if (res.status) {
      setBlogsList(res.Blogdata);
    }
  };

  const viewModalDataHandler = (data) => {
    setPopData(data);
  };

  function selectMultipledata(ids) {
    console.log("arrayOfIds", ids);
    dispatch(selectMultipleBlogIds({ ids: ids }));
  }

  // -----------  Delete Blog Popup (Show and Hide Handler) Starts ------------ //

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const handleCloseDeletePopup = () => setIsDeleteOpen(false);
  const handleShowDeletePopup = () => setIsDeleteOpen(true);

  // ---------------------     delete multiple clients api    ----------------------------------  //
  async function deletemultiple() {
    await dispatch(
      deleteBlog({
        ids: blogIds,
      })
    );
  }

  useEffect(() => {
    if (deleteBlogCount) {
      try {
        swal(
          deleteBlogStatus ? "Success" : "Failed",
          Array.isArray(deleteBlogMessage)
            ? deleteBlogMessage.join("\n")
            : deleteBlogMessage,
          deleteBlogStatus ? "success" : "error"
        ).then((value) => {
          dispatch(
            backToNormal({
              loading: false,
              blogIds: [],
              selectedIds: [],
              deleteBlogCount: 0,
              deleteBlogMessage: "",
              deleteBlogStatus: false,
            })
          );
          handleCloseDeletePopup();
          getBlogs();
          dispatch(selectMultipleBlogIds({ ids: [] }));
          return;
        });
      } catch (e) {
        swal("Failed", "Oops, Something went wrong", "error");
      }
    }
  }, [deleteBlogCount]);
  return (
    <>
      <div className="col-md-12 btns_head text-end mb-3">
        <DropdownButton id="dropdown-basic-button" title="Actions">
          <Dropdown.Item
            onClick={() => {
              setIsOpen(true);
            }}
          >
            Add New Blog
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              if (blogIds.length >= 1) handleShowDeletePopup(true);
              else
                swal(
                  "Failed",
                  "Please Select at least one client",
                  "error"
                ).then((value) => {
                  return;
                });
            }}
          >
            Delete{" "}
          </Dropdown.Item>
        </DropdownButton>
      </div>
      <BlogListTable
        rows={blogsList}
        invokeParentMethod={viewModalDataHandler}
        selectMultipledata={selectMultipledata}
      />
      <BlogModals
        popData={popData}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        getLatestBlogData={getLatestBlogData}
      />

      <Modal
        className="viewModal"
        show={isDeleteOpen}
        onHide={handleCloseDeletePopup}
      >
        <Modal.Header>
          <Modal.Title>
            <span>Delete Client</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="">
                <p>
                  Are you sure you want to delete this{" "}
                  {blogIds.length > 1 ? "blogs" : "blog"} ?
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex">
            <button className="btn submitBtn" onClick={handleCloseDeletePopup}>
              No
            </button>
            <button
              className="btn submitBtn"
              onClick={() => {
                deletemultiple();
              }}
            >
              Yes
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
