export const filterData = (columns, data, searchText) => {
  if (searchText?.trim() === '') {
    return data;
  }
  const normalizedSearchText = searchText?.toLowerCase();

  return data?.filter((item) => {
    return columns?.some((column) => {
      const cellValue = item[column.id]?.toString()?.toLowerCase();
      return cellValue?.includes(normalizedSearchText) || false;
    });
  });
};
