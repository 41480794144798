import React, { useEffect, useState } from "react";
import cx from "../Portal.module.css";
import { Row, Col, Button } from "react-bootstrap";
import { httpPortal } from "../../Apis/commonApis";
import { useForm } from 'react-hook-form';
import { useHistory } from "react-router-dom";
import { NavLink } from 'react-router-dom';
import swal from "sweetalert";
export default function PortalChangePassword() {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const history = useHistory();
    const [error, setError] = useState("")
    let changePassword = async (postdata) => {
        let client_id = await JSON.parse(localStorage?.getItem("fandfData"))
            let data = JSON.stringify({
                id:`${client_id?._id}`,
                old_password: postdata?.old_password,
                new_password: postdata?.new_password,
                confirm_password:postdata?.confirm_password,
                resetType:"password"
            });
            const res = await httpPortal('reset_password','post', data, 'json');
            if (res.status) {
                    // history.push(`/portal/portal-main`, { replace: false });
                    swal("Success", res.message, "success").then((value) => {
                        history.push(`/portal/portal-main`, { replace: false });
                     });
            }else{
                swal("failed", res.message, "error").then((value) => {
                    return;
                  });
                // setError(res?.message)
            }
    }
    return (
        <div className={`${cx.LoginStart}`}>
            <div className={`container`}>
                <Row>
                    <Col lg={4} md={6} sm={12} className={`m-auto`}>
                    <div className={`${cx.backIcon}`}>
                    <NavLink to="/portal/portal-main"><img src="/images/BackVector.png" alt=""/></NavLink>
                    </div>
                        <div className={`${cx.LoginBox}`}>
                            <img src="/images/Newfooter_Logo.svg" alt="" />
                            <div className={`${cx.formInput}`}>
                                <div className={`${cx.inputEmail}`}>
                                    <label>Old Password</label>
                                    <input className={`form-control`} type="Text" placeholder="*******" 
                                    {...register("old_password", {
                                        required: true,
                                    })}
                                />
                            {errors?.old_password?.type === "required" && (
                                <p className="error_style">This field is required</p>
                            )}
                            </div>

                                <div className={`${cx.inputEmail}`}>
                                    <label> New Password</label>
                                    <input className={`form-control`} type="text" placeholder="*******"
                                        {...register("new_password", {
                                            required: true,
                                        })}
                                    />
                                {errors?.new_password?.type === "required" && (
                                    <p className="error_style">This field is required</p>
                                )}
                                </div>
                                <div className={`${cx.inputEmail}`}>
                                    <label> Confirm Password</label>
                                    <input className={`form-control`} type="text" placeholder="*******"
                                        {...register("confirm_password", {
                                            required: true,
                                        })}
                                    />
                                {errors?.confirm_password?.type === "required" && (
                                    <p className="error_style">This field is required</p>
                                )}
                               <p className="error_style">{error}</p>
                                </div>
                            </div>
                            <div className={`${cx.continueButton}`}>
                                <NavLink to="#" onClick={handleSubmit(changePassword)}>Continue</NavLink>
                            </div>
                            {/* <p>It seems that this email is either not correct or not yet registered to use the Friends & Family App. Kindly recheck email or contact your care service provider to activate your profile.</p> */}
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
