import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Fragment } from "react";
import datechangeHandler from "../../utils/datechangehandler";
import { currentCareSiteId } from "../../redux-toolkit/reducer/switchSites";
import { useDispatch, useSelector } from "react-redux";
import { getClientbyId } from "../../redux-toolkit/reducer/carePlanReducer";
import { httpAdmin } from "../../Apis/commonApis";
import axios from 'axios';

const TypesData = [
  {
    value: 1,
    Ctg: "Personal Care",
    image: `/images/s1.svg`,
  },
  {
    value: 2,
    Ctg: "Health",
    image: `/images/s2.svg`,
  },
  {
    value: 3,
    Ctg: "Mobility",
    image: `/images/s3.svg`,
  },
  {
    value: 4,
    Ctg: "Diet",
    image: `/images/s4.svg`,
  },
  {
    value: 5,
    Ctg: "Social & Communication",
    image: `/images/s5.svg`,
  },
  {
    value: 6,
    Ctg: "PRN Meds",
    image: `/images/s6.svg`,
  },
  {
    value: 7,
    Ctg: "Housekeeping",
    image: `/images/s7.svg`,
  },
  {
    value: 8,
    Ctg: "Report Incident",
    image: `/images/s8.svg`,
  },
];

function DownloadCarePlan(props) {

  const dispatch = useDispatch();
  const { clientProfileData, downlaodCarePlan } = useSelector((state) => state.carePlanReducer);
  const { currentCareSiteData } = useSelector((state) => state.caresiteDataReducer);

  const param = useParams();

  const [companyLogo, setCompanyLogo] = useState('');

  const location = useLocation();
  const clientId = location.state?.clientId;

  const userData = JSON.parse(localStorage.getItem("userData"));
  const currentCareHome =
    userData?.subscriptionPlanData &&
    userData?.subscriptionPlanData.find(
      (carehome) => carehome._id === currentCareSiteId
    );

  useEffect(() => {
    // console.log(downlaodCarePlan, 'downlaodCarePlan')
    dispatch(getClientbyId({ clientId: clientId, currentCareSiteId }));
  }, []);

  useEffect(() => {
    if (currentCareSiteData?.logo) {
      converToBase64(`${currentCareSiteData?.logo}`);
      console.log(currentCareSiteData?.logo, 'client imgggggggg');
      // imageUrlToBase64(clientProfileData?.image).then((base64Image) => {
      //     setClientImage(base64Image);
      //     console.log('Base64 Image:');
      //   })
      //   .catch((error) => {
      //     // Handle the error appropriately
      //   });
    }
  }, [currentCareSiteData]);

  const converToBase64 = async (url) => {
    const body = { url }
    // const response = await axios.get(url, { responseType: 'arraybuffer' });
    // const imageData = Buffer.from(response.data).toString('base64');
    // setCompanyLogo(imageData);
    const res = await httpAdmin(`convertImageUrltoBase64`, 'post', body, null);
    if (res.status) {
      setCompanyLogo(res.data);
    }
  }

  return (
    <div id="pagetoDownload" ref={props.divRef} className="position-relative">
      <div className="container mt-3">
        <div className="row align-items-center mb-4">
          <div className="col-md-6 mb-3">
            <img
              alt=""
              src={`data:image/png;base64,${companyLogo}` ?? `/images/companyLogo.svg`}
              style={{ height: companyLogo ? "35px" : '40px' }}
            />
          </div>
          <div className="col-md-6 text-end mb-1">
            <p className="mb-0">
              Care Plan &nbsp;
              {currentCareHome?.careSiteName}
            </p>
          </div>
          <div className="col-md-12 textfont">
            <p>Date: {datechangeHandler(clientProfileData?.createdAt)}</p>
          </div>
          <div className="col-md-12 textfont">
            <p>Created By: {`${userData.firstName} ${userData.lastName}`} </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mb-2">
            <h5 className="fontSize">Client Details</h5>
          </div>
          <div className="col-md-5 col-lg-5 col-xl-5 col-sm-12">
            <ul className="details_list">
              <li>
                <strong>First Name</strong>
                <span>{clientProfileData?.first_Name?.trim()}</span>
              </li>
              <li>
                <strong>Last Name</strong>
                <span>{clientProfileData?.last_Name?.trim()}</span>
              </li>
              <li>
                <strong>Contract Start Date</strong>
                <span>{clientProfileData?.contractStartdate}</span>
              </li>
              <li>
                <strong>Location</strong>
                <span>{clientProfileData?.location}</span>
              </li>
              <li>
                <strong>Date of Birth</strong>
                <span>{clientProfileData?.DOB}</span>
              </li>
              <li>
                <strong>Profession</strong>
                <span>{clientProfileData?.profession}</span>
              </li>
              <li>
                <strong>Marital Status</strong>
                <span>{clientProfileData?.marital_Status}</span>
              </li>
              <li>
                <strong>Citizenship</strong>
                <span>{clientProfileData?.citizenship}</span>
              </li>
            </ul>
          </div>
          <div className="col-lg-1"></div>
          <div className="col-md-4 col-lg-4 col-xl-4 col-sm-12">
            <ul className="details_list">
              <li>
                <strong>Religion</strong>
                <span>{clientProfileData?.religion}</span>
              </li>
              <li>
                <strong>Ethnicity</strong>
                <span>{clientProfileData?.ethnicity}</span>
              </li>
              <li>
                <strong>Languages</strong>
                <span>{clientProfileData?.language}</span>
              </li>
              <li>
                <strong>Height</strong>
                <span>{clientProfileData?.height}</span>
              </li>
              <li>
                <strong>Eye Color</strong>
                <span>{clientProfileData?.eye_Color}</span>
              </li>
              <li>
                <strong>Hair Color</strong>
                <span>{clientProfileData?.hair_Color}</span>
              </li>
              <li>
                <strong>Distinguishing Mark</strong>
                <span>{clientProfileData?.distinguishing_Mark}</span>
              </li>
              <li>
                <strong>Blood Type</strong>
                <span>{clientProfileData?.bloodGroup}</span>
              </li>
            </ul>
          </div>
          <div className="col-md-2">
            <div className="profile_img">
              <img src={clientProfileData?.image} alt="client" />
            </div>
          </div>

          <div className="col-md-12 mb-2 mt-5">
            <h5 className="fontSize">Consent</h5>
          </div>
          <div className="col-md-5 col-lg-5 col-xl-5 col-sm-12">
            <ul className="details_list">
              <li>
                <strong>Mental Capacity to Consent</strong>
                <span>{clientProfileData?.consentMentalCapacity}</span>
              </li>
              <li>
                <strong>Power of Attorney in place</strong>
                <span>{clientProfileData?.consentPOA}</span>
              </li>
              <li>
                <strong>Authorized Person</strong>
                {clientProfileData?.authrisedPerson
                  ?.map(({ name }) => name)
                  ?.join(",")}
              </li>
              <li>
                <strong>Advanced Directive</strong>
                <span>{clientProfileData?.advance_Directive}</span>
              </li>
            </ul>
          </div>

          {clientProfileData?.medical_Conditions?.length > 0 && (
            <Fragment>
              <div className="col-md-12 mb-2 mt-5">
                <h5 className="fontSize">Medical Condition & Allergies</h5>
              </div>
              <div className="col-md-12">
                <table className="popupTbleList table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Type</th>
                      <th>Start Date</th>
                      <th>Diagnosed by</th>
                    </tr>
                  </thead>
                  <tbody>
                    {clientProfileData?.medical_Conditions?.map((condition) => {
                      return (
                        <tr key={condition?._id}>
                          <td>{condition?.name}</td>
                          <td>{condition?.type}</td>
                          <td>{condition?.start_Date}</td>
                          <td>{condition?.diagnosedBy}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Fragment>
          )}

          {clientProfileData?.medication?.length > 0 && (
            <Fragment>
              <div className="col-md-12 mb-3 mt-5">
                <h5 className="fontSize">Medicines</h5>
              </div>
              <div className="col-md-12">
                <table className="popupTbleList table careTemplateTable">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>PRN</th>
                      <th>Start Date</th>
                      <th>Dosage</th>
                      <th>Frequency</th>
                      <th>Time</th>
                      <th>Meal Consideration</th>
                    </tr>
                  </thead>
                  <tbody>
                    {clientProfileData?.medication?.map((medic) => {
                      return (
                        <tr key={medic?._id}>
                          <td>{medic?.NM}</td>
                          <td>{medic?.prn}</td>
                          <td>{medic?.startDate?.split("T")[0]}</td>
                          <td>
                            {medic?.strengthDose} {medic?.doseAmount}
                          </td>
                          <td>{medic?.frequency}</td>
                          <td>{medic?.schedule}</td>
                          <td>{medic?.mealConsideration}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Fragment>
          )}

          <div className="col-md-12 mb-3 mt-5">
            <h5 className="fontSize">Brief Background</h5>
          </div>
          <div className="col-md-12">
            <div className="row cont_list">
              <div className="col">
                <strong className="fontSize">{clientProfileData?.brief}</strong>
              </div>
            </div>
          </div>
        </div>

        {downlaodCarePlan?.map((categoryplan) => {
          let imageCategoryProfile = TypesData?.find(
            (item) => item.Ctg === categoryplan?.categoryType
          )?.image;
          return (
            <>
              {categoryplan.template.length > 0 && (
                <Fragment>
                  <div className="row">
                    <div className="col-md-12 mb-2">
                      <h5 className="text-dark fontSize">
                        <img
                          className="me-2"
                          alt=""
                          src={imageCategoryProfile}
                        />
                        {categoryplan?.categoryType}
                      </h5>
                    </div>
                    {/* <div className="col-md-12 left">
                      <ul className="catList mb-3 justify-content-end">
                        <h6 className="mb-0">Category Risk Profile: </h6>
                        {categoryplan?.riskProfileCategory && (
                          <li
                            style={{
                              backgroundColor:
                                categoryplan?.riskProfileCategory === "high"
                                  ? "#BE0C0C"
                                  : categoryplan?.riskProfileCategory ===
                                    "medium"
                                    ? "#D96A07"
                                    : "#7D7D7D",
                            }}
                          >
                            {categoryplan?.riskProfileCategory === "high"
                              ? "High"
                              : categoryplan?.riskProfileCategory === "medium"
                                ? "Medium"
                                : "Low"}{" "}
                            <br /> Risk
                          </li>
                        )}
                      </ul>
                    </div> */}

                    {categoryplan.template?.map((cplan) => {
                      return (
                        <Fragment>
                          <hr></hr>
                          <h5 className="fontSize mt-3">
                            {" "}
                            {cplan?.TemplateName}
                          </h5>
                          <ul className="catList mb-3 p-2 mt-3 justify-content-start align-items-center">
                            <h6 className="mb-0">Risk Profile: </h6>
                            {cplan?.risk_profile && (
                              <li
                                style={{
                                  backgroundColor:
                                    cplan?.risk_profile === "high"
                                      ? "#BE0C0C"
                                      : cplan?.risk_profile === "medium"
                                        ? "#D96A07"
                                        : "#7D7D7D",
                                }}
                              >
                                {cplan?.risk_profile === "high"
                                  ? "High"
                                  : cplan?.risk_profile === "medium"
                                    ? "Medium"
                                    : "Low"}{" "}
                                <br /> Risk
                              </li>
                            )}
                          </ul>
                          <p className="fontsmallText">
                            Updated:{" "}
                            {datechangeHandler(
                              cplan?.last_update_date?.split("T")[0]
                            )}
                          </p>

                          <div className="col-md-8 right">
                            <div className="row">
                              <div className="col-md-12">
                                <h5 className="fontSize">
                                  Observations, Needs and Comments
                                </h5>
                                <p className="fontSize">
                                  {cplan?.obeservation_needs_comments}
                                </p>
                                <h5 className="fontSize mt-5">Outcomes</h5>
                                <p className="fontSize">{cplan?.outcomes}</p>
                              </div>

                              <div className="col-md-12 mb-3">
                                <div className="d-flex fontSize">
                                  <div className={`col-md-4`}>
                                    <h5 className="fontSize">Task</h5>
                                  </div>
                                  <div className="col-4 ms-3">
                                    <h5 className="fontSize">Description</h5>
                                  </div>
                                  <div className="col-4 ms-3">
                                    <h5 className="fontSize"> Specific Time</h5>
                                  </div>
                                </div>

                                {cplan?.Task?.map((item, index) => {
                                  return (
                                    <div key={index} className="d-flex fontSize">
                                      <div className="col-4">
                                        <span>{item?.taskType}</span>
                                      </div>
                                      <div className="col-4 ms-3">
                                        <span>{item?.comments === '' ? '-' : item?.comments}</span>
                                      </div>
                                      <div className="col-4 ms-3">
                                        <span>{item?.shiftType === "specific time" ? item.startTime : 'No'}</span>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>

                              <div className="col-md-12 mb-3">
                                <div className="d-flex fontSize">
                                  <div className={`col-md-4`}>
                                    <h5 className="fontSize">Risks</h5>
                                  </div>
                                  <div className={`col-md-4 ms-3`}>
                                    <h5 className="fontSize"></h5>
                                  </div>
                                  <div className="col-4 ms-3">
                                    <h5 className="fontSize">Interventions</h5>
                                  </div>
                                </div>

                                {cplan?.risk_interventions?.map((item, index) => {
                                  return (
                                    <div key={index} className="d-flex fontSize">
                                      <div className="col-4">
                                        <span>{item?.risk}</span>
                                      </div>
                                      <div className="col-4 ms-3">
                                        <span></span>
                                      </div>
                                      <div className="col-4 ms-3">
                                        <span>{item?.interventions}</span>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </Fragment>
                      );
                    })}
                  </div>
                </Fragment>
              )}
            </>
          );
        })}
      </div>
    </div>
  );
}

export default DownloadCarePlan;

// async function imageUrlToBase64(url) {
//   try {
//     const response = await fetch(url);

//     if (!response.ok) {
//       throw new Error(`Failed to fetch image: ${response.statusText}`);
//     }

//     const blob = await response.blob();

//     return new Promise((resolve, reject) => {
//       const reader = new FileReader();
//       reader.onloadend = () => resolve(reader.result);
//       reader.onerror = reject;
//       reader.readAsDataURL(blob);
//     });
//   } catch (error) {
//     console.error('Error converting image to base64:', error);
//     throw error;
//   }
// }