import swal from "sweetalert";

export const ShowSwal = (status, msg) => {
    swal(
        status ? "Success" : "Failed",
        msg,
        status ? "success" : "error"
    );
};

export const ShowInfoSwal = (msg) => {
    swal(
        "Info",
        msg,
        "info"
    );
};