export default function paramsObject(searchString) {
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      // each 'entry' is a [key, value] tupple
      result[key] = value;
    }
    return result;
  }
  const urlParams = new URLSearchParams(searchString);
  const entries = urlParams.entries(); //returns an iterator of decoded [key,value] tuples
  return paramsToObject(entries); //{abc:"foo",def:"[asf]",xyz:"5"}
}
