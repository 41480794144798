import React, { useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import axios from "axios";
import Moment from "react-moment";
import { Fragment } from "react";
import { MdArrowRight } from "react-icons/md";
import DatePicker from "react-datepicker";
import cx from "../Portal.module.css";
import "react-datepicker/dist/react-datepicker.css";
import { sortByDateDescend } from "../../Apis/commonApis";
import { combineDateTime, formatDate, getFullName, getMondayDate, getMonthAgoDate } from "../../utils/CommonFunctions";
import JournalDetails from "./JournalDetails";

const calendarStrings = {
    lastDay: "[Yesterday ]",
    sameDay: "[Today ]",
    nextDay: "[Tomorrow ]",
    lastWeek: "L",
    nextWeek: "L",
    sameElse: "L",
};

const ClientJournalPortal = (props) => {
    const { clientImg } = props;

    const [journalData, setJournalData] = useState([]);
    const [clientJournal, setClientJournal] = useState({});
    const [loading, setLoading] = useState(false);
    const [refreshData, setRefreshData] = useState(0);

    const [filterType, setFilterType] = useState("custom");
    const [dateRange, setDateRange] = useState([getMonthAgoDate(), new Date()]);
    const [startDate, endDate] = dateRange;

    useEffect(() => {
        if (filterType === "custom" && endDate) {
            getJournalbyClientId(formatDate(startDate), formatDate(endDate));
        } else if (filterType === "week") {
            let mondayDate = getMondayDate();
            getJournalbyClientId(mondayDate, formatDate(new Date()));
        } else if (filterType === "today") {
            getJournalbyClientId(formatDate(new Date()), formatDate(new Date()));
        }
    }, [filterType, dateRange, refreshData]);

    const getJournalbyClientId = (start, end) => {
        let client_id = localStorage.getItem("fandfData");
        client_id = JSON.parse(client_id)
        setLoading(true);
        axios({
            url: `${process.env.REACT_APP_fANDF}/journal?clientId=${client_id?.clientId}&careHomeId=${client_id.careHomeId}&startDate=${start}&endDate=${end}`,
            method: "GET",
            headers: { Authorization: localStorage.getItem("fandfam_token") },
        }).then((res) => {
            setLoading(false);
            const journalData = res?.data?.result;
            let clientJournals = [];
            if (journalData?.length > 0) {
                journalData?.forEach((shift) => {
                    if (shift.carefacility === 'facalities') {
                        shift.assignedCareTeam?.forEach((assignedData) => {
                            clientJournals.push({
                                _shiftId: shift?._id,
                                _id: assignedData?._id,
                                Date: shift?.Date,
                                shiftDateTime: combineDateTime(shift.Date, shift.startingTime, shift.timeZone),
                                endDate: shift?.endDate,
                                Task: assignedData?.Task,
                                notesData: shift?.notesData,
                                startingTime: shift?.startingTime,
                                endingTime: shift?.endingTime,
                                careTeamId: assignedData?.careTeamId,
                                careTeamMember: getFullName(assignedData?.careTeamId),
                                clientId: assignedData?.clientId?._id ?? assignedData?.clientId,
                                forClient: assignedData?.clientId?._id ? getFullName(assignedData?.clientId) : assignedData?.forClient,
                                checkinDateTime: assignedData?.checkinDateTime,
                                checkoutDateTime: assignedData?.checkoutDateTime,
                                fndfRating: assignedData?.fandfRating ?? 0,
                                shiftEndDatetime: shift?.shiftEndDatetime,
                                status: assignedData?.status,
                                wellbeingAssessment: assignedData?.wellbeingAssessment ?? {},
                                shiftTimeZone: shift?.timeZone
                            });
                        });
                    } else {
                        clientJournals.push({
                            _shiftId: shift?._id,
                            _id: shift?._id,
                            Date: shift?.Date,
                            shiftDateTime: combineDateTime(shift.Date, shift.startingTime, shift.timeZone),
                            endDate: shift?.endDate,
                            Task: shift?.Task,
                            notesData: shift?.notesData,
                            forClient: shift?.forClient,
                            startingTime: shift?.startingTime,
                            endingTime: shift?.endingTime,
                            careTeamId: shift?.careTeamId,
                            careTeamMember: shift?.careTeamMember,
                            checkinDateTime: shift?.checkinDateTime,
                            checkoutDateTime: shift?.checkoutDateTime,
                            fndfRating: shift?.fandfRating ? shift?.fandfRating : 0,
                            clientId: shift?.clientId?._id ?? shift?.clientId,
                            forClient: shift?.clientId?._id ? getFullName(shift?.clientId) : shift?.forClient,
                            shiftApprovalstatus: shift?.shiftApprovalstatus,
                            shiftEndDatetime: shift?.shiftEndDatetime,
                            status: shift?.status,
                            templateType: shift?.templateType,
                            wellbeingAssessment: shift?.wellbeingAssessment ?? {},
                        });
                    }
                });

                let sortedObj = sortByDateDescend(clientJournals, 'shiftDateTime');
                let groupdate = groupJournalDataByDate(sortedObj);

                setJournalData(groupdate);
                if (Object.keys(clientJournal)?.length === 0) {
                    setClientJournal(sortedObj[0]);
                } else {
                    let selctedShift = sortedObj?.find(item => item._shiftId === clientJournal._shiftId);
                    if (selctedShift) {
                        setClientJournal(selctedShift);
                    } else {
                        setClientJournal(sortedObj[0]);
                    }
                }
            } else {
                setJournalData([]);
                setClientJournal({});
            }
        })
            .catch((error) => console.log(`Error: ${error}`));
    };

    function customDateChangeHandler(daterange) {
        setDateRange(daterange);
    }

    const activeTab = {
        background: "#f0e8f7",
    };

    return (
        <>
            <div className={`row clientList ${cx.careJournalSection}`}>
                <h5>Shifts History</h5>
                <div className="shiftsHistory">
                    <ul className="filter_box me-2 mb-1">
                        <NavLink
                            exact
                            activeClassName={filterType === "today" ? "active" : ""}
                            to="#"
                            onClick={() => setFilterType("today")}
                        >
                            Today
                        </NavLink>
                        <NavLink
                            activeClassName={filterType === "week" ? "active " : ""}
                            to="#"
                            onClick={() => setFilterType("week")}
                        >
                            This Week
                        </NavLink>
                        <NavLink
                            activeClassName={filterType === "custom" ? "active" : ""}
                            to="#"
                            onClick={() => setFilterType("custom")}
                        >
                            Custom
                        </NavLink>
                        {filterType === "custom" && (
                            <div className="datepicker_input">
                                <DatePicker
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={(update) => {
                                        customDateChangeHandler(update);
                                    }}
                                    isClearable={true}
                                />
                            </div>
                        )}
                    </ul>
                </div>
                <div className="col-md-6 journalListScroll">
                    <ul className={`menuList ${cx.careBox}`}>

                        {loading && <p>Loading...</p>}

                        {journalData?.length > 0 &&
                            journalData?.map((journal) => {
                                // const date = Object.keys(journal);
                                // const datewisejournal = Object.values(journal).flatMap(
                                //   (num) => num
                                // );
                                return (
                                    <Fragment>
                                        <h5 className="titlen">
                                            <span style={{ color: "#BB6BD9" }}>
                                                <Moment calendar={calendarStrings}>{journal.date}</Moment>
                                            </span>{" "}
                                            <br />
                                            <Moment format="Do MMM YYYY">{journal.date}</Moment>
                                        </h5>
                                        {journal?.shifts?.map((item, index) => {
                                            return (
                                                <li style={item._id === clientJournal._id ? activeTab : {}}
                                                    key={index}
                                                    onClick={() => { setClientJournal(item); }}
                                                >
                                                    <NavLink to="#" className='mb-0'>
                                                        {/* <img alt="" src={item?.careTeamId[0]?.image} /> */}
                                                        <div className="body">
                                                            <p style={{ color: "#BB6BD9" }}>
                                                                {item?.startingTime} - {item?.endingTime}
                                                            </p>
                                                            <p>Shift Summary</p>
                                                            <MdArrowRight className="arrowIcon" />
                                                        </div>
                                                    </NavLink>
                                                </li>
                                            );
                                        })}
                                    </Fragment>
                                );
                            })}
                        {loading === false && journalData?.length === 0 && (
                            <p className="notAvailable">No Shift Available</p>
                        )}
                    </ul>
                </div>
                {Object.keys(clientJournal)?.length !== 0 && (
                    <JournalDetails clientJournal={clientJournal} setRefreshData={setRefreshData} clientImg={clientImg} />
                )}
            </div>
        </>
    );
};

export default ClientJournalPortal;

function groupJournalDataByDate(shiftdata) {
    if (shiftdata?.length < 1) {
        return [];
    }
    const grouped = {};
    shiftdata?.forEach((item) => {
        const dateLabel = new Date(item.Date).toDateString();

        if (!grouped[dateLabel]) {
            grouped[dateLabel] = [];
        }
        grouped[dateLabel].push(item);
    });

    return Object.keys(grouped).map((date) => ({
        date,
        shifts: grouped[date],
    }));
}