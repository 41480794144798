import React, { Component, Fragment } from "react";
import Scheduler, { SchedulerData, ViewTypes } from "react-big-scheduler";
import WithDragDropContext from "./withDnDContext";
// import "./scheduleMed.css";
import axios from "axios";
import { Col, Row } from "react-bootstrap";
import { Redirect } from "react-router";
import "react-big-scheduler/lib/css/style.css";
import "moment/locale/en-gb";
import { combineDateTime, convertToTimeZone, getCurrentTimeZone, getFullName, getWeekStartAndEndDates, handleDataPrint } from "../../../utils/CommonFunctions";
import { httpAdmin } from "../../../Apis/commonApis";
import { currentCareSiteId } from "../../../redux-toolkit/reducer/switchSites";
import { AiFillPrinter } from "react-icons/ai";
import JsonDownload from "../../../components/DownloadExcel/DownloadExcel";

class Basic extends Component {
  schedulerData;
  constructor(props) {
    super(props);
    //let schedulerData = new SchedulerData(new moment("2017-12-18").format(DATE_FORMAT), ViewTypes.Week);
    this.schedulerData = new SchedulerData(
      new Date(),
      ViewTypes.Week,
      false,
      false,
      {
        schedulerWidth: "87%",
        marginTop: "-36px",
        besidesWidth: 0,
        defaultEventBgColor: "#808080",
        resourceName: "Name",
        movable: true,
        creatable: true,
        crossResourceMove: false,
        eventItemPopoverEnabled: true,
        calendarPopoverEnabled: true,
        dayResourceTableWidth: 180,
        weekResourceTableWidth: 180,
        eventItemHeight: 35,
        eventItemLineHeight: 40,
        schedulerMaxHeight: 380,
        selectedAreaColor: "#808080",
        recurringEventsEnabled: true,
        headerEnabled: true,
        displayWeekend: true,
        nonWorkingTimeHeadColor: "grey",
        nonWorkingTimeHeadBgColor: "white",
        nonWorkingTimeBodyBgColor: "white",

        groupOnlySlotColor: "#808080.",
        startResizable: true,
        // customCellWidth: 80,
        dayCellWidth: "5%",
        endResizable: true,
        weekCellWidth: "12%",
        views: [
          {
            viewName: "Day",
            viewType: ViewTypes.Day,
            showAgenda: false,
            isEventPerspective: false,
          },
          {
            viewName: "Week",
            viewType: ViewTypes.Week,
            showAgenda: false,
            isEventPerspective: false,
          },
          {
            viewName: "Month",
            viewType: ViewTypes.Month,
            showAgenda: false,
            isEventPerspective: false,
          },
        ],
      }
    );

    this.schedulerData.localeMoment.locale("en-gb");

    this.state = {
      viewModel: this.schedulerData,
      resourceArr: [],
      EventsArr: [],
      downloadData: [],
      showClientEditShiftTaskUI: false,
      formtemplate: false,
      isDisplayed: false,
      redirect: false,
      clientId: "",
      genericTem: false,
      clientSide: false,
      EditShiftData: [],
    };
  }

  componentDidMount() {
    let weekDates = getWeekStartAndEndDates(new Date());
    this.getPayrollCalenderData(weekDates.start, weekDates.end);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.text !== this.props?.text) {
      let filteredVal = this.state.resourceArr?.filter((elem) => {
        return elem.name.toLowerCase().includes(this.props.text.toLowerCase());
      });
      this.schedulerData.setResources(filteredVal);
      this.forceUpdate(); // Force a re-render of the component
    }
  }

  getPayrollCalenderData = async (start, end) => {
    const result = await httpAdmin(`getPayrollCalender?startDate=${start}&endDate=${end}&careHomeId=${currentCareSiteId}`, 'get', null, null);
    if (result.status) {
      let eventData = [];
      let resourceData = [];
      let downloadData = [];
      result.data?.forEach((elem) => {
        const clientname = getFullName(elem);
        elem.shiftsHistory?.forEach((item, index) => {
          let bgColor = "#2D9CDB";
          if (item.regularHours === null) {
            bgColor = "#F2C94C";
          }
          // const checkInTime = convertToTimeZoneFullTime(item?.checkinDateTime);
          // const checkOutTime = convertToTimeZoneFullTime(item?.checkoutDateTime);
          const hrsWorked = this.getDifferenceInHrsAndMin(item.checkinDateTime, item.checkoutDateTime, +item.shiftBreak);
          eventData.push({
            shiftId: item._id,
            ShiftData: item,
            editShiftId: item._id,
            id: index,
            start: item?.checkinDateTime,
            end: item?.checkoutDateTime,
            resourceId: elem._id,
            shiftBreak: item.shiftBreak,
            hoursWorked: `${hrsWorked?.hours}hrs ${hrsWorked?.minutes}min ${hrsWorked?.seconds}secs`,
            title: item?.clientName ?? clientname,
            bgColor: bgColor,
          });
          downloadData.push({
            ['Careteam']: getFullName(elem),
            //  `${elem.first_Name} ${elem.last_Name}`,
            ['Start time']: item?.checkinDateTime,
            ['End time']: item?.checkoutDateTime,
            ['Hrs Worked']: `${hrsWorked?.hours}hrs ${hrsWorked?.minutes}min ${hrsWorked?.seconds}secs`,
            ['client']: item?.clientName,
          });
        });
        elem.declinedArray?.forEach((item, index) => {
          eventData.push({
            shiftId: item.shiftId,
            ShiftData: item,
            editShiftId: item.shiftId,
            id: `declined-${index}`,
            start: combineDateTime(item.Date, item?.startingTime, getCurrentTimeZone()),
            end: combineDateTime(item.Date, item?.endingTime, getCurrentTimeZone()),
            resourceId: elem._id,
            shiftBreak: item.shiftBreak,
            hoursWorked: `0.00`,
            title: item?.clientName ?? clientname,
            bgColor: '#EB5757',
          });
          downloadData.push({
            ['Careteam']: getFullName(elem),
            //  `${elem.first_Name} ${elem.last_Name}`,
            ['Start time']: item?.startingTime,
            ['End time']: item?.endingTime,
            ['Hrs Worked']: `0.00`,
            ['client']: item?.clientName,
          });
        });
        resourceData.push({
          id: elem._id,
          name: getFullName(elem),
          // `${elem.first_Name} ${elem.last_Name}`,
          image: elem.image,
        });
      });
      // const jsonObject = resources.map(JSON.stringify);
      // const uniqueSet = new Set(jsonObject);
      // const uniqueArray = Array.from(uniqueSet).map(JSON.parse);
      this.schedulerData.setResources(resourceData);
      this.schedulerData.setEvents(eventData);
      this.setState((prevState) => ({
        ...prevState,
        EventsArr: eventData,
        resourceArr: resourceData,
        downloadData: downloadData,
      }));
    }
  };

  getDifferenceInHrsAndMin(date1, date2, breakTime) {
    const startDate = new Date(date1);
    const endDate = new Date(date2);
    const timeDifferenceInMilliseconds = Math.abs(endDate - startDate) - (breakTime > 0 ? (+breakTime * 1000 * 60) : 0);

    if (timeDifferenceInMilliseconds > 0) {
      const hours = Math.floor(timeDifferenceInMilliseconds / (1000 * 60 * 60));
      const minutes = Math.floor((timeDifferenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDifferenceInMilliseconds % (1000 * 60)) / 1000);
      return { hours, minutes, seconds };
    }else{
      return { hours: 0, minutes: 0, seconds: 0 };
    }
  }

  render() {
    const { viewModel, redirect, clientId, downloadData } = this.state;

    if (redirect) {
      return (
        <Redirect
          to={{
            pathname: "/admin/clientweekschedule",
            state: { referrer: true, clientId: clientId },
          }}
        />
      );
    }
    return (
      <Fragment>
        <div>
          <AiFillPrinter
            className="me-3 ms-1 curser_point"
            onClick={() => handleDataPrint(downloadData)}
          />
          <a className="curser_point">
            <JsonDownload
              fileName="payroll-excel"
              downloadfile={downloadData}
            />
          </a>
        </div>
        <div className="mt-4">
          <Scheduler
            schedulerData={viewModel}
            prevClick={this.prevClick}
            nextClick={this.nextClick}
            onSelectDate={this.onSelectDate}
            onViewChange={this.onViewChange}
            eventItemClick={this.eventClicked}
            // updateEventStart={this.updateEventStart}
            // updateEventEnd={this.updateEventEnd}
            moveEvent={this.moveEvent}
            newEvent={this.newEvent}
            onScrollLeft={this.onScrollLeft}
            onScrollRight={this.onScrollRight}
            toggleExpandFunc={this.toggleExpandFunc}
            eventItemPopoverTemplateResolver={
              this.eventItemPopoverTemplateResolver
            }
            eventItemTemplateResolver={this.eventItemTemplateResolver}
            slotItemTemplateResolver={this.slotItemTemplateResolver}
            movingEvent={this.movingEvent}
          />
        </div>
      </Fragment>
    );
  }

  moveEvent = (schedulerData, event, slotId, slotName, start, end) => {
    let date = start.split(" ")[0];
    schedulerData.moveEvent(event, slotId, slotName, start, end);
    this.setState({
      viewModel: schedulerData,
    });

    var shiftType = [];
    var startTime = [];
    var endTime = [];
    var taskName = [];
    var taskType = [];
    event.ShiftData?.Task?.forEach((items, index) => {
      shiftType.push(items.shiftType);
      startTime.push(items.startTime);
      endTime.push(items.endTime);
      taskName.push(items.taskName);
      taskType.push(items.taskType);
    });

    if (shiftType.length > 1) {
      var type = "array";
    } else {
      var type = "string";
    }

    var data = new FormData();
    data.append("careHomeId", event.ShiftData.careHomeId);
    data.append("careTeamId", event.ShiftData.careTeamId._id);
    data.append("shiftId", event.ShiftData._id);
    data.append("clientId", event.ShiftData.clientId._id);
    data.append(
      "forClient",
      event.ShiftData.forClient
        .split(" ")
        .filter((s) => s)
        .join(" ")
    );
    data.append(
      "careTeamMember",
      event.ShiftData.careTeamMember
        .split(" ")
        .filter((s) => s)
        .join(" ")
    );
    data.append("Date", date);
    data.append("startingTime", event.ShiftData.startingTime);
    data.append("endingTime", event.ShiftData.endingTime);
    data.append("recurringTask", event.ShiftData.recurringTask);
    data.append("recurringTasktype", event.ShiftData.recurringTasktype);
    data.append("instruction", event.ShiftData.instruction);
    data.append("recurringShiftType", event.ShiftData.recurringShiftType);
    data.append(
      "attachments",
      event.ShiftData.image ? event.ShiftData.image : ""
    );
    data.append("type", type);
    data.append("endDate", event.ShiftData.endDate);
    data.append("shiftType", shiftType);
    data.append("startTime", startTime);
    data.append("endTime", endTime);
    data.append("taskName", taskName);
    data.append("taskType", taskType);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/editShift`,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        window.location.reload(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  eventItemPopoverTemplateResolver = (
    schedulerData,
    eventItem,
    title,
    start,
    end,
    statusColor
  ) => {
    const hrsWorked = this.getDifferenceInHrsAndMin(eventItem.start, eventItem.end, eventItem.shiftBreak);
    console.log(new Date(eventItem.start), new Date(eventItem.end), hrsWorked, 'hrsWorked...');
    return (
      <div style={{ width: "180px", height: "150px" }}>
        <div className="d-flex justify-content-center mb-2">
          <div className="status-dot" style={{ backgroundColor: eventItem.bgColor }} />
          <span className="header2-text ms-2" title="Client Name">
            {eventItem.title}
          </span>
        </div>
        <Row type="flex" align="middle">
          <Col span={100} style={{ lineHeight: "15px" }}>
            <p>Start Time: {convertToTimeZone(eventItem.start)}</p>
            <p>End Time: {convertToTimeZone(eventItem.end)}</p>
            <p>Break Time: {eventItem.shiftBreak ?? '-'}</p>
            <p>Hours Worked: {eventItem.hoursWorked}</p>
            {/*<p>End Time: {end}</p> */}
          </Col>
        </Row>
      </div>
    );
  };

  slotItemTemplateResolver(
    schedulerData,
    slot,
    slotClickedFunc,
    width,
    clsName
  ) {
    let resourceData = schedulerData.getSlotById(slot.slotId);
    const chatUserImg = '/images/chatUser.svg';
    let dp = resourceData.image;
    const isDefaultImg = resourceData?.image?.includes('chatUser');
    return (
      <span className="schedulerResourse">
        <img className={`profile_img_calendar ${isDefaultImg ? 'forImagecareteam' : ''}`}
          src={`${dp ? dp : chatUserImg}`} />{" "}
        <span>{slot.slotName}</span>
      </span>
    );
  }

  prevClick = (schedulerData) => {
    schedulerData.prev();
    schedulerData.setEvents(this.state.EventsArr);
    this.setState({
      viewModel: schedulerData,
    });
    this.getPayrollCalenderData(schedulerData.startDate, schedulerData.endDate);
  };

  nextClick = (schedulerData) => {
    schedulerData.next();
    schedulerData.setEvents(this.state.EventsArr);
    this.setState({
      viewModel: schedulerData,
    });
    this.getPayrollCalenderData(schedulerData.startDate, schedulerData.endDate);
  };

  onViewChange = (schedulerData, view) => {
    schedulerData.setViewType(
      view.viewType,
      view.showAgenda,
      view.isEventPerspective
    );
    schedulerData.setEvents(this.state.EventsArr);
    this.setState({
      viewModel: schedulerData,
    });
    this.getPayrollCalenderData(schedulerData.startDate, schedulerData.endDate);
  };

  onSelectDate = (schedulerData, date) => {
    schedulerData.setDate(date);
    schedulerData.setEvents(this.state.EventsArr);
    this.setState({
      viewModel: schedulerData,
    });
    this.getPayrollCalenderData(schedulerData.startDate, schedulerData.endDate);
  };

  eventClicked = (schedulerData, event) => {
    schedulerData.setEvents(this.state.EventsArr);
    this.setState({
      viewModel: schedulerData,
    });
  };

  newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
    this.setState({ redirect: false, clientId: slotId });
  };

  updateEventStart = (schedulerData, event, newStart) => {
    schedulerData.updateEventStart(event, newStart);
    this.setState({
      viewModel: schedulerData,
    });
  };

  updateEventEnd = (schedulerData, event, newEnd) => {
    schedulerData.updateEventEnd(event, newEnd);
    this.setState({
      viewModel: schedulerData,
    });
  };

  onScrollRight = (schedulerData, schedulerContent, maxScrollLeft) => {
    if (schedulerData.ViewTypes === ViewTypes.Day) {
      schedulerData.next();
      schedulerData.setEvents(this.state.EventsArr);
      this.setState({
        viewModel: schedulerData,
      });
      schedulerContent.scrollLeft = maxScrollLeft - 10;
    }
  };

  onScrollLeft = (schedulerData, schedulerContent, maxScrollLeft) => {
    if (schedulerData.ViewTypes === ViewTypes.Day) {
      schedulerData.prev();
      schedulerData.setEvents(this.state.EventsArr);
      this.setState({
        viewModel: schedulerData,
      });
      schedulerContent.scrollLeft = 10;
    }
  };

  toggleExpandFunc = (schedulerData, slotId) => {
    schedulerData.toggleExpandStatus(slotId);
    this.setState({ viewModel: schedulerData });
  };
}

export default WithDragDropContext(Basic);