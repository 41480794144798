import React, { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import DataGridTable from "../../utils/DataGridTable";

export default function FriendsFamily({ careSite }) {

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (careSite) {
      getClientbyId();
    }
  }, [careSite]);

  const getClientbyId = () => {
    setLoading(true);
    axios({
      url: `${process.env.REACT_APP_SUPERADMIN_BASEURL}/getFandF?careHomeId=${careSite?.careSiteId}`,
      method: "GET",
      headers: { Authorization: localStorage.getItem("superadmin_token") },
    })
      .then((res) => {
        let keycontactData = res?.data?.data;
        let fandfdata = [];
        keycontactData.forEach((elem) => {
          fandfdata.push({ ...elem.keycontact, mid: elem._id, id: elem._id });
        });
        setRows(fandfdata);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(`Error: ${error}`)
      });
  };

  return (
    <>
      <DataGridTable
        columns={columns}
        rows={rows}
        handleRowSelection={() => { }}
        tableHeight="72vh"
        loading={loading}
      />
    </>
  );
}

const columns = [
  // { field: "id", headerName: "No.", width: 30 },
  {
    field: "name",
    headerName: "Name",
    width: 230,
  },
  { field: "email", headerName: "Email", width: 150 },
  { field: "phone", headerName: "Telephone", width: 150 },
  { field: "creationDate", headerName: "Creation Date", width: 150 },
  { field: "activationDate", headerName: "Activation Date", width: 150 },
  { field: "deactivationDate", headerName: "Deactivation Date", width: 150 },
  { field: "deletionDate", headerName: "Deletion Date", width: 150 },
  {
    field: "isFandFappActive",
    headerName: "Status",
    width: 250,
    renderCell: (params) => <StatusButton params={params} />,
  },
];

const StatusButton = (props) => {
  let { params } = props;

  return (
    <div>
      {params?.row?.isFandFappActive && (
        <button className={`btn btn-sm btn_table btn-success`}>Active</button>
      )}
      {!params?.row?.isFandFappActive && (
        <button className={`btn btn-sm btn_table btn-danger`}>Deactive</button>
      )}
    </div>
  );
};