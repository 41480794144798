import React, { useEffect, useState } from "react";
import cx from "./Portal.module.css";
import { Row, Col, Button } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { useHistory } from "react-router-dom";
import Calendar from 'react-calendar';
import { httpPortal } from "../Apis/commonApis";
import { useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import swal from "sweetalert";
import { timeValues } from "../constants/constants";
export default function RequestServiceCalendar() {
  const history = useHistory();
  const fullDate = new Date();
  const formattedDate = `${fullDate.getDate()}-${fullDate.getMonth()}-${fullDate.getFullYear()}`
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [date, setDate] = useState(formattedDate);
  const [error, setError] = useState("")
  const [endTimeErr, setendTimeError] = useState("")
  const location = useLocation();
  const service = location.state?.service;
  const shiftInstructions = location.state?.shiftInstructions;
  let userData = JSON.parse(localStorage?.getItem("fandfData"));
  const handleStartTimeChange = (event) => {
    setStartTime(event.target.value);
  };
  const handleEndTimeChange = (event) => {
    setEndTime(event.target.value);
  };
  const handelSubmit = (selectedDate) => {
    const fullDate = new Date(selectedDate);
    const formattedDate = `${fullDate.getDate()}-${fullDate.getMonth()}-${fullDate.getFullYear()}`
    setDate(formattedDate)
  }
  const requestService = async () => {
    if (startTime.length < 1) {
      setError("please select");
      return;
    } else if (endTime.length < 1) {
      setendTimeError("please select")
      return;
    }
    const data = {
      fandfName: userData?.email,
      clientId: userData?.clientId,
      taskName: service.join(","),
      instructions: shiftInstructions,
      startTime: startTime,
      endTime: endTime,
      date: date
    };
    const res = await httpPortal('requestService', 'post', data, 'json');
    if (res.status) {
      swal("Success", res.message, "success").then((value) => {
        history.push(`/portal/portal-main`, { replace: false });
      });
      // history.push(`/portal/portal-main`, { replace: false })

    } else {
      swal("failed", res.message, "error").then((value) => {
        return;
      });
      // setError(res?.message)
    }

  }

  return (
    <div className={`${cx.profilePage}`}>
      <div className={`container`}>
        <Row>
          <Col lg={4} md={6} sm={12} className={`m-auto`}>
            <div className={`${cx.ProfileBox} ${cx.CalendarBox}`}>
              <div className={`${cx.topHeadeing}`}>
                <h3>Request Service</h3>
                <div className={`${cx.backIcon}`}>
                  <NavLink to="/portal/requestService"><img src="/images/BackVector.png" alt="" /></NavLink>
                </div>
              </div>
              <div className={`${cx.CalendarBox}`}>
                <Calendar onChange={(selectedDate) => handelSubmit(selectedDate)} />
              </div>

              <div className={`${cx.timeShow}`}>
                <h5>Time</h5>

                <div className={`${cx.timeDropdown}`}>
                  <span>Starting</span>
                  <Form.Select aria-label="Default select example" onChange={handleStartTimeChange}>
                    {timeValues.map((item) => {
                      return (
                        <option key={item.value} value={item.value}>
                          {item.label}
                        </option>
                      );
                    })}
                    {/* </select> */}
                  </Form.Select>
                </div>
                <p className="error_style"> {error}</p>
                <div className={`${cx.timeDropdown}`}>
                  <span>Ending</span>

                  <Form.Select aria-label="Default select example" onChange={handleEndTimeChange}>
                    {timeValues.map((item) => {
                      if (item?.value > startTime) {
                        return (
                          <option key={item.value} value={item.value}>
                            {item.label}
                          </option>
                        );
                      }
                    })}
                  </Form.Select>
                </div>
                <p className="error_style">{endTimeErr}</p>
              </div>
              <Col lg="12">
                <div className={`${cx.nextButton}`}>
                  <button onClick={requestService}>Next</button>
                </div>
              </Col>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}
