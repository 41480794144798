import React, { useState, useEffect } from "react";
import "../Analytics/analytics.css";
import Graphs from "./Graphs";
import { httpSuperAdmin, limitToTwoDecimal } from "../../Apis/commonApis";
import { NavLink } from "react-router-dom";
import "../Analytics/analytics.css";
import DatePicker from "react-datepicker";
import { formatDate } from "../../utils/CommonFunctions";

let today = new Date();
let helperArrDate = [];

function Analytics() {
  const [graphStaticData, setGraphStaticData] = useState(null);
  const [filterType, setFilterType] = useState("today");
  const [weekDayNames, setWeekDayNames] = useState([]);
  const [customDates, setCustomDates] = useState([]);
  const [churnRate, setChurnRate] = useState("");
  const [dailyBasisVals, setDailyBasisVals] = useState({
    newSignUpsArr: [],
    activationsArr: [],
    cancellationsArr: [],
    MRR: [],
    MSCR: [],
    ARPU: [],
  });

  let monthAgoDate = new Date();
  let monthAgo = monthAgoDate.getMonth() - 1;
  monthAgoDate.setMonth(monthAgo);

  const [dateRange, setDateRange] = useState([monthAgoDate, new Date()]);
  const [startDate, endDate] = dateRange;

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getDashboardDetails();
    }
    return () => (mounted = false);
  }, []);

  useEffect(() => {
    if (graphStaticData) {
      graphsFilter();
      churnRateCalculation();
    }
  }, [filterType, graphStaticData]);

  const getDashboardDetails = async () => {
    const result = await httpSuperAdmin("getDashboardDetails", null);
    if (result.status) {
      setGraphStaticData(result.data);
    }
    // filterGraphs(result.data);
  };

  function graphsFilter() {
    // const dailyBasedHz = [0, 4, 8, 12, 16, 20, 24];
    const dummyDayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const weekDayNamesArr = [];
    let weekBasedHz = [];
    let first = new Date().getDate() - 6;
    for (let i = first; i < first + 7; i++) {
      let day = new Date(new Date().setDate(i)).toISOString();
      weekBasedHz.push(day);
    }

    for (let i = 0; i < weekBasedHz.length; i++) {
      let dayCount = new Date(weekBasedHz[i]).getDay();
      weekDayNamesArr.push(dummyDayNames[dayCount]);
    }
    setWeekDayNames(weekDayNamesArr);

    let signupCountArr = [];
    let activationCountArr = [];
    let cancellationsCountArr = [];
    let arpuCountArr = [];
    let mrrCountArr = [];
    let mscrCountArr = [];
    const monthlyData = groupByMonth(graphStaticData?.MRR);
    if (filterType === "today") {

      const signUpLength = graphStaticData?.signUps?.filter(filterByDate(new Date(), "created_at"))?.length;
      signupCountArr = [0, signUpLength];

      const activationsLength = graphStaticData.activations?.filter(filterByDate(new Date(), 'planstartDate'))?.length;
      activationCountArr = [0, activationsLength];

      const cancellationsLength = graphStaticData.cancellations?.filter(filterByDate(new Date(), 'created_at'))?.length;
      cancellationsCountArr = [0, cancellationsLength];

      const month = new Date().getMonth() + 1;
      const year = new Date().getFullYear();
      const key = `${year}-${month}`;
      const mrr = monthlyData?.find(item => item.monthYear === key);
      mrrCountArr = [0, mrr?.totalAmt ?? 0];

      const arpuArr = graphStaticData?.allCharges?.filter(item => item.charge_date === formatDate(new Date()));
      const uniqueIds = new Set();
      arpuArr.forEach(item => uniqueIds.add(item?.carehomedata?._id));
      const arpuCount = arpuArr.reduce((acc, obj) => acc + (+obj.amount ?? 0), 0);
      const arpu = uniqueIds.size > 0 ? (arpuCount / uniqueIds.size) : 0;
      arpuCountArr = [0, limitToTwoDecimal(arpu > 0 ? arpu : 0)];

      let freeClientUpgradLength =
        graphStaticData?.activations?.filter(item => formatDate(item.planstartDate) === formatDate(new Date()))?.length;
      const freeClientLength = graphStaticData?.trialActiveArray?.filter(trialsActiveFilter(new Date()))?.length;
      const mscrLength = freeClientLength > 0 ? ((freeClientUpgradLength / (freeClientUpgradLength + freeClientLength)) * 100) : 0;
      mscrCountArr = [0, limitToTwoDecimal(mscrLength)];

    } else if (filterType === "week") {
      for (let i = 0; i < weekBasedHz.length; i++) {
        const signUpLength = graphStaticData?.signUps?.filter(filterByDate(weekBasedHz[i], "created_at"))?.length;
        signupCountArr.push(signUpLength);

        const activationsLength = graphStaticData.activations?.filter(filterByDate(weekBasedHz?.[i], 'planstartDate'))?.length;
        activationCountArr?.push(activationsLength);

        const cancellationsLength = graphStaticData.cancellations?.filter(filterByDate(weekBasedHz[i], 'created_at'))?.length;
        cancellationsCountArr.push(cancellationsLength);

        const month = new Date(weekBasedHz[i]).getMonth() + 1;
        const year = new Date(weekBasedHz[i]).getFullYear();
        const key = `${year}-${month}`;
        const mrr = monthlyData?.find(item => item.monthYear === key);
        mrrCountArr.push(mrr?.totalAmt ?? 0);

        const arpuArr = graphStaticData?.allCharges?.filter(item => item.charge_date === formatDate(weekBasedHz[i]));
        const uniqueIds = new Set();
        arpuArr.forEach(item => uniqueIds.add(item?.carehomedata?._id));
        const arpuCount = arpuArr.reduce((acc, obj) => acc + (+obj.amount ?? 0), 0);
        const arpu = uniqueIds.size > 0 ? (arpuCount / uniqueIds.size) : 0;
        arpuCountArr.push(limitToTwoDecimal(arpu > 0 ? arpu : 0));

        let freeClientUpgradLength =
          graphStaticData?.activations?.filter(item => formatDate(item.planstartDate) === formatDate(weekBasedHz[i]))?.length;
        const freeClientLength = graphStaticData?.trialActiveArray?.filter(trialsActiveFilter(weekBasedHz[i]))?.length;
        const mscrLength = freeClientLength > 0 ? ((freeClientUpgradLength / (freeClientUpgradLength + freeClientLength)) * 100) : 0;
        mscrCountArr.push(limitToTwoDecimal(mscrLength));
      }
    } else if (filterType === "custom") {
      cutomizeHz();
      for (let i = 0; i < helperArrDate.length; i++) {

        const signUpLength = graphStaticData?.signUps?.filter(filterByDate(helperArrDate[i], "created_at"))?.length;
        signupCountArr.push(signUpLength);

        const activationsLength = graphStaticData.activations?.filter(filterByDate(helperArrDate?.[i], 'planstartDate'))?.length;
        activationCountArr?.push(activationsLength);

        const cancellationsLength = graphStaticData.cancellations?.filter(filterByDate(helperArrDate[i], 'created_at'))?.length;
        cancellationsCountArr.push(cancellationsLength);

        const month = helperArrDate[i].getMonth() + 1;
        const year = helperArrDate[i].getFullYear();
        const key = `${year}-${month}`;
        const mrr = monthlyData?.find(item => item.monthYear === key);
        mrrCountArr.push(mrr?.totalAmt ?? 0);

        const arpuArr = graphStaticData?.allCharges?.filter(item => item.charge_date === formatDate(helperArrDate[i]));
        const uniqueIds = new Set();
        arpuArr.forEach(item => uniqueIds.add(item?.carehomedata?._id));
        const arpuCount = arpuArr.reduce((acc, obj) => acc + (+obj.amount ?? 0), 0);
        const arpu = uniqueIds.size > 0 ? (arpuCount / uniqueIds.size) : 0;
        arpuCountArr.push(limitToTwoDecimal(arpu > 0 ? arpu : 0));

        let freeClientUpgradLength =
          graphStaticData?.activations?.filter(item => formatDate(item.planstartDate) === formatDate(helperArrDate[i]))?.length;
        const freeClientLength = graphStaticData?.trialActiveArray?.filter(trialsActiveFilter(helperArrDate[i]))?.length;
        const mscrLength = freeClientLength > 0 ? ((freeClientUpgradLength / (freeClientUpgradLength + freeClientLength)) * 100) : 0;
        mscrCountArr.push(limitToTwoDecimal(mscrLength));

      }
    }
    setDailyBasisVals({
      ...dailyBasisVals,
      newSignUpsArr: signupCountArr,
      activationsArr: activationCountArr,
      cancellationsArr: cancellationsCountArr,
      ARPU: arpuCountArr,
      MRR: mrrCountArr,
      MSCR: mscrCountArr?.map((item) => item?.toFixed(2)),
    });
  }

  function trialsActiveFilter(dateVal) {
    return function (item) {
      const trialStrt = new Date(item.trial_start).getTime();
      const trialEnd = new Date(item.trial_end).getTime();
      return (
        trialEnd >= new Date(dateVal).getTime() &&
        trialStrt < new Date(dateVal).getTime()
      );
    };
  }

  function cutomizeHzold() {
    helperArrDate = [];
    let customBasedHz = [];
    const getDateDifference = (date1, date2) => {
      const diffTime = Math.abs(date2 - date1);
      return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    };
    let custumInterval = getDateDifference(startDate, endDate);
    custumInterval = Math.ceil(custumInterval / 7) + 1;
    for (let i = 0; i < 7; i++) {
      let finalDate = new Date(startDate);
      if (i > 0) {
        let dayByInterval = helperArrDate[0].getDate() + custumInterval * i;
        finalDate.setDate(dayByInterval);
      }
      helperArrDate.push(finalDate);
      let changeFormat = finalDate
        .toJSON()
        .slice(0, 10)
        .split("-")
        .reverse()
        .join("/");
      // let changeFormat = finalDate.toLocaleString('en-GB', { timeZone: 'UTC' }).slice(0,10);
      customBasedHz.push(changeFormat);
    }
    setCustomDates(customBasedHz);
  }

  function cutomizeHz() {
    helperArrDate = [];
    const customDatesArr = [];
    // let customBasedHz = [];
    const getDateDifference = (date1, date2) => {
      const diffTime = Math.abs(date2 - date1);
      return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    };
    let difference = getDateDifference(startDate, endDate);
    for (let i = 0; i < difference + 1; i++) {
      let finalDate = new Date(startDate);
      if (i > 0) {
        let dayByInterval = new Date(helperArrDate[0]).getDate() + i;
        finalDate.setDate(dayByInterval);
        if (finalDate > endDate) {
          finalDate = new Date(endDate);
        }
      }
      // let formatedDate = formatDateToDDMMYYYY(finalDate);
      helperArrDate.push(finalDate);
      let changedFormat = finalDate.toLocaleDateString("en-GB").slice(0, 5);
      customDatesArr.push(changedFormat);
      if (finalDate > endDate) {
        break;
      }
    }
    setCustomDates(customDatesArr);
  }

  function filterByDate(maxDate, dateKey) {
    return function (item) {
      return (formatDate(item[dateKey]) === formatDate(maxDate)
      );
    };
  }

  function churnRateCalculation() {
    const allChurnRateArr = [];
    graphStaticData?.churnRate?.forEach(elem => {
      const activeNos = elem?.activeSubscriptions > 0 ? elem?.activeSubscriptions : 1;
      const deactiveNos = elem?.deactiveSubscriptions > 0 ? elem?.deactiveSubscriptions : 0;
      const churnCount = (deactiveNos / activeNos) * 100;
      allChurnRateArr.push(churnCount);
    });

    let avgChurnRate = 0;
    if (allChurnRateArr.length > 0) {
      avgChurnRate = allChurnRateArr.reduce((acc, val) => acc + val, 0) / allChurnRateArr.length;
    }
    setChurnRate(avgChurnRate.toFixed(2));
  }

  useEffect(() => {
    if (startDate && endDate) {
      if (graphStaticData) {
        graphsFilter();
      }
    }
  }, [dateRange]);

  function customDateChangeHandler(update) {
    setDateRange(update);
  }
  let roleAccess = JSON.parse(localStorage.getItem("__csadmin__d"));

  return (
    <div className="page-wrapper">
      {roleAccess?.role?.map((roletype) => {
        const dashboardAccess = roletype.Modules[0];
        if (dashboardAccess?.access !== "full")
          return <div className="clickOff"></div>;
      })}

      <div className="container-fluid min_height">
        <div className="row ms-3">
          <div className="col-md-12 d-flex justify-content-end border_top">
            <ul className="filter_box me-4">
              <NavLink
                exact
                activeClassName={filterType === "today" ? "active" : ""}
                to="#"
                onClick={() => setFilterType("today")}
              >
                Today
              </NavLink>
              <NavLink
                activeClassName={filterType === "week" ? "active mx-4" : "mx-4"}
                to="#"
                onClick={() => setFilterType("week")}
              >
                This Week
              </NavLink>
              <NavLink
                activeClassName={filterType === "custom" ? "active" : ""}
                to="#"
                onClick={() => setFilterType("custom")}
              >
                Custom
              </NavLink>
              {filterType === "custom" ? (
                <div>
                  <DatePicker
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    maxDate={today}
                    onChange={(update) => {
                      customDateChangeHandler(update);
                    }}
                    isClearable={true}
                  />
                </div>
              ) : (
                <div className="container mb-1">&ensp;</div>
              )}
            </ul>
          </div>
        </div>
        <Graphs
          graphStaticData={graphStaticData}
          dailyBasisVals={dailyBasisVals}
          filterType={filterType}
          setFilterType={setFilterType}
          weekDayNames={weekDayNames}
          customDates={customDates}
          churnRate={churnRate}
        />
      </div>
    </div>
  );
}

export default Analytics;

//   ARPU (Average Revenue Per User-Subscription) = Total Active Subscriptions Revenue / Total Active Subscriptions
// MRR (Monthly Recurring Revenue) = ARPU  x  Total Active Subscriptions      This will only show data for monthly timeframe
// CLV (Customer Lifetime Value) =  Average Lifetime per subscription (calculated in total number of months) x ARPU       This will only show data for monthly timeframe

function groupByMonth(data) {
  const groupedData = {};

  data?.forEach(item => {
    if (item.created) {
      const date = new Date(item.created);
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      const key = `${year}-${month}`;
      if (!groupedData[key]) {
        groupedData[key] = [];
      }
      groupedData[key].push(item);
    }
  });

  const totalByMonth = Object.keys(groupedData).map(item => {
    const totalAmt = groupedData?.[item]?.reduce((acc, obj) => acc + (+obj.amount ?? 0), 0)
    return {
      monthYear: item,
      totalAmt: limitToTwoDecimal(totalAmt)
    }
  })

  return totalByMonth;
}