import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "../AllActivity/AllActivity.css";
import { BsFilterSquare } from "react-icons/bs";
import AllEvents from "./AllEvents";
import { httpSuperAdmin } from "../../Apis/commonApis";
import DataGridTable from "../../utils/DataGridTable";
import { formatDate } from "../../utils/CommonFunctions";

function AllActivity() {

  const [rows, setRows] = useState([]);
  const [stripeEvents, setStripeEvents] = useState([]);
  const [goCardEvents, setGoCardEvents] = useState([]);
  const [manualEvents, setManualEvents] = useState([]);

  useEffect(() => {
    getManualEvents();
    getGoCardEvents();
    getStripeEvents();
  }, []);

  useEffect(() => {
    let newArray = [];
    newArray = [...stripeEvents, ...goCardEvents, ...manualEvents];
    setRows(newArray);
  }, [stripeEvents, goCardEvents, manualEvents]);

  const getStripeEvents = async () => {
    const result = await httpSuperAdmin("listAllEvents", null);
    if (result.status) {
      let eventData = result?.data?.data;
      let stripeRowData = [];
      eventData.forEach((elem, i) => {
        stripeRowData.push({
          id: elem.id,
          eventType: elem.type,
          time: formatDate(elem.created * 1000),
          eventResult: elem.data.object.status,
          ip: '-',
          location: `-`,
          os: '-',
          browser: '-',
          source: "Stripe",
          eventId: `ST_1001SUB100${i + 1}`,
          uid: elem.doerId?._id ?? '-',
          userName: elem.doerId?.userName ?? '-',
        });
      });
      setStripeEvents(stripeRowData);
    }
  };

  const getGoCardEvents = async () => {
    const result = await httpSuperAdmin("gocardLessEvents", null);
    if (result.status) {
      let goCardEvents = result.data?.events;
      let rowData = [];
      goCardEvents.forEach((elem, i) => {
        rowData.push({
          id: elem.id,
          eventType: elem.action,
          time: formatDate(elem.created_at),
          ip: '-',
          location: `-`,
          os: '-',
          browser: '-',
          source: "GoCardless",
          eventId: `GO_1001SUB100${i + 1}`,
          uid: elem.doerId?.id ?? '-',
          eventResult: 'Success',
          userName: elem.doerId?.userName ?? '-',
        });
      });
      setGoCardEvents(rowData);
    }
  };

  const getManualEvents = async () => {
    const result = await httpSuperAdmin('getManualEvents', null);
    if (result.status) {
      let eventData = result.data;
      let rowData = [];
      eventData?.forEach((elem, i) => {
        let loc = elem.doerLocation;
        rowData.push({
          id: elem._id,
          eventType: elem.eventType,
          time: formatDate(elem.createdAt),
          uid: elem.doerId?._id ?? 'deleted user',
          ip: elem.doerIp,
          location: `${loc.lat}, ${loc.long}`,
          os: elem.userAgent?.os?.name,
          browser: elem.userAgent?.browser?.name,
          eventId: elem.eventId,
          userName: getFullName(elem.doerId),
          eventResult: 'Success',
          source: elem.source,
        })
      });
      setManualEvents(rowData);
    }
  }

  
 function getFullName(dataObj) {
  return dataObj ? `${dataObj?.firstName} ${dataObj?.lastName}` : 'deleted user';
}

  let roleAccess = JSON.parse(localStorage.getItem("__csadmin__d"));

  const columns = [
    { field: "time", headerName: "Time Stamp", minWidth: 90 },
    { field: "id", headerName: "Ext. Event ID", minWidth: 120 },
    { field: "uid", headerName: "User ID", minWidth: 120 },
    { field: "userName", headerName: "User Name", minWidth: 120 },
    { field: "ip", headerName: "IP Address", flex: 1, minWidth: 120 },
    { field: "os", headerName: "OS Type", minWidth: 90 },
    { field: "browser", headerName: "Browser Type", minWidth: 120 },
    { field: "location", headerName: "location", flex: 1, minWidth: 120 },
    // { field: "eventId", headerName: "Event ID", minWidth: 120 },
    { field: "eventType", headerName: "EventType", flex: 1, minWidth: 120 },
    { field: "eventResult", headerName: "Event Result", minWidth: 90 },
    { field: "source", headerName: "Source", minWidth: 90 },
  ];

  return (
    <div className="page-wrapper">
      {roleAccess?.role?.map((roletype) => {
        const allEventsAccess = roletype.Modules[6];
        if (allEventsAccess.access !== "full")
          return <div className="clickOff"></div>;
      })}
      <div className="container-fluid min_height">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">
              {/* <div className="top_menubar" style={{ width: "auto" }}>
                <ul
                  className="nav nav-tabs ract_tab_list border-0"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      style={{ fontSize: "16px" }}
                      id="tabD-1"
                    >
                      All Events
                    </button>
                  </li>
                </ul>
              </div> */}

              {/* <div className="float-end btns_head">
                <NavLink className="float-end filter_icon mt-1" to="#">
                  <BsFilterSquare />
                </NavLink>
                <ul className="filter_box">
                  <NavLink exact activeClassName="active" to="#">
                    Day
                  </NavLink>
                  <NavLink activeClassName="" to="#">
                    Week
                  </NavLink>
                  <NavLink activeClassName="" to="#">
                    Month
                  </NavLink>
                </ul>
              </div> */}
            </h4>
            {/* <AllEvents rows={rows} /> */}
            <DataGridTable columns={columns} rows={rows} handleRowSelection={() => { }} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllActivity;
