import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetch1, fetch2, fetch3, fetch4 } from "../../Apis/commonApis";
import { replaceWordInObject } from "../../utils/utilsHandlerFunctions";

export const data = [
  {
    value: 1,
    Ctg: "Personal Care",
    apiCategoryName: "Personal Care",
    image: `/images/s1.svg`,
  },
  {
    value: 2,
    Ctg: "Health",
    apiCategoryName: "Health",
    image: `/images/s2.svg`,
  },
  {
    value: 3,
    Ctg: "Mobility & Safety",
    apiCategoryName: "Mobility&Safety",
    image: `/images/s3.svg`,
  },
  {
    value: 4,
    Ctg: "Diet",
    apiCategoryName: "Dietary",
    image: `/images/s4.svg`,
  },
  {
    value: 5,
    Ctg: "Social & Communication",
    apiCategoryName: "Social & Communications",
    image: `/images/s5.svg`,
  },
  {
    value: 6,
    Ctg: "Administrative",
    image: `/images/s12.svg`,
  },
];

const initialState = {
  firstName: "",
  loading: false,
  plusButtonStatus: false,
  whichModalStatus: false,
  activeclassOnClick: false,
  oberservationsfield: "",
  outcomesfield: "",
  risksfield: "",
  risksFields: [],
  interventionsFields: [],
  interventionsfield: "",
  risksinterventionsFields: [],
  questionAndAnswer: {},
  questionAndAnswersList: [],
  whichModalType: "",
  templateName: "",
  lastupdate: "",
  updatedBy: "",
  selectedCategory: "",
  savebuttonstatus: "",
  activetemplateriskClass: "",
  activeriskClass: "",
  globalClientSpecificCarePlanTemplate: [],
  globalCarePlanTemplate: [],
  existingCategoryTemplates: [],
  getClientCarePlanPdfData: [],
  editTemplateData: {},
  riskProfileCategory: "",
  categoryType: "",
  templateId: "",
  careteGoryId: "",
  review: "",
  answerId: "",
  savedPlanHistoryStatus: false,
  clientProfileData: {},
  downlaodCarePlan: [],
  saveCarePlanPopupStatus: false,
  carePlanRemainderDatePopupStatus: false,
  selectedAnswerEvent: false,
  allAnswerIds: [],
};

export const UpdateCategoryRiskClientSpecific = createAsyncThunk(
  "UpdateCategoryRiskClientSpecific",
  async (body) => {
    const result = await fetch4(
      `${process.env.REACT_APP_BASEURL}/updateClientCarePlanRiskProfileCategory?careHomeId=${body.currentCareSiteId}&clientId=${body.clientId}&careplanObjId=${body.careplanObjId}`,
      {
        riskProfileCategory: body.risktype,
      },
      {
        Authorization: localStorage.getItem("care_admin_token"),
      }
    );

    return result;
  }
);

export const createCarePlanDirect = createAsyncThunk(
  "createCarePlanDirect",
  async (body) => {
    const result = await fetch4(
      `${process.env.REACT_APP_BASEURL}/createCarePlanDirect`,
      {
        ...body,
      },
      {
        Authorization: localStorage.getItem("care_admin_token"),
      }
    );

    return result;
  }
);

export const createClientCarePlanPdf = createAsyncThunk(
  "createClientCarePlanPdf",
  async (body) => {
    const result = await fetch1(
      `${process.env.REACT_APP_BASEURL}/createClientCarePlanPdf`,
      {
        ...body,
      },
      {
        Authorization: localStorage.getItem("care_admin_token"),
      }
    );

    return result;
  }
);

export const editClientCarePlanPdf = createAsyncThunk(
  "editClientCarePlanPdf",
  async ({ review, documentId }) => {
    const result = await fetch4(
      `${process.env.REACT_APP_BASEURL}/editClientCarePlanPdf?documentId=${documentId}`,
      {
        nextReview: review,
      },
      {
        Authorization: localStorage.getItem("care_admin_token"),
      }
    );

    return result;
  }
);

export const deleteCarePlanDirect = createAsyncThunk(
  "deleteCarePlanDirect",
  async (body) => {
    const result = await fetch4(
      `${process.env.REACT_APP_BASEURL}/deleteCarePlanDirect`,
      {
        ...body,
      },
      {
        Authorization: localStorage.getItem("care_admin_token"),
      }
    );

    return result;
  }
);

export const getCarePlanQueAndAns = createAsyncThunk(
  "getCarePlanQueAndAns",
  async () => {
    const result = await fetch3(
      `${process.env.REACT_APP_BASEURL}/getCarePlanQueAndAns`,
      "get"
    );
    return result;
  }
);

export const getClientbyId = createAsyncThunk(
  "getClientbyId",
  async ({ clientId, currentCareSiteId }) => {
    const result = await fetch3(
      `${process.env.REACT_APP_BASEURL}/clientbyId?clientId=${clientId}&careHomeId=${currentCareSiteId}`,
      "get"
    );
    return result;
  }
);

export const getClientCarePlanPdf = createAsyncThunk(
  "getClientCarePlanPdf",
  async (body) => {
    const result = await fetch3(
      `${process.env.REACT_APP_BASEURL}/getClientCarePlanPdf?adminId=${body.adminId}&careHomeId=${body.currentCareSiteId}&clientId=${body.clientId}`,
      "get"
    );
    return result;
  }
);

const CarePlanSlice = createSlice({
  name: "CarePlanSlice",
  initialState,
  reducers: {
    selectMultipleIds(state = initialState, action) {
      //   state.shiftTaskIds = action.payload.ids;
    },
    plusButtonStatusHandler(state = initialState, action) {
      state.plusButtonStatus = action.payload.plusButtonStatus;
    },
    risksinterventionsRemoveChangeHandler(state = initialState, action) {
      const interventionclonefield = state.interventionsFields.slice();
      const intervationdata = interventionclonefield.splice(
        action.payload.removeIdx,
        1
      );
      state.interventionsFields = interventionclonefield;

      const clonefield = state.risksFields.slice();
      const data = clonefield.splice(action.payload.removeIdx, 1);
      state.risksFields = clonefield;

      const risk_intervention_clonefield =
        state.risksinterventionsFields.slice();
      const risk_interventiondata = risk_intervention_clonefield.splice(
        action.payload.removeIdx,
        1
      );
      state.risksinterventionsFields = risk_intervention_clonefield;
    },

    handleRisksInterventionsChange(state = initialState, action) {
      state.risksFields = [...state.risksFields, action.payload.risk];
      state.risksfield = "";
      state.interventionsFields = [
        ...state.interventionsFields,
        action.payload.intervention,
      ];
      state.interventionsfield = "";
      state.risksinterventionsFields = [
        ...state.risksinterventionsFields,
        {
          risk: action.payload.risk,
          interventions: action.payload.intervention,
        },
      ];
    },
    addCarePlanTemplate(state = initialState, action) {
      const keys = Object.keys(action.payload);
      keys.forEach((item) => {
        state[item] = action.payload[item];
      });
    },
    questionAnswerStatementCollection(state = initialState, action) {
      let {
        questionAndAnswersList,
        questionId,
        selectedAnswerId,
        selectedAnswerEvent,
        questionType,
      } = action.payload;
      const list = JSON.parse(JSON.stringify(questionAndAnswersList));
      if (questionType === "checkbox") {
        for (let i = 0; i < list.length; i++) {
          for (let j = 0; j < list[i].options.length; j++) {
            if (
              list[i]._id === questionId &&
              selectedAnswerId.includes(list[i].options[j]._id) &&
              selectedAnswerEvent
            ) {
              list[i].options[j].answerValue = true;
            } else {
              if (list[i]._id === questionId)
                if (selectedAnswerId.includes(list[i].options[j]._id)) {
                  list[i].options[j].answerValue = false;
                }
            }
          }
        }
        if (selectedAnswerEvent) {
          state.allAnswerIds = [...state.allAnswerIds, selectedAnswerId];
        } else {
          const indexToRemove = state.allAnswerIds?.indexOf(selectedAnswerId);
          if (indexToRemove !== -1) {
            state.allAnswerIds.splice(indexToRemove, 1);
          }
        }
      }
      if (questionType === "radio") {
        for (let i = 0; i < list.length; i++) {
          for (let j = 0; j < list[i].options.length; j++) {
            if (
              list[i]._id === questionId &&
              list[i].options[j]._id === selectedAnswerId
            ) {
              list[i].options[j].answerValue = true;
            } else {
              if (list[i]._id === questionId)
                list[i].options[j].answerValue = false;
            }
          }
        }
      }
      state.questionAndAnswersList = list;
    },
  },

  extraReducers: {
    [UpdateCategoryRiskClientSpecific.fulfilled]: (state, { payload }) => {
      state.loading = false;
      // state.deleteTaskStatus = payload.status;
      // state.deleteTaskCount += 1;
      // state.deleteTaskMessage = payload.message;
    },
    [createCarePlanDirect.fulfilled]: (state, { payload }) => {
      state.loading = false;
      // state.deleteTaskStatus = payload.status;
      // state.deleteTaskCount += 1;
      // state.deleteTaskMessage = payload.message;
    },
    [getCarePlanQueAndAns.fulfilled]: (state, { payload }) => {
      let { category, _id } = replaceWordInObject(
        payload.data,
        "{{Name}}",
        state.firstName?.trim()
      );
      state.existingCategoryTemplates = category;
      state.loading = false;
      // state.deleteTaskStatus = payload.status;
      // state.deleteTaskCount += 1;
      // state.deleteTaskMessage = payload.message;
    },
    [getClientbyId.fulfilled]: (state, { payload }) => {
      state.clientProfileData = payload?.clientData;
      state.downlaodCarePlan = payload?.clientData?.carePlan;
      state.loading = false;
      // state.deleteTaskStatus = payload.status;
      // state.deleteTaskCount += 1;
      // state.deleteTaskMessage = payload.message;
    },
    [getClientCarePlanPdf.fulfilled]: (state, { payload }) => {
      state.getClientCarePlanPdfData = payload.data.map((item) => {
        return {
          ...item,
          creationDate: item.createdAt?.split("T")[0],
          createdBy: item.createdBy,
          review: Object.keys(item).includes("review") ? item.review : "",
          image: Object.keys(item).includes("pdfLink") ? item.pdfLink : "",
          fileName: Object.keys(item).includes("pdfLink")
            ? item.pdfLink
                .split("/")
                .pop()
                .replace(/\b\d{5,}-\b/g, "")
            : "",
        };
      });
      state.loading = false;
      // state.deleteTaskStatus = payload.status;
      // state.deleteTaskCount += 1;
      // state.deleteTaskMessage = payload.message;
    },
    [createClientCarePlanPdf.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.carePlanRemainderDatePopupStatus = false;
      // state.deleteTaskStatus = payload.status;
      // state.deleteTaskCount += 1;
      // state.deleteTaskMessage = payload.message;
    },
    [editClientCarePlanPdf.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.carePlanRemainderDatePopupStatus = false;
      // state.deleteTaskStatus = payload.status;
      // state.deleteTaskCount += 1;
      // state.deleteTaskMessage = payload.message;
    },
  },
});

export const {
  selectMultipleIds,
  plusButtonStatusHandler,
  addCarePlanTemplate,
  handleRisksInterventionsChange,
  risksinterventionsRemoveChangeHandler,
  questionAnswerStatementCollection,
  observationNeedComments,
} = CarePlanSlice.actions;
export default CarePlanSlice.reducer;
