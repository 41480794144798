import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { httpAdmin } from "../../Apis/commonApis";

const initialState = {
    careSitesData: null,
    getCareSiteDataState: 0,
    getBusinessCatState: 0,
    currentCareSiteId: null,
    currentCareSiteData: null,
    currentPlanName: null,
    accessRoles: null,
    userDataLatest: null,
};

export const getCareSiteListing = createAsyncThunk(
    "getCareSiteListing",
    async () => {
        const res = await httpAdmin(`careHomelisting`, "get", null, null);
        return res;
    }
);

export const switchCareSiteApi = createAsyncThunk(
    "switchCareSite",
    async (body) => {
        const res = await httpAdmin(`switchCareSite?userId=${body.userId}&careSiteId=${body.careSiteId}`, "get", null, null);
        return res;
    }
);

export const getAccessRoles = createAsyncThunk(
    "getAccessRoles",
    async (adminId) => {
        const res = await httpAdmin(`getAdmin?adminId=${adminId}`, "get", null, null);
        return res;
    }
);

const careSitDataSlice = createSlice({
    name: "careSitDataSlice",
    initialState,
    reducers: {
        changeCurrentCareSite(state = initialState, action) {
            state.currentCareSiteId = action.payload.csid;
        },
    },

    extraReducers: {
        [getCareSiteListing.fulfilled]: (state, { payload }) => {
            state.getCareSiteDataState = 1;
            if (payload?.status) {
                state.careSitesData = payload?.listing;
                let ccsid = localStorage.getItem("currentCareSiteId");
                let currentcsid = ccsid ? ccsid : payload.defaultCareSiteId;
                let currentsite = payload?.listing.find(item => item._id === currentcsid);
                state.currentCareSiteData = currentsite;
                state.currentPlanName = currentsite?.SubscriptionPlan;
                console.log(currentsite?.SubscriptionPlan, currentsite?.careSiteName, 'current site111111');
            }
        },
        [switchCareSiteApi.fulfilled]: (state, { payload }) => {
            state.getCareSiteDataState = 1;
            console.log(payload, 'switchCareSiteApi')
            if (payload?.status) {
                state.careSitesData = payload?.listing;
            }
        },
        [getAccessRoles.fulfilled]: (state, { payload }) => {
            if (payload?.status) {
                state.accessRoles = payload?.result?.[0]?.role?.[0]?.Modules;
                state.userDataLatest = payload?.result?.[0];
            }
        },
    },
});

export const { changeCareSiteState } =
    careSitDataSlice.actions;
export default careSitDataSlice.reducer;
