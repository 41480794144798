import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
  useEffect,
} from "react";
import cx from "./template.module.css";
import axios from "axios";
import { useForm } from "react-hook-form";
import Dropdown from "react-bootstrap/Dropdown";
import swal from "sweetalert";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { twoDecimalPlaces } from "../../utils/utilsHandlerFunctions";

const Invoices = forwardRef((props, ref) => {
  let { invoiceId } = props;

  const divRef = useRef(null);

  const [specificInvoice, setSpecificInvoice] = useState(null);
  const [charges, setCharges] = useState([]);
  const [tax, setTax] = useState(0);

  const [items, setItems] = useState([
    { discription: "", rate: "", qty: "1", tax: "", amount: 0 },
  ]);
  const [editIndex, setEditIndex] = useState(null);
  const [error, setError] = useState("");

  const {
    register,
    handleSubmit,
    unregister,
    reset,
    setValue,
    formState: { errors },
    control,
  } = useForm();

  useEffect(() => {
    getSpecificInvoiceAdminById();
  }, []);

  function getSpecificInvoiceAdminById() {
    axios({
      url: `${process.env.REACT_APP_BASEURL}/getInvoiceAdminById?invoiceId=${invoiceId}`,
      method: "GET",
      headers: { Authorization: localStorage.getItem("care_admin_token") },
    })
      .then((res) => {
        let invdata = res.data.invoiceData;
        setSpecificInvoice(invdata);
        setTax(invdata.tax ? invdata.tax : 0);
        setCharges(invdata?.chargesDetails);
        // setValue({ toName: invdata.toName, toAddress: invdata.toAddress, toEmail: invdata.toEmail, toContactNo: invdata.toContactNo });
        setItems(invdata?.chargesDetails);
        setValue("toName", invdata.toName);
        setValue("toAddress", invdata.toAddress);
        setValue("toEmail", invdata.toEmail);
        setValue("toContactNo", invdata.toContactNo);
      })
      .catch((error) => console.log(`Error: ${error}`));
  }

  function editInvoice(invoiceData) {
    let isError = false;
    items.map((e, index) => {
      if (e.discription == "" || e.rate == "" || e.qty == "" || e.amount == 0) {
        isError = true;
      }
    });
    let selectedChargeData = [...items];
    selectedChargeData.forEach((elem) => {
      elem.tax = tax ? tax : 0;
    });
    if (isError) {
      setError("chargesErr");
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
      return;
    }
    const totalWithoutTax = items.reduce((acc, obj) => acc + obj.amount, 0);
    const totalWithTax = totalWithoutTax + (totalWithoutTax / 100) * tax;
    let data = JSON.stringify({
      invoiceId: specificInvoice._id,
      toName: invoiceData.toName,
      toAddress: invoiceData.toAddress,
      toEmail: invoiceData.toEmail,
      toContactNo: invoiceData.toContactNo,
      chargesDetails: selectedChargeData,
      tax: tax ? tax : 0,
      total: totalWithTax,
    });

    let config = {
      method: "POST",
      url: `${process.env.REACT_APP_BASEURL}/editInvoice`,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("care_admin_token"),
      },
      data: data,
    };
    axios(config)
      .then(function (res) {
        swal("Success", res.data.message, "success");
        getSpecificInvoiceAdminById();
      })
      .catch(function (error) {
        swal("Failed", error, "error");
        console.log(error);
      });
  }

  function approvalStatus() {
    let userData = JSON.parse(localStorage.getItem("userData"));
    var data = JSON.stringify({
      invoiceId: [specificInvoice._id],
      careHomeId: specificInvoice.careHomeId._id,
      approveBy: `${userData.firstName} ${userData.lastName} (${userData.userType})`,
    });
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/approveInvoice`,
      headers: {
        Authorization: localStorage.getItem("care_admin_token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (res) {
        swal("Success", res.data.message, "success");
        getSpecificInvoiceAdminById();
      })
      .catch(function (error) {
        swal("Failed", error, "error");
      });
  }

  // ------------- add edit charge items -------------------//

  const handleAddItem = () => {
    setItems([
      ...items,
      { discription: "", rate: "", qty: "1", tax: "", amount: 0 },
    ]);
  };

  const handleInputChange = (index, field, value) => {
    const updatedItems = [...items];
    updatedItems[index][field] = value;

    if (field === "rate" || field === "qty" || field === "tax") {
      const rate = parseFloat(updatedItems[index].rate) || 0;
      const qty = parseFloat(updatedItems[index].qty) || 1;
      updatedItems[index].amount = rate * qty;
    }
    setItems(updatedItems);
    if (error.length > 0) setError("");
  };

  const handleEditItem = (index) => {
    console.log(items, "itemssssssss");
    setEditIndex(index);
  };

  const handleRemoveItem = (index) => {
    setError("");
    if (items.length === 1) {
      return;
    }
    const updatedItems = [...items];
    updatedItems.splice(index, 1);
    setItems(updatedItems);
  };

  const generatePDF = async () => {
    if (divRef.current) {
      const content = divRef.current;
      const canvas = await html2canvas(content);

      const pdf = new jsPDF("p", "mm", "a4", true);

      // Add the captured image to the PDF
      const imgData = canvas.toDataURL("image/png");
      pdf.addImage(imgData, "jpg", 10, 10, 180, 0);

      pdf.save("my-document.pdf");
    }
  };

  let subTotal = items.reduce((acc, obj) => acc + obj.amount, 0);
  let totalAmt = subTotal + (subTotal / 100) * tax;

  return (
    <>
      <div className="d-flex col-md-11 justify-content-end">
        <Dropdown className="themeBtn">
          <Dropdown.Toggle id="dropdown-basic">Actions</Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={handleSubmit(editInvoice)}>
              Save
            </Dropdown.Item>
            <Dropdown.Item onClick={approvalStatus}>Approve</Dropdown.Item>
            <Dropdown.Item onClick={generatePDF}>Download</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className={`${cx.printSection}`} ref={divRef}>
        <div className="col-md-10 m-auto mt-1">
          <div className="row">
            <div className="col-md-12 position-relative text-center px-5 py-2">
              <span className={`${cx.logoBox}`}>
                {specificInvoice?.careHomeId?.companyName}
              </span>
              <h3 style={{ color: "#222" }}>Invoice</h3>
            </div>
            {/* <div className="col-md-7 text-end mb-2" style={{ color: '#222' }}></div>
    <div className="col-md-5 text-end mb-2" style={{ color: '#222' }}>To</div> */}
            <div className="col-md-5">
              <ul className={`${cx.formbox}`}>
                <li>
                  <div className="text-end mb-2">&nbsp;</div>
                </li>
                <li>
                  <input
                    type="text"
                    value={specificInvoice?.adminId?.companyName}
                    className="form-control"
                    disabled
                  />
                </li>
                <li>
                  <textarea
                    className="form-control"
                    rows="3"
                    value={specificInvoice?.adminId?.billingAddress}
                    disabled
                  ></textarea>
                </li>
                <li>
                  <input
                    type="text"
                    value={specificInvoice?.adminId?.email}
                    className="form-control"
                    disabled
                  />
                </li>
                <li>
                  <input
                    type="text"
                    value={specificInvoice?.adminId?.mobileNo}
                    className="form-control"
                    disabled
                  />
                </li>
              </ul>
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-5">
              <ul className={`${cx.formbox}`}>
                <div className=" text-end mb-2" style={{ color: "#222" }}>
                  To
                </div>
                <li>
                  <input
                    type="text"
                    className="form-control"
                    {...register("toName", {
                      required: true,
                    })}
                    name="toName"
                    placeholder="Recipent Name"
                  />
                  {errors.toName && (
                    <span className="error_style">This field is required</span>
                  )}
                </li>
                <li>
                  <textarea
                    className="form-control"
                    {...register("toAddress", {
                      required: true,
                    })}
                    name="toAddress"
                    placeholder="Recipent Address"
                  ></textarea>
                  {errors.toAddress && (
                    <span className="error_style">This field is required</span>
                  )}
                </li>
                <li>
                  <input
                    type="email"
                    className="form-control"
                    {...register("toEmail", {
                      required: true,
                    })}
                    name="toEmail"
                    placeholder="Recipent Email"
                  />
                  {errors.toEmail && (
                    <span className="error_style">This field is required</span>
                  )}
                </li>
                <li>
                  <input
                    type="number"
                    className="form-control"
                    {...register("toContactNo", {
                      required: true,
                    })}
                    name="toContactNo"
                    placeholder="Recipent Telephone"
                  />
                  {errors.toContactNo && (
                    <span className="error_style">This field is required</span>
                  )}
                </li>
              </ul>

              <ul className="mt-4">
                <li
                  className="d-flex mb-2 justify-content-between"
                  style={{ color: "#222" }}
                >
                  <b>Invoice No.</b>
                  <b>Invoice Date</b>
                </li>
                <li
                  className="d-flex mb-2 justify-content-between"
                  style={{ color: "#222" }}
                >
                  <b>{specificInvoice?.invoiceNo}</b>
                  <b>{specificInvoice?.invoiceDate?.split("T")[0]}</b>
                </li>
              </ul>
            </div>

            <div className="col-md-12">
              <ul className="mb-3">
                <li
                  className="d-flex my-2 justify-content-between"
                  style={{ color: "#222" }}
                >
                  <b>Invoice Period</b>
                  <b>Invoice Deadline</b>
                </li>
                <li
                  className="d-flex justify-content-between"
                  style={{ color: "#222" }}
                >
                  <b>{specificInvoice?.invoicePeriod}</b>
                  <b>{specificInvoice?.invoiceDeadline?.split("T")[0]}</b>
                </li>
              </ul>
            </div>

            <div className="col-md-12">
              <table className={`${cx.tableInvoice} table`}>
                <thead>
                  <tr>
                    <th style={{ color: "#222" }}>
                      <div style={{ width: "300px" }}>Description</div>
                    </th>
                    <th style={{ color: "#222" }}>Rate</th>
                    <th style={{ color: "#222" }}>Qty</th>
                    {/* <th style={{ color: "#222" }}>Tax(%)</th> */}
                    <th style={{ color: "#222" }}>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {/* {specificInvoice?.chargesDetails?.length > 0 && (
      <>
        <tr>
          <td>
            <input
              type="text"
              defaultValue={
                specificInvoice?.chargesDetails[0]?.discription
              }
              className="form-control"
              disabled
            />
          </td>
          <td>
            <input
              type="text"
              defaultValue={
                specificInvoice?.chargesDetails[0]?.rate
              }
              className="form-control"
              disabled
            />
          </td>
          <td>
            <input
              type="text"
              defaultValue={specificInvoice?.chargesDetails[0]?.qty}
              className="form-control"
              disabled
            />
          </td>
          <td>
            <input
              type="text"
              defaultValue={specificInvoice?.chargesDetails[0]?.tax}
              className="form-control"
              disabled
            />
          </td>
          <td>
            <input
              type="text"
              defaultValue={
                specificInvoice?.chargesDetails[0]?.amount
              }
              className="form-control"
              disabled
            />
          </td>
          <button
            onClick={() => {
              if (detailsValue.length > 0) {
                let details = detailsValue[detailsValue.length - 1];
                if (
                  details.Discription == "" &&
                  details.Rate == 1 &&
                  details.Qty == 1 &&
                  details.Tax == 0 &&
                  details.Amount == 0
                ) {
                  return;
                } else {
                  handleAnsChange(ansfield);
                }
              } else {
                handleAnsChange(ansfield);
              }
            }}
          >
            +
          </button>
        </tr>
      </>
    )} */}

                  {items?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <input
                            type="text"
                            placeholder="Description"
                            className="form-control"
                            value={item.discription}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "discription",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            placeholder="Rate"
                            className="form-control"
                            value={twoDecimalPlaces(item.rate)}
                            onChange={(e) =>
                              handleInputChange(index, "rate", e.target.value)
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            placeholder="Qty"
                            className="form-control"
                            value={item.qty}
                            onChange={(e) =>
                              handleInputChange(index, "qty", e.target.value)
                            }
                          />
                        </td>
                        {/* <td>
                          <input
                            type="number"
                            placeholder="Tax"
                            className="form-control"
                            value={item.tax}
                            onChange={(e) => handleInputChange(index, 'tax', e.target.value)} />
                        </td> */}
                        <td>
                          <input
                            type="number"
                            placeholder="Amount"
                            className="form-control"
                            value={twoDecimalPlaces(item.amount)}
                            disabled
                          />
                        </td>
                        <div className={`${cx.charges_field}`}>
                          {index === 0 ? (
                            <button onClick={handleAddItem}>+</button>
                          ) : (
                            <button onClick={() => handleRemoveItem(index)}>
                              --
                            </button>
                          )}
                          {/* {editIndex === index ? (
                  <>
                    <button onClick={() => handleEditItem(null)}>Save</button>
                  </>
                ) : (
                  <button onClick={() => handleEditItem(index)}>Edit</button>
                )} */}
                        </div>
                      </tr>
                    );
                  })}
                  {/* {charges?.map((item, index) => {
      return index > 0 ? (
        <tr key={index}>
          <td>
            <input
              type="text"
              defaultValue={item?.discription}
              className="form-control"
              onChange={(event) => {
                item.discription = event.target.value;
              }}
            />
          </td>
          <td>
            <input
              type="number"
              onChange={(event) => {
                item.rate = event.target.value;
                item.amount = +item.rate * +item.qty + +item.tax;
                setChargesTotal({ i: index, total: item.amount });
              }}
              defaultValue={item?.rate}
              className="form-control"
            />
          </td>
          <td>
            <input
              type="number"
              onChange={(event) => {
                item.qty = event.target.value;
                item.amount = +item.rate * +item.qty + +item.tax;
                setChargesTotal({ i: index, total: item.amount });
              }}
              defaultValue={item?.qty}
              className="form-control"
            />
          </td>
          <td>
            <input
              type="number"
              onChange={(event) => {
                item.tax = event.target.value;
                item.amount = +item.rate * +item.qty + +item.tax;
                setChargesTotal({ i: index, total: item.amount });
              }}
              defaultValue={item?.tax}
              className="form-control"
            />
          </td>
          <td>
            <input
              type="number"
              value={
                chargesTotal.i === index
                  ? chargesTotal.total
                  : charges[index].amount
              }
              className="form-control"
              disabled
            />
          </td>
          <button
            onClick={() => {
              specificInvoiceChangeHandler(index);
              setChargesTotal({ i: 0, total: 0 });
            }}
          >
            -
          </button>
        </tr>
      ) : (
        ""
      );
    })}
    {ansFields?.map((e, index) => {
      return (
        <>
          <tr>
            <td>
              <input
                type="text"
                onChange={(event) => {
                  detailsValue.map((value, num) => {
                    if (index === num) {
                      value.discription = event.target.value;
                    }
                  });
                }}
                className="form-control"
              />
            </td>
            <td>
              <input
                type="number"
                onChange={(event) => {
                  detailsValue.map((value, num) => {
                    if (index === num) {
                      value.rate = event.target.value;
                      value.amount =
                        +value.rate * +value.qty + +value.tax;
                      setTotal({ i: num, total: value.amount });
                    }
                  });
                }}
                className="form-control"
              />
            </td>
            <td>
              <input
                type="number"
                onChange={(event) => {
                  detailsValue.map((value, num) => {
                    if (index === num) {
                      value.qty = event.target.value;
                      value.amount =
                        +value.rate * +value.qty + +value.tax;
                      setTotal({ i: num, total: value.amount });
                    }
                  });
                }}
                className="form-control"
              />
            </td>
            <td>
              <input
                type="number"
                onChange={(event) => {
                  detailsValue.map((value, num) => {
                    if (index === num) {
                      value.tax = event.target.value;
                      value.amount =
                        +value.rate * +value.qty + +value.tax;
                      setTotal({ i: num, total: value.amount });
                    }
                  });
                }}
                className="form-control"
              />
            </td>
            <td>
              <input
                type="number"
                value={
                  total.i === index
                    ? total.total
                    : detailsValue[index].amount
                }
                className="form-control"
                disabled
              />
            </td>
            <button
              onClick={() => {
                ansRemoveChangeHandler(index);
                setTotal({ i: 0, total: 0 });
              }}
            >
              -
            </button>
          </tr>
        </>
      );
    })} */}
                </tbody>
              </table>
              {error.includes("chargesErr") && (
                <p style={{ margin: "-15px 0px 0px 8px" }}>
                  <span className="error_style">All fields are required</span>
                </p>
              )}
            </div>

            <div className="col-md-8"></div>
            <div className="col-md-4">
              <table className="table">
                <thead>
                  <tr>
                    <th style={{ color: "#222", verticalAlign: "middle" }}>SubTotal</th>
                    <th style={{ color: "#222" }} className="text-end">
                      <input
                        type="number"
                        value={twoDecimalPlaces(subTotal)}
                        disabled
                        className="form-control"
                      />
                    </th>
                  </tr>
                  <tr>
                    <th style={{ color: "#222", verticalAlign: "middle" }}>Tax(%)</th>
                    <th style={{ color: "#222" }} className="text-end">
                      <input
                        type="number"
                        placeholder="0"
                        value={twoDecimalPlaces(tax)}
                        onChange={(e) => setTax(e.target.value)}
                        className="form-control"
                      />
                    </th>
                  </tr>
                  <tr>
                    <th style={{ color: "#222", verticalAlign: "middle" }}>Total</th>
                    <th style={{ color: "#222" }} className="text-end">
                      <input
                        className="form-control"
                        type="number"
                        value={twoDecimalPlaces(totalAmt)}
                        disabled
                      />
                    </th>
                  </tr>
                </thead>
              </table>
            </div>

            {/* <div>
      {items.map((item, index) => (
        <div key={index}>
          <input
            type="text"
            placeholder="Description"
            value={item.description}
            onChange={(e) => handleInputChange(index, 'description', e.target.value)}
            disabled={editIndex === index ? false : true}
          />
          <input
            type="number"
            placeholder="Rate"
            value={item.rate}
            onChange={(e) => handleInputChange(index, 'rate', e.target.value)}
            disabled={editIndex === index ? false : true}
          />
          <input
            type="number"
            placeholder="Quantity"
            value={item.qty}
            onChange={(e) => handleInputChange(index, 'qty', e.target.value)}
            disabled={editIndex === index ? false : true}
          />
          <input
            type="number"
            placeholder="Tax"
            value={item.tax}
            onChange={(e) => handleInputChange(index, 'tax', e.target.value)}
            disabled={editIndex === index ? false : true}
          />
          <input
            type="number"
            placeholder="Amount"
            value={item.amount}
            onChange={(e) => handleInputChange(index, 'amount', e.target.value)}
            disabled={editIndex === index ? false : true}
          />
          {editIndex === index ? (
            <>
              <button onClick={() => handleEditItem(null)}>Save</button>
              <button onClick={() => handleRemoveItem(index)}>Remove</button>
            </>
          ) : (
            <button onClick={() => handleEditItem(index)}>Edit</button>
          )}
        </div>
      ))}
      <button onClick={handleAddItem}>+ Add Item</button>
    </div> */}
          </div>
        </div>
      </div>
    </>
  );
});

export default Invoices;
