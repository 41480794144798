import { useParams } from "react-router-dom";
import Emails from "./emails";
import Event from "./event";
import FriendsFamily from "./friendsfamily";
import Notes from "./notes";
import OverviewPage from "./overviewPage";

export default function CommonClientPage(props) {
  const { careSite } = props;
  
  let { topicId } = useParams();

  return (
    <div className="mt-3">
      {/* {topicId == '' && <OverviewPage />} */}
      {topicId == "overview" && <OverviewPage careSite={careSite} />}
      {topicId == "events" && <Event careSite={careSite} />}
      {topicId == "emails" && <Emails careSite={careSite} />}
      {topicId == "ff" && <FriendsFamily careSite={careSite} />}
      {topicId == "notes" && <Notes careSite={careSite} />}
    </div>
  );
}
