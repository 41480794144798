import React, { Fragment, useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
// import InformationText from "./InformationText";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";

export default function FriendsAndFamilyAppSection(props) {
  let { planName, fandfselectHandler, SubscriptionType, fnfData } = props;
  const { selectedCurrency } = useSelector((state) => state.websitePlans);

  const [state, setState] = useState({ [planName]: false });
  const [valuetext, setValueText] = useState(0);

  let fndfrate = converToCurrency(fnfData?.unitPrice);

  useEffect(() => {
    setState({...state, [planName]: false})
    fandfselectHandler(planName, false, valuetext, fndfrate);
  }, [selectedCurrency]);

  function valueChangeHandler(event) {
    setValueText(event);
    fandfselectHandler(planName, state[planName], event, fndfrate);
  }

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
    fandfselectHandler(planName, event.target.checked, valuetext, fndfrate);
  };

  function converToCurrency(defaultRate) {
    const toRate = selectedCurrency?.gbpExchangeRate;
    const rate = (+toRate * defaultRate)?.toFixed(2);
    return rate;
  }

  return (
    <Fragment>
      <div className="added_plans">
        <h5>
          <label className="checkbox">
            <Checkbox
              name={planName}
              checked={state[planName]}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
            Friends & Family Portal Add-On{" "}
            {/* <InformationText infoText="Friends & Family Portal Add-On" /> */}
          </label>
        </h5>
        <div className="app_user">
          <div className="mb-1 mt-2" style={{ textAlign: "center" }}>
            No. of Portal Users{" "}
          </div>
          <div className="slidecontainer">
            <span className="me-3">{valuetext}</span>{" "}
            <IOSSlider
              aria-label="ios slider"
              value={valuetext}
              valueLabelDisplay="off"
              onChange={(e) => {
                valueChangeHandler(e.target.value);
              }}
              max={100}
            />
            <span className="ms-1">100</span>
          </div>
          <p className="text-center mb-1">
            {/* <span id="demo2"></span> */}
          </p>
        </div>
        <label className="checkbox">
          Paid by your Care Service per app user @
          {`${selectedCurrency?.symbol} ${SubscriptionType ? fndfrate * 12 : fndfrate}`} /{SubscriptionType ? "year" : "month"}
        </label>
      </div>
    </Fragment>
  );
}

const IOSSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.mode === "dark" ? "#C4C4C4" : "#C4C4C4",
  height: 19,
  padding: "15px 0",

  "& .MuiSlider-valueLabel": {
    fontSize: 12,
    fontWeight: "normal",
    top: 46,
    backgroundColor: "unset",
    color: theme.palette.text.primary,
  },
  "& .MuiSlider-track": {
    border: "none",
    backgroundColor: "#C4C4C4",
    height: "14px !important"
  },
  "& .MuiSlider-thumb": {
    height: 18,
    width: 18,
    backgroundColor: "#9B51E0",
  },
  "& .MuiSlider-rail": {
    opacity: 1,
    backgroundColor: "#C4C4C4",
  },
}));
