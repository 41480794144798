import React from 'react'
import cx from "./Portal.module.css";
import { Row, Col, NavLink } from "react-bootstrap";
import { useEffect, useState, useRef } from 'react';
import { httpPortal } from "../Apis/commonApis";
import { useDispatch, useSelector } from "react-redux";
import { updatePortalUserData } from "../redux-toolkit/reducer/portalReducer";
import { getFullName } from '../utils/CommonFunctions';
import ClientJournalPortal from './portalViews/ClientJournal';
import PortalMessaging from './portalViews/PortalMessaging';

export default function PortalMain() {

  const dispatch = useDispatch();

  const [clientData, setClientData] = useState(null);

  useEffect(() => {
    clientProfile()
  }, [])

  let clientProfile = async () => {
    let client_id = localStorage.getItem("fandfData")
    client_id = JSON.parse(client_id)
    const res = await httpPortal(`clientProfile?id=${client_id?._id}`, 'get', null, null);
    if (res.status) {
      setClientData(res?.data);
      dispatch(updatePortalUserData({ userData: res?.data }));
    }
  }

  let clientAutFilter = clientData?.keycontact?.filter((item) => item?.consentAuthority == "yes").map((item) => { return `${item?.name}(${item?.relation})` });

  return (
    <div className={`${cx.PortalMainStart}`}>
      <div className={`container`}>
        <Row>
          <Col lg={5} md={12} sm={12} className={`mb-3`} >
            <div className={`${cx.profileProtal}`}>
              <h3>Profile</h3>
              <div className={`${cx.profileBox}`}>
                <div className={`${cx.profile}`}>
                  <img src={clientData?.image ?? '/images/chatUser.svg'} alt="" />
                  <h4>{getFullName(clientData)}</h4>
                  {/* <h4>{clientData?.first_Name} {clientData?.last_Name}</h4> */}
                </div>
                <ul>
                  <li>
                    <span>Contract Start Date</span>
                    <span>{clientData?.contractStartdate}</span>
                  </li>
                  <li>
                    <span>Contract End Date</span>
                    <span>{clientData?.contractEnddate}</span>
                  </li>
                  <li>
                    <span>Date of Birth</span>
                    <span>{clientData?.DOB}</span>
                  </li>
                  <li>
                    <span>Blood Group Type</span>
                    <span>{clientData?.bloodGroup}</span>
                  </li>
                </ul>

                <ul>
                  <h5>Address</h5>
                  <li>
                    <span>Current Address (Location)</span>
                    <span>{clientData?.location}</span>
                  </li>
                  <li>
                    <span>Permanent Address</span>
                    <span>{clientData?.permanentAddress}</span>
                  </li>
                </ul>
                <ul>
                  <h5>Consent</h5>
                  <li>
                    <span>Mental Capacity to Consent</span>
                    <span>{clientData?.consentMentalCapacity}</span>
                  </li>
                  <li>
                    <span>Power of Attorney in Place</span>
                    <span>{clientData?.consentPOA}</span>
                  </li>
                  <li>
                    <span>Authorized Person</span>
                    <span>{clientAutFilter?.join(', ')}</span>
                  </li>
                  <li>
                    <span>Advanced Directive</span>
                    <span>{clientData?.advance_Directive}</span>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col lg={7} md={12} sm={12} className={`mb-3`} >
            <ClientJournalPortal
              clientImg={clientData?.image}
              JournalAccess={true}
            />
          </Col>
          <Col lg={5} md={12} sm={12} >
            <div className={`mb-3 ${cx.profileProtal}`}>
              <h3>Medical History</h3>
              <div className={`${cx.profileBox1}`}>
                <table className={`${cx.table} table`}>
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Type</th>
                      <th scope="col">Start Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {clientData?.medical_Conditions?.map((item) => {
                      return (<tr>
                        <td>{item?.name}</td>
                        <td>{item?.type}</td>
                        <td>{item?.start_Date}</td>
                      </tr>)
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className={`mb-3 ${cx.profileProtal}`}>
              <h3>Medicines</h3>
              <div className={`${cx.profileBox1} `}>
                <table className={`${cx.table} table`}>
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Type</th>
                      <th scope="col">Start Date</th>
                    </tr>
                  </thead>
                  <tbody >
                    {clientData?.medication?.map((item) => {
                      const startDate = new Date(item?.startDate);
                      const formattedDate = startDate.toISOString().slice(0, 10);
                      return (<tr>
                        <td>{item?.NM}</td>
                        <td>{item?.prn.trim().toUpperCase() === "NO" ? "Normal" : "PRN"}</td>
                        <td>{formattedDate}</td>
                      </tr>)
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </Col>

          <Col lg={7} md={12} sm={12} >
            <PortalMessaging clientData={clientData} />
          </Col>
        </Row>
      </div>
    </div>

  )
}
