import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import styles from "./reports.module.css";
import { limitToTwoDecimal } from "../../Apis/commonApis";
import { formatDate } from "../../utils/CommonFunctions";

let helperArrDate = [];

export default function ReportsGraph(props) {
  const {
    dateRange,
    graphStaticData,
    selectedSubCat,
    selectedCat,
    selectedLabel,
    selectedNameData,
  } = props;

  const [startDate, endDate] = dateRange;

  const [options, setOptions] = useState({
    chart: {
      height: 500,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      curve: 'straight',
      lineCap: 'butt',
      colors: undefined,
      width: 3,
      dashArray: 0,
    },
    title: {
      text: "",
      align: "left",
    },
    grid: {
      show: true,
      borderColor: "#F0F0F0",
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    xaxis: {
      categories: [],
    },
    yaxis: [
      {
        axisTicks: {
          show: true,
        },
      },
    ],
  });

  const [series, setSeries] = useState([
    {
      name: "Revenue",
      data: [0, 0, 0, 0, 0, 0, 0],
    },
  ]);
  const [extraInfo, setExtraInfo] = useState('');

  useEffect(() => {
    if (startDate && endDate && Object?.keys(graphStaticData)?.length !== 0) {
      graphsFilter();
    }
  }, [graphStaticData, dateRange, selectedSubCat, selectedNameData]);

  function graphsFilter() {
    cutomizeHz();
    let verticalAxisArr = [];
    // const arrayUniqueByKey = [...new Map(array.map(item =>
    //     [item.age, item])).values()];
    // const unique = [...new Set(graphStaticData[selectedSubCat].map((item) => item.adminId))];

    if (selectedCat === "Subscriptions") {
      if (selectedSubCat === "newSignUps") {
        setExtraInfo('');
        for (let i = 0; i < helperArrDate?.length; i++) {
          const filterLength = graphStaticData?.signUps?.filter(filterByDate(helperArrDate[i], "created_at"))?.length;
          verticalAxisArr.push(filterLength);
        }
      } else if (selectedSubCat === "activations") {
        setExtraInfo('');
        for (let i = 0; i < helperArrDate?.length; i++) {
          const filterLength = graphStaticData.activations?.filter(filterByDate(helperArrDate?.[i], 'planstartDate'))?.length;
          verticalAxisArr?.push(filterLength);
        }
      } else if (selectedSubCat === "cancellations") {
        setExtraInfo('');
        for (let i = 0; i < helperArrDate?.length; i++) {
          const filterLength = graphStaticData.cancellations?.filter(filterByDate(helperArrDate[i], 'created_at'))?.length;
          verticalAxisArr.push(filterLength);
        }
      } else if (selectedSubCat === "ChurnRate") {
        setExtraInfo('');
        for (let i = 0; i < helperArrDate?.length; i++) {
          const churnObj = graphStaticData?.churnRate?.find(item => item.date === formatDate(helperArrDate[i]));
          const activeNos = churnObj?.activeSubscriptions > 0 ? churnObj?.activeSubscriptions : 1;
          const deactiveNos = churnObj?.deactiveSubscriptions > 0 ? churnObj?.deactiveSubscriptions : 0;
          const churnCount = (deactiveNos / activeNos) * 100;
          const valueToDeduct = verticalAxisArr?.[i - 1] >= churnCount ? verticalAxisArr[i - 1] : 0;
          verticalAxisArr.push(limitToTwoDecimal(churnCount - valueToDeduct));
        }
      } else if (selectedSubCat === "DeadTrials") {
        setExtraInfo('(cummulative)');
        for (let i = 0; i < helperArrDate?.length; i++) {
          let deadTrialsLength = graphStaticData?.deadTrialArray?.filter(
            filterByDate(helperArrDate[i], "createdAt")
          )?.length;
          verticalAxisArr.push(i > 0 ? (deadTrialsLength + verticalAxisArr[i - 1]) : deadTrialsLength);
        }
      } else if (selectedSubCat === "TrialsInProgress") {
        setExtraInfo('');
        for (let i = 0; i < helperArrDate?.length; i++) {
          const deadTrialsLength = graphStaticData?.trialActiveArray?.filter(
            trialsActiveFilter(helperArrDate[i]))?.length;
          verticalAxisArr.push(deadTrialsLength);
        }
      }
    } else if (selectedCat === "Revenue") {
      setExtraInfo('');
      if (selectedSubCat === "MRR") {
        const monthlyData = groupByMonth(graphStaticData?.MRR);
        for (let i = 0; i < helperArrDate?.length; i++) {
          const month = helperArrDate[i].getMonth() + 1;
          const year = helperArrDate[i].getFullYear();
          const key = `${year}-${month}`;
          const mrr = monthlyData?.find(item => item.monthYear === key);
          verticalAxisArr.push(mrr?.totalAmt ?? 0);
        }
      } else if (selectedSubCat === "ARPU") {
        for (let i = 0; i < helperArrDate?.length; i++) {
          const mrr = graphStaticData?.allCharges?.filter(item => item.charge_date === formatDate(helperArrDate[i]));
          const uniqueIds = new Set();
          mrr.forEach(item => uniqueIds.add(item?.carehomedata?._id));
          const mrrCount = mrr.reduce((acc, obj) => acc + (+obj.amount ?? 0), 0);
          const arpu = uniqueIds.size > 0 ? (mrrCount / uniqueIds.size) : 0;
          verticalAxisArr.push(limitToTwoDecimal(arpu > 0 ? arpu : 0));
        }
      } else if (selectedSubCat === "MSCR") {
        setExtraInfo('');
        for (let i = 0; i < helperArrDate?.length; i++) {
          let freeClientUpgradLength =
            graphStaticData?.activations?.filter(item => formatDate(item.planstartDate) === formatDate(helperArrDate[i]))?.length;
          const freeClientLength = graphStaticData?.trialActiveArray?.filter(trialsActiveFilter(helperArrDate[i]))?.length;
          const mscrLength = freeClientLength > 0 ? ((freeClientUpgradLength / (freeClientUpgradLength + freeClientLength)) * 100) : 0;
          verticalAxisArr.push(limitToTwoDecimal(mscrLength));
        }
      } else if (selectedSubCat === "TotalRevenue") {
        setExtraInfo('');
        for (let i = 0; i < helperArrDate?.length; i++) {
          const mrr = graphStaticData?.allCharges?.filter(item => item.charge_date === formatDate(helperArrDate[i]));
          // filterByDateRangeArpu(helperArrDate[0], helperArrDate[i], "charge_date")
          const mrrCount = mrr.reduce((acc, obj) => acc + (+obj.amount ?? 0), 0);
          verticalAxisArr.push(limitToTwoDecimal(mrrCount));
        }
      } else if (selectedSubCat === 0 && selectedNameData) {
        for (let i = 0; i < helperArrDate?.length; i++) {
          const filterData = graphStaticData?.allCharges?.filter(item => (item.charge_date === formatDate(helperArrDate[i]) && 
          item?.carehomedata?.chargesDetails?.[0]?.discription === selectedNameData.label));
          let cummAmount = 0;
          filterData?.forEach((item) => {
            if (item?.carehomedata?.chargesDetails[selectedSubCat]?.rate) {
              let chargesDetails1 = +item?.carehomedata?.chargesDetails[0]?.rate * +item?.carehomedata?.chargesDetails[0]?.qty;
              let chargesDetails2 = +item?.carehomedata?.chargesDetails[1]?.rate * +item?.carehomedata?.chargesDetails[1]?.qty;
              let totalAmount = chargesDetails1 + chargesDetails2;
              let planData = chargesDetails1 / totalAmount;
              const finalPlanAmount = (planData) * item?.amount
              cummAmount = finalPlanAmount > 0 ? finalPlanAmount : 0;
            }
          });
          verticalAxisArr.push(limitToTwoDecimal(cummAmount));
        }
      } else if (selectedSubCat === 1) {
        for (let i = 0; i < helperArrDate?.length; i++) {
          const filterData = graphStaticData?.allCharges?.filter(item => item.charge_date === formatDate(helperArrDate[i]));
          let cummAmount = 0;
          filterData?.forEach((item) => {
            if (item?.carehomedata?.chargesDetails[selectedSubCat]?.rate) {
              let chargesDetails1 = +item?.carehomedata?.chargesDetails[0]?.rate * +item?.carehomedata?.chargesDetails[0]?.qty;
              let chargesDetails2 = +item?.carehomedata?.chargesDetails[1]?.rate * +item?.carehomedata?.chargesDetails[1]?.qty;
              let totalAmount = chargesDetails1 + chargesDetails2;
              let planData = chargesDetails1 / totalAmount;
              let addOns = chargesDetails2 / totalAmount;
              const finalPlanAmount = (selectedSubCat === 0 ? planData : addOns) * item?.amount
              cummAmount = finalPlanAmount > 0 ? finalPlanAmount : 0;
            }
          });
          verticalAxisArr.push(limitToTwoDecimal(cummAmount));
        }
      } else if (
        selectedSubCat === 2
      ) {
        for (let i = 0; i < helperArrDate?.length; i++) {
          const filterData = graphStaticData?.allCharges?.filter(
            filterByDateRange(helperArrDate[i], "charge_date")
          );
          let cummAmount = 0;
          filterData?.forEach((item) => {
            cummAmount = + item?.carehomedata?.unit_amount - item?.amount
          });
          verticalAxisArr.push(cummAmount);
          // if (verticalAxisArr?.length > 0) {
          //     verticalAxisArr.push(verticalAxisArr[i - 1] <= cummAmount ? cummAmount : verticalAxisArr[i - 1]);
          // } else {
          // }
        }
      }
      else if (selectedSubCat === "CountryWise" && selectedNameData) {
        for (let i = 0; i < helperArrDate?.length; i++) {
          const revenue = graphStaticData?.allCharges?.filter(
            filterByNameRevenue(
              helperArrDate[i],
              "charge_date",
              selectedNameData?.value,
              "country"
            )
          );
          const totalRevenue = revenue.reduce((acc, obj) => acc + (+obj.amount ?? 0), 0);
          verticalAxisArr.push(limitToTwoDecimal(totalRevenue));
        }
      } else if (selectedSubCat === "RevenueByClient" && selectedNameData) {
        setExtraInfo('');
        for (let i = 0; i < helperArrDate?.length; i++) {
          let revenue = graphStaticData?.allCharges?.filter(
            filterByNameRevenue(
              helperArrDate[i],
              "charge_date",
              selectedNameData?.value,
              "adminId"
            )
          );
          let totalRevenue = 0;
          if (revenue?.length > 0) {
            revenue.forEach((elem) => {
              totalRevenue = totalRevenue + elem.amount;
            });
          }
          if (verticalAxisArr?.length > 0) {
            verticalAxisArr.push(
              verticalAxisArr[i - 1] <= totalRevenue.toFixed(2)
                ? totalRevenue.toFixed(2)
                : verticalAxisArr[i - 1]
            );
          } else {
            verticalAxisArr.push(totalRevenue.toFixed(2));
          }
        }
      } else if (selectedSubCat === "SalesPerson" && selectedNameData) {
        for (let i = 0; i < helperArrDate?.length; i++) {
          let revenue = graphStaticData?.allCharges?.filter(
            filterByNameRevenue(
              helperArrDate[i],
              "charge_date",
              selectedNameData?.value,
              "salesPerson"
            )
          );
          let totalRevenue = 0;
          if (revenue?.length > 0) {
            revenue.forEach((elem) => {
              totalRevenue = totalRevenue + elem.amount;
            });
          }
          if (verticalAxisArr?.length > 0) {
            verticalAxisArr.push(
              verticalAxisArr[i - 1] <= totalRevenue.toFixed(2)
                ? totalRevenue.toFixed(2)
                : verticalAxisArr[i - 1]
            );
          } else {
            verticalAxisArr.push(totalRevenue.toFixed(2));
          }
        }
      }
    } else if (selectedCat === "Clients" && selectedSubCat) {
      for (let i = 0; i < helperArrDate?.length; i++) {
        const filterLength = graphStaticData?.[selectedSubCat]?.filter(
          filterByDate(helperArrDate?.[i], "createdAt")
        )?.length;
        verticalAxisArr?.push(i > 0 ? (filterLength + verticalAxisArr?.[i - 1]) : filterLength);
      }
    } else if (selectedCat === "Payments" && selectedSubCat) {
      setExtraInfo('');
      if (selectedSubCat === "paymentFailure") {
        for (let i = 0; i < helperArrDate?.length; i++) {
          let paymentFailLength = graphStaticData?.allFailedPayments?.filter(item => formatDate(item.charge_date) === formatDate(helperArrDate[i]))?.length;
          verticalAxisArr.push(paymentFailLength);
        }
      } else if (selectedSubCat === "Refunds") {
        for (let i = 0; i < helperArrDate?.length; i++) {
          let paymentRefundLength = graphStaticData?.allRefunds?.filter(item => formatDate(item.charge_date) === formatDate(helperArrDate[i]))?.length;
          verticalAxisArr.push(paymentRefundLength ?? 0);
        }
      } else if (selectedSubCat === "DaysToPayment") {
        for (let i = 0; i < helperArrDate?.length; i++) {
          let paymentRefundLength = graphStaticData?.daysToPaymentArray?.filter(item => item.charge_date === formatDate(helperArrDate[i]))?.length;
          verticalAxisArr.push(paymentRefundLength ?? 0);
        }
      }
    }
    setSeries([
      {
        name: selectedLabel,
        data: verticalAxisArr,
      },
    ]);
  }

  function trialsActiveFilter(dateVal) {
    return function (item) {
      const trialStrt = new Date(item.trial_start);
      const trialEnd = new Date(item.trial_end);
      return (
        trialEnd >= new Date(dateVal) && trialStrt <= new Date(dateVal)
      );
    };
  }

  function filterByNameRevenue(dateVal, dateKey, searchKey, type) {
    return function (item) {
      // let dataDate = formatDate(item[dateKey]);
      let hzDate = formatDate(dateVal);
      return (
        item[dateKey] === hzDate &&
        searchKey === item?.carehomedata?.[type]
      );
    };
  }

  function filterByDateRange(dateVal, dateKey) {
    return function (item) {
      let dataDate = new Date(item[dateKey]).getTime();
      let stDate = startDate.getTime();
      let hzDate = new Date(dateVal).getTime();
      return dataDate >= stDate && dataDate <= hzDate;
    };
  }

  function filterByDate(maxDate, dateKey) {
    return function (item) {
      return (formatDate(item[dateKey]) === formatDate(maxDate)
      );
    };
  }

  function cutomizeHz() {
    helperArrDate = [];
    let customDates = [];
    let customBasedHz = [];
    const getDateDifference = (date1, date2) => {
      const diffTime = Math.abs(date2 - date1);
      return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    };
    let difference = getDateDifference(startDate, endDate);
    for (let i = 0; i < difference + 1; i++) {
      let finalDate = new Date(startDate);
      if (i > 0) {
        let dayByInterval = new Date(helperArrDate[0]).getDate() + i;
        finalDate.setDate(dayByInterval);
        if (finalDate > endDate) {
          finalDate = new Date(endDate);
        }
      }
      let formatedDate = formatDateToDDMMYYYY(finalDate);
      helperArrDate.push(finalDate);
      let changedFormat = finalDate.toLocaleDateString("en-GB").slice(0, 5);
      customDates.push(changedFormat);
      if (finalDate > endDate) {
        break;
      }
    }
    setOptions((prevState) => {
      return {
        ...prevState,
        xaxis: {
          categories: customDates,
          floating: true,
        },
        title: {
          text: '',
          // text: `${selectedLabel.toUpperCase()} (Cumm)`,
          align: "left",
        },
      };
    });
  }

  function formatDateToDDMMYYYY(dateStr) {
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  return (
    <div className={`graph_box ${styles?.graphdiv}`}>
      <h5>{selectedLabel?.toUpperCase()} &nbsp;<span style={{ fontSize: '14px' }}>{extraInfo}</span></h5>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="line"
          height={450}
        />
      </div>
    </div>
  );
}

function groupByMonth(data) {
  const groupedData = {};

  data?.forEach(item => {
    if (item.created) {
      const date = new Date(item.created);
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      const key = `${year}-${month}`;
      if (!groupedData[key]) {
        groupedData[key] = [];
      }
      groupedData[key].push(item);
    }
  });

  const totalByMonth = Object.keys(groupedData).map(item => {
    const totalAmt = groupedData?.[item]?.reduce((acc, obj) => acc + (+obj.amount ?? 0), 0)
    return {
      monthYear: item,
      totalAmt: limitToTwoDecimal(totalAmt)
    }
  })

  return totalByMonth;
}