import React, { useRef, useState, useEffect } from "react";
import { NavLink, Switch, Route, useHistory, useRouteMatch, useLocation } from "react-router-dom";
import "../Invoicing/invoicing.css";
// import {BsFilterSquare} from "react-icons/bs";
import { BsSearch } from "react-icons/bs";
import DatePicker from "react-datepicker";
import { getMondayDate, getMonthAgoDate } from "../../utils/CommonFunctions";
import InvoiceRoutes from "./InvoiceRoutes";

// import {MdKeyboardArrowRight} from 'react-icons/md';
let monthAgoDate = getMonthAgoDate();
let today = new Date();

function Invoicing() {

  const history = useHistory();
  const location = useLocation();
  let { path, url } = useRouteMatch();

  const [filterType, setFilterType] = useState("week");

  const [dateRange, setDateRange] = useState([monthAgoDate, new Date()]);
  const [startDate, endDate] = dateRange;

  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    console.log(location.pathname, 'lllllllllll')
    let pathname = window?.location?.pathname;
    if (pathname?.includes('configure') || pathname?.includes('invoices')) {
      // console.log(window.location.pathname, 'jjjjjjjjjjjjjj')
    } else {
      handleNavigate('invoices');
    }
  }, [location.pathname]);

  function customDateChangeHandler(update) {
    setDateRange(update);
  }

  const handleNavigate = (type) => {
    history.replace({
      pathname: `${url}/${type}`,
    });
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="container-fluid min_height">
          <div className="card">
            <div className="card-body" style={{height: "70vh"}}> 

              <div className="row">
                <div className="col-md-4">
                  <ul
                    className="nav nav-tabs ract_tab_list border-0 listspaceGap"
                    id="myTab"
                    role="tablist"
                  >
                    <li className="nav-item me-3" role="presentation">
                      <p
                        onClick={() => handleNavigate('invoices')}
                        className={`nav-link curser_point ${window.location.href.includes("invoices") ? "active" : ""
                          }`}
                      >
                        Invoices
                      </p>
                    </li>

                    <li className="nav-item" role="presentation">
                      <p
                        onClick={() => handleNavigate('configure')}
                        className={`nav-link curser_point ${window.location.href.includes("configure") ? "active" : ""
                          }`}
                      >
                        Configure
                      </p>
                    </li>

                  </ul>
                </div>
                <div className="col-md-3 searchForm" style={{ marginLeft: "0px", transform: 'translate(-6%, 0%)' }}>
                  <input
                    type="text"
                    className="form-control"
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    placeholder="search"
                  />
                  <BsSearch className="icon me-2" />
                </div>
                {window.location.href.includes("invoices") &&
                  <div className="col-md-5">
                    <div className="col-md-12 dateFilter_sales">
                      <div className="">
                        <ul className="filter_box">
                          {/* <NavLink
                          exact
                          activeClassName={filterType === "today" ? "active" : ""}
                          to="#"
                          onClick={() => setFilterType("today")}
                        >
                          Today
                        </NavLink> */}
                          <NavLink
                            activeClassName={
                              filterType === "week" ? "active mx-3" : "mx-3"
                            }
                            to="#"
                            onClick={() => setFilterType("week")}
                          >
                            This Week
                          </NavLink>
                          <NavLink
                            activeClassName={
                              filterType === "custom" ? "active" : ""
                            }
                            to="#"
                            onClick={() => setFilterType("custom")}
                          >
                            Custom
                          </NavLink>
                        </ul>
                      </div>
                      {filterType === "custom" ? (
                        <div className="input_space">
                          <DatePicker
                            selectsRange={true}
                            dateFormat="dd/MM/yyyy"
                            startDate={startDate}
                            endDate={endDate}
                            maxDate={today}
                            onChange={(update) => {
                              customDateChangeHandler(update);
                            }}
                            isClearable={true}
                          />
                        </div>
                      ) : (
                        <div className="input_space">&ensp;</div>
                      )}
                    </div>
                  </div>}
              </div>

              <div className="tab-content mt-3" id="myTabContent">
                <Switch>
                  <Route path={`${path}/:topicId`}>
                    <InvoiceRoutes
                      searchText={searchText}
                      dateRange={dateRange}
                      filterType={filterType} />
                  </Route>
                </Switch>
              </div>

            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default Invoicing;
