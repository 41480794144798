import React, { useState } from "react";
import InvoiceDetail from "./InvoiceDetail";
import { useSelector } from "react-redux";
import DataGridTable from "../../utils/DataGridTable";
import { IoIosArrowForward } from "react-icons/io";
import { BsSearch } from "react-icons/bs";
import InvoiceActions from "./InvoiceActions";
import { filterData } from "../../utils/TableSearchFilter";
import { sortByLatestDateFirst } from "../../utils/CommonFunctions";
import { twoDecimalPlaces } from "../../utils/utilsHandlerFunctions";

function Invoices() {

  const { invoiceAllData } = useSelector((state) => state.superAdminReducer);
  const [invoiceData, setInvoiceData] = useState(null);
  const [searchTxt, setSearchTxt] = useState("");
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [getLatestInvioceCount, setGetLatestInvioceCount] = useState(0);

  function getLatestInvioceData() {
    setGetLatestInvioceCount(getLatestInvioceCount + 1);
  }

  const rowClickHandler = (id) => {
    setSelectedRowIds([]);
    invoiceAllData.forEach((elem) => {
      if (elem._id === id) {
        setInvoiceData(elem);
      }
    });
  };

  const columns = [
    {
      field: "companyName",
      headerName: "Company Name",
      flex: 1,
      minWidth: 150,
    },
    { field: "careSiteName", headerName: "Site Name", flex: 1, minWidth: 90 },
    { field: "customId", headerName: "Invoice ID", flex: 1, minWidth: 20 },
    {
      field: "paymentGatewayId",
      headerName: "Payment Gateway ID",
      flex: 1,
      minWidth: 120,
    },
    { field: "planType", headerName: "Plan Type", minWidth: 90 },
    { field: "invoiceDate", headerName: "Invoice Date", minWidth: 100 },
    {
      field: "total",
      headerName: "Value",
      minWidth: 90,
      renderCell: (fieldData) => <DisplayDecimal fieldData={fieldData} />,
    },
    { field: "currency", headerName: "Currency", minWidth: 100 },
    {
      field: "status",
      headerName: "Status",
      minWidth: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (fieldData) => <StatusBtn fieldData={fieldData} />,
    },
    {
      id: "arrow",
      width: 50,
      numeric: true,
      disablePadding: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (fieldData) => <RedirectionIcon fieldData={fieldData} />,
    },
  ];

  const RedirectionIcon = (props) => {
    const { fieldData } = props;
    return (
      <IoIosArrowForward
        style={{ cursor: "pointer", fontSize: "20px" }}
        onClick={() => rowClickHandler(fieldData.row._id)}
      />
    );
  };
  const DisplayDecimal = (props) => {
    const { fieldData } = props;
    return <>{twoDecimalPlaces(fieldData.row.total)}</>;
  };

  let roleAccess = JSON.parse(localStorage.getItem("__csadmin__d"));
  const filteredRowData = filterData(
    columns?.map((item) => ({
      id: item.field,
      label: item.headerName,
    })),
    invoiceAllData,
    searchTxt
  );

  return (
    <div className="page-wrapper">
      <div className="card">
        <div className="card-body">
          {roleAccess?.role?.map((roletype) => {
            const invoiceAccess = roletype.Modules[4];
            if (invoiceAccess?.access !== "full")
              return <div className="clickOff"></div>;
          })}
          {!invoiceData && (
            <>
              <div className="row mb-2 justify-content-end">
                <div className="searchForm careSearch">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    value={searchTxt}
                    onChange={(e) => setSearchTxt(e.target.value)}
                  />
                  <BsSearch className="icon me-2" />
                </div>
                <div className="col-md-6">
                  <InvoiceActions
                    selectedData={selectedRowIds}
                    getLatestInvioceData={getLatestInvioceData}
                  />
                </div>
              </div>
              <DataGridTable
                columns={columns}
                rows={filteredRowData}
                handleRowSelection={(rowId) => setSelectedRowIds(rowId)}
              />
            </>
          )}
          {invoiceData && (
            <InvoiceDetail
              setShowInvoiceDetails={setInvoiceData}
              invoiceDetail={invoiceData}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Invoices;

const StatusBtn = (props) => {
  const { fieldData } = props;
  const status = fieldData?.row?.status;
  return (
    <button
      className={`btn btn-sm table_btn ${status === "paid" ? "btn-success" : status === "refunded" ? "btn-primary" : "btn-danger"
        }`}
    >
      {status === "paid" ? "paid" : status === "refunded" ? "refunded" : "unpaid"}
    </button>
  );
};
