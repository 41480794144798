import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import { Modal, Dropdown, DropdownButton } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import NoRowsOverlay from "../../components/NoRowsOverlays";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/system";
import swal from "sweetalert";
import { httpAdmin } from "../../Apis/commonApis";
import { currentCareSiteId, getCareSiteListing } from "../../redux-toolkit/reducer/switchSites";
import { getChangedKeysVals } from "../../utils/CommonFunctions";
import AwsLocation from "../../utils/AwsLocation";

const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-cell:focus": {
    outline: "none",
  },
}));

const StatusButton = (props) => {
  let { params } = props;

  return (
    <div>
      {params?.row?.isFandFappActive && (
        <button className={`btn table_btn_font btn-success`}>Active</button>
      )}
      {!params?.row?.isFandFappActive && (
        <button className={`btn table_btn_font btn-danger`}>Inactive</button>
      )}
    </div>
  );
};

const ConsentAuthorityField = (props) => {
  let { params } = props;

  return (
    <div>
      <span>{params.row.consentAuthority == "yes" && "Yes"}</span>
      <span>{params.row.consentAuthority != "yes" && "No"}</span>
    </div>
  );
};

const columns = [
  { field: "name", headerName: "Name", width: 190 },
  {
    field: "relation",
    headerName: "Relation",
    width: 150,
    editable: true,
  },
  {
    field: "address",
    headerName: "Address",
    width: 170,
    editable: true,
  },
  {
    field: "phone",
    headerName: "Telephone",
    width: 150,
    editable: true,
  },
  {
    field: "email",
    headerName: "Email Address",
    width: 150,
    editable: false,
  },
  {
    field: "consentAuthority",
    headerName: "Consent Authority",
    width: 150,
    editable: true,
    renderCell: (params) => <ConsentAuthorityField params={params} />,
  },
  {
    field: "isFandFappActive",
    headerName: "Friends&Family App",
    width: 150,
    editable: true,
    renderCell: (params) => <StatusButton params={params} />,
  },
];

const AddEditContact = (props) => {
  const { clientAllData, clientId } = props;
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const [openAddEditModal, setOpenAddEditModal] = useState(false);
  const [ids, setIDs] = useState([]);
  const [keyContactRows, setKeyContactRows] = useState([]);
  const [editKeyContactData, setEditKeyContactData] = useState(null);
  // const [locErrorMsg, setlocErrorMsg] = useState(true);
  const [location, setlocation] = useState(null);

  useEffect(() => {
    if (clientAllData) {
      setKeyContactRows(clientAllData.keycontact);
    }
  }, [clientAllData]);

  function editKeyContactEvent(id) {
    const keyContactData = keyContactRows.find((mid) => mid._id == id);
    setEditKeyContactData(keyContactData);
    setlocation(keyContactData.address);
    setmobileNo(keyContactData.phone?.toString());
    reset();
    setOpenAddEditModal(true);
  }

  function handleModalClose() {
    setEditKeyContactData(null);
    setOpenAddEditModal(false);
    setmobileNo('');
    setlocation('');
    reset();
  }

  function onAddEditContactSubmit(postdata) {
    const userData = JSON.parse(localStorage.getItem("userData"));
    let phoneNoVal = mobileNo ? mobileNo : editKeyContactData.phone?.toString();
    let formVals = { ...postdata };
    if (location) {
      formVals.address = location?.Label;
    }
    formVals.phone = phoneNoVal;
    let idVals = {
      adminId: userData._id,
      careHomeId: currentCareSiteId,
      clientId: clientId,
    }
    if (editKeyContactData) {
      let editData = getChangedKeysVals(editKeyContactData, formVals);
      idVals.keycontact_id = editKeyContactData._id;
      let body = { ...idVals, ...editData }
      editKeyContacts(body);
    } else {
      let body = { ...idVals, ...formVals }
      createKeyContacts(body);
    }
  }

  const createKeyContacts = async (body) => {
    const result = await httpAdmin(`keyContact/${clientId}`, "post", body, null);
    if (result.status) {
      let keyContData = result.docs?.keycontact;
      if (keyContData) setKeyContactRows(keyContData);
    }
    handleModalClose();
    swal(
      result.status ? "Success" : "Failed",
      result.message,
      result.status ? "success" : "error"
    );
  };

  const editKeyContacts = async (body) => {
    const result = await httpAdmin(`editKeycontact/${clientId}`, "post", body, null);
    if (result.status) {
      let keyContData = result.docs?.keycontact;
      if (keyContData) setKeyContactRows(keyContData);
    }
    handleModalClose();
    swal(
      result.status ? "Success" : "Failed",
      result.message,
      result.status ? "success" : "error"
    );
  };

  let params = useParams();

  const getClientbyId = () => {
    axios({
      url: `${process.env.REACT_APP_BASEURL}/clientbyId?clientId=${clientId}&careHomeId=${currentCareSiteId}`,
      method: "GET",
      headers: { Authorization: localStorage.getItem("care_admin_token") },
    })
      .then((res) => {
        let keycontactData = res.data.clientData?.keycontact;
        if (keycontactData) setKeyContactRows(keycontactData);
      })
      .catch((error) => console.log(`Error: ${error}`));
  };

  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  useEffect(() => {
    setIDs(rowSelectionModel);
  }, [rowSelectionModel]);

  // -----------  Delete Clients Popup (Show and Hide Handler) Starts ------------ //

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const handleCloseDeletePopup = () => setIsDeleteOpen(false);
  const handleShowDeletePopup = () => setIsDeleteOpen(true);

  // ---------------------     delete multiple  api    ----------------------------------  //

  async function deletemultiple() {
    const result = await httpAdmin(`deleteKeyContact`, "post", { clientId: clientId, keyContactId: ids }, null);
    if (result.status) {
      let keycontactData = result.data[0]?.keycontact;
      if (keycontactData) setKeyContactRows(keycontactData);
      // getClientbyId();
    }
    handleCloseDeletePopup();
    swal(
      result.status ? "Success" : "Failed",
      result.message,
      result.status ? "success" : "error"
    )
  }

  // -----------   Change friends and family status  Popup (Show and Hide Handler) Starts ------------ //

  const [selectedStatus, setSelectedStatus] = useState(null);
  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const handleCloseStatusPopup = () => setIsStatusOpen(false);
  const handleShowStatusPopup = () => setIsStatusOpen(true);

  // --------------------- change the friends and family status (api) ------------------------- //

  async function changeFriendsAndFamilyStatus() {
    if (!selectedStatus) {
      swal("Failed", 'Select Status', "error")
      return;
    }
    let statusVal = (selectedStatus === 'active') ? true : false;
    let careSiteData = getCareSiteListing?.find(item => item?._id === currentCareSiteId);
    const fandfLicCount = careSiteData?.chargesDetails[1]?.qty;
    const emails = keyContactRows
      .filter((contact) => ids.includes(contact._id))
      .map((contact) => contact.email);
    const data = {
      client_id: clientId,
      careHomeId: currentCareSiteId,
      keycontact_id: ids,
      totalFandFapp: fandfLicCount,
      isFandFappActive: statusVal,
      email: emails,
    };
    const result = await httpAdmin(`attachFandFapp`, "post", data, null);
    if (result.status) {
      getClientbyId();
    }
    handleCloseStatusPopup();
    swal(
      result.status ? "Success" : "Failed",
      result.message,
      result.status ? "success" : "error"
    );
  }

  function awsLocationHandler(locVal) {
    setlocation(locVal);
  }

  const [mobileNo, setmobileNo] = useState("");
  function phoneInput(value, data) {
    setmobileNo(value);
    // setDialCode(`+${data.dialCode}`);
    // setcuntrycode(`${data.countryCode}`);
  }

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="btns_head topButtonShow">
            {" "}
            <DropdownButton
              id="dropdown-basic-button"
              title="Actions"
              className="d-flex justify-content-end"
            >
              <Dropdown.Item
                onClick={() => {
                  setOpenAddEditModal(true);
                }}
                disabled={props.Contactaccess !== 'full'}
              >
                Add Contact
              </Dropdown.Item>
              <Dropdown.Item
                // className="action_table_btn"
                data-bs-toggle="modal"
                disabled={props.Contactaccess !== 'full'}
                onClick={() => {
                  if (ids.length === 1) {
                    editKeyContactEvent(ids[0]);
                  } else {
                    swal(
                      "Failed",
                      "Please Select only one client",
                      "error"
                    ).then((value) => {
                      return;
                    });
                  }
                }}
              >
                Edit
              </Dropdown.Item>
              <Dropdown.Item
                disabled={props.Contactaccess !== 'full'}
                onClick={() => {
                  if (ids.length > 0) handleShowDeletePopup(true);
                  else
                    swal(
                      "Failed",
                      "Please Select at least one key contact",
                      "error_style"
                    ).then((value) => {
                      return;
                    });
                }}
              >
                Delete{" "}
              </Dropdown.Item>
              <Dropdown.Item
                disabled={props.Contactaccess !== 'full'}
                onClick={() => {
                  if (ids.length > 0) handleShowStatusPopup(true);
                  else
                    swal(
                      "Failed",
                      "Please Select at least one key contact",
                      "error"
                    ).then((value) => {
                      return;
                    });
                }}
              >
                Change Status{" "}
              </Dropdown.Item>
            </DropdownButton>
          </div>

          <div style={{ height: "80vh", width: "100%", paddingTop: "0px" }}>
            <CustomDataGrid
              columns={columns}
              rows={keyContactRows}
              // autoHeight
              checkboxSelection
              onSelectionModelChange={(newRowSelectionModel) => {
                setRowSelectionModel(newRowSelectionModel);
              }}
              disableSelectionOnClick
              getRowId={(row) => row?._id}
              rowSelectionModel={rowSelectionModel}
              slots={{
                noRowsOverlay: NoRowsOverlay,
              }}
            />
          </div>

          {/* Add and Edit key Contacts */}
          <Modal
            show={openAddEditModal}
            onHide={handleModalClose}
          >
            <Modal.Body>
              <div
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <form onSubmit={handleSubmit(onAddEditContactSubmit)}>
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        {editKeyContactData ? 'Edit' : 'Add'} Key Contact
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={handleModalClose}
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">Name</label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={editKeyContactData?.name}
                            {...register("name", {
                              required: true,
                              pattern: /^[A-Za-z ]+$/i,
                            })}
                          />
                          {errors?.name?.type === "required" && (
                            <p className="error_style">This field is required</p>
                          )}
                        </div>
                        <div className="col-md-12 mb-3">
                          <label className="form-label">Relation</label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={editKeyContactData?.relation}
                            {...register("relation", {
                              required: true,
                            })}
                          />

                          {errors?.relation?.type === "required" && (
                            <p className="error_style">This field is required</p>
                          )}
                        </div>
                        <div className="col-md-12 mb-3">
                          <label className="form-label">Address</label>
                          <AwsLocation awsLocationHandler={awsLocationHandler}
                            defaultVal={editKeyContactData?.address}
                          />
                          {/* {errors?.address?.type === "required" &&
                            locErrorMsg && (
                              <p className="error_style">This field is required</p>
                            )} */}
                        </div>

                        <div className="col-md-12 mb-3">
                          <label className="form-label">Email</label>
                          <input
                            type="email"
                            className="form-control"
                            defaultValue={editKeyContactData?.email}
                            {...register("email", {
                              required: true,
                              pattern:
                                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            })}
                          />
                          {errors?.email?.type === "pattern" && (
                            <p className="error_style">Invalid email address</p>
                          )}
                          {errors?.email?.type === "required" && (
                            <p className="error_style">This field is required</p>
                          )}
                        </div>

                        <div className="col-md-12 mb-3">
                          <label className="form-label">
                            Consent Authority
                          </label>
                          {/* React Select Along with React Hook Form */}
                          <select className="form-select"
                            defaultValue={editKeyContactData?.consentAuthority}
                            {...register("consentAuthority", {
                              required: true,
                            })}
                            name="consentAuthority" >
                            <option value=''>--Select--</option>
                            <option value='yes'>Yes</option>
                            <option value='no'>No</option>
                          </select>
                          {errors?.consentAuthority?.type === "required" && (
                            <p className="error_style">This field is required</p>
                          )}
                          {/* <Controller
                            control={control}
                            name="consentAuthority"
                            defaultValue={defaultVal}
                            rules={{
                              required: "Consent Authority is required",
                            }}
                            render={({ field }) => {
                              return (
                                <Select
                                  {...field}
                                  isClearable
                                  isSearchable={false}
                                  className="react-dropdown"
                                  classNamePrefix="dropdown"
                                  options={BooleanOptions}
                                // onChange={handleStatusChange}
                                />
                              );
                            }}
                          />
                          <p className="error_style">
                            {errors.consentAuthority?.message ||
                              errors.consentAuthority?.label.message}
                          </p> */}
                        </div>

                        <div className="col-md-12 mb-3">
                          <label className="form-label">Telephone</label>

                          <PhoneInput
                            defaultMask={mobileNo}
                            country={"us"}
                            value={mobileNo}
                            onChange={phoneInput}
                            isValid={(value, country) => {
                              if (
                                value.match(
                                  /\+?\d{1,9}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/g
                                )
                              ) {
                                return true;
                              } else {
                                return false;
                              }
                            }}
                          />
                          {/* <Controller
                            name="phoneNo"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <PhoneInput
                                country={"us"}
                                value={value}
                                onChange={onChange}
                                isValid={(value, country) => {
                                  if (
                                    value.match(
                                      /\+?\d{1,9}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/g
                                    )
                                  ) {
                                    return true;
                                  } else {
                                    return false;
                                  }
                                }}
                              />
                            )}
                          /> */}
                          {errors.phoneNo && (
                            <p className="error_style">{errors.phoneNo.message}</p>
                          )}
                        </div>
                        {/* <div className="col-md-12 mb-3">
													<label className="form-label">File</label>
													<input type="file" onChange={(e) => setimage(e.target.files[0])} className="form-control" />
												</div> */}
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="submit"
                        className="btn btn-success"
                      // data-bs-dismiss="modal"
                      // onClick={}
                      >
                        {editKeyContactData ? 'Save' : 'Create'}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </Modal.Body>
          </Modal>

          {/* delete key Contacts */}
          <Modal
            className="viewModal"
            show={isDeleteOpen}
            onHide={handleCloseDeletePopup}
          >
            <Modal.Header>
              <Modal.Title>
                <span>Delete Client</span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-12">
                  <div className="">
                    <p>
                      Are you sure you want to delete this{" "}
                      {ids.length > 1 ? "clients" : "client"} ?
                    </p>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex">
                <button
                  className="btn submitBtn"
                  onClick={handleCloseDeletePopup}
                >
                  No
                </button>
                <button
                  className="btn submitBtn"
                  onClick={() => {
                    deletemultiple();
                  }}
                >
                  Yes
                </button>
              </div>
            </Modal.Footer>
          </Modal>

          {/* change key Contacts status */}
          <Modal
            className="viewModal"
            show={isStatusOpen}
            onHide={handleCloseStatusPopup}
          >
            <Modal.Header>
              <Modal.Title>
                <span>Change friends and family Status</span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-12 mb-3">
                  <label className="form-label">Status</label>
                  {/* React Select Along with React Hook Form */}
                  <select className="form-select"
                    onChange={(e) => setSelectedStatus(e.target.value)}
                    name="fandfStatus" >
                    <option value=''>--Select--</option>
                    <option value='active'>Active</option>
                    <option value='inactive'>Inactive</option>
                  </select>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex">
                <button
                  type="button"
                  className="btn submitBtn"
                  onClick={handleCloseStatusPopup}
                >
                  Close
                </button>
                <button className="btn submitBtn" onClick={changeFriendsAndFamilyStatus}>
                  Update
                </button>
              </div>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default AddEditContact;
