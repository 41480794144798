import React, { useEffect, Fragment, useState } from "react";
import Moment from "react-moment";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Accordion from "react-bootstrap/Accordion";
import { FaStar } from "react-icons/fa";
import { BiSolidDownArrow } from "react-icons/bi";
import { taskImages } from "../../constants/roles";
import "./clients.css";
import { convertTimeToUserTimeZone, convertToTimeZone, getFullName } from "../../utils/CommonFunctions";
import cx from './clients.module.css'
import { wCategories } from "../../constants/constants";

const ratingArr = [
    { star: 1 },
    { star: 2 },
    { star: 3 },
    { star: 4 },
    { star: 5 },
];
const ratingCount = [1, 2, 3, 4, 5, 6];

const JournalDetailFacility = (props) => {
    let { clientJournal } = props;

    const [notesData, setNotesData] = useState([]);

    function completedTasks(journalData) {
        let tasks = [...journalData?.Task, journalData?.wellbeingAssessment];
        let completed = tasks?.filter((item) => item?.status === "true" && item.shiftId === journalData?._shiftId);
        return [
            completed?.length ? completed?.length : 0,
            tasks?.length ? tasks?.length : 0,
        ];
    }

    useEffect(() => {
        console.log(clientJournal, 'clientJournal')
        if (clientJournal.notesData) {
            const filteredNotes = clientJournal.notesData?.filter((item) =>
                (item?.clientId === clientJournal?.clientId?._id && item.shiftId === clientJournal._shiftId));
            setNotesData(filteredNotes);
        }
    }, [clientJournal]);

    return (
        <>
            <div className="col-md-6 tabbingMenu journalListScroll">
                {clientJournal?.careTeamId?.length > 0 && (
                    <ul className="menuList">
                        <li>
                            <a>
                                <img
                                    alt="careteam-profile"
                                    src={clientJournal?.clientId[0]?.image}
                                />
                                <div className="body">
                                    <p>
                                        {clientJournal?.startingTime} - {clientJournal?.endingTime}{" "}
                                    </p>
                                    <h5>{`${clientJournal?.forClient}`}</h5>

                                    <p className="timeBox">
                                        <span className="ms-3">
                                            <Moment format="Do MMM YYYY">
                                                {clientJournal?.Date}
                                            </Moment>
                                        </span>{" "}
                                    </p>
                                </div>
                            </a>
                        </li>
                    </ul>
                )}
                <div className="starList">
                    {ratingArr.map((item, index) => {
                        return (
                            <button
                                key={index}
                                className={index < clientJournal.fndfRating ? "active" : ""}
                            >
                                <FaStar />
                            </button>
                        );
                    })}
                </div>
                <Tab.Container id="left-tabs-example" defaultActiveKey="taB1">
                    <Nav variant="pills">
                        <Nav.Item>
                            <Nav.Link eventKey="taB1">Tasks</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="taB2">Care Notes</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="taB3">Family Notes</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey="taB1">
                            <ul className={`TasksList ${cx.tasklist}`}>
                                <p style={{ color: "#BB6BD9" }}>
                                    {completedTasks(clientJournal)[0]} of{" "}
                                    {completedTasks(clientJournal)[1]} Tasks Completed
                                </p>
                                {(clientJournal?.wellbeingAssessment?.shiftId === clientJournal?._shiftId && clientJournal?.wellbeingAssessment?.status === "true") && (
                                    <Fragment>
                                        <li>
                                            <div className="listRow">
                                                <img src="/images/s9.svg" />
                                                Wellbeing Assessment
                                                <img src={clientJournal?.wellbeingAssessment?.status === "true" ? "/images/checkedIco.svg" : "/images/blankCheck.svg"} className="boxIcons" />
                                            </div>
                                        </li>
                                        <Accordion className="colorIcon">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header style={{ borderBottom: "none" }}>
                                                    <BiSolidDownArrow />
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <ul>
                                                        {Object.entries(clientJournal?.wellbeingAssessment).map((item, index) => {
                                                            const [wcategory, rating] = item;
                                                            const displayCategory = wCategories.find(cats => cats.wCat === wcategory)?.displayName;
                                                            if (index >= 7) return;
                                                            return (
                                                                <li key={index}>
                                                                    <label>{displayCategory}</label>
                                                                    <div>
                                                                        {ratingCount.map((count) => (
                                                                            <button key={count}>
                                                                                <img className={rating === count ? "wellbineColor" : ""}
                                                                                    src={`/images/is${count}.svg`}
                                                                                />
                                                                            </button>
                                                                        ))}
                                                                    </div>
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                        <p className={`teamNameInNotes`}>{getFullName(clientJournal.shiftdata?.wellbeingAssessmentDoneBy)} ({convertToTimeZone(clientJournal?.wellbeingAssessment?.timestamp)})</p>
                                    </Fragment>
                                )}
                                {clientJournal?.Task?.map((task, index) => {
                                    const conditionsToShowMed = task.medName && task?.prn?.toLowerCase() === 'no';
                                    const conditionsToShowPrn = task.medName && task?.prn?.toLowerCase() === "yes";

                                    const showTask = ((task?.status === "omitted" || task?.status === "true") && task.shiftId === clientJournal._shiftId);

                                    if (conditionsToShowMed && showTask) {
                                        return (
                                            <li key={index}>
                                                <div className="listRow justify-content-between">
                                                    <p>
                                                        <img alt="dd" src={`/images/s10.svg`} />
                                                        Medication Reminder
                                                    </p>
                                                    {task.startTime !== '' && <p className={`teamNameInNotes me-5`}>
                                                        {convertTimeToUserTimeZone(task.startTime, clientJournal.shiftTimeZone)}
                                                    </p>}
                                                    <img src={(task?.status === "true" ? "/images/checkedIco.svg" : "/images/blankCheck.svg")} className="boxIcons" />
                                                </div>
                                                <p>{task?.NM}</p>
                                                <p>
                                                    Dose: {task?.doseAmount} {task?.UDFS_UOMCD}
                                                </p>
                                                <p>Meal Consideration: {task?.mealConsideration}</p>
                                                {Object.keys(task).includes("comments") &&
                                                    task?.comments && (
                                                        <p>Comments: {task?.comments}</p>
                                                    )}{" "}
                                                <p className={`teamNameInNotes`}>{getFullName(task.doneByDetails)} ({convertToTimeZone(task?.timestamp)})</p>
                                            </li>
                                        );
                                    }
                                    if (conditionsToShowPrn && showTask) {
                                        return (
                                            <li key={index}>
                                                <div className="listRow justify-content-between">
                                                    <p>
                                                        <img alt="rr" src={`/images/s6.svg`} />
                                                        PRN Med
                                                    </p>
                                                    <img src={(task?.status === "true" ? "/images/checkedIco.svg" : "/images/blankCheck.svg")} className="boxIcons" />
                                                </div>
                                                <p>{task?.NM}</p>
                                                <p>
                                                    Dose: {task?.doseAmount} {task?.UDFS_UOMCD}
                                                </p>
                                                <p>Meal Consideration: {task?.mealConsideration}</p>
                                                {Object.keys(task).includes("comments") &&
                                                    task?.comments && (
                                                        <p>Comments: {task?.comments}</p>
                                                    )}{" "}
                                                <p className={`teamNameInNotes`}>{getFullName(task.doneByDetails)} ({convertToTimeZone(task?.timestamp)})</p>
                                            </li>
                                        );
                                    }
                                    const taskImgObj = taskImages.find(elem => task?.taskName?.includes(elem?.Ctg));
                                    let mesuredValue = '';
                                    if (task.taskType === "BP") {
                                        mesuredValue = task.input ? `: ${task.input?.Systolic_BP?.measureValue}/${task.input?.Diastolic_BP?.measureValue} ${task.input?.Diastolic_BP?.measureType}` : '';
                                    } else {
                                        mesuredValue = (task.input && task.input?.measureValue !== '') ? `: ${task.input?.measureValue} ${task.input?.measureType}` : '';
                                    }

                                    return (
                                        <>
                                            {showTask && <li key={index}>
                                                <div className="listRow justify-content-between">
                                                    <p>
                                                        <img alt="icon" src={taskImgObj.image} />
                                                        {task?.taskName}
                                                    </p>
                                                    {task.startTime !== '' && <p className={`teamNameInNotes me-5`}>
                                                        {convertTimeToUserTimeZone(task.startTime, clientJournal.shiftTimeZone)}
                                                    </p>}
                                                    <img src={task?.status === "omitted" ? "/images/crossIco.svg" : (task?.status === "true" ? "/images/checkedIco.svg" : "/images/blankCheck.svg")} className="boxIcons" />
                                                </div>
                                                <p>{task?.taskType} {mesuredValue}</p>
                                                <p>Comments: {task?.comments}</p>
                                                {(task?.reason && task?.status === "omitted") && <p>
                                                    {`Reason: ${task?.reason}`}
                                                </p>}
                                                <p className={`teamNameInNotes`}>{`${task.doneByDetails?.first_Name || 'deleted'} ${task.doneByDetails?.last_Name || 'user'}`} ({convertToTimeZone(task?.timestamp)})</p>
                                            </li>}
                                        </>
                                    );
                                })}
                            </ul>
                        </Tab.Pane>
                        <Tab.Pane eventKey="taB2">
                            {notesData?.length > 0 &&
                                notesData?.filter((item) => item?.notesType === "careteammember")?.map(elem => {
                                    return (<div className="rightTextTabs" key={elem._id}>
                                        {elem.media && <img src={elem.media} />}
                                        <p>{elem.notesData}</p>
                                        <p>{getFullName(elem.careTeamId)} ({convertToTimeZone(elem.createdAt)})</p>
                                    </div>)
                                })}
                        </Tab.Pane>
                        <Tab.Pane eventKey="taB3">
                            {notesData?.length > 0 &&
                                notesData?.filter((item) => item?.notesType === "family")?.map(elem => {
                                    return (<div className="rightTextTabs" key={elem._id}>
                                        {elem.media && <img src={elem.media} />}
                                        <p>{elem.notesData}</p>
                                        <p>{getFullName(elem.careTeamId)} ({convertToTimeZone(elem.createdAt)})</p>
                                    </div>)
                                })}
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </>
    );
};

export default JournalDetailFacility;