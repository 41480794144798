import React, { Component } from "react";
import Scheduler, { SchedulerData, ViewTypes } from "react-big-scheduler";
import "./demo.css";
import axios from "axios";
import "react-big-scheduler/lib/css/style.css";
import { Link } from "react-router-dom";
import AssignStaff from "../AssignStaff";
import ShiftConfig from "../ShiftConfig";
import { currentCareSiteId } from "../../../redux-toolkit/reducer/switchSites";
import moment from "moment";
import cx from '../shift.module.css'
import { DragDropContext } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import { ShowSwal } from "../../../utils/alertSwal";
import { httpAdmin } from "../../../Apis/commonApis";
import { colorCodes } from "../../../constants/constants";
import { AiOutlineUsergroupAdd, AiOutlineCloseCircle } from "react-icons/ai";
import swal from "sweetalert";
import "moment/locale/en-gb";
import { AiFillPrinter } from "react-icons/ai";
import JsonDownload from "../../../components/DownloadExcel/DownloadExcel";
import { convertTimeToUserTimeZone, convertToTimeZone, getFullName, getWeekStartAndEndDates, handleDataPrint, isDateTimeGreaterThanCurrent, sortDataByKey } from "../../../utils/CommonFunctions";
const chatUserImg = '/images/chatUser.svg';

class RotaScheduler extends Component {
  constructor(props) {
    super(props);

    const pageHeight = window.innerHeight - 300;

    // moment.locale('en-gb');
    //let schedulerData = new SchedulerData(new moment("2017-12-18").format(DATE_FORMAT), ViewTypes.Week);
    this.schedulerData = new SchedulerData(
      new Date(),
      ViewTypes.Week,
      false,
      false,
      {
        schedulerWidth: "88%",
        resourceTableWidth: 0,
        schedulerMaxHeight: pageHeight,
        besidesWidth: 0,
        defaultEventBgColor: "#808080",
        resourceName: "",
        movable: true,
        creatable: true,
        crossResourceMove: false,
        eventItemPopoverEnabled: true,
        calendarPopoverEnabled: true,
        dayResourceTableWidth: '0',
        weekResourceTableWidth: '0',
        monthResourceTableWidth: '0',
        eventItemHeight: 150,
        eventItemLineHeight: 150,
        selectedAreaColor: "#808080",
        recurringEventsEnabled: true,
        headerEnabled: true,
        displayWeekend: true,
        nonWorkingTimeHeadColor: "grey",
        nonWorkingTimeHeadBgColor: "white",
        nonWorkingTimeBodyBgColor: "white",
        groupOnlySlotColor: "#808080.",
        startResizable: true,
        customCellWidth: 80,
        dayCellWidth: "5%",
        endResizable: true,
        weekCellWidth: "14.26%",
        monthCellWidth: '13%',
        views: [
          {
            viewName: "Day",
            viewType: ViewTypes.Day,
            showAgenda: false,
            isEventPerspective: false,
          },
          {
            viewName: "Week",
            viewType: ViewTypes.Week,
            showAgenda: false,
            isEventPerspective: false,
          },
          {
            viewName: "Month",
            viewType: ViewTypes.Month,
            showAgenda: false,
            isEventPerspective: false,
          },
        ],
      }
    );

    this.schedulerData.localeMoment.locale("en-gb");
    this.schedulerData.setResources([{ id: 1, name: '' }]);

    this.state = {
      viewModel: this.schedulerData,
      shiftConfigData: null,
      showAssignStaffUI: false,
      showCreateShift: false,
      createShiftDate: '',
      assignStaffEventDetails: null,
      isDisplayed: false,
      EventsArr: [],
      groupArr: [],
      downloadData: [],
      SchedulerAccess: props.SchedulerAccess,
      loader: true,
      intervalId: '',
      siteTimeZone: this.props.siteTimeZone
    };
  }

  componentDidMount() {
    const intervalId = setInterval(() => {
      this.getShiftsandtaskNew();
    }, 60000);
    this.getShiftsandtaskNew();
    this.getClientGroupData();
    this.setState(() => ({ intervalId: intervalId }));
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.text !== this.props.text) {
      // let filteredVal = this.props?.resources?.filter((elem) => {
      //   return elem?.shiftName.toLowerCase().includes(this.props.text.toLowerCase());
      // });

      // this.schedulerData.setResources(filteredVal);
      // this.forceUpdate(); // Force a re-render of the component
    }
    if (prevProps.siteTimeZone !== this.props.siteTimeZone) {
      this.setState((prevState) => ({
        ...prevState,
        siteTimeZone: this.props.siteTimeZone,
      }));
      this.forceUpdate(); // Force a re-render of the component
    }
  }

  getShiftsandtaskNew = async (actionType) => {
    const start = this.state.viewModel.startDate;
    const end = this.state.viewModel.endDate;
    const res = await httpAdmin(`getShiftConfig?careHomeId=${currentCareSiteId}&startDate=${start}&endDate=${end}`, 'get', null, null);
    if (res.status) {
      const shiftData = res.data;
      const events = [];
      const downloadArr = [];
      shiftData?.forEach(element => {
        const name = getFullName(element);
        //  `${element?.first_Name} ${element?.last_Name}`;
        element?.shiftData?.forEach((item) => {
          events.push({
            id: item._id,
            shiftId: element._id,
            start: item.shiftStartDatetime,
            end: item.shiftEndDatetime,
            // shiftTime: `${convertTimeToUserTimeZone(item.startingTime, item.timeZone)} - ${convertTimeToUserTimeZone(item.endingTime, item.timeZone)}`,
            shiftTime: `${convertToTimeZone(item.shiftStartDatetime)} - ${convertToTimeZone(item.shiftEndDatetime)}`,
            shiftBreak: item.shiftBreak,
            resourceId: 1,
            assignedCareTeam: item.assignedCareTeam,
            title: item?.shiftName,
            bgColor: "#898988",
            openShiftZone: item?.openShiftZone
          });
          downloadArr.push({
            ['Client Name']: name,
            ['Date']: item.Date,
            ['Shift Start Time']: item.startingTime,
            ['Shift End Time']: item.endingTime,
          });
        });

      });
      this.schedulerData.setResources([{ id: 1, name: '' }]);
      this.schedulerData.setEvents(events);
      if (actionType === 'createOrEdit') {
        this.setState((prevState) => ({
          ...prevState,
          EventsArr: events,
          shiftConfigData: res.data?.data,
          downloadData: downloadArr,
          showCreateShift: false,
          showAssignStaffUI: false,
          assignStaffEventDetails: null
        }));
      } else {
        this.setState((prevState) => ({
          EventsArr: events,
          shiftConfigData: res.data?.data,
          downloadData: downloadArr
        }));
      }
    }
  };

  getClientGroupData = async () => {
    const res = await httpAdmin(`getClient?careHomeId=${currentCareSiteId}&isGroup=${true}`, 'get', null, null);
    if (res.status) {
      const groupData = res?.clientListing?.map((item, index) => {
        return {
          groupId: item._id,
          zone: item.zone,
          groupName: item.groupName,
          groupColor: item.colorCode
        }
      });
      this.setState((prevState) => ({
        ...prevState,
        groupArr: sortDataByKey(groupData, 'zone'),
      }));
    }
  };

  deleteConfirm(mainshiftId, shiftId, deleteType) {
    swal({
      title: "Are you sure??",
      text: `click on Yes to procced`,
      buttons: {
        cancel: "Cancel",
        succeed: "Yes",
      },
    }).then((result) => {
      if (result) {
        this.deleteShift(mainshiftId, shiftId, deleteType);
      } else {
        console.log("rejected");
      }
    });
  }

  deleteShift(mainshiftId, shiftId, deleteType) {
    let data = JSON.stringify({
      mainshiftId: mainshiftId,
      shiftId: shiftId,
      type: deleteType,
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/deleteshift`,
      headers: {
        Authorization: localStorage.getItem("care_admin_token"),
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config).then((res) => {
      this.getShiftsandtaskNew();
      ShowSwal(res.data.status, res.data.message);
      // window.location.reload(false);
    }).catch((error) => {
      // console.log(error);
    });
  }

  handleEventClick = (schedulerData, event) => {
    // Prevent the default behavior
    console.log('Event clicked. Preventing default behavior.');
  };

  handleActionClick = (action) => {
    if (action === 'create') {
      this.setState((prevState) => ({
        showCreateShift: !prevState.showCreateShift,
      }));
      document.body?.classList?.add("modal-scroll");
    } else {
      this.setState((prevState) => ({
        showAssignStaffUI: !prevState.showAssignStaffUI,
      }));
    }
  };

  render() {
    const { viewModel, groupArr, downloadData } = this.state;
    return (
      <>
        <div className="col-md-2 ps-2" >
          <AiFillPrinter
            className="me-3 ms-1 curser_point"
            onClick={() => handleDataPrint(downloadData)}
          />
          <a className="curser_point">
            <JsonDownload
              fileName="shifttasks-excel"
              downloadfile={downloadData}
            />
          </a>
        </div>
        <div className="float-end btns_head" style={{ marginTop: "-34px" }}>
          <div className="dropdown multiDrop">
            <button
              className="btn btn-primary dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
            >
              Actions
            </button>
            <ul className="dropdown-menu">
              <li>
                <a
                  disabled={this.state.SchedulerAccess !== 'full'}
                  style={{ cursor: "pointer" }}
                  className="dropdown-item"
                  onClick={() => this.handleActionClick('create')}
                >
                  Create Shifts
                </a>
              </li>
              <li>
                <a
                  disabled={this.state.SchedulerAccess !== 'full'}
                  style={{ cursor: "pointer" }}
                  className="dropdown-item"
                  onClick={() => this.handleActionClick('assign')}
                >
                  Assign Staff
                </a>
              </li>
            </ul>
          </div>
        </div>
        {!this.state.showAssignStaffUI &&
          !this.state.showCreateShift && (
            <div className={cx.rotaResource}>
              <Scheduler
                schedulerData={viewModel}
                prevClick={this.prevClick}
                nextClick={this.nextClick}
                onSelectDate={this.onSelectDate}
                onViewChange={this.onViewChange}
                eventItemClick={this.eventClicked}
                // updateEventStart={this.updateEventStart}
                // updateEventEnd={this.updateEventEnd}
                newEvent={this.newEvent}
                onScrollLeft={this.onScrollLeft}
                onScrollRight={this.onScrollRight}
                toggleExpandFunc={this.toggleExpandFunc}
                eventItemPopoverTemplateResolver={
                  this.eventItemPopoverTemplateResolver
                }
                eventItemTemplateResolver={this.eventItemTemplateResolver}
                slotItemTemplateResolver={this.slotItemTemplateResolver}
              />
            </div>
          )}

        {/* Assign Staff POPup */}
        <div
          className="modal"
          style={{
            display: this.state.showAssignStaffUI ? "block" : "none",
          }}
        >
          <div>
            <div className="bg_popup"></div>
            <div className="modal-dialog modal-xl">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Assign Staff
                  </h5>
                  <Link
                    className="btn-close"
                    onClick={() => {
                      this.setState((prevState) => ({
                        showAssignStaffUI: false,
                        assignStaffEventDetails: null
                      }));
                    }}
                    to="#"
                  ></Link>
                </div>
                <div className="modal-body">
                  {this.state.showAssignStaffUI && (
                    <AssignStaff
                      getShiftsandtaskNew={this.getShiftsandtaskNew}
                      prevShiftData={this.state.shiftConfigData}
                      assignStaffEvent={this.state.assignStaffEventDetails}
                      groupArr={groupArr}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* // Shift Configuration */}
        <div
          className={`modal ${this.state.isDisplayed ? "" : ""}`}
          id="showCreateShift"
          style={{
            display: `${this.state.showCreateShift ? "block" : "none"}`,
          }}
        >
          <div className="bg_popup"></div>
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Create Shift
                  <small>
                    {this.state.siteTimeZone ? ` (Timezone used for shift creation - ${this.state.siteTimeZone})` : ''}
                  </small>
                </h4>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => {
                    this.setState((prevState) => ({
                      showCreateShift: !prevState.showCreateShift,
                      createShiftDate: '',
                    }));
                    document.body?.classList?.remove("modal-scroll");
                  }}
                ></button>
              </div>

              <div className="modal-body">
                {" "}
                {this.state.showCreateShift && (
                  <ShiftConfig
                    getShiftsandtaskNew={this.getShiftsandtaskNew}
                    prevShiftData={this.state.shiftConfigData}
                    shiftDate={this.state.createShiftDate}
                  />
                )}
              </div>

              <div className="modal-footer"></div>
            </div>
          </div>
        </div>

        <ul className="statusBox text-center">
          <li>
            <span style={{ background: "purple" }}></span>
            Open Shift
          </li>
          <li>
            <span style={{ background: "#2D9CDB" }}></span>
            All Clients
          </li>
          {groupArr?.map((item) => {
            return (
              <li key={item.id}>
                <span style={{ background: item.groupColor }}></span>
                {item.zone}
              </li>
            )
          })}
        </ul>
      </>
    );
  }

  eventItemPopoverTemplateResolver = (
    schedulerData,
    eventItem,
    title,
    start,
    end,
    statusColor
  ) => {
    const isShiftDatepassed = isDateTimeGreaterThanCurrent(start.format(), new Date());
    const formattedDate = moment.utc(start.format()).local().format('YYYY-MM-DD');
    // console.log(eventItem, 'eventItem')
    let dataForAssign = {
      date: formattedDate,
      shift: eventItem.title
    }
    return (
      <div className={`${cx.personDetail_main}`}>
        <div className="d-flex justify-content-between" style={{ minWidth: '110px' }}>
          {(isShiftDatepassed || eventItem?.assignedCareTeam?.length < 1) ? <AiOutlineCloseCircle style={{ fontSize: '25px', cursor: 'pointer' }}
            onClick={() => this.deleteConfirm(eventItem?.shiftId, eventItem?.id, "")} /> :
            <span>&nbsp;</span>}
          <AiOutlineUsergroupAdd style={{ fontSize: '25px', cursor: 'pointer' }} onClick={() => {
            this.setState((prevState) => ({
              showAssignStaffUI: true,
              assignStaffEventDetails: dataForAssign
            }));
          }} />
        </div>
        {eventItem?.assignedCareTeam?.map((item, index) => {
          const teamData = item.careTeamId;
          const imageIcon = teamData.image ?? chatUserImg;
          return (
            <div key={index} className={`${cx.personDetail_box} mt-2 align-items-start`}
            >
              <div className={`${cx.personImg}`}>
                <img src={imageIcon} className={imageIcon.includes('chatUser') ? `forImagecareteam` : ''} alt="img" />
              </div>
              <div className={`${cx.personDetails}`}>
                <p>{getFullName(teamData)}</p>
                {/* <p>{teamData.first_Name} {teamData.last_Name}</p> */}
                <p>Job Title - {teamData.jobTitle ?? ''}</p>
                <p>Shift Start - {item.startTime ? item.startTime : convertToTimeZone(eventItem.start)}</p>
                <p>Shift End - {item.endTime ? item.endTime : convertToTimeZone(eventItem.end)}</p>
                <p>Group - {item.all ? 'All Clients' : item?.zone}</p>
                <span></span>
              </div>
            </div>
          )
        })}
      </div>
    );
  };

  eventItemTemplateResolver = (schedulerData, event, bgColor, isStart, isEnd, mustAddCssClass, mustBeHeight, agendaMaxEventWidth) => {
    let divStyle = { border: `${event?.openShiftZone ? '2px solid purple' : '2px solid #F2C94C'}`, borderRadius: '5px' };
    let dataForAssign = {
      date: event.start?.split(' ')[0],
      shift: event.title
    }
    return (<div key={event.id} style={divStyle}>
      <div className={cx.resourceInEvent} style={{ backgroundColor: event?.openShiftZone ? 'purple' : '#F2C94C' }} >
        <p>{event.title}
          {/* <span><FaRegSun onClick={() => {
            this.setState((prevState) => ({
              showAssignStaffUI: true,
              assignStaffEventDetails: dataForAssign
            }));
          }} /></span> */}
        </p>
        <p>{event.shiftTime}</p>
        <p>Break ({event?.shiftBreak ?? 0}mins)</p>
      </div>
      <div className={cx.eventDiv} >
        {event?.assignedCareTeam?.map((item, index) => {
          // let teamName = item.first_Name + ' ' + item.last_Name;
          const groupColor = item?.all ? '#2D9CDB' : this.state.groupArr?.find(elem => elem.zone === item?.zone)?.groupColor;
          return (<span key={index} style={{ background: groupColor }}>{item.careTeamId?.first_Name}</span>)
        })}
      </div>
    </div>);
  }

  slotItemTemplateResolver(
    schedulerData,
    slot,
    slotClickedFunc,
    width,
    clsName
  ) {
    return (
      <div className="text-center slotStyle">
        <p>{slot.slotName}</p>
      </div>
    );
  }

  prevClick = (schedulerData) => {
    schedulerData.prev();
    schedulerData.setEvents(this.state?.EventsArr);
    this.setState({
      viewModel: schedulerData,
    });
    this.getShiftsandtaskNew();
  };

  nextClick = (schedulerData) => {
    schedulerData.next();
    schedulerData.setEvents(this.state?.EventsArr);
    this.setState({
      viewModel: schedulerData,
    });
    this.getShiftsandtaskNew();
  };

  onViewChange = (schedulerData, view) => {
    schedulerData.setViewType(
      view.viewType,
      view.showAgenda,
      view.isEventPerspective
    );
    schedulerData.setEvents(this.state.EventsArr);
    this.setState({
      viewModel: schedulerData,
    });
    this.getShiftsandtaskNew();
  };

  onSelectDate = (schedulerData, date) => {
    schedulerData.setDate(date);
    schedulerData.setEvents(this.state.EventsArr);
    this.setState({
      viewModel: schedulerData,
    });
    this.getShiftsandtaskNew();
  };

  eventClicked = (schedulerData, event) => {
    schedulerData.setEvents(this.state.EventsArr);
    this.setState({
      viewModel: schedulerData,
    });
  };

  newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
    this.setState({
      createShiftDate: start?.split(' ')[0],
      showCreateShift: !this.state.showCreateShift,
    });
  };

  updateEventStart = (schedulerData, event, newStart) => {
    schedulerData.updateEventStart(event, newStart);
    this.setState({
      viewModel: schedulerData,
    });
  };

  updateEventEnd = (schedulerData, event, newEnd) => {
    schedulerData.updateEventEnd(event, newEnd);
    this.setState({
      viewModel: schedulerData,
    });
  };

  onScrollRight = (schedulerData, schedulerContent, maxScrollLeft) => {
    if (schedulerData.ViewTypes === ViewTypes.Day) {
      schedulerData.next();
      schedulerData.setEvents(this.state.EventsArr);
      this.setState({
        viewModel: schedulerData,
      });
      schedulerContent.scrollLeft = maxScrollLeft - 10;
    }
  };

  onScrollLeft = (schedulerData, schedulerContent, maxScrollLeft) => {
    if (schedulerData?.ViewTypes === ViewTypes.Day) {
      schedulerData.prev();
      schedulerData.setEvents(this.state?.EventsArr);
      this.setState({
        viewModel: schedulerData,
      });
      schedulerContent.scrollLeft = 10;
    }
  };

  toggleExpandFunc = (schedulerData, slotId) => {
    schedulerData.toggleExpandStatus(slotId);
    this.setState({ viewModel: schedulerData });
  };
}

// export default WithDragDropContext(Basic);
export default DragDropContext(HTML5Backend)(RotaScheduler);