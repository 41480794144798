import React from "react";
import "../Segments/segments.css";

function About() {
  return (
    <>
      <section className="mt-5 pagespace_section resp_pagespace_section">
        <div className="container">
          <div className="title resp_pagespace_section_title">
            <h3>Empowering Care. Transforming Lives. </h3>
          </div>
        </div>
      </section>

      <section className="care_facilities_section resp_care_facilities_section_padding">
        <div className="container">
          <div className="col-md-9 offset-3 title">
            <h3 style={{ textAlign: "left" }}>CareMagnus</h3>
            <p>
              Rooted in a resolute mission, we are dedicated to ushering in a
              paradigm shift within the healthcare sector through our
              revolutionary care management software platform. As a dynamic and
              forward-thinking entity, our impetus stems from an ardent
              commitment to empower care providers and enhance patient outcomes.
              At the core of our ethos lies an unbridled passion to innovate
              relentlessly, driving us to craft solutions that transcend the
              traditional boundaries of healthcare delivery. Our journey unfolds
              with a spirit of adaptability, ensuring our solutions stand at the
              vanguard of an ever-evolving industry.
            </p>
          </div>
          <br />
          <br />

          <div className="row facilities_row align-items-center resp_facilities_row ">
            <div className="col-md-5 img_box_segment resp_row facilities_row resp_facilities_row_img">
              <img
                alt=""
                src={`${process.env.PUBLIC_URL}/images/aboutimg1.svg`}
              />
            </div>
            <div className="col-md-7 content_box">
              <h3>Vision</h3>
              <div className="bg_theme mb-3"></div>
              Our vision is to transform the way care is delivered by providing
              cutting-edge technology solutions that enhance efficiency,
              streamline workflows, and ultimately improve the quality of care.
              We envision a future where healthcare professionals can focus on
              what they do best – delivering exceptional care – while our
              intuitive software takes care of the administrative complexities.
            </div>
          </div>

          <br />
          <br />
          <br />
          <br />

          <div className="row facilities_row align-items-center">
            <div className="col-md-7 content_box">
              <h3>Our Commitment </h3>
              <div className="bg_theme mb-3"></div>
              We are committed to delivering exceptional value to care
              providers, from small care agencies to large care facilities. We
              understand the unique challenges they face in an ever-evolving
              industry, and we strive to provide tailored solutions that meet
              their specific needs. Our dedicated team of experts work
              tirelessly to ensure that our software remains at the forefront of
              technological advancements and best care practices, enabling our
              clients to stay ahead of the curve.
            </div>

            <div className="col-md-5 img_box_segment text-right resp_facilities_row_img">
              <img
                alt=""
                src={`${process.env.PUBLIC_URL}/images/aboutimg2.svg`}
              />
            </div>
          </div>
        </div>
      </section>

      <br />
      <br />
      <br />

      <section className="retirement_section resp_care_facilities_section_padding">
        <div className="container">
          <div className="row facilities_row align-items-center resp_facilities_row_img">
            <div className="col-md-5 img_box_segment">
              <img
                alt=""
                src={`${process.env.PUBLIC_URL}/images/aboutimg3.svg`}
              />
            </div>
            <div className="col-md-7 content_box">
              <h3>Our Positioning As The Partner of Choice </h3>
              <div className="bg_theme"></div>
              <ul>
                <li>
                  Innovative Solutions: Our care management software platform is
                  designed to revolutionise care delivery, optimise operational
                  efficiency, and drive cost savings. With a patient-centric
                  approach, we empower healthcare providers to deliver
                  exceptional care and improve patient outcomes.{" "}
                </li>

                <li>
                  Cutting-Edge Technology: Leveraging the latest advancements in
                  cloud computing, security algorithms and data analytics, our
                  software provides intuitive and intelligent tools that
                  automate processes, centralise patient data, and enable
                  seamless collaboration among care teams.
                </li>

                <li>
                  Exceptional Support: We pride ourselves on our commitment to
                  customer satisfaction. Our dedicated support team is always
                  available to assist our clients, ensuring smooth onboarding
                  and providing ongoing support as needed.
                </li>

                <li>
                  Future-Proof Solutions: As a key player at the forefront of
                  the industry, we are committed to continuous innovation and
                  improvement. We stay ahead of emerging trends, best practices
                  and technologies to ensure that our clients have access to the
                  most advanced and future-proof solutions.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <br />
      <br />

      <section className="care_facilities_section padding_botm bgColor resp_care_facilities_section">
        <div className="container">
          <div className="col-md-9 title">
            <h3 style={{ textAlign: "left" }}>Join Us On The Journey</h3>
            <p>
              We invite you to join us on our journey as we reshape care
              delivery and embrace the future of care management. Together, we
              can make a meaningful impact on the lives of patients and the care
              industry as a whole.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
export default About;
