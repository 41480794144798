import { MdClose } from "react-icons/md";
import { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { httpAdmin } from "../../Apis/commonApis";
import "./Profile.css";
import swal from "sweetalert";
import Modal from "react-bootstrap/Modal";
import useInput from "../../hooks/use-input";
import { useDispatch, useSelector } from "react-redux";
import {
  authenticateOwnerSignup,
  getOwnerIdAfterLogin,
  securitysuccessfullMsg,
} from "../../redux-toolkit/reducer/accountCreationApiReducer";
import Select from "react-select";
import { securityQuestions } from "../../constants/constants";
import CircularProgress from "@mui/material/CircularProgress";
import { AiFillPlusCircle } from "react-icons/ai";
import { currentCareSiteId } from "../../redux-toolkit/reducer/switchSites";
import { ShowSwal } from "../../utils/alertSwal";
import AwsLocation from "../../utils/AwsLocation";
import { Controller, useForm } from "react-hook-form";
import { getCareSiteListing } from "../../redux-toolkit/reducer/caresiteDataReducer";

const companyLogo = '/images/companyLogo.svg';
const isPasswordValidate = (value) => value.length >= 6;
const isEmpty = (value) => value.trim() !== "";

export default function Profile() {
  const dispatch = useDispatch();
  const { loginVerifyEmail } = useSelector((state) => state.accountCreationApiReducer);
  let { currentCareSiteData } = useSelector((state) => state.caresiteDataReducer);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm();

  const [userDetail, setUserDetail] = useState(null);
  const [authProcess, setAuthProcess] = useState("password");
  const [authenticatPassword, setAuthenticatePassword] = useState(false);
  const [careSiteplanData, setCareSitePlanData] = useState(null);
  const [imgLoader, setImgLoader] = useState(false);
  const [totalBeds, setTotalBeds] = useState(0);

  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem("userData"));
    if (userData) getProfileDetails(userData._id);
    if (currentCareSiteData) {
      console.log(currentCareSiteData, 'currentCareSiteData')
      setCareSiteAddress(currentCareSiteData.careSiteAddress ?? '')
      setCareSitePlanData(currentCareSiteData);
      setTotalBeds(currentCareSiteData.totalBeds);
    }
  }, [currentCareSiteData]);

  const getProfileDetails = async (adminId) => {
    const res = await httpAdmin(`getAdmin?adminId=${adminId ? adminId : userDetail?._id}`, 'get', null, null);
    if (res.status) {
      let pfdata = res.result[0];
      setUserDetail(pfdata);
    };
  };

  const updateProfileDetails = async (postData) => {
    let formValues = { ...postData }
    formValues.adminId = userDetail?._id;
    formValues.subAdminId = userDetail?.subscriptionPlanData[0]?._id;
    formValues.careHomeId = currentCareSiteData?._id;
    const res = await httpAdmin(`editCarehomeProfile`, 'post', formValues, null);
    if (res.status) getProfileDetails(null);
    setShow(false);
    swal(
      res.status ? "Success" : "Failed",
      res.message,
      res.status ? "success" : "error"
    );
  };

  // -------------------------  Change Email -------------------------  //

  const [show, setShow] = useState(false);
  const [popupStatus, setPopupStatus] = useState("");

  const handleClose = () => {
    setCheckForOTP(false);
    setShow(false);
    setAuthenticatePassword(false);
  };

  const handleShow = (text) => {
    setShow(true);
    setPopupStatus(text);
  };

  const [email, setNewEmail] = useState("");
  const [otp, setOTP] = useState("");
  const [checkForOTP, setCheckForOTP] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData"));

  const [question, setQuestion] = useState("");
  const [careSiteAddress, setCareSiteAddress] = useState(null);

  const {
    value: passwordValue,
    isValid: passwordIsValid,
    hasError: passwordHasError,
    valueChangeHandler: passwordChangeHandler,
    inputBlurHandler: passwordBlurHandler,
  } = useInput(isPasswordValidate);
  const {
    value: confirmPasswordValue,
    isValid: confirmPasswordIsValid,
    hasError: confirmPasswordHasError,
    valueChangeHandler: confirmPasswordChangeHandler,
    inputBlurHandler: confirmPasswordBlurHandler,
  } = useInput(isPasswordValidate);

  const {
    value: securityAnswerValue,
    isValid: securityAnswerIsValid,
    hasError: securityAnswerHasError,
    valueChangeHandler: securityAnswerChangeHandler,
    inputBlurHandler: securityAnswerBlurHandler,
  } = useInput(isEmpty);

  const passwordClasses = passwordHasError
    ? "form-control invalid"
    : "form-control mt-3";

  const confirmPasswordClasses = confirmPasswordHasError
    ? "form-control invalid"
    : "form-control mt-3";

  const securityAnswerClasses = securityAnswerHasError
    ? "form-control invalid"
    : "form-control mt-2 ";

  async function changeEmailPassSctQty(changeType) {
    const data = {
      userId: userData?._id,
      changeType: changeType,
    };
    const result = await httpAdmin("reset_profile", 'post', data, null);
    if (result.status) {
      swal("Success", result.message, "success").then((value) => {
        if (changeType === "password" || changeType === "securityQuestions") {
          dispatch(
            getOwnerIdAfterLogin({
              loginOwnerId: userData?._id,
              loginVerifyEmail: 1,
              authProcess: authProcess,
            })
          );
        }
      });
    } else {
      ShowSwal(false, result.message ?? 'Something went wrong!');
    }
  }

  async function verifyOTP() {
    const data = {
      userId: userData?._id,
      otp: otp,
      newEmail: email,
    };

    const result = await httpAdmin("verifyOtp", 'post', data, null);
    if (result.status) {
      swal("Success", result.message, "success").then((value) => {
        userData.email = email;
        localStorage.setItem("userData", JSON.stringify(userData));
        handleClose();
        getProfileDetails(userData._id);
      });
    } else {
      ShowSwal(false, result.message ?? 'Something went wrong!');
    }
  }

  // -------------------------  Reset Password -------------------------  //

  async function reset_password_securityQuestion(resetType) {
    const data = {};

    if (resetType === "securityQuestions") {
      if (!securityAnswerIsValid && question === "") {
        return;
      }
      data.resetType = resetType;
      data.question = question;
      data.answer = securityAnswerValue;
    } else {
      if (!passwordIsValid && !confirmPasswordIsValid) {
        return;
      }
      data.resetType = resetType;
      data.new_password = passwordValue;
      data.confirm_password = confirmPasswordValue;
    }
    const result = await httpAdmin(`reset_password/${userData?._id}`, 'post', data, null);
    if (result.status) {
      handleClose();
      getProfileDetails(userData._id);
    }
    ShowSwal(result.status, result.message);
  }

  // ----------------------------  Security Questions   -------------------------- //

  function selectQuestion(event) {
    setQuestion(event.label);
  }

  // -----------------------------  Authentication Process  ------------------------ //

  useEffect(() => {
    let intervalID = setInterval(() => {
      if (userData._id && loginVerifyEmail == 1) {
        dispatch(
          authenticateOwnerSignup({ loginOwnerId: userData._id, authProcess })
        );
      }
    }, 3000);
    if (loginVerifyEmail == 2) {
      swal("Success", securitysuccessfullMsg, "success").then((value) => {
        setAuthenticatePassword(true);
      });
      dispatch(
        getOwnerIdAfterLogin({
          loginOwnerId: "",
          loginVerifyEmail: 0,
          authProcess: "",
        })
      );
      return () => clearInterval(intervalID);
    }
    return () => clearInterval(intervalID);
  }, [loginVerifyEmail]);

  async function onFileChange(e) {
    let profileImg = e.target.files[0];
    if (profileImg) {
      setImgLoader(true);
      editImage(profileImg);
    }
  }

  const editImage = async (imgg) => {
    let body = new FormData();
    body.append("image", imgg);
    const res = await httpAdmin(`changeProfilePhoto?careSiteId=${currentCareSiteId}`, "post", body, "formdata");
    if (res.status) {
      setCareSitePlanData(res.result);
      dispatch(getCareSiteListing());
    }
    setImgLoader(false);
  };

  const editCarehomeData = async (editField) => {
    let data = {};
    if (editField === 'beds') {
      data = {
        careHomeId: currentCareSiteId,
        totalBeds: totalBeds
      }
    } else if (careSiteAddress?.Label) {
      data = {
        careHomeId: currentCareSiteId,
        careSiteAddress: careSiteAddress.Label,
        lattitude: careSiteAddress.latitude,
        longitude: careSiteAddress.longitude,
      };
    } else {
      setShow(false);
    }

    let res = await httpAdmin("editCarehome", "post", data, null);
    setShow(false);
    if (res.status) {
      dispatch(getCareSiteListing());
    }
    ShowSwal(res.status, res.message);
  }

  function awsLocationHandler(locVal) {
    setCareSiteAddress(locVal);
  }

  return (
    <>
      <div
        className="mt-3 profileBox"
        style={{ minHeight: "calc(100vh - 289px)" }}
      >
        <div className="col-md-9">
          <div className="row profileB">
            <div className="col-12">
              <ul>
                <li>
                  <div className="d-flex">
                    <span className="themeColor">Company Logo:</span>{" "}

                    <div className="ms-3 position-relative">
                      {imgLoader ? (
                        <CircularProgress color="secondary" />
                      ) : (
                        <img
                          src={careSiteplanData?.logo ?? companyLogo}
                          className="profile_img"
                          alt="user"
                        />
                      )}
                      <div className="edit_clogo">
                        <input
                          type="file"
                          id="upLoader"
                          accept="image/*"
                          name="images"
                          onChange={onFileChange}
                        />
                        <span>
                          <AiFillPlusCircle className="edit_icon" />
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
                {currentCareSiteData?.carefacility === 'facalities' && <>
                  <br />
                  <li>
                    <p>
                      <span className="themeColor ">Current CareSite Address:</span>{" "}
                      {currentCareSiteData?.careSiteAddress ?? ''}
                    </p>
                    <p>
                      <span onClick={() => handleShow("address")} to="#">
                        <Link to="#">Change</Link>
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span className="themeColor ">No. of beds:</span>{" "}
                      {currentCareSiteData?.totalBeds ?? ''}
                    </p>
                    <p>
                      <span onClick={() => handleShow("beds")} to="#">
                        <Link to="#">Change</Link>
                      </span>
                    </p>
                  </li>
                </>}
                <br />
                <li className="my-2">
                  <p>
                    <span className="themeColor">Global Account Details</span>{" "}
                  </p>
                  <p>
                    <NavLink
                      onClick={() => handleShow("profile")}
                      to="#"
                    >
                      Edit
                    </NavLink>
                    &ensp; &ensp; &ensp;
                  </p>
                </li>
                <li>
                  <p>
                    <span className="themeColor">First Name:</span>{" "}
                    {userDetail?.firstName}
                  </p>
                </li>
                <li>
                  <p>
                    <span className="themeColor">Last Name:</span>{" "}
                    {userDetail?.lastName}
                  </p>
                </li>
                <li>
                  <p>
                    <span className="themeColor">Tel:</span>{" "}
                    {userDetail?.mobileNo}
                  </p>
                </li>
                <li>
                  <p>
                    <span className="themeColor">Company Name:</span>{" "}
                    {userDetail?.companyName}
                  </p>
                </li>
                <li>
                  <p>
                    <span className="themeColor">Company Address:</span>{" "}
                    {userDetail?.address}
                  </p>
                </li>
                <li>
                  <p>
                    <span className="themeColor ">Email:</span>{" "}
                    {userDetail?.email}
                  </p>
                  <p>
                    <span onClick={() => handleShow("Email")} to="#">
                      <Link to="#">Change</Link>
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span className="themeColor">Password:</span> **********
                  </p>
                  <p>
                    <span onClick={() => handleShow("reset_password")}>
                      <Link to="#">Change</Link>
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span className="themeColor">Security Question:</span>{" "}
                    **********
                  </p>
                  <p>
                    <span onClick={() => handleShow("reset_securityQuestion")}>
                      <Link to="#">Change</Link>
                    </span>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {popupStatus === "profile" && (
        <Modal className="viewModal" show={show} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>
              <span>Update Profile</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="row">
                <div className="mb-3 col-md-6">
                  <label className={`form-label`}>First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={userDetail?.firstName}
                    {...register("firstName", {
                      required: true,
                    })}
                  />
                  {errors?.firstName?.type === "required" && (
                    <p className="error_style">This field is required</p>
                  )}
                </div>
                <div className="mb-3 col-md-6">
                  <label className={`form-label`}>Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={userDetail?.lastName}
                    {...register("lastName", {
                      required: true,
                    })}
                  />
                  {errors?.lastName?.type === "required" && (
                    <p className="error_style">This field is required</p>
                  )}
                </div>
                <div className="mb-3 col-md-12">
                  <label className={`form-label`}>Phone Number</label>
                  <input
                    type="number"
                    className="form-control"
                    defaultValue={userDetail?.mobileNo}
                    {...register("mobileNo", {
                      required: true,
                    })}
                  />
                  {errors?.mobileNo?.type === "required" && (
                    <p className="error_style">This field is required</p>
                  )}
                </div>
                <div className="mb-3 col-md-12">
                  <label className={`form-label`}>Company Name</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={userDetail?.companyName}
                    {...register("companyName", {
                      required: true,
                    })}
                  />
                  {errors?.companyName?.type === "required" && (
                    <p className="error_style">This field is required</p>
                  )}
                </div>
                <div className="mb-3 col-md-12">
                  <label className={`form-label`}>Company Address</label>
                  <textarea
                    type="text"
                    className="form-control"
                    defaultValue={userDetail?.address}
                    {...register("address", {
                      required: true,
                    })}
                  />
                  {errors?.address?.type === "required" && (
                    <p className="error_style">This field is required</p>
                  )}
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex ">
              <button className="btn submitBtn" onClick={handleClose}>
                cancel
              </button>
              <button
                className="btn submitBtn"
                onClick={handleSubmit(updateProfileDetails)}
              >
                update
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      )}

      {popupStatus === "address" && (
        <Modal className="viewModal" show={show} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>
              <span>Change Current CareSite Address</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              {popupStatus === "address" && (
                <div className="row">
                  <div className="col-md-12 my-3">
                    <AwsLocation awsLocationHandler={awsLocationHandler}
                      defaultVal={currentCareSiteData?.careSiteAddress ?? ''} />
                    {(careSiteAddress === '') && (
                      <p className="error_style">
                        This field is required
                      </p>
                    )}
                  </div>
                </div>
              )}
            </form>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex ">
              <button className="btn submitBtn" onClick={handleClose}>
                cancel
              </button>
              <button
                className="btn submitBtn"
                onClick={() => editCarehomeData('address')}
              >
                update
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      )}

      {popupStatus === "beds" && (
        <Modal className="viewModal" show={show} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>
              <span>Change Total No. Of Beds</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="row">
                <div className="col-md-12 my-3">
                  <label className={`form-label`}>Total Beds</label>
                  <input
                    type="text"
                    className="form-control"
                    value={totalBeds}
                    onChange={(e) => setTotalBeds(e.target.value)}
                  />
                  {totalBeds < 1 && (
                    <p className="error_style">This field is required</p>
                  )}
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex ">
              <button className="btn submitBtn" onClick={handleClose}>
                cancel
              </button>
              <button
                className="btn submitBtn"
                onClick={() => editCarehomeData('beds')}
              >
                Change
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      )}

      {popupStatus === "Email" && (
        <Modal className="viewModal" show={show} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>
              <span>Change Email Id</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              {popupStatus === "Email" && (
                <div className="row">
                  <div className="mb-3 col-md-12">
                    <label className={`form-label`}>Email</label>
                    <input
                      type={"email"}
                      className="form-control"
                      value={email}
                      onChange={(e) => setNewEmail(e.target.value)}
                    />
                  </div>
                  <label className={`form-label`}>OTP</label>
                  <div className="mb-3 col-md-12 d-flex justify-content-between">
                    <input
                      type={"number"}
                      className="form-control w-75"
                      value={otp}
                      onChange={(e) => setOTP(e.target.value)}
                    />
                    {!checkForOTP && (
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => {
                          changeEmailPassSctQty("email");
                          setCheckForOTP(true);
                        }}
                      >
                        Send OTP{" "}
                      </button>
                    )}{" "}
                  </div>
                </div>
              )}
            </form>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex ">
              <button className="btn submitBtn" onClick={handleClose}>
                cancel
              </button>
              {checkForOTP && (
                <button
                  className="btn submitBtn"
                  onClick={() => {
                    if (popupStatus === "Email") {
                      verifyOTP();
                    }
                  }}
                >
                  update
                </button>
              )}
            </div>
          </Modal.Footer>
        </Modal>
      )}

      {popupStatus === "reset_password" && (
        <Modal className="viewModal" show={show}>
          <Modal.Header>
            <Modal.Title>
              <span>Reset Password</span>
              <button
                className="popupCloseIcon"
                title="Close"
                onClick={handleClose}
              >
                <MdClose />
              </button>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {popupStatus === "reset_password" && !authenticatPassword && (
              <form>
                <div className="row">
                  <p>Authenticate yourself by clicking on the below button</p>
                  {!checkForOTP && (
                    <button
                      className="btn btn-primary mb-3 w-25 mx-auto"
                      type="button"
                      onClick={() => {
                        changeEmailPassSctQty("password");
                        setCheckForOTP(true);
                        setAuthProcess("password");
                      }}
                    >
                      Send Email{" "}
                    </button>
                  )}{" "}
                </div>
              </form>
            )}
            {authenticatPassword && (
              <form>
                <h4 className="mt-2">New Password</h4>
                <input
                  type="password"
                  id="password"
                  value={passwordValue}
                  onChange={passwordChangeHandler}
                  onBlur={passwordBlurHandler}
                  className={passwordClasses}
                  placeholder="Password"
                />
                {passwordHasError && (
                  <span className="input_error">
                    Please enter at least 6 digits
                  </span>
                )}

                <input
                  type="password"
                  id="confirmPassword"
                  value={confirmPasswordValue}
                  onChange={confirmPasswordChangeHandler}
                  onBlur={confirmPasswordBlurHandler}
                  className={confirmPasswordClasses}
                  placeholder="Confirm Password"
                />
                {confirmPasswordHasError && (
                  <span className="input_error">
                    Please enter at least 6 digits
                  </span>
                )}
                <button
                  type="button"
                  className="form-control mt-2 btn btn-primary  w-25 mb-2 d-block mx-auto "
                  onClick={() => {
                    reset_password_securityQuestion("password");
                  }}
                >
                  Save
                </button>
              </form>
            )}
          </Modal.Body>
        </Modal>
      )}

      {popupStatus === "reset_securityQuestion" && (
        <Modal className="viewModal" show={show}>
          <Modal.Header>
            <Modal.Title>
              <span>Reset Security Question</span>
              <button
                className="popupCloseIcon"
                title="Close"
                onClick={handleClose}
              >
                <MdClose />
              </button>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {popupStatus === "reset_securityQuestion" &&
              !authenticatPassword && (
                <form>
                  <div className="row">
                    <p>Authenticate yourself by clicking on the below button</p>
                    {!checkForOTP && (
                      <button
                        className="btn btn-primary mb-3 w-25 mx-auto"
                        type="button"
                        onClick={() => {
                          changeEmailPassSctQty("securityQuestions");
                          setCheckForOTP(true);
                          setAuthProcess("securityQuestions");
                        }}
                      >
                        Send Email{" "}
                      </button>
                    )}{" "}
                  </div>
                </form>
              )}
            {authenticatPassword && (
              <form>
                <h4>New Security Question</h4>
                <Select
                  options={securityQuestions}
                  onChange={selectQuestion}
                  required={true}
                />
                {question !== "" && (
                  <input
                    style={{ marginTop: "20px" }}
                    type="text"
                    id="securityAnswer"
                    value={securityAnswerValue}
                    onChange={securityAnswerChangeHandler}
                    onBlur={securityAnswerBlurHandler}
                    className={securityAnswerClasses}
                    placeholder="Security Answer"
                  />
                )}{" "}
                {securityAnswerHasError && (
                  <span className="input_error">
                    Please Enter Security Answer
                  </span>
                )}
                <button
                  type="button"
                  onClick={() => {
                    reset_password_securityQuestion("securityQuestions");
                  }}
                  className="form-control btn"
                >
                  Save
                </button>
              </form>
            )}
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
