import React, { useEffect, useState } from 'react';
import Chats from "./Chats";
import FrontMsg from "./FrontMsg";
import Users from "./Users";
import { httpAdmin } from "../Apis/commonApis";
import { auth, db, storage } from "../Firebase";
import { doc, setDoc, getDocs, getDoc, onSnapshot, collection, updateDoc } from "firebase/firestore";
import { FiSearch } from "react-icons/fi";
import { getFullName } from '../utils/CommonFunctions';

export default function ChatBox(props) {

    const { currentCareSiteId, userdata } = props;
    // const decodedId = userdata._id.split("").reverse().join("");

    const [chatUserList, setChatUserList] = useState(null);
    const [chatHeadList, setChatHeadList] = useState(null);
    const [selectedChat, setSelectedChat] = useState(null);
    const [userDetails, setUserDetails] = useState(null);

    useEffect(() => {
        setUserDetails({
            name: userdata.firstName + ' ' + userdata.lastName,
            image: userdata.image,
            email: userdata.email
        });
        addOnlineStatus(currentCareSiteId);
        getMembers(currentCareSiteId);
        getUsersArray(currentCareSiteId);
    }, []);

    useEffect(() => {
        const unSub = onSnapshot(
            collection(db, "chatHeads", currentCareSiteId, 'chatUsers'),
            (querySnapshot) => {
                let headdata = [];
                querySnapshot.forEach((doc) => {
                    headdata.push(doc.data())
                });
                setChatHeadList(headdata);
            }
        );
        return () => {
            unSub();
            onlineStatusToFalse();
        };
    }, []);

    const addOnlineStatus = async (csid) => {
        try {
            const res = await getDoc(doc(db, "onlineStatus", csid, "user", userdata._id));
            if (!res.exists()) {
                await setDoc(doc(db, "onlineStatus", csid, "user", userdata._id), {
                    status: true,
                    id: userdata._id,
                    time: Date.now()
                });
            } else {
                await updateDoc(doc(db, "onlineStatus", csid, "user", userdata._id), {
                    status: true,
                    id: userdata._id,
                    time: Date.now()
                });
            }
        } catch (err) { }
    };

    const onlineStatusToFalse = async () => {
        console.log('onlineStatusToFalseeeeeeeeeee')
        try {
            await updateDoc(doc(db, "onlineStatus", currentCareSiteId, "user", userdata._id), {
                status: false,
                id: userdata._id,
                time: Date.now()
            });
        } catch (err) { }
    };

    const getMembers = async (csid) => {
        const res = await httpAdmin(`getChatUsers?caresiteId=${csid}`, 'get', null, null);
        if (res.status) {
            const createdList = createChatUserList(res.data, csid);
            // createdList.forEach(elem => {
            //     setUsersIndividually(elem, csid);
            // });
            setUsersInArray(createdList, csid);
        }
    }

    const getUsersArray = async (uid) => {
        const res = await getDoc(doc(db, "chatUsers", uid));
        let nestedData = res.data()?.users;
        // const chatHeadCollection = collection(db, "chatHeads", uid, "chatUsers");
        // const qrySnapOfChatHeads = await getDocs(chatHeadCollection);
        // const chatheadData = [];
        // qrySnapOfChatHeads.forEach((doc) => {
        //     chatheadData.push(doc.data());
        // });
        // setChatHeadList(chatheadData);
        if (nestedData) setChatUserList(nestedData);
    };

    const setUsersInArray = async (users, uid) => {
        try {
            await setDoc(doc(db, "chatUsers", uid), { users: users });
        } catch (err) {
            console.log(err, 'error');
        }
    }

    const setUsersIndividually = async (user, uid) => {
        try {
            await setDoc(doc(db, "users", uid, "chatUsers", user.id), user);
        } catch (err) {
            console.log(err, 'error');
        }
    }

    function handleChatSelection(chat) {
        setSelectedChat(chat);
    }

    return (
        <div className="row">
            {chatUserList ? <Users
                chatUserList={chatUserList}
                chatHeadList={chatHeadList}
                handleChatSelection={handleChatSelection}
                currentUser={userdata}
                selectedChat={selectedChat}
            /> :
                <div className="col-md-5 chat_users_box">
                    <div className="d-flex">
                        <span className="careTeamTab">Care Team</span>
                        <span className="careTeamTab FfTab">Friends & Family</span>
                        <span className="careTeamTab adminTab">Admin</span>
                    </div>
                    <div className="input-group user_search_box">
                        <input type="text" className="form-control" placeholder="Search" />
                        <div className="input-group-append">
                            <span className="input-group-text">
                                <FiSearch />
                            </span>
                        </div>
                    </div>
                    <div className="chatuser_list">
                    </div>
                </div>}

            {selectedChat ?
                <Chats userDetails={userDetails} selectedChat={selectedChat} uid={currentCareSiteId}
                    currentUserId={userdata._id}
                    chatUserList={chatUserList}
                    chatHeadList={chatHeadList} /> :
                <FrontMsg userDetails={userDetails} />
            }
        </div>
    )
}

function createChatUserList(allData, csid) {
    const chatUsersList = [];
    const rolesArr = [
        { role: 'owner', label: 'Owner' },
        { role: 'admin', label: 'Admin' },
        { role: "deputy_admin", label: 'Deputy Admin' },
        { role: "supervisor", label: 'Supervisor' },
    ]
    chatUsersList.push({
        id: csid,
        displayName: "Admins Group",
        email: '',
        photoURL: '/images/adminGroupLogo.png',
        type: 'group',
        role: 'Admins. Deputy Admins. Supervisors',
    });
    allData?.admins?.forEach(elem => {
        const dname = elem?.firstName + ' ' + elem?.lastName;
        if (elem.isActive) {
            chatUsersList.push({
                id: elem._id,
                displayName: dname,
                email: elem.email,
                photoURL: elem.image ?? '',
                img: elem.image.includes('chatUser') ? '' : elem.image,
                type: 'admin',
                role: rolesArr.find(item => item.role === elem.userType)?.label,
            });
        }
    });
    allData?.careteams?.forEach(elem => {
        chatUsersList.push({
            id: elem._id,
            displayName: getFullName(elem),
            email: elem.email,
            photoURL: elem.image ?? '',
            img: elem.image.includes('chatUser') ? '' : elem.image,
            assignedClients: elem.assignedClients,
            type: 'careteam',
            role: 'careteam',
        });
    });
    allData?.fandfUsers?.forEach(elem => {
        chatUsersList.push({
            id: elem._id,
            displayName: elem.name,
            email: elem.email,
            photoURL: elem.image ?? "",
            img: elem.image.includes('chatUser') ? '' : elem.image,
            clientName: elem.clientName,
            relation: elem.relation,
            type: 'fandsfam',
            role: 'fands&fam',
        });
    });
    return chatUsersList;
}