import React, { useEffect, useState, useRef } from 'react';
import Modal from "react-bootstrap/Modal";
import { AiOutlineClose } from "react-icons/ai";
import cx from "./medicinedetails.module.css";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Select from "react-select";
import { httpAdmin } from '../../Apis/commonApis';
import { currentCareSiteId } from "../../redux-toolkit/reducer/switchSites";
import './medicines.css';
import { timeValues } from '../../constants/constants';
import { weekDays } from '../../constants/constants';
import { disablePastDate } from '../../utils/CommonFunctions';
import { ShowSwal } from '../../utils/alertSwal';

export default function AddMedModal(props) {
    const { handleShowOrHide, showAddMedModal, allMedicines, clientList, getClientRowData } = props;

    const ref = useRef();

    let initialVals = {
        careHomeId: '',
        clientId: '',
        SOURCE: "dmd",
        // customMedicineId: '',
        restrictions: 'None',
        medName: '',
        prn: 'No',
        prescriber: '',
        medType: '',
        units: '',
        strengthUnit: '',
        strengthDose: '',
        doseAmount: '',
        startDate: '',
        durationofCourse: '',
        adminRoute: 'Orally',
        status: 'active',
        // schedule: `${schedulestarttime} - ${scheduleendtime}`,
        // schedule: '',
        frequency: {},
        NM: '',
        DESC: '',
        BNF_CODE: "",
        SUPPCD: '',
        LIC_AUTHCD: '',
        // ONT_FORMCD: `${doseDetails.doseForm}.${doseDetails.doseForm}`,
        ONT_FORMCD: '',
        ISID: '',
        UDFS: '',
        UDFS_UOMCD: '',
        UNIT_DOSE_UOMCD: '',
        STRNT_NMRTR_VAL: '',
        STRNT_NMRTR_UOMCD: '',
        STRNT_DNMTR_VAL: '',
        STRNT_DNMTR_UOMCD: '',
        SUG_F: '',
        GLU_F: '',
        PRES_F: '',
        CFC_F: '',
        reorderAlertTrigger: 10,
        mealConsideration: 'Not Relevant',
    };

    const [manualSwitch, setManualSwitch] = useState(false);
    const [isAddMedicine, setIsAddMedicine] = useState(false);
    const [error, setError] = useState([]);
    const [medName, setMedName] = useState("");
    const [medFormData, setMedFormData] = useState(initialVals);
    const [doseDetails, setdoseDetails] = useState({
        doseForm: "",
        doseRoute: ""
    });
    const [durationofCourse, setdurationofCourse] = useState({
        inputVal: "1",
        selectType: "Days",
    });
    const [frequency, setFrequency] = useState("Daily");

    const [startTimeField, setStartTimeField] = useState("00:00");
    const [endTimeField, setEndTimeField] = useState("00:00");
    const [selectedTimes, setSelectedTimes] = useState([]);
    const [dateField, setDateField] = useState("");
    const [selectedDates, setSelectedDates] = useState([]);
    const [selectedweekDays, setSelectedWeekDays] = useState([]);

    useEffect(() => {
        if (isAddMedicine) {
            let checkVals = checkEmptyKeys(medFormData)
            setIsAddMedicine(false);
            addMedicine();
        }
    }, [isAddMedicine])

    function handleAddMedicine() {
        var finalDuration = "";
        if (durationofCourse.selectType === "Days") {
            finalDuration = durationofCourse.inputVal;
        } else if (durationofCourse.selectType === "Weeks") {
            finalDuration = durationofCourse.inputVal * 7;
        } else if (durationofCourse.selectType === "Months") {
            finalDuration = durationofCourse.inputVal * 30;
        }

        if (medFormData.clientId === '' || medName.trim() === '') {
            setError(medFormData.clientId === '' ? 'clientId' : 'medName');
            ref.current?.scrollIntoView({ behavior: "smooth" });
            return;
        } else if (medFormData.startDate === '') {
            setError('startDate');
            // ref.current.scrollTop = 300;(frequency === 'Monthly' && selectedweekDays.length === 0)
            return;
        }

        let isPRNYes = medFormData.prn === 'Yes';

        if (!isPRNYes) {
            if (selectedTimes.length === 0) {
                setError(medFormData.startDate === '' ? 'startDate' : 'schedule');
                // ref.current.scrollTop = 300;(frequency === 'Monthly' && selectedweekDays.length === 0)
                return;
            } else if (frequency === 'Weekly' && selectedweekDays.length === 0) {
                setError('selectedWeekDays');
                return;
            } else if (frequency === 'Monthly' && selectedDates.length === 0) {
                setError('selectedDates');
                return;
            }
        }

        let scheduleTimeArray = [];
        let weekDaysArray = [];
        let monthDatesArray = [];

        selectedTimes?.forEach(elem => {
            scheduleTimeArray.push(`${elem.starttime}-${elem.endtime}`)
        });

        if (frequency === 'Weekly') {
            selectedweekDays.forEach(elem => {
                weekDaysArray.push(elem.value);
            });
        } else if (frequency === 'Monthly') {
            monthDatesArray = [...selectedDates];
        }

        let frequencyData = {
            freq: isPRNYes ? '' : frequency,
            days: isPRNYes ? [] : weekDaysArray,
            dates: isPRNYes ? [] : monthDatesArray,
            times: isPRNYes ? [] : scheduleTimeArray
        }

        setMedFormData((prevState) => {
            return {
                ...prevState,
                medName: medName,
                NM: medName,
                durationofCourse: finalDuration,
                ONT_FORMCD: `${doseDetails.doseForm}.${doseDetails.doseRoute}`,
                careHomeId: currentCareSiteId,
                frequency: frequencyData,
            };
        });
        setIsAddMedicine(true);
    }

    const addMedicine = async () => {
        const result = await httpAdmin("createMedicines", 'post', medFormData, null);
        if (result.status) {
            getClientRowData();
            handleCloseModal();
        }
        ShowSwal(result.status, result.message);
    };

    function checkEmptyKeys(obj) {
        const emptyKeys = [];
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (obj[key] === '' && key !== 'BNF_CODE') {
                    emptyKeys.push(key);
                }
            }
        }
        return {
            allKeysNotEmpty: emptyKeys.length === 0,
            emptyKeys: emptyKeys
        };
    }

    const handleManualName = (event) => {
        // const index = error.indexOf('medName');
        // if (index > -1) {
        //     error.splice(index, 1);
        // }
        setMedName(event.target.value);
        setError('');
    };

    const handleManualSwitch = (event) => {
        setManualSwitch(event.target.checked);
        setMedFormData((prevState) => {
            return { ...prevState, SOURCE: event.target.checked ? 'manual' : 'dmd' };
        });
    };

    function handleClientSelect(event) {
        setMedFormData((prevState) => {
            return { ...prevState, clientId: event.value };
        });
        setError('');
    }

    function handleFormValuesChange(keyName, event) {
        let value = event.target.value ? event.target.value : event;
        if (event.target.value === '') value = '';
        setMedFormData((prevState) => {
            return { ...prevState, [keyName]: value };
        });
        if (error.length > 0) setError('');
    }

    const getSpicificDmdDetails = async (name, dmdId) => {
        setMedName(name);
        const result = await httpAdmin(`getSpecificDmdMedicineDetail?APID=${dmdId}`, 'get', null, null);
        if (result.status) {
            setdoseDetails({
                doseForm:
                    result?.ONT_FORMCD[0]?.ONT_FORM_ROUTE?.INFO?.DESC?._text?.split(".")[0],
                doseRoute:
                    result?.ONT_FORMCD[0]?.ONT_FORM_ROUTE?.INFO?.DESC?._text?.split(".")[1],
            });
            setMedFormData((prevState) => {
                return {
                    ...prevState,
                    restrictions: result?.ampData[0]?.restrictions,
                    medName: name,
                    medType: result?.UDFS_UOMCD[0]?.UNIT_OF_MEASURE?.INFO?.DESC?._text,
                    NM: name,
                    DESC: result?.ampData[0]?.DESC?._text,
                    SUPPCD: result?.SUPPCD[0]?.SUPPLIER?.INFO?.DESC?._text,
                    BNF_CODE: dmdId,

                    LIC_AUTHCD: result?.LIC_AUTHCD[0]?.LICENSING_AUTHORITY?.INFO?.DESC?._text,
                    ONT_FORMCD: `${result?.ONT_FORMCD[0]?.ONT_FORM_ROUTE?.INFO?.DESC?._text?.split(".")[0]}.${result?.ONT_FORMCD[0]?.ONT_FORM_ROUTE?.INFO?.DESC?._text?.split(".")[1]}`,
                    ISID: result?.ISID[0]?.NM?._text,

                    UDFS: result?.UDFS + "." + "000",
                    UDFS_UOMCD: result?.UDFS_UOMCD[0]?.UNIT_OF_MEASURE?.INFO?.DESC?._text,
                    UNIT_DOSE_UOMCD: result?.UNIT_DOSE_UOMCD[0]?.UNIT_OF_MEASURE?.INFO?.DESC?._text,

                    STRNT_NMRTR_VAL: result?.STRNT_NMRTR_VAL?._text,
                    STRNT_NMRTR_UOMCD: result?.STRNT_NMRTR_UOMCD[0]?.UNIT_OF_MEASURE?.INFO?.DESC?._text,
                    STRNT_DNMTR_VAL: result?.STRNT_DNMTR_VAL,
                    STRNT_DNMTR_UOMCD: result?.STRNT_DNMTR_UOMCD,
                    SUG_F:
                        result?.vmpData[0]?.SUG_F?._text?.length > 0 ? "Yes" : "No",
                    GLU_F:
                        result?.vmpData[0]?.GLU_F?._text?.length > 0 ? "Yes" : "No",
                    PRES_F:
                        result?.vmpData[0]?.PRES_F?._text?.length > 0 ? "Yes" : "No",
                    CFC_F:
                        result?.vmpData[0]?.CFC_F?._text?.length > 0 ? "Yes" : "No",
                };
            });
        }
    };

    const medIconColor = {
        filter:
            "invert(9%) sepia(99%) saturate(5630%) hue-rotate(246deg) brightness(111%) contrast(148%)",
    };

    // -------------- multi start time and end time selection ----------------- //

    const handleTimeChange = (e, starttime, endtime) => {
        e.preventDefault();
        if (endtime > '00:00') {
            setSelectedTimes((prev) => [...prev, { starttime, endtime }]);
        }
        setError('');
        setStartTimeField("00:00");
        setEndTimeField("00:00");
    };

    const timeRemoveChangeHandler = (e, indexToRemove) => {
        e.preventDefault();
        const clonefield = selectedTimes.slice();
        clonefield.splice(indexToRemove, 1);
        setSelectedTimes(clonefield);
    };

    // -------------- dates selection ----------------- //

    const handleDateChange = (e, date) => {
        e.preventDefault();
        if (date) {
            setSelectedDates((prev) => [...prev, date]);
        }
        setDateField('');
        setError('');
    };

    const dateRemoveChangeHandler = (e, indexToRemove) => {
        e.preventDefault();
        const clonefield = selectedDates.slice();
        clonefield.splice(indexToRemove, 1);
        setSelectedDates(clonefield);
    };

    // -------------- week days selection ----------------- //
    function handleWeekDaysChange(e) {
        setSelectedWeekDays(e);
        setError('');
    }

    function handleCloseModal(){
        setMedFormData(initialVals);
        setMedName("");
        setdoseDetails({ doseForm: "", doseRoute: "" });
        setdurationofCourse({ inputVal: "1", selectType: "Days", });
        setFrequency("Daily");
        setSelectedTimes([]);
        handleShowOrHide();
    }

    return (
        <Modal className="viewModal" show={showAddMedModal}>
            <Modal.Header>
                <Modal.Title>
                    <span>Add Medicine</span>
                    <span>
                        <AiOutlineClose
                            className="curser_point"
                            onClick={handleCloseModal}
                        />
                    </span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: "10px 20px", fontSize: '14px' }}>
                <form>
                    <div ref={ref} className="row">
                        <div className="d-flex mb-2">
                            <label className="me-4 default_color"> Client</label>
                            <div className=' w-100'>
                                <Select
                                    className="react-dropdown"
                                    options={clientList}
                                    onChange={handleClientSelect}
                                />
                                {error.includes('clientId') && (
                                    <span className='error_style'>
                                        This field is required
                                    </span>
                                )}
                            </div>
                        </div>
                        <hr />
                        <div className="d-flex mb-2">
                            <div className="col-md-4 d-flex">
                                <label className="me-4 default_color">PRN</label>
                                <select
                                    className="ms-2 form-select"
                                    value={medFormData.prn}
                                    onChange={(e) => handleFormValuesChange('prn', e)}
                                >
                                    <option value="No" > No </option>
                                    <option value="Yes" > Yes </option>
                                </select>
                            </div>
                        </div>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={manualSwitch}
                                    onChange={handleManualSwitch}
                                />
                            }
                            label="Add Medicine Manually"
                            className="mb-2"
                        />
                        <label className="mb-1 default_color">Product Details :</label>
                        {!manualSwitch ? <div className="col-md-12 mb-3 position-relative">
                            <div className="">
                                <label className="mb-1 me-4">Medicine Name</label>
                                <input
                                    type="text"
                                    className="form-control react-dropdown"
                                    value={medName}
                                    placeholder="Search for medicine"
                                    onChange={handleManualName}
                                />
                            </div>
                            {error.includes('medName') && (
                                <span className='error_style'>
                                    This field is required
                                </span>
                            )}
                            <div className={` ${cx.addMeddropdown}`}>
                                {allMedicines
                                    .filter((item) => {
                                        const searchTerm = medName.toLowerCase();
                                        const fullName = item?.label.toLowerCase();
                                        return (
                                            searchTerm &&
                                            fullName.includes(searchTerm) &&
                                            fullName !== searchTerm
                                        );
                                    })
                                    .slice(0, 500)
                                    .map((item) => (
                                        <div
                                            onClick={() =>
                                                getSpicificDmdDetails(item.label, item.value)
                                            }
                                            className={`${cx.dropdownRow}`}
                                            key={item.value}
                                        >
                                            {item.label}
                                        </div>
                                    ))}
                            </div>
                        </div> :
                            <div className="col-md-12 mb-3">
                                <label className="mb-1">Medicine Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Medicine Name"
                                    value={medName}
                                    onChange={handleManualName}
                                />
                                {error.includes('medName') && (
                                    <span className='error_style'>
                                        This field is required
                                    </span>
                                )}
                                {/* <div className={` ${cx.dropdown}`}>
                                {manualMedNames
                                    .filter((item) => {
                                        const searchTerm = medName.toLowerCase();
                                        const fullName = item?.label.toLowerCase();
                                        return (
                                            searchTerm &&
                                            fullName.startsWith(searchTerm) &&
                                            fullName !== searchTerm
                                        );
                                    })
                                    .slice(0, 20)
                                    .map((item) => (
                                        <div
                                            onClick={() =>
                                                onSelectManualMed(item.label, item.value)
                                            }
                                            className={`${cx.dropdownRow}`}
                                            key={item.value}
                                        >
                                            {item.label}
                                        </div>
                                    ))}
                            </div> */}
                            </div>}
                        <>
                            <div className="col-md-12 mb-3">
                                <label className="mb-1">Description</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={medFormData.DESC}
                                    onChange={(e) => handleFormValuesChange('DESC', e)} />
                                {error.includes('DESC') && (
                                    <span className='error_style'>
                                        This field is required
                                    </span>
                                )}
                            </div>
                            {/* <div className="col-md-12 mb-3">
                                <label className="mb-1">Supplier Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={medFormData.SUPPCD}
                                    onChange={(e) => handleFormValuesChange('SUPPCD', e)} />
                            </div> */}
                            {/* <div className="col-md-12 mb-3">
                                <label className="mb-1">
                                    Current Licencing Authority
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={medFormData.LIC_AUTHCD}
                                    onChange={(e) => handleFormValuesChange('LIC_AUTHCD', e)} />
                            </div> */}
                        </>
                        <>
                            {/* <label className="mb-1 default_color">Product Ingredients :</label>
                            <div className="col-md-12 mb-3">
                                <label className="mb-1">Ingredient</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={medFormData.ISID}
                                    onChange={(e) => handleFormValuesChange('ISID', e)} />
                            </div>
                            <div className="d-flex mb-3">
                                <div className="col-md-6">
                                    <label className="mb-1">Strength value numerator</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        style={{ width: "94%" }}
                                        value={medFormData.STRNT_NMRTR_VAL}
                                        onChange={(e) => handleFormValuesChange('STRNT_NMRTR_VAL', e)} />
                                </div>
                                <div className="col-md-6">
                                    <label className="mb-1"> Unit</label>
                                    <select
                                        className="form-select"
                                        value={medFormData.STRNT_NMRTR_UOMCD}
                                        onChange={(e) => handleFormValuesChange('STRNT_NMRTR_UOMCD', e)}
                                    >
                                        <option value="ml">
                                            Mls
                                        </option>
                                        <option value="mg" >
                                            Mgs
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div className="d-flex mb-3">
                                <div className="col-md-6">
                                    <label className="mb-1">
                                        Strength value denominator
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        style={{ width: "94%" }}
                                        value={medFormData.STRNT_DNMTR_VAL}
                                        onChange={(e) => handleFormValuesChange('STRNT_DNMTR_VAL', e)} />
                                </div>
                                <div className="col-md-6">
                                    <label className="mb-1"> Unit</label>
                                    <select
                                        className="form-select"
                                        value={medFormData.STRNT_DNMTR_UOMCD}
                                        onChange={(e) => handleFormValuesChange('STRNT_DNMTR_UOMCD', e)}
                                    >
                                        <option
                                            value="ml"
                                        >
                                            Mls
                                        </option>
                                        <option
                                            value="mg"
                                        >
                                            Mgs
                                        </option>
                                    </select>
                                </div>
                            </div> */}
                        </>
                        <>
                            <label className="mb-1 default_color">Product Dose Details :</label>
                            <div className="d-flex mb-3">
                                <div className="col-md-6">
                                    <label className="mb-1">Dose Form</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        style={{ width: "94%" }}
                                        value={doseDetails.doseForm}
                                        onChange={(e) =>
                                            setdoseDetails({
                                                ...doseDetails,
                                                doseForm: e.target.value,
                                            })
                                        } />
                                </div>
                                <div className="col-md-6">
                                    <label className="mb-1"> Dose Route</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={doseDetails.doseRoute}
                                        onChange={(e) =>
                                            setdoseDetails({
                                                ...doseDetails,
                                                doseRoute: e.target.value,
                                            })
                                        } />
                                </div>
                            </div>
                            <div className="d-flex mb-3">
                                <div className="col-md-6">
                                    <label className="mb-1">Unit dose form size</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        style={{ width: "94%" }}
                                        value={medFormData.UDFS}
                                        onChange={(e) => handleFormValuesChange('UDFS', e)} />
                                </div>
                                <div className="col-md-6">
                                    <label className="mb-1">Unit dose form units</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={medFormData.UDFS_UOMCD}
                                        onChange={(e) => handleFormValuesChange('UDFS_UOMCD', e)} />
                                </div>
                            </div>
                            {/* <div className="col-md-12 mb-3">
                                <label className="mb-1">Unit dose unit of measure</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={medFormData.UNIT_DOSE_UOMCD}
                                    onChange={(e) => handleFormValuesChange('UNIT_DOSE_UOMCD', e)} />
                            </div> */}
                        </>
                        <>
                            {/* 
                            <div className="d-flex mb-3">
                                <div className="col-md-6">
                                    <label>Sugar Free</label>
                                    <select
                                        className="form-select"
                                        value={medFormData.SUG_F}
                                        onChange={(e) => handleFormValuesChange('SUG_F', e)}
                                        style={{ width: "94%" }}
                                    >
                                        <option
                                            value="No"
                                        >
                                            No
                                        </option>
                                        <option
                                            value="Yes"
                                        >
                                            Yes
                                        </option>
                                    </select>
                                </div>
                                <div className="col-md-6">
                                    <label className="mb-1">Gluten Free</label>
                                    <select
                                        className="form-select"
                                        value={medFormData.GLU_F}
                                        onChange={(e) => handleFormValuesChange('GLU_F', e)}
                                    >
                                        <option
                                            value="No"
                                        >
                                            No
                                        </option>
                                        <option
                                            value="Yes"
                                        >
                                            Yes
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div className="d-flex mb-3">
                                <div className="col-md-6">
                                    <label className="mb-1">Preservative Free</label>
                                    <select
                                        className="form-select"
                                        value={medFormData.PRES_F}
                                        onChange={(e) => handleFormValuesChange('PRES_F', e)}
                                        style={{ width: "94%" }}
                                    >
                                        <option
                                            value="No"
                                        >
                                            No
                                        </option>
                                        <option
                                            value="Yes"
                                        >
                                            Yes
                                        </option>
                                    </select>
                                </div>
                                <div className="col-md-6">
                                    <label className="mb-1">CFC Free</label>
                                    <select
                                        className="form-select"
                                        value={medFormData.CFC_F}
                                        onChange={(e) => handleFormValuesChange('CFC_F', e)}
                                    >
                                        <option value="No">
                                            No
                                        </option>
                                        <option value="Yes" >
                                            Yes
                                        </option>
                                    </select>
                                </div>
                            </div>
                       */}
                        </>
                        {/* <div className="col-md-12 mb-3">
                            <label>Medicine Type</label>
                            <div className="d-flex ms-3">
                                <ul className="type_icon">
                                    <li
                                        onClick={() => {
                                            handleFormValuesChange('medType', "tablet");
                                        }}
                                    >
                                        <img
                                            style={medFormData.medType === "tablet"
                                                ? medIconColor
                                                : { filter: "none" }}
                                            src="../../../images/sdf1.svg" />
                                    </li>
                                    <li
                                        onClick={() => {
                                            handleFormValuesChange('medType', "capsule");
                                        }}
                                    >
                                        <img
                                            style={medFormData.medType === "capsule"
                                                ? medIconColor
                                                : { filter: "none" }}
                                            src="../../../images/sdf2.svg" />
                                    </li>
                                    <li
                                        onClick={() => {
                                            handleFormValuesChange('medType', "liquid");
                                        }}
                                    >
                                        <img
                                            style={medFormData.medType === "liquid" || medFormData.medType === "ml"
                                                ? medIconColor
                                                : { filter: "none" }}
                                            src="../../../images/sdf3.svg" />
                                    </li>
                                </ul>
                            </div>
                        </div> */}
                        <>
                            {/* <div className="col-md-12 mb-3">
                                <label className="mb-1">Prerscriber</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={medFormData.prescriber}
                                    onChange={(e) => handleFormValuesChange('prescriber', e)} />
                            </div> */}
                            <label className="mb-1 default_color">Client Dosage Requirements :</label>
                            {/* <div className="col-md-12 mb-3">
                                <label className="mb-1">Strength/Unit</label>
                                <div className="d-flex">
                                    <input
                                        type="text"
                                        className="form-control me-2"
                                        value={medFormData.strengthUnit}
                                        onChange={(e) => handleFormValuesChange('strengthUnit', e)} />
                                    <select className="form-select ms-2">
                                        <option>Mls</option>
                                    </select>
                                </div>
                            </div> */}
                            {/* <div className="col-md-12 mb-3">
                                <label className="mb-1">Strength/Dose</label>
                                <div className="d-flex">
                                    <input
                                        type="text"
                                        className="form-control me-2"
                                        value={medFormData.strengthDose}
                                        onChange={(e) => handleFormValuesChange('strengthDose', e)} />
                                    <select className="form-select ms-2">
                                        <option>Mls</option>
                                    </select>
                                </div>
                            </div> */}

                            <div className="d-flex mb-3">
                                <div className="col-md-6">
                                    <label className="mb-1">Dose Form Size</label>
                                    <div className="d-flex">
                                        <input
                                            type="text"
                                            className="form-control me-2"
                                            value={medFormData.doseAmount}
                                            onChange={(e) => handleFormValuesChange('doseAmount', e)} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label className="mb-1">Dose unit of measure </label>
                                    <div className="d-flex">
                                        <input
                                            type="text"
                                            className="form-control me-2"
                                            value={medFormData.strengthUnit}
                                            onChange={(e) => handleFormValuesChange('strengthUnit', e)} />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 mb-3">
                                <label className="mb-1">Start Date</label>
                                <div className="d-flex">
                                    <input
                                        type="date"
                                        className="form-control"
                                        min={disablePastDate()}
                                        value={medFormData.startDate}
                                        onChange={(e) => handleFormValuesChange('startDate', e)} />
                                </div>
                                {error.includes('startDate') && (
                                    <span className='error_style'>
                                        This field is required
                                    </span>
                                )}
                            </div>

                            {medFormData.prn === 'No' && <>
                                <div className="col-md-12 mb-3">
                                    <label className="mb-1">Duration of Course</label>
                                    <div className="d-flex">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={durationofCourse.inputVal}
                                            onChange={(e) => setdurationofCourse({
                                                ...durationofCourse,
                                                inputVal: e.target.value,
                                            })} />
                                        <select
                                            className="form-select ms-2"
                                            onChange={(e) => setdurationofCourse({
                                                ...durationofCourse,
                                                selectType: e.target.value,
                                            })}
                                        >
                                            <option value="Days">Days</option>
                                            <option value="Weeks">Weeks</option>
                                            <option value="Months">Months</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                    <label className="mb-1">Frequency</label>
                                    <div className="d-flex">
                                        <select
                                            className="form-select"
                                            value={frequency}
                                            onChange={(e) => setFrequency(e.target.value)}
                                        >
                                            <option value="Daily">Daily</option>
                                            <option value="Weekly">Weekly</option>
                                            <option value="Monthly">Monthly</option>
                                        </select>
                                    </div>
                                </div>
                                {frequency === 'Weekly' && <div className="col-md-12 mb-3">
                                    <label className="mb-1">Week Days</label>
                                    <Select
                                        isMulti
                                        value={selectedweekDays}
                                        options={weekDays}
                                        onChange={handleWeekDaysChange}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        getSearchLabel={(e) => (
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <span style={{ marginLeft: 5 }}>{e.label}</span>
                                            </div>
                                        )}
                                    />
                                    {error.includes('selectedWeekDays') && (
                                        <span className='error_style'>
                                            This field is required
                                        </span>
                                    )}
                                </div>}
                                {frequency === 'Monthly' && <>
                                    <div className="col-md-12 mb-2">
                                        <label className="mb-1">Add specific dates</label>
                                        <div className="d-flex">
                                            <input
                                                type="date"
                                                className="form-control"
                                                value={dateField}
                                                onChange={(e) => setDateField(e.target.value)} />
                                            <button className="ms-1 btn theme_btn" onClick={(e) => handleDateChange(e, dateField)}>
                                                +
                                            </button>
                                        </div>
                                        {error.includes('selectedDates') && (
                                            <span className='error_style'>
                                                This field is required
                                            </span>
                                        )}
                                    </div>
                                    {selectedDates?.map((item, index) => {
                                        return (
                                            <div key={index} className="col-md-12">
                                                <div
                                                    className="pe-5 mb-3 position-relative d-flex general"
                                                >
                                                    <input
                                                        className="form-control"
                                                        type="date"
                                                        value={item}
                                                        disabled />
                                                    <button
                                                        className="removeBtn"
                                                        style={{ top: "3px" }}
                                                        onClick={(e) => {
                                                            dateRemoveChangeHandler(e, index);
                                                        }}
                                                    >
                                                        -
                                                    </button>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </>
                                }
                                <div className="col-md-12">
                                    <label className="mb-1">Schedule</label>
                                    <div className="d-flex">
                                        <div className="col-5">
                                            <label>Start Time</label>
                                            <select className="form-select custom_timeInput"
                                                value={startTimeField}
                                                onChange={(e) => setStartTimeField(e.target.value)}>
                                                {timeValues.map(item => {
                                                    return <option key={item.value} value={item.value}>{item.label}</option>
                                                })}
                                            </select>
                                        </div>
                                        <div className="col-7">
                                            <label className="ms-3">End Time</label>
                                            <div className="d-flex">
                                                <select className="form-select custom_timeInput ms-3"
                                                    value={endTimeField}
                                                    onChange={(e) => setEndTimeField(e.target.value)}>
                                                    {timeValues
                                                        .filter(item => item.value >= startTimeField)
                                                        .map(item => {
                                                            return <option key={item.value} value={item.value}>{item.label}</option>
                                                        })}
                                                </select>
                                                <button className="ms-1 btn theme_btn" onClick={(e) => handleTimeChange(e, startTimeField, endTimeField)}>
                                                    +
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    {error.includes('schedule') && (
                                        <span className='error_style'>
                                            This field is required
                                        </span>
                                    )}
                                </div>

                                {selectedTimes?.map((item, index) => {
                                    return (
                                        <div key={index} className="col-md-12 mt-2">
                                            <div
                                                className="pe-5 mb-3 position-relative d-flex general"
                                            >
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    value={item?.starttime}
                                                    disabled
                                                />
                                                <input
                                                    className="form-control ms-3"
                                                    type="text"
                                                    value={item?.endtime}
                                                    disabled
                                                />
                                                <button
                                                    className="removeBtn"
                                                    style={{ top: "3px" }}
                                                    onClick={(e) => {
                                                        timeRemoveChangeHandler(e, index);
                                                    }}
                                                >
                                                    -
                                                </button>
                                            </div>
                                        </div>
                                    );
                                })}
                            </>}
                            <label className="mb-1 default_color">Inventory :</label>

                            <div className="col-md-12 mb-3">
                                <label className="mb-1">Units (In-Stock)</label>
                                <div className="d-flex justify-content-between">
                                    <input
                                        type="number"
                                        className="form-control"
                                        value={medFormData.units}
                                        onChange={(e) => handleFormValuesChange('units', e)} />
                                    {/* <select className="form-select ms-2">
                                        <option>Mls</option>
                                    </select> */}
                                </div>
                            </div>
                            {/* <div className="col-md-12 mb-3">
                                <label className="mb-1">Adminstration Route</label>
                                <div className="d-flex">
                                    <select
                                        className="form-select"
                                        value={medFormData.adminRoute}
                                        onChange={(e) => handleFormValuesChange('adminRoute', e)}
                                    >
                                        <option value="Orally">Orally</option>
                                    </select>
                                </div>
                            </div> */}
                        </>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <div className="d-flex">
                    <button
                        className="btn"
                        type="button"
                        onClick={() => {
                            handleAddMedicine();
                        }}
                    >
                        Add
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}
