import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/system";
import NoRowsOverlay from "../components/NoRowsOverlays";

const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
    "& .MuiDataGrid-cell:focus": {
        outline: "none",
    },
}));

export default function CareMagnusTable(props) {

    const { columns, rows, handleRowSelection, tableHeight, selectedRow, idParam } = props;

    const [pageSize, setPageSize] = useState(10);

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize);
    };

    return (
        <div style={{ height: `${tableHeight ?? '80vh'}`, width: '100%' }}>
            <CustomDataGrid
                columns={columns}
                rows={rows}
                // autoHeight
                checkboxSelection
                onSelectionModelChange={handleRowSelection}
                selectionModel={selectedRow}
                disableSelectionOnClick
                slots={{
                    noRowsOverlay: NoRowsOverlay,
                }}
                pageSize={pageSize}
                onPageSizeChange={handlePageSizeChange}
                rowsPerPageOptions={[10, 20, 30]}
                getRowId={(row) => row[idParam]}
            />
        </div>
    )
}

