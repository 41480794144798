import React from 'react'
import cx from "../Portal.module.css";
import { Row, Col, NavLink } from "react-bootstrap";

export default function PortalFooter() {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  return (
    <div className={`${cx.footerStart}`}>
      <div className={`container`}>
        <Row>
          <Col lg={12}>
            <div className={`${cx.footer}`}>
              <p><a href="https://caremagnus.com/blogs/app-terms-of-use">Terms of Use</a> I <a href="https://caremagnus.com/blogs/privacy-policy">Privacy Policy</a> I ©2021-{currentYear} Auriga Magnus Care Limited. All Rights Reserved. </p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}
