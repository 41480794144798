import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    msgCount: 0,
};

const messageSlice = createSlice({
    name: "CareSiteSlice",
    initialState,
    reducers: {
        updateCounter(state = initialState, action) {
            state.msgCount = action.payload.count;
        },
    },
});

export const {
    updateCounter,
} = messageSlice.actions;
export default messageSlice.reducer;
