import React, { useEffect, useState, Fragment } from "react";
import "./signup.css";
import Checkbox from "@mui/material/Checkbox";
import FormButton from "./FormNextButton";
import { useDispatch, useSelector } from "react-redux";
import countryList from "./countries.json";
import {
  changeButton,
  authenticateOwnerSignup,
  trackSignup,
} from "../../redux-toolkit/reducer/accountCreationApiReducer";
import Select from "react-select";
import { postcodeValidator } from "postcode-validator";
import swal from "sweetalert";
import { useForm } from "react-hook-form";
import AwsLocation from "../../utils/AwsLocation";
import { AccessManagementDefaultRoles } from "../../constants/roles";
import { fetch4 } from "../../Apis/commonApis";
import { ShowSwal } from "../../utils/alertSwal";
import { fetchUserIpData, getTaxesAndCurrency } from "../../redux-toolkit/reducer/websitePlan";
import state from "../../constants/usStates.json"

export default function StepTwoCareSiteDetails() {

  const { userIpData, taxesAndCurrencyData } = useSelector((state) => state.websitePlans);
  const { stepNo, step, registerSuccess, step1Data } = useSelector((state) => state.accountCreationApiReducer);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    clearErrors,
  } = useForm();

  const dispatch = useDispatch();

  /// Email verification through api
  const authProcess = "signup";
  const [intervalId, setIntervalId] = useState(null);
  const [addedCountryList, setAddedCountryList] = useState([]);
  const [addedStateList, setAddedStateList] = useState([]);
  const [addedCountry, setAddedCountry] = useState([]);


  useEffect(() => {
    if (userIpData && taxesAndCurrencyData) {
      const addedCountries = new Set();
      let countrytaxesData = [];
      let stateTaxesData = []
      // taxesAndCurrencyData?.taxes?.forEach((elem) => {
      //   countrytaxesData.push({ value: elem._id, label: elem.country });
      // });
      taxesAndCurrencyData?.taxes?.forEach((elem) => {
        if (!addedCountries.has(elem.country)) {
          countrytaxesData.push({ value: elem._id, label: elem.country });
          addedCountries.add(elem.country); // Add the country to the set
        }
        if (elem?.state) {
          stateTaxesData.push({ value: elem._id, label: elem.state });
        }
      });
      setAddedCountryList(countrytaxesData);
      setAddedStateList(stateTaxesData)
      let findCountry = countryList?.countries?.find(item => {
        return item?.isoAlpha2?.includes(userIpData?.country);
      });
      let findCurrency = countrytaxesData.find(item => item.label.includes(findCountry?.name));
      if (!findCurrency) {
        findCurrency = countrytaxesData?.find(item => item.label === 'United Kingdom');
      }
      setAddedCountry(findCurrency)
      const selectedContry = countryList.countries?.find((item) => item.name === findCurrency.label);
      setCountryCode(selectedContry?.isoAlpha2);
    }
  }, [userIpData, taxesAndCurrencyData]);

  useEffect(() => {
    dispatch(fetchUserIpData());
    dispatch(getTaxesAndCurrency());
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (registerSuccess === 2) {
      clearInterval(intervalId);
      swal("Success", "Email Verified Successfully", "success").then(
        (value) => {
          nextStepChangeHandler(true);
        }
      );
    }
  }, [registerSuccess]);

  const [location, setLocation] = useState("");

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const response = await fetch("https://ipapi.co/json/");
        const data = await response.json();
        setLocation(data);
      } catch (error) {
        console.error("Error fetching location:", error);
      }
    };

    fetchLocation();
  }, []);

  const isFormValidCheck = (postdata) => {
    if (!addedCountry.label) {
      setCustomError('country');
      return;
    }
    if (location === '' && postdata.carefacility === "facalities") {
      setlocErrorMsg('location');
      return;
    }
    if (postcodeValidator(postdata?.postalCode?.trim(), countryCode)) {
      let userAgent = navigator.userAgent;

      // Check for user device type
      if (/(iPhone|iPad|iPod)/i.test(userAgent)) {
        location.device_type = "iOS Mobile device";
        return "iOS";
      } else if (/(Android)/i.test(userAgent)) {
        location.device_type = "Android Mobile device";
      } else if (/(Windows NT)/i.test(userAgent)) {
        location.device_type = "Windows PC";
      } else if (/(Macintosh)/i.test(userAgent)) {
        location.device_type = "Mac OS device";
      } else if (/(Linux)/i.test(userAgent)) {
        location.device_type = "Linux OS device";
      } else {
        location.device_type = "Unknown";
      }
      let isBedsRequires = postdata.carehometype === "facalities" ? true : false;
      let combinedAddress = `${postdata.billingAddress}, ${postdata.billingCity}, ${postdata.postalCode?.trim()}, ${addedCountry.label}`;
      let ownerData = {
        ...step1Data,
        companyName: postdata.companyName,
        careSiteName: postdata.careSiteName,
        carefacility: postdata.carehometype,
        totalBeds: isBedsRequires ? postdata.totalBeds : 0,
        postalCode: postdata.postalCode?.trim(),
        city: postdata.billingCity,
        country: addedCountry.label,
        address: combinedAddress,
        billingAddress: postdata.isBilling ? combinedAddress : '',
        billingAddressStatus: postdata.isBilling,
        state: changeState?.label,
        timeZone: location.timezone,
        role: AccessManagementDefaultRoles[0],
        deviceAuth: [{
          deviceInfo: location,
          authenticateStatus: true,
        }],
        userType: "owner",
        lattitude: addressLoc?.latitude ?? 0,
        longitude: addressLoc?.longitude ?? 0,
      };
      if (postdata.carehometype === "facalities") {
        ownerData.careSiteAddress = addressLoc?.Label ?? '';
      }
      localStorage.clear();
      ownerRegister(ownerData)
    } else {
      setCustomError('postal');
      setTimeout(() => {
        setCustomError('');
      }, 3000);
    }
  };

  const ownerRegister = async (body) => {
    const res = await fetch4(`${process.env.REACT_APP_BASEURL}/register`, body);
    if (res.status) {
      let trackData1 = {
        step: 1,
        ownerId: res?.data,
        setp_1_Details: body,
      };
      dispatch(trackSignup(trackData1));
      let intID = setInterval(() => {
        dispatch(authenticateOwnerSignup({ loginOwnerId: res?.data, authProcess }));
      }, 3000);
      setIntervalId(intID);
      swal("Success", res.message, "success").then((value) => {
      });
    } else {
      ShowSwal(false, res.message);
    }
  }

  function nextStepChangeHandler(type) {
    if (type) {
      dispatch(
        changeButton({
          currentNo: stepNo,
          buttonText: "Next",
          stepNo: stepNo + 1,
          nextNo: stepNo + 1,
        })
      );
    } else {
      dispatch(
        changeButton({
          currentNo: 1,
          buttonText: "Next",
          stepNo: 1,
          nextNo: 1,
          registerSuccess: 0,
        })
      );
    }
  }

  // ------------------------------------- /Country list / --------------------------------- //
  let [customError, setCustomError] = useState('');
  let [countryCode, setCountryCode] = useState("");
  const [changeState, setChangeState] = useState("")

  function countryListHandleChange(event) {
    setCustomError('');
    setAddedCountry(event);
    const selectedContry = countryList.countries?.find((item) => item.name === event.label);
    setCountryCode(selectedContry?.isoAlpha2);
  }
  function stateListHandleChange(event) {
    setChangeState(event);
  }

  const [addressLoc, setAddressLoc] = useState("");
  const [locErrorMsg, setlocErrorMsg] = useState('');

  function awsLocationHandler(locVal) {
    setAddressLoc(locVal);
    if (locVal) setlocErrorMsg('');
  }

  let locationPlaceholder = 'CareSite Location (where care services will be given)';
  var updatedStates = state?.states.map(function (state) {
    return {
      value: state?.code,
      label: state?.name
    };
  });

  return (
    <Fragment>
      <div
        className="col-md-4 form_box"
        style={{ display: `${step === "step2" ? "block" : "none"}` }}
      >
        <form>
          <h4>Care Site Details</h4>
          <div className="mb-2">
            <input
              type="text"
              id="companyName"
              className="form-control"
              {...register("companyName", {
                required: true,
              })}
              placeholder="company Name"
            />
            {errors?.companyName?.type === "required" && (
              <p className="error_style error_bottom">This field is required</p>
            )}
          </div>

          <div className="mb-2">
            <input
              type="text"
              id="billingAddress"
              className="form-control"
              {...register("billingAddress", {
                required: true,
              })}
              placeholder="Company Address"
            />
            {errors?.billingAddress?.type === "required" && (
              <p className="error_style error_bottom">This field is required</p>
            )}
          </div>

          <div className="mb-2">
            <input
              type="text"
              id="billingCity"
              className="form-control"
              {...register("billingCity", {
                required: true,
              })}
              placeholder="City"
            />
            {errors?.billingCity?.type === "required" && (
              <p className="error_style error_bottom">This field is required</p>
            )}
          </div>

          <div className="col-md-12 mb-3">
            <Select
              className="select_Box_border"
              placeholder="Country"
              options={addedCountryList}
              value={addedCountry}
              onChange={countryListHandleChange}
            />
            {customError === 'country' && (
              <span className="input_error">Please Enter Country</span>
            )}
          </div>

          {addedCountry?.label == "United States" && <div className="col-md-12 mb-3">
            <Select
              placeholder="Select an State"
              options={addedStateList}
              onChange={stateListHandleChange}
            />
            {customError === 'state' && (
              <span className="input_error">Please Enter State</span>
            )}
          </div>}

          <div className="mb-2">
            <input
              type="text"
              id="postalCode"
              className="form-control"
              {...register("postalCode", {
                required: true,
              })}
              placeholder="Postal Code"
            />
            {(errors?.postalCode?.type === "required" && customError === '') && (
              <p className="error_style error_bottom">This field is required</p>
            )}
            {customError === 'postal' && (
              <p className="error_style error_bottom">Please enter correct Postal code</p>
            )}
          </div>

          <div className="checkbox-wrapper">
            <Checkbox
              name="billing"
              {...register("isBilling", {
                required: false,
              })}
              inputProps={{ "aria-label": "controlled" }}
            />
            <span>Use as Billing Address</span>
          </div>
          <input
            type="text"
            id="careSiteName"
            className="form-control"
            {...register("careSiteName", {
              required: true,
            })}
            placeholder="careSite Name"
          />
          {errors?.careSiteName?.type === "required" && (
            <p className="error_style error_bottom">This field is required</p>
          )}
          <select
            className="form-controll form-select"
            {...register("carehometype", {
              required: true,
            })}
          >
            <option value="agency">Care Agency</option>
            <option value="facalities">Care Facilities </option>
          </select>

          {watch('carehometype', '') === "facalities" &&
            <AwsLocation awsLocationHandler={awsLocationHandler} placeholder={locationPlaceholder} />
          }
          {(locErrorMsg === 'location') && (
            <p className="error_style error_bottom">
              This field is required
            </p>
          )}

          {watch('carehometype', '') === "facalities" && (
            <input
              type="number"
              id="totalBeds"
              className="form-control"
              {...register("totalBeds", {
                required: true,
              })}
              placeholder="No. of Beds (if site is a care facility)"
            />
          )}
          {errors?.careSiteName?.type === "required" && (
            <p className="error_style error_bottom">This field is required</p>
          )}
          <FormButton isFormValidCheck={handleSubmit(isFormValidCheck)} />
        </form>
      </div>
    </Fragment>
  );
}
