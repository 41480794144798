import React from "react";
import AdminWrapper from "../../../components/AdminWrapper";
import "./PredefinedTemplate.css";

export default function PredefinedTemplates() {
  return (
    <AdminWrapper>
      <div className="predefineTemplate">
        <div className="row mb-5 align-items-center">
          <div className="col-md-6">
            <h5 className="mb-0">Readiness for Enhanced SelfCare</h5>
          </div>
          <div className="col-md-6">
            <ul className="menuListTop">
              <li>
                <button className="btn">Back</button>
              </li>
              <li>
                <button className="btn">Save</button>
              </li>
              <li>
                <button className="btn">Edit</button>
              </li>
              <li>
                <button className="btn">Save to Plan</button>
              </li>
              <li>
                <button className="btn">Close</button>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mb-3">
            <ul className="catList">
              <h6 className="mb-0">Select Risk Profile: </h6>
              <li
                // className={activetemplateriskClass === "high" ? "high" : ""}
                style={{ backgroundColor: "#BE0C0C" }}
              >
                High <br /> Risk
              </li>
              <li
                // className={activetemplateriskClass === "medium" ? "medium" : ""}
                style={{ backgroundColor: "#D96A07" }}
              >
                Medium <br /> Risk
              </li>
              <li
                // className={activetemplateriskClass === "low" ? "low" : ""}
                style={{ backgroundColor: "#7D7D7D" }}
              >
                Low <br /> Risk
              </li>
            </ul>
            <h6>Last Update Date: </h6>
            <h6>Updated By: </h6>
          </div>
          {/* Questions and Answers Dynamic Form  */}

          <>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <h5>Oberservations, Needs and Comments</h5>

                  <div className="d-flex mb-3">
                    <textarea
                      style={{ minHeight: "150px" }}
                      className="form-control"
                      placeholder=""
                    ></textarea>
                  </div>

                  <h5>Outcomes</h5>

                  <div className="d-flex mb-3">
                    <textarea
                      style={{ minHeight: "150px" }}
                      className="form-control"
                      placeholder=""
                    ></textarea>
                  </div>
                </div>

                <div className="col-md-6">
                  <h5>Risks</h5>
                  <input
                    type="text"
                    className="form-control mb-3"
                    placeholder="Add Risk"
                  />
                  <input
                    type="text"
                    className="form-control mb-3"
                    placeholder="Add Risk"
                  />
                </div>

                <div className="col-md-6">
                  <h5>Interventions</h5>
                  <div className="position-relative pe-5">
                    <input
                      type="text"
                      className="form-control mb-3"
                      placeholder=""
                    />
                    <button className="removeBtn" style={{ top: "3px" }}>
                      ADD
                    </button>
                  </div>
                  <div className="position-relative pe-5">
                    <input
                      type="text"
                      className="form-control mb-3"
                      placeholder=""
                    />
                    <button className="removeBtn" style={{ top: "3px" }}>
                      -
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </AdminWrapper>
  );
}
