import React, { Fragment, useMemo } from "react";
import getSymbolFromCurrency from "currency-symbol-map";
import { useSelector } from "react-redux";
import { limitToTwoDecimal } from "../../utils/CommonFunctions";

export default function StepsPlanSection(props) {
  let { plan, SubscriptionType, selectedPlan, selectedPlanId } = props;

  const { selectedCurrency } = useSelector((state) => state.websitePlans);

  function planSelected(id) {
    selectedPlan(id);
  }

  const planPriceWithCurrency = useMemo(() => {
    const amtwithCurrency = (SubscriptionType ? plan?.unitPriceYearly : plan?.unitPriceMonthly) * (+selectedCurrency?.gbpExchangeRate);
    return limitToTwoDecimal(amtwithCurrency);
  }, [SubscriptionType, plan, selectedCurrency]);

  return (
    <Fragment>
      <div className="row planSelect">
        <div className="col">
          <img
            alt=""
            className="me-2"
            width="40px"
            height={"50px"}
            src={plan?.image}
          />
        </div>
        <div className="col text-start">
          <h5>{plan?.planName}</h5>
        </div>
        <div className="col">
          <span>Features</span>
        </div>
        <div className="col">
          <h5>
            {/* {getSymbolFromCurrency(currency)}{" "} */}
            {selectedCurrency?.symbol}&nbsp;
            {planPriceWithCurrency}
          </h5>
        </div>
        <div className="col">
          <h5> {SubscriptionType ? "/year" : " /month"}</h5>
        </div>
        <div className="col d-flex align-items-center justify-content-center">
          <input
            type="radio"
            name="subscriptionPlan"
            value={plan?._id}
            defaultChecked={plan?._id === selectedPlanId}
            onChange={(e) => planSelected(e.target.value)}
          />
        </div>
      </div>
    </Fragment>
  );
}
