import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import cx from './shift.module.css';
import { Controller, useForm } from "react-hook-form";
import { timeValues } from '../../constants/constants';
import { httpAdmin } from '../../Apis/commonApis';
import { currentCareSiteId } from '../../redux-toolkit/reducer/switchSites';
import { ShowSwal } from '../../utils/alertSwal';
import { getCurrentTimeZone, getWeekStartAndEndDates } from '../../utils/CommonFunctions';

const noOfShiftDays = [1, 2, 3, 4, 5, 6, 7, 8, 9];

export default function ShiftConfig(props) {
    const { getShiftsandtaskNew, prevShiftData, shiftDate } = props;
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        watch,
        formState: { errors },
        control,
    } = useForm();

    const [noOfShift, setNoOfShift] = useState([1]);

    useEffect(() => {
        console.log(shiftDate, 'shiftDate');
        if (shiftDate) setValue('applyFrom', shiftDate);
        // if (prevShiftData) {
            // handleShiftNoChange(prevShiftData.shiftLimit);
            // setValue('applyFrom', prevShiftData.applyFrom);
            // for (let i = 1; i <= prevShiftData.shiftLimit; i++) {
            //     setValue(`shiftName${i}`, prevShiftData?.shiftDetails[i - 1]?.shiftName);
            //     setValue(`shiftBreak${i}`, prevShiftData?.shiftDetails[i - 1]?.shiftBreak);
            //     setValue(`startTime${i}`, prevShiftData?.shiftDetails[i - 1]?.startTime);
            //     setValue(`endTime${i}`, prevShiftData?.shiftDetails[i - 1]?.endTime);
            // }
        // }
    }, [shiftDate]);

    const saveNoOfShifts = async (shiftData) => {
        let shiftNoArr = [];
        for (let i = 1; i <= noOfShift.length; i++) {
            shiftNoArr.push({
                shiftName: shiftData[`shiftName${i}`],
                shiftBreak: shiftData[`shiftBreak${i}`],
                startTime: shiftData[`startTime${i}`],
                endTime: shiftData[`endTime${i}`],
            })
        }
        let userTimezone = getCurrentTimeZone();
        let shiftConfigObj = {
            careHomeId: currentCareSiteId,
            shiftLimit: noOfShift.length,
            applyFrom: shiftData.applyFrom,
            applyTo: shiftData.applyTo ?? shiftData.applyFrom,
            shiftDetails: shiftNoArr,
            timeZone: userTimezone
        };
        console.log(shiftConfigObj, 'shiftConfigObj');
        const res = await httpAdmin(`createShiftConfig`, 'post', shiftConfigObj, null);
        getShiftsandtaskNew('createOrEdit');
        ShowSwal(res.status, res.message);
    }

    function handleShiftNoChange(value) {
        let arr = [];
        for (let i = 1; i <= value; i++) {
            arr.push(i)
        }
        setNoOfShift(arr);
    }

    return (
        <div className={`${cx.dayFilterBox}`}>
            <Col md={10}>
                <Row className="align-items-center">
                    <Col md={4} className="mb-3">
                        <div>No. of Shifts per Day:</div>
                        <div className={`${cx.configur_staff_select}`}>
                            <select
                                className="form-select"
                                aria-label="Default select example"
                                onChange={(e) => handleShiftNoChange(e.target.value)}
                                value={noOfShift.length}
                            >
                                {noOfShiftDays.map((item) => <option key={item} value={item}>{item}</option>)}
                            </select>
                        </div>
                    </Col>
                    <Col md={3} className="mb-3">
                        <div>Apply {shiftDate ? 'Date' : 'From'}:</div>
                        <div className={`${cx.configur_staff_select}`}>
                            <input type="date"
                                className={`form-control ${errors[`applyFrom`]?.type === "required" ? cx.borderRed : ''}`}
                                {...register('applyFrom', {
                                    required: true,
                                })}
                            />
                        </div>
                    </Col>
                    {shiftDate === '' && <Col md={3} className="mb-3">
                        <div>To:</div>
                        <div className={`${cx.configur_staff_select}`}>
                            <input type="date"
                                className={`form-control ${errors[`applyTo`]?.type === "required" ? cx.borderRed : ''}`}
                                {...register('applyTo', {
                                    required: true,
                                })}
                            />
                        </div>
                    </Col>}
                </Row>
                {noOfShift.map((item) => {
                    return (
                        <Row key={item} className="align-items-center">
                            <Col md={3} className="mb-3">
                                <div>
                                    <input
                                        type="text"
                                        placeholder="Shift Name"
                                        className={`form-control ${errors[`shiftName${item}`]?.type === "required" ? cx.borderRed : ''}`}
                                        {...register(`shiftName${item}`, {
                                            required: true,
                                        })}
                                    />
                                </div>
                            </Col>
                            <Col md={3} className="mb-3">
                                <div>
                                    <input
                                        type="number"
                                        placeholder="Break Time(mins)"
                                        className={`form-control ${errors[`shiftName${item}`]?.type === "required" ? cx.borderRed : ''}`}
                                        {...register(`shiftBreak${item}`, {
                                            required: false,
                                        })}
                                    />
                                </div>
                            </Col>
                            <Col md={3} className="mb-3">
                                <div>
                                    <div className={`${cx.configur_staff_select}`}>
                                        <select
                                            className={`form-select ${errors[`startTime${item}`]?.type === "required" ? cx.borderRed : ''}`}
                                            aria-label="Default select example"
                                            {...register(`startTime${item}`, {
                                                required: true,
                                            })}
                                        >
                                            <option value=''>Start Time</option>
                                            {timeValues.map((vals) => <option key={vals.value} value={vals.value}>{vals.label}</option>)}
                                        </select>
                                    </div>
                                </div>
                            </Col>
                            <Col md={3} className="mb-3">
                                <div>
                                    <div className={`${cx.configur_staff_select}`}>
                                        <select
                                            className={`form-select ${errors[`endTime${item}`]?.type === "required" ? cx.borderRed : ''}`}
                                            aria-label="Default select example"
                                            {...register(`endTime${item}`, {
                                                required: true,
                                            })}
                                        >
                                            <option value=''>End Time</option>
                                            {timeValues.filter(elem => elem.value > watch(`startTime${item}`, '')).map((vals) => <option key={vals.value} value={vals.value}>{vals.label}</option>)}
                                        </select>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    )
                })}
            </Col>
            <Col md={11}>
                <div className={`${cx.saveBtn} text-end`}>
                    <button type="submit" onClick={handleSubmit(saveNoOfShifts)}>Save</button>
                </div>
            </Col>
        </div>
    )
}
