import React, { useState, useEffect, useMemo } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import Dropdown from "react-bootstrap/Dropdown";
import { currentCareSiteId } from "../../redux-toolkit/reducer/switchSites";
import { userData } from "../../redux-toolkit/reducer/switchSites";
import swal from "sweetalert";
import { httpAdmin } from "../../Apis/commonApis";
import Select from "react-select";
import { AiOutlineClose } from "react-icons/ai";
import { Controller, useForm } from "react-hook-form";
import styles from "../../superadmin/Invoices/invoices.module.css";
import { disablePastDate, getFullName, handleDataPrint } from "../../utils/CommonFunctions";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { ShowSwal } from "../../utils/alertSwal";
import { AiFillPrinter } from "react-icons/ai";
import JsonDownload from "../../components/DownloadExcel/DownloadExcel";
import LoadingBtn from "../../components/Toggle_Button/LoadingBtn";
import { twoDecimalPlaces } from "../../utils/utilsHandlerFunctions";
import { useSelector } from "react-redux";

export default function InvoiceAction(props) {
    const { invoiceTemplateHandler, selected, getClientRowData, rowData } = props;

    const { accessRoles } = useSelector((state) => state.caresiteDataReducer);
    const moduleAccess = useMemo(() => {
        const findCurrentModule = accessRoles?.find(item => item.moduleName === 'Invoicing');
        return findCurrentModule;
    }, [accessRoles]);

    const [date, setDate] = useState(new Date().toISOString().slice(0, 10));

    const {
        register,
        handleSubmit,
        unregister,
        reset,
        setValue,
        formState: { errors },
        control,
    } = useForm();

    let initialChargesVals = {
        desc: "",
        rate: '',
        qty: 1,
        tax: '',
        amount: 0,
    };

    const [show, setShow] = useState(false);
    const [tax, setTax] = useState(0);
    const [clientList, setClientList] = useState([]);
    const [keyContactList, setKeyContactList] = useState([]);
    const [chargesData, setChargesData] = useState(initialChargesVals);
    const [customErr, setCustomErr] = useState('');
    const [selectedClient, setSelectedClient] = useState(null);
    const [selectedkeyCont, setSelectedKeyCont] = useState(null);
    const [selectedCharges, setSelectedCharges] = useState([]);
    const [payStatus, setPayStatus] = useState('unpaid');
    const [loading, setLoading] = useState(false);

    // -----------  Approval Status Popup (Show and Hide Handler) Starts ------------ //

    const [showApprovalPopup, setShowApprovalPopup] = useState(false);
    const handleCloseApprovalPopup = () => setShowApprovalPopup(false);
    const handleShowApprovalPopup = () => setShowApprovalPopup(true);
    const [manualSwitch, setManualSwitch] = useState(false);

    const [dataToDownload, setDataToDownload] = useState([]);
    useEffect(() => {
        let downloadData = [];
        rowData?.forEach(elem => {
            let invFor = getFullName(elem?.clientId)
            // `${elem?.clientId?.first_Name} ${elem?.clientId?.last_Name}`
            downloadData.push({
                ['Invoice For']: invFor,
                ['Invoice No.']: elem.invoiceNo ?? '',
                ['Creation Date']: elem.creationDate ?? '',
                ['Invoice To']: elem.toEmail ?? '',
                ['Value']: elem.total ?? '',
                ['Approval']: elem.approval ?? '',
                ['Approved By']: elem.approveBy ?? '',
                ['Sent']: elem.startDate ?? '',
                ['Status']: elem.status ?? '',
                ['Payment Date']: elem.approvalTimestamp ?? '',
            })
        });
        setDataToDownload(downloadData);
    }, []);

    useEffect(() => {
        getClientsData();
    }, []);

    // -------------------------------------- Approve Invoice Api (Yes /No) -------------------------------------------- //

    function approveInvoiceStatus() {
        setLoading(true);
        let userData = JSON.parse(localStorage.getItem('userData'));
        var data = JSON.stringify({
            careHomeId: selected?.careHomeId,
            invoiceId: [selected?._id],
            approveBy: `${userData.firstName} ${userData.lastName} (${userData.userType})`
        });
        var config = {
            method: "post",
            url: `${process.env.REACT_APP_BASEURL}/approveInvoice`,
            headers: {
                Authorization: localStorage.getItem("care_admin_token"),
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config).then((res) => {
            setLoading(false);
            getClientRowData();
            handleCloseApprovalPopup();
            swal("Success", res.data.message, "success");
        }).catch((error) => {
            console.log(error.message, 'eeeee');
            setLoading(false);
            swal("Failed", error.message, "error");
        });
    }
    // -------------------------------------- Payment Status (Paid) Invoice Api -------------------------------------------- //

    function markPaymentStatus() {
        var data = JSON.stringify({
            invoiceId: [selected?._id],
            paymentTimestamp: date,
            status: payStatus,
        });
        var config = {
            method: "post",
            url: `${process.env.REACT_APP_BASEURL}/markPaymentStatus`,
            headers: {
                Authorization: localStorage.getItem("care_admin_token"),
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (result) {
                getClientRowData();
                handleClose();
                ShowSwal(result.data.status, result.data.message);
            })
            .catch(function (error) {
                console.log(error);
                ShowSwal(false, error);
            });
    }

    const createInvoiceManually = async (data) => {
        if (selectedCharges.length < 1 || !selectedClient || !selectedkeyCont) {
            setCustomErr(selectedCharges.length < 1 ? 'charges' : selectedkeyCont ? 'client' : 'keyContact');
            return;
        }
        let userdata = JSON.parse(localStorage.getItem('userData'));
        let selectedChargeData = [...selectedCharges];
        selectedChargeData?.forEach(elem => {
            elem.tax = +tax;
        });

        // let date1 = new Date(data.invoiceDeadline);
        // let date2 = new Date(data.invoiceDate);
        // let timeDiffInMilliseconds = Math.abs(date2 - date1);
        // let daysDiff = Math.ceil(timeDiffInMilliseconds / (1000 * 60 * 60 * 24));
        const totalWithoutTax = selectedCharges.reduce((acc, obj) => acc + obj.amount, 0);
        const totalWithTax = totalWithoutTax + (totalWithoutTax / 100) * tax;

        let body = {
            careHomeId: currentCareSiteId,
            adminId: userData?._id,
            clientId: selectedClient,
            toName: data.toName,
            toAddress: data.toAddress,
            invoicePeriod: data?.invoicePeriod,
            toEmail: data.toEmail,
            toContactNo: data.toContactNo,
            fromName: userdata.careSiteName,
            fromAddress: userdata.billingAddress,
            fromEmail: userdata.email,
            // invoiceDeadline: data.invoiceDeadline,
            // invoicePeriod: `${daysDiff} days`,
            invoicePeriod: `${data.invoicePeriodStart}:${data.invoicePeriodEnd}`,
            chargesDetails: selectedChargeData,
            tax: tax,
            total: totalWithTax,
        };
        const result = await httpAdmin(`createInvoice`, 'post', body, null);
        reset();
        setSelectedCharges([]);
        setTax(0);
        getClientRowData();
        handleClose();
        ShowSwal(result.status, result.message);
    };

    const deleteInvoice = async () => {
        const result = await httpAdmin('deleteInvoice', 'post', { invoiceId: selected._id }, null);
        getClientRowData();
        ShowSwal(result.status, result.message);
    }

    const handleClose = () => setShow(false);

    const getClientsData = () => {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/getClient?careHomeId=${currentCareSiteId}`,
            method: "GET",
            headers: { Authorization: localStorage.getItem("care_admin_token") },
        })
            .then((res) => {
                if (res?.data?.clientListing) {
                    let ClientData = res.data.clientListing.clientListing;
                    let clients = [];
                    ClientData?.forEach((element, index) => {
                        if (element.clientType === "client") {
                            clients.push({
                                value: element._id,
                                label: getFullName(element),
                                // `${element.first_Name} ${element.last_Name}`,
                            });
                        }
                    });
                    setClientList(clients);
                }
            })
            .catch((error) => console.log(`Error: ${error}`));
    };

    // ---------------------------------- Get Care Home Invoice Details  ------------------------------- //

    const getClientKeyContact = (client) => {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/getClientKeyContact?clientId=${client}`,
            method: "GET",
            headers: { Authorization: localStorage.getItem("care_admin_token") },
        })
            .then((res) => {
                let clientKeyCont = res.data.clientListing[0].keycontact;
                let clientKeyContactsoptions = [];
                clientKeyCont?.map((item) => {
                    clientKeyContactsoptions.push({
                        value: item._id,
                        label: item.name,
                        email: item.email,
                        address: item.address,
                        phone: item.phone,
                    });
                });
                setKeyContactList(clientKeyContactsoptions);
            })
            .catch((error) => console.log(`Error: ${error}`));
    };

    function handleActionClick(type) {
        if (type === "create") {
            setShow("create");
            return;
        }
        if (selected) {
            if (type === "edit") {
                invoiceTemplateHandler(type, selected);
            } else if (type === "approve") {
                handleShowApprovalPopup();
            } else if (type === "delete") {
                swal({
                    title: "Are you sure??",
                    text: "Click below to procced",
                    buttons: {
                        cancel: "Cancel",
                        succeed: "Yes",
                    },
                }).then((result) => {
                    if (result) {
                        deleteInvoice();
                    } else {
                        console.log("rejected");
                    }
                });
            } else {
                setShow("status");
            }
        } else {
            swal("Failed", `Please Select checkbox to proceed`, "error").then(
                (value) => {
                    return;
                }
            );
        }
    }

    function handleClientSelect(event) {
        getClientKeyContact(event.value);
        setSelectedKeyCont(null);
        setSelectedClient(event.value);
        setCustomErr('');
    }

    function handleKeyContactSelect(event) {
        setSelectedKeyCont(event.value);
        setValue('toName', event.label);
        setValue('toEmail', event.email);
        setValue('toAddress', event.address);
        setValue('toContactNo', event.phone);
        setCustomErr('');
    }

    // -------------- multi start time and end time selection ----------------- //

    const handleChargesChange = (e, data) => {
        e.preventDefault();
        if (data.desc.trim() !== "") {
            setCustomErr('');
            setSelectedCharges((prev) => [
                ...prev,
                {
                    discription: data.desc,
                    rate: data.rate,
                    qty: 1,
                    tax: 0,
                    amount: +data.rate,
                },
            ]);
        }
        setChargesData(initialChargesVals);
    };

    const chargesRemoveChangeHandler = (e, indexToRemove) => {
        e.preventDefault();
        const clonefield = selectedCharges.slice();
        clonefield.splice(indexToRemove, 1);
        setSelectedCharges(clonefield);
    };

    const handleManualSwitch = (event) => {
        setManualSwitch(event.target.checked);
    };

    let totalAmt = selectedCharges.reduce((acc, obj) => acc + obj.amount, 0);
    totalAmt = totalAmt + (totalAmt / 100) * tax;

    return (
        <>
            <div className="d-flex justify-content-between ">
                <div>
                    <AiFillPrinter
                        className="me-3 ms-1 curser_point"
                        onClick={() => handleDataPrint(dataToDownload)}
                    />
                    <a className="curser_point">
                        <JsonDownload
                            fileName="invoice-excel"
                            downloadfile={dataToDownload}
                        />
                    </a>
                </div>

                <div
                    className="mb-2 btns_head"
                    style={{ marginTop: "-15px" }}
                >
                    <Dropdown className="">
                        <Dropdown.Toggle id="dropdown-basic">Actions</Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                disabled={moduleAccess?.access !== 'full'}
                                onClick={() => {
                                    handleActionClick("create");
                                }}
                            >
                                Manual Invoice
                            </Dropdown.Item>
                            <Dropdown.Item
                                disabled={moduleAccess?.access !== 'full'}
                                onClick={() => {
                                    handleActionClick("edit");
                                }}
                            >
                                Edit
                            </Dropdown.Item>
                            <Dropdown.Item
                                disabled={moduleAccess?.access !== 'full'}
                                onClick={() => {
                                    handleActionClick("delete");
                                }}
                            >
                                Delete
                            </Dropdown.Item>
                            <Dropdown.Item
                                disabled={moduleAccess?.access !== 'full'}
                                onClick={() => {
                                    handleActionClick("approve");
                                }}
                            >
                                Approve
                            </Dropdown.Item>
                            <Dropdown.Item
                                disabled={moduleAccess?.access !== 'full'}
                                onClick={() => {
                                    handleActionClick("status");
                                }}
                            >
                                Payment Status
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>

            {/*  -------------------------------------------- Approve status Popup (Yes/No) -------------------------------------- */}

            <Modal
                className="viewModal"
                show={showApprovalPopup}
                onHide={handleCloseApprovalPopup}
            >
                <Modal.Header>
                    <Modal.Title>
                        <span>Approve Invoice</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="">
                                <p>Are you sure you want to approve this Invoice ?</p>
                                <p>
                                    Once you approve, an email will be sent to your client with
                                    the finalized Invoice.
                                </p>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex">
                        <button className="btn" onClick={handleCloseApprovalPopup}>
                            No
                        </button>
                        <button
                            disabled={loading}
                            className="btn"
                            onClick={() => { approveInvoiceStatus(); }}
                        >
                            {loading ? <LoadingBtn /> : 'Yes'}
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>

            {/*  -------------------------------------------- Payment status Popup (paid/unpaid/overdue)  -------------------------------------- */}

            <Modal
                className="viewModal"
                show={show === "status"}
                onHide={handleClose}
            >
                <Modal.Header>
                    <Modal.Title>
                        <span>Payment Invoice Status</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 mb-2">
                            <label className="mb-1">Payment Status</label>
                            <select
                                className="form-select"
                                value={payStatus}
                                onChange={(e) => setPayStatus(e.target.value)}
                            >
                                <option value="paid">Paid</option>
                                <option value="unpaid">Unpaid</option>
                                <option value="overdue">Overdue</option>
                            </select>
                        </div>
                        <div className="col-md-12 mb-2">
                            <label className="mb-1">Payment Date</label>
                            <input
                                type="date"
                                className="form-control"
                                defaultValue={date}
                                onChange={(e) => {
                                    setDate(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex">
                        <button className="btn submitBtn" onClick={handleClose}>
                            No
                        </button>
                        <button
                            className="btn submitBtn"
                            onClick={() => {
                                markPaymentStatus();
                            }}
                        >
                            Yes
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal
                className="viewModal modal_width"
                show={show === "create"}
                onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>
                        <span>Create Invoice</span>
                        <span>
                            <AiOutlineClose className="curser_point" onClick={handleClose} />
                        </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit(createInvoiceManually)}>
                        <div className="row">
                            <div className="col-md-12">
                                <label className="form-label mt-2">Select Client</label>
                                <Select options={clientList} onChange={handleClientSelect} />
                                {customErr.includes('client') && (
                                    <span className='error_style'>
                                        This field is required
                                    </span>
                                )}
                            </div>

                            <h6 className={`text-dark mb-2 mt-2`}>To</h6>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={manualSwitch}
                                        onChange={handleManualSwitch}
                                    />
                                }
                                label="Add Details Manually"
                                className="mb-2"
                            />
                            {manualSwitch ? <>
                                <div className="mb-3 col-md-6">
                                    <input
                                        type="text"
                                        className="form-control"
                                        {...register("toName", {
                                            required: true,
                                        })}
                                        name="toName"
                                        placeholder="Recipent Name" />
                                    {errors.toName && (
                                        <span className='error_style'>
                                            This field is required
                                        </span>
                                    )}
                                </div>
                                <div className="mb-3 col-md-6">
                                    <input
                                        type="email"
                                        className="form-control"
                                        {...register("toEmail", {
                                            required: true,
                                        })}
                                        name="toEmail"
                                        placeholder="Recipent Email" />
                                    {errors.toEmail && (
                                        <span className='error_style'>
                                            This field is required
                                        </span>
                                    )}
                                </div>
                                <div className="mb-3 col-md-6">
                                    <textarea
                                        className="form-control"
                                        {...register("toAddress", {
                                            required: true,
                                        })}
                                        name="toAddress"
                                        placeholder="Recipent Address"
                                    ></textarea>
                                    {errors.toAddress && (
                                        <span className='error_style'>
                                            This field is required
                                        </span>
                                    )}
                                </div>
                                <div className="mb-3 col-md-6">
                                    <input
                                        type="number"
                                        className="form-control"
                                        {...register("toContactNo", {
                                            required: true,
                                        })}
                                        name="toContactNo"
                                        placeholder="Recipent Telephone" />
                                    {errors.toContactNo && (
                                        <span className='error_style'>
                                            This field is required
                                        </span>
                                    )}
                                </div>
                            </> :
                                <div className="col-md-12">
                                    <label className="form-label mt-2">Select Key Contact</label>
                                    <Select options={keyContactList} onChange={handleKeyContactSelect} />
                                    {customErr.includes('keyContact') && (
                                        <span className='error_style'>
                                            This field is required
                                        </span>
                                    )}
                                </div>
                            }
                            <div className="mb-3 col-md-12">
                                <label className="form-label mt-2"> Invoice Date</label>
                                <input
                                    type="date"
                                    defaultValue={(new Date()).toISOString().slice(0, 10)}
                                    className="form-control"
                                    {...register("invoiceDate", {
                                        required: true,
                                    })}
                                    min={disablePastDate()}
                                    name="invoiceDate"
                                    placeholder="invoice Date"
                                />
                                {errors.invoiceDate && (
                                    <span className='error_style'>
                                        This field is required
                                    </span>
                                )}

                                <div className="d-flex justify-content-between">
                                    <div className="w-50">
                                        <label className="form-label mt-2">Invoice Period</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            {...register("invoicePeriodStart", {
                                                required: true,
                                            })}
                                            name="invoicePeriodStart"
                                        />
                                        {errors.invoicePeriodStart && (
                                            <span className="error_style">This field is required</span>
                                        )}
                                    </div>

                                    <div className="w-50 ms-2">
                                        <label className="form-label mt-2">&nbsp;</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            {...register("invoicePeriodEnd", {
                                                required: true,
                                            })}
                                            name="invoicePeriodEnd"
                                        />
                                        {errors.invoicePeriodEnd && (
                                            <span className="error_style">This field is required</span>
                                        )}
                                    </div>
                                </div>


                            </div>


                            {/* <div className="mb-3 col-md-6">
                                <label className="form-label mt-2">
                                    Invoice Deadline
                                </label>
                                <input
                                    type="date"
                                    className="form-control"
                                    {...register("invoiceDeadline", {
                                        required: true,
                                    })}
                                    min={disablePastDate()}
                                    name="invoiceDeadline"
                                    placeholder="invoice Deadline"
                                />
                                {errors.invoiceDeadline && (
                                    <span className='error_style'>
                                        This field is required
                                    </span>
                                )}
                            </div> */}
                        </div>
                        <div className="row mb-2">
                            <h6 className={`text-dark mb-0`}>Charges Details</h6>
                            <div className="col-md-5">
                                <label className={`form-label ${styles.lable}`}>
                                    Description{" "}
                                </label>
                                <input
                                    type="text"
                                    className={`form-control`}
                                    value={chargesData?.desc}
                                    onChange={(e) =>
                                        setChargesData({ ...chargesData, desc: e.target.value })
                                    }
                                />
                            </div>
                            <div className="col-md-3">
                                <label className={`form-label ${styles.lable}`}>Rate</label>
                                <input
                                    type="text"
                                    className={`form-control`}
                                    value={chargesData?.rate}
                                    onChange={(e) =>
                                        setChargesData({ ...chargesData, rate: e.target.value })
                                    }
                                />
                            </div>
                            {/* <div className="col-md-2">
                                <label className={`form-label ${styles.lable}`}>Tax(%) </label>
                                <input
                                    type="text"
                                    className={`form-control`}
                                    value={chargesData?.tax}
                                    onChange={(e) =>
                                        setChargesData({ ...chargesData, tax: e.target.value })
                                    }
                                />
                            </div> */}
                            <div className="col-md-4">
                                <label className={`form-label ${styles.lable}`}>Amount </label>
                                <div className="d-flex">
                                    <input
                                        type="text"
                                        disabled
                                        className={`form-control`}
                                        value={+chargesData?.rate + ((+chargesData.rate / 100) * chargesData.tax)}
                                    />
                                    <button
                                        className="ms-2 btn theme_btn"
                                        onClick={(e) => handleChargesChange(e, chargesData)}
                                    >
                                        +
                                    </button>
                                </div>
                            </div>
                            {customErr.includes('charges') && (
                                <span className='error_style'>
                                    This field is required
                                </span>
                            )}
                        </div>

                        {selectedCharges?.map((item, index) => {
                            return (
                                <div key={index} className="row mb-2">
                                    <div className="col-md-5">
                                        <input
                                            type="text"
                                            className={`form-control`}
                                            value={item?.discription}
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <input
                                            type="text"
                                            className={`form-control`}
                                            value={item?.rate}
                                            disabled
                                        />
                                    </div>
                                    {/* <div className="col-md-2">
                                        <input
                                            type="text"
                                            className={`form-control`}
                                            value={item?.tax}
                                            disabled
                                        />
                                    </div> */}
                                    <div className="col-md-4">
                                        <div className="d-flex">
                                            <input
                                                type="text"
                                                disabled
                                                className={`form-control`}
                                                value={+item?.rate + +item?.tax}
                                            />
                                            <button
                                                className="ms-2 btn theme_btn"
                                                onClick={(e) => {
                                                    chargesRemoveChangeHandler(e, index);
                                                }}
                                            >
                                                -
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}

                        <div className="row">
                            <div className="col-md-6 mb-1">
                                <label className={`form-label ${styles.lable}`}>Tax(%) </label>
                                <input
                                    type="number"
                                    className={`form-control`}
                                    value={tax}
                                    onChange={(e) => setTax(e.target.value)}
                                    name="tax"
                                />
                                {customErr.includes('tax') && (
                                    <span className='error_style'>
                                        This field is required
                                    </span>
                                )}
                            </div>
                            <div className="col-md-6 mb-1">
                                <label className={`form-label ${styles.lable}`}>Total </label>
                                <input
                                    type="number"
                                    value={twoDecimalPlaces(totalAmt)}
                                    className={`form-control`}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success">
                                Create
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
}
