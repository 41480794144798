import React, { useState } from 'react';
import { Modal } from "react-bootstrap";
import Previewer from './previewer';
import { HiDownload } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";

let videoTypes = ['mp4', 'avi', 'mov', 'wmv', 'mkv', 'flv', 'm4v', 'mpg', 'mpeg', 'webm', '3gp'];
let audioTypes = ['mp3', 'wav', 'flac', 'aac', 'wma', 'm4a', 'mid', 'midi', 'mp2'];

export default function PreviewButton(props) {

    let { filedata } = props;

    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const handleClosePreviewPopup = () => setIsPreviewOpen(false);
    const handleShowPreviewPopup = () => setIsPreviewOpen(true);

    const fileExtension = getFileExtension(filedata.row?.image);

    let fileIcon = fileExtension;
    if (videoTypes.includes(fileExtension)) {
        fileIcon = 'video';
    } else if (audioTypes.includes(fileExtension)) {
        fileIcon = 'audio';
    }

    function getFileExtension(fileUrl) {
        const extension = fileUrl.split('.').pop();
        return extension.toLowerCase();
    }

    function handleDownload(file) {
        window.location.href = file;
    }

    return (
        <>
            <span
                className="action_edit"
                role='button'
                onClick={(e) => {
                    e.preventDefault();
                    handleShowPreviewPopup();
                }}
            >
                <img
                    alt="params.row"
                    src={`/images/icon_${fileIcon}.svg`}
                />
            </span>

            <Modal
                className="viewModal customModalWidth"
                show={isPreviewOpen}
                // onHide={handleClosePreviewPopup}
            >
                <Modal.Header>
                    <Modal.Title>
                        <span>Document Preview</span>
                        <span>
                            {/* <HiDownload
                                className="me-5 curser_point"
                                onClick={() => handleDownload(filedata.row?.image)}
                            /> */}
                            <AiOutlineClose
                                className="curser_point"
                                onClick={handleClosePreviewPopup}
                            />
                        </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Previewer fileUrl={filedata.row?.image} extension={fileExtension} type={fileIcon}/>
                </Modal.Body>
            </Modal>
        </>
    );
};
