import React, { useEffect, useState } from "react";
import cx from "../Portal.module.css";
import { Row, Col, Button } from "react-bootstrap";
import { httpPortal } from "../../Apis/commonApis";
import { useForm } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { securityQuestions } from "../../constants/constants";
import Select from "react-select";
import swal from "sweetalert";
export default function ResetPortalPassword() {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const location = useLocation();
    console.log(location.state.SecurityQuestion,"looooo");
    const email = location.state?.email;
    const history = useHistory();
    const [error, setError] = useState("")
    const [question, setQuestion] = useState("");
    const [customErr, setCustomErr] = useState('');

    function selectQuestion(event) {
        setQuestion(event.label);
        setCustomErr('');
    }
    let createPassword = async (postdata) => {
        if (question === "") {
            setCustomErr('question');
            return;
        }
        var data = JSON.stringify({
            email: email,
            password: postdata?.password,
            security_questions: {
                question: question,
                answer: postdata?.answer
            }
        });
        const res = await httpPortal('savePassword', 'post', data, 'withoutToken');
        if (res.status) {
            history.push({
                pathname: `/portal/password`,
                state: { email: email },
            });

        } else {
            setError(res?.message)
        }

    }
    let updateSecurityQueston = async (postdata) => {
       
        let client_id = await JSON.parse(localStorage?.getItem("fandfData"))
        if (question === "") {
            setCustomErr('question');
            return;
        }
        let data = JSON.stringify({
            id:`${client_id?._id}`,
            old_password: postdata?.password,
            security_questions: {
                question: question,
                answer: postdata?.answer
            },
            resetType:"securityQuestions"
        });
        const res = await httpPortal('reset_password', 'post', data, 'json');
        if (res.status) {
            swal("Success", res.message, "success").then((value) => {
                history.push({ pathname: 'portal-main' });
             });
           
        } else {
            swal("failed", res.message, "error").then((value) => {
                return;
              });
            // setError(res?.message)
        }
    }
    return (
        <div className={`${cx.LoginStart}`}>
            <div className={`container`}>
                <Row>
                    <Col lg={4} md={6} sm={12} className={`m-auto`}>
                        {location.state.SecurityQuestion && ( <div><NavLink to="/portal/portal-main"><img src="/images/BackVector.png" alt=""/></NavLink></div>)}
                        <div className={`${cx.LoginBox}`}>
                            <img src="/images/Newfooter_Logo.svg" alt="" />
                            <h3>Welcome,</h3>
                            <p>It appears that you are signing in for the first time hence please set your password and as additional security set your security question and answer </p>
                            <div className={`${cx.formInput}`}>
                                <div className={`${cx.inputEmail}`}>
                                    <label>Password</label>
                                    <input className={`form-control`} type="Password" placeholder="*******"
                                        {...register("password", {
                                            required: true,
                                        })}
                                    />
                                {errors?.password?.type === "required" && (
                                    <p className="error_style">This field is required</p>
                                )}
                                </div>
                                <div className={`${cx.inputEmail}`}>
                                    <label>Security Questions</label>
                                    <Select
                                        placeholder="Security Question"
                                        options={securityQuestions}
                                        onChange={selectQuestion}
                                        required={true}
                                    />
                                    {customErr === 'question' && (
                                        <p className="error_style">This field is required</p>
                                    )}
                                </div>
                                <div className={`${cx.inputEmail}`}>
                                    <label>Answer</label>
                                    <input className={`form-control`} type="text" placeholder="Answer"  {...register("answer", {
                                        required: true,
                                    })}
                                    />
                                {errors?.answer?.type === "required" && (
                                    <p className="error_style">This field is required</p>
                                )}
                                <p className="error_style">{error}</p>
                                </div>
                            </div>
                            <div className={`${cx.continueButton}`}>
                                
                                <NavLink
                                    to="#"
                                    onClick={location?.state?.SecurityQuestion === undefined ? handleSubmit(createPassword) : handleSubmit(updateSecurityQueston)}
                                >
                                    Continue
                                </NavLink>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
