import React, { useEffect, useState } from "react";
import cx from "../Portal.module.css";
import { Row, Col, Button } from "react-bootstrap";
import { httpPortal } from "../../Apis/commonApis";
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { NavLink } from 'react-router-dom';
export default function CheckSecurityQuestion() {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const history = useHistory();
    const location = useLocation();
    const email = location.state?.email;
    const security_questions =location.state?.security_questions;
    const [error, setError] = useState("")

    let checkSecurityQuestions = async (postdata) => {
        var data = JSON.stringify({
            email: email,
            question:security_questions,
            answer: postdata?.Answer,
        });
        const res = await httpPortal('checkSecurityQuestions', 'post', data, 'withoutToken');
         if (res.status) {
            localStorage.setItem("fandfData", JSON.stringify(res.data));
            localStorage.setItem("fandfam_token", res.token);
            localStorage.setItem("careSite", res?.caresite?.careSiteName);
            history.push(`/portal/portal-main`, { replace: false });
        } else {
            setError(res?.message)
        }

    }
    return (
        <div className={`${cx.LoginStart}`}>
            <div className={`container`}>
                <Row>
                    <Col lg={4} md={6} sm={12} className={`m-auto`}>
                        <div className={`${cx.LoginBox}`}>
                            <img src="/images/Newfooter_Logo.svg" alt="" />
                            <h3>Welcome,</h3>
                            <p>Please enter security answer. </p>
                            <h3>Question</h3>
                            <p>{security_questions}</p>
                             <div className={`${cx.formInput}`}>
                                <div className={`${cx.inputEmail}`}>
                                    <label>Answer</label>
                                    <input className={`form-control`} type="text" placeholder="Answer"
                                        {...register("Answer", {
                                            required: true,
                                        })}
                                    />
                                </div>
                                {errors?.Answer?.type === "required" && (
                                    <p className="errorMessage">This field is required</p>
                                )}
                                <p>{error}</p>
                            </div>
                            <div className={`${cx.continueButton}`}>
                                <NavLink to="#" onClick={handleSubmit(checkSecurityQuestions)}>Continue</NavLink>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
