import React, { useEffect, useMemo, useState } from "react";
import cx from "./Payroll.module.css";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { httpAdmin } from "../../Apis/commonApis";
import { AiOutlineClose } from "react-icons/ai";
import { disablePastDate, getAccessList } from "../../utils/CommonFunctions";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import { Fragment } from "react";
import { twoDecimalPlaces } from "../../utils/utilsHandlerFunctions";
import { useSelector } from "react-redux";

export default function WageProfile() {
  const {
    register,
    handleSubmit,
    unregister,
    reset,
    setValue,
    formState: { errors },
    control,
  } = useForm();

  const { accessRoles } = useSelector((state) => state.caresiteDataReducer);
  const moduleAccess = useMemo(() => {
      const findCurrentModule = accessRoles?.find(item => item.moduleName === 'Payroll');
      return findCurrentModule;
  }, [accessRoles]);

  const [wageProfileData, setWageProfileData] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const wageData = location.state;

  useEffect(() => {
    getWageProfileData();
  }, []);

  const handleEdit = async (data) => {
    let formData = { careTeamId: wageData?.careTeamId, ...data };
    let result = await httpAdmin("editRates", "post", formData, null);
    if (result.status) {
      getWageProfileData();
      handleCloseModal();
    }
    swal(
      result.status ? "Success" : "Failed",
      result.message,
      result.status ? "success" : "error"
    );
  };

  const getWageProfileData = async () => {
    let result = await httpAdmin(
      `getRates?careTeamId=${wageData?.careTeamId}`,
      "get",
      null,
      null
    );
    if (result.status) {
      setWageProfileData(result.data[0]);
    }
  };

  const handleShowModal = () => {
    let payStartDate = wageProfileData?.payslipStartDate
      ? wageProfileData?.payslipStartDate.split("T")[0]
      : "";
    setValue("payslipStartDate", payStartDate);
    // setValue('manager', wageProfileData?.manager);
    // setValue('designation', wageProfileData?.designation);
    setValue("empPayrollFreq", wageProfileData?.empPayrollFreq);
    setValue("wagesPerhour", twoDecimalPlaces(wageProfileData?.wagesPerhour));
    setValue("overtimeRate", twoDecimalPlaces(wageProfileData?.overtimeRate));
    setShowModal(true);
  };

  const handleCloseModal = () => {
    reset();
    setShowModal(false);
  };

  return (
    <div className="page-wrapper">
      <div className="container-fluid min_height">
        <div className="card">
          <div className="card-body">
            {/* <h5 className="default_color">Payroll</h5> */}
            <div className={`top_menubar ${cx.wageEditBtn}`}>
              <div className="row">
                <p className="col-md-3 default_color">
                  Payroll {">"} Wage Profile
                </p>
                <a
                  onClick={history.goBack}
                  className="payroll_back"
                  style={{ fontSize: "13px", top: "4px" }}
                >
                  Back
                </a>
              </div>

              <div
                className="col-md-8 px-2 py-3 mt-1"
                style={{ backgroundColor: "#FAFAFA" }}
              >
                <span className={`profle_img_box d-flex ${cx.wageEditBtn}`}>
                  <img
                    alt=""
                    className="profile_img_table"
                    src={wageData?.image}
                  />
                  <h6 className="ms-3">{wageData?.name}</h6>
                  <button
                    className="btn btn-sm ms-auto"
                    disabled={moduleAccess?.access !== 'full'}
                    onClick={handleShowModal}
                  >
                    Edit
                  </button>
                </span>
              </div>

              <div className="row">
                <div className="col-md-1"></div>
                <div className={`col-12 col-md-7 ${cx.cardBox}`}>
                  <ul className={`me-3 ${cx.licentre}`}>
                    <p>General</p>
                    <li>
                      Employment Start Date
                      <span>
                        {" "}
                        {wageData?.startDate?.toLocaleString("en-GB", {
                          timeZone: "UTC",
                        })}
                      </span>
                    </li>
                    <li>
                      Payslip Generation Start Date
                      <span>
                        {" "}
                        {wageProfileData?.payslipStartDate?.split("T")[0]}
                      </span>
                    </li>
                    <li>
                      Job Title <span> {wageProfileData?.jobTitle ?? ""}</span>{" "}
                    </li>
                    <li>
                      Manager <span> {wageProfileData?.manager}</span>
                    </li>
                    <li>
                      Regular Hours Threshold per Week{" "}
                      <span> {wageProfileData?.weeklyPlannedHours}</span>
                    </li>
                    <li>
                      Employee Payroll Frequency{" "}
                      <span> {wageProfileData?.empPayrollFreq}</span>
                    </li>

                    <li>
                      Current Hourly Rate{" "}
                      <span>
                        {" "}
                        £ {twoDecimalPlaces(wageProfileData?.wagesPerhour)}
                      </span>
                    </li>
                    <li>
                      Current Overtime Rate per hour{" "}
                      <span>
                        {" "}
                        £ {twoDecimalPlaces(wageProfileData?.overtimeRate)}
                      </span>
                    </li>

                    <p>Wage Info</p>

                    <ul>
                      {wageProfileData?.ratesHistory?.map((item, index) => {
                        return (
                          <Fragment key={item._id}>
                            <li>
                              Hourly Rate &emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;
                              <span>
                                £ {twoDecimalPlaces(item?.wagesPerhour)}
                              </span>{" "}
                              <span>
                                {" "}
                                {item?.startDate?.split("T")[0]} -{" "}
                                {item?.endDate?.split("T")[0]}
                              </span>
                            </li>
                            <li>
                              Overtime Rate per hour{" "}
                              <span>
                                {" "}
                                {twoDecimalPlaces(item?.overtimeRate)}£
                              </span>{" "}
                              <span>
                                {" "}
                                {item?.startDate?.split("T")[0]} -{" "}
                                {item?.endDate?.split("T")[0]}
                              </span>
                            </li>
                          </Fragment>
                        );
                      })}
                    </ul>
                    {/* {wageProfileData?.ratesHistory?.map((item) => {
                      return (<li key={item._id}>
                        Overtime Rate per hour{" "}
                        <span> {item?.overtimeRate}£</span> <span> {item?.startDate?.split('T')[0]} - {item?.endDate?.split('T')[0]}</span>
                      </li>
                      );
                    })} */}
                    {/* <p>Deductibles</p>
                                        <li>Tax <span> lsjdlfjlsdfghjk</span></li>
                                        <li>National Insurance <span> lsjdlfjlsdfghjk</span></li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal className="viewModal" show={showModal} onHide={handleCloseModal}>
        <Modal.Header>
          <Modal.Title>
            <span>Edit</span>
            <span>
              <AiOutlineClose
                className="curser_point"
                onClick={handleCloseModal}
              />
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "10px 20px" }}>
          <form onSubmit={handleSubmit(handleEdit)}>
            <div className="row">
              <div className="col-md-12 mb-3">
                <label className="mb-1">Payslip Generation Start Date</label>
                <input
                  type="date"
                  className="form-control"
                  min={disablePastDate()}
                  {...register("payslipStartDate", {
                    required: true,
                  })}
                  name="payslipStartDate"
                />
                {errors?.payslipStartDate?.type === "required" && (
                  <p className="error_style">This field is required</p>
                )}
              </div>
              {/* <div className="col-md-12">
                <div className="d-flex justify-content-between">
                  <div className="col-6 mb-3">
                    <label className="mb-1">Designation</label>
                    <input
                      type="text"
                      className="form-control"
                      style={{ width: "94%" }}
                      {...register("designation", {
                        required: true,
                      })}
                      name="designation"
                    />
                    {errors?.designation?.type === "required" && (
                      <p className="error_style">This field is required</p>
                    )}
                  </div>
                  <div className="col-6 mb-3">
                    <label className="mb-1"> Manager</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("manager", {
                        required: true,
                      })}
                      name="manager"
                    />
                    {errors?.manager?.type === "required" && (
                      <p className="error_style">This field is required</p>
                    )}
                  </div>
                </div>
              </div> */}
              {/* <div className="col-md-12 mb-3">
                <label className="mb-1">Regular Hours Threshold per Week</label>
                <input
                  type="number"
                  className="form-control"
                  value={wageFormData.weeklyPlannedHours}
                  onChange={(e) =>
                    setWageFormData({
                      ...wageFormData,
                      weeklyPlannedHours: e.target.value,
                    })
                  }
                />
              </div> */}
              <div className="col-md-12 mb-3">
                <label className="mb-1">Employee Payroll Frequency</label>
                <select
                  className="form-select"
                  {...register("empPayrollFreq", {
                    required: true,
                  })}
                  name="empPayrollFreq"
                >
                  <option value="">--select--</option>
                  <option value="Weekly">Weekly</option>
                  <option value="Monthly">Monthly</option>
                </select>
                {errors?.empPayrollFreq?.type === "required" && (
                  <p className="error_style">This field is required</p>
                )}
              </div>
              <div className="col-md-12 mb-3">
                <label className="mb-1">Hourly Rate</label>
                <input
                  type="number"
                  className="form-control"
                  {...register("wagesPerhour", {
                    required: true,
                  })}
                  name="wagesPerhour"
                />
                {errors?.wagesPerhour?.type === "required" && (
                  <p className="error_style">This field is required</p>
                )}
              </div>
              <div className="col-md-12 mb-2">
                <label className="mb-1">Overtime Rate per hour</label>
                <input
                  type="number"
                  className="form-control"
                  {...register("overtimeRate", {
                    required: true,
                  })}
                  name="overtimeRate"
                />
                {errors?.overtimeRate?.type === "required" && (
                  <p className="error_style">This field is required</p>
                )}
              </div>

              {wageProfileData?.payslipStartDate ? (
                <div className="col-md-12 mb-3">
                  <label className="mb-1">New Rate Application Date</label>
                  <input
                    type="date"
                    className="form-control"
                    min={disablePastDate()}
                    {...register("currentRateStartDate", {
                      required: true,
                    })}
                    name="currentRateStartDate"
                  />
                  {errors?.currentRateStartDate?.type === "required" && (
                    <p className="error_style">This field is required</p>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="modal-footer">
              <button type="submit" className="btn btn-success">
                Save Edit
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
