import React, { useState, useEffect, Fragment } from "react";
import cx from "./medicinedetails.module.css";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import Typography from "@mui/material/Typography";
import OrderHistoryTable from "./orderHistoryTable";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import MedModal from "./MedModal";
import { httpAdmin } from "../../Apis/commonApis";
import { AiOutlineClose } from "react-icons/ai";
import { currentCareSiteId } from "../../redux-toolkit/reducer/switchSites";
import { timeValues } from "../../constants/constants";
import Select from "react-select";
import { weekDays } from "../../constants/constants";
import { disablePastDate } from "../../utils/CommonFunctions";
import swal from "sweetalert";
import Dropdown from "react-bootstrap/Dropdown";
import { ShowSwal } from "../../utils/alertSwal";
import { useLocation, useHistory } from "react-router-dom";

export default function InsideMedicineTable(props) {
  const { allMedicines, medAccess } = props;

  const location = useLocation();
  const stateVals = location.state;
  const history = useHistory();

  const [manualSwitch, setManualSwitch] = useState(false);
  const [isEditMedicine, setIsEditMedicine] = useState(false);
  const [medName, setMedName] = useState("");
  const [medFormData, setMedFormData] = useState({});
  const [rowDataDetail, setRowDataDetail] = useState(null);
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(false);

  const [doseDetails, setdoseDetails] = useState({
    doseForm: "",
    doseRoute: "",
    unitDoseSize: "",
    unitDoseUnit: "",
    unitDoseUnitMeasure: "",
  });
  const [durationofCourse, setdurationofCourse] = useState({
    inputVal: "1",
    selectType: "Days",
  });
  const [frequency, setFrequency] = useState("Daily");

  const [startTimeField, setStartTimeField] = useState("00:00");
  const [endTimeField, setEndTimeField] = useState("00:00");
  const [selectedTimes, setSelectedTimes] = useState([]);
  const [dateField, setDateField] = useState("");
  const [selectedDates, setSelectedDates] = useState('');
  const [selectedweekDays, setSelectedWeekDays] = useState([]);

  const [show2, setShow2] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [add, setAdd] = useState(false);
  const [editItem, setEditItem] = useState(null);

  const [manualMedNames, setManualMedNames] = useState([]);
  const [custumMedId, setCustumMedId] = useState("");
  // const [careHomeId, setcareHomeId] = useState(currentCareSiteId);
  const [careAdminToken, setcareAdminToken] = useState("");
  const [oHistMedId, setOHistMedId] = useState("");
  const [editMedHist, setEditMedHist] = useState({ selected: false, id: "" });
  const [editHistDetail, setEditHistDetail] = useState(null);
  const [deleteBeforeEdit, setDeleteBeforeEdit] = useState(false);

  let isStartDateEditable = new Date(rowDataDetail?.startDate + 'T' + rowDataDetail?.schedule?.split('-')[0] + 'Z') > new Date();

  useEffect(() => {
    getMedicineDetails();
  }, []);

  useEffect(() => {
    if (isEditMedicine) {
      handleFinalCheck();
      setIsEditMedicine(false);
    }
  }, [isEditMedicine]);

  const getMedicineDetails = async () => {
    setLoading(true);
    const res = await httpAdmin(`getMedicineOrderHistory?clientId=${stateVals?.clientId}&customMedicineId=${stateVals?.customMedId}`, 'get', null, null);
    if (res.status) {
      setRowDataDetail(res.data);
      fillFormDetails(res.data);
    }
    setLoading(false);
  };

  function fillFormDetails(data) {
    setMedFormData({
      careHomeId: data.value,
      clientId: stateVals?.clientId,
      customMedicineId: data.customMedicineId,
      status: data.status,
      SOURCE: data.SOURCE,

      medName: data.medName,
      NM: data.NM,
      prescriber: data.prescriber,
      DESC: data.DESC,
      SUPPCD: data.SUPPCD,
      BNF_CODE: data.BNF_CODE,
      LIC_AUTHCD: data.LIC_AUTHCD,

      ISID: data.ISID,
      STRNT_NMRTR_VAL: data.STRNT_NMRTR_VAL,
      STRNT_NMRTR_UOMCD: data.STRNT_NMRTR_UOMCD,
      STRNT_DNMTR_VAL: data.STRNT_DNMTR_VAL,
      STRNT_DNMTR_UOMCD: data.STRNT_DNMTR_UOMCD,

      medType: data.medType,
      units: data.units,
      strengthUnit: data.strengthUnit,
      strengthDose: data.strengthDose,
      doseAmount: data.doseAmount,
      adminRoute: data.adminRoute,
      // schedule: `${schedulestarttime} - ${scheduleendtime}`,
      // ONT_FORMCD: `${doseDetails.doseForm}.${doseDetails.doseForm}`,
      ONT_FORMCD: data.ONT_FORMCD,
      UDFS: data.UDFS,
      UDFS_UOMCD: data.UDFS_UOMCD,
      UNIT_DOSE_UOMCD: data.UNIT_DOSE_UOMCD,

      SUG_F: data.SUG_F,
      GLU_F: data.GLU_F,
      PRES_F: data.PRES_F,
      CFC_F: data.CFC_F,

      prn: data.prn,
      startDate: data?.startDate?.split("T")[0],
      durationofCourse: data.durationofCourse,
      frequency: data.frequency,
      schedule: data.schedule,
      reorderAlertTrigger: data.reorderAlertTrigger
        ? data.reorderAlertTrigger
        : 10,
      mealConsideration: data.mealConsideration,
    });
    setMedName(data.medName);
    setdurationofCourse({
      inputVal: data.durationofCourse,
      selectType: "Days",
    });
    setFrequency(data.frequency);
    let selecteddays = [];
    let selectedtime = [];
    data.details[0]?.days?.forEach((elem) => {
      selecteddays.push({ value: elem, label: elem });
    });
    data.details[0]?.times?.forEach((elem) => {
      selectedtime.push({
        starttime: elem?.split("-")[0],
        endtime: elem?.split("-")[1],
      });
    });
    setSelectedTimes(selectedtime);
    setSelectedWeekDays(selecteddays);
    setSelectedDates(data.details[0]?.dates);
  }

  function handleEditMedicine() {
    var finalDuration = "";
    if (durationofCourse.selectType === "Days") {
      finalDuration = durationofCourse.inputVal;
    } else if (durationofCourse.selectType === "Weeks") {
      finalDuration = durationofCourse.inputVal * 7;
    } else if (durationofCourse.selectType === "Months") {
      finalDuration = durationofCourse.inputVal * 30;
    }

    if (medFormData.clientId === "" || medName.trim() === "") {
      setError(medFormData.clientId === "" ? "clientId" : "medName");
      return;
    } else if (medFormData?.startDate === '') {
      setError('startDate');
      // ref.current.scrollTop = 300;(frequency === 'Monthly' && selectedweekDays.length === 0)
      return;
    }

    let isPRNYes = medFormData.prn === 'Yes';

    if (!isPRNYes) {
      if (selectedTimes.length === 0) {
        setError(medFormData?.startDate === '' ? 'startDate' : 'schedule');
        // ref.current.scrollTop = 300;(frequency === 'Monthly' && selectedweekDays.length === 0)
        return;
      } else if (frequency === 'Weekly' && selectedweekDays.length < 1) {
        setError('selectedWeekDays');
        return;
      } else if (frequency === 'Monthly' && selectedDates.length < 1) {
        setError('selectedDates');
        return;
      }
    }

    let scheduleTimeArray = [];
    let weekDaysArray = [];
    let monthDatesArray = [];

    selectedTimes?.forEach((elem) => {
      scheduleTimeArray.push(`${elem.starttime}-${elem.endtime}`);
    });

    if (frequency === "Weekly") {
      selectedweekDays.forEach((elem) => {
        weekDaysArray.push(elem.value);
      });
    } else if (frequency === "Monthly") {
      monthDatesArray = [...selectedDates];
    }

    let frequencyData = {
      freq: isPRNYes ? '' : frequency,
      days: isPRNYes ? [] : weekDaysArray,
      dates: isPRNYes ? [] : monthDatesArray,
      times: isPRNYes ? [] : scheduleTimeArray
    }

    setMedFormData((prevState) => {
      return {
        ...prevState,
        medName: medName,
        NM: medName,
        // durationofCourse: (finalDuration === rowDataDetail?.durationofCourse) ? finalDuration : finalDuration,
        durationofCourse: finalDuration,
        ONT_FORMCD: `${doseDetails.doseForm}.${doseDetails.doseRoute}`,
        careHomeId: currentCareSiteId,
        frequency: frequencyData,
      };
    });
    setIsEditMedicine(true);
  }

  function handleFinalCheck() {
    if (
      rowDataDetail?.startDate?.split("T")[0] !== medFormData?.startDate ||
      durationofCourse.selectType !== "Days" ||
      rowDataDetail?.frequency !== frequency ||
      deleteBeforeEdit
    ) {
      if (isStartDateEditable) {
        console.log("deleted firsttttttt");
        deleteMedicine();
      } else {
        swal(
          "Failed",
          "It is not possible to change the medicine schedule, when the start date has passed and there has been activity on these tasks.",
          "error"
        ).then((value) => {
          return;
        });
      }
    } else {
      console.log("direct editedddddddd");
      editMedicine();
    }
  }

  const editMedicine = async () => {
    // let changedVals = getChangedValues(medFormData);
    const res = await httpAdmin(`editMedicines`, 'post', medFormData, null);
    if (res.status) {
      getMedicineDetails();
      setShow2(false);
    }
    ShowSwal(res.status, res.message);
  };

  function handleFormValuesChange(keyName, event) {
    let value = event.target.value ? event.target.value : event;
    if (event.target.value === "") value = "";
    setMedFormData((prevState) => {
      return { ...prevState, [keyName]: value };
    });
  }

  const handleClose2 = () => {
    setEditItem(null);
    setAdd(false);
    setShow2(false);
  };

  useEffect(() => {
    let adminToken = localStorage.getItem("care_admin_token");
    setcareAdminToken(adminToken);
    getManulyCreatedMeds(adminToken);
  }, []);

  const addMedicine = async () => {
    setShow2(false);
    const result = await httpAdmin("createMedicines", 'post', medFormData, null);
    if (result.status) {
      getMedicineDetails();
    }
  };

  const deleteMedicine = async () => {
    var data = [
      {
        careHomeId: currentCareSiteId,
        clientId: stateVals?.clientId,
        customMedicineId: custumMedId,
      },
    ];
    const res = await httpAdmin(
      `deleteMedicines`,
      'post',
      { medicineArray: data },
      null
    );
    if (res.status) {
      addMedicine();
    }
  };

  const handleManualSwitch = (event) => {
    setManualSwitch(event.target.checked);
  };

  const getManulyCreatedMeds = (adminToken) => {
    axios({
      url: `${process.env.REACT_APP_BASEURL}/getDmdManual?careHomeId=${currentCareSiteId}`,
      method: "GET",
      headers: { Authorization: adminToken },
    })
      .then((res) => {
        let details = res.data.data;
        let manualMeds = [];
        for (let i = 0; i < details?.length; i++) {
          manualMeds.push({
            label: details[i]?.medicine?.NM,
            value: details[i]?.medicine?._id,
          });
        }
        setManualMedNames(manualMeds);
      })
      .catch((error) => console.log(`Error: ${error}`));
  };

  const getManualMedDetails = async (medId) => {
    const res = await httpAdmin(
      `getSpecificDmdManual?careHomeId=${currentCareSiteId}&medicineId=${medId}`,
      'get',
      null,
      null
    );
  };

  // const medIconColor = {
  //   filter:
  //     "invert(9%) sepia(99%) saturate(5630%) hue-rotate(246deg) brightness(111%) contrast(148%)",
  // };

  const handleManualName = (event) => {
    setMedName(event.target.value);
  };

  const onSelectManualMed = (name, value) => {
    setMedName(name);
    getManualMedDetails(value);
  };
  const onSelectDmdMed = (name, value) => {
    setMedName(name);
  };

  const editMedDetails = (edit, medid, customId) => {
    setCustumMedId(customId);
    setShow2(true);
    setEditItem(edit);
  };

  const showHistoryModal = (medId, detail, type) => {
    setOHistMedId(medId);
    if (type === "add") {
      setShowModal(true);
    } else {
      if (editMedHist.selected) {
        detail.forEach((elem) => {
          if (elem._id === editMedHist.id) {
            setEditHistDetail(elem);
          }
        });
        setShowModal(true);
      }
    }
  };

  const closeHistoryModal = () => {
    setEditHistDetail(null);
    setShowModal(false);
  };

  const deleteOrderHistory = async () => {
    if (editMedHist.selected) {
      swal({
        title: "Are you sure??",
        text: 'Click below to procced',
        buttons: {
          cancel: "Cancel",
          succeed: "Yes",
        },
      }).then(async (result) => {
        if (result) {
          const res = await httpAdmin(`deleteMedicineOrderHistory?clientId=${stateVals?.clientId}&customMedicineId=${stateVals?.customMedId}&orderHistoryId=${editMedHist.id}`, 'post', {}, null);
          if (res.status) {
            getMedicineDetails();
          }
          ShowSwal(res.status, res.message ?? 'Something went wrong!');
        }
      });
    } else {
      ShowSwal(false, 'Please Select atleast one')
    }
  }

  // -------------- multi start time and end time selection ----------------- //

  const handleTimeChange = (e, starttime, endtime) => {
    e.preventDefault();
    if (endtime > "00:00") {
      setSelectedTimes((prev) => [...prev, { starttime, endtime }]);
    }
    setDeleteBeforeEdit(true);
    setStartTimeField("00:00");
    setEndTimeField("00:00");
  };

  const timeRemoveChangeHandler = (e, indexToRemove) => {
    e.preventDefault();
    const clonefield = selectedTimes.slice();
    clonefield.splice(indexToRemove, 1);
    setSelectedTimes(clonefield);
    setDeleteBeforeEdit(true);
  };

  // -------------- dates selection ----------------- //

  const handleDateChange = (e, date) => {
    e.preventDefault();
    if (date) {
      setError("");
      setSelectedDates((prev) => [...prev, date]);
    }
    setDateField("");
    setDeleteBeforeEdit(true);
  };

  const dateRemoveChangeHandler = (e, indexToRemove) => {
    e.preventDefault();
    const clonefield = selectedDates.slice();
    clonefield.splice(indexToRemove, 1);
    setSelectedDates(clonefield);
    setDeleteBeforeEdit(true);
  };

  // -------------- week days selection ----------------- //
  function handleWeekDaysChange(e) {
    setSelectedWeekDays(e);
    setDeleteBeforeEdit(true);
  }

  return (
    <>
      <a className="medicine_back" onClick={() => history.push(`/admin/emar/medicines`)}>
        Back
      </a>

      {loading && <p>Loading...</p>}

      <h6 className="default_color">{rowDataDetail?.medName}</h6>
      <hr />
      <div className="">
        <div
          className="row"
          style={{ justifyContent: "space-between" }}
        >
          <div className={`col-12 col-md-5 col-lg-6 ${cx.cardBox}`}>
            <h5>
              Product Details
              {(rowDataDetail?.SOURCE !== "dmd" && medAccess === 'full') && (
                <a
                  className={`${cx.editIcon}`}
                  onClick={() =>
                    editMedDetails(
                      "productDetails",
                      rowDataDetail?._id,
                      rowDataDetail?.customMedicineId
                    )
                  }
                >
                  Edit
                </a>
              )}
            </h5>
            <ul>
              <li>Name: {rowDataDetail?.medName}</li>
              <li>Description: {rowDataDetail?.DESC}</li>
              <li>Supplier Name: {rowDataDetail?.SUPPCD}</li>
              <li>Medicine Code: {rowDataDetail?.BNF_CODE}</li>
              <li>
                Current Licencing Authority:{" "}
                {rowDataDetail?.LIC_AUTHCD}
              </li>
              <li>Restrictions: {rowDataDetail?.restrictions}</li>
            </ul>
          </div>
          <div className={`col-12 col-md-5 col-lg-5 ${cx.cardBox}`}>
            <h5>
              Product Ingredients
              {(rowDataDetail?.SOURCE !== "dmd" && medAccess === 'full') && (
                <a
                  className={`${cx.editIcon}`}
                  onClick={() =>
                    editMedDetails(
                      "productIngredients",
                      rowDataDetail?._id,
                      rowDataDetail?.customMedicineId
                    )
                  }
                >
                  Edit
                </a>
              )}
            </h5>
            <ul>
              <li>Ingredient: {rowDataDetail?.ISID}</li>
              <li>
                Strength value numerator:{" "}
                {rowDataDetail?.STRNT_NMRTR_VAL}
              </li>
              <li>
                Strength value numerator unit:{" "}
                {rowDataDetail?.STRNT_NMRTR_UOMCD}
              </li>
              <li>
                Strength value denominator:{" "}
                {rowDataDetail?.STRNT_DNMTR_VAL}
              </li>
              <li>
                Strength value denominator unit:{" "}
                {rowDataDetail?.STRNT_DNMTR_UOMCD}
              </li>
            </ul>
          </div>
          <div className={`col-12 col-md-5 col-lg-6 ${cx.cardBox}`}>
            <h5>
              Product Dose Details
              {(rowDataDetail?.SOURCE !== "dmd" && medAccess === 'full') && (
                <a
                  className={`${cx.editIcon}`}
                  onClick={() =>
                    editMedDetails(
                      "doseDetails",
                      rowDataDetail?._id,
                      rowDataDetail?.customMedicineId
                    )
                  }
                >
                  Edit
                </a>
              )}
            </h5>
            <ul>
              <li>
                Dose Form: {rowDataDetail?.ONT_FORMCD?.split(".")[0]}
              </li>
              <li>
                Dose Route: {rowDataDetail?.ONT_FORMCD?.split(".")[1]}
              </li>
              <li>
                Unit dose form size:{" "}
                {rowDataDetail?.UDFS ? rowDataDetail?.UDFS : "0"}
              </li>
              <li>
                Unit dose form units: {rowDataDetail?.UDFS_UOMCD}
              </li>
              <li>
                Unit dose unit of measure:{" "}
                {rowDataDetail?.UNIT_DOSE_UOMCD}
              </li>
            </ul>
          </div>
          <div className={`col-12 col-md-5 col-lg-5 ${cx.cardBox}`}>
            <h5>
              Product Exclusions
              {(rowDataDetail?.SOURCE !== "dmd" && medAccess === 'full') && (
                <a
                  className={`${cx.editIcon}`}
                  onClick={() =>
                    editMedDetails(
                      "productExclusions",
                      rowDataDetail?._id,
                      rowDataDetail?.customMedicineId
                    )
                  }
                >
                  Edit
                </a>
              )}
            </h5>
            <ul>
              <li>Sugar Free: {rowDataDetail?.SUG_F}</li>
              <li>Gluten Free: {rowDataDetail?.GLU_F}</li>
              <li>Preservative Free: {rowDataDetail?.PRES_F}</li>
              <li>CFC Free: {rowDataDetail?.CFC_F}</li>
            </ul>
          </div>
          <div className={`col-12 col-md-5 col-lg-6 ${cx.cardBox}`}>
            <h5>
              {stateVals?.clientName}'s Dosage Requirements
              {medAccess === 'full' && <a
                className={`${cx.editIcon}`}
                onClick={() =>
                  editMedDetails(
                    "dosageRequirements",
                    rowDataDetail?._id,
                    rowDataDetail?.customMedicineId
                  )
                }
              >
                Edit
              </a>}
            </h5>
            <div className="row">
              <div className="col-md-5">
                <ul>
                  <li>PRN: {rowDataDetail?.prn}</li>
                  <li>Dose form size: {rowDataDetail?.doseAmount}</li>
                  <li>
                    Dose Unit of Measure: {rowDataDetail?.strengthUnit}
                  </li>
                  <li>
                    Start Date:{" "}
                    {rowDataDetail?.startDate?.split("T")[0]}
                  </li>
                </ul>
              </div>
              <div className="col-md-7">
                <ul>
                  <li>
                    Duration of Course:{" "}
                    {rowDataDetail?.prn === 'No' && rowDataDetail?.durationofCourse ? `${rowDataDetail?.durationofCourse} Days` : '-'}
                  </li>
                  <li className="content_space">
                    Frequency:{" "}
                    {rowDataDetail?.prn === 'No' && <span>
                      {rowDataDetail?.frequency === "Daily"
                        ? rowDataDetail?.details[0]?.times?.length
                        : rowDataDetail?.frequency === "Weekly"
                          ? rowDataDetail?.details[0]?.days?.length
                          : rowDataDetail?.details[0]?.dates?.length}
                      x
                    </span>}
                    {rowDataDetail?.prn === 'No' && rowDataDetail?.frequency}{" "}
                  </li>
                  {rowDataDetail?.frequency !== "Daily" && (
                    <li>
                      {rowDataDetail?.frequency === "Weekly"
                        ? "Days"
                        : "Dates"}
                      :{" "}
                      {rowDataDetail?.frequency === "Weekly"
                        ? rowDataDetail?.details[0]?.days.join(", ")
                        : rowDataDetail?.details[0]?.dates.join(", ")}
                    </li>
                  )}
                  <li>
                    Time: {rowDataDetail?.details[0]?.times.join(", ")}
                  </li>
                  <li>
                    Meal considerations:{" "}
                    {rowDataDetail?.mealConsideration}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={`col-12 col-md-5 col-lg-5 ${cx.cardBox}`}>
            <h5>
              Inventory
              {medAccess === 'full' && <a
                className={`${cx.editIcon}`}
                onClick={() =>
                  editMedDetails(
                    "requirement_inventory",
                    rowDataDetail?._id,
                    rowDataDetail?.customMedicineId
                  )
                }
              >
                Edit
              </a>}
            </h5>
            <ul>
              <li>
                Initial Quantity in Stock for this client:{" "}
                {rowDataDetail?.inventory?.initial_qty}
              </li>
              <li>
                Last Ordered Qty:{" "}
                {rowDataDetail?.inventory?.last_ordered_qty}
              </li>
              <li>
                Last Received Qty:{" "}
                {rowDataDetail?.inventory?.last_received_qty}
              </li>
              <li>
                Total Quantity Received for Client:{" "}
                {rowDataDetail?.inventory?.total_received_qty}
              </li>
              <li>
                Quantity Remaining (In-Stock):{" "}
                {rowDataDetail?.inventory?.remaining_qty}
              </li>
              <li>
                Reorder Alert Trigger:{" "}
                {rowDataDetail?.reorderAlertTrigger
                  ? rowDataDetail?.reorderAlertTrigger
                  : 0}
              </li>
            </ul>
          </div>
        </div>
        <div className=" btns_head d-flex pt-2 pb-2 border-top">
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Order History
          </Typography>
          <Dropdown className="">
            <Dropdown.Toggle id="dropdown-basic">Actions</Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                disabled={medAccess !== 'full'}
                onClick={() =>
                  showHistoryModal(
                    rowDataDetail?._id,
                    rowDataDetail?.orderHistory,
                    "add"
                  )
                }
              >
                Add
              </Dropdown.Item>
              <Dropdown.Item
                disabled={medAccess !== 'full'}
                onClick={() => {
                  if (editMedHist.selected) {
                    showHistoryModal(
                      rowDataDetail?._id,
                      rowDataDetail?.orderHistory,
                      "edit"
                    )
                  } else {
                    ShowSwal(false, 'Please Select atleast one')
                  }
                }}
              >
                Edit
              </Dropdown.Item>
              <Dropdown.Item
                disabled={medAccess !== 'full'}
                onClick={deleteOrderHistory}>Delete</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <OrderHistoryTable
          orderHistory={rowDataDetail?.orderHistory}
          setEditMedHist={setEditMedHist}
        />
      </div>

      {showModal && (
        <MedModal
          showModal={showModal}
          closeModal={closeHistoryModal}
          getLatestData={getMedicineDetails}
          clientId={stateVals?.clientId}
          oHistMedId={oHistMedId}
          editHistDetail={editHistDetail}
          editMedHist={editMedHist}
        />
      )}

      <Modal className="viewModal" show={show2} onHide={handleClose2}>
        <Modal.Header>
          <Modal.Title>
            {editItem ? <span>Edit</span> : <span>Add Medicine</span>}
            <span>
              <AiOutlineClose
                className="curser_point"
                onClick={handleClose2}
              />
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "10px 20px" }}>
          <form>
            <div className="row">
              {!editItem && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={manualSwitch}
                      onChange={handleManualSwitch}
                    />
                  }
                  label="Add Medicine Menually"
                  className="mb-2"
                />
              )}
              {!manualSwitch && !editItem && (
                <div className="col-md-12 mb-3">
                  <label className="mb-1">Select Medicine Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={medName}
                    placeholder="Search for medicine"
                    onChange={handleManualName}
                  />
                  <div className={` ${cx.dropdown}`}>
                    {allMedicines
                      .filter((item) => {
                        const searchTerm = medName.toLowerCase();
                        const fullName = item?.label.toLowerCase();
                        return (
                          searchTerm &&
                          fullName.startsWith(searchTerm) &&
                          fullName !== searchTerm
                        );
                      })
                      .slice(0, 20)
                      .map((item) => (
                        <div
                          onClick={() =>
                            onSelectDmdMed(item.label, item.value)
                          }
                          className={`${cx.dropdownRow}`}
                          key={item.value}
                        >
                          {item.label}
                        </div>
                      ))}
                  </div>
                </div>
              )}
              {(manualSwitch || editItem === "productDetails") && (
                <div className="col-md-12 mb-3">
                  <label className="mb-1">Add Medicine Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={medName}
                    onChange={handleManualName}
                  />
                  <div className={` ${cx.dropdown}`}>
                    {manualMedNames
                      .filter((item) => {
                        const searchTerm = medName.toLowerCase();
                        const fullName = item?.label.toLowerCase();
                        return (
                          searchTerm &&
                          fullName.startsWith(searchTerm) &&
                          fullName !== searchTerm
                        );
                      })
                      .slice(0, 20)
                      .map((item) => (
                        <div
                          onClick={() =>
                            onSelectManualMed(item.label, item.value)
                          }
                          className={`${cx.dropdownRow}`}
                          key={item.value}
                        >
                          {item.label}
                        </div>
                      ))}
                  </div>
                </div>
              )}
              {(editItem === "productDetails" || add) && (
                <>
                  <div className="col-md-12 mb-3">
                    <label className="mb-1">Prescriber</label>
                    <input
                      type="text"
                      className="form-control"
                      value={medFormData.prescriber}
                      onChange={(e) =>
                        handleFormValuesChange("prescriber", e)
                      }
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="mb-1">Description</label>
                    <input
                      type="text"
                      className="form-control"
                      value={medFormData.DESC}
                      onChange={(e) => handleFormValuesChange("DESC", e)}
                    />
                  </div>
                  {/* <div className="col-md-12 mb-3">
                      <label className="mb-1">Supplier Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={medFormData.SUPPCD}
                        onChange={(e) => handleFormValuesChange('SUPPCD', e)} />
                    </div> */}
                  <div className="col-md-12 mb-3">
                    <label className="mb-1">Medicine Code</label>
                    <input
                      type="text"
                      className="form-control"
                      value={medFormData.BNF_CODE}
                      onChange={(e) => handleFormValuesChange("BNF_CODE", e)}
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="mb-1">
                      Current Licencing Authority
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={medFormData.LIC_AUTHCD}
                      onChange={(e) =>
                        handleFormValuesChange("LIC_AUTHCD", e)
                      }
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="mb-1">Restrictions</label>
                    <input
                      type="text"
                      className="form-control"
                      value={medFormData.restrictions}
                      onChange={(e) =>
                        handleFormValuesChange("restrictions", e)
                      }
                    />
                  </div>
                </>
              )}
              {(editItem === "productIngredients" || add) && (
                <>
                  <div className="col-md-12 mb-3">
                    <label className="mb-1">Ingredient</label>
                    <input
                      type="text"
                      className="form-control"
                      value={medFormData.ISID}
                      onChange={(e) => handleFormValuesChange("ISID", e)}
                    />
                  </div>
                  <div className="d-flex mb-3">
                    <div className="col-md-6">
                      <label className="mb-1">Strength value numerator</label>
                      <input
                        type="text"
                        className="form-control"
                        style={{ width: "94%" }}
                        value={medFormData.STRNT_NMRTR_VAL}
                        onChange={(e) =>
                          handleFormValuesChange("STRNT_NMRTR_VAL", e)
                        }
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="mb-1"> Unit</label>
                      <select
                        className="form-select"
                        value={medFormData.STRNT_NMRTR_UOMCD}
                        onChange={(e) =>
                          handleFormValuesChange("STRNT_NMRTR_UOMCD", e)
                        }
                      >
                        <option value=""> - </option>
                        <option value="ml">Mls</option>
                        <option value="mg">Mgs</option>
                      </select>
                    </div>
                  </div>
                  <div className="d-flex mb-3">
                    <div className="col-md-6">
                      <label className="mb-1">
                        Strength value denominator
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        style={{ width: "94%" }}
                        value={medFormData.STRNT_DNMTR_VAL}
                        onChange={(e) =>
                          handleFormValuesChange("STRNT_DNMTR_VAL", e)
                        }
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="mb-1"> Unit</label>
                      <select
                        className="form-select"
                        value={medFormData.STRNT_DNMTR_UOMCD}
                        onChange={(e) =>
                          handleFormValuesChange("STRNT_DNMTR_UOMCD", e)
                        }
                      >
                        <option value=""> - </option>
                        <option value="ml"> Mls </option>
                        <option value="mg"> Mgs </option>
                      </select>
                    </div>
                  </div>
                </>
              )}
              {(editItem === "doseDetails" || add) && (
                <>
                  <label className="mb-1 default_color">
                    Product Dose Details :
                  </label>
                  <div className="d-flex mb-3">
                    <div className="col-md-6">
                      <label className="mb-1">Dose Form</label>
                      <input
                        type="text"
                        className="form-control"
                        style={{ width: "94%" }}
                        value={doseDetails.doseForm}
                        onChange={(e) =>
                          setdoseDetails({
                            ...doseDetails,
                            doseForm: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="mb-1"> Dose Route</label>
                      <input
                        type="text"
                        className="form-control"
                        value={doseDetails.doseRoute}
                        onChange={(e) =>
                          setdoseDetails({
                            ...doseDetails,
                            doseRoute: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="d-flex mb-3">
                    <div className="col-md-6">
                      <label className="mb-1">Unit dose form size</label>
                      <input
                        type="text"
                        className="form-control"
                        style={{ width: "94%" }}
                        value={medFormData.UDFS}
                        onChange={(e) => handleFormValuesChange("UDFS", e)}
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="mb-1">Unit dose form units</label>
                      <input
                        type="text"
                        className="form-control"
                        value={medFormData.UDFS_UOMCD}
                        onChange={(e) =>
                          handleFormValuesChange("UDFS_UOMCD", e)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="mb-1">Unit dose unit of measure</label>
                    <input
                      type="text"
                      className="form-control"
                      value={medFormData.UNIT_DOSE_UOMCD}
                      onChange={(e) =>
                        handleFormValuesChange("UNIT_DOSE_UOMCD", e)
                      }
                    />
                  </div>
                </>
              )}
              {(editItem === "productExclusions" || add) && (
                <>
                  <div className="d-flex mb-3">
                    <div className="col-md-6">
                      <label>Sugar Free</label>
                      <select
                        className="form-select"
                        value={medFormData.SUG_F}
                        onChange={(e) => handleFormValuesChange("SUG_F", e)}
                        style={{ width: "94%" }}
                      >
                        <option value=""> - </option>
                        <option value="No"> No</option>
                        <option value="Yes"> Yes </option>
                      </select>
                    </div>
                    <div className="col-md-6">
                      <label className="mb-1">Gluten Free</label>
                      <select
                        className="form-select"
                        value={medFormData.GLU_F}
                        onChange={(e) => handleFormValuesChange("GLU_F", e)}
                      >
                        <option value=""> - </option>
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                      </select>
                    </div>
                  </div>
                  <div className="d-flex mb-3">
                    <div className="col-md-6">
                      <label className="mb-1">Preservative Free</label>
                      <select
                        className="form-select"
                        value={medFormData.PRES_F}
                        onChange={(e) => handleFormValuesChange("PRES_F", e)}
                        style={{ width: "94%" }}
                      >
                        <option value=""> - </option>
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                      </select>
                    </div>
                    <div className="col-md-6">
                      <label className="mb-1">CFC Free</label>
                      <select
                        className="form-select"
                        value={medFormData.CFC_F}
                        onChange={(e) => handleFormValuesChange("CFC_F", e)}
                      >
                        <option value=""> - </option>
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                      </select>
                    </div>
                  </div>
                </>
              )}
              {(editItem === "dosageRequirements" || add) && (
                <>
                  <>
                    {/* <div className="col-md-12 mb-3">
                      <label className="mb-1">Medicine Type</label>
                      <div className="d-flex">
                        <ul className="type_icon">
                          <li
                            onClick={() => {
                              setmedType("tablet");
                            }}
                          >
                            <img
                              style={
                                medType === "tablet"
                                  ? medIconColor
                                  : { filter: "none" }
                              }
                              src="../../../images/sdf1.svg"
                            />
                          </li>
                          <li
                            onClick={() => {
                              setmedType("capsule");
                            }}
                          >
                            <img
                              style={
                                medType === "capsule"
                                  ? medIconColor
                                  : { filter: "none" }
                              }
                              src="../../../images/sdf2.svg"
                            />
                          </li>
                          <li
                            onClick={() => {
                              setmedType("liquid");
                            }}
                          >
                            <img
                              style={
                                medType === "liquid" || medType === "ml"
                                  ? medIconColor
                                  : { filter: "none" }
                              }
                              src="../../../images/sdf3.svg"
                            />
                          </li>
                        </ul>
                      </div>
                    </div> */}
                  </>
                  <div className="d-flex mb-2">
                    <div className="col-md-4 d-flex">
                      <label className="me-4 default_color">PRN</label>
                      <select
                        className="ms-2 form-select"
                        value={rowDataDetail?.prn}
                        // onChange={(e) => handleFormValuesChange("prn", e)}
                        // disabled={rowDataDetail?.prn === 'Yes'}
                        disabled
                      >
                        <option value="No"> No </option>
                        <option value="Yes"> Yes </option>
                      </select>
                    </div>
                  </div>
                  <div className="d-flex mb-3">
                    <div className="col-md-6">
                      <label className="mb-1">Dose Form Size</label>
                      <div className="d-flex">
                        <input
                          type="text"
                          className="form-control me-2"
                          value={medFormData.doseAmount}
                          onChange={(e) =>
                            handleFormValuesChange("doseAmount", e)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label className="mb-1">Dose Unit of Measure: </label>
                      <div className="d-flex">
                        <input
                          type="text"
                          className="form-control me-2"
                          value={medFormData.strengthUnit}
                          onChange={(e) =>
                            handleFormValuesChange("strengthUnit", e)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  {rowDataDetail?.prn === 'No' &&
                    <div className="col-md-12 mb-3">
                      <label className="mb-1">Duration of Course</label>
                      <div className="d-flex">
                        <input
                          type="text"
                          className="form-control"
                          value={durationofCourse.inputVal}
                          onChange={(e) =>
                            setdurationofCourse({
                              ...durationofCourse,
                              inputVal: e.target.value,
                            })
                          }
                        />
                        <select
                          className="form-select ms-2"
                          onChange={(e) =>
                            setdurationofCourse({
                              ...durationofCourse,
                              selectType: e.target.value,
                            })
                          }
                        >
                          <option value="Days">Days</option>
                          <option value="Weeks">Weeks</option>
                          <option value="Months">Months</option>
                        </select>
                      </div>
                    </div>
                  }
                  {!isStartDateEditable &&
                    <span className='error_style'>
                      It is not possible to change the medicine schedule when the start date has passed. However, you can delete the medicine which will also delete all its schedule entries which are not completed, omitted or overdue
                    </span>}
                  <div className="col-md-12 mb-3">
                    <label className="mb-1">Start Date</label>
                    <div className="d-flex">
                      <input
                        type="date"
                        className="form-control"
                        min={disablePastDate()}
                        value={medFormData?.startDate}
                        disabled={!isStartDateEditable}
                        onChange={(e) =>
                          handleFormValuesChange("startDate", e)
                        }
                      />
                    </div>
                  </div>
                  {rowDataDetail?.prn === 'No' && <>
                    <div className="col-md-12 mb-3">
                      <label className="mb-1">Frequency</label>
                      <div className="d-flex">
                        <select
                          className="form-select"
                          value={frequency}
                          disabled={!isStartDateEditable}
                          onChange={(e) => setFrequency(e.target.value)}
                        >
                          <option value="Daily">Daily</option>
                          <option value="Weekly">Weekly</option>
                          <option value="Monthly">Monthly</option>
                        </select>
                      </div>
                    </div>
                    {frequency === "Weekly" && (
                      <div className="col-md-12 mb-3">
                        <label className="mb-1">Week Days</label>
                        <Select
                          isMulti
                          value={selectedweekDays}
                          options={weekDays}
                          onChange={handleWeekDaysChange}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          disabled={!isStartDateEditable}
                          getSearchLabel={(e) => (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <span style={{ marginLeft: 5 }}>{e.label}</span>
                            </div>
                          )}
                        />
                        {error.includes("selectedWeekDays") && (
                          <span style={{ color: "red", fontSize: "13px" }}>
                            This field is required
                          </span>
                        )}
                      </div>
                    )}
                    {frequency === "Monthly" && (
                      <>
                        <div className="col-md-12 mb-2">
                          <label className="mb-1">Add specific dates</label>
                          <div className="d-flex">
                            <input
                              type="date"
                              className="form-control"
                              value={dateField}
                              disabled={!isStartDateEditable}
                              onChange={(e) => setDateField(e.target.value)}
                            />
                            <button
                              className="ms-1 btn theme_btn"
                              onClick={(e) => handleDateChange(e, dateField)}
                            >
                              +
                            </button>
                          </div>
                        </div>
                        {error === "selectedDates" && (
                          <span
                            style={{ color: "red", fontSize: "13px" }}
                          >
                            This field is required
                          </span>
                        )}
                        {selectedDates?.map((item, index) => {
                          return (
                            <div key={index} className="col-md-12">
                              <div className="pe-5 mb-3 position-relative d-flex general">
                                <input
                                  className="form-control"
                                  type="date"
                                  value={item}
                                  disabled
                                />
                                <button
                                  className="removeBtn"
                                  style={{ top: "3px" }}
                                  disabled={!isStartDateEditable}
                                  onClick={(e) => {
                                    dateRemoveChangeHandler(e, index);
                                  }}
                                >
                                  -
                                </button>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    )}
                    <div className="col-md-12">
                      <label className="mb-1">Schedule</label>
                      <div className="d-flex">
                        <div className="col-5">
                          <label>Start Time</label>
                          <select
                            className="form-select custom_timeInput"
                            value={startTimeField}
                            disabled={!isStartDateEditable}
                            onChange={(e) => setStartTimeField(e.target.value)}
                          >
                            {timeValues.map((item) => {
                              return (
                                <option key={item.value} value={item.value}>
                                  {item.label}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="col-7">
                          <label className="ms-3">End Time</label>
                          <div className="d-flex">
                            <select
                              className="form-select custom_timeInput ms-3"
                              value={endTimeField}
                              disabled={!isStartDateEditable}
                              onChange={(e) => setEndTimeField(e.target.value)}
                            >
                              {timeValues
                                .filter((item) => item.value >= startTimeField)
                                .map((item) => {
                                  return (
                                    <option key={item.value} value={item.value}>
                                      {item.label}
                                    </option>
                                  );
                                })}
                            </select>
                            <button
                              className="ms-1 btn theme_btn"
                              onClick={(e) =>
                                handleTimeChange(
                                  e,
                                  startTimeField,
                                  endTimeField
                                )
                              }
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                      {error.includes("schedule") && (
                        <span style={{ color: "red", fontSize: "13px" }}>
                          This field is required
                        </span>
                      )}
                    </div>

                    {selectedTimes?.map((item, index) => {
                      return (
                        <div key={index} className="col-md-12 mt-2">
                          <div className="pe-5 mb-3 position-relative d-flex general">
                            <input
                              className="form-control"
                              type="time"
                              value={item?.starttime}
                              disabled
                            />
                            <input
                              className="form-control ms-3"
                              type="time"
                              value={item?.endtime}
                              disabled
                            />
                            <button
                              className="removeBtn"
                              style={{ top: "3px" }}
                              disabled={!isStartDateEditable}
                              onClick={(e) => {
                                timeRemoveChangeHandler(e, index);
                              }}
                            >
                              -
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </>}
                  <div className="col-md-12 mb-3">
                    <label className="mb-1">Meal Consideration</label>
                    <div className="d-flex">
                      <select
                        className="form-select"
                        value={medFormData.mealConsideration}
                        onChange={(e) =>
                          handleFormValuesChange("mealConsideration", e)
                        }
                      >
                        <option value="Not Relevant">Not Relevant</option>
                        <option value="After Meal">After Meal</option>
                        <option value="Before Meal">Before Meal</option>
                      </select>
                    </div>
                  </div>
                  {/* <div className="col-md-12 mb-3">
                      <label className="mb-1">Adminstration Route</label>
                      <div className="d-flex">
                        <select
                          className="form-select"
                          onChange={(e) => setadminRoute(e.target.value)}
                        >
                          <option value="Orally">Orally</option>
                        </select>
                      </div>
                    </div> */}
                </>
              )}

              {(editItem === "requirement_inventory" || add) && (
                <>
                  <div className="col-md-12 mb-3">
                    <label className="mb-1">Units (In-Stock)</label>
                    <input
                      type="number"
                      className="form-control me-2"
                      value={medFormData.units}
                      onChange={(e) => handleFormValuesChange("units", e)}
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="mb-1">Reorder Alert Trigger</label>
                    <input
                      type="number"
                      className="form-control me-2"
                      value={medFormData.reorderAlertTrigger}
                      onChange={(e) =>
                        handleFormValuesChange("reorderAlertTrigger", e)
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex">
            {editItem ? (
              <button
                className="btn"
                type="button"
                onClick={() => {
                  handleEditMedicine();
                }}
              >
                Save
              </button>
            ) : (
              <button
                className="btn"
                type="button"
                onClick={() => {
                  addMedicine();
                }}
              >
                Save
              </button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
