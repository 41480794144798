import React, { useEffect, useState } from 'react';
import cx from "./shift.module.css";
import { Row, Col } from "react-bootstrap";
import { jobTitleOptions, timeValues } from '../../constants/constants';
import { httpAdmin, limitToTwoDecimal } from '../../Apis/commonApis';
import { currentCareSiteId } from '../../redux-toolkit/reducer/switchSites';
import { useForm } from "react-hook-form";
import { ShowSwal } from '../../utils/alertSwal';
import { combineDateTime, getCurrentTimeZone, getFullName, isDateTimeGreaterThanCurrent } from '../../utils/CommonFunctions';
import LoadingBtn from '../../components/Toggle_Button/LoadingBtn';
import swal from 'sweetalert';

const chatUserImg = '/images/chatUser.svg';

export default function AssignStaff(props) {
    const { assignStaffEvent, getShiftsandtaskNew, groupArr } = props;

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm();

    const initialVals = {
        date: '',
        shiftName: '',
        jobTitle: '',
        criteria: '',
    }
    const [filters, setFilters] = useState(initialVals);
    const [teamList, setTeamList] = useState([]);
    const [selectedStaffId, setSelectedStaffId] = useState('');
    const [selectedStaffData, setSelectedStaffData] = useState([]);
    const [selectedStaffAllData, setSelectedStaffAllData] = useState([]);
    const [shiftDetails, setShiftDetails] = useState([]);
    const [declinedArray, setDeclinedArray] = useState([]);
    const [selectedShiftDetails, setSelectedShiftDetails] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // const intervalId = setInterval(() => {
        //     getAssignedData('toRefresh');
        // }, 5000);
        // return () => clearInterval(intervalId);
    }, []);

    const dateAndTime = combineDateTime(selectedShiftDetails?.Date, selectedShiftDetails?.startingTime, getCurrentTimeZone());
    // const isShiftDatepassed = isDateTimeGreaterThanCurrent(dateAndTime, new Date());

    useEffect(() => {
        if (assignStaffEvent) {
            console.log(assignStaffEvent, 'assignStaffEvent');
            const selectedDate = assignStaffEvent.date.includes('T') ? assignStaffEvent.date.split('T')[0] : assignStaffEvent.date;
            setFilters({ ...filters, date: selectedDate, shiftName: assignStaffEvent.shift })
        }
    }, [assignStaffEvent]);

    useEffect(() => {
        let intervalId;
        if (filters.date !== '' && filters.shiftName !== '') {
            getAvailableCareTeam();
            getAssignedData('setLatest');
            intervalId = setInterval(() => {
                getAssignedData('toRefresh');
            }, 5000);
        }
        return () => clearInterval(intervalId);
    }, [filters]);

    useEffect(() => {
        if (filters.date !== '') {
            getShiftNames();
        }
    }, [filters.date]);

    const getAvailableCareTeam = async () => {
        const startDate = new Date(filters?.date)?.toISOString();
        const data = {
            careSiteId: currentCareSiteId,
            priorityBasedOn: filters?.criteria,
            shiftName: filters?.shiftName,
            jobTitle: filters?.jobTitle,
            shiftDates: [{
                date: startDate
            }],
            startDate: startDate,
            endDate: startDate,
        };
        const res = await httpAdmin("availableCareMember", 'post', data, null);
        if (res.status) {
            let teamdata = [];
            res.data?.forEach(item => {
                teamdata.push({
                    id: item._id,
                    name: getFullName(item),
                    //  `${item.first_Name} ${item.last_Name}`,
                    image: item.image,
                    jobTitle: item.jobTitle ?? '',
                    shiftHours: item.shiftHours ? limitToTwoDecimal(+item.shiftHours) : '0',
                    averagePerformance: item.averagePerformance ? limitToTwoDecimal(+item.averagePerformance) : '0',
                    distance: item.distance ? limitToTwoDecimal(+item.distance) : '0',
                });
            });
            setTeamList(teamdata);
        } else {
            setTeamList([]);
        }
    };

    const getAssignedData = async (actionType) => {
        const res = await httpAdmin(`getShiftConfig?careHomeId=${currentCareSiteId}&shiftDate=${filters.date}&shiftName=${filters.shiftName}`, 'get', null, null);
        if (res.status) {
            // getCareTeamRowData(assignedData ?? []);
            const assignedData = res.data[0]?.shiftData[0]?.assignedCareTeam;
            setSelectedStaffAllData(assignedData);
            if (actionType === 'setLatest') {
                console.log(assignedData, 'assignedData')
                const shiftData = res.data[0]?.shiftData?.[0];
                if (shiftData?.recurringShiftType === 'open') {
                    setValue(`isOpen`, true);
                    setValue(`openShiftStart`, shiftData.openShiftStart);
                    setValue(`openShiftEnd`, shiftData.openShiftEnd);
                    setValue(`openClients`, shiftData.openShiftZone);
                }
                const assignedstaff = assignedData?.map(item => {
                    let group = item.all ? 'all' : groupArr.find(elem => elem.zone === item.zone)?.groupId;
                    setValue(`shiftStart${item.careTeamId?._id}`, item.startTime);
                    setValue(`shiftEnd${item.careTeamId?._id}`, item.endTime);
                    setValue(`clients${item.careTeamId?._id}`, group);
                    let teamStaff = item.careTeamId;
                    return {
                        id: teamStaff._id,
                        name: getFullName(teamStaff),
                        // `${teamStaff.first_Name} ${teamStaff.last_Name}`,
                        image: teamStaff.image,
                        jobTitle: teamStaff.jobTitle ?? '',
                    }
                });
                setSelectedStaffData(assignedstaff);
                setSelectedStaffId(assignedstaff?.[0]?.id ?? '');
            }
        }
    };

    function selectStaff() {
        let originalArray = [...teamList];
        let selectedArray = [...selectedStaffData];
        const selectedStaff = originalArray.find(staff => staff.id === selectedStaffId);
        if (selectedStaff) {
            originalArray.splice(originalArray.indexOf(selectedStaff), 1);
            selectedArray.push(selectedStaff);
        }
        setSelectedStaffData(selectedArray);
        setTeamList(originalArray);
        getAssignedData('toRefresh');
    }

    function removeStaff(staffId) {
        let originalArray = [...teamList];
        let selectedArray = [...selectedStaffData];
        console.log(selectedStaffAllData, staffId, 'getShiftConfig api data and staffId')
        const removedItem = selectedArray.find(staff => staff.id === staffId);
        const removingStaffData = selectedStaffAllData?.find(item => item.careTeamId?._id === staffId);
        console.log(removedItem, 'removedItem-----');
        if (removingStaffData?.checkinDateTime) {
            ShowSwal(false, 'Member cannot be removed after clock-in');
        } else {
            swal({
                title: "Confirmation!",
                text: "Is shift declined by the care team member?",
                buttons: {
                    cancel: "No",
                    succeed: "Yes",
                },
            }).then((result) => {
                if (result) {
                    setDeclinedArray(prev => [...prev, removedItem]);
                }
                selectedArray?.splice(selectedArray.indexOf(removedItem), 1);
                originalArray.push(removedItem);
            });
            setSelectedStaffData(selectedArray);
            setTeamList(originalArray);
        }
        getAssignedData('toRefresh');
    }

    const assignStaffApi = async (formVals) => {
        setLoading(true);
        let teamDataArr = [];
        let userTimezone = getCurrentTimeZone();
        selectedStaffData?.forEach(elem => {
            const clientid = formVals[`clients${elem.id}`];
            const shiftstart = formVals[`shiftStart${elem.id}`];
            const shiftend = formVals[`shiftEnd${elem.id}`];
            const latestDataOfAssignedStaff = selectedStaffAllData?.find(item => item.careTeamId?._id === elem.id) || {};
            const isOldShift = Object.keys(latestDataOfAssignedStaff).length > 0;
            teamDataArr.push({
                innerShiftType: isOldShift ? 'old' : 'new',
                careTeamId: elem.id,
                clientId: clientid,
                all: clientid === 'all',
                timing: shiftstart === '' ? 'within time' : "Specific Time",
                // staffTitle: filters.jobTitle,
                startTime: shiftstart,
                endTime: shiftend,
                // startDateTime: "2024-01-10T07:39:25.369Z",
                // endDateTime: "2024-01-10T07:39:25.369Z",
            });
        });
        declinedArray?.forEach(elem => {
            const clientid = formVals[`clients${elem.id}`];
            const shiftstart = formVals[`shiftStart${elem.id}`];
            const shiftend = formVals[`shiftEnd${elem.id}`];
            teamDataArr.push({
                decline: true,
                careTeamId: elem.id,
                clientId: clientid,
                all: clientid === 'all',
                timing: shiftstart === '' ? 'within time' : "Specific Time",
                startTime: shiftstart,
                endTime: shiftend,
            });
        });
        let assignData = {
            careHomeId: currentCareSiteId,
            shiftName: filters.shiftName,
            shiftDate: filters.date,
            timeZone: userTimezone,
            recurringShiftType: formVals?.isOpen ? 'open' : 'assigned',
            assignedCareTeam: teamDataArr,
        };
        if (formVals?.isOpen) {
            assignData.openShiftStart = formVals.openShiftStart ?? selectedShiftNameObj?.startingTime;
            assignData.openShiftEnd = formVals.openShiftEnd ?? selectedShiftNameObj?.endingTime;
            assignData.openShiftZone = formVals.openClients ?? 'all';
        }
        const res = await httpAdmin(`editShiftConfig`, 'post', assignData, null);
        if (res.status) {
            getShiftsandtaskNew('createOrEdit');
        }
        setLoading(false);
        ShowSwal(res.status, res.message);
    }

    const getShiftNames = async () => {
        const res = await httpAdmin(`getShiftNames?careHomeId=${currentCareSiteId}&date=${filters.date}`, 'get', null, null);
        if (res.status) {
            setShiftDetails(res.data);
            const selectedShiftData = res.data?.find(item => item.shiftName === assignStaffEvent.shift);
            setSelectedShiftDetails(selectedShiftData);
        }
    };

    function handleShiftNameChange(e) {
        const selectedShiftData = shiftDetails?.find(item => item.shiftName === e.target.value);
        setSelectedShiftDetails(selectedShiftData);
        setFilters({ ...filters, shiftName: e.target.value });
    }

    let selectedShiftNameObj = shiftDetails?.find(item => item.shiftName === filters.shiftName);

    return (
        <div className={`${cx.dayFilterBox}`}>
            <Col md={10}>
                <Row className="align-items-center">
                    <Col md={1} className="mb-3">
                        <div>Filter:</div>
                    </Col>
                    <Col md={11}>
                        <Row>
                            <Col md={3} className="mb-3">
                                <div className={`${cx.configur_staff_select}`}>
                                    <input type="date" className={`form-control ${filters.date === "" ? cx.borderRed : ''}`}
                                        value={filters.date}
                                        onChange={(e) => setFilters({ ...filters, date: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col md={3} className="mb-3">
                                <div className={`${cx.configur_staff_select}`}>
                                    <select
                                        className={`form-select ${filters.shiftName === "" ? cx.borderRed : ''}`}
                                        aria-label="Default select example"
                                        value={filters.shiftName}
                                        onChange={handleShiftNameChange}
                                    >
                                        <option value='' >Shift Name</option>
                                        {shiftDetails?.length === 0 && <option value='' disabled >No shift available for selected date</option>}
                                        {shiftDetails?.map((item, index) => {
                                            return (<option key={index} value={item.shiftName}>{item.shiftName}</option>);
                                        })}
                                    </select>
                                    <p className='error_style'></p>
                                </div>
                            </Col>
                            <Col md={3} className="mb-3">
                                <div className={`${cx.configur_staff_select}`}>
                                    <select
                                        className="form-select"
                                        aria-label="Default select example"
                                        value={filters.jobTitle}
                                        onChange={(e) => setFilters({ ...filters, jobTitle: e.target.value })}
                                    >
                                        <option value=''>Job Title</option>
                                        <option value='all'>All</option>
                                        {jobTitleOptions.map((item) => {
                                            return (<option key={item.value} value={item.value}>{item.label}</option>);
                                        })}
                                    </select>
                                </div>
                            </Col>
                            <Col md={3} className="mb-3">
                                <div className={`${cx.configur_staff_select}`}>
                                    <select
                                        className="form-select"
                                        aria-label="Default select example"
                                        value={filters.criteria}
                                        onChange={(e) => setFilters({ ...filters, criteria: e.target.value })}
                                    >
                                        <option value=''>Criteria</option>
                                        <option value="weeklyHoursAssigned">Assigned shifts Hrs</option>
                                        <option value="distance">Distance</option>
                                        <option value="performance">Performance</option>
                                    </select>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col md={11}>
                <Row className="mt-4">
                    <Col md={4}>
                        <div className={`${cx.personDetail_main}`}>
                            {filters.date !== '' && <>
                                <div className={`${cx.personDetail_box}`}>
                                    <input
                                        style={{ width: "40px", height: '20px' }}
                                        type="checkbox"
                                        {...register(`isOpen`, {
                                            required: false,
                                        })}
                                    />
                                    <p className="mb-0">Open Shift</p>
                                </div>
                                {watch('isOpen', false) && <><div className="d-flex mb-2">
                                    <select
                                        className="form-select ms-2 me-1"
                                        style={{ fontSize: "12px" }}
                                        disabled={!watch('isOpen', true)}
                                        aria-label="Default select example"
                                        {...register(`openShiftStart`, {
                                            required: false,
                                        })}
                                    >
                                        <option value=''>Shift Start(default)</option>
                                        {timeValues.filter(item => (item.value >= selectedShiftNameObj?.startingTime && item.value <= selectedShiftNameObj?.endingTime)).map((item) => {
                                            return (
                                                <option key={item.value} value={item.value}>
                                                    {item.label}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    <select
                                        className={`form-select mx-1`}
                                        style={{ fontSize: "12px" }}
                                        disabled={!watch('isOpen', true)}
                                        aria-label="Default select example"
                                        {...register(`openShiftEnd`, {
                                            required: watch(`openShiftStart`, '') !== '',
                                        })}
                                    >
                                        <option value=''>Shift End(default)</option>
                                        {timeValues.filter(item => (item.value >= selectedShiftNameObj?.startingTime && item.value <= selectedShiftNameObj?.endingTime)).map((item) => {
                                            return (
                                                <option key={item.value} value={item.value}>
                                                    {item.label}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                                    <div className="d-flex mb-2">
                                        <select
                                            className="form-select ms-2 me-1"
                                            style={{ fontSize: "12px" }}
                                            aria-label="Default select example"
                                            {...register(`openClients`, {
                                                required: false,
                                            })}
                                        >
                                            <option value="all">All Client</option>
                                            {groupArr?.map((elem) => {
                                                return (
                                                    <option key={elem.groupId} value={elem.groupId}>
                                                        {elem.zone}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </>}
                            </>}
                            {teamList.length < 1 && <p className='ms-3'>No team available</p>}
                            {teamList?.map((item, index) => {
                                const imageIcon = item?.image ?? chatUserImg;
                                return (
                                    <div key={index} className={`${cx.personDetail_box} ${cx.hoverStaff}`}
                                        onClick={() => setSelectedStaffId(item.id)}
                                    >
                                        <div className={`${cx.personImg}`}>
                                            <img src={imageIcon} className={imageIcon.includes('chatUser') ? `forImagecareteam` : ''} alt="img" />
                                        </div>
                                        <div className={`${cx.personDetails} ${item.id === selectedStaffId ? cx.selectedStaff : ''}`}>
                                            <p>{item.name} (<span>{item.jobTitle}</span>)</p>
                                            <span>Criteria {filters.criteria ?? '-'}
                                                {filters.criteria === 'distance' ? `(${item.distance}m)` : ''}
                                                {filters.criteria === 'weeklyHoursAssigned' ? `(${item.shiftHours}Hrs)` : ''}
                                                {filters.criteria === 'performance' ? `(${item.averagePerformance}%)` : ''}
                                            </span>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </Col>
                    {selectedStaffId && <Col md={2} className='my-auto'>
                        <div className={`${cx.rightShiftZone_main}`}>
                            <div className={`${cx.greaterBtn}`}>
                                <button onClick={selectStaff}>
                                    <img
                                        src="/images/greater-sign.svg"
                                        alt="img"
                                    />
                                </button>
                            </div>
                        </div>
                    </Col>}
                    <Col md={6}>
                        {selectedStaffData?.map((item, index) => {
                            const removingStaffData = selectedStaffAllData?.find(elem => elem.careTeamId?._id === item?.id) || {};
                            const isDisable = Object.keys(removingStaffData)?.length > 0;
                            const imageIcon = item.image ?? chatUserImg;
                            return (
                                <div key={index} className={`${cx.rightShiftZone_main}`}>
                                    <Row>
                                        <Col md={6}>
                                            <div className={`${cx.personDetail_main}`}>
                                                <div className={`${cx.personDetail_box}`}>
                                                    <div className={`${cx.personImg}`}>
                                                        <img src={imageIcon} className={imageIcon.includes('chatUser') ? `forImagecareteam` : ''} alt="img" />
                                                    </div>
                                                    <div className={`${cx.personDetails}`}>
                                                        <p>{item.name} (<span>{item.jobTitle}</span>)</p>
                                                        {/* <span>Criteria Metric</span> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col md={5}>
                                            <div className={`${cx.rightZoneSelect}`}>
                                                <select
                                                    className="form-select mb-1"
                                                    aria-label="Default select example"
                                                    {...register(`shiftStart${item.id}`, {
                                                        required: false,
                                                    })}
                                                    disabled={isDisable}
                                                >
                                                    <option value=''>Shift Start(default)</option>
                                                    {timeValues.filter(elem => (elem.value >= selectedShiftNameObj?.startingTime && elem.value <= selectedShiftNameObj?.endingTime)).map((timeObj) => {
                                                        return (
                                                            <option key={timeObj.value} value={timeObj.value}>
                                                                {timeObj.label}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                            <div className={`${cx.rightZoneSelect}`}>
                                                <select
                                                    className={`form-select mb-1 ${errors[`shiftEnd${item.id}`]?.type === "required" ? cx.borderRed : ''}`}
                                                    aria-label="Default select example"
                                                    {...register(`shiftEnd${item.id}`, {
                                                        required: watch(`shiftStart${item.id}`, '') !== '',
                                                    })}
                                                    disabled={isDisable}
                                                >
                                                    <option value=''>Shift End(default)</option>
                                                    {timeValues.filter(elem => (elem.value >= selectedShiftNameObj?.startingTime && elem.value <= selectedShiftNameObj?.endingTime)).map((timeObj) => {
                                                        return (
                                                            <option key={timeObj.value} value={timeObj.value}>
                                                                {timeObj.label}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                            <div className={`${cx.rightZoneSelect}`}>
                                                <select
                                                    className="form-select mb-2"
                                                    aria-label="Default select example"
                                                    {...register(`clients${item.id}`, {
                                                        required: false,
                                                    })}
                                                    disabled={isDisable}
                                                >
                                                    <option value="all">All Client</option>
                                                    {groupArr?.map((elem) => {
                                                        return (
                                                            <option key={elem.groupId} value={elem.groupId}>
                                                                {elem.zone}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </Col>
                                        <Col md={1}>
                                            <div>
                                                <button className="border-0 bg-transparent" onClick={() => removeStaff(item.id)}>
                                                    X
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            )
                        })}
                    </Col>
                </Row>
            </Col >
            <Col md={11}>
                {/* (selectedStaffData.length > 0 || watch('isOpen', '')) && */}
                {<div className={`${cx.saveBtn} text-end`}>
                    <button disabled={loading} onClick={handleSubmit(assignStaffApi)} style={{ width: '80px' }}>
                        {loading ? <LoadingBtn /> : 'Save'}</button>
                </div>}
            </Col>
        </div >
    )
}
