import { Switch, Route, Link, useRouteMatch, useLocation } from "react-router-dom";
import CommonProfilepages from "../Profile/CommonProfilepages";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { currentCareSiteId } from "../../redux-toolkit/reducer/switchSites";

export default function ProfileRoutes() {
  const location = useLocation();
  let { path, url } = useRouteMatch();

  let { careSitesData } = useSelector((state) => state.caresiteDataReducer);

  const [currentSiteData, setCurrentSiteData] = useState(null);

  useEffect(() => {
    if (careSitesData) {
      let currentsite = careSitesData?.find(item => item?._id === currentCareSiteId);
      setCurrentSiteData(currentsite);
    }
  }, [careSitesData]);

  return (
    <div className="page-wrapper">
      <div className="card">
        <div className="card-body">
          <div className="top_menubar">
            <ul
              className="nav nav-tabs ract_tab_list border-0 listspaceGap"
              id="myTab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button className="nav-link settingsColor">
                  Account Hub {">"}
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <Link
                  to={`${url}/details`}
                  className={`nav-link ${window.location.href.includes("details") ? "active" : ""
                    }`}
                >
                  Profile
                </Link>
              </li>
              <li className={`nav-item`} role="presentation">
                <Link
                  to={`${url}/subscription`}
                  className={`nav-link ${window.location.href.includes("subscription")
                    ? "active"
                    : ""
                    }`}
                >
                  Subscription
                </Link>
              </li>

              <li className="nav-item" role="presentation">
                <Link
                  to={`${url}/billing`}
                  className={`nav-link ${window.location.href.includes("billing") ? "active" : ""
                    }`}
                >
                  Billing
                </Link>
              </li>
              {location?.pathname?.includes('update-billing-method') && <Link
                to={`${url}/billing`}
                className="billing_back"
              > Back
              </Link>}
              {/* <li className="nav-item" role="presentation">
                <span
                  className="nav-link"
                  onClick={() => {
                    localStorage.removeItem("care_admin_token");
                    localStorage.removeItem("companyName");
                    localStorage.removeItem("carehomeId");
                    localStorage.removeItem("currentCareSiteId");
                    localStorage.removeItem("carehomeName");
                    localStorage.removeItem("userData");
                    localStorage.removeItem("carefacility");
                    history.replace("/login");
                  }}
                >
                  Logout
                </span>
              </li> */}
            </ul>
          </div>

          <Switch>
            <Route path={`${path}/success/:topicId/:ownerId/:csid`}>
              <CommonProfilepages />
            </Route>
            <Route path={`${path}/:topicId`}>
              <CommonProfilepages />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}
