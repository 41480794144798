import React, { useEffect, useState } from "react";
import cx from "./Portal.module.css";
import { Row, Col,Button } from "react-bootstrap";
import { NavLink } from 'react-router-dom';
export default function Feedback() {

 
  return (
    <div className={`${cx.profilePage} ${cx.displayHeight}`}>
      <div className={`container`}>
        <Row>
          <Col lg={4} md={6} sm={12} className={`m-auto`}>
            <div className={`${cx.ProfileBox} ${cx.FeedBackBox}`}>
              <div className={`${cx.topHeadeing}`}>
                  <h3>Feedback</h3>
                  <div className={`${cx.backIcon}`}>
                  <div className={`${cx.backIcon}`}>
                  <NavLink to="/portal/portal-main"><img src="/images/BackVector.png" alt=""/></NavLink>
                  </div>
                  </div>
              </div>
              <div className={`${cx.feedbackText}`}>
                <p>We are constantly looking at improving our app and value your input.</p>
                <p>For any suggestions or feedback please contact us at <NavLink to="#"> support@caremagnus.com</NavLink>.</p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}
