import React, { useState, useEffect } from "react";
import "../CareTeam/careteam.css";
import { Switch, Route, Link, useRouteMatch, useLocation, useHistory } from "react-router-dom";
import CareteamRoutes from "./CareteamRoutes";

function CareteamDetails() {

  const history = useHistory();
  const location = useLocation();
  const careteamId = location.state?.careteamId;
  let { path, url } = useRouteMatch();

  const handleNavigate = (type) => {
    history.replace({
      pathname: `${url}/${type}`,
      state: { careteamId: careteamId },
    });
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="container-fluid min_height">
          <div className="card">
            <div className="card-body">
              <div className="top_menubar">
                <ul
                  className="nav nav-tabs ract_tab_list border-0 listspaceGap"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <p
                      onClick={() => handleNavigate('profile')}
                      className={`nav-link curser_point ${window.location.href.includes("profile") ? "active" : ""
                        }`}
                    >
                      Profile
                    </p>
                  </li>

                  <li className="nav-item" role="presentation">
                    <p
                      onClick={() => handleNavigate('docs')}
                      className={`nav-link curser_point ${window.location.href.includes("docs") ? "active" : ""
                        }`}
                    >
                      Docs
                    </p>
                  </li>

                  <li className={`nav-item`} role="presentation">
                    <p
                      onClick={() => handleNavigate('trainings')}
                      className={`nav-link curser_point ${window.location.href.includes("trainings")
                        ? "active"
                        : ""
                        }`}
                    >
                      Trainings
                    </p>
                  </li>

                  <li className="nav-item" role="presentation">
                    <p
                      onClick={() => handleNavigate('journal')}
                      className={`nav-link curser_point ${window.location.href.includes("journal") ? "active" : ""
                        }`}
                    >
                      Journal
                    </p>
                  </li>
                </ul>
              </div>
              <div className="tab-content mt-3" id="myTabContent">
                <Switch>
                  <Route path={`${path}/:topicId`}>
                    <CareteamRoutes />
                  </Route>
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CareteamDetails;
