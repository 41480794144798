import React, { useState, useEffect } from "react";
import "../Medicines/medicines.css";
import { BsSearch } from "react-icons/bs";
import { Switch, Route, useRouteMatch, useLocation, useHistory } from "react-router-dom";
import MedicineRoutes from "./MedicineRoutes";

function AddMedicines() {

  const history = useHistory();
  const location = useLocation();
  let { path, url } = useRouteMatch();

  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    let pathname = window?.location?.pathname;
    if (pathname?.includes('medicines') || pathname?.includes('schedule') || pathname?.includes('medicine-detail')) {
    } else {
      handleNavigate('schedule');
    }
  }, [location.pathname]);

  const handleNavigate = (type) => {
    history.replace({
      pathname: `${url}/${type}`,
    });
  };

  const locHref = window.location.href;

  return (
    <>
      <div className="page-wrapper position-relative">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-4">
                  <ul
                    className="nav nav-tabs ract_tab_list border-0 listspaceGap"
                    id="myTab"
                    role="tablist"
                  >
                    <li className="nav-item me-3" role="presentation">
                      <p
                        onClick={() => handleNavigate('schedule')}
                        className={`nav-link curser_point ${locHref.includes("schedule") ? "active" : ""
                          }`}
                      >
                        Schedule
                      </p>
                    </li>

                    <li className="nav-item" role="presentation">
                      <p
                        onClick={() => handleNavigate('medicines')}
                        className={`nav-link curser_point ${(locHref.includes("medicines") || locHref.includes("medicine-detail")) ? "active" : ""
                          }`}
                      >
                        Medicines
                      </p>
                    </li>

                  </ul>
                </div>
                {!location.pathname?.includes('medicine-detail') && <div className="col-md-3 searchForm" style={{ marginLeft: "0px", transform: 'translate(-6%, 0%)' }}>
                  <input
                    type="text"
                    className="form-control"
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    placeholder="search"
                  />
                  <BsSearch className="icon me-2" />
                </div>}
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid" style={{height: '79vh', overflowY: 'auto'}}>
          <div className="card">
            <div className="card-body">

              <div className="tab-content mt-3" id="myTabContent">
                <Switch>
                  <Route path={`${path}/:topicId`}>
                    <MedicineRoutes
                      searchText={searchText} />
                  </Route>
                </Switch>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddMedicines;
