import React, { useEffect, useState } from "react";
import { httpSuperAdmin } from "../../Apis/commonApis";
import "../AllActivity/AllActivity.css";
import DataGridTable from "../../utils/DataGridTable";
import { formatDate } from "../../utils/CommonFunctions";

function Event({ careSite }) {

  const [rows, setRows] = useState([]);
  const [stripeEvents, setStripeEvents] = useState([]);
  const [goCardEvents, setGoCardEvents] = useState([]);
  const [manualEvents, setManualEvents] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (careSite) {
      getManualEvents();
      getGoCardEvents();
      getStripeEvents();
    }
  }, [careSite]);

  useEffect(() => {
    let newArray = [];
    newArray = [...stripeEvents, ...goCardEvents, ...manualEvents];
    setRows(newArray);
  }, [stripeEvents, goCardEvents, manualEvents]);

  const getStripeEvents = async () => {
    setLoading(true);
    const result = await httpSuperAdmin(`listAllEvents?subscriptionId=${careSite?.subscriptionId}`, null);
    if (result.status) {
      let eventData = result?.data?.data;
      let stripeRowData = [];
      eventData?.forEach((elem, i) => {
        stripeRowData.push({
          id: elem.id,
          eventType: elem.type,
          time: formatDate(elem.created * 1000),
          eventResult: elem.data.object.status,
          ip: "-",
          location: `-`,
          os: "-",
          browser: "-",
          source: "Stripe",
          eventId: `ST_1001SUB100${i + 1}`,
          uid: elem.doerId?._id ?? "-",
          userName: elem.doerId?.userName ?? "-",
          subId: elem.data?.object?.subscription,
        });
      });
      setStripeEvents(stripeRowData);
    }
    setLoading(false);
  };

  const getGoCardEvents = async () => {
    const result = await httpSuperAdmin(`gocardLessEvents?subscriptionId=${careSite?.subscriptionId}`, null);
    if (result.status) {
      let goCardEvents = result.data?.data;
      let rowData = [];
      goCardEvents?.forEach((elem, i) => {
        rowData.push({
          id: elem.id,
          eventType: elem.action,
          time: formatDate(elem.created_at),
          ip: "-",
          location: `-`,
          os: "-",
          browser: "-",
          source: "GoCardless",
          eventId: `GO_1001SUB100${i + 1}`,
          uid: elem.doerId?.id ?? "-",
          eventResult: "Success",
          userName: elem.doerId?.userName ?? "-",
          subId: elem.links?.subscription,
        });
      });
      setGoCardEvents(rowData);
    }
  };

  const getManualEvents = async () => {
    const result = await httpSuperAdmin(`getManualEvents?careHomeId=${careSite?.careSiteId}`, null);
    if (result.status) {
      let eventData = result.data;
      let rowData = [];
      eventData?.forEach((elem, i) => {
        let loc = elem.doerLocation;
        rowData.push({
          id: elem._id,
          eventType: elem.eventType,
          time: formatDate(elem.createdAt),
          uid: elem.doerId?._id ?? "deleted user",
          ip: elem.doerIp,
          location: `${loc.lat}, ${loc.long}`,
          os: elem.userAgent?.os?.name,
          browser: elem.userAgent?.browser?.name,
          eventId: elem.eventId,
          userName: getFullName(elem.doerId),
          eventResult: "Success",
          source: elem.source,
          subId: elem.action === null ? elem.careSiteId : "",
        });
      });
      setManualEvents(rowData);
    }
  };

  function getFullName(dataObj) {
    return dataObj
      ? `${dataObj?.firstName} ${dataObj?.lastName}`
      : "deleted user";
  }

  const columns = [
    { field: "time", headerName: "Time Stamp", minWidth: 90 },
    { field: "id", headerName: "Ext. Event ID", minWidth: 120 },
    { field: "uid", headerName: "User ID", minWidth: 120 },
    { field: "userName", headerName: "User Name", minWidth: 120 },
    { field: "ip", headerName: "IP Address", flex: 1, minWidth: 120 },
    { field: "os", headerName: "OS Type", minWidth: 90 },
    { field: "browser", headerName: "Browser Type", minWidth: 120 },
    { field: "location", headerName: "location", flex: 1, minWidth: 120 },
    // { field: "eventId", headerName: "Event ID", minWidth: 120 },
    { field: "eventType", headerName: "EventType", flex: 1, minWidth: 120 },
    { field: "eventResult", headerName: "Event Result", minWidth: 90 },
    { field: "source", headerName: "Source", minWidth: 90 },
  ];

  return (
    <DataGridTable
      columns={columns}
      rows={rows}
      handleRowSelection={() => {}}
      tableHeight="72vh"
      loading={loading}
    />
  );
}

export default Event;
