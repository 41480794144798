import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetch3, httpCareMagnus } from "../../Apis/commonApis";

// Include api for currency change
const api = "https://api.exchangerate-api.com/v4/latest/GBP";
let count = 0;

let initialExchanges = {
  GBP: 1,
  USD: 1.27,
  CAD: 1.71,
  EUR: 1.17
}

const initialState = {
  currency: "GBP",
  convertCurrencyData: null,
  selectedCurrency: null,
  taxesAndCurrencyData: null,
  exchangeRates: initialExchanges,
  seletedWebsiteCount: 0,
  seletedWebsitePlanId: "",
  seletedWebsitePlanName: "",
  seletedWebsiteStatus: false,
  seletedWebsiteYear: false,
  websitedatarefresh: 0,
  userIpData: null
};

export const CurrencyConversion = createAsyncThunk(
  "currencyConversion",
  async () => {
    const currencyData = await fetch(`${api}`);
    const data = await currencyData.json();
    return data;
  }
);

export const getTaxesAndCurrency = createAsyncThunk(
  "taxesAndCurrency",
  async () => {
    const currencyData = await httpCareMagnus('getTaxesAndCurrency');
    return currencyData;
  }
);

export const fetchUserIpData = createAsyncThunk(
  "fetchUserIpData",
  async () => {
    const res = await fetch(`https://ipinfo.io/json`);
    return res?.json();
  }
);

const websitePlans = createSlice({
  name: "websitePlans",
  initialState,
  reducers: {
    changeCurrency(state = initialState, action) {
      state.currency = action.payload.currency;
      state.selectedCurrency = action.payload.currency;
      count = 0;
    },
    collectSelectedData(state = initialState, action) {
      state.seletedWebsiteCount = action.payload.count;
      state.seletedWebsitePlanId = action.payload.planId;
      state.seletedWebsitePlanName = action.payload.planName;
      state.currency = action.payload.currency;
      state.seletedWebsiteStatus =
        action.payload.status === "false" ? false : true;
      state.seletedWebsiteYear = action.payload.year === "false" ? false : true;
    },
    websiteRefreshHandler(state = initialState, action) {
      state.websitedatarefresh = state.websitedatarefresh + 1;
    },
  },
  extraReducers: {
    [CurrencyConversion.fulfilled]: (state, { payload }) => {
      state.convertCurrencyData = payload;
      if (payload?.rates) state.exchangeRates = payload.rates;
    },
    [getTaxesAndCurrency.fulfilled]: (state, { payload }) => {
      if (payload.data) {
        // state.selectedCurrency = payload.data?.currencies?.[0];
        state.taxesAndCurrencyData = payload.data;
      }
    },
    [fetchUserIpData.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.userIpData = payload;
      }
    },
  },
});

export const { changeCurrency, collectSelectedData, websiteRefreshHandler } =
  websitePlans.actions;
export default websitePlans.reducer;
