import React from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

// const data = [
//   { name: "Page A", uv: 0, pv: 0, amt: 20 },
//   { name: "Page B", uv: 50, pv: 600, amt: 240 },
//   { name: "Page C", uv: 200, pv: 1100, amt: 250 },
//   { name: "Page D", uv: 300, pv: 1500, amt: 300 },
//   { name: "Page E", uv: 700, pv: 2100, amt: 500 },
//   { name: "Page F", uv: 800, pv: 2400, amt: 500 },
// ];

const tickValues = [1, 2, 3, 4, 5, 6];
const strokeColors = ["#c580d1", "#eb5757", "#2f80ed", "#8A2BE2", "#DEB887"];

export default function Chart(props) {
  const { chartData, dataKeys, selectedCat, measureUnit } = props;

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const value = payload[0].payload;
      return (
        <div className="recharts-default-tooltip" style={{background: '#ffff', border: '1px solid #ccc', padding: '5px'}}>
          <p className="label">Date: {`${value.name}`}</p>
          {payload.map((item, i) => {
            let multiVals = item.payload[`${item.dataKey}~2`];
            // console.log(item.payload);
            let showVals = (multiVals && multiVals !== '') ? `(${multiVals})` : '';
            return <p key={i} className="label" style={{ color: item.stroke }}>{`${item.dataKey} : ${item.value} ${showVals} ${measureUnit}`}</p>
          })}
        </div>
      );
    }
    return null;
  };

  return (
    <ResponsiveContainer width="100%" height={'60vh'} aspect={3}>
      <LineChart
        width={500}
        height={400}
        data={chartData}
        margin={{ top: 5, right: 20, bottom: 5, left: 30 }}
      >
        {dataKeys.map((item, index) => {
          return (
            <Line
              key={index}
              type="monotone"
              dataKey={item}
              stroke={strokeColors[index]}
              strokeWidth={3}
            />
          );
        })}
        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
        <XAxis dataKey="name" />
        {selectedCat.subheading === "wellbeing" ? (
          <YAxis ticks={tickValues} tick={renderCustomAxisTick} />
        ) : (
          <YAxis tick={<CustomYAxisTick measureUnit={measureUnit} />} />
        )}
        <Tooltip content={<CustomTooltip />} />
      </LineChart>
    </ResponsiveContainer>
  );
}

const CustomYAxisTick = ({ x, y, payload, measureUnit }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text style={{fontSize: "15px"}} x={0} y={0} dy={5} textAnchor="end" fill="#666">
        {payload.value} {measureUnit}
      </text>
    </g>
  );
};

const renderCustomAxisTick = (props) => {
  const icon = `/images/is${props.payload?.value}.svg`; // Adjust the path as needed
  return (
    <g transform={`translate(${props.x},${props.y})`}>
      {icon && (
        <image
          href={icon}
          x={-20}
          y={-12}
          width={24}
          height={24}
        />
      )}
    </g>
  );
};
