import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";
import ScrollRestoration from "react-scroll-restoration";

// --------------------------------------- Admin Components ----------------------------- //

import Login from "./admin/Login/login";
import Dashboard from "./admin/Dashboard/dashboard";
import Clients from "./admin/Clients/clients";
import ClientsDetails from "./admin/Clients/clientsDetails";
import CareTeam from "./admin/CareTeam/careteam";
import CareteamDetails from "./admin/CareTeam/careteamDetails";
import Shift from "./admin/ShiftTask/ShiftTask";
import Invoicing from "./admin/Invoicing/invoicing";
import SalesFunnel from "./admin/SalesFunnel/SalesFunnel";
import AccessRights from "./AccessRights/accessRights";
import Forgotpassword from "./admin/ForgotPassword/forgot-password";
import CreateShiftTask from "./admin/ShiftTask/CreateShiftTask";
import AddMedicines from "./admin/Medicines/addMedicines";
import Report from "./admin/Report/Report";
import Messaging from "./messaging/messaging";
import FromTemplate from "./admin/ShiftTask/FromTemplate";
import GenericShiftcreation from "./admin/ShiftTask/GenericShiftcreation";
import ClientShiftcreation from "./admin/ShiftTask/ClientSideshift";
import Header from "./admin/Header/header";
import Sidebar from "./admin/Sidebar/sidebar";
import Footer from "./admin/Footer/footer";
import UserRoleManagement from "./admin/UserRoleManagement/UserRoleManagement";
import SettingAceessModule from "./admin/UserRoleManagement/settingmoudles";
import ClientMedicinesWeekSchedule from "./admin/Medicines/clientSpeMedicWeekaddMedicines";
// ------------------------- Superadmin Components ------------------------- //

import SuperAdminHeader from "./superadmin/Header/header";
import SuperAdminFooter from "./superadmin/Footer/footer";
import SuperAdminSidebar from "./superadmin/Sidebar/sidebar";
import SuperAdminLogin from "./superadmin/Login/login";
import SuperAdminForgotpassword from "./superadmin/ForgotPassword/forgot-password";
import Analytics from "./superadmin/Analytics/analytics";
import Invoices from "./superadmin/Invoices/invoices";
import GeneralPlans from "./superadmin/SubscriptionPlans/generalPlans";
import AllActivity from "./superadmin/AllActivity/AllActivity";
import ContentManagement from "./superadmin/ContentManagement/ContentManagement";
import Support from "./superadmin/Support/Support";
import SuperAdminProfile from "./superadmin/Profile/Profile";
import Page from "./superadmin/detailpage/page";
import Customdatatable from "./components/customtable/customtable";
import ClientsOfSuperadmin from "./superadmin/superadmin-clients/superadmin-client.component";
import SuperClientDetails from "./superadmin/superadminroutes/clientdetails";
import Subscription from "./superadmin/Allsubscription/subscription";

// ------------------------------ Website Components ------------------------------ //

import WebsiteHeader from "./website/Header/header";
import WebsiteFooter from "./website/Footer/footer";
import Home from "./website/Home/home";
import Segments from "./website/Segments/segments";
import Contact from "./website/Contact/contact";
import Privacy from "./website/PrivacyPolicy/privacy";
import Terms from "./website/TermsConditions/terms";
import Features from "./website/Features/features";
import BlogDetails from "./website/Blog/blogDetails";
import Payments from "./superadmin/Payments/payments";
import Reports from "./superadmin/Reports/reports";
import SubscriptionDetails from "./superadmin/Allsubscription/subscriptionDetails";
import EditPlanDetails from "./superadmin/Customtable/EditPlanDetails";
import EditCoupons from "./superadmin/Customtable/EditCoupons";
import EditAddOnDetails from "./superadmin/Customtable/EditAddOnDetails";
import SettingsDetails from "./superadmin/superadminroutes/settingsDetails";
import ProfileRoutes from "./admin/adminRoutes/ProfileRoutes";
import WebsitePlan from "./website/WebsitePlans/WebsitePlans";
import MultiStepSignupForm from "./admin/Signup/MultiStepSignupForm";
import ClientJournalAsApp from "./admin/Clients/ClientJournalAsApp";
import CardlessPaymentSuccess from "./admin/Signup/CardlessPaymentSuccess";
import InvoiceTemplate from "./superadmin/Settings/InvoiceTemplate";
import EmailTemplates from "./superadmin/Settings/EmailTemplates";
import StepFourSubscriptionPlan from "./admin/Signup/StepFourSubscriptionPlan";
// import FinalStepPaymentRedirection from "./admin/Signup/FinalStepPaymentRedirection";
import Payroll from "./admin/Payroll/Payroll";
// import OverviewDetails from "./admin/Payroll/OverviewDetails";
import CommonFeaturePages from "./website/Features/commonFeaturePages";
import VerifyUser from "./admin/verify/verify";

// ------------------------------ Performance Optimization Components ------------------------------ //
import ErrorBoundary from "./components/ErrorBoundary";
import { Suspense } from "react";
import DisaproveInvoice from "./superadmin/Invoices/DisaproveInvoice";
import WageOrDetail from "./admin/Payroll/WageOrDetail";
import SecurityQuestion from "./admin/securityQuestions/security";
import ResetPassword from "./admin/newPassword/newPassword";
import ForgotSecurityQuestion from "./admin/ForgotSecurityQuestion/ForgotSecurityQuestion";
import NewSecurityQuestion from "./admin/securityQuestions/newSecurityQuestion";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  careSitelisting,
  currentCareSiteIdHandler,
  selectCareSite,
} from "./redux-toolkit/reducer/switchSites";
import PredefinedTemplates from "./admin/Clients/CareplanTemplates/PredefinedTemplates";
import PredefinedQuestionAnswer from "./admin/Clients/CareplanTemplates/PredefinedQuestionAnswer";
import About from "./website/Aboutus/about";
import { db } from "./Firebase";
import { onSnapshot, collection } from "firebase/firestore";
import { updateCounter } from "./redux-toolkit/reducer/msgReducer";
import { ProductFruits } from "react-product-fruits";
import { toTitleCase } from "./utils/CommonFunctions";
import { getAccessRoles, getCareSiteListing } from "./redux-toolkit/reducer/caresiteDataReducer";
import PortalLogin from "./FandFamPortal/Authentication/Login";
import PortalHome from "./FandFamPortal/PortalHome";
import PortalMain from "./FandFamPortal/PortalMain";
import PortalChangePassword from "./FandFamPortal/Authentication/ChangePassword";
import ResetPortalPassword from "./FandFamPortal/Authentication/ResetPassword";
import NewPassword from "./FandFamPortal/Authentication/Password";
import CheckSecurityQuestion from "./FandFamPortal/Authentication/SecurtyQuestion";
import userProfile from "./FandFamPortal/UserProfile";
import requestService from "./FandFamPortal/RequestService";
import RequestServiceCalendar from "./FandFamPortal/RequestServiceCalendar";
import Feedback from "./FandFamPortal/Feedback";
import welcomeLogin from "./FandFamPortal/welcomeLogin";
import { getInvoiceData } from "./redux-toolkit/reducer/SuperAdminReducer";
import MultiSites from "./admin/multiSiteDash/MultiSites";
import PortalHeader from "./FandFamPortal/shared/Header";
import PortalFooter from "./FandFamPortal/shared/Footer";

const PrivateRoute = (props) => {
  const care_admin_token = localStorage.getItem("care_admin_token");
  const currentCareSiteId = localStorage.getItem("currentCareSiteId");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();
  const { adminToken } = useSelector((state) => state.switchSites);
  const { adminTokenFromAuth, verifySuccessLogin, loginVerifyEmail, registerSuccess } = useSelector((state) => state.accountCreationApiReducer);

  let userInfo;

  if (userData) {
    let currentSite = userData.subscriptionPlanData.find(item => item._id === currentCareSiteId);
    let subPlan = currentSite?.SubscriptionPlan ? currentSite?.SubscriptionPlan : '';
    const planName = toTitleCase(subPlan);
    const uName = userData?.firstName + ' ' + userData?.lastName;
    const encodedName = customEncode(uName);
    userInfo = {
      username: encodedName,
      role: planName,
    };
  }

  function customEncode(inputString) {
    let encoded = "";
    for (let i = 0; i < inputString.length; i++) {
      const char = inputString.charAt(i);
      encoded += char === " " ? " " : String.fromCharCode(char.charCodeAt(0) + 1);
    }
    return encoded;
  }

  useEffect(() => {
    const currentUserId = userData?._id ?? '';
    const unSub = onSnapshot(
      collection(db, "chatHeads", currentCareSiteId, "chatUsers"),
      (querySnapshot) => {
        console.log('object')
        const headdata = [];
        querySnapshot.forEach((doc) => {
          if (doc.exists()) {
            headdata.push(doc.data());
          }
        });
        let unseenMsgs = 0;
        if (headdata?.length > 0) {
          for (let i = 0; i < headdata.length; i++) {
            const counterData = headdata[i]?.counterMap?.find(c => c.id === currentUserId);
            if (counterData?.counter > 0) {
              unseenMsgs += 1
            }
          }
          dispatch(updateCounter({ count: unseenMsgs }));
        }
      }
    );
    return () => {
      unSub();
    };
  }, []);

  useEffect(() => {
    const admin_token = localStorage.getItem("care_admin_token");
    if (admin_token) {
      dispatch(getCareSiteListing());
      setTimeout(() => {
        dispatch(getCareSiteListing());
      }, 3000);
    }
    if (userData?._id) {
      dispatch(getAccessRoles(userData?._id));
      setTimeout(() => {
        dispatch(getAccessRoles(userData?._id));
      }, 3000);
    }
    handleRequests();
  }, [adminToken, adminTokenFromAuth, verifySuccessLogin, loginVerifyEmail, registerSuccess]);

  async function handleRequests() {
    await dispatch(currentCareSiteIdHandler({ currentCareSiteId }));
    await dispatch(
      careSitelisting({
        id: currentCareSiteId ? currentCareSiteId : userData?.defaultCareSiteId,
      })
    );
    await dispatch(
      selectCareSite({
        id: currentCareSiteId ? currentCareSiteId : userData?.defaultCareSiteId,
      })
    );
  }
  // iRvazMxmeZ9EmHoE
  // aQ3W82M6KmtNgfNT
  return (
    <>
      {care_admin_token ? (
        <>
          <Header />
          <Sidebar />
          <Route {...props} />
          {userInfo && <ProductFruits workspaceCode="aQ3W82M6KmtNgfNT" language="en" user={userInfo} />}
          <Footer />
        </>
      ) : (
        <Redirect
          to={{
            pathname: "/login",
          }}
        />
      )}
    </>
  );
};

const PublicRoute = (props) => {
  const care_admin_token = localStorage.getItem("care_admin_token");

  return care_admin_token ? (
    <Redirect to={{ pathname: "/admin/dashboard" }} />
  ) : (
    <Route {...props} />
  );
};

const PaymentRoute = (props) => {
  let care_admin_token = localStorage.getItem("careAdminToken");
  if (!care_admin_token)
    care_admin_token = localStorage.getItem("care_admin_token");

  return care_admin_token ? (
    <Route {...props} />
  ) : (
    <Redirect to={{ pathname: "/multistepsignupform" }} />
  );
};

const SuperAdminPrivateRoute = (props) => {
  const superadmin_token = localStorage.getItem("superadmin_token");
  const dispatch = useDispatch();

  useEffect(() => {
    if (superadmin_token) {
      dispatch(getInvoiceData());
    }
  }, []);

  return (
    <>
      {superadmin_token ? (
        <>
          <SuperAdminHeader />
          <SuperAdminSidebar />
          <Route {...props} />
          <SuperAdminFooter />
        </>
      ) : (
        <Redirect
          to={{
            pathname: "/superadmin/login",
          }}
        />
      )}
    </>
  );
};

const SuperAdminPublicRoute = (props) => {
  const superadmin_token = localStorage.getItem("superadmin_token");

  return superadmin_token ? (
    <Redirect to={{ pathname: "/superadmin/dashboard" }} />
  ) : (
    <Route {...props} />
  );
};

const PortalPublicRoute = (props) => {
  const fandfam_token = localStorage.getItem("fandfam_token");

  return fandfam_token ? (
    <Redirect to={{ pathname: "/portal/portal-main" }} />
  ) : (
    <>
      <PortalHeader />
      <Route {...props} />
      <PortalFooter />
    </>
  );
};
const PortalPrivateRoute = (props) => {
  const fandfam_token = localStorage.getItem("fandfam_token");
  return (
    <>
      {fandfam_token ? (
        <>
          <PortalHeader />
          <Route {...props} />
          <PortalFooter />
        </>
      ) : (
        <Redirect
          to={{
            pathname: "/portal/login",
          }}
        />
      )}
    </>
  );
};

function App() {
  // const userData = JSON.parse(localStorage.getItem("userData"));

  return (
    <div>
      <ErrorBoundary fallback={<p>Something went wrong</p>}>
        <Suspense fallback={<div>loading ...</div>}>
          <Router basename="/">
            <ScrollRestoration />
            <Switch>
              {/* ----------------------------- Portal Routes -----------------------------  */}
              <PortalPublicRoute exact path="/portal/login" component={PortalLogin} />
              <PortalPublicRoute exact path="/portal" component={PortalHome} />
              <Route exact path="/portal/createPortalPassword" component={ResetPortalPassword} />
              <PortalPublicRoute exact path="/portal/password" component={NewPassword} />
              <PortalPublicRoute exact path="/portal/securityQuestion" component={CheckSecurityQuestion} />
              <PortalPublicRoute exact path="/portal/welcome-login" component={welcomeLogin} />

              <PortalPrivateRoute exact path="/changePassword" component={PortalChangePassword} />
              <PortalPrivateRoute exact path="/portal/RequestServiceCalendar" component={RequestServiceCalendar} />
              <PortalPrivateRoute exact path="/portal/requestService" component={requestService} />
              <PortalPrivateRoute exact path="/portal/userProfile" component={userProfile} />
              <PortalPrivateRoute exact path="/portal/portal-main" component={PortalMain} />
              <PortalPrivateRoute exact path="/portal/Feedback" component={Feedback} />

              {/* ----------------------------- Admin Routes -----------------------------  */}

              <PublicRoute exact path="/admin/" component={Login}></PublicRoute>
              <PublicRoute exact path="/login" component={Login} />
              <PublicRoute
                exact
                path="/admin/security/:id"
                component={SecurityQuestion}
              />
              <PublicRoute
                exact
                path="/admin/forgotsecurityquestion"
                component={NewSecurityQuestion}
              />
              <PublicRoute
                exact
                path="/app/forgotsecurityquestion"
                component={NewSecurityQuestion}
              />
              <PublicRoute
                exact
                path="/ff/forgotsecurityquestion"
                component={NewSecurityQuestion}
              />
              <PublicRoute
                exact
                path="/admin/verify/:id"
                component={VerifyUser}
              />
              {/* <PublicRoute
                exact
                path="/admin/signup"
                component={MultiStepSignupForm}
              /> */}
              <PublicRoute
                exact
                path="/multistepsignupform"
                component={MultiStepSignupForm}
              />

              <PublicRoute
                exact
                path="/admin/disapprove-invoice/:invoiceId"
                component={DisaproveInvoice}
              />

              <PaymentRoute
                exact
                path="/multistepsignupform/:registerType/:ownerId/:adminId/:trackId"
                component={CardlessPaymentSuccess}
              />

              <PaymentRoute
                exact
                path="/admin/purchagePlan"
                component={StepFourSubscriptionPlan}
              />
              {/* <PaymentRoute
            exact
            path="/admin/payment"
            component={FinalStepPaymentRedirection}
          /> */}
              <PublicRoute
                exact
                path="/admin/forgotpassword"
                component={Forgotpassword}
              />
              <PublicRoute
                exact
                path="/admin/forgot-securityquestion"
                component={ForgotSecurityQuestion}
              />
              <PublicRoute
                exact
                path="/admin/newPassword"
                component={ResetPassword}
              />
              <PublicRoute
                exact
                path="/app/newPassword"
                component={ResetPassword}
              />
              <PublicRoute
                exact
                path="/app/forgotpassword"
                component={Forgotpassword}
              />
              <PublicRoute
                exact
                path="/ff/newPassword"
                component={ResetPassword}
              />
              <PublicRoute
                exact
                path="/ff/forgotpassword"
                component={Forgotpassword}
              />
              <PrivateRoute path="/admin/profile" component={ProfileRoutes} />
              <PrivateRoute path="/admin/multiSites" component={MultiSites} />
              <PrivateRoute
                exact
                path="/admin/dashboard/:_id"
                component={Dashboard}
              />
              <PrivateRoute
                exact
                path="/admin/dashboard"
                component={Dashboard}
              />
              <PrivateRoute exact path="/admin/clients" component={Clients} />

              <PrivateRoute exact path="/admin/report" component={Report} />
              <PrivateRoute
                exact
                path="/admin/fromtemplate"
                component={FromTemplate}
              />
              <PrivateRoute
                exact
                path="/admin/genericshift"
                component={GenericShiftcreation}
              />
              <PrivateRoute
                exact
                path="/admin/clientSideshift"
                component={ClientShiftcreation}
              />
              <PrivateRoute
                path="/admin/clients/details"
                component={ClientsDetails}
              />
              <PrivateRoute
                exact
                path="/admin/clientsdetails/:id/template"
                component={PredefinedTemplates}
              />
              <PrivateRoute
                exact
                path="/admin/clientsdetails/:id/template2"
                component={PredefinedQuestionAnswer}
              />
              <PrivateRoute exact path="/admin/careteam" component={CareTeam} />
              <PrivateRoute
                path="/admin/careteam/details"
                component={CareteamDetails}
              />
              <PrivateRoute path="/admin/shift" component={Shift} />
              <PrivateRoute
                exact
                path="/admin/createshifttask"
                component={CreateShiftTask}
              />
              {/* <PrivateRoute exact path="/admin/demo" component={DemoData} /> */}
              {/* <PrivateRoute
                exact
                path="/admin/shiftTasks"
                component={PayrollShiftTask}
              /> */}
              <PrivateRoute path="/admin/payroll" component={Payroll} />
              <PrivateRoute
                exact
                path="/admin/paywages/:pageType/:clientId"
                component={WageOrDetail}
              />
              <PrivateRoute
                path="/admin/emar"
                component={AddMedicines}
              />
              <PrivateRoute
                exact
                path="/admin/clientweekschedule"
                component={ClientMedicinesWeekSchedule}
              />
              <PrivateRoute
                path="/admin/invoicing"
                component={Invoicing}
              />
              <PrivateRoute
                exact
                path="/admin/clientJournal"
                component={ClientJournalAsApp}
              />
              <PrivateRoute
                path="/admin/salesfunnel"
                component={SalesFunnel}
              />
              <PrivateRoute
                exact
                path="/admin/accessrights"
                component={AccessRights}
              />
              <PrivateRoute
                exact
                path="/admin/messaging"
                component={Messaging}
              />
              <PrivateRoute
                exact
                path="/admin/userole"
                component={UserRoleManagement}
              />
              <PrivateRoute
                exact
                path="/admin/settings"
                component={SettingAceessModule}
              />
              {/* -------------------------------- Superadmin Routes  ---------------------------  */}
              <SuperAdminPublicRoute
                exact
                path="/superadmin/"
                component={SuperAdminLogin}
              />
              <SuperAdminPublicRoute
                exact
                path="/superadmin/login"
                component={SuperAdminLogin}
              />
              <SuperAdminPublicRoute
                exact
                path="/superadmin/forgotpassword"
                component={SuperAdminForgotpassword}
              />
              <SuperAdminPrivateRoute
                exact
                path="/superadmin/dashboard"
                component={Analytics}
              />
              <SuperAdminPrivateRoute
                exact
                path="/superadmin/clients"
                component={ClientsOfSuperadmin}
              />
              <SuperAdminPrivateRoute
                exact
                path="/superadmin/subscription"
                component={Subscription}
              />
              <SuperAdminPrivateRoute
                path="/superadmin/settings"
                component={SettingsDetails}
              />
              <SuperAdminPrivateRoute
                path="/superadmin/invoiceTemplate"
                component={InvoiceTemplate}
              />
              <SuperAdminPrivateRoute
                path="/superadmin/emailTemplates/:templateType"
                component={EmailTemplates}
              />
              <SuperAdminPrivateRoute
                exact
                path="/superadmin/clientdetails/:id/subscription/:subId"
                component={SubscriptionDetails}
              />
              <SuperAdminPrivateRoute
                path="/superadmin/clientdetails/:copanyName/:id"
                component={SuperClientDetails}
              />
              <SuperAdminPrivateRoute
                exact
                path="/superadmin/invoices"
                component={Invoices}
              />
              <SuperAdminPrivateRoute
                exact
                path="/superadmin/reports"
                component={Reports}
              />
              <SuperAdminPrivateRoute
                exact
                path="/superadmin/reports/:dashboardCategory/:dashboardSubCategory"
                component={Reports}
              />
              <SuperAdminPrivateRoute
                exact
                path="/superadmin/payments"
                component={Payments}
              />
              <SuperAdminPrivateRoute
                exact
                path="/superadmin/plans"
                component={GeneralPlans}
              />
              <SuperAdminPrivateRoute
                exact
                path="/superadmin/editAdd-On"
                component={EditAddOnDetails}
              />
              <SuperAdminPrivateRoute
                exact
                path="/superadmin/editCoupons"
                component={EditCoupons}
              />
              <SuperAdminPrivateRoute
                exact
                path="/superadmin/editPlan"
                component={EditPlanDetails}
              />
              <SuperAdminPrivateRoute
                exact
                path="/superadmin/all-activity"
                component={AllActivity}
              />
              <SuperAdminPrivateRoute
                exact
                path="/superadmin/content-management"
                component={ContentManagement}
              />
              <SuperAdminPrivateRoute
                exact
                path="/superadmin/content-management/:_id"
                component={ContentManagement}
              />
              {/* <PopupContactList /> */}
              <SuperAdminPrivateRoute
                exact
                path="/superadmin/support"
                component={Support}
              />
              <SuperAdminPrivateRoute
                exact
                path="/superadmin/customtable"
                component={Customdatatable}
              />
              <SuperAdminPrivateRoute
                exact
                path="/superadmin/page"
                component={Page}
              />
              <SuperAdminPrivateRoute
                exact
                path="/superadmin/profile"
                component={SuperAdminProfile}
              />
              {/* // -------------------------- Website Routes -------------------------- // */}
              <Route exact path="/">
                <WebsiteHeader />
                <Home />
                <WebsiteFooter />
              </Route>
              <Route exact path="/segments">
                <WebsiteHeader />
                <Segments />
                <WebsiteFooter />
              </Route>
              <Route exact path="/about">
                <WebsiteHeader />
                <About />
                <WebsiteFooter />
              </Route>
              <Route exact path="/contact">
                <WebsiteHeader />
                <Contact />
                <WebsiteFooter />
              </Route>
              <Route exact path="/plans">
                <WebsiteHeader />
                <WebsitePlan />
                <WebsiteFooter />
              </Route>
              <Route exact path="/privacy">
                <WebsiteHeader />
                <Privacy />
                <WebsiteFooter />
              </Route>
              <Route exact path="/terms">
                <WebsiteHeader />
                <Terms />
                <WebsiteFooter />
              </Route>
              {/* <Route exact path="/blogs/">
                <WebsiteHeader />
                <AllBlogs />
                <WebsiteFooter />
              </Route> */}

              <Route exact path="/blog-details/">
                <WebsiteHeader />
                <BlogDetails />
                <WebsiteFooter />
              </Route>
              <Route exact path="/blog-details/:_id">
                <WebsiteHeader />
                <BlogDetails />
                <WebsiteFooter />
              </Route>
              <Route exact path="/features/:topicId">
                <WebsiteHeader />
                <CommonFeaturePages />
                <WebsiteFooter />
              </Route>
              <Route exact path="/features">
                <WebsiteHeader />
                <Features />
                <WebsiteFooter />
              </Route>
            </Switch>
          </Router>
        </Suspense>
      </ErrorBoundary>
    </div>
  );
}

export default App;

function customDecode(encodedString) {
  let decoded = "";
  for (let i = 0; i < encodedString.length; i++) {
    const char = encodedString.charAt(i);
    decoded += char === " " ? " " : String.fromCharCode(char.charCodeAt(0) - 1);
  }
  return decoded;
}