import React from 'react'

export default function PayrollStats(props) {
    const { overallCount } = props;

    function limitToTwoDecimal(number) {
        return number ? (Math.round(number * 100) / 100) : '000.00';
    }

    return (
        <div className="align-items-center mt-3">
            <div className='row justify-content-around'>
                <div className='col-md-6 d-flex justify-content-around mt-3' style={{ transform: "translate(-62px, 0px)" }}>
                    <div className=" sechudleCard default_color">
                        <p>Care Team</p>
                        <h5>{overallCount?.totalCareTeam}</h5>
                    </div>
                    <div className=" sechudleCard" style={{ color: '#2D9CDB' }}>
                        <p>Regular Hrs</p>
                        <h5>{overallCount?.totalRegularHours?.toFixed(2)}</h5>
                    </div>
                    <div className=" sechudleCard" style={{ color: '#F2C94C' }}>
                        <p>Overtime Hrs </p>
                        <h5>{overallCount?.totalOverTimeHours?.toFixed(2)}</h5>
                    </div>
                    <div className=" sechudleCard" style={{ color: '#F2994A' }}>
                        <p>Total Hrs</p>
                        <h5>{overallCount?.totalHours?.toFixed(2)}</h5>
                    </div>
                    <div className=" sechudleCard" style={{ color: '#27AE60' }}>
                        <p>Wages</p>
                        <h5>£  {limitToTwoDecimal(overallCount?.totalGrossWages)}</h5>
                    </div>
                </div>
            </div>
        </div>
    )
}
