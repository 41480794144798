import React, { useMemo, useState, useRef, useEffect, useContext } from "react";
import axios from "axios";
import "./UserRoleManagement.css";
import { MdModeEditOutline } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import Select from "react-select";
import UpdateUserRoleManagement from "./UpdateUserRoleManagement";
import { AiFillEye } from "react-icons/ai";
import Modal_Popup from "../../components/modal_renderer/Modal_Popup";
import { useForm } from "react-hook-form";
import { Modal, Button } from "react-bootstrap";
import { Roles, AccessManagementDefaultRoles } from "../../constants/roles";
import UserRoleTable from "./UserRoleTable";
import GlobalContext from "../store/global-context";
import { httpAdmin } from "../../Apis/commonApis";
import { Dropdown } from "react-bootstrap";
import swal from "sweetalert";
import { currentCareSiteId, getCareSiteListing } from "../../redux-toolkit/reducer/switchSites";
import { useSelector } from "react-redux";
import { FormControlLabel, Switch } from "@mui/material";
import { ShowSwal } from "../../utils/alertSwal";

function UserRoleManagement() {

  const { userDataLatest } = useSelector((state) => state.caresiteDataReducer);
  const { careSitelistings } = useSelector((state) => state.switchSites);

  const [refresh, doRefresh] = useState(0);
  const [UpdateStudentData, setUpdateCategoriesData] = useState({});
  const [Viewuser, setViewuser] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [existingUsersList, setExistingUsersList] = useState([]);
  const [selectedActionId, setSelectedActionId] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const [manualSwitch, setManualSwitch] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedCareSites, setselectedCareSites] = useState([]);
  const [selectedRole, setSelectedRole] = useState({});

  const [show, setShow] = useState(false);
  // const childRef = useRef();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let urlID = JSON.parse(localStorage.getItem("userData"));

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm({ mode: "onBlur" });

  const globalCtx = useContext(GlobalContext);

  const getAllCareHomeID = () => {
    globalCtx.getAllAdminHomeID();
  };

  const roleList = useMemo(() => {
    let userRoles = [];
    if (urlID.userType === "owner") {
      userRoles = Roles.slice(1);
    }
    if (urlID.userType === "admin") {
      userRoles = Roles.slice(2);
    }
    if (urlID.userType === "deputy_admin") {
      userRoles = Roles.slice(3);
    }
    if (urlID.userType === "supervisor") {
      userRoles = [];
    }
    return userRoles;
  }, []);

  const careSitesList = useMemo(() => {
    const sitesList = careSitelistings?.map((elem) => {
      return { value: elem._id, label: elem.careSiteName };
    });
    return sitesList;
  }, [careSitelistings]);

  useEffect(() => {
    getAllCareHomeID();
  }, []);

  const editdataReloadFunc = () => {
    getAdminsbyOwnerId();
  };

  // ------Post API-------//
  const [errorMsg, setErrMsg] = useState('');

  const addNewRole = async (postdata) => {
    if (manualSwitch === false && !selectedUser.value) {
      setErrMsg('Please Select user');
      return;
    }
    if (selectedCareSites?.length < 1) {
      setErrMsg('Please Select atleast one caresite');
      return;
    }
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const mapSelectedSites = selectedCareSites.map(item => ({ caresiteId: item.value }));
    const defaultRole = AccessManagementDefaultRoles.filter((roles) => roles.roleId === selectedRole?.role);

    let formValues = {
      adminId: urlID._id,
      subscriptionPlanData: mapSelectedSites,
      timeZone: userTimezone,
      role: defaultRole,
      userType: selectedRole?.role,
    };
    if (manualSwitch) {
      formValues.email = postdata.email;
      formValues.firstName = postdata.firstName;
      formValues.lastName = postdata.lastName;
      formValues.mobileNo = postdata.tel;
    } else {
      formValues.userId = selectedUser?.value;
    }
    setIsOpen(false);
    const result = await httpAdmin(`assingRoles?adminId=${urlID._id}`, 'post', formValues, null);
    if (result.status) {
      reset();
      getAdminsbyOwnerId();
    }
    ShowSwal(result.status, result.message ?? '');
  };

  useEffect(() => {
    getAdminsbyOwnerId();
  }, []);

  function getAdminsbyOwnerId() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getSubUsers?caresiteId=${currentCareSiteId}`,
      headers: {
        Authorization: localStorage.getItem("care_admin_token"),
        "Content-Type": "application/json",
      },
    };
    axios(config).then(function (response) {
      setRowData(response.data.result);
      setSelectedActionId([]);
    }).catch(function (error) {
      console.log(error);
    });
  }

  function getAdminsOnDropdownList() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getSubUsers?caresiteId=${currentCareSiteId}&dropdownList=${true}`,
      headers: {
        Authorization: localStorage.getItem("care_admin_token"),
        "Content-Type": "application/json",
      },
    };
    axios(config).then(function (response) {
      const formattedUsers = response.data?.result.map((user) => {
        return {
          label: `${user.firstName} ${user.lastName}`,
          value: user._id,
          careSiteId: user?.subscriptionPlanData?.map(item => item.caresiteId),
          userType: user.userType
        };
      });
      setExistingUsersList(formattedUsers);
    }).catch(function (error) {
      console.log(error);
    });
  }

  const handleRolechange = (event) => {
    setSelectedRole(event);
  };

  const handleSiteChange = (event) => {
    setselectedCareSites(event);
    setErrMsg('');
  };

  const handleManualSwitch = (event) => {
    setManualSwitch(event.target.checked);
    if (event.target.checked) {
      setSelectedRole(roleList[0])
    }
  };

  const handleExistingUsers = (event) => {
    setSelectedUser(event);
    const assignSites = careSitesList.filter(item => event.careSiteId?.includes(item.value));
    const assignedRole = roleList.find(item => item.role === event.userType);
    setSelectedRole(assignedRole);
    setselectedCareSites(assignSites);
    setErrMsg('');
  };

  function hideModal() {
    setIsOpen(false);
    reset();
    setManualSwitch(false);
    setSelectedUser("");
    setselectedCareSites([]);
    setSelectedRole({});
  }

  const changeStatus = async (status) => {
    let body = {
      userId: selectedActionId,
      userStatus: status,
    };
    const result = await httpAdmin(`deleteUsers`, 'post', body, null);
    setSelectedActionId([]);
    getAdminsbyOwnerId();
  };

  const deleteAdmin = async () => {
    const OwnerId = JSON.parse(localStorage.getItem("userData"))._id;
    const result = await httpAdmin(`deleteAdmin?adminId=${selectedActionId[0]}&ownerId=${OwnerId}`, 'delete', null, null);
    getAdminsbyOwnerId();
  };

  function addEditClickHandler(actionType) {
    if (actionType === "edit") {
      if (selectedActionId.length === 0) {
        swal("Error!", "Please Select To Edit", "error");
      } else if (selectedActionId.length > 1) {
        swal("Error!", "Please Select Only One To Edit", "error");
      } else {
        rowData?.forEach((element) => {
          if (element._id === selectedActionId[0]) {
            setUpdateCategoriesData(element);
            // setValue("firstName", element.firstName);
          }
        });
        handleShow();
        doRefresh(0);
      }
    } else if (actionType === "add") {
      setIsOpen(true);
    } else if (actionType === "changeStatus") {
      if (selectedActionId.length === 0) {
        swal("Error!", "Please Select To Change Status", "error");
        return;
      }
      swal({
        title: "Change Status",
        text: "click button to activate or inactivate",
        buttons: {
          cancel: "Cancel",
          inactivate: "In-activate",
          activate: "Activate",
        },
      }).then((result) => {
        if (result === "activate") {
          changeStatus(true);
        } else if (result === "inactivate") {
          changeStatus(false);
        } else {
          console.log("rejected");
        }
      });
    } else if (actionType === 'delete') {
      if (selectedActionId.length === 0) {
        swal("Error!", "Please Select To delete", "error");
      } else if (selectedActionId.length > 1) {
        swal("Error!", "Please Select Only One To delete", "error");
      } else {
        swal({
          title: "Are you sure??",
          text: 'Click below to procced',
          buttons: {
            cancel: "Cancel",
            succeed: "Yes",
          },
        }).then((result) => {
          if (result) {
            deleteAdmin();
          } else {
            console.log("rejected");
          }
        });
      }
    }
  }

  return (
    <>
      <h4 className="card-title">
        <div className="float-end btns_head">
          {/* <button
            className="btn btn-theme btn-sm"
            onClick={() => {
              setIsOpen(true);
            }}
          >
            Add User
          </button> */}
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              Action
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                disabled={(userDataLatest?.userType === 'owner' || userDataLatest?.userType === 'admin') ? false : true}
                href="#"
                onClick={() => {
                  addEditClickHandler("add");
                  getAdminsOnDropdownList();
                }}
              >
                Add User
              </Dropdown.Item>
              <Dropdown.Item
                href="#"
                disabled={userDataLatest?.userType === 'owner' ? false : true}
                onClick={() => {
                  addEditClickHandler("edit");
                  getAdminsOnDropdownList();
                }}
              >
                Edit User
              </Dropdown.Item>
              <Dropdown.Item
                disabled={(userDataLatest?.userType === 'owner' || userDataLatest?.userType === 'admin') ? false : true}
                href="#"
                onClick={() => {
                  addEditClickHandler("changeStatus");
                }}
              >
                Change Status
              </Dropdown.Item>
              <Dropdown.Item
                disabled={userDataLatest?.userType === 'owner' ? false : true}
                href="#"
                onClick={() => {
                  addEditClickHandler("delete");
                }}
              >
                Delete User
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </h4>

      <UserRoleTable
        rows={rowData}
        selectedActionId={selectedActionId}
        setSelectedActionId={setSelectedActionId}
      />

      <Modal show={isOpen} onHide={hideModal}>
        <Modal.Body>
          <form onSubmit={handleSubmit(addNewRole)}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Add User
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => hideModal()}
                  ></button>
                </div>

                <div className="modal-body">
                  <div className="row">
                    <FormControlLabel
                      control={
                        <Switch
                          checked={manualSwitch}
                          onChange={handleManualSwitch}
                        />
                      }
                      label="Add Details Manually"
                      className="mb-2"
                    />
                    {!manualSwitch && <div className="col-md-12 mb-3">
                      <label className="form-label">Existing users</label>
                      <Select
                        options={existingUsersList}
                        value={selectedUser}
                        onChange={handleExistingUsers}
                      />
                      {errorMsg.includes('user') && (
                        <p className="error_style">{errorMsg}</p>
                      )}
                    </div>}

                    {manualSwitch &&
                      <>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">First Name</label>
                          <div className="position-relative">
                            <input
                              className="form-control"
                              {...register("firstName", {
                                required: true,
                                pattern: /^[A-Za-z ]+$/i,
                                min: 1,
                              })}
                            />
                            {errors?.firstName?.type === "pattern" && (
                              <p className="error_style">Alphabet only</p>
                            )}
                            {errors?.firstName?.type === "required" && (
                              <p className="error_style">This field is required</p>
                            )}
                            {errors?.firstName?.type === "min" && (
                              <p className="error_style">This field is required</p>
                            )}
                            <div
                              className="hint_box"
                              style={{ display: "block" }}
                            ></div>
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">Last Name</label>
                          <div className="position-relative">
                            <input
                              className="form-control"
                              {...register("lastName", {
                                required: true,
                                pattern: /^[A-Za-z ]+$/i,
                                min: 1,
                              })}
                            />
                            {errors?.lastName?.type === "pattern" && (
                              <p className="error_style">Alphabet only</p>
                            )}
                            {errors?.lastName?.type === "required" && (
                              <p className="error_style">This field is required</p>
                            )}
                            {errors?.lastName?.type === "min" && (
                              <p className="error_style">This field is required</p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">Email</label>
                          <div className="position-relative">
                            <input
                              className="form-control"
                              {...register("email", {
                                required: true,
                                pattern:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              })}
                            />
                            {errors?.email?.type === "pattern" && (
                              <p className="error_style">Invalid email address</p>
                            )}
                            {errors?.email?.type === "required" && (
                              <p className="error_style">This field is required</p>
                            )}
                            {/* {isEmailExist && (
                            <p style={{ color: "red" }}>{roleErrorMessage}</p>
                          )} */}
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">Contact Number</label>
                          <div className="position-relative">
                            <input
                              className="form-control"
                              {...register("tel", {
                                required: "This field is required",
                                minLength: {
                                  value: 6,
                                  message: "Please enter minimum 6 characters",
                                },
                              })}
                            />
                            {errors.tel && (
                              <p role="alert" className="error_style">
                                {errors.tel.message}
                              </p>
                            )}
                          </div>
                        </div>
                      </>}

                    <div className="col-md-12 mb-3">
                      <label className="form-label">Care Site</label>
                      <Select
                        isMulti
                        options={careSitesList}
                        value={selectedCareSites}
                        onChange={handleSiteChange}
                      />
                      {errorMsg.includes('caresite') && (
                        <p className="error_style">{errorMsg}</p>
                      )}
                    </div>

                    <div className="col-md-12 mb-3">
                      <label className="form-label">Role</label>
                      <Select
                        isDisabled={manualSwitch ? false : true}
                        options={roleList}
                        value={selectedRole}
                        onChange={handleRolechange}
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="submit"
                    className="btn btn-success submitBtn"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
          {/* </div> */}
        </Modal.Body>
      </Modal>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit Admin Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UpdateUserRoleManagement
            refresh={refresh}
            handleClose={handleClose}
            careSites={careSitesList}
            updatedData={UpdateStudentData}
            onEditDataFunction={editdataReloadFunc}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-danger mt-3 CancelBtn"
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            className="btn btn-success submitBtn"
            onClick={() => {
              doRefresh((prev) => prev + 1);
            }}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <div
        className="modal fade"
        id="UserViewId"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Admin Details{" "}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body border">
                      <div className="profile_box">
                        <div className="profile_box_body">
                          <div>
                            <h6> Name : {Viewuser.username}</h6>
                          </div>
                          <div>
                            <h6> Email : {Viewuser.email}</h6>
                          </div>
                          <div>
                            {Viewuser?.type === "master admin" &&
                              Viewuser?.roles?.length === 0 && (
                                <h6> Roles : Access of All Modules</h6>
                              )}
                            {Viewuser?.roles?.length > 0 && (
                              <h6>
                                {" "}
                                Roles :{" "}
                                {Viewuser?.roles
                                  .map((item) => {
                                    if (item.includes("_")) {
                                      const newItem = item.split("_").join(" ");
                                      return newItem;
                                    }
                                    return item;
                                  })
                                  .join(", ")}
                              </h6>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger CancelBtn"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* <div
                style={{ width: "100%" }}
                className="ag-theme-alpine tableFix"
            >
                <AgGridReact
                    rowHeight={rowHeight}
                    rowData={rowData}
                    defaultColDef={defaultColDef}
                    frameworkComponents={{
                        childMessageRenderer: ChildMessageRenderer,
                        moodRenderer: MoodRenderer,
                        srNoRenderer: SrNoRenderer,
                        nameRenderer: NameRenderer,
                        multicaresite: Multicaresite,
                        statusRenderer: StatusRenderer,
                        rolesRenderer: RolesRenderer,
                    }}
                >
                    <AgGridColumn
                        width={200}
                        field="Name"
                        cellRenderer="nameRenderer"
                        sortable={true}
                        filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                        width={200}
                        field="Roles"
                        cellRenderer="rolesRenderer"
                        sortable={true}
                        filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                        width={250}
                        field="email"
                        sortable={true}
                        filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                        cellRenderer="multicaresite"
                        field="Multi Care Site Status"
                        sortable={true}
                    ></AgGridColumn>
                    <AgGridColumn
                        width={100}
                        field="Action"
                        cellRenderer="childMessageRenderer"
                        colId="params"
                        sortable={false}
                        filter={false}
                    ></AgGridColumn>
                    <AgGridColumn
                        cellRenderer="statusRenderer"
                        field="status"
                        sortable={true}
                    ></AgGridColumn>
                </AgGridReact>
            </div> */}
    </>
  );
}

export default UserRoleManagement;
