// @function: this method is taken an array and property to group the data based on that and the output should be an object having key as properties and the remaining item specific to that.

export default function groupItems(array, property) {
  return array.reduce(function (groups, item) {
    var name = item[property];
    var group = groups[name] || (groups[name] = []);
    group.push(item);
    return groups;
  }, {});
}

export function RefreshPage() {
  window.location.reload(false);
}

/**
 *
 * @param {*} obj is the given object which contains the array of objects nested string of "{{Name}}"
 *  in question and options with have to replace it with the "Client's First Name"
 * @param {*} searchWord : "{{Name}}"
 * @param {*} replaceWord : "Client's First Name"
 * @returns the object with contains client's first name instead of "{{Name}}"
 */
export const replaceWordInObject = (obj, searchWord, replaceWord) => {
  const newObj = {};
  for (const key in obj) {
    if (typeof obj[key] === "string") {
      newObj[key] = obj[key].replace(new RegExp(searchWord, "gi"), replaceWord);
    } else if (Array.isArray(obj[key])) {
      newObj[key] = obj[key].map((item) =>
        replaceWordInObject(item, searchWord, replaceWord)
      );
    } else if (typeof obj[key] === "object" && obj[key] !== null) {
      newObj[key] = replaceWordInObject(obj[key], searchWord, replaceWord);
    } else {
      newObj[key] = obj[key];
    }
  }
  return newObj;
};

export const twoDecimalPlaces = (input) => {
  let number;
  if (input) {

    // Parse the input string to a number if it's a string
    if (typeof input === "string") {
      number = parseFloat(input);
      if (isNaN(number)) {
        return '0.00';
      }
    } else if (typeof input !== "number") {
      return '0.00';
    } else {
      number = input;
    }

    return number.toFixed(2)
  } else {
    return '0.00';
  }
};

export const convertInMile = (input) => {
  if (+input) {
    const inMile = input * 0.000621371;
    return inMile.toFixed(2)
  } else {
    return '0.00';
  }
};
