import React, { useState, useEffect, useMemo } from "react";
import { useParams, NavLink } from "react-router-dom";
import Operations from "./Operations";
import Finance from "./Finance";
import { AiFillPrinter } from "react-icons/ai";
import JsonDownload from "../../components/DownloadExcel/DownloadExcel";
import { BsSearch } from "react-icons/bs";
import DatePicker from "react-datepicker";
import { formatDate, getAccessList, getMondayDate, getMonthAgoDate, getWeekStartAndEndDates, handleDataPrint, limitToTwoDecimal } from "../../utils/CommonFunctions";
// import { useSelector } from "react-redux";
import { httpAdmin } from "../../Apis/commonApis";
import { useSelector } from "react-redux";
import NoAccessMsg from "../../components/NoAccessMsg";

let monthAgoDate = getMonthAgoDate();
let today = new Date().toISOString().slice(0, 10);

function MultiSiteRoutes() {

    let { topicId } = useParams();
    const { accessRoles } = useSelector((state) => state.caresiteDataReducer);
    const moduleAccess = useMemo(() => {
        const findCurrentModule = accessRoles?.find(item => item.moduleName === 'MultiSite Dashboard');
        const accesssList = getAccessList(findCurrentModule?.children);
        return accesssList;
    }, [accessRoles]);

    const [searchText, setSearchText] = useState("");
    const [allSiteTasks, setAllSiteTasks] = useState([]);
    const [allSiteFinance, setAllSiteFinance] = useState([]);
    const [filterType, setFilterType] = useState("week");

    const [dateRange, setDateRange] = useState([monthAgoDate, new Date()]);
    const [startDate, endDate] = dateRange;

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem("userData"));
        if (filterType === "custom" && endDate) {
            getAllSiteTaskData(formatDate(startDate), formatDate(endDate), userData._id);
            getAllSiteFinanceData(formatDate(startDate), formatDate(endDate), userData._id);
        } else if (filterType === "week") {
            const weekDates = getWeekStartAndEndDates(today);
            getAllSiteTaskData(weekDates.start, weekDates.end, userData._id);
            getAllSiteFinanceData(weekDates.start, weekDates.end, userData._id);
        }
    }, [filterType, dateRange]);

    const getAllSiteTaskData = async (start, end, uid) => {
        const res = await httpAdmin(`getShiftTasks?adminId=${uid}&startDate=${start}&endDate=${end}&type=operations`, 'get', null, null);
        if (res.status) {
            const userData = JSON.parse(localStorage.getItem("userData"));
            const allowedCareSites = userData?.subscriptionPlanData?.map(item => item._id);
            const filterDataByUser = res.data?.filter(item => allowedCareSites.includes(item._id));
            let siteDataArr = filterDataByUser?.map((item, i) => {
                return { careSiteName: item.careSiteName, id: i + 1, ...item.taskCount }
            });
            let combinedArr = [{
                id: 0,
                careSiteName: "All Sites",
                completed: countTotal(siteDataArr, 'completed'),
                inProgress: countTotal(siteDataArr, 'inProgress'),
                omitted: countTotal(siteDataArr, 'omitted'),
                overdue: countTotal(siteDataArr, 'overdue'),
                pending: countTotal(siteDataArr, 'pending'),
                reportIncident: countTotal(siteDataArr, 'reportIncident'),
                total: countTotal(siteDataArr, 'total')
            },
            ...siteDataArr
            ];
            setAllSiteTasks(combinedArr)
        }
    }

    const getAllSiteFinanceData = async (start, end, uid) => {
        const res = await httpAdmin(`getShiftTasks?adminId=${uid}&startDate=${start}&endDate=${end}&type=finance`, 'get', null, null);
        if (res.status) {
            const userData = JSON.parse(localStorage.getItem("userData"));
            const allowedCareSites = userData?.subscriptionPlanData?.map(item => item._id);
            const filterDataByUser = userData?.userType === "owner" ? res.data : res.data?.filter(item => allowedCareSites.includes(item._id));
            let siteDataArr = filterDataByUser?.map((item, i) => {
                const filteredTasks = item.taskCount?.filtered ?? [];
                const totalLength = filteredTasks.length > 0 ? filteredTasks.length : 1;
                const taskCount = item.taskCount;
                const exlZeroActiveMember = removeUntilFirstNonZero(filteredTasks, 'activeCareMember');
                const inactiveTeamAtBegg = exlZeroActiveMember?.[0]?.deactiveCareMember ?? 0;
                const inactiveTeamAtEnd = exlZeroActiveMember?.[exlZeroActiveMember.length - 1]?.deactiveCareMember ?? 0;
                const activeTeamAtBegg = exlZeroActiveMember?.[0]?.activeCareMember ?? 0;
                const activeTeamAtEnd = exlZeroActiveMember?.[exlZeroActiveMember.length - 1]?.activeCareMember ?? 1;
                const denominator = (activeTeamAtEnd + activeTeamAtBegg) / 2;
                const calcChurnRate = denominator > 0 ? (((inactiveTeamAtEnd - inactiveTeamAtBegg) / denominator) * 100) : 0;
                console.log(inactiveTeamAtEnd, inactiveTeamAtBegg, activeTeamAtEnd, activeTeamAtBegg, 'aaaaaaaaa');
                const exlZeroValsPayAsGo = removeUntilFirstNonZero(filteredTasks, 'payAsYouGoAverageFeeRates');
                const exlZeroValsFixFee = removeUntilFirstNonZero(filteredTasks, 'fixedPeriodAverageFeeRates');
                const exlZeroValsOccupancy = removeUntilFirstNonZero(filteredTasks, 'occupancy');
                return {
                    ...taskCount,
                    id: i + 1,
                    careSiteName: item.careSiteName === 'allSites' ? 'All Sites' : item.careSiteName,
                    occupancy: limitToTwoDecimal(countTotal(exlZeroValsOccupancy, 'occupancy') / exlZeroValsOccupancy.length),
                    occupancyRate: limitToTwoDecimal(countTotal(filteredTasks, 'occupancyRates') / totalLength),
                    churnRate: limitToTwoDecimal(calcChurnRate),
                    fixedPeriodAverageFeeRates: limitToTwoDecimal(((countTotal(exlZeroValsFixFee, 'fixedPeriodAverageFeeRates') / exlZeroValsFixFee?.length) ?? 0)),
                    payAsYouGoAverageFeeRates: limitToTwoDecimal(((countTotal(exlZeroValsPayAsGo, 'payAsYouGoAverageFeeRates') / exlZeroValsPayAsGo?.length) ?? 0)),
                }
            });
            setAllSiteFinance(siteDataArr);
        }
    }

    function countTotal(arrayOfObj, valParam) {
        const totalValue = arrayOfObj.reduce((accumulator, currentObject) => {
            return accumulator + (currentObject[valParam] || 0);
        }, 0);
        return limitToTwoDecimal(totalValue);
    }

    function customDateChangeHandler(update) {
        setDateRange(update);
    }

    return (
        <div>
            <div className="row mt-4 mb-2 position-relative">
                <div className=" col-md-2">
                    <AiFillPrinter
                        className="me-3 ms-1 curser_point"
                        onClick={() => handleDataPrint(topicId === "operations" ? allSiteTasks : allSiteFinance)}
                    />
                    <a className="curser_point me-3">
                        <JsonDownload
                            fileName="clients-excel"
                            downloadfile={topicId === "operations" ? allSiteTasks : allSiteFinance}
                        />
                    </a>
                </div>
                <div className="col-md-4"></div>
                <div className="searchForm careSearch col-md-4">
                    <input
                        type="text"
                        className="form-control"
                        value={searchText}
                        onChange={(e) => {
                            setSearchText(e.target.value);
                        }}
                        placeholder="Search"
                    />
                    <BsSearch className="icon me-2" />
                </div>
                <div className="col-md-6 action_mt">
                    <div className="d-flex justify-content-end heightSetCustom">
                        <div className="date_filters">
                            <ul className="filter_box me-2 mb-1">
                                {/* <NavLink
                                exact
                                activeClassName={filterType === "today" ? "active" : ""}
                                to="#"
                                onClick={() => setFilterType("today")}
                              >
                                Today
                              </NavLink> */}
                                <NavLink
                                    activeClassName={
                                        filterType === "week" ? "active " : ""
                                    }
                                    to="#"
                                    onClick={() => setFilterType("week")}
                                >
                                    This Week
                                </NavLink>
                                <NavLink
                                    activeClassName={
                                        filterType === "custom" ? "active" : ""
                                    }
                                    to="#"
                                    onClick={() => setFilterType("custom")}
                                >
                                    Custom
                                </NavLink>
                            </ul>
                        </div>
                        {filterType === "custom" ? (
                            <div className="col-md-4 mb-2 datepicker_input">
                                <DatePicker
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={(update) => {
                                        customDateChangeHandler(update);
                                    }}
                                    isClearable={true}
                                />
                            </div>
                        ) : (
                            <div className="col-md-4 mb-2">&ensp;</div>
                        )}
                    </div>
                </div>
            </div>

            {topicId === "operations" && <>
                {(moduleAccess?.Operations === 'full' || moduleAccess?.Operations === 'view') ?
                    <Operations
                        searchText={searchText}
                        rows={allSiteTasks}
                    /> : <NoAccessMsg />}
            </>}
            {topicId === "finance" && <>
                {(moduleAccess?.Finance === 'full' || moduleAccess?.Finance === 'view') ?
                    <Finance
                        searchText={searchText}
                        rows={allSiteFinance}
                    /> : <NoAccessMsg />}
            </>}
        </div>
    );
}

export default MultiSiteRoutes;

function removeUntilFirstNonZero(array, value) {
    const firstNonZeroIndex = array.findIndex(obj => obj[value] > 0);
    if (firstNonZeroIndex === -1) {
        return [];
    }
    return array.slice(firstNonZeroIndex);
}