import React, { useState, Fragment, useEffect } from "react";
import "./signup.css";
import FormButton from "./FormNextButton";
import { useDispatch, useSelector } from "react-redux";
import {
  changeButton,
  nextStepFuncFirstForm,
  handleSteps,
} from "../../redux-toolkit/reducer/accountCreationApiReducer";
import PrivacyAndPolicy from "./PrivacyAndPolicy";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Checkbox from "@mui/material/Checkbox";
import Select from "react-select";
import { securityQuestions } from "../../constants/constants";
import { useForm } from "react-hook-form";
import { fetchUserIpData } from "../../redux-toolkit/reducer/websitePlan";

export default function StepOneOwnerDetails() {
  const { userIpData } = useSelector((state) => state.websitePlans);
  useEffect(() => {
    dispatch(fetchUserIpData());
  }, []);
  const { stepNo, step } = useSelector(
    (state) => state.accountCreationApiReducer
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    clearErrors,
  } = useForm();

  // ==========================  Phone Number Input Library COde  =============== //
  const [isCountryCode, setcuntrycode] = useState("");
  const [isDialCode, setDialCode] = useState("");
  const [mobileNo, setmobileNo] = useState("");
  function phoneInput(value, data) {
    setmobileNo(value);
    setDialCode(`+${data.dialCode}`);
    setcuntrycode(`${data.countryCode}`);
    if (customErr !== '') setCustomErr('')
  }

  const dispatch = useDispatch();

  const [question, setQuestion] = useState("");
  const [customErr, setCustomErr] = useState('');

  const isFormValidCheck = (postdata) => {
    if (question === "" || mobileNo.length < 6) {
      setCustomErr(mobileNo.length < 6 ? 'mobile' : 'question');
      return;
    }
    let data = {
      email: postdata.email.toLowerCase(),
      password: postdata.password,
      firstName: postdata.firstName,
      lastName: postdata.lastName,
      mobileNo: mobileNo,
      privacyPolicy: postdata.privacy,
      security_questions: {
        question: question,
        answer: postdata.securityAnswer,
      },
    };
    dispatch(nextStepFuncFirstForm(data));
    nextStepChangeHandler();
  };

  function nextStepChangeHandler() {
    dispatch(
      changeButton({
        currentNo: stepNo,
        buttonText: "Next",
        stepNo: stepNo + 1,
        nextNo: stepNo + 1,
      })
    );
    dispatch(handleSteps({ errorMsg: "", registerSuccess: 0 }));
  }

  function selectQuestion(event) {
    setQuestion(event.label);
    setCustomErr('');
  }

  return (
    <Fragment>
      <div
        className="col-md-4 form_box"
        style={{ display: `${step === "step1" ? "block" : "none"}` }}
      >
        <form>
          <h4>Create Account</h4>
          <div className="mb-2">
            <input
              type="email"
              id="email"
              className="form-control"
              {...register("email", {
                required: true,
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              })}
              placeholder="Email"
            />
            {errors?.email?.type === "required" && (
              <p className="error_style error_bottom">This field is required</p>
            )}
            {errors?.email?.type === "pattern" && (
              <p className="error_style error_bottom">Invalid email address</p>
            )}
          </div>

          <div className="mb-2">
            <input
              type="password"
              id="password"
              className="form-control"
              {...register("password", {
                required: true,
              })}
              placeholder="Password"
            />
            {errors?.password?.type === "required" && (
              <p className="error_style error_bottom">This field is required</p>
            )}
          </div>

          <div className="mb-3">
            <Select
              className="select_Box_border"
              placeholder="Security Question"
              options={securityQuestions}
              onChange={selectQuestion}
              required={true}
            />
            {customErr === 'question' && (
              <p className="error_style">This field is required</p>
            )}
          </div>

          {question !== "" && (
            <div className="mb-2">
              <input
                type="text"
                id="securityAnswer"
                className="form-control"
                {...register("securityAnswer", {
                  required: true,
                })}
                placeholder="Security Answer"
              />
              {errors?.securityAnswer?.type === "required" && (
                <p className="error_style error_bottom">This field is required</p>
              )}
            </div>)
          }

          <div className="mb-2">
            <input
              type="text"
              style={{ marginTop: "20px" }}
              id="firstName"
              className="form-control"
              {...register("firstName", {
                required: true,
              })}
              placeholder="First Name"
            />
            {errors?.firstName?.type === "required" && (
              <p className="error_style error_bottom">This field is required</p>
            )}
          </div>

          <div className="mb-2">
            <input
              type="text"
              id="lastName"
              className="form-control"
              {...register("lastName", {
                required: true,
              })}
              placeholder="Last Name"
            />
            {errors?.lastName?.type === "required" && (
              <p className="error_style error_bottom">This field is required</p>
            )}
          </div>

          <div className="col-md-12 form-group">
            <div className="position-relative">
              <PhoneInput
                country={userIpData?.country?.toLowerCase()}
                inputProps={{
                  name: "phone",
                  required: true,
                }}
                isValid={(value, country) => {
                  if (
                    value.match(
                      /\+?\d{1,9}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/g
                    )
                  ) {
                    return true;
                  } else {
                    return false;
                  }
                }}
                onChange={phoneInput}
                defaultErrorMessage="Invalid Number"
              />
              {customErr === 'mobile' && (
                <p className="error_style">This field is required</p>
              )}
            </div>
          </div>
          <div className="checkbox-wrapper row">
            <div className="col-2">
              <Checkbox
                name="privacy"
                className="align-items-start"
                {...register("privacy", {
                  required: true,
                })}
                inputProps={{ "aria-label": "controlled" }}
              />
            </div>
            <div className="col-10">
              <PrivacyAndPolicy />
            </div>
          </div>
          {errors?.privacy?.type === "required" && (
            <p className="error_style privacy_error">Please check the Privacy policy and Terms and Conditions.
            </p>
          )}
          <FormButton isFormValidCheck={handleSubmit(isFormValidCheck)} />
          {/* <LoginRedirection /> */}
        </form>
      </div>
    </Fragment>
  );
}
