import React, { useEffect, useState, useRef } from 'react';
import {
    arrayUnion,
    doc,
    serverTimestamp,
    Timestamp,
    updateDoc,
    setDoc,
    getDoc,
    onSnapshot,
} from "firebase/firestore";
import { db, storage } from "../../Firebase";
import { VscSend } from "react-icons/vsc";
import cx from "../Portal.module.css";
import moment from "moment";

const chatUserImg = '/images/chatUser.svg'

export default function PortalMessaging({ clientData }) {

    const msgdiv = useRef(null);
    let userData = JSON.parse(localStorage?.getItem("fandfData"));

    const [messages, setMessages] = useState([]);
    const [text, setText] = useState("");
    const [img, setImg] = useState(null);

    useEffect(() => {
        if (clientData) {
            console.log(clientData, userData, 'clientData');
            getChatHeadsData();
            scrollToBottom()
            addOnlineStatus(clientData?.careHomeId);
            setMessages([]);
            const unSub = onSnapshot(
                doc(db, "chats", clientData?.careHomeId, "chatUsers", userData?._id),
                (doc) => {
                    doc.exists() && setMessages(doc.data().messages);
                    scrollToBottom()
                }
            );
            return () => {
                unSub();
                onlineStatusToFalse();
            };
        }
    }, [clientData]);

    const getChatHeadsData = async () => {
        const res = await getDoc(doc(db, "chatHeads", clientData?.careHomeId, 'chatUsers', userData?._id));
        const chathead = res.data();
        if (!chathead) {
            await setDoc(doc(db, "chatHeads", clientData?.careHomeId, "chatUsers", userData?._id), {
                id: userData?._id,
                lastMessage: "",
                counterMap: [
                    { id: clientData?.adminId, counter: 0 },
                    { id: userData?._id, counter: 0 },
                ]
            });
        }
    }

    const handleSend = async () => {
        const chatId = userData?._id;
        if (text.trim() === "") {
            return;
        }
        let sendTxt = text;

        setText("");
        if (img) {

        } else {
            await updateDoc(doc(db, "chats", clientData.careHomeId, "chatUsers", userData?._id), {
                messages: arrayUnion({
                    id: Date.now(),
                    text: sendTxt,
                    senderId: userData?._id,
                    senderName: userData?.name,
                    msgAlert: 1,
                    userType: 'fandsfam',
                    senderImg: userData.image ? userData.image : chatUserImg,
                    date: Timestamp.now(),
                }),
            });
            const res = await getDoc(doc(db, "chatHeads", clientData?.careHomeId, 'chatUsers', userData?._id));
            if (res.data()) {
                const counterData = res.data()?.counterMap;
                let counterMap = [];
                for (let i = 0; i < counterData.length; i++) {
                    if (counterData[i].id === chatId) {
                        counterMap.push({ id: counterData[i].id, counter: 0 });
                    } else {
                        const counterObj = counterData?.find(counter => counter.id === counterData[i].id);
                        counterMap.push({
                            id: counterObj.id, counter: counterObj ? counterObj.counter + 1 : 1
                        });
                    }
                }

                await updateDoc(doc(db, "chatHeads", clientData.careHomeId, "chatUsers", chatId), {
                    id: chatId,
                    lastMessage: sendTxt,
                    date: Date.now(),
                    senderId: chatId,
                    receiverId: clientData.careHomeId,
                    counterMap: counterMap
                });
            }
        }
        setImg(null);
    };
    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            handleSend();
        }
    };

    const addOnlineStatus = async (csid) => {
        if (!csid) return;
        const res = await getDoc(doc(db, "chats", csid, "chatUsers", userData._id));
        if (!res.exists()) {
            await setDoc(doc(db, "chats", csid, "chatUsers", userData._id), {
                messages: [],
            });
        }
        try {
            const res = await getDoc(doc(db, "onlineStatus", csid, "user", userData._id));
            if (!res.exists()) {
                await setDoc(doc(db, "onlineStatus", csid, "user", userData._id), {
                    status: true,
                    id: userData._id,
                    time: Date.now()
                });
            } else {
                await updateDoc(doc(db, "onlineStatus", csid, "user", userData._id), {
                    status: true,
                    id: userData._id,
                    time: Date.now()
                });
            }
        } catch (err) { }
    };

    const onlineStatusToFalse = async () => {
        try {
            await updateDoc(doc(db, "onlineStatus", clientData?.careHomeId, "user", userData._id), {
                status: false,
                id: userData._id,
                time: Date.now()
            });
        } catch (err) { }
    };

    const scrollToBottom = () => {
        if (msgdiv.current) {
            msgdiv.current.scrollTop = msgdiv.current.scrollHeight;
        }
    };

    const groupedMessages = groupMessagesByDate(messages);

    return (
        <div className={`mb-3 ${cx.profileProtal}`}>
            <h3>Messaging</h3>
            <div className={`${cx.profileBox}`}>
                <div className={`${cx.scrollMessage}`} ref={msgdiv}>
                    {/* <span className={`${cx.timeDate}`}>Today {formattedDate}</span> */}
                    {groupedMessages.map((group) => (
                        <div key={group.date}>
                            <div className={`${cx.chatMsgDates}`}>
                                <span>{group.date}</span>
                            </div>
                            {group.messages?.map((m) => {
                                let isMsgByUser = m?.senderId === userData?._id;
                                const userImg = m.senderImg ?? chatUserImg;
                                return (<div className={`${cx.messageChat}`} key={m.id}>
                                    <img src={userImg} alt="" className={(userImg.includes('chatUser')) ? `forImage${m.userType}` : ``} />
                                    <div className={`${cx.messageBox}`} style={{ backgroundColor: isMsgByUser ? "#F2C94C" : "#2D9CDB" }}>
                                        <h5>{isMsgByUser ? 'Me' : m?.senderName}</h5>
                                        <span>{m?.text}</span>
                                        <time>{moment(m.id).format("HH:mm")}</time>
                                    </div>
                                </div>)
                            }
                            )}
                        </div>
                    ))}

                </div>
                <div className={`${cx.sendButton}`}>
                    <input type="text" className={`form-control`} placeholder='Type Message' value={text} onChange={(e) => { setText(e?.target?.value) }}
                        onKeyDown={handleKeyPress}
                    />
                    <button onClick={handleSend}><VscSend /></button>
                </div>
            </div>
        </div>
    )
}

function groupMessagesByDate(messages) {
    if (messages.length < 1) {
        return [];
    }
    const grouped = {};
    const currentDate = new Date();
    const today = currentDate.toDateString();
    const yesterdayDate = new Date(currentDate);
    yesterdayDate.setDate(currentDate.getDate() - 1);
    const yesterday = yesterdayDate.toDateString();

    messages.forEach((message) => {
        const messageDate = new Date(message.date.toDate()).toDateString();
        let dateLabel = messageDate;

        if (messageDate === today) {
            dateLabel = 'Today';
        } else if (messageDate === yesterday) {
            dateLabel = 'Yesterday';
        }

        if (!grouped[dateLabel]) {
            grouped[dateLabel] = [];
        }
        grouped[dateLabel].push(message);
    });

    return Object.keys(grouped).map((date) => ({
        date,
        messages: grouped[date],
    }));
}