// import firebase from "firebase";
// const firebaseApp = firebase.initializeApp(firebaseConfig);
// const db = firebase.firestore();
// export default db;

// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration

const firebaseConfig = {
    apiKey: "AIzaSyDOcYeFZCjec9R43jFw95uEzXAwJbLuPuc",
    authDomain: "caremagnus-firebase-uk.firebaseapp.com",
    projectId: "caremagnus-firebase-uk",
    storageBucket: "caremagnus-firebase-uk.appspot.com",
    messagingSenderId: "1025443960575",
    appId: "1:1025443960575:web:8c598a4b3d351bbc4d1eca",
    measurementId: "G-WE01F2905H"
  };

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const storage = getStorage();
export const db = getFirestore()
