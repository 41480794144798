import React from 'react';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import WageProfile from './WageProfile';
import OverviewDetails from './OverviewDetails';

export default function WageOrDetail() {

    const history = useHistory();
    const params = useParams();
    console.log('wage or profileeeee pageeeeeee')
  return (
    params.pageType === 'wageProfile' ? <WageProfile/> : <OverviewDetails/>
  )
}
