import React, { useState, Fragment, useEffect, useMemo } from "react";
import "./signup.css";
import axios from "axios";
import getSymbolFromCurrency from "currency-symbol-map";
import SnackBar from "../../components/SnackBar/SnackBar";
import { useHistory, useLocation, Link } from "react-router-dom";
import Slider from "@mui/material/Slider";
import { alpha, styled } from "@mui/material/styles";
import { deepPurple } from "@mui/material/colors";
import Switch from "@mui/material/Switch";
import StepsPlanSection from "./StepsPlanSection";
import FormLayout from "./FormLayout";
import FinalStepPaymentRedirection from "./FinalStepPaymentRedirection";
import { httpPayments } from "../../Apis/commonApis";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { trackId, trackSignup } from "../../redux-toolkit/reducer/accountCreationApiReducer";
import { currentCareSiteId } from "../../redux-toolkit/reducer/switchSites";
import LoadingBtn from "../../components/Toggle_Button/LoadingBtn";
import { ShowSwal } from "../../utils/alertSwal";
import SelectSmall from "../../website/WebsitePlans/CurrencySelect";
import { limitToTwoDecimal } from "../../utils/CommonFunctions";
import { changeCurrency, getTaxesAndCurrency } from "../../redux-toolkit/reducer/websitePlan";
import countryList from "./countries.json";

const GreenSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: deepPurple[600],
    "&:hover": {
      backgroundColor: alpha(
        deepPurple[600],
        theme.palette.action.hoverOpacity
      ),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: deepPurple[600],
  },
}));

const label = { inputProps: { "aria-label": "Color switch demo" } };

export default function StepFourSubscriptionPlan() {

  const dispatch = useDispatch();

  const { taxesAndCurrencyData, selectedCurrency } = useSelector((state) => state.websitePlans);
  let { currentCareSiteData } = useSelector((state) => state.caresiteDataReducer);

  const [inValid, setInvalid] = useState(false);
  const [status, setStatus] = useState("info");
  const [statusMsg, setStatusMsg] = useState("Please Select Plan");
  const [planData, setPlansData] = useState([]);
  const [state, setState] = useState({ monthlyearly: false });
  const [loading, setLoading] = useState(false);
  const [fandsFam, setFandsFam] = useState(0);
  const [noOfLics, setNoOfLics] = useState(0);
  const [planPrice, setPlanPrice] = useState(0);
  const [selectedPlanId, setSelectedPlanId] = useState("");
  const [selectedPlanData, setSelectedPlanData] = useState(null);
  const [promoCode, setPromoCode] = useState("");
  const [isCouponValidated, setIsCouponValidated] = useState(false);
  const [validatingLoader, setValidatingLoader] = useState(false);
  const [promoDiscount, setPromoDiscount] = useState({
    disType: "",
    disVal: "0",
  });
  const [addOnStatus, setAddOnStatus] = useState({ fandFamily: false });
  const [openPaymentPage, setOpenPaymentPage] = useState(false);
  const [cartData, setCartData] = useState({});
  const [adminData, setAdminData] = useState(null);
  const [buttonText, setButtonText] = useState("Next");
  const [bold, setBold] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const { ownerId, updatePlan, trackIdParam } = location.state;

  useEffect(() => {
    // console.log(taxesAndCurrencyData, 'taxesAndCurrencyData')
    if (adminData && taxesAndCurrencyData) {
      const selectedCountryCurrency = countryList?.countries?.find((ele) => ele.name === adminData?.country)?.currency?.code;
      let findCurrency = taxesAndCurrencyData?.currencies?.find(item => item.currency === selectedCountryCurrency);
      if (!findCurrency) {
        findCurrency = taxesAndCurrencyData?.currencies?.find(item => item.currency === 'GBP');
      }
      console.log(findCurrency, 'findCurrency')
      dispatch(changeCurrency({ currency: findCurrency }));
    }
  }, [adminData, taxesAndCurrencyData]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (location.search !== '') {
      const planIdFromQuery = query.get('planId');
      getPlans(planIdFromQuery);
      const currencyFromQuery = query.get('currency');
      console.log(planIdFromQuery, 'planIdFromQuery')
      setState({ monthlyearly: query.get('year') === 'true' });
      setAddOnStatus({ fandFamily: +query.get('count') > 0 });
      setNoOfLics(+query.get('count'))
      dispatch(changeCurrency({ currency: currencyFromQuery }));
      setSelectedPlanId(planIdFromQuery);
    } else {
      getPlans('');
    }
    // dispatch(getTaxesAndCurrency());
    getAdminData();
    if (updatePlan) {
      setButtonText('Confirm Update');
      let locdata = location.state;
      let fandfqty = +locdata?.currentPlanData[1]?.qty;
      setSelectedPlanId(locdata?.planId);
      setAddOnStatus({ fandFamily: fandfqty > 0 });
      setNoOfLics(fandfqty ? fandfqty : 0);
      setState({
        monthlyearly: locdata?.monthlyearly === 'Yearly',
      });
    };
  }, []);

  useEffect(() => {
    if (updatePlan && planData.length > 0) {
      let locdata = location.state;
      planData.forEach((elem) => {
        if (elem._id === locdata?.planId) {
          let price = state.monthlyearly
            ? elem.unitPriceYearly
            : elem.unitPriceMonthly;
          setPlanPrice(price);
          setSelectedPlanData(elem);
        }
      });
    };
  }, [planData]);

  const getAdminData = async () => {
    const res = await httpPayments(`getAdmin?adminId=${ownerId}`, null);
    if (res.status) setAdminData(res.result[0]);
  };

  const upgradePlan = async (cartDetails) => {
    setLoading(true);
    // unit_amount, currency, newPlanName, frequency, goCardLess_mandate_id, paymentStartDate, reason
    let prodId = state.monthlyearly
      ? selectedPlanData?.stripe_yearlyProduct_id
      : selectedPlanData?.stripe_monthlyProduct_id;
    let currentsite = adminData?.subscriptionPlanData?.find(item => item?._id === currentCareSiteId);
    let subscriptionData = currentCareSiteData ?? currentsite;

    let registeredPaymentMode = subscriptionData?.goCardLess_BankAccount_id ? "gocardless" : "stripe";

    let data = {
      careHomeId: subscriptionData?._id,
      paymentThrough: subscriptionData?.paymentThrough === '' ? registeredPaymentMode : subscriptionData?.paymentThrough,
      currency: subscriptionData?.currency?.toUpperCase(),
      unit_amount: +cartDetails?.totalBeforeTax,
      total_amount: cartDetails?.unit_amount,
      totalTax: cartDetails?.unit_amount - cartDetails?.totalBeforeTax,
      superadminTaxrate: cartDetails?.taxRate,
      totalDiscountValue: 0,
      product_id: prodId,
      newPlanName: selectedPlanData?.planName,
      newPlanMonthyOrYearly: state.monthlyearly ? "Yearly" : "Monthly",
      newPlanId: selectedPlanId,
      frequency: state.monthlyearly ? "yearly" : "monthly",
      interval: state.monthlyearly ? "year" : "month",
      chargesDetails: cartDetails?.chargesDetails,
      reason: 'upgrade plan'
    };
    let currentDate = new Date();
    let nextMonthDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate());
    if (+subscriptionData.unit_amount === 0 && registeredPaymentMode === 'gocardless') {
      data.paymentStartDate = nextMonthDate?.toJSON()?.split('T')[0];
      data.goCardLess_mandate_id = subscriptionData.goCardLess_mandate_id;
    }
    const res = await httpPayments(`upgradeSubscription`, data);
    setLoading(false);
    swal(
      res.status ? "Success" : "Failed",
      res.message,
      res.status ? "success" : "error"
    ).then((value) => {
      if (res.status) {
        history.push("/admin/dashboard");
      }
    });
  };

  const handleChange = (event) => {
    setBold(event.target.checked)
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
    setPromoCode("");
    setPromoDiscount({ disType: "", disVal: "0" });
    if (selectedPlanId !== "") {
      planData.forEach((elem) => {
        if (elem._id === selectedPlanId) {
          setPlanPrice(
            event.target.checked ? elem.unitPriceYearly : elem.unitPriceMonthly
          );
        }
      });
    }
  };

  const totalSubFee = useMemo(() => {
    const feeWithoutCoupon = state.monthlyearly ? (fandsFam * 12 * noOfLics + planPrice) : (fandsFam * noOfLics + planPrice);
    const afterApplyExchangeRate = feeWithoutCoupon * (+selectedCurrency?.gbpExchangeRate);
    const afterDiscount = afterApplyExchangeRate - (promoDiscount.disType === "Fixed" ? (+promoDiscount.disVal) : ((afterApplyExchangeRate / 100) * (+promoDiscount.disVal)));
    return +((afterDiscount > 0 ? afterDiscount : 0)).toFixed(2);
  }, [fandsFam, noOfLics, planPrice, selectedCurrency, promoDiscount]);

  const isFormValidCheck = () => {
    if (selectedPlanId !== "") {
      const fandsFamAmt = (fandsFam * (state.monthlyearly ? 12 : 1)) * (+selectedCurrency?.gbpExchangeRate);
      const discountAmt = limitToTwoDecimal(promoDiscount.disType === "Fixed" ? (+promoDiscount.disVal) :
        (((fandsFam * (state.monthlyearly ? 12 : 1)) * noOfLics + planPrice) / 100) * (+promoDiscount.disVal)) * (+selectedCurrency?.gbpExchangeRate);

      const currentCountry = adminData ? adminData?.country : currentCareSiteData?.country;
      let tax = 0;
      if (currentCountry === 'United States') {
        const fetchTaxCountry = taxesAndCurrencyData?.taxes?.find((elem) => (elem.country.includes(currentCountry) && elem.state === adminData.state));
        tax = fetchTaxCountry ? fetchTaxCountry.taxRate : 0;
      } else {
        const fetchTaxCountry = taxesAndCurrencyData?.taxes?.find((elem) => elem.country.includes(currentCountry));
        tax = fetchTaxCountry ? fetchTaxCountry.taxRate : 0;
      }

      let totalAfterTax = totalSubFee + (+totalSubFee * (+tax / 100));

      let prodId = state.monthlyearly
        ? selectedPlanData?.stripe_yearlyProduct_id
        : selectedPlanData?.stripe_monthlyProduct_id;

      const chargesDetails = [
        {
          discription: selectedPlanData?.planName,
          rate: planPrice * (+selectedCurrency?.gbpExchangeRate),
          qty: 1,
        },
        {
          discription: "Friends & Family App",
          rate: limitToTwoDecimal(fandsFamAmt),
          qty: noOfLics,
        },
        {
          discription: "Dicount Coupon",
          rate: discountAmt,
          qty: discountAmt > 0 ? 1 : 0,
        },
      ];
      let cartDetails = {
        planId: selectedPlanId,
        productId: prodId,
        // unit_amount: totalAmount,
        taxRate: +tax,
        unit_amount: +(totalAfterTax > 0 ? totalAfterTax : 0).toFixed(2),
        totalBeforeTax: totalSubFee > 0 ? totalSubFee : 0,
        interval: state.monthlyearly ? "year" : "month",
        frndFamAmt: limitToTwoDecimal(fandsFamAmt),
        licsCount: noOfLics,
        planName: selectedPlanData?.planName,
        planPrice: planPrice * (+selectedCurrency?.gbpExchangeRate),
        trialDays: 30,
        couponType: "amount_off",
        amount_off: discountAmt,
        promoName: discountAmt > 0 ? promoCode.toUpperCase() : '',
        SubscriptionPlan: selectedPlanData?.planName,
        SubscriptionPlanId: state.monthlyearly
          ? selectedPlanData?.yearlyPlan.planId
          : selectedPlanData?.monthlyPlan.planId,
        monthyOrYearly: state.monthlyearly ? "Yearly" : "Monthly",
        chargesDetails: chargesDetails,
        selectedCurrency: selectedCurrency,
      };

      localStorage.setItem("cartDetails", JSON.stringify(cartDetails));
      setCartData(cartDetails);

      let trackData3 = {
        step: 3,
        _id: trackId ? trackId : trackIdParam,
        setp_3_Details: cartDetails
      }
      let locdata = location.state;
      if (updatePlan && locdata?.currentPlanData?.length > 0) {
        if (totalSubFee > 0) {
          upgradePlan(cartDetails);
        } else {
          ShowSwal(false, 'Please Select paid plan to upgrade');
        }
      } else {
        dispatch(trackSignup(trackData3));
        setOpenPaymentPage(true);
      }
      // if (totalSubFee !== "0") {
      // } else {
      //   // let id = adminData?._id ? adminData?._id : adminData?._id;
      //   dispatch(trackSignup(trackData3));
      //   registerNewSite(cartDetails);
      // }
      setInvalid(false);
    } else {
      setInvalid(true);
      setStatus("error");
      setTimeout(() => {
        setInvalid(false);
      }, 5000);
    }
  };

  const getPlans = (planIdFromQuery) => {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_SUPERADMIN_BASEURL}/getAllPlans`,
    };
    axios(config).then((res) => {
      let fandsFamPrice =
        res.data.result[0]?.combined?.FandFappData[0]?.unitPrice;
      if (!fandsFamPrice) fandsFamPrice = null;
      setFandsFam(fandsFamPrice || 0);
      setPlansData(res.data.result);
      res.data?.result?.forEach((elem) => {
        if (elem._id === planIdFromQuery) {
          let price = state.monthlyearly
            ? elem.unitPriceYearly
            : elem.unitPriceMonthly;
          setPlanPrice(price);
          setSelectedPlanData(elem);
          // handleCreateAcc(price !== 0 ? true : false);
        }
      });
    }).catch((error) => {
      console.log(error);
    });
  };

  function selectedPlan(id) {
    setSelectedPlanId(id);
    planData.forEach((elem) => {
      if (elem._id === id) {
        let price = state.monthlyearly
          ? elem.unitPriceYearly
          : elem.unitPriceMonthly;
        setPlanPrice(price);
        setSelectedPlanData(elem);
        // handleCreateAcc(price !== 0 ? true : false);
      }
    });
  }

  const applyPromo = async (e) => {
    e.preventDefault();
    setValidatingLoader(true);
    if (promoCode.trim() !== "") {
      if (selectedPlanData) {
        const validateDataString = `couponCode=${promoCode}&planName=${selectedPlanData?.planName}&couponFor=${updatePlan ? "existingUser" : "newUsers"}`
        const res = await httpPayments(`validateCoupon?${validateDataString}`, null);
        if (res.status) {
          const resCouponData = res?.data;
          setPromoDiscount({
            disType: resCouponData?.discountType,
            disVal: resCouponData?.discountValue,
          });
          setIsCouponValidated(true)
          setStatusMsg("Coupon Applied Successfully");
          setStatus("success");
          setInvalid(true);
        } else {
          setStatusMsg("Invalid Coupon");
          setStatus("error");
          setInvalid(true);
        }
        setValidatingLoader(false);
        setTimeout(() => {
          setInvalid(false);
        }, 5000);
      }
    }
  }

  function oldapplypromo(e) {
    planData.forEach((elem) => {
      if (elem._id === selectedPlanId) {
        // let checkInvalidCouponCode = elem.combined.couponData;
        elem.combined.couponData.forEach((item) => {
          if (item.couponCode.toLowerCase() === promoCode.toLowerCase()) {
            setPromoDiscount({
              disType: item.discountType,
              disVal: item.discountValue,
            });
            setStatus("success");
            setInvalid(true);
            setStatusMsg("Coupon Applied Successfully");
          }
        });
        let checkInvalidCouponCode = elem.combined.couponData.some(
          (item) =>
            item.couponCode.toLowerCase() === promoCode.toLowerCase()
        );

        if (!checkInvalidCouponCode) {
          setPromoDiscount({
            disType: "",
            disVal: "0",
          });
          setInvalid(true);
          setStatus("error");
          setStatusMsg("Invalid Coupon");
        }
      }
    });
  }

  function handleAddOn(event) {
    setAddOnStatus({
      ...addOnStatus,
      [event.target.name]: event.target.checked,
    });
    setNoOfLics(event.target.checked ? 1 : 0);
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setInvalid(false);
  };

  return (
    <Fragment>
      {openPaymentPage ? (
        <FormLayout>
          <FinalStepPaymentRedirection cartData={cartData} setOpenPaymentPage={setOpenPaymentPage} />
        </FormLayout>
      ) : (
        <FormLayout>
          <div className="col-md-8 form_box" style={{ display: "block" }}>
            <form>
              <h4> Subscription Plan </h4>
              <div className="mt-3 subscriptionBox">

                <div className="currency_select">
                  <div className="plan_select">
                    <span className="p1" style={{ fontWeight: !bold ? "bold" : "normal", color: "var(--main)" }}>Monthly</span>
                    <GreenSwitch
                      {...label}
                      checked={state.monthlyearly}
                      onChange={handleChange}
                      name="monthlyearly"
                    />
                    <span className="p1" style={{ fontWeight: bold ? "bold" : "normal", color: "var(--main)" }}>Yearly</span>
                    <small className="present ms-4">
                      'Save on yearly plans'
                      {/* Save {planData[0]?.yearlyDiscount}% with the yearly plan */}
                    </small>
                  </div>
                  <SelectSmall fromPage='purchage' />
                </div>

                {planData?.map((plan, index) => {
                  return (
                    <StepsPlanSection
                      key={index}
                      plan={plan}
                      SubscriptionType={state.monthlyearly}
                      selectedPlan={selectedPlan}
                      selectedPlanId={selectedPlanId}
                    />
                  );
                })}

                {fandsFam && (
                  <h5 className="mt-3">
                    <GreenSwitch
                      {...label}
                      checked={addOnStatus.fandFamily}
                      onChange={handleAddOn}
                      name="fandFamily"
                    />
                    <span className="p1">Friends & Family Portal Add-On</span>
                  </h5>
                )}
                {addOnStatus.fandFamily && (
                  <>
                    <h4 className="themeColor mt-3">
                      Friends & Family Portal Add-On{" "}
                      <span className="ms-3">
                        {/* {getSymbolFromCurrency(currency)}{" "} */}
                        {selectedCurrency?.symbol}{" "}
                        {state.monthlyearly
                          ? `${limitToTwoDecimal(fandsFam * 12 * noOfLics * selectedCurrency?.gbpExchangeRate)} /Year`
                          : `${limitToTwoDecimal(fandsFam * noOfLics * selectedCurrency?.gbpExchangeRate)} /Month`}
                      </span>
                    </h4>
                    <p className="text-center ">No. of App User Licenses {noOfLics}</p>
                    <h3 className="text-center">
                      {" "}
                      <div className="slidecontainer align-items-center">
                        <span className="me-2">{noOfLics}</span>{" "}
                        <IOSSlider
                          aria-label="ios slider"
                          value={noOfLics}
                          valueLabelDisplay="off"
                          min={0}
                          max={100}
                          onChange={(e) => setNoOfLics(e.target.value)}
                        />
                        <span className="ms-1">100</span>
                      </div>
                    </h3>
                  </>
                )}

                <p className="mt-3">
                  Charged to your care service and included in your total
                  subscription fee based on per app license @{" "}
                  {selectedCurrency?.symbol}&nbsp;
                  {state.monthlyearly
                    ? `${limitToTwoDecimal(fandsFam * 12 * selectedCurrency?.gbpExchangeRate)} /yearly`
                    : `${limitToTwoDecimal(fandsFam * selectedCurrency?.gbpExchangeRate)} /monthly`}{" "}
                  . For more than 100 licences, please{" "}
                  <Link to="/contact" target="_blank" rel="noopener noreferrer">
                    contact us
                  </Link>{" "}
                  for custom pricing.{" "}
                </p>

                {(planPrice > 0 ||
                  addOnStatus.fandFamily) && (
                    <p className="themeColor mt-3 promotionalCode d-flex justify-content-center">
                      Enter Promotional Code
                      <input
                        type="text"
                        disabled={isCouponValidated}
                        value={promoCode.toUpperCase()}
                        onChange={(e) => setPromoCode(e.target.value)}
                      />
                      <button
                        className="btn btn-sm ms-2 coupen_btn"
                        disabled={isCouponValidated}
                        onClick={(e) => applyPromo(e)}
                        maxLength="10"
                      >
                        {validatingLoader ? <LoadingBtn /> : 'Apply'}
                      </button>
                    </p>
                  )}

                {selectedPlanId === "" && (
                  <div
                    className="alert alert-danger d-flex justify-content-center"
                    role="alert"
                  >
                    <h5>Please Select Plan</h5>
                  </div>
                )}
                {/* <p className="themeColor mt-3 promotionalCode d-flex justify-content-center">
                  30 days free trial included
                </p> */}
                {selectedPlanId !== "" && (
                  <h4 className="themeColor">
                    Total Subscription Fee:
                    <span className="ms-3">
                      {/* {getSymbolFromCurrency(currency)}{" "} */}
                      {selectedCurrency?.symbol}&nbsp;
                      {`${totalSubFee} ${state.monthlyearly ? '/year' : '/month'}`}
                    </span>
                  </h4>
                )}

                {/* <button className="btn updateBtn">Update</button> */}
              </div>
              {/* <NewSubscriptionDesign setNoOfLicences/> */}
              {/* <FormButton isFormValidCheck={isFormValidCheck} /> */}
              <div className="col-md-12 submitBtn">
                <button
                  type="button"
                  className="form-control btn"
                  style={{ display: "inline-block" }}
                  onClick={() => {
                    isFormValidCheck();
                  }}
                  disabled={loading}
                >
                  {loading ? <LoadingBtn /> : buttonText}
                </button>
              </div>
            </form>
            <SnackBar
              isInvalid={inValid}
              status={status}
              textmessage={statusMsg}
              hideDuration={3000}
              handleClose={handleClose}
            />
          </div>
        </FormLayout>
      )}
    </Fragment>
  );
}

const IOSSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.mode === "dark" ? "#C4C4C4" : "#C4C4C4",
  height: 19,
  padding: "15px 0",

  "& .MuiSlider-valueLabel": {
    fontSize: 12,
    fontWeight: "normal",
    top: 46,
    backgroundColor: "unset",
    color: theme.palette.text.primary,
  },
  "& .MuiSlider-track": {
    border: "none",
    backgroundColor: "#C4C4C4",
    height: "14px !important",
  },
  "& .MuiSlider-thumb": {
    height: 18,
    width: 18,
    backgroundColor: "#9B51E0",
  },
  "& .MuiSlider-rail": {
    opacity: 1,
    backgroundColor: "#C4C4C4",
    height: "14px !important",
  },
}));
