import React, { useMemo, useState, useEffect } from "react";
// import CardTitle from "../../components/card-title/card-title.component";
import { Accordion } from "react-bootstrap";
import { Fragment } from "react";
import cx from "./overviewPage.module.css";
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import DataGridTable from "../../utils/DataGridTable";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { IoIosArrowForward } from "react-icons/io";
import { twoDecimalPlaces } from "../../utils/utilsHandlerFunctions";

const columns = [
  { field: "sitename", headerName: "Site Name", flex: 1, minWidth: 150 },
  {
    field: "subscriptionid",
    headerName: "Subscription ID",
    flex: 1,
    minWidth: 120,
  },
  { field: "plantype", headerName: "Plan Type", flex: 1, minWidth: 120 },
  { field: "planid", headerName: "Plan ID", flex: 1, minWidth: 120 },
  { field: "startdate", headerName: "Start Date", flex: 1, minWidth: 120 },
  {
    field: "trialdaysleft",
    headerName: "Trial Days Left",
    flex: 1,
    minWidth: 120,
  },
  { field: "monthlyfees", headerName: "Fees", flex: 1, minWidth: 120 },
  {
    field: "nextbillingdate",
    headerName: "Next Billing Date",
    flex: 1,
    minWidth: 120,
  },
  {
    field: "status",
    headerName: "Status",
    minWidth: 100,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (fieldData) => <StatusBtn fieldData={fieldData} />,
  },
  {
    id: "arrow",
    width: 50,
    numeric: true,
    disablePadding: true,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (fieldData) => <RedirectionIcon fieldData={fieldData} />,
  },
];

const StatusBtn = (props) => {
  const { fieldData } = props;
  const status = fieldData?.row?.status;
  return (
    <button
      className={`btn table_btn btn-${status ? "success" : "danger"} btn-sm`}
    >
      {status ? "Active" : "Inactive"}
    </button>
  );
};

const RedirectionIcon = (props) => {
  const { fieldData } = props;
  const history = useHistory();
  return (
    <IoIosArrowForward
      style={{ cursor: "pointer", fontSize: "20px" }}
      onClick={() => {
        history.push(fieldData.row?.redirectionLink);
      }}
    />
  );
};

export default function OverviewPage({ careSite }) {
  const params = useParams();

  const [clientsData, setClientsData] = useState([]);
  const [rowsData, setRowsData] = useState([]);

  useEffect(() => {
    getAdminsbyOwnerId();
  }, []);

  function getAdminsbyOwnerId() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_SUPERADMIN_BASEURL}/getOwners?ownerId=${params.id}`,
      headers: {
        Authorization: localStorage.getItem("superadmin_token"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        let subscriptionData = [];
        response.data.data[0]?.subscriptionPlanData?.map(
          (subscription, index) => {
            const planstartDate = subscription.planstartDate ?? '';
            const date = new Date(planstartDate);
            subscriptionData.push({
              id: subscription._id,
              sitename: subscription.careSiteName,
              subscriptionid: subscription?.finalId,
              plantype: subscription.SubscriptionPlan,
              planid: subscription.planId,
              startdate: date?.toLocaleString("en-US"),
              trialdaysleft: subscription.trialPeriod,
              monthlyfees: twoDecimalPlaces(subscription.total_amount),
              nextbillingdate:
                "nextBillingDate" in subscription &&
                subscription.nextBillingDate.split("T")[0],
              status: true,
              redirectionLink: `/superadmin/clientdetails/${response.data.data[0]._id}/subscription/${subscription._id}`,
            });
          }
        );
        setRowsData(subscriptionData);
        setClientsData(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const selectedSiteData = useMemo(() => {
    const findCareSiteData = clientsData?.[0]?.subscriptionPlanData?.find(
      (item) => item._id === careSite?.careSiteId
    );
    return findCareSiteData;
  }, [careSite, clientsData]);

  return (
    <Fragment>
      {/* <CardTitle titleName="" /> */}
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>key Contacts</Accordion.Header>
          <Accordion.Body>
            <div className={`col-12 col-md-12 col-lg-12 ${cx.cardBox}`}>
              <Row>
                {clientsData?.map((client, index) => {
                  return (
                    <Col lg={6} className={`${cx.cltDetails}`}>
                      <div key={index} className={`${cx.contractList}`}>
                        <img
                          src={`${process.env.PUBLIC_URL}/images/blog.jpg`}
                        />
                        <div className={`${cx.contentBody}`}>
                          <Row>
                            <Col lg={7}>
                              <h6>{`${client?.firstName?.trim()} ${client?.lastName?.trim()}`}</h6>
                              <p>{client.email}</p>
                            </Col>
                            <Col lg={5}>
                              <button className="btn">{client.userType}</button>
                              <p>{client?.mobileNo}</p>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  );
                })}

                {/* <li className={`${cx.contractList}`}>
                                    <img src={`${process.env.PUBLIC_URL}/images/blog.jpg`} />
                                    <div className={`${cx.contentBody}`}>
                                        <Row>
                                            <Col lg={6}>
                                                <h6>John Ghrisham</h6>
                                                <p>john@orchard.com</p>
                                            </Col>
                                            <Col lg={6}>
                                                <button className="btn">Owner</button>
                                                <p>john@orchard.com</p>
                                            </Col>
                                        </Row>
                                    </div>
                                </li> */}
              </Row>
            </div>
            <div className="row" style={{ justifyContent: "space-between" }}>
              <div className={`col-12 col-md-5 col-lg-5 ${cx.cardBox}`}>
                <h5>
                  Billing Address
                  {/* <NavLink to="#" className={`${cx.editIcon}`}>
                    Edit
                  </NavLink> */}
                </h5>
                <ul>
                  {selectedSiteData?.billingAddress
                    ?.split(",")
                    ?.map((address) => {
                      return <li key={address}>{address}</li>;
                    })}
                </ul>
              </div>
              <div className={`col-12 col-md-5 col-lg-5 ${cx.cardBox}`}>
                <h5>
                  Billing Information
                  {/* <NavLink to="#" className={`${cx.editIcon}`}>
                    Edit
                  </NavLink> */}
                </h5>
                <ul>
                  <li>Currency: {selectedSiteData?.currency}</li>
                  <li>Billing Method: {selectedSiteData?.paymentThrough}</li>
                </ul>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Subscriptions</Accordion.Header>
          <Accordion.Body>
            {/* <Customdatatable rowsData={rowsData} headCells={headCells} /> */}
            <DataGridTable
              columns={columns}
              tableHeight="55vh"
              rows={rowsData}
              handleRowSelection={() => {}}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Fragment>
  );
}
