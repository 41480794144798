import React, { useState, useEffect } from "react";
import "../Medicines/medicines.css";
import AddMedModal from "./AddMedModal";
import MedicineNewTable from "./MedicineNewTable";

export default function Medicines(props) {
    const { searchText, rowsData, clientList, stockData, getClientRowData, medAccess, allMedicines, dmdUpdationDate } = props;

    const [showAddMedModal, setShowAddMedModal] = useState(false);

    function handleShowHideAddMedModal() {
        setShowAddMedModal(!showAddMedModal);
    }

    return (
        <>
            <div className="" style={{ padding: "0px" }}>
                <div className="d-flex col-12 justify-content-center mt-3" >
                    <div className="row col-md-9 m-auto" style={{ transform: "translate(-50px, 0px)" }}>
                        <div className="col-md-7 my-1">
                            <div className="d-flex justify-content-around completedcount">
                                <div
                                    className=" sechudleCard"
                                    style={{ color: "#2D9CDB" }}
                                >
                                    <p>In-Stock </p>
                                    <h5>{stockData.stockTotal}</h5>
                                </div>
                                <div
                                    className=" sechudleCard"
                                    style={{ color: "#EB5757" }}
                                >
                                    <p>Out of Stock</p>
                                    <h5>{stockData.outOfStockTotal}</h5>
                                </div>
                                <div
                                    className=" sechudleCard"
                                    style={{ color: "#F2994A" }}
                                >
                                    <p>Low Stock</p>
                                    <h5>{stockData.lowStockTotal}</h5>
                                </div>
                                <div
                                    className=" sechudleCard"
                                    style={{ color: "#F2C94C" }}
                                >
                                    <p>Ordered</p>
                                    <h5>{stockData.orderedTotal}</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 my-1">
                            <div
                                className="d-flex justify-content-around completedcount"
                                style={{ border: "1px solid #219653" }}
                            >
                                <div
                                    className=" sechudleCard"
                                    style={{ color: "#BB6BD9" }}
                                >
                                    <p>Status (Total)</p>
                                    <h5>{stockData.activeInactiveTotal}</h5>
                                </div>
                                <div
                                    className=" sechudleCard"
                                    style={{ color: "#219653" }}
                                >
                                    <p>Active </p>
                                    <h5>{stockData.active}</h5>
                                </div>
                                <div
                                    className=" sechudleCard"
                                    style={{ color: "#EB5757" }}
                                >
                                    <p>In-Active</p>
                                    <h5>{stockData.inActive}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <MedicineNewTable
                    rows={rowsData}
                    getClientRowData={getClientRowData}
                    handleShowOrHide={handleShowHideAddMedModal}
                    dmdUpdationDate={dmdUpdationDate}
                    searchText={searchText}
                    medAccess={medAccess}
                />
            </div>

            <AddMedModal
                handleShowOrHide={handleShowHideAddMedModal}
                showAddMedModal={showAddMedModal}
                allMedicines={allMedicines}
                clientList={clientList}
                getClientRowData={getClientRowData}
            />

        </>
    )
}
