import { useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { ShowInfoSwal, ShowSwal } from "../../utils/alertSwal";

const SidebarItem = (props) => {
  const {msgToShow} = props;
  let [plantype, setplantype] = useState("");

  setTimeout(() => {
    setplantype(
      JSON?.parse(localStorage?.getItem("userData"))?.SubscriptionPlan
    );
  }, 600);

  const history = useHistory();

  const handleClick = (e) => {
    e.preventDefault();
    if (props.isAccess) {
      history.push(props.links);
    } else {
      const msg = msgToShow === '' ? `This feature is not available in the ${props.currentPlanName} Plan` : msgToShow;
      ShowInfoSwal(msg);
    }
  };

  const showMsgAlert = (props.sidebarName === 'Messaging' && props.msgCount > 0);
  return (
    <li className={`sidebar-item ${plantype}`}>
      <NavLink
        className="sidebar-link waves-effect waves-dark sidebar-link"
        // style={props.isAccess ? {} : customStyle}
        to={props.links}
        aria-expanded="false"
        onClick={handleClick}
      >
        <img alt={props.sidebarName} src={props.src} />
        <span className="hide-menu">{props.sidebarName}</span>
        {showMsgAlert && <span className="count_badge badge badge-success">{props.msgCount}</span>}
      </NavLink>
    </li>
  );
};
export default SidebarItem;
