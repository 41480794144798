import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Scheduler from "./demo/scheduler";
import { AiFillPrinter } from "react-icons/ai";
import JsonDownload from "../../components/DownloadExcel/DownloadExcel";
import { AiOutlineReload } from "react-icons/ai";
import { useDispatch, useSelector } from 'react-redux';
import RotaScheduler from './ScheduerCareFacility/scheduler';
import { getAccessList, handleDataPrint } from '../../utils/CommonFunctions';
import DataGridTable from '../../utils/DataGridTable';
import { filterData } from '../../utils/TableSearchFilter';
import NoAccessMsg from '../../components/NoAccessMsg';
import { getSettingList } from '../../redux-toolkit/reducer/adminSettings';
import { currentCareSiteId } from '../../redux-toolkit/reducer/switchSites';

export default function RotaRoutes(props) {
    const { searchText, rowData, handleTaskReload } = props;
    
    const { topicId } = useParams();
    const dispatch = useDispatch();

    const { settingData } = useSelector((state) => state.adminPanelSetting);
    const { accessRoles, currentCareSiteData } = useSelector((state) => state.caresiteDataReducer);
    const moduleAccess = useMemo(() => {
        const findCurrentModule = accessRoles?.find(item => item.moduleName === 'Scheduler');
        const accesssList = getAccessList(findCurrentModule?.children);
        return accesssList;
    }, [accessRoles]);

    useEffect(async () => {
        dispatch(getSettingList(currentCareSiteId));
      }, []);

    const dowloadData = rowData?.map(item => {
        return {
            clientName: item.clientName,
            RiskProfile: item.RiskProfile,
            Task: item.Task,
            careteamName: item.careteamName,
            date: item.date,
            shiftPeriod: item.shiftPeriod,
            taskTime: item.taskTime,
            timeDone: item.timeDone,
            taskStatus: item.taskStatus
        }
    });

    const columns = [
        { field: "clientName", headerName: "Name", flex: 1, minWidth: 150 },
        {
            field: "RiskProfile", headerName: "Risk Profile", minWidth: 100,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (fieldData) => <RiskProfileBtn fieldData={fieldData} />
        },
        {
            field: "TaskType", headerName: "Task Type", minWidth: 90,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (fieldData) => <TaskTypeImg fieldData={fieldData} />
        },
        { field: "Task", headerName: "Task", flex: 1, minWidth: 120 },
        { field: "careteamName", headerName: "Care Team", flex: 1, minWidth: 120 },
        { field: "date", headerName: "Date", flex: 1, minWidth: 120 },
        { field: "shiftPeriod", headerName: "Shift Period", flex: 1, minWidth: 120 },
        { field: "taskTime", headerName: "Task Time", minWidth: 90 },
        { field: "timeDone", headerName: "Time Done", minWidth: 90 },
        {
            field: "taskStatus", headerName: "Status", minWidth: 120,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (fieldData) => <StatusBtn fieldData={fieldData} />
        },
    ];

    const filteredData = filterData(
        columns?.map((item) => ({
            id: item.field,
            label: item.headerName,
        })),
        rowData,
        searchText
    );

    return (
        <>
            {topicId === "schedule" && <>
                {(moduleAccess?.Scheduler === 'full' || moduleAccess?.Scheduler === 'view') ?
                    <div className="mt-2">
                        {(currentCareSiteData?.carefacility === 'facalities' &&
                            <RotaScheduler
                                SchedulerAccess={moduleAccess?.Scheduler}
                                text={searchText}
                                siteTimeZone={settingData?.timeZone}
                            />)
                        }
                        {(currentCareSiteData?.carefacility === 'agency' &&
                            <Scheduler
                                SchedulerAccess={moduleAccess?.Scheduler}
                                text={searchText}
                                siteTimeZone={settingData?.timeZone}
                            />)
                        }
                    </div> : <NoAccessMsg />}
            </>}
            {topicId === "task-status" && <>
                {(moduleAccess?.['Task Status'] === 'full' || moduleAccess?.['Task Status'] === 'view') ?
                    <div
                        className="ag-theme-alpine cts_datatable"
                        style={{ height: "100%", paddingTop: 10 }}
                    >
                        <div className="col-md-2 ps-3" >
                            <AiFillPrinter
                                className="me-3 ms-1 curser_point"
                                onClick={() => handleDataPrint(dowloadData)}
                            />
                            <a className="curser_point me-3">
                                <JsonDownload
                                    fileName="shifttasks-excel"
                                    downloadfile={dowloadData}
                                />
                            </a>
                            <AiOutlineReload className='curser_point' onClick={() => handleTaskReload(Math.random())} />
                        </div>
                        <DataGridTable columns={columns} tableHeight='70vh' rows={filteredData} handleRowSelection={() => { }} />
                    </div> : <NoAccessMsg />}
            </>}
        </>
    )
}

const TaskTypeImg = (props) => {
    let { fieldData } = props;
    let taskTypeImg = fieldData?.row?.TaskType;
    return (
        <img
            alt=""
            // className="profile_img_table"
            src={taskTypeImg}
        />
    );
};

const StatusBtn = (props) => {
    let { fieldData } = props;
    return (
        <button
            className="btn btn-sm table_btn"
            style={{ backgroundColor: fieldData?.row?.bgColor }}
        >
            {fieldData?.row?.taskStatus}
        </button>
    );
};
const RiskProfileBtn = (props) => {
    let { fieldData } = props;
    let riskImgObj = riskArray.find(item => item.risk === fieldData?.row?.RiskProfile?.toLowerCase());
    return (
        <>
            {riskImgObj ? <img src={riskImgObj.img} /> : <span>-</span>}
        </>
    );
};

const riskArray = [
    { risk: 'high', img: '/images/HR.svg' },
    { risk: 'medium', img: '/images/MR.svg' },
    { risk: 'low', img: '/images/LR.svg' },
];