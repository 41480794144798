import React, { useState, useEffect } from "react";
import "./clients.css";
import axios from "axios";
import Moment from "react-moment";
import { Fragment } from "react";
import { MdArrowRight } from "react-icons/md";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { combineDateTime, convertToTimeZone, getFullName, getMondayDate, getMonthAgoDate } from "../../utils/CommonFunctions";
import { sortByDateDescend } from "../../Apis/commonApis";
import { currentCareSiteId } from "../../redux-toolkit/reducer/switchSites";
import { useSelector } from "react-redux";
import JournalDetailFacility from "./JournalDetailFacility";
import JournalDetailAgency from "./JournalDetailAgency";
let today = new Date();
let monthAgoDate = getMonthAgoDate();

const ClientJournalAsApp = (props) => {
  const { clientId } = props;

  // let params = useParams();
  const { currentCareSiteData } = useSelector((state) => state.caresiteDataReducer);

  const [journalData, setJournalData] = useState([]);
  const [clientJournal, setClientJournal] = useState({});
  const [loading, setLoading] = useState(false);

  const [filterType, setFilterType] = useState("custom");
  const [dateRange, setDateRange] = useState([monthAgoDate, new Date()]);
  const [startDate, endDate] = dateRange;

  useEffect(() => {
    if (currentCareSiteData) {
      if (filterType === "custom" && endDate) {
        getJournalbyClientId(startDate, endDate);
      } else if (filterType === "week") {
        let mondayDate = getMondayDate();
        getJournalbyClientId(mondayDate, today);
      } else if (filterType === "today") {
        let sendDate = today.toLocaleDateString();
        getJournalbyClientId(sendDate, today);
      }
    }
  }, [filterType, dateRange, currentCareSiteData]);

  const getJournalbyClientId = (start, end) => {
    setLoading(true);
    axios({
      // url: `${process.env.REACT_APP_BASEURL}/journal?clientId=${clientId}`,
      url: `${process.env.REACT_APP_BASEURL}/journal?clientId=${clientId}&careHomeId=${currentCareSiteId}&startDate=${start}&endDate=${end}`,
      method: "GET",
      headers: { Authorization: localStorage.getItem("care_admin_token") },
    }).then((res) => {
      setLoading(false);
      const ClientData = res?.data?.result;
      if (ClientData.length > 0) {
        if (currentCareSiteData?.carefacility === "facalities") {
          handleFacilityData(ClientData);
        } else if (currentCareSiteData?.carefacility === "agency") {
          handleAgencyData(ClientData);
        }
      } else {
        setJournalData([]);
        setClientJournal({});
      }
    }).catch((error) => console.log(`Error: ${error}`));
  };

  function handleFacilityData(ClientData) {
    let clientJournals = [];
    ClientData?.forEach((shift) => {
      if (shift) {
        const shiftdata = shift?.assignedCareTeam[0];
        const forClientName = shiftdata?.forClient ?? getFullName(shiftdata?.clientId);
        if (shiftdata) {
          clientJournals.push({
            _shiftId: shift?._id,
            _id: shiftdata?._id,
            shiftDateTime: combineDateTime(shift.Date, shift.startingTime, shift.timeZone),
            Date: shiftdata?.Date ?? shift.Date,
            endDate: shiftdata?.endDate ?? shift?.endDate,
            Task: shiftdata?.Task,
            notesData: shift?.notesData,
            forClient: forClientName,
            startingTime: convertToTimeZone(shift.shiftStartDatetime),
            endingTime: convertToTimeZone(shift?.shiftEndDatetime),
            shiftTimeZone: shiftdata.timeZone,
            careTeamId: shiftdata?.careTeamId,
            checkinDateTime: shiftdata?.checkinDateTime,
            checkoutDateTime: shiftdata?.checkoutDateTime,
            fndfRating: shiftdata?.fandfRating ?? 0,
            clientId: shiftdata?.clientId,
            shiftApprovalstatus: shiftdata?.shiftApprovalstatus ?? '',
            shiftEndDatetime: shiftdata?.shiftEndDatetime ?? '',
            status: shiftdata?.status ?? shift?.status,
            templateType: shiftdata?.templateType ?? '',
            wellbeingAssessment: shiftdata?.wellbeingAssessment ?? {},
            shiftdata: shiftdata,
          });
        }
      }
    });

    let sortedObj = sortByDateDescend(clientJournals, 'shiftDateTime')
    let groupdate = groupJournalDataByDate(sortedObj);
    // let groupdate = groupItems(sortedObj, "Date");
    // groupdate = Object.entries(groupdate).map(([k, v]) => ({ [k]: v }));
    setJournalData(groupdate ?? []);
    setClientJournal(sortedObj[0] ?? {});
  }

  function handleAgencyData(ClientData) {
    let clientJournals = [];
    ClientData?.forEach((shift) => {
      if (shift) {
        const shiftdata = shift?.shiftData;
        const forClientName = shiftdata?.forClient ?? getFullName(shiftdata?.clientId);
        if (shiftdata) {
          clientJournals.push({
            _shiftId: shiftdata?._id,
            _id: shiftdata?._id,
            Date: shiftdata?.Date ?? shift.Date,
            shiftDateTime: combineDateTime(shiftdata.Date, shiftdata.startingTime, shiftdata.timeZone),
            endDate: shiftdata?.endDate ?? shift?.endDate,
            Task: shiftdata?.Task,
            notesData: shiftdata.notesData,
            forClient: forClientName,
            startingTime: shiftdata?.startingTime ?? shift?.startingTime,
            endingTime: shiftdata?.endingTime ?? shift?.endingTime,
            shiftTimeZone: shiftdata.timeZone,
            careTeamId: shiftdata?.careTeamId[0],
            checkinDateTime: shiftdata?.checkinDateTime,
            checkoutDateTime: shiftdata?.checkoutDateTime,
            fndfRating: shiftdata?.fandfRating ?? 0,
            clientId: shiftdata?.clientId,
            shiftApprovalstatus: shiftdata?.shiftApprovalstatus ?? '',
            shiftEndDatetime: shiftdata?.shiftEndDatetime ?? '',
            status: shiftdata?.status ?? shift?.status,
            templateType: shiftdata?.templateType ?? '',
            wellbeingAssessment: shiftdata?.wellbeingAssessment ?? {},
          });
        }
      }
    });

    let sortedObj = sortByDateDescend(clientJournals, 'shiftDateTime')
    let groupdate = groupJournalDataByDate(sortedObj);
    // let groupdate = groupItems(sortedObj, "Date");
    // groupdate = Object.entries(groupdate).map(([k, v]) => ({ [k]: v }));
    setJournalData(groupdate ?? []);
    setClientJournal(sortedObj[0] ?? {});

  }

  const calendarStrings = {
    lastDay: "[Yesterday ]",
    sameDay: "[Today ]",
    nextDay: "[Tomorrow ]",
    lastWeek: "L",
    nextWeek: "L",
    sameElse: "L",
  };

  function customDateChangeHandler(daterange) {
    setDateRange(daterange);
  }

  const activeTab = {
    background: "#f0e8f7",
  };

  return (
    <>
      <div className="row clientList">
        <div className="col-md-6 shiftsHistory">
          <h5 style={{ color: "#9B51E0" }}>Shifts History</h5>
          <ul className="filter_box me-2 mb-1">
            <button
              exact
              className={filterType === "today" ? "active" : ""}
              onClick={() => setFilterType("today")}
            >
              Today
            </button>
            <button
              className={filterType === "week" ? "active " : ""}
              onClick={() => setFilterType("week")}
            >
              This Week
            </button>
            <button
              className={filterType === "custom" ? "active" : ""}
              onClick={() => setFilterType("custom")}
            >
              Custom
            </button>
            {filterType === "custom" ? (
              <div className="datepicker_input">
                <DatePicker
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    customDateChangeHandler(update);
                  }}
                  isClearable={true}
                />
              </div>
            ) : (
              <div className="container">&ensp;</div>
            )}
          </ul>
        </div>
        <div className="col-md-6"></div>
        <div className="col-md-6 journalListScroll">
          <ul className="menuList">
            {loading && <p>Loading...</p>}

            {journalData.length > 0 &&
              journalData?.map((journal) => {
                // const date = Object.keys(journal);
                // const datewisejournal = Object.values(journal).flatMap(
                //   (num) => num
                // );
                return (
                  <Fragment>
                    <h5 className="titlen">
                      <span style={{ color: "#BB6BD9" }}>
                        <Moment calendar={calendarStrings}>{journal.date}</Moment>
                      </span>{" "}
                      <br />
                      <Moment format="Do MMM YYYY">{journal.date}</Moment>
                    </h5>
                    {journal?.shifts?.map((item, index) => {
                      return (
                        <li
                          style={
                            item._id === clientJournal._id ? activeTab : {}
                          }
                          key={index}
                          onClick={() => {
                            setClientJournal(item);
                          }}
                        >
                          <a>
                            {/* <img alt="" src={item?.careTeamId[0]?.image} /> */}
                            <div className="body">
                              <p style={{ color: "#BB6BD9" }}>
                                {item?.startingTime} - {item?.endingTime}
                              </p>
                              <h5> Shift Summary</h5>
                              <MdArrowRight className="arrowIcon" />
                            </div>
                          </a>
                        </li>
                      );
                    })}
                  </Fragment>
                );
              })}
            {loading === false && journalData.length === 0 && (
              <p>No Shift Available</p>
            )}
          </ul>
        </div>
        {Object.keys(clientJournal).length !== 0 && (
          <>
            {(currentCareSiteData?.carefacility === "facalities") &&
              <JournalDetailFacility clientJournal={clientJournal} />
            }
            {(currentCareSiteData?.carefacility === "agency") &&
              <JournalDetailAgency clientJournal={clientJournal} />
            }
          </>
        )}
      </div>
    </>
  );
};

export default ClientJournalAsApp;

function groupJournalDataByDate(shiftdata) {
  if (shiftdata.length < 1) {
    return [];
  }
  const grouped = {};
  shiftdata?.forEach((item) => {
    const dateLabel = new Date(item.Date).toDateString();

    if (!grouped[dateLabel]) {
      grouped[dateLabel] = [];
    }
    grouped[dateLabel].push(item);
  });

  return Object.keys(grouped).map((date) => ({
    date,
    shifts: grouped[date],
  }));
}