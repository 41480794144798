import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetch3, fetch4 } from "../../Apis/commonApis";

export let ownerId;
export let trackId;
export let loginOwnerId;
export let authProcess = "login";
export let adminId;
export let securitysuccessfullMsg;

const initialState = {
  loading: false,
  accountListCount: 0,
  id: 1,
  step: "step1",
  buttonText: "Next",
  data: {},
  ownerId: null,
  step1Data: null,
  stepNo: 1,
  isSubmit: false,
  isValid: false,
  errorMsg: "",
  registerSuccess: 0,
  verifySuccessLogin: 0,
  loginVerifyEmail: 0,
  signupVerification: false,
  adminTokenFromAuth: ''
};

export const accountList = createAsyncThunk("accountList", async () => {
  const result = await fetch3(
    `${process.env.REACT_APP_SUPERADMIN_BASEURL}/getPlans`,
    "get"
  );
  return result;
});

export const trackSignup = createAsyncThunk("trackSignup", async (body) => {
  const result = await fetch4(
    `${process.env.REACT_APP_BASEURL}/tracksignup`,
    body
  );
  trackId = result?.data._id;
  return result;
});

export const authenticateOwnerSignup = createAsyncThunk(
  "authenticateOwnerSignup",
  async (body) => {
    const response = await fetch3(
      `${process.env.REACT_APP_BASEURL}/authenticateOwnerSignup?ownerId=${body.loginOwnerId}&authProcess=${body.authProcess}`,
      "get"
    );
    try {
      if (response.status && body.authProcess === "signup") {

        localStorage.setItem("careAdminToken", response.token);
        localStorage.setItem(
          "userRegisterData",
          JSON.stringify(response.result)
        );
        return response;
      } else if (response.status && body.authProcess === "password") {
        securitysuccessfullMsg = response.message;

        return response;
      } else if (response.status && body.authProcess === "securityQuestions") {
        securitysuccessfullMsg = response.message;
        return response;
      } else {
        localStorage.removeItem("careAdminToken");
        localStorage.removeItem("userRegisterData");
        securitysuccessfullMsg = response.message;
        if (response.status) {
          localStorage.setItem(
            "currentCareSiteId",
            response.result.defaultCareSiteId
          );
          response.result.currentCareSiteId = response.result.defaultCareSiteId;
          localStorage.setItem("userData", JSON.stringify(response.result));
          // localStorage.setItem("carehomeId", response.result.defaultCareSiteId);
        }
      }
    } catch (error) {
      alert("error: " + error);
    }
    return response;
  }
);

const manageAccountCreation = createSlice({
  name: "manageAccountCreation",
  initialState,
  reducers: {
    changeButton(state = initialState, action) {
      state.buttonText = action.payload.buttonText;
      state.stepNo = action.payload.stepNo;
      state.step = "step" + state.stepNo;
    },
    getownerIdbeforeLogin(state = initialState, action) {
      ownerId = action.payload;
      state.ownerId = action.payload;
    },
    handleSteps(state = initialState, action) {
      state.errorMsg = action.payload.errorMsg;
      state.registerSuccess = action.payload.registerSuccess;
    },

    handleEmailVerifyFromLogin(state = initialState, action) {
      state.verifySuccessLogin = action.payload.verifySuccessLogin;
      ownerId = action.payload.ownerId;
      state.ownerId = action.payload.ownerId;
      state.loginVerifyEmail = 0;
      state.signupVerification = action.payload.signupVerification;
    },

    getOwnerId(state = initialState, action) {
      state.loginVerifyEmail = action.payload.loginVerifyEmail;
      authProcess = action.payload.authProcess;
      loginOwnerId = action.payload.loginOwnerId;
    },
    getOwnerIdAfterLogin(state = initialState, action) {
      state.loginVerifyEmail = action.payload.loginVerifyEmail;
      authProcess = action.payload.authProcess;
      loginOwnerId = action.payload.loginOwnerId;
    },

    nextStepFuncFirstForm(state = initialState, action) {
      state.step1Data = action.payload;
    },
  },
  extraReducers: {
    [accountList.fulfilled]: (state, { payload: { error, message } }) => {
      state.loading = false;
      state.accountListCount += 1;
    },

    [authenticateOwnerSignup.fulfilled]: (state, { payload }) => {
      let { error, message, status } = payload;
      if (status) {
        console.log(payload, 'payload of authenticationnnnnnnnn')
        if (payload.authProcess !== 'signup') {
          localStorage.setItem("care_admin_token", payload?.token);
          localStorage.setItem("userData", JSON.stringify(payload.result));
        }
        state.adminTokenFromAuth = payload?.token;
        state.registerSuccess = 2;
        state.verifySuccessLogin = 2;
        state.ownerId = payload?.result?._id;
      } else {
        state.errorMsg = message;
        state.registerSuccess = 1;
        state.verifySuccessLogin = 1;
      }
      if (
        message.includes("User successfully authenticated") &&
        !state.signupVerification
      ) {
        state.loginVerifyEmail = 2;
        // loginOwnerId = undefined;
      }
      if (
        message.includes("User successfully authenticated") &&
        !state.signupVerification
      ) {
        state.loginVerifyEmail = 2;
        // loginOwnerId = undefined;
      }
    },
  },
});

export const {
  changeButton,
  nextStepFuncFirstForm,
  handleSteps,
  handleEmailVerifyFromLogin,
  getownerIdbeforeLogin,
  getOwnerId,
  getOwnerIdAfterLogin,
} = manageAccountCreation.actions;
export default manageAccountCreation.reducer;
