import React from "react";

function Footer() {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  return (
    <footer className="footer text-center m-0">
      © 2021 - {currentYear} Auriga Magnus Care Limited. All Rights Reserved.
    </footer>
  );
}

export default Footer;