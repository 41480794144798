import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetch2, fetch3, fetch4 } from "../../Apis/commonApis";
//security_question / approval_email / false

export let getSettingListItems;

const initialState = {
  loading: false,
  getSettingListCount: 0,
  settingData: null,
};

export const getSettingList = createAsyncThunk(
  "getSettingList",
  async (careHomeId) => {
    const result = await fetch3(
      `${process.env.REACT_APP_BASEURL}/getSetting?careHomeId=${careHomeId}`,
      "get"
    );
    getSettingListItems = result.data;
    return result;
  }
);

const adminPanelSetting = createSlice({
  name: "adminPanelSetting",
  initialState,
  reducers: {
    getOwnerId(state = initialState, action) {},
  },
  extraReducers: {
    [getSettingList.fulfilled]: (state, { payload }) => {
      // console.log("getsettings:", state, message, error);
      state.loading = false;
      state.settingData = payload.data;
      state.getSettingListCount += 1;
    },
  },
});

export const { getOwnerId } = adminPanelSetting.actions;
export default adminPanelSetting.reducer;
