import React, { useState } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import "../ForgotPassword/forgot-password.css";
import { httpAdmin } from "../../Apis/commonApis";
import swal from "sweetalert";
import useInput from "../../hooks/use-input";
import SnackBar from "../../components/SnackBar/SnackBar";
import useQuery from "../../hooks/useQuery";

const isPasswordValidate = (value) => value.length >= 6;

function ResetPassword() {
  let query = useQuery();
  let history = useHistory();
  const location = useLocation();
  console.log(location);

  const [inValid, setInvalid] = useState(false);
  const [status, setStatus] = useState("info");
  const [statusMsg, setStatusMsg] = useState("All Fields are required");
  const {
    value: passwordValue,
    isValid: passwordIsValid,
    hasError: passwordHasError,
    valueChangeHandler: passwordChangeHandler,
    inputBlurHandler: passwordBlurHandler,
  } = useInput(isPasswordValidate);
  const {
    value: confirmPasswordValue,
    isValid: confirmPasswordIsValid,
    hasError: confirmPasswordHasError,
    valueChangeHandler: confirmPasswordChangeHandler,
    inputBlurHandler: confirmPasswordBlurHandler,
  } = useInput(isPasswordValidate);

  const passwordClasses = passwordHasError
    ? "form-control invalid"
    : "form-control";

  const confirmPasswordClasses = confirmPasswordHasError
    ? "form-control invalid"
    : "form-control";

  const isFormValidCheck = async () => {
    if (passwordIsValid && confirmPasswordIsValid) {
      setInvalid(false);
      let data = {
        resetType: "password",
        new_password: passwordValue,
        confirm_password: confirmPasswordValue,
      };

      if (location.pathname.includes("ff")) {
        data["panel"] = "fandf";
      } else if (location.pathname.includes("app")) {
        data["panel"] = "careteam";
      }

      const result = await httpAdmin(
        `reset_password_from_email/${query.get("id")}`,
        "post",
        data,
        null
      );

      if (result.status) {
        swal("Success", result.message, "success").then((value) => {
          history.replace("/login");
        });
      } else {
        setInvalid(true);
        setStatus("error");
        setStatusMsg("Passwords do not match.");
      }
    } else {
      setInvalid(true);
      setStatus("error");
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setInvalid(false);
  };

  return (
    <section className="login_section">
      <div className="login_header">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 logo_box">
              <NavLink className="navbar-brand" to="">
                <b className="logo-icon">
                  <img alt="" src={`/images/logo.svg`} />
                </b>
              </NavLink>
            </div>
            <div className="col-md-9">
              <div className="login_title">
                <h1>Care Management Simplified!</h1>
                <p>
                  Welcome to your new experience of care management. Our
                  powerful, easy to use <br/> and intuitive care platform, enables you
                  to easily manage all you care tasks.
                </p>
              </div>
            </div>
            <div className="col-md-3 img_box">
              <img alt="" src={`/images/login.svg`} />
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 form_box">
        <form>
          <h4>New Password</h4>
          <input
            type="password"
            id="password"
            value={passwordValue}
            onChange={passwordChangeHandler}
            onBlur={passwordBlurHandler}
            className={passwordClasses}
            placeholder="Password"
          />
          {passwordHasError && (
            <span className="input_error">Please enter at least 6 digits</span>
          )}

          <input
            type="password"
            id="confirmPassword"
            value={confirmPasswordValue}
            onChange={confirmPasswordChangeHandler}
            onBlur={confirmPasswordBlurHandler}
            className={confirmPasswordClasses}
            placeholder="Confirm Password"
          />
          {confirmPasswordHasError && (
            <span className="input_error">Please enter at least 6 digits</span>
          )}
          <button
            type="button"
            className="form-control btn"
            onClick={isFormValidCheck}
          >
            Save
          </button>
        </form>
        <div className="option_box">
          <p>
            Already have an account?{" "}
            <NavLink to="/login" className="">
              Log in
            </NavLink>
          </p>
        </div>
      </div>
      <SnackBar
        isInvalid={inValid}
        status={status}
        hideDuration={4000}
        textmessage={statusMsg}
        handleClose={handleClose}
      />
    </section>
  );
}

export default ResetPassword;
