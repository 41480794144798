import React, { useState, useEffect, useRef, useMemo } from "react";
import { MdSend } from "react-icons/md";
import {
  arrayUnion,
  doc,
  serverTimestamp,
  Timestamp,
  updateDoc,
  setDoc,
  getDoc,
  onSnapshot,
} from "firebase/firestore";
import { db, storage } from "../Firebase";
import moment from "moment";
import { HiDownload } from "react-icons/hi";
// import { Modal, Dropdown, DropdownButton } from "react-bootstrap";
// import { AiOutlineMore } from "react-icons/ai";
import jsPDF from "jspdf";
import { useSelector } from "react-redux";

const chatUserImg = '/images/chatUser.svg'

export default function Chats(props) {

  const { selectedChat, uid, userDetails, currentUserId, chatHeadList, chatUserList } = props;

  const { accessRoles } = useSelector((state) => state.caresiteDataReducer);
  const moduleAccess = useMemo(() => {
    const findCurrentModule = accessRoles?.find(item => item.moduleName === 'Messaging');
    return findCurrentModule;
  }, [accessRoles]);

  const chatId = useMemo(() => {
    return selectedChat.type === "admin" ? generateChatId(selectedChat.id, currentUserId) : selectedChat.id;
  }, [selectedChat, currentUserId]);

  const [text, setText] = useState("");
  const [img, setImg] = useState(null);

  const [messages, setMessages] = useState([]);
  const [allUserIds, setAllUserIds] = useState([]);

  const ref = useRef();

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  useEffect(() => {
    if (chatUserList) {
      let allIds = [];
      let allAdminIds = chatUserList?.filter(items => items.type === 'admin')?.map(item => item.id);
      if (selectedChat.type === 'fandsfam' || selectedChat.type === 'careteam') {
        allIds = [selectedChat.id, ...allAdminIds]
      } else if (selectedChat.type === 'admin') {
        allIds = [selectedChat.id, currentUserId]
      } else {
        allIds = allAdminIds;
      }
      setAllUserIds(allIds);
      handleSelect(allIds);
    }
  }, [selectedChat, chatUserList]);

  useEffect(() => {
    setMessages([]);
    const getChatId = selectedChat.type === "admin" ? generateChatId(selectedChat.id, currentUserId) : selectedChat.id;
    const unSub = onSnapshot(
      doc(db, "chats", uid, "chatUsers", getChatId),
      (doc) => {
        doc.exists() && setMessages(doc.data().messages);
      }
    );
    return () => {
      unSub();
    };
  }, [selectedChat]);

  const handleSend = async () => {
    if (text.trim() === "" && moduleAccess?.access !== 'full') {
      return;
    }
    let sendTxt = text;
    setText("");
    if (img) {
      //   const storageRef = ref(storage, uuid());
      //   const uploadTask = uploadBytesResumable(storageRef, img);
      //   uploadTask.on(
      //     (error) => {
      //       //TODO:Handle Error
      //     },
      //     () => {
      //       getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
      //         await updateDoc(doc(db, "chats", data.chatId), {
      //           messages: arrayUnion({
      //             id: uuid(),
      //             text,
      //             senderId: currentUser.uid,
      //             date: Timestamp.now(),
      //             img: downloadURL,
      //           }),
      //         });
      //       });
      //     }
      //   );
    } else {
      await updateDoc(doc(db, "chats", uid, "chatUsers", chatId), {
        messages: arrayUnion({
          id: Date.now(),
          text: sendTxt,
          senderId: currentUserId,
          senderName: userDetails.name,
          msgAlert: 1,
          userType: 'admin',
          senderImg: userDetails.image ?? '',
          date: Timestamp.now(),
        }),
      });
    }
    setImg(null);

    let chatHead = chatHeadList.filter(item => item.id === chatId);

    let counterMap = [];
    for (let i = 0; i < allUserIds.length; i++) {
      if (allUserIds[i] === currentUserId) {
        counterMap.push({ id: allUserIds[i], counter: 0 });
      } else {
        let counterObj = chatHead[0]?.counterMap?.filter(counter => counter.id === allUserIds[i]);
        counterMap.push({
          id: allUserIds[i], counter: (counterObj && counterObj.length > 0)
            ? counterObj[0].counter + 1 : 1
        });
      }
    }

    await updateDoc(doc(db, "chatHeads", uid, "chatUsers", chatId), {
      id: chatId,
      lastMessage: sendTxt,
      date: Date.now(),
      senderId: currentUserId,
      receiverId: selectedChat.id,
      counterMap: counterMap
    });
  };

  const handleSelect = async (allUIds) => {
    try {
      const res = await getDoc(doc(db, "chats", uid, "chatUsers", chatId));
      if (!res.exists()) {
        console.log("chatt????????????");
        await setDoc(doc(db, "chats", uid, "chatUsers", chatId), {
          messages: [],
        });
      }
      let chathead = chatHeadList.find(item => item.id === chatId);
      if (!chathead) {
        let counterMap = [];
        for (let i = 0; i < allUIds.length; i++) {
          counterMap.push({ id: allUIds[i], counter: 0 });
        }
        await setDoc(doc(db, "chatHeads", uid, "chatUsers", chatId), {
          id: chatId,
          lastMessage: "",
          counterMap: counterMap
        });
      } else {
        let counterMap = chathead.counterMap;
        const targetObject = counterMap?.find(item => item.id === currentUserId);
        const updatedCouterArray = counterMap.map(item => {
          if (item.id === currentUserId) {
            return { ...item, counter: 0 };
          }
          return item;
        });
        if (targetObject?.counter > 0) {
          await updateDoc(doc(db, "chatHeads", uid, "chatUsers", chatId), { counterMap: updatedCouterArray });
        }
      }
    } catch (err) { }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSend();
    }
  };

  function generateChatId(user1Id, user2Id) {
    const sortedUserIds = [user1Id, user2Id].sort(); // Sort user IDs alphabetically
    return sortedUserIds.join("_");
  }

  const downloadChatHistory = () => {
    const pdf = new jsPDF();
    pdf.text("Chat History", 10, 10);
    messages.forEach((m, index) => {
      pdf.text(`${m.senderName}: ${m.text}`, 10, 20 + index * 10);
    });
    pdf.save("chat-history.pdf");
  };

  const groupedMessages = useMemo(() => {
    return groupMessagesByDate(messages);
  }, [messages]);

  return (
    <div className="col-md-7 chat_content_box">
      <div className="mobile_back d-none">
        <button className="btn chatback_btn">
          <i className="fas fa-long-arrow-alt-left"></i>
        </button>
      </div>
      <div className="user_box online">
        <img
          className={selectedChat.img === "" ? `forImage${selectedChat.type}` : ''}
          alt=""
          src={selectedChat.photoURL ?? chatUserImg}
        />
        <div className="user_box_body">
          <h4>{selectedChat.displayName}</h4>
          {/* <small><i className="fas fa-circle"></i> {selectedChat.role}</small> */}
        </div>
        {
          <div className="d-flex">
            <HiDownload className="moreIcon" onClick={downloadChatHistory} />
          </div>
        }
      </div>

      <div className="userchat_body">

        {groupedMessages.map((group) => (
          <div key={group.date}>
            <div className="chat_old_messsage_date">
              <span>{group.date}</span>
            </div>
            {group.messages?.map((m) =>{
              const imageIcon = m.senderImg ?? chatUserImg;
              return (currentUserId === m.senderId ? (
                <div ref={ref} key={m.id} className={`chat_message chat_right`}>
                  <p>
                    <strong>Me</strong><br />
                    {m.text}
                    <time>{moment(m.id).format('HH:mm')}</time>
                  </p>
                  <img className={imageIcon.includes('chatUser') ? `forImage${m.userType}` : ''}
                    alt="" src={imageIcon} />
                </div>
              ) : (
                <div ref={ref} key={m.id} className="chat_message chat_left">
                  <img className={imageIcon?.includes('chatUser') ? `forImage${m.userType}` : ''}
                    alt="" src={imageIcon} />
                  <p>
                    <strong>{m.senderName}</strong><br />
                    {m.text}
                    <time>{moment(m.id).format("HH:mm")}</time>
                  </p>
                </div>
              ))
            }
            )}
          </div>
        ))}
      </div>

      <div className="chatfooter_submit">
        <div className="input-group user_submit_chat">
          <input
            type="text"
            className="form-control"
            placeholder="Type a message"
            value={text}
            onChange={(e) => {
              setText(e.target.value);
            }}
            onKeyDown={handleKeyPress}
          />
          <div className="input-group-append">
            <MdSend onClick={handleSend} />
          </div>
        </div>
      </div>
    </div>
  );
}

function groupMessagesByDate(messages) {
  if (!messages || messages?.length < 1) {
    return [];
  }
  const grouped = {};
  const currentDate = new Date();
  const today = currentDate.toDateString();
  const yesterdayDate = new Date(currentDate);
  yesterdayDate.setDate(currentDate.getDate() - 1);
  const yesterday = yesterdayDate.toDateString();

  messages.forEach((message) => {
    const messageDate = new Date(message.date.toDate()).toDateString();
    let dateLabel = messageDate;

    if (messageDate === today) {
      dateLabel = 'Today';
    } else if (messageDate === yesterday) {
      dateLabel = 'Yesterday';
    }

    if (!grouped[dateLabel]) {
      grouped[dateLabel] = [];
    }
    grouped[dateLabel].push(message);
  });

  return Object.keys(grouped).map((date) => ({
    date,
    messages: grouped[date],
  }));
}