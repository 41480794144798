import React, { useEffect, useState } from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";
import axios from "axios";
import "../Login/login.css";
import swal from "sweetalert";
import { securityQuestions } from "../../constants/constants";
import useInput from "../../hooks/use-input";
import Select from "react-select";

const isEmpty = (value) => value.trim() !== "";

function SecurityQuestion() {
  //security questions
  let history = useHistory();
  const params = useParams();
  const [question, setQuestion] = useState("");

  const {
    value: securityAnswerValue,
    isValid: securityAnswerIsValid,
    hasError: securityAnswerHasError,
    valueChangeHandler: securityAnswerChangeHandler,
    inputBlurHandler: securityAnswerBlurHandler,
  } = useInput(isEmpty);

  const securityAnswerClasses = securityAnswerHasError
    ? "form-control invalid"
    : "form-control";

  const isFormValidCheck = () => {
    if (securityAnswerIsValid && question !== "") {
    }
  };

  function checkSecurityQuestions() {
    let data = JSON.stringify({
      userId: params.id,
      panel: "admin",
      question: question,
      answer: securityAnswerValue,
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/checkSecurityQuestions`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.status === true) {
          swal("Success", response.data.message, "success").then((value) => {
            localStorage.setItem("care_admin_token", response.data.token);
            localStorage.setItem(
              "currentCareSiteId",
              response.data.data.defaultCareSiteId
            );
            localStorage.setItem(
              "userData",
              JSON.stringify(response.data.data)
            );
            history.push("/admin/dashboard");
          });
        } else {
          swal("failed", response.data.message, "error").then((value) => {
            return;
          });
        }
      })
      .catch(function (error) {
        console.log(error, "error");
      });
  }

  function selectQuestion(event) {
    setQuestion(event.label);
  }

  return (
    <section className="login_section">
      <div className="login_header">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 logo_box">
              <NavLink className="navbar-brand" to="/admin/dashboard">
                <b className="logo-icon">
                  <img alt="" src={`/images/logo.svg`} />
                </b>
              </NavLink>
            </div>
            <div className="col-md-9">
              <div className="login_title">
                <h1>Care Management Simplified!</h1>
                <p>
                  Welcome to your new experience of care management. Our
                  powerful, easy to use <br/> and intuitive care platform, enables you
                  to easily manage all you care tasks.
                </p>
              </div>
            </div>
            <div className="col-md-3 img_box">
              <img alt="" src={`/images/login.svg`} />
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 form_box">
        <form>
          <h4>Security Questions</h4>
          <Select
            options={securityQuestions}
            onChange={selectQuestion}
            required={true}
          />
          {question !== "" && (
            <input
              style={{ marginTop: "20px" }}
              type="text"
              id="securityAnswer"
              value={securityAnswerValue}
              onChange={securityAnswerChangeHandler}
              onBlur={securityAnswerBlurHandler}
              className={securityAnswerClasses}
              placeholder="Security Answer"
            />
          )}{" "}
          {securityAnswerHasError && (
            <span className="input_error">Please Enter Security Answer</span>
          )}
          <NavLink to="/admin/forgot-securityquestion" className="forgot">
            Forgot Security Question?
          </NavLink>
          <button
            type="button"
            onClick={checkSecurityQuestions}
            className="form-control btn"
          >
            Log in
          </button>
        </form>
      </div>
    </section>
  );
}

export default SecurityQuestion;
