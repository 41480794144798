import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { httpAdmin, httpPayments } from '../../Apis/commonApis';
import LinearProgress from '@mui/material/LinearProgress';
import { currentCareSiteId } from '../../redux-toolkit/reducer/switchSites';
import { ShowSwal } from '../../utils/alertSwal';

export default function UpdateBillingSuccess() {
    const history = useHistory();
    const params = useParams();

    const [adminData, setAdminData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showMsg, setShowMsg] = useState('Updating... !');


    useEffect(() => {
        console.log(params, 'params')
        getAdminData();
    }, []);

    useEffect(() => {
        let strtPayApiData = JSON.parse(localStorage.getItem("startPayApiData"));
        if (!strtPayApiData) {
            history.replace('/admin/profile/billing');
        }
        if (adminData && strtPayApiData) {
            let currentDate = new Date();
            let nextMonthDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate());
            const subscriptionData = adminData.subscriptionPlanData?.find(item => item._id === currentCareSiteId);

            const billingRequestData = {
                careHomeId: params.csid ?? subscriptionData?._id,
                adminId: params.ownerId,
                reason: "change_billing_method",
                redirectFlowId: strtPayApiData.data.id,
                session_token: strtPayApiData.data.session_token,
                amount: subscriptionData.total_amount,
                planName: subscriptionData.SubscriptionPlan,
                frequency: subscriptionData.monthyOrYearly.toLowerCase(),
                currency: 'GBP',
                paymentStartDate: nextMonthDate.toJSON().split('T')[0],
                billingEmail: subscriptionData?.billingEmail
            };
            completeRedirctFlow(billingRequestData);
        }
    }, [adminData]);


    const getAdminData = async () => {
        setLoading(true);
        const res = await httpPayments(`getAdmin?adminId=${params.ownerId}`, null);
        if (res.status) setAdminData(res.result[0]);
    }

    const completeRedirctFlow = async (billingRequestData) => {
        const res = await httpPayments(`completeRedirctFlow`, billingRequestData);
        if (res.status) {
            changeBillingMethod(res, billingRequestData?.careHomeId);
        } else {
            setLoading(false);
            ShowSwal(false, res.message ?? 'Something went wrong!');
            setShowMsg(res.message ?? 'Something went wrong!');
        }
    }

    const changeBillingMethod = async (completeRedirctFlowData, csid) => {
        // console.log(paymentDetails, 'paument details');
        let body = {
            careHomeId: csid,
            adminId: adminData._id,
            newPaymentThrough: "gocardless",
            reason: "change_billing_method",
            newSubscriptionDetails: {
                goCardLess_mandate_id: completeRedirctFlowData.data.links.mandate,
                goCardLess_customer_id: completeRedirctFlowData.data.links.customer,
                goCardLess_BankAccount_id: completeRedirctFlowData.data.links.customer_bank_account,
                goCardLess_subscription_id: completeRedirctFlowData.subscription.id,
            }
        }
        const res = await httpAdmin('changeBillingMethod', 'post', body, null);
        setLoading(false);
        if (res.status) {
            localStorage.removeItem('startPayApiData');
            setShowMsg('Successfully Updated');
            setTimeout(() => {
                history.replace('/admin/profile/billing');
            }, 2000);
        } else {
            setShowMsg(res.message);
            ShowSwal(false, res.message ?? 'Something went wrong!');
        }
    }
    return (
        <div className="col-md-6" style={{ paddingTop: "60px", margin: "auto", height: "425px" }}>
            <h2 className="themeColor d-flex justify-content-center">
                {showMsg}
            </h2>
            {loading && <>
                <LinearProgress />
                <div className=" contentCreate d-flex justify-content-center">
                    <p>Please wait.</p>
                </div>
            </>}
        </div>
    )
}
