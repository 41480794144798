import React, { useState, useEffect, Fragment } from "react";
import Moment from "react-moment";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Accordion from "react-bootstrap/Accordion";
import { taskImages } from "../../constants/roles";
import { FaStar } from "react-icons/fa";
import { BiSolidDownArrow } from "react-icons/bi";
import { convertTimeToUserTimeZone, convertToTimeZone, getFullName } from "../../utils/CommonFunctions";
import cx from '../Clients/clients.module.css'
import { wCategories } from "../../constants/constants";

const ratingArr = [
    { star: 1 },
    { star: 2 },
    { star: 3 },
    { star: 4 },
    { star: 5 },
];

const JournalDetailFacility = (props) => {
    let { careteamJournal } = props;

    const [notesData, setNotesData] = useState([]);

    useEffect(() => {
        if (careteamJournal.notesData) {
            const filteredNotes = careteamJournal.notesData?.filter((item) =>
                (item?.careTeamId === careteamJournal.AssignedCareTeam[0]?.careTeamId?._id && item.shiftId === careteamJournal._shiftId));
            setNotesData(filteredNotes);
        }
    }, [careteamJournal]);

    return (
        <>
            <div className="col-md-6 tabbingMenu journalListScroll">
                {careteamJournal?.clientId?.length > 0 && (
                    <ul className="menuList">
                        <li>
                            <a>
                                {/* <img
                                    alt="careteam-profile"
                                    src={careteamJournal?.clientId[0]?.image}
                                /> */}
                                <div className="body">
                                    <p>
                                        {" "}
                                        {careteamJournal?.startingTime} -{" "}
                                        {careteamJournal?.endingTime}{" "}
                                    </p>
                                    <h5>Shift Summary{" "}
                                    </h5>
                                    <p className="timeBox">
                                        <span className="ms-3">
                                            <Moment format="Do MMM YYYY">
                                                {careteamJournal?.Date}
                                            </Moment>
                                        </span>{" "}
                                    </p>
                                </div>
                            </a>
                        </li>
                    </ul>
                )}
                <div className="starList">
                    {ratingArr.map((item, index) => {
                        return (
                            <button
                                key={index}
                                className={index < careteamJournal.fndfRating ? "active" : ""}
                            >
                                <FaStar />
                            </button>
                        );
                    })}
                </div>
                <Tab.Container id="left-tabs-example" defaultActiveKey="taB1">
                    <Nav variant="pills">
                        <Nav.Item>
                            <Nav.Link eventKey="taB1">Tasks</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="taB2">Care Notes</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="taB3">Family Notes</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey="taB1">
                            <ul className={`TasksList ${cx.tasklist}`}>
                                <p style={{ color: "#BB6BD9" }}>
                                    {careteamJournal.completedTasks} of{" "}
                                    {careteamJournal.totalTask} Tasks Completed
                                </p>

                                {careteamJournal.AssignedCareTeam?.map((shift) => {
                                    return (
                                        <div key={shift._id}>
                                            {(shift?.wellbeingAssessment?.shiftId === careteamJournal._shiftId
                                                && shift?.wellbeingAssessment?.status === "true"
                                                && shift?.wellbeingAssessmentDoneBy === shift.careTeamId?._id) && (
                                                    <Fragment>
                                                        <li>
                                                            <div className="listRow">
                                                                <img src="/images/s9.svg" />
                                                                Wellbeing Assessment
                                                                <img src={shift?.wellbeingAssessment?.status === "true" ? "/images/checkedIco.svg" : "/images/blankCheck.svg"} className="boxIcons" />
                                                            </div>
                                                        </li>
                                                        <Accordion className="colorIcon">
                                                            <Accordion.Item eventKey="0">
                                                                <Accordion.Header style={{ borderBottom: "none" }}>
                                                                    <BiSolidDownArrow />
                                                                </Accordion.Header>
                                                                <Accordion.Body>
                                                                    <ul>
                                                                        {Object.entries(shift?.wellbeingAssessment)?.map((item, index) => {
                                                                            const [wcategory, rating] = item;
                                                                            const ratingCount = [1, 2, 3, 4, 5, 6];
                                                                            const displayCategory = wCategories.find(cats => cats.wCat === wcategory)?.displayName;
                                                                            if (index >= 7) return;
                                                                            return (
                                                                                <li key={index}>
                                                                                    <label>{displayCategory}</label>
                                                                                    <div>
                                                                                        {ratingCount.map((count) => (
                                                                                            <button key={count}>
                                                                                                <img
                                                                                                    className={
                                                                                                        rating === count
                                                                                                            ? "wellbineColor"
                                                                                                            : ""
                                                                                                    }
                                                                                                    src={`/images/is${count}.svg`}
                                                                                                />
                                                                                            </button>
                                                                                        ))}
                                                                                    </div>
                                                                                </li>
                                                                            );
                                                                        })}
                                                                    </ul>
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                        </Accordion>
                                                        <p className={`teamNameInNotes`}>{getFullName(shift?.clientId)} ({convertToTimeZone(shift?.wellbeingAssessment?.timestamp)})</p>
                                                    </Fragment>
                                                )}
                                            {shift?.Task?.map((task, index) => {
                                                const conditionsToShowMed = task?.medName && task?.prn?.toLowerCase() === "no";
                                                const conditionsToShowPrn = task?.medName && task?.prn?.toLowerCase() === "yes";

                                                const showTask = ((task?.status === "omitted" || task?.status === "true")
                                                    && task.shiftId === careteamJournal._shiftId
                                                    && task?.doneBy === shift.careTeamId?._id);

                                                if (conditionsToShowMed && showTask) {
                                                    return (
                                                        <li key={index}>
                                                            <div className="listRow justify-content-between">
                                                                <p>
                                                                    <img alt="dd" src={`/images/s10.svg`} />
                                                                    Medication Reminder
                                                                </p>
                                                                {task.startTime !== '' && <p className={`teamNameInNotes me-5`}>
                                                                    {convertTimeToUserTimeZone(task.startTime, careteamJournal.shiftTimeZone)}
                                                                </p>}
                                                                <img src={(task?.status === "true" ? "/images/checkedIco.svg" : "/images/crossIco.svg")} className="boxIcons" />
                                                            </div>
                                                            <p>{task?.NM}</p>
                                                            <p>
                                                                Dose: {task?.doseAmount} {task?.UDFS_UOMCD}
                                                            </p>
                                                            <p>Meal Consideration: {task?.mealConsideration}</p>
                                                            {Object.keys(task).includes("comments") &&
                                                                task?.comments && (
                                                                    <p>Comments: {task?.comments}</p>
                                                                )}{" "}
                                                            <p className={`teamNameInNotes`}>{getFullName(shift?.clientId)} ({convertToTimeZone(task?.timestamp)})</p>
                                                        </li>
                                                    );
                                                }
                                                if (conditionsToShowPrn && showTask) {
                                                    return (
                                                        <li key={index}>
                                                            <div className="listRow justify-content-between">
                                                                <p>
                                                                    <img alt="rr" src={`/images/s6.svg`} />
                                                                    PRN Med
                                                                </p>
                                                                <img src={(task?.status === "true" ? "/images/checkedIco.svg" : "/images/crossIco.svg")} className="boxIcons" />
                                                            </div>
                                                            <p>{task?.NM}</p>
                                                            <p>
                                                                Dose: {task?.doseAmount} {task?.UDFS_UOMCD}
                                                            </p>
                                                            <p>Meal Consideration: {task?.mealConsideration}</p>
                                                            {Object.keys(task).includes("comments") &&
                                                                task?.comments && <p>Comments: {task?.comments}</p>}
                                                            <p className={`teamNameInNotes`}>{getFullName(shift?.clientId)} ({convertToTimeZone(task?.timestamp)})</p>
                                                        </li>
                                                    );
                                                }
                                                const taskImgObj = taskImages.find(elem => task?.taskName?.includes(elem?.Ctg));
                                                let mesuredValue = '';
                                                if (task.taskType === "BP") {
                                                    mesuredValue = task.input ? `: ${task.input?.Systolic_BP?.measureValue}/${task.input?.Diastolic_BP?.measureValue} ${task.input?.Diastolic_BP?.measureType}` : '';
                                                } else {
                                                    mesuredValue = (task.input && task.input?.measureValue !== '') ? `: ${task.input?.measureValue} ${task.input?.measureType}` : '';
                                                }
                                                return (
                                                    <>
                                                        {showTask && <li key={index}>
                                                            <div className="listRow justify-content-between">
                                                                <p>
                                                                    <img alt="icon" src={taskImgObj.image} />
                                                                    {task?.taskName}
                                                                </p>
                                                                {task.startTime !== '' && <p className={`teamNameInNotes me-5`}>
                                                                    {convertTimeToUserTimeZone(task.startTime, careteamJournal.shiftTimeZone)}
                                                                </p>}
                                                                <img src={task?.status === "omitted" ? "/images/crossIco.svg" : "/images/checkedIco.svg"} className="boxIcons" />
                                                            </div>
                                                            <p>{task?.taskType} {mesuredValue}</p>
                                                            <p>Comments: {task?.comments}</p>
                                                            {(task?.reason && task?.status === "omitted") && <p>
                                                                {`Reason: ${task?.reason}`}
                                                            </p>}
                                                            <p className={`teamNameInNotes`}>{getFullName(shift?.clientId)} ({convertToTimeZone(task?.timestamp)})</p>
                                                        </li>}
                                                    </>
                                                );
                                            })}
                                        </div>
                                    )
                                })}
                            </ul>
                        </Tab.Pane>
                        <Tab.Pane eventKey="taB2">
                            {notesData?.filter((item) => item?.notesType === "careteammember")?.map(elem => {
                                return (<div className="rightTextTabs" key={elem._id}>
                                    {elem.media && <img src={elem.media} />}
                                    <p>{elem.notesData}</p>
                                    <p>{getFullName(elem.clientId)} ({convertToTimeZone(elem.createdAt)})</p>
                                </div>)
                            })}
                        </Tab.Pane>
                        <Tab.Pane eventKey="taB3">
                            {notesData?.filter((item) => item?.notesType === "family")?.map(elem => {
                                return (<div className="rightTextTabs" key={elem._id}>
                                    {elem.media && <img src={elem.media} />}
                                    <p>{elem.notesData}</p>
                                    <p>{getFullName(elem.clientId)} ({convertToTimeZone(elem.createdAt)})</p>
                                </div>)
                            })}
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </>
    );
};

export default JournalDetailFacility;
