import React, { useEffect, useState, useRef, useMemo } from "react";
import cx from "./invoices.module.css";
import { Dropdown } from "react-bootstrap";
import { Col, Row } from "react-bootstrap";
import styles from "./invoices.module.css";
import { httpSuperAdmin } from "../../Apis/commonApis";
import swal from "sweetalert";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { twoDecimalPlaces } from "../../utils/utilsHandlerFunctions";
import { formatDate } from "../../utils/CommonFunctions";

export default function InvoiceDetail(props) {
  const { setShowInvoiceDetails, invoiceDetail } = props;

  const divRef = useRef(null);

  let editableData = {
    invoiceId: invoiceDetail?.invoiceId,
    fromName: invoiceDetail?.fromName,
    fromAddress: invoiceDetail?.fromAddress,
    fromEmail: invoiceDetail?.fromEmail,
    toName: invoiceDetail?.toName,
    toAddress: invoiceDetail?.toAddress,
    toEmail: invoiceDetail?.toEmail,
    toContactNo: invoiceDetail?.toContactNo,
  };

  const [editMode, setEditMode] = useState(true);
  const [editDetails, setEditDetails] = useState(editableData);

  useEffect(() => {
    getsetting();
  }, []);

  const saveEditDetailHandler = async () => {
    const result = await httpSuperAdmin(`editInvoice`, editDetails);
    swal("Success!", result.message, "Success");
  };

  const sendInvoiceToClient = async () => {
    let invoiceId = { invoiceId: [invoiceDetail.invoiceId] };
    const result = await httpSuperAdmin(`createInvoiceAndSend`, invoiceId);
    swal("Success!", result.message, "Success");
  };

  // const sendInvoiceToClient = async () => {
  //     let invoiceId = { invoiceId: invoiceDetail.id }
  //     const result = await httpSuperAdmin(`sendInvoice`, invoiceId);
  //     alert(result.message);
  // }

  const getsetting = async () => {
    const result = await httpSuperAdmin(`getSetting`);
    if (result.status) {
      setEditDetails({
        ...editDetails,
        fromName: result?.data?.company?.companyName,
        fromAddress: result?.data?.company?.address,
        fromEmail: result?.data?.company?.email,
      });
    }
  };

  const generatePDF = async () => {
    if (divRef.current) {
      const content = divRef.current;
      const canvas = await html2canvas(content);

      const pdf = new jsPDF("p", "mm", "a4", true);

      const imgData = canvas.toDataURL("image/png");
      pdf.addImage(imgData, "jpg", 10, 10, 180, 0);

      pdf.save("my-document.pdf");
    }
  };

  function editInvoiceHandler() {
    setEditMode(false);
  }


  const amtData = useMemo(() => {
    console.log(invoiceDetail, 'iiiiiiiii');
    const chargeData = invoiceDetail?.chargesDetails;
    const cargeDetailAmt = (chargeData?.[0]?.rate ? ((+chargeData?.[0]?.rate) * (+chargeData?.[0]?.qty)) : 0) + (chargeData?.[1]?.rate ? ((+chargeData?.[1]?.rate) * (+chargeData?.[1]?.qty)) : 0) - (chargeData?.[2]?.rate ? ((+chargeData?.[2]?.rate) * (+chargeData?.[2]?.qty)) : 0);
    const subTotalAmt = invoiceDetail?.isManualInvoice ? cargeDetailAmt : (+invoiceDetail.amountDue - +invoiceDetail.totalTax);
    const totaltax = invoiceDetail?.isManualInvoice ? ((invoiceDetail?.taxRate / 100) * subTotalAmt) : invoiceDetail.totalTax;
    const totalAmt = invoiceDetail?.isManualInvoice ? invoiceDetail?.total : invoiceDetail.amountDue;
    return { subTotalAmt, totaltax, totalAmt };
  }, [invoiceDetail]);

  return (
    <div className="container-fluid min_height">
      <div className="card">
        <h4 className="card-title ms-4">
          Invoice Details
          <div
            className={`float-end justify-content-between align-items-center d-flex just me-5`}
          >
            <a
              href="#"
              className={
                invoiceDetail.isManualInvoice
                  ? styles.invoiceBack
                  : styles.hideAction
              }
              onClick={() => setShowInvoiceDetails(false)}
            >
              Back
            </a>
            <Dropdown>
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                className={styles.btnHead}
              >
                Action
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {invoiceDetail.isManualInvoice && (
                  <Dropdown.Item onClick={editInvoiceHandler}>
                    Edit
                  </Dropdown.Item>
                )}
                {invoiceDetail.isManualInvoice && (
                  <Dropdown.Item onClick={saveEditDetailHandler}>
                    Save{" "}
                  </Dropdown.Item>
                )}
                <Dropdown.Item onClick={sendInvoiceToClient}>
                  Send to client
                </Dropdown.Item>
                <Dropdown.Item onClick={generatePDF}>Download</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </h4>
        <div className="card-body" ref={divRef}>
          <Row>
            <Col lg={10} className={`${cx.invoiceBody}`}>
              <Row style={{ justifyContent: "space-between" }}>
                <h6 className={`${cx.fweight} text-center font_SizeInvoice`}>
                  Invoice
                </h6>
                <Col lg={6} md={6} className={`${cx.left}`}>
                  <h6 className={`${cx.fweight} text-dark mb-4`}>
                    Date: {formatDate(invoiceDetail?.invoiceDate)}
                  </h6>
                  <input
                    type="text"
                    value={editDetails?.fromName}
                    readOnly={editMode}
                    onChange={(e) =>
                      setEditDetails({
                        ...editDetails,
                        fromName: e.target.value,
                      })
                    }
                    className="form-control input"
                    placeholder="Your Company Name"
                  />
                  <textarea
                    className="form-control input"
                    value={editDetails?.fromAddress}
                    readOnly={editMode}
                    onChange={(e) =>
                      setEditDetails({
                        ...editDetails,
                        fromAddress: e.target.value,
                      })
                    }
                    placeholder="Your Company Address"
                  ></textarea>
                  <input
                    type="email"
                    value={editDetails?.fromEmail}
                    readOnly={editMode}
                    onChange={(e) =>
                      setEditDetails({
                        ...editDetails,
                        fromEmail: e.target.value,
                      })
                    }
                    className="form-control input"
                    placeholder="Your Company Email"
                  />
                  {/* <input
                    type="number"
                    value={invoiceDetail?.fromContact}
                    readOnly={editMode}
                    onChange={(e) =>
                      setEditDetails({
                        ...editDetails,
                        fromContact: e.target.value,
                      })
                    }
                    className="form-control input"
                    placeholder="Your Company Telephone"
                  /> */}
                </Col>

                <Col lg={6} md={6} className={`${cx.right}`}>
                  <h6
                    className={`d-flex justify-content-end ${cx.fweight} text-dark mb-4`}
                  >
                    To
                  </h6>
                  <input
                    type="text"
                    value={editDetails?.toName}
                    readOnly={editMode}
                    onChange={(e) =>
                      setEditDetails({ ...editDetails, toName: e.target.value })
                    }
                    className="form-control input"
                    placeholder="Recipent Name"
                  />
                  <textarea
                    className="form-control input"
                    value={editDetails?.toAddress}
                    readOnly={editMode}
                    onChange={(e) =>
                      setEditDetails({
                        ...editDetails,
                        toAddress: e.target.value,
                      })
                    }
                    placeholder="Recipent Address"
                  ></textarea>
                  <input
                    type="email"
                    value={editDetails?.toEmail}
                    readOnly={editMode}
                    onChange={(e) =>
                      setEditDetails({
                        ...editDetails,
                        toEmail: e.target.value,
                      })
                    }
                    className="form-control input"
                    placeholder="Recipent Email"
                  />
                  <input
                    type="number"
                    value={editDetails?.toContactNo}
                    readOnly={editMode}
                    onChange={(e) =>
                      setEditDetails({
                        ...editDetails,
                        toContactNo: e.target.value,
                      })
                    }
                    className="form-control input"
                    placeholder="Recipent Telephone"
                  />
                </Col>
              </Row>
              <Row className="justify-content-between">
                <Col lg={6} md={6} className={`${cx.left}`}>
                  <ul>
                    <li>
                      <span className={`${cx.fweight} text-dark`}>
                        Invoice Period
                      </span>
                    </li>
                    <li>
                      <span>{invoiceDetail?.invoicePeriod}</span>
                    </li>
                  </ul>
                </Col>
                <Col lg={6} md={6} className={`${cx.right}`}>
                  <ul>
                    <li>
                      <span className={`${cx.fweight} text-dark`}>
                        Invoice No.
                      </span>
                      <span className={`${cx.fweight} text-dark`}>
                        Invoice Date
                      </span>
                    </li>
                    <li>
                      <span>{invoiceDetail?.invoiceNo}</span>
                      <span>{formatDate(invoiceDetail?.invoiceDate)}</span>
                    </li>
                    <li>
                      <span></span>
                      <span className={`${cx.fweight} text-dark`}>
                        Invoice Deadline
                      </span>
                    </li>
                    <li>
                      <span></span>
                      <span>
                        {formatDate(invoiceDetail?.invoiceDeadline)}
                      </span>
                    </li>
                  </ul>
                </Col>
              </Row>
              <Row className="mt-5">
                <div className="col-md-6">
                  <label className="text-dark mb-2">Description </label>
                </div>
                <div className="col-md-2">
                  <label className="text-dark mb-2">Rate</label>
                </div>
                <div className="col-md-2">
                  <label className="text-dark mb-2">Qty </label>
                </div>
                <div className="col-md-2">
                  <label className="text-dark mb-2">Amount </label>
                </div>
              </Row>

              {invoiceDetail?.chargesDetails?.map((item, index) => {
                return (
                  <Row key={index}>
                    <div className="col-md-6">
                      <input
                        type="text"
                        value={item?.discription}
                        readOnly
                        className={`form-control mb-3 ${cx.invoice_desc_border}`}
                        placeholder=""
                      />
                    </div>
                    <div className="col-md-2">
                      <input
                        type="text"
                        value={twoDecimalPlaces(item?.rate)}
                        readOnly
                        className={`form-control mb-3 ${cx.invoice_desc_border}`}
                        placeholder=""
                      />
                    </div>
                    <div className="col-md-2">
                      <input
                        type="text"
                        value={item.qty}
                        readOnly
                        className={`form-control mb-3 ${cx.invoice_desc_border}`}
                        placeholder=""
                      />
                    </div>
                    <div className="col-md-2">
                      <input
                        type="text"
                        value={twoDecimalPlaces(item?.rate * item.qty)}
                        readOnly
                        className={`form-control mb-3 ${cx.invoice_desc_border}`}
                        placeholder=""
                      />
                    </div>
                  </Row>
                );
              })}
              <Row>
                <div className="col-md-8"></div>
                <div className="col-md-4">
                  <table className="table">
                    <thead>
                      <tr>
                        <th style={{ color: "#222", verticalAlign: "middle", minWidth: '107px', textAlign: 'end' }}>SubTotal</th>
                        <th style={{ color: "#222" }} className="text-end pe-0">
                          <input
                            type="number"
                            value={twoDecimalPlaces(amtData?.subTotalAmt)}
                            disabled
                            className="form-control"
                          />
                        </th>
                      </tr>
                      <tr>
                        <th style={{ color: "#222", verticalAlign: "middle", minWidth: '107px', textAlign: 'end' }}>Total Tax</th>
                        <th style={{ color: "#222" }} className="text-end pe-0">
                          <input
                            type="number"
                            placeholder="0"
                            className="form-control"
                            value={twoDecimalPlaces(amtData?.totaltax)}
                            disabled
                          />
                        </th>
                      </tr>
                      <tr>
                        <th style={{ color: "#222", verticalAlign: "middle", minWidth: '107px', textAlign: 'end' }}>Total</th>
                        <th style={{ color: "#222" }} className="text-end pe-0">
                          <input
                            className="form-control"
                            type="number"
                            value={twoDecimalPlaces(amtData?.totalAmt)}
                            disabled
                          />
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
