import React, { Component } from "react";
import Scheduler, { SchedulerData, ViewTypes } from "react-big-scheduler";
import WithDragDropContext from "./withDnDContext";
import "./demo.css";
import axios from "axios";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import Button from "antd/lib/button";
import "react-big-scheduler/lib/css/style.css";
import CreateShiftTask from "../CreateShiftTask";
import EditCreateShiftTask from "../EditCreateShiftTask";
import FromTemplate from "../FromTemplate";
import { Link } from "react-router-dom";
import GenericShiftcreation from "../GenericShiftcreation";
import ClientSideshift from "../ClientSideshift";
import "moment/locale/en-gb";
import { convertTimeToUserTimeZone, getFullName, getWeekStartAndEndDates, handleDataPrint, isDateTimeGreaterThanCurrent } from "../../../utils/CommonFunctions";
import { currentCareSiteId } from "../../../redux-toolkit/reducer/switchSites";
import { httpAdmin } from "../../../Apis/commonApis";
import { ShowSwal } from "../../../utils/alertSwal";
import { AiFillPrinter } from "react-icons/ai";
import JsonDownload from "../../../components/DownloadExcel/DownloadExcel";
import swal from "sweetalert";

let weekDates = getWeekStartAndEndDates(new Date());

class Basic extends Component {
  constructor(props) {
    super(props);
    //let schedulerData = new SchedulerData(new moment("2017-12-18").format(DATE_FORMAT), ViewTypes.Week);
    this.schedulerData = new SchedulerData(
      new Date(),
      ViewTypes.Week,
      false,
      false,
      {
        schedulerWidth: "88%",
        besidesWidth: 0,
        defaultEventBgColor: "#808080",
        resourceName: "Name",
        movable: true,
        creatable: true,
        crossResourceMove: false,
        eventItemPopoverEnabled: true,
        calendarPopoverEnabled: true,
        dayResourceTableWidth: 180,
        weekResourceTableWidth: 180,
        eventItemHeight: 35,
        eventItemLineHeight: 40,
        schedulerMaxHeight: 380,
        selectedAreaColor: "#808080",
        recurringEventsEnabled: true,
        headerEnabled: true,
        displayWeekend: true,
        nonWorkingTimeHeadColor: "grey",
        nonWorkingTimeHeadBgColor: "white",
        nonWorkingTimeBodyBgColor: "white",
        groupOnlySlotColor: "#808080.",
        startResizable: true,
        customCellWidth: 80,
        dayCellWidth: "5%",
        endResizable: true,
        weekCellWidth: "12%",
        views: [
          {
            viewName: "Day",
            viewType: ViewTypes.Day,
            showAgenda: false,
            isEventPerspective: false,
          },
          {
            viewName: "Week",
            viewType: ViewTypes.Week,
            showAgenda: false,
            isEventPerspective: false,
          },
          {
            viewName: "Month",
            viewType: ViewTypes.Month,
            showAgenda: false,
            isEventPerspective: false,
          },
        ],
      }
    );

    this.schedulerData.localeMoment.locale("en-gb");

    // let EditShiftData = []
    this.state = {
      viewModel: this.schedulerData,
      shownShiftTaskUI: false,
      showEditShiftTaskUI: false,
      showClientEditShiftTaskUI: false,
      formtemplate: false,
      isDisplayed: false,
      genericTem: false,
      clientSide: false,
      resourceArr: [],
      EventsArr: [],
      downloadData: [],
      SchedulerAccess: props.SchedulerAccess,
      EditShiftData: [],
      EditclientShiftData: [],
      intervalId: '',
      siteTimeZone: this.props.siteTimeZone
    };
  }

  componentDidMount() {
    const intervalId = setInterval(() => {
      this.getShiftsandtaskNew('refresh');
    }, 60000);
    this.getShiftsandtaskNew('refresh');
    this.setState(() => ({ intervalId: intervalId }));
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.text !== this.props.text) {
      let filteredVal = this.state?.resourceArr?.filter((elem) => {
        return elem?.name.toLowerCase().includes(this.props.text.toLowerCase());
      });

      this.schedulerData.setResources(filteredVal);
      this.forceUpdate(); // Force a re-render of the component
    }
    if (prevProps.siteTimeZone !== this.props.siteTimeZone) {
      this.setState((prevState) => ({
        ...prevState,
        siteTimeZone: this.props.siteTimeZone,
      }));
      this.forceUpdate(); // Force a re-render of the component
    }
  }

  getShiftsandtaskNew = async (type) => {
    const start = this.state.viewModel.startDate;
    const end = this.state.viewModel.endDate;
    if (type === 'closePopup') {
      this.setState((prevState) => ({ shownShiftTaskUI: false, showEditShiftTaskUI: false }));
    }
    let res = await httpAdmin(`getShiftsandtaskNew?careHomeId=${currentCareSiteId}&startDate=${start}&endDate=${end}`, 'get', null, null);
    if (res.status) {
      let shiftData = res?.shiftData;
      let CareTeamData;
      const resource = [];
      const events = [];
      const downloadArr = [];
      console.log(shiftData, 'shiftTaskItem999999999999')
      shiftData?.forEach((client) => {
        const id = client?._id ? client?._id : "";
        const name = getFullName(client);
        //  `${client?.first_Name} ${client?.last_Name}`;
        const image = client?.image;
        CareTeamData = client?.shiftDetails;
        CareTeamData?.forEach((element) => {
          const shiftTaskItem = element?.shiftData;
          if (shiftTaskItem?.templateType === "") {
            let bgColor = "";
            if (shiftTaskItem?.recurringShiftType === "open") {
              bgColor = "purple";
            } else if (shiftTaskItem?.recurringShiftType === "unassigned") {
              bgColor = "#F2994A";
            } else if (shiftTaskItem?.status == "pending") {
              bgColor = "#898988";
            } else if (shiftTaskItem?.status == "In-Process") {
              bgColor = "#007fff";
            } else if (shiftTaskItem?.status == "overdue") {
              bgColor = "#EB5757";
            } else if (shiftTaskItem?.status == "Done") {
              bgColor = "#219653";
            } else {
              bgColor = "#898988";
            }

            if (shiftTaskItem?.recurringShiftType === "assigned") {
              events.push({
                shiftId: element?._id,
                ShiftData: shiftTaskItem,
                editShiftId: element?._id,
                evdata: shiftTaskItem?.careTeamMember,
                evImage: Array.isArray(shiftTaskItem?.careTeamId)
                  ? shiftTaskItem?.careTeamId[0]?.image
                  : shiftTaskItem?.careTeamId?.image,
                id: shiftTaskItem._id,
                start: shiftTaskItem?.shiftStartDatetime ?? `${shiftTaskItem.Date} ${shiftTaskItem.startingTime}`,
                taskList: shiftTaskItem?.Task,
                end: shiftTaskItem?.shiftEndDatetime ?? `${shiftTaskItem.Date} ${shiftTaskItem.endingTime}`,
                resourceId: id,
                title: getFullName(shiftTaskItem?.careTeamId?.[0]),
                bgColor: bgColor,
              });
            } else {
              events.push({
                shiftId: element._id,
                ShiftData: shiftTaskItem,
                evdata: shiftTaskItem?.recurringShiftType,
                id: shiftTaskItem._id,
                start: shiftTaskItem?.shiftStartDatetime ?? `${shiftTaskItem.Date} ${shiftTaskItem.startingTime}`,
                taskList: shiftTaskItem?.Task,
                end: shiftTaskItem?.shiftEndDatetime ?? `${shiftTaskItem.Date} ${shiftTaskItem.endingTime}`,
                resourceId: id,
                title: shiftTaskItem?.recurringShiftType,
                bgColor: bgColor,
              });
            }
            // Client Name, Date, Shift Start Time, Shift End Time, Shift Hrs (hrs, minutes).
            downloadArr.push({
              ['Client Name']: name,
              ['Date']: shiftTaskItem.Date,
              ['Shift Start Time']: shiftTaskItem.startingTime,
              ['Shift End Time']: shiftTaskItem.endingTime,
            });
          }
        });
        resource.push({ id: id, name: name, image: image });
      });

      this.schedulerData.setResources(resource);
      this.schedulerData.setEvents(events);
      this.setState((prevState) => ({
        ...prevState,
        EventsArr: events,
        resourceArr: resource,
        downloadData: downloadArr,
        // shownShiftTaskUI: false,
        // showEditShiftTaskUI: false,
      }));
    }
  };

  toggleShowHide = (events, type) => {
  };

  toggle = () => {
    this.setState((state) => ({
      isDisplayed: !state.isDisplayed,
      shownShiftTaskUI: state.showEditShiftTaskUI,
      // EditShiftData: events.resourceData.shiftData[0]
    }));
  };

  hide = () => {
    this.setState((state) => ({
      isDisplayed: !state.isDisplayed,
      shownShiftTaskUI: state.showEditShiftTaskUI,
    }));
  };

  clear = () => {
    this.setState((state) => ({
      isDisplayed: !state.isDisplayed,
      shownShiftTaskUI: state.shownShiftTaskUI,
      formtemplate: !state.formtemplate,
    }));
  };

  close = () => {
    this.setState((state) => ({
      genericTem: !state.genericTem,
    }));
  };

  clientTem = () => {
    this.setState((state) => ({
      clientSide: !state.clientSide,
    }));
  };

  deleteShift(mainshiftId, shiftId, deleteType) {
    let data = {
      mainshiftId: mainshiftId,
      shiftId: shiftId,
      type: deleteType,
    };

    swal({
      title: "Confirmation!",
      text: "Is shift declined by the care team member?",
      buttons: {
        cancel: "No",
        succeed: "Yes",
      },
    }).then((result) => {
      if (result) {
        data.decline = true;
      }
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_BASEURL}/deleteshift`,
        headers: {
          Authorization: localStorage.getItem("care_admin_token"),
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      };

      axios(config).then((res) => {
        this.getShiftsandtaskNew('closePopup');
        ShowSwal(res.data.status, res.data.message);
        // window.location.reload(false);
      }).catch((error) => {
        console.log(error);
      });
    });
  }

  render() {
    const { viewModel, downloadData } = this.state;
    return (
      <>
        <div className="col-md-2 ps-2" >
          <AiFillPrinter
            className="me-3 ms-1 curser_point"
            onClick={() => handleDataPrint(downloadData)}
          />
          <a className="curser_point">
            <JsonDownload
              fileName="shifttasks-excel"
              downloadfile={downloadData}
            />
          </a>
        </div>
        <div className="float-end btns_head" style={{ marginTop: "-34px" }}>
          <button className="btn btn-theme btn-md"
            disabled={this.state.SchedulerAccess !== 'full'}
            onClick={() => {
              this.setState(prevState => ({
                shownShiftTaskUI: !prevState.shownShiftTaskUI,
              }));
            }}>Create Shift</button>
          {/* <div
                className="dropdown multiDrop"
                style={{ marginBlock: "-30px" }}
              >
                <button
                  disabled={this.state.isviewdisabled}
                  className="btn btn-primary dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                >
                  Create
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <a
                      style={{ cursor: "pointer" }}
                      className="dropdown-item"
                      onClick={() => {
                        this.setState((prevState) => ({
                          shownShiftTaskUI: !prevState.shownShiftTaskUI,
                        }));
                      }}
                    >
                      New Shift
                    </a>
                  </li>
                  <li>
                    <a
                      style={{ cursor: "pointer" }}
                      className="dropdown-item"
                      onClick={() => {
                        this.setState((prevState) => ({
                          formtemplate: !prevState.formtemplate,
                        }));
                        document.body?.classList?.add("modal-scroll");
                      }}
                    >
                      From Template
                    </a>
                  </li>
                </ul>
              </div> */}
        </div>
        {!this.state.shownShiftTaskUI &&
          !this.state.showEditShiftTaskUI &&
          !this.state.formtemplate && (
            <Scheduler
              schedulerData={viewModel}
              prevClick={this.prevClick}
              nextClick={this.nextClick}
              onSelectDate={this.onSelectDate}
              onViewChange={this.onViewChange}
              eventItemClick={this.eventClicked}
              // updateEventStart={this.updateEventStart}
              // updateEventEnd={this.updateEventEnd}
              moveEvent={this.moveEvent}
              newEvent={this.newEvent}
              onScrollLeft={this.onScrollLeft}
              onScrollRight={this.onScrollRight}
              toggleExpandFunc={this.toggleExpandFunc}
              eventItemPopoverTemplateResolver={
                this.eventItemPopoverTemplateResolver
              }
              eventItemTemplateResolver={this.eventItemTemplateResolver}
              slotItemTemplateResolver={this.slotItemTemplateResolver}
              movingEvent={this.movingEvent}
            />)
        }

        {/* Create Shift POPup */}
        <div
          className="modal"
          style={{
            display: this.state.shownShiftTaskUI ? "block" : "none",
          }}
        >
          <div>
            <div className="bg_popup"></div>
            <div className="modal-dialog modal-xl">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Shift Creation
                    <small>
                      {this.state.siteTimeZone ? ` (Timezone used for shift creation - ${this.state.siteTimeZone})` : ''}
                    </small>
                  </h5>
                  <Link
                    className="btn-close"
                    onClick={() => {
                      this.setState((prevState) => ({
                        shownShiftTaskUI: !prevState.shownShiftTaskUI,
                      }));
                    }}
                    to="#"
                  ></Link>
                  {/* <button type="button" className="btn-close"></button> */}
                </div>
                <div className="modal-body">
                  {this.state.shownShiftTaskUI && (
                    <CreateShiftTask
                      shownShiftTaskUI={this.state.shownShiftTaskUI}
                      getShiftsandtaskNew={this.getShiftsandtaskNew}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal m-5"
          style={{
            display: this.state.genericTem ? "block" : "none",
            width: "90%",
          }}
        >
          <div className="bg_popup"></div>
          <div className="modal-dialog-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Generic Template Creation
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={this.close}
                  to="#"
                ></button>
              </div>
              <div className="modal-body">
                {this.state.genericTem && (
                  <GenericShiftcreation
                    genericTem={this.state.genericTem}
                    close={this.close}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal m-5"
          style={{
            display: this.state.clientSide ? "block" : "none",
            width: "92%",
            height: "92%",
          }}
        >
          <div className="bg_popup"></div>
          <div className="modal-dialog-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Client Template Creation
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={this.clientTem}
                  to="#"
                ></button>
              </div>
              <div className="modal-body">
                {this.state.clientSide && (
                  <ClientSideshift
                    clientSide={this.state.clientSide}
                    clientTem={this.clientTem}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        {/* // FormTemplate */}
        <div
          className={`modal ${this.state.isDisplayed ? "" : ""}`}
          id="formTemplate"
          style={{
            display: `${this.state.formtemplate ? "block" : "none"}`,
          }}
        >
          <div className="bg_popup"></div>
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Form Template</h4>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => {
                    this.setState((prevState) => ({
                      formtemplate: !prevState.formtemplate,
                    }));
                    document.body?.classList?.remove("modal-scroll");
                  }}
                ></button>
              </div>

              <div className="modal-body">
                {" "}
                {this.state.formtemplate && (
                  <FromTemplate
                    fromtemplatestatus={this.state.formtemplate}
                    onClick={this.toggleShowHide}
                    isDisplayed={this.state.isDisplayed}
                    hide={this.hide}
                    clear={this.clear}
                    toggle={this.toggle}
                    genericTem={this.state.genericTem}
                    close={this.close}
                    clientSide={this.state.clientSide}
                    clientTem={this.clientTem}
                  />
                )}
              </div>

              <div className="modal-footer"></div>
            </div>
          </div>
        </div>

        <div
          className="modal"
          style={{
            display: this.state.showEditShiftTaskUI ? "block" : "none",
          }}
        >
          <div className="bg_popup"></div>
          <div className="modal-dialog-sm">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Shift Updation
                  <small>
                    {this.state.siteTimeZone ? ` (Timezone used for shift creation - ${this.state.siteTimeZone})` : ''}
                  </small>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => {
                    this.setState((prevState) => ({
                      showEditShiftTaskUI: !prevState.showEditShiftTaskUI,
                    }));
                  }}
                ></button>
              </div>
              <div className="modal-body">
                {this.state.showEditShiftTaskUI && (
                  <EditCreateShiftTask
                    shownEditShiftTaskUI={this.state.showEditShiftTaskUI}
                    titleName="Shift Creation"
                    buttonType="editShift"
                    addCreateShift="editshift"
                    typeshift=""
                    editTaskData={this.state.EditShiftData}
                    data={this.state}
                    getShiftsandtaskNew={this.getShiftsandtaskNew}
                  />
                )}

                {this.state.showClientEditShiftTaskUI && (
                  <EditCreateShiftTask
                    shownEditShiftTaskUI={this.state.showEditShiftTaskUI}
                    editTaskData={this.state.EditclientShiftData}
                    data={this.state}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <ul className="statusBox text-center">
          <li>
            <span style={{ background: "purple" }}></span>
            Open
          </li>
          <li>
            <span style={{ background: "#F2994A" }}></span>
            Unassigned
          </li>
          <li>
            <span style={{ background: "grey" }}></span>
            Assigned
          </li>
          <li>
            <span style={{ background: "#007fff" }}></span>
            In-process
          </li>
          <li>
            <span style={{ background: "#219653" }}></span>
            Completed
          </li>
          <li>
            <span style={{ background: "#EB5757" }}></span>
            Overdue
          </li>
        </ul>
      </>
    );
  }

  moveEvent = (schedulerData, event, slotId, slotName, start, end) => {
    let date = start.split(" ")[0];

    schedulerData.moveEvent(event, slotId, slotName, start, end);
    this.setState({
      viewModel: schedulerData,
    });

    var shiftType = [];
    var startTime = [];
    var endTime = [];
    var taskName = [];
    var taskType = [];
    event?.ShiftData?.Task?.forEach((items, index) => {
      shiftType.push(items.shiftType);
      startTime.push(items.startTime);
      endTime.push(items.endTime);
      taskName.push(items.taskName);
      taskType.push(items.taskType);
    });

    if (shiftType.length > 1) {
      var type = "array";
    } else {
      var type = "string";
    }

    var data = new FormData();
    data.append("careHomeId", event.ShiftData.careHomeId);
    data.append("careTeamId", event.ShiftData.careTeamId._id);
    data.append("shiftId", event.ShiftData._id);
    data.append("clientId", event.ShiftData.clientId._id);
    data.append(
      "forClient",
      event.ShiftData.forClient
        .split(" ")
        .filter((s) => s)
        .join(" ")
    );
    data.append(
      "careTeamMember",
      event.ShiftData.careTeamMember
        .split(" ")
        .filter((s) => s)
        .join(" ")
    );
    data.append("Date", date);
    data.append("startingTime", event.ShiftData.startingTime);
    data.append("endingTime", event.ShiftData.endingTime);
    data.append("recurringTask", event.ShiftData.recurringTask);
    data.append("recurringTasktype", event.ShiftData.recurringTasktype);
    data.append("instruction", event.ShiftData.instruction);
    data.append("recurringShiftType", event.ShiftData.recurringShiftType);
    data.append(
      "attachments",
      event.ShiftData.image ? event.ShiftData.image : ""
    );
    data.append("type", type);
    data.append("endDate", event.ShiftData.endDate);
    data.append("shiftType", shiftType);
    data.append("startTime", startTime);
    data.append("endTime", endTime);
    data.append("taskName", taskName);
    data.append("taskType", taskType);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/editShift`,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        window.location.reload(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  movingEvent = (
    schedulerData,
    slotId,
    slotName,
    newStart,
    newEnd,
    action,
    type,
    item
  ) => {
    // console.log('moving event', schedulerData, slotId, slotName, newStart, newEnd, action, type, item);
  };

  eventItemPopoverTemplateResolver = (
    schedulerData,
    eventItem,
    title,
    start,
    end,
    statusColor
  ) => {
    // console.log(start.format(), eventItem, '777777777777777')
    let shiftTimeZone = eventItem.ShiftData?.timeZone;
    const isShiftDatepassed = isDateTimeGreaterThanCurrent(start.format(), new Date());
    return (
      <div>
        <Row type="flex" align="middle">
          <Col span={2}>
            <div
              className="status-dot"
              style={{ backgroundColor: statusColor }}
            />
          </Col>
          <Col span={22} className="overflow-text">
            <img
              style={{
                borderRadius: "50%",
                width: "34px",
                height: "34px",
                marginRight: "5px",
              }}
              src={eventItem?.evImage}
            />
            <span className="header2-text" title={eventItem?.evdata}>
              {eventItem?.evdata}
            </span>
          </Col>
        </Row>
        <Row type="flex" align="middle">
          <Col span={2}>
            <div />
          </Col>
          <Col span={22} md={45}>
            <span className="header1-text">
              {start.format("HH:mm")} - {end.format("HH:mm")}
            </span>
          </Col>
        </Row>
        <Row type="flex" align="middle">
          <Col span={2}>
            <div />
          </Col>
          <Col span={55}>
            <span style={{ color: "#EB5757" }}>TaskList: </span>
            <ul>
              {eventItem?.taskList.map((item, index) => {
                let isWithinTime = item?.shiftType?.toLowerCase()?.includes("within");
                // console.log(item, index, "eventItemtaskList");
                const conditionsToShowMed =
                  Object.keys(item).includes("medName") &&
                  Object.keys(item).includes("prn") &&
                  item?.prn?.toLowerCase() === "no";

                const conditionsToNotShowTime =
                  Object.keys(item).includes("medName") &&
                  Object.keys(item).includes("prn") &&
                  item?.prn?.toLowerCase() === "yes";

                if (conditionsToShowMed) {
                  return (
                    <li key={index} style={{ textDecoration: "none" }}>
                      Medication Reminder ({item.schedule})
                    </li>
                  );
                }
                if (conditionsToNotShowTime) {
                  return (
                    <li key={index} style={{ textDecoration: "none" }}>
                      PRN Med (
                      {isWithinTime ? start.format("HH:mm") : convertTimeToUserTimeZone(item.startTime, shiftTimeZone)}{" "}
                      -{" "}
                      {isWithinTime ? end.format("HH:mm") : convertTimeToUserTimeZone(item.endTime, shiftTimeZone)}
                      )
                    </li>
                  );
                }
                return (
                  <>
                    <li key={index} style={{ textDecoration: "none" }}>
                      {item.taskType} (
                      {isWithinTime ? start.format("HH:mm") : convertTimeToUserTimeZone(item.startTime, shiftTimeZone)}{" "}
                      -{" "}
                      {isWithinTime ? end.format("HH:mm") : convertTimeToUserTimeZone(item.endTime, shiftTimeZone)}
                      )
                    </li>
                  </>
                );
              })}
              <li style={{ textDecoration: "none" }}>
                Wellbeing (
                {start.format("HH:mm")}-{" "}{end.format("HH:mm")}
                )
              </li>
            </ul>
          </Col>
        </Row>
        {(eventItem?.ShiftData?.recurringShiftType === "open" ||
          eventItem?.ShiftData?.recurringShiftType === "unassigned" ||
          (eventItem?.ShiftData?.recurringShiftType === "assigned" &&
            eventItem?.ShiftData?.status === "pending" && isShiftDatepassed)) && (
            <Row type="flex" align="middle">
              <div>
                <Button
                  className="mx-3 btn btn-primary"
                  onClick={() => {
                    this.EditButtonClicked(eventItem);
                  }}
                >
                  Edit
                </Button>
              </div>
              <div className="dropdown multiDrop">
                <button
                  className="btn btn-primary dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                >
                  Delete
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <a
                      style={{ cursor: "pointer" }}
                      className="dropdown-item"
                      onClick={() => {
                        this.deleteShift(eventItem.shiftId, eventItem.id, "");
                      }}
                    >
                      Delete this shift
                    </a>
                    {/* <a className="dropdown-item" href="#">From Template</a> */}
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() => {
                        this.deleteShift(
                          eventItem.shiftId,
                          eventItem.shiftId,
                          "mainshift"
                        );
                      }}
                    >
                      Delete All associated recurring shift
                    </a>
                  </li>
                </ul>
              </div>
              {/* <Button
              className="btn btn-primary"
              onClick={() => {
                this.deleteShift(eventItem.id);
              }}
            >
              Delete
            </Button> */}
            </Row>
          )}{" "}
      </div>
    );
  };

  EditButtonClicked = (event) => {
    this.setState((prevState) => ({
      showEditShiftTaskUI: !prevState.showEditShiftTaskUI,
      // EditTaskData: prevState.EventsArr.filter(task => task.id === event.id),
      EditShiftData: event,
    }));
  };

  deleteButtonClicked = (event) => {
    this.setState(
      (prevState) => {
        return prevState.viewModel.events.filter(
          (index) => index.id !== event.id
        );
      },
      () => this.state.viewModel
    );
    this.deleteShift(event.id);
  };

  slotItemTemplateResolver(
    schedulerData,
    slot,
    slotClickedFunc,
    width,
    clsName
  ) {
    let resourceData = schedulerData.getSlotById(slot.slotId);
    const chatUserImg = '/images/chatUser.svg';
    let dp = resourceData.image;
    return (
      <span className="schedulerResourse">
        <img className='profile_img_calendar'
          src={`${dp ? dp : chatUserImg}`} />{" "}
        <span>{slot.slotName}</span>
      </span>
    );
  }

  prevClick = (schedulerData) => {
    schedulerData.prev();
    schedulerData.setEvents(this.state?.EventsArr);
    this.setState({
      viewModel: schedulerData,
    });
    this.getShiftsandtaskNew('refresh');
  };

  nextClick = (schedulerData) => {
    schedulerData.next();
    schedulerData.setEvents(this.state?.EventsArr);
    this.setState({
      viewModel: schedulerData,
    });
    this.getShiftsandtaskNew('refresh');
  };

  onViewChange = (schedulerData, view) => {
    schedulerData.setViewType(
      view.viewType,
      view.showAgenda,
      view.isEventPerspective
    );
    schedulerData.setEvents(this.state.EventsArr);
    this.setState({
      viewModel: schedulerData,
    });
    this.getShiftsandtaskNew('refresh');
  };

  onSelectDate = (schedulerData, date) => {
    schedulerData.setDate(date);
    schedulerData.setEvents(this.state.EventsArr);
    this.setState({
      viewModel: schedulerData,
    });
    this.getShiftsandtaskNew('refresh');
  };

  eventClicked = (schedulerData, event) => {
    // console.log("eventClicked==>>", this.state.showEditShiftTaskUI)
    schedulerData.setEvents(this.state.EventsArr);
    this.setState({
      viewModel: schedulerData,
    });
  };

  newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
    this.setState({
      shownShiftTaskUI: !this.state.shownShiftTaskUI,
    });
  };

  updateEventStart = (schedulerData, event, newStart) => {
    schedulerData.updateEventStart(event, newStart);
    this.setState({
      viewModel: schedulerData,
    });
  };

  updateEventEnd = (schedulerData, event, newEnd) => {
    schedulerData.updateEventEnd(event, newEnd);
    this.setState({
      viewModel: schedulerData,
    });
  };

  onScrollRight = (schedulerData, schedulerContent, maxScrollLeft) => {
    if (schedulerData.ViewTypes === ViewTypes.Day) {
      schedulerData.next();
      schedulerData.setEvents(this.state.EventsArr);
      this.setState({
        viewModel: schedulerData,
      });
      schedulerContent.scrollLeft = maxScrollLeft - 10;
    }
  };

  onScrollLeft = (schedulerData, schedulerContent, maxScrollLeft) => {
    if (schedulerData?.ViewTypes === ViewTypes.Day) {
      schedulerData.prev();
      schedulerData.setEvents(this.state?.EventsArr);
      this.setState({
        viewModel: schedulerData,
      });
      schedulerContent.scrollLeft = 10;
    }
  };

  toggleExpandFunc = (schedulerData, slotId) => {
    schedulerData.toggleExpandStatus(slotId);
    this.setState({ viewModel: schedulerData });
  };
}

export default WithDragDropContext(Basic);