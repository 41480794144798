import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { fetch4, httpPayments } from "../../Apis/commonApis";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch } from "react-redux";
import {
  changeButton,
  getOwnerId,
  handleEmailVerifyFromLogin
} from "../../redux-toolkit/reducer/accountCreationApiReducer";
import { getCurrentTimeZone } from "../../utils/CommonFunctions";

export default function CardlessPaymentSuccess() {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();

  const [selectedPlanData, setSelectedPlanData] = useState([]);
  const [cardlessData, setCardlessData] = useState([]);
  const [adminData, setAdminData] = useState(null);
  const [completeRedirctFlowData, setCompleteRedirctFlowData] = useState(null);
  const [showMsg, setShowMsg] = useState("Payment in Progress... !");
  const [errorMsg, setErrorMsg] = useState("Please Wait");
  const [trackData, setTrackData] = useState([]);

  useEffect(() => {
    if (params.trackId !== '0') getTrackSignup();
    if (params.ownerId !== '0') getAdminData();

    let strtPayApiData = JSON.parse(localStorage.getItem("startPayApiData"));
    let cartData = JSON.parse(localStorage.getItem("cartDetails"));
    let goCardData = JSON.parse(localStorage.getItem("goCardlessData"));

    setSelectedPlanData(cartData);
    setCardlessData(goCardData);
    let currentDate = new Date();
    let nextMonthDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      currentDate.getDate()
    );

    if (strtPayApiData) {
      const billingRequestData = {
        adminId: params.ownerId ?? params.ownerId,
        redirectFlowId: strtPayApiData?.data?.id,
        session_token: strtPayApiData?.data?.session_token,
        amount: cartData.unit_amount,
        currency: goCardData.currency?.toUpperCase(),
        planName: cartData.SubscriptionPlan,
        frequency: cartData.monthyOrYearly?.toLowerCase(),
        paymentStartDate: nextMonthDate?.toJSON()?.split("T")[0],
        billingEmail: goCardData?.billingEmail
      };
      completeRedirctFlow(billingRequestData);
    } else {
      setShowMsg('Payment details missing, could not complete the process');
      setErrorMsg('Try Again');
    }
  }, []);

  useEffect(() => {
    if (completeRedirctFlowData) {
      registerNewSite();
    }
  }, [completeRedirctFlowData]);

  function handleTryAgain() {
    setShowMsg('Fetching details');
    setErrorMsg('Please wait.');
    // getTrackSignup();
    // history.replace("/login")
  }

  const getTrackSignup = async () => {
    const res = await fetch4(`getTrackSignup?_id=${params.trackId}`, null);
    if (res.status) {
      setTrackData(res.data);
    }
  };

  const getAdminData = async () => {
    const res = await httpPayments(`getAdmin?adminId=${params.ownerId}`, null);
    if (res.status) setAdminData(res.result[0]);
    // console.log(res, 'getadmin data');
  };

  const completeRedirctFlow = async (billingRequestData) => {
    const res = await httpPayments(`completeRedirctFlow`, billingRequestData);
    if (res.status) {
      setCompleteRedirctFlowData(res);
    } else {
      setShowMsg(res.message ? res.message : 'Complete Payment redirection flow failed !');
      setErrorMsg('Try Again');
    }
  };

  const registerNewSite = async () => {
    let strtPayApiData = JSON.parse(localStorage.getItem("startPayApiData"));
    let newSite = false;
    let careData = [];
    if (params.registerType === "newCareSite") {
      careData = JSON.parse(localStorage.getItem("createNewCareSiteData"));
      newSite = true;
      // console.log(careData, 'newCareSiteData');
    }
    let caresitedata = newSite ? careData : adminData;
    let lattitude = caresitedata?.lattitude ?? caresitedata?.loc?.coordinates?.[1];
    let longitude = caresitedata?.longitude ?? caresitedata?.loc?.coordinates?.[0];
    let data = {
      adminId: newSite ? params.ownerId : adminData?._id,
      companyName: newSite ? careData.companyName : adminData?.companyName,
      careSiteName: newSite ? careData.careSiteName : adminData?.careSiteName,
      carefacility: newSite ? careData.carefacility : adminData?.carefacility,
      totalBeds: newSite ? careData.totalBeds : adminData?.totalBeds,
      careSiteAddress: caresitedata?.careSiteAddress ?? 0,
      lattitude: lattitude ?? 0,
      longitude: longitude ?? 0,
      type: newSite ? "loggedIn" : "signup",
      ownerName: adminData.firstName,
      billingAddress: adminData?.billingAddress,
      city: adminData?.city,
      postalCode: adminData?.postalCode,
      billingEmail: cardlessData?.billingEmail,
      billingContact: adminData.mobileNo,
      country: cardlessData?.country,
      state: cardlessData?.country,
      currency: cardlessData?.currency,
      planId: selectedPlanData.planId,
      SubscriptionPlan: selectedPlanData?.SubscriptionPlan,
      SubscriptionPlanId: selectedPlanData?.SubscriptionPlanId,
      chargesDetails: selectedPlanData?.chargesDetails,
      couponCode: selectedPlanData.promoName,
      monthyOrYearly: selectedPlanData?.monthyOrYearly,
      trialExpiry: "",
      planRate: selectedPlanData?.planPrice,
      unit_amount: selectedPlanData?.totalBeforeTax,
      taxRate: selectedPlanData?.taxRate,
      total_amount: selectedPlanData?.unit_amount,
      multiCareSite: false,
      planstartDate: Date.now(),
      paymentThrough: selectedPlanData?.unit_amount > 0 ? "gocardless" : '',
      // trackSignUp: "complete",
      goCardLess_mandate_id: completeRedirctFlowData.data.links.mandate,
      goCardLess_customer_id: completeRedirctFlowData.data.links.customer,
      goCardLess_BankAccount_id:
        completeRedirctFlowData.data.links?.customer_bank_account,
      productId: selectedPlanData?.productId ?? adminData?.productId,
      goCardLess_subscription_id: completeRedirctFlowData?.subscription?.id,
      redirectFlowId: strtPayApiData?.data?.id,
      timeZone: getCurrentTimeZone()
    };
    if (params.adminId !== "0") {
      data.subAdminId = params.adminId;
    }
    // console.log(data, 'register new site data')
    const res = await httpPayments(
      `registerNewSite?adminId=${params.ownerId}`,
      data
    );
    // console.log(res, 'register new site');
    if (res.status) {
      dispatch(
        changeButton({
          currentNo: 0,
          buttonText: "Next",
          stepNo: 1,
          nextNo: 1,
        })
      );
      dispatch(getOwnerId({ loginVerifyEmail: 0 }));
      dispatch(handleEmailVerifyFromLogin({ verifySuccessLogin: 0 }));
      setShowMsg("Successfully Created Payment Method.");
      setTimeout(() => {
        if (newSite) {
          localStorage.removeItem("createNewCareSiteData");
          history.replace("/admin/dashboard");
        } else {
          localStorage.clear();
          history.replace("/login");
        }
      }, 3000);
    } else {
      setShowMsg(res.message);
      setErrorMsg('Try Again');
    }
  };

  return (
    <div className="col-md-8" style={{ paddingTop: "60px", margin: "auto" }}>
      <h2 className="themeColor d-flex justify-content-center">{showMsg}</h2>
      {showMsg !== "Successfully Updated" ? (
        errorMsg !== 'Try Again' ? <>
          <div className=" contentCreate d-flex justify-content-center">
            <CircularProgress color="secondary" />
          </div>
          <div className=" contentCreate d-flex justify-content-center">
            <p>{errorMsg}</p>
          </div>
        </> : (<div className="btns_head mt-3 d-flex justify-content-center"><button
          className=" mb-2 btn btn-theme btn-sm"
          onClick={handleTryAgain}
        >
          Try Again
        </button></div>)

      ) : (
        <div className=" contentCreate d-flex justify-content-center">
          <p>Now You Can Login.</p>
        </div>
      )}
    </div>
  );
}
