import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PaymentAction from "./PaymentAction";
import { BsSearch } from "react-icons/bs";
import DataGridTable from "../../utils/DataGridTable";
import { filterData } from "../../utils/TableSearchFilter";
import { sortByLatestDateFirst } from "../../utils/CommonFunctions";
import { twoDecimalPlaces } from "../../utils/utilsHandlerFunctions";

const Payments = () => {
  const { invoiceAllData } = useSelector((state) => state.superAdminReducer);

  const [searchTxt, setSearchTxt] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);

  const rowClickHandler = (id) => {
    setSelectedRow(invoiceAllData.find((item) => item._id === id[0]));
  };

  const columns = [
    {
      field: "companyName",
      headerName: "Company Name",
      flex: 1,
      minWidth: 150,
    },
    { field: "careSiteName", headerName: "Site Name", flex: 1, minWidth: 120 },
    { field: "customId", headerName: "Invoice ID", flex: 1, minWidth: 120 },
    {
      field: "customPaymentId",
      headerName: "Payment ID",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "paymentGatewayId",
      headerName: "Payment Gateway ID",
      flex: 1,
      minWidth: 120,
    },
    { field: "planType", headerName: "Plan Type", minWidth: 90 },
    { field: "invoiceDate", headerName: "Invoice Date", minWidth: 90 },
    {
      field: "total",
      headerName: "Value",
      minWidth: 90,
      renderCell: (fieldData) => <DisplayDecimal fieldData={fieldData} />,
    },
    { field: "currency", headerName: "Currency", minWidth: 100 },
    {
      field: "status",
      headerName: "Status",
      minWidth: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (fieldData) => <StatusBtn fieldData={fieldData} />,
    },
  ];

  const filteredData = filterData(
    columns?.map((item) => ({
      id: item.field,
      label: item.headerName,
    })),
    invoiceAllData,
    searchTxt
  );

  const roleAccess = JSON.parse(localStorage.getItem("__csadmin__d"));

  return (
    <div className="page-wrapper" style={{ height: "90vh" }}>
      <div className="card">
        <div className="card-body">
          {roleAccess?.role?.map((roletype) => {
            const invoiceAccess = roletype.Modules[5];
            if (invoiceAccess?.access !== "full")
              return <div className="clickOff"></div>;
          })}
          {/* <PaymentsTable rows={invoiceAllData} /> */}

          <>
            {/* <InvoiceTable
                rows={invoiceAllData}
                onRowClick={rowClickHandler}
                getLatestInvioceData={getLatestInvioceData}
              /> */}
            <div className="row mb-2 justify-content-end">
              <div className="searchForm careSearch">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  value={searchTxt}
                  onChange={(e) => setSearchTxt(e.target.value)}
                />
                <BsSearch className="icon me-2" />
              </div>
              <div className="col-md-6">
                <PaymentAction
                  rowDetail={selectedRow}
                // latestRowData={latestRowData}
                />
              </div>
            </div>
            <DataGridTable
              columns={columns}
              rows={filteredData}
              handleRowSelection={rowClickHandler}
            />
          </>
        </div>
      </div>
    </div>
  );
};

export default Payments;

const StatusBtn = (props) => {
  const { fieldData } = props;
  const status = fieldData?.row?.status;
  return (
    <button
      className={`btn btn-sm table_btn ${status === "paid" ? "btn-success" : status === "refunded" ? "btn-primary" : "btn-danger"
        }`}
    >
      {status === "paid" ? "paid" : status === "refunded" ? "refunded" : "unpaid"}
    </button>
  );
};

const DisplayDecimal = (props) => {
  const { fieldData } = props;
  return <>{twoDecimalPlaces(fieldData.row.total)}</>;
};
